import { createSlice } from '@reduxjs/toolkit';
import { ACTION_STATUS, CATEGORY_TYPE } from 'libs/constants';
import { formatMaterialID } from "libs/utils/material-number";

const formatMaterialNumber = (products) =>
  products.map((product) => ({
    ...product,
    materialNumber: formatMaterialID(
      product?.materialNumber,
      product?.materialNumber?.length
    ),
  }));

const updateStateForCategories = (state, _isLoading, _list, _allAmount) => {
  // Remove for..in so the code will be more concise
  // https://stackoverflow.com/questions/43807515/eslint-doesnt-allow-for-in#comment120279279_43807676
  Object.keys(state.data).forEach((category) => {
    if (_isLoading !== undefined && _isLoading !== null) {
      state.data[category].isLoading = _isLoading;
    }
    if (_list !== undefined && _list !== null) {
      state.data[category].list = _list;
    }
    if (_allAmount !== undefined && _allAmount !== null) {
      state.data[category].allAmount = _allAmount;
    }
  });
};

const requestSearchAllResultSuccess = (state, { data }) => {
  state.allResult.list = formatMaterialNumber(data?.items);
  state.allResult.allAmount = data?.total;
  state.allResult.isLoading = false;
};

const requestSearchOverviewSuccess = (state, { data }) => {
  updateStateForCategories(state, false);

  /*
  {
    0: {items: [], total: ...},
    1: {items: [], total: ...},
    2: {items: [], total: ...},
  }
  */
  const categoryNames = [];

  if (data) {
    Object.keys(data).forEach((categoryTypeIndex) => {
      // categoryTypeIndexes in data are 0, 1, 2

      const categoryProducts = data[categoryTypeIndex]?.items;
      const allProductsAmount = data[categoryTypeIndex]?.total;
      // get the category name by data index
      const categoryType = CATEGORY_TYPE[categoryTypeIndex][0];
      categoryNames.push(categoryType);

      state.data[categoryType].list = formatMaterialNumber(categoryProducts);
      state.data[categoryType].allAmount = allProductsAmount;
    });
  } else {
    Object.keys(CATEGORY_TYPE).forEach((categoryTypeIndex) => {
      if (Object.hasOwnProperty.call(CATEGORY_TYPE, categoryTypeIndex)) {
        const categoryType = CATEGORY_TYPE[categoryTypeIndex][0];
        state.data[categoryType].list = [];
        state.data[categoryType].allAmount = 0;
      }
    });
  }

  state.overviewCategories = categoryNames;
};

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    data: {
      machine: {
        isLoading: false,
        list: [],
        allAmount: 0,
      },
      accessory: {
        isLoading: false,
        list: [],
        allAmount: 0,
      },
      detergent: {
        isLoading: false,
        list: [],
        allAmount: 0,
      },
    },
    overviewCategories: [],
    allResult: {
      isLoading: false,
      list: [],
      allAmount: 0,
    },
    suggestions: [],
    isFetchingSuggesstions: false,
    searchTerm: '',
    isSearchFocused: false,
  },
  reducers: {
    setSearchTerm(state, { payload }) {
      state.searchTerm = payload;
    },
    getSearchSuggestions(state) {
      state.isFetchingSuggesstions = true;
    },
    getSearchSuggestionsSuccess(state, { payload }) {
      state.isFetchingSuggesstions = false;
      state.suggestions = payload;
    },
    getSearchSuggestionsFailed(state) {
      state.isFetchingSuggesstions = false;
    },
    requestSearch(state) {
      state.actionStatus = ACTION_STATUS.DEFAULT;
      state.allResult.isLoading = true;

      updateStateForCategories(state, true, [], 0);
    },
    requestSearchFailed(state) {
      state.actionStatus = ACTION_STATUS.FAILED;
      state.allResult.isLoading = false;

      updateStateForCategories(state, false, [], 0);
    },
    requestSearchSuccess(state, { payload }) {
      const { searchAll } = payload;
      state.actionStatus = ACTION_STATUS.SUCCESS;

      return searchAll
        ? requestSearchAllResultSuccess(state, payload)
        : requestSearchOverviewSuccess(state, payload);
    },
  },
});

// Destructure and export the plain action creators
export const {
  getSearchSuggestions,
  getSearchSuggestionsSuccess,
  getSearchSuggestionsFailed,
  requestSearch,
  requestSearchSuccess,
  requestSearchFailed,
  setSearchTerm,
} = searchSlice.actions;

export default searchSlice.reducer;
