import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const B2becTranslation = ({
  value: key,
  variables,
  isComponent,
  htmlTags,
  context,
}) => {
  const { t } = useTranslation();

  /** *
   * value/key  : id for the translatable texts, rename to 'key' to avoid confusion
   * variables  : an object contains dynamic variables in the translatable texts
   * isComponent: a boolean flag distinguish translatable texts interpolate html tags
   * htmlTags   : an array of html tags to interpolate based on index of tag <0></0>, ...
   */

  return isComponent ? (
    <Trans
      i18nKey={key}
      values={variables}
      components={htmlTags}
      context={context}
    />
  ) : (
    <span>{t(key, variables)}</span>
  );
};

B2becTranslation.propTypes = {
  value: PropTypes.string.isRequired,
  variables: PropTypes.shape({}),
  isComponent: PropTypes.bool,
  htmlTags: PropTypes.arrayOf(PropTypes.node),
  context: PropTypes.string,
};

B2becTranslation.defaultProps = {
  variables: {},
  isComponent: false,
  htmlTags: [],
  context: '',
};

export default B2becTranslation;
