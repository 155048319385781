import { LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './B2becButton.module.scss';

const B2becButton = ({
  children,
  className,
  ghost,
  block,
  disabled,
  loading,
  icon,
  ...props
}) => {
  const classnames = clsx(
    styles.button,
    ghost && styles.ghost,
    block && styles.block,
    loading && styles.loading,
    disabled && styles.disabled,
    className
  );

  const renderIcon = () => {
    if (loading) {
      return (
        <span className={clsx(styles.icon, styles.loading)}>
          <LoadingOutlined />
        </span>
      );
    }

    if (icon) {
      return <span className={styles.icon}>{icon}</span>;
    }

    return null;
  };

  if (props.href) {
    return (
      <a type="button" className={classnames} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button type="button" className={classnames} {...props}>
      {renderIcon()}
      <span className={styles.content}>{children}</span>
    </button>
  );
};

B2becButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.node,
};

B2becButton.defaultProps = {
  className: undefined,
  disabled: false,
  ghost: false,
  block: false,
  loading: false,
  icon: null,
};

const ButtonLink = ({ navigate, ...props }, ref) => {
  return <B2becButton ref={ref} {...props} onClick={navigate} />;
};

export default B2becButton;

export const B2becButtonLink = React.forwardRef(ButtonLink);
