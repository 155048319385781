import './styles.scss';

import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import servicePackagesData from '../../servicePackagesData';

const ResponsiveTable = () => {
  const { t } = useTranslation();

  const renderOptionLabels = () => {
    return (
      <div className="service-table__labels">
        <span className="service-table__label service-table__label--included">
          {t('service.servicePackages.overview.table.included')}
        </span>
        <span className="service-table__label service-table__label--optional">
          {t('service.servicePackages.overview.table.optional')}
        </span>
        <span className="service-table__label service-table__label--not-included">
          {t('service.servicePackages.overview.table.notIncluded')}
        </span>
      </div>
    );
  };

  const renderColumnHeader = () => {
    return (
      <thead>
        <tr>
          <td className="service-table__header service-table__header__first-column" />
          <td className="service-table__header service-table__header__second-column">
            {t('service.servicePackages.karcherInspect.briefTitle')}
          </td>
          <td className="service-table__header service-table__header__third-column">
            {t('service.servicePackages.karcherMaintain.briefTitle')}
          </td>
          <td className="service-table__header service-table__header__forth-column">
            {t('service.servicePackages.karcherFullService.briefTitle')}
          </td>
        </tr>
      </thead>
    );
  };

  const renderColumnFooter = () => {
    return (
      <tfoot>
        <tr>
          <td className="service-table__footer service-table__footer__first-column" />
          <td className="service-table__footer service-table__footer__second-column">
            {t('service.servicePackages.karcherInspect.briefTitle')}
          </td>
          <td className="service-table__footer service-table__footer__third-column">
            {t('service.servicePackages.karcherMaintain.briefTitle')}
          </td>
          <td className="service-table__footer service-table__footer__forth-column">
            {t('service.servicePackages.karcherFullService.briefTitle')}
          </td>
        </tr>
      </tfoot>
    );
  };

  const renderNormalRows = () => {
    return servicePackagesData.map((packageItem) => {
      const { benefitTranslationKey, inspect, maintain, fullService } =
        packageItem;

      return !packageItem.extraRow ? (
        <tr key={benefitTranslationKey} className="service-table__normal-row">
          <td>{t(benefitTranslationKey)}</td>
          <td
            className={clsx('service-table__normal-row--border', {
              'service-table__normal-row--included': inspect?.included,
              'service-table__normal-row--optional': inspect?.optional,
              'service-table__normal-row--not-included': inspect?.notIncluded,
            })}
          >
            {inspect?.content}
          </td>
          <td
            className={clsx('service-table__normal-row--border', {
              'service-table__normal-row--included': maintain?.included,
              'service-table__normal-row--optional': maintain?.optional,
              'service-table__normal-row--not-included': maintain?.notIncluded,
            })}
          >
            {maintain?.content}
          </td>
          <td
            className={clsx('service-table__normal-row--border', {
              'service-table__normal-row--included': fullService?.included,
              'service-table__normal-row--optional': fullService?.optional,
              'service-table__normal-row--not-included':
                fullService?.notIncluded,
            })}
          >
            {fullService?.content}
          </td>
        </tr>
      ) : null;
    });
  };

  return (
    <div>
      {renderOptionLabels()}
      <table className="service-table__content">
        {renderColumnHeader()}
        <tbody>
          <tr className="service-table__section-title">
            <td colSpan={4}>
              {t('service.servicePackages.overview.table.benefits.title')}
            </td>
          </tr>
          {renderNormalRows()}
        </tbody>
        {renderColumnFooter()}
      </table>
    </div>
  );
};

export default ResponsiveTable;
