import { CONFIG_DATA_TYPES } from '../../../libs/constants/configs';
import { isValidArray } from '../../../libs/utils/array';

const mapConfigData = (configurations) => {
  if (!isValidArray(configurations)) {
    return {};
  }

  const result = {};

  configurations.forEach((config) => {
    const dataType = config.dataTypeDto?.toUpperCase();
    const { value } = config;

    switch (dataType) {
      case CONFIG_DATA_TYPES.BOOLEAN.toUpperCase():
        result[config.configurationKey] =
          value?.toUpperCase() === 'TRUE' || value === true;
        break;
      case CONFIG_DATA_TYPES.OBJECT.toUpperCase():
        result[config.configurationKey] = value || '{}';
        break;
      default:
        result[config.configurationKey] = value;
    }
  });

  return result;
};

export default mapConfigData;
