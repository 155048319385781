import { Col, Row } from 'antd';
import ProductComparisonModal from 'components/ProductComparisonModal';
import useDeviceDetect from 'hooks/useDeviceDetect';
import useProductComparison from 'hooks/useProductComparison';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  sendProductDetail,
  showAddProductToWishlistModal,
} from 'store/slices/wishlistSlice';

import AddToCartBtn from '../../../components/AddToCartBtn';
import B2becTable from '../../../components/B2becTable';
import ProductCard from '../../../components/ProductCard';
import useDummyItems from '../../../hooks/useDummyItems';
import usePermission from '../../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../libs/constants/modulerights';
import listColumns from './renderColumns';

export const GridView = (props) => {
  const { productsList, onAddProductToCart, dispatch } = props;
  const {
    verifiedPermissions: [
      hasViewListPricePermission,
      hasAddProductToCartPermission,
    ],
  } = usePermission([
    MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_LIST_PRICE,
    MODULE_SHOP_NAVIGATION_RIGHTS.ADD_PRODUCT_TO_SHOPPING_CART,
  ]);

  const {
    onCompareHandler,
    comparingProductIds,
    showComparisonModal,
    setShowComparisonModal,
    productInformation,
  } = useProductComparison();

  const onCheckHandler = useCallback(
    (product, event) => {
      const isChecked = event?.target?.checked;

      onCompareHandler(isChecked, product);
    },
    [onCompareHandler]
  );

  const handleAddToWishlist = ({ materialName, materialNumber, img }) => {
    dispatch(sendProductDetail({ materialName, materialNumber, img }));
    dispatch(showAddProductToWishlistModal());
  };

  return (
    <>
      <Row gutter={20} style={{ marginBottom: 20 }}>
        {productsList.map((item) => (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={8}
            key={item?.materialNumber}
            className="card-item"
          >
            <ProductCard
              name={item?.name}
              category={item.category}
              img={item?.img || item?.imageUrl}
              materialNumber={item?.materialNumber || item?.materialId}
              listPrice={item?.listPrice || item?.price}
              currencyUnit={item?.currencyUnit || item?.currency}
              requiresInstruction={item?.requiresInstruction}
              addToCartBtn={
                <AddToCartBtn
                  handleClick={() =>
                    onAddProductToCart(item?.materialNumber || item?.materialId)
                  }
                />
              }
              comparingProductIds={comparingProductIds}
              onCheckHandler={onCheckHandler}
              isCompareCheckboxDisplayed
              displayAddToCart={
                item?.displayAddToCart && hasAddProductToCartPermission
              }
              shouldDisplayListPrice={
                item?.displayListPrice && hasViewListPricePermission
              }
              onAddToWishlist={handleAddToWishlist}
            />
          </Col>
        ))}
      </Row>

      <ProductComparisonModal
        showModal={showComparisonModal}
        setShowModal={setShowComparisonModal}
        productsData={productInformation}
      />
    </>
  );
};

export const ListView = (props) => {
  const { dataSrc, isLoading, onAddProductToCart } = props;

  const {
    onCompareHandler,
    comparingProductIds,
    showComparisonModal,
    setShowComparisonModal,
    productInformation,
  } = useProductComparison();

  const {
    verifiedPermissions: [
      hasAddProductToCartPermission,
      hasProductComparisonPermission,
    ],
  } = usePermission([
    MODULE_SHOP_NAVIGATION_RIGHTS.ADD_PRODUCT_TO_SHOPPING_CART,
    MODULE_SHOP_NAVIGATION_RIGHTS.PRODUCT_COMPARISION,
  ]);

  const { isIpadLandscape } = useDeviceDetect();
  const history = useHistory();

  const fakeItems = useDummyItems(5, {
    img: '',
    materialNumber: '',
    _name: '',
    listPrice: 0,
  });

  const handleCellClick = (materialNumber) => {
    return history.push(linkGenerator(`/product/${materialNumber}`));
  };

  const onCheckHandler = useCallback(
    (product, event) => {
      const isChecked = event?.target?.checked;
      const selectedProduct = {
        materialNumber: product.materialNumber,
        materialName: product.mediaName,
        categoryName: product.category,
        listPrice: product.price,
        currencyUnit: product.currency,
        pictureUrl: product.imageUrl,
      };
      onCompareHandler(isChecked, selectedProduct);
    },
    [onCompareHandler]
  );

  const renderColumns = () => {
    const currentTableColumns = listColumns(
      onAddProductToCart,
      handleCellClick,
      isLoading,
      onCheckHandler,
      comparingProductIds,
      hasAddProductToCartPermission,
      hasProductComparisonPermission
    );
    // Don't render the column of comaprison feature in mobile view
    return isIpadLandscape
      ? currentTableColumns.filter(
        (column) => column?.dataIndex !== 'comparison'
      )
      : currentTableColumns;
  };

  return (
    <>
      <B2becTable
        style={{ marginTop: 20 }}
        className="order-details-cart-table"
        columns={renderColumns()}
        dataSource={isLoading ? fakeItems : dataSrc}
        pagination={false}
        rowKey="materialNumber"
      />
      <ProductComparisonModal
        showModal={showComparisonModal}
        setShowModal={setShowComparisonModal}
        productsData={productInformation}
      />
    </>
  );
};

GridView.propTypes = {
  productsList: PropTypes.arrayOf(PropTypes.shape),
  onAddProductToCart: PropTypes.func,
};

GridView.defaultProps = {
  productsList: [],
  onAddProductToCart: () => { },
};

ListView.propTypes = {
  dataSrc: PropTypes.arrayOf(PropTypes.shape),
  isLoading: PropTypes.bool,
  onAddProductToCart: PropTypes.func,
};

ListView.defaultProps = {
  dataSrc: [],
  isLoading: false,
  onAddProductToCart: () => { },
};
