import './styles.scss';

import { Divider } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import ButtonsWrapperComponent from '../components/ButtonsWrapper';
import LowerSection from './LowerSection';
import MiddleSection from './MiddleSection';
import UpperSection from './UpperSection';

const StepThreeComponent = (props) => {
  const { form, tab, setTabHandler, submitFormHandler } = props;
  return (
    <div className="step-three__wrapper">
      <div className="order-overview__wrapper">
        <UpperSection form={form} submitFormHandler={submitFormHandler} />
        <div className="order-overview__divider fw-container">
          <Divider className="divider--thin" />
        </div>
        <MiddleSection />
        <LowerSection form={form} />
      </div>

      <div className="checkout__bottom-space" />
      <div className="fw-container">
        <ButtonsWrapperComponent
          tab={tab}
          setTabHandler={setTabHandler}
          submitFormHandler={submitFormHandler}
          form={form}
        />
      </div>
    </div>
  );
};

StepThreeComponent.propTypes = {
  form: PropTypes.shape({}).isRequired,
  tab: PropTypes.number,
  setTabHandler: PropTypes.func,
  submitFormHandler: PropTypes.func,
};
StepThreeComponent.defaultProps = {
  tab: 1,
  setTabHandler: () => {},
  submitFormHandler: () => {},
};

export default StepThreeComponent;
