import B2bLoadingFullPage from 'components/B2bLoadingFullPage';
import useDocumentTitle from 'hooks/useDocumentTitle';
import PropTypes from 'prop-types';
import React from 'react';

const PunchoutPage = ({ title }) => {
  useDocumentTitle(title);
  return <B2bLoadingFullPage />;
};

PunchoutPage.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PunchoutPage;
