import { Form, Input, Radio } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const { useForm } = Form;

const contactTypes = [
  { label: 'contactEmail', value: 'contactEmail' },
  { label: 'contactPhone', value: 'contactPhone' },
];

const useInitialProps = (values) => {
  const ref = React.useRef(null);

  if (values && ref.current === null) {
    ref.current = values;
  }

  return ref.current;
};

const EnquiryForm = ({
  initialValues: initialValuesFromProps,
  isLoading,
  form: initialForm,
}) => {
  const [form] = useForm(initialForm);
  const { t } = useTranslation();
  const [contactType, setContactType] = useState('');
  const initialValues = useInitialProps(initialValuesFromProps);

  const contactTypesTranslated = contactTypes.map(({ label, value }) => ({
    label: t(`enquiryModal.form.contactType.${label}`),
    value,
  }));

  const validateMessages = {
    required: t('enquiryModal.form.validateMesages.required'),
    types: {
      email: t('enquiryModal.form.validateMesages.email'),
      phone: t('enquiryModal.form.validateMesages.phone'),
    },
  };

  const renderContactFormItem = (name) => {
    const contactFormItemRules = [];
    const translatedLabel = t(`enquiryModal.form.contactType.${name}`);

    if (name !== '') {
      contactFormItemRules.push({
        required: true,
        message: t('enquiryModal.form.validateMesages.requiredCustom', {
          label: `${translatedLabel}`,
        }),
      });
    }

    if (name === 'contactEmail') {
      contactFormItemRules.push({ type: 'email' });
    }

    return (
      <Form.Item
        name={name}
        className="custom-form-item"
        rules={contactFormItemRules}
        messagevariables={{ translatedLabel }}
      >
        <Input
          placeholder={t('enquiryModal.form.contact.placeHolder')}
          disabled={isLoading}
        />
      </Form.Item>
    );
  };

  return (
    <Form
      className="enquiry-modal__form"
      layout="vertical"
      form={form}
      validateMessages={validateMessages}
      requiredMark={false}
      initialValues={initialValues}
    >
      <Form.Item
        name="companyName"
        label={t('enquiryModal.form.companyName.label')}
        className="custom-form-item custom-form-item--disabled"
      >
        <Input
          placeholder={t('enquiryModal.form.companyName.placeHolder')}
          disabled
        />
      </Form.Item>

      <Form.Item
        name="contactName"
        label={t('enquiryModal.form.contactName.label')}
        className="custom-form-item"
        rules={[{ required: true }]}
      >
        <Input
          placeholder={t('enquiryModal.form.contactName.placeHolder')}
          disabled={isLoading}
        />
      </Form.Item>

      <Form.Item
        name="contactType"
        label={t('enquiryModal.form.contactType.label')}
        className="custom-form-item"
        rules={[{ required: true }]}
      >
        <Radio.Group
          options={contactTypesTranslated}
          onChange={(e) => setContactType(e.target.value)}
          disabled={isLoading}
        />
      </Form.Item>

      {renderContactFormItem(contactType)}

      <Form.Item
        name="note"
        label={t('enquiryModal.form.note.label')}
        className="custom-form-item"
      >
        <TextArea
          rows={7}
          style={{ resize: 'none' }}
          placeholder={t('enquiryModal.form.maintainNote.placeholder')}
          disabled={isLoading}
        />
      </Form.Item>
    </Form>
  );
};

EnquiryForm.defaultProps = {
  isLoading: false,
  form: null,
  initialValues: null,
};

EnquiryForm.propTypes = {
  initialValues: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  form: PropTypes.shape({}),
};

export { EnquiryForm };
