import { Form } from 'antd';
import { useDeviceDetect } from 'hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SelectAddress from '../SelectAddress';
import SelectAddressMobile from '../SelectAddressMobile';

const DeliveryAddressSection = () => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isMobile } = useDeviceDetect();

  return (
    <div className="select-address__wrapper">
      <div className="select-address__label">
        {t('service.addressAndDate.pleaseSelect')}
      </div>

      <div className="select-address__group">
        <div className="select-address__group__label space-between">
          {t('service.serviceOverview.label.deliveryAddress')}
          {isMobile && (
            <button
              type="button"
              className="button-as-link"
              onClick={() => setIsModalVisible(true)}
            >
              {t('checkout.step1.allAddresses')}
            </button>
          )}
        </div>
        <Form.Item noStyle name="deliveryAddress">
          {isMobile ? (
            <SelectAddressMobile
              isModalVisible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
            />
          ) : (
            <SelectAddress />
          )}
        </Form.Item>
      </div>
    </div>
  );
};

export default React.memo(DeliveryAddressSection);
