import { orderServiceFAPI } from 'services';

import { hasEmptyElements } from './array';
import { isStringNotEmpty } from './stringUltis';
import JSZip from 'jszip';

export const downloadPDF = ({ fileName, file }) => {
  if (isStringNotEmpty(file) && isStringNotEmpty(fileName)) {
    const linkSource = `data:application/pdf;base64,${file}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
};

function base64toBlob(base64Data, contentType) {
  const sliceSize = 512;
  const data = base64Data.replace(/^[^,]+,/, '').replace(/\s/g, '');
  const byteCharacters = window.atob(data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType || '' });
}

export const downloadMultipleInvoicesZipFile = (dataArray) => {
  const zip = new JSZip();
  dataArray.forEach((item) => {
    zip.file(item.fileName, base64toBlob(item.file, item.contentType));
  });
  zip.generateAsync({ type: 'blob' }).then((content) => {
    const blobUrl = URL.createObjectURL(content);
    const downloadLink = document.createElement('a');

    downloadLink.href = blobUrl;
    downloadLink.download = 'Invoices.zip';
    downloadLink.click();
  });
};

export const getInvoices = (invoiceNumbers) => {
  if (!hasEmptyElements(invoiceNumbers)) {
    return orderServiceFAPI.getMultipleInvoicesByInvoiceNumbers(invoiceNumbers);
  }

  return Promise.reject();
};
