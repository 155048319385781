import { Radio } from 'antd';
import B2becAddressForm from 'components/B2becAddressForm';
import SearchAddress from 'pages/CheckoutPage/StepOneComponent/SearchAddress';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getDeliveryAddresses } from 'store/selectors/customerSelector';
import { selectUserCountry } from 'store/selectors/userSelector';

const SelectAddress = (props) => {
  const { value, onChange } = props;
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState(1);
  const deliveryAddresses = useSelector(getDeliveryAddresses);
  const country = useSelector(selectUserCountry);
  const [selectedPartnerNumber, setSelectedPartnerNumber] = useState(
    value?.partnerNumber
  );

  const handleSwitchRadio = useCallback(
    (e) => {
      const selected = e.target.value;
      if (selected === 1) {
        const selectedAddress = deliveryAddresses.find(
          (item) => item.partnerNumber === selectedPartnerNumber
        );
        onChange({ ...selectedAddress, isAddNew: false });
      } else {
        onChange({ country, isAddNew: true });
      }
      setRadioValue(selected);
    },
    [country, deliveryAddresses, onChange, selectedPartnerNumber]
  );

  const handleSelectAddress = useCallback(
    (selectedAddress) => {
      onChange({ ...selectedAddress, isAddNew: false });
      setSelectedPartnerNumber(selectedAddress.partnerNumber);
    },
    [onChange]
  );

  return (
    <div>
      <Radio.Group
        className="custom-input select-address__group__label"
        style={{ width: '100%' }}
        defaultValue={1}
        onChange={handleSwitchRadio}
      >
        <Radio key={1} value={1}>
          <span className="mt-6 mb-4 delivery__option__title">
            {t('form.label.myAddress')}
          </span>
        </Radio>
        <Radio key={2} value={2}>
          <span className="mt-6 mb-4 delivery__option__title">
            {t('form.label.newAddress')}
          </span>
        </Radio>
      </Radio.Group>
      {radioValue === 2 ? (
        <B2becAddressForm id="deliveryAddress" />
      ) : (
        <SearchAddress
          value={value}
          onChange={handleSelectAddress}
          dataSource={deliveryAddresses}
        />
      )}
    </div>
  );
};

export default SelectAddress;

SelectAddress.propTypes = {
  value: PropTypes.shape({ partnerNumber: PropTypes.string }),
  onChange: PropTypes.func,
};

SelectAddress.defaultProps = {
  value: {
    partnerNumber: '',
    isAddNew: false,
  },
  onChange: () => {},
};
