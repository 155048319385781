import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import styles from './GroupFilter.module.scss';

function GroupFilter(props) {
  const { groups, onChange, value: propValue } = props;
  const selected = (
    groups.find((group) => group.key === propValue) ||
    groups[0] ||
    {}
  ).key;
  const [selectedGroup, setSelectedGroup] = useState(selected);
  const onGroupSelected = (selectedKey) => {
    setSelectedGroup(selectedKey);
    onChange(selectedKey);
  };

  return (
    <div className={styles.wrapper}>
      {groups.map((group) => (
        <button
          className={clsx(
            styles.button,
            group.key === selectedGroup && styles.selected
          )}
          key={group.key}
          type="button"
          onClick={() => onGroupSelected(group.key)}
        >
          {group.name}
        </button>
      ))}
    </div>
  );
}

GroupFilter.propTypes = {
  value: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
};

GroupFilter.defaultProps = {
  value: undefined,
  onChange: () => {},
};

export default GroupFilter;
