import { replaceSpaceWithCamelCase } from 'libs/utils/adobeAnalysis';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCountryName } from 'store/selectors/configSelector';

const useGetCountryName = (countryCode = '') => {
  const { t } = useTranslation();
  const countryName = useSelector((state) =>
    selectCountryName(state, countryCode)
  );

  return countryName
    ? t(`country.${replaceSpaceWithCamelCase(countryName)}`)
    : countryCode || 'N/A';
};

export default useGetCountryName;
