import { nanoid } from '@reduxjs/toolkit';
import { NotificationManager } from 'components/Notification';
import { isValidArray } from 'libs/utils/array';
import { put, takeLatest } from 'redux-saga/effects';
import { machineService } from 'services';
import {
  getMachinesList,
  getMachinesListFailed,
  getMachinesListSucceeded,
} from 'store/slices/machineSlice';

function generateItemKeys(items) {
  if (isValidArray(items)) {
    return items.map((item) => ({
      ...item,
      key: nanoid(),
    }));
  }

  return [];
}

function* getMachinesListSaga({ payload }) {
  try {
    const { data } = yield machineService.getMachinesList(payload);
    const { items } = data;

    const transformedItems = generateItemKeys(items);
    const transformedData = {
      ...data,
      items: transformedItems,
    };

    yield put(getMachinesListSucceeded(transformedData));
  } catch (error) {
    yield put(getMachinesListFailed());
    console.error('error when retriving machines list: ', error);
    NotificationManager.error({
      message: 'notification.error.load',
    });
  }
}

function* machineSaga() {
  yield takeLatest(getMachinesList.type.toString(), getMachinesListSaga);
}

export default machineSaga;
