import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

const B2becPageTitle = ({ children }) => {
  return (
    <div className="full-width-page-component__wrapper">
      <div className="b2bec-page-title__wrapper b2bec-page-title__wrapper--grey">
        <div className="b2bec-page-title__header">{children}</div>
      </div>
    </div>
  );
};

B2becPageTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default B2becPageTitle;
