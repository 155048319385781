import './styles.scss';

import { Form, Input } from 'antd';
import Translator from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { adminFapiContactReason } from 'services/adminFapi';

import B2becCustomSelect from '../../../../../components/B2becCustomSelect';
import { CustomFormItem } from '../../../../../components/B2bUserForm';
import { notificationComponent } from '../../../../../components/Notification';
import useAsync from '../../../../../hooks/useAsync';
import useModal from '../../../../../hooks/useModal';
import { ASYNC_STATUS, STATUS_CODE } from '../../../../../libs/constants';
import { NOTIFICATION_TYPE } from '../../../../../libs/constants/notification';
import {
  getInvalidPropTypesError,
  getRequiredPropTypesError,
} from '../../../../../libs/utils/propTypes';

function ContactReasonModal(props) {
  const {
    isEdit,
    editData,
    isShow,
    onClose,
    contactReasons,
    onSubmitSuccess,
    isDelete,
    deleteData,
    onCloseDeleteModal,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { Modal, toggleModal } = useModal();

  useEffect(() => {
    if (!isShow) {
      form.resetFields(['receiverAddress']);
    }
    toggleModal(isShow);
  }, [isShow, form, contactReasons, toggleModal]);

  const validateMessages = {
    required: (label = '') => t('form.validate.required', { label }),
  };

  const addContactReason = useCallback(
    (contactReasonId, contactReasonNumber, receiverAddress) =>
      adminFapiContactReason.addContactReason(
        contactReasonId,
        contactReasonNumber,
        receiverAddress
      ),
    []
  );

  const editContactReason = useCallback(
    (contactReasonId, contactReasonNumber, receiverAddress) =>
      adminFapiContactReason.editContactReason(
        contactReasonId,
        contactReasonNumber,
        receiverAddress
      ),
    []
  );

  const deleteContactReason = useCallback(
    (contactReasonId) =>
      adminFapiContactReason.deleteContactReason(contactReasonId),
    []
  );

  const onAddSuccess = useCallback(
    (response) => {
      if (response?.status === STATUS_CODE.SUCCESS) {
        onSubmitSuccess(form.getFieldsValue());
        notificationComponent({
          type: NOTIFICATION_TYPE.SUCCESS,
          message: t('notification.success.create'),
        });
        onClose();
      }
    },
    [onSubmitSuccess, form, t, onClose]
  );

  const onAddError = useCallback(
    (error) => {
      notificationComponent({
        type: NOTIFICATION_TYPE.ERROR,
        message: t('notification.error.create'),
        description: error?.message,
      });
    },
    [t]
  );

  const onEditSuccess = useCallback(
    (response) => {
      if (response?.status === STATUS_CODE.SUCCESS) {
        const fieldsValue = form.getFieldsValue();
        onSubmitSuccess(fieldsValue);
        notificationComponent({
          type: NOTIFICATION_TYPE.SUCCESS,
          message: t('notification.success.update'),
        });
        onClose(fieldsValue.contactReason);
      }
    },
    [onSubmitSuccess, form, t, onClose]
  );

  const onEditError = useCallback(
    (error) => {
      notificationComponent({
        type: NOTIFICATION_TYPE.ERROR,
        message: t('notification.error.update'),
        description: error?.message,
      });
    },
    [t]
  );

  const onDeleteSuccess = useCallback(
    (response) => {
      if (response?.status === STATUS_CODE.SUCCESS) {
        onSubmitSuccess(deleteData);
        notificationComponent({
          type: NOTIFICATION_TYPE.SUCCESS,
          message: t('notification.success.delete'),
        });
        onCloseDeleteModal();
      }
    },
    [t, onCloseDeleteModal, deleteData, onSubmitSuccess]
  );

  const onDeleteError = useCallback(
    (error) => {
      notificationComponent({
        type: NOTIFICATION_TYPE.ERROR,
        message: t('notification.error.delete'),
        description: error?.message,
      });
    },
    [t]
  );

  const { execute: executeAddContactReason, status: addContactReasonStatus } =
    useAsync(addContactReason, false, onAddSuccess, onAddError);

  const { execute: executeEditContactReason, status: editContactReasonStatus } =
    useAsync(editContactReason, false, onEditSuccess, onEditError);

  const {
    execute: executeDeleteContacReason,
    status: deleteContactReasonStatus,
  } = useAsync(deleteContactReason, false, onDeleteSuccess, onDeleteError);

  const isAdding = addContactReasonStatus === ASYNC_STATUS.PENDING;
  const isEditing = editContactReasonStatus === ASYNC_STATUS.PENDING;
  const isDeleting = deleteContactReasonStatus === ASYNC_STATUS.PENDING;

  const onSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        const {
          contactReason: contactReasonId,
          contactReasonNumber,
          receiverAddress,
        } = values;

        if (isEdit) {
          executeEditContactReason(
            contactReasonId,
            contactReasonNumber,
            receiverAddress
          );
        } else {
          executeAddContactReason(
            contactReasonId,
            contactReasonNumber,
            receiverAddress
          );
        }
      })
      .catch((info) => {
        console.info('Validate Failed:', info);
      });
  }, [form, isEdit, executeEditContactReason, executeAddContactReason]);

  const onDelete = useCallback(
    () => executeDeleteContacReason(deleteData?.contactReasonId),
    [executeDeleteContacReason, deleteData]
  );

  const onKeyUp = (event) => {
    if (
      event.key === 'Enter' ||
      event.code === 'Enter' ||
      event.keyCode === 13 ||
      event.which === 13
    ) {
      if (isDelete) {
        onDelete();
      } else {
        onSubmit();
      }
    }
  };

  const onKeyDown = useCallback(
    (event) => {
      if (
        event.key === 'Escape' ||
        event.code === 'Escape' ||
        event.keyCode === 27 ||
        event.which === 27
      ) {
        if (isDelete) {
          onCloseDeleteModal();
        } else {
          onClose();
        }
      }
    },
    [onClose, onCloseDeleteModal, isDelete]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onKeyDown]);

  const renderModalAction = () => (
    <div className="contact-reason-management__add-modal__action">
      <CustomButton type="ghost" onClick={onClose}>
        {t('buttonTexts.cancel')}
      </CustomButton>
      <CustomButton loading={isAdding || isEditing} onClick={onSubmit}>
        {isEdit
          ? t('contactReasonManagement.modal.edit.title')
          : t('contactReasonManagement.modal.add.title')}
      </CustomButton>
    </div>
  );

  const extractContactReasonNumber = useCallback((keyName) => {
    const foundMatches = keyName?.match(/\d+$/);
    if (Array.isArray(foundMatches) && foundMatches.length > 0) {
      return Number(foundMatches[0]);
    }
    return 0;
  }, []);

  const dropdownOptions = useMemo(
    () =>
      contactReasons
        .sort((a, b) => {
          // Sort by last digits in key name in ascending order
          const numberA = extractContactReasonNumber(a.contactReasonKeyName);
          const numberB = extractContactReasonNumber(b.contactReasonKeyName);
          if (numberA < numberB) {
            return -1;
          }
          if (numberA > numberB) {
            return 1;
          }
          return 0;
        })
        .map((item) => ({
          label: item?.translationContent || item.contactReasonKeyName,
          value: item.contactReasonId,
        })),
    [extractContactReasonNumber, contactReasons]
  );

  const onSelectContactReason = (contactReasonId) => {
    const foundItem = contactReasons.find(
      (item) => item.contactReasonId === contactReasonId
    );
    // Set contact reason number for submitting
    form.setFieldsValue({
      contactReasonNumber: extractContactReasonNumber(
        foundItem?.contactReasonKeyName
      ),
    });
  };

  const handleCloseDeleteModal = useCallback(() => {
    onCloseDeleteModal();
  }, [onCloseDeleteModal]);

  const renderDeleteModalAcion = () => (
    <div className="contact-reason-management__add-modal__action">
      <CustomButton type="ghost" onClick={handleCloseDeleteModal}>
        {t('buttonTexts.cancel')}
      </CustomButton>
      <CustomButton loading={isDeleting} onClick={onDelete}>
        {t('buttonTexts.delete')}
      </CustomButton>
    </div>
  );

  useEffect(() => {
    // Set initial default values
    if (
      isShow &&
      contactReasons[0]?.contactReasonId &&
      contactReasons[0]?.contactReasonKeyName
    ) {
      form.setFieldsValue({
        contactReason: contactReasons[0]?.contactReasonId,
        contactReasonNumber: extractContactReasonNumber(
          contactReasons[0]?.contactReasonKeyName
        ),
      });
    } else if (isShow && contactReasons.length === 0) {
      form.setFieldsValue({
        contactReason: undefined,
        contactReasonNumber: undefined,
      });
    }
  }, [isShow, form, contactReasons, extractContactReasonNumber]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        contactReason: editData?.contactReasonId,
        contactReasonNumber: editData?.contactReasonNumber,
        receiverAddress: editData?.receiverAddress,
      });
    } else if (!isDelete) {
      form.resetFields(['receiverAddress']);
    }
  }, [isEdit, form, editData, isDelete]);

  return isDelete ? (
    <Modal
      className="contact-reason-management__delete-modal"
      title={t('contactReasonManagement.modal.delete.title')}
      width={570}
      closable={false}
      footer={renderDeleteModalAcion()}
      getContainer={false}
    >
      <div>
        <Translator
          value="contactReasonManagement.modal.delete.content"
          variables={{ contactReasonName: deleteData?.translationContent }}
          isComponent
          htmlTags={[<br />]}
        />
      </div>
    </Modal>
  ) : (
    <Form
      className="contact-reason-management__add-modal__form"
      layout="vertical"
      form={form}
      validateMessages={validateMessages}
      scrollToFirstError
      onKeyUp={onKeyUp}
    >
      <Modal
        className="contact-reason-management__add-modal"
        title={
          isEdit
            ? t('contactReasonManagement.modal.edit.title')
            : t('contactReasonManagement.modal.add.title')
        }
        width={570}
        closable={false}
        footer={renderModalAction()}
        getContainer={false}
      >
        {isEdit
          ? t('contactReasonManagement.modal.edit.content')
          : t('contactReasonManagement.modal.add.content')}
        <Form.Item name="contactReasonNumber" hidden>
          <Input disabled />
        </Form.Item>
        <div id="contact-reason-modal__select">
          <CustomFormItem
            className="contact-reason-management__add-modal__form__contact-reason"
            name="contactReason"
            rules={[{ required: true }]}
          >
            <B2becCustomSelect
              options={dropdownOptions}
              onChange={onSelectContactReason}
              disabled={isEdit}
              getPopupContainer={() =>
                document.getElementById('contact-reason-modal__select')
              }
            />
          </CustomFormItem>
        </div>

        <CustomFormItem
          className="contact-reason-management__add-modal__form__receiver-address"
          name="receiverAddress"
          rules={[
            { required: true },
            {
              type: 'email',
              message: t('checkout.submitErrors.invalidEmail'),
            },
          ]}
        >
          <Input />
        </CustomFormItem>
      </Modal>
    </Form>
  );
}

ContactReasonModal.propTypes = {
  isDelete: PropTypes.bool,
  isEdit: PropTypes.bool,
  // deleteData: PropTypes.shape({
  //   contactReasonId: PropTypes.string.isRequired.isRequired,
  //   translationContent: PropTypes.string.isRequired.isRequired,
  // }),
  deleteData: (props, propName, componentName) => {
    const { isDelete, [propName]: deleteData } = props;
    const deleteDataTypes = {
      contactReasonId: 'string',
      translationContent: 'string',
    };

    if (isDelete) {
      if (!deleteData) {
        getRequiredPropTypesError(deleteData, propName, componentName);
      }
      if (typeof deleteData !== 'object' || Array.isArray(deleteData)) {
        return getInvalidPropTypesError(
          deleteData,
          propName,
          componentName,
          'object'
        );
      }
      // Validate properties of deleteData object
      const validation = {
        required: { error: false, key: undefined },
        type: { error: false, key: undefined },
      };
      Object.entries(deleteDataTypes).forEach(([key, value]) => {
        if (
          validation.required.error === false &&
          // eslint-disable-next-line no-prototype-builtins
          deleteData.hasOwnProperty(key) &&
          !deleteData[key]
        ) {
          validation.required.error = true;
          validation.required.key = key;
        }
        if (
          validation.type.error === false &&
          // eslint-disable-next-line no-prototype-builtins
          deleteData.hasOwnProperty(key) &&
          // eslint-disable-next-line valid-typeof
          typeof deleteData[key] !== value
        ) {
          validation.type.error = true;
          validation.type.key = key;
        }
      });
      if (validation.required.error) {
        return getRequiredPropTypesError(
          deleteData[validation.required.key],
          `deleteData.${validation.required.key}`,
          componentName
        );
      }
      if (validation.type.error) {
        return getInvalidPropTypesError(
          deleteData[validation.type.key],
          `deleteData.${validation.type.key}`,
          componentName,
          deleteDataTypes[validation.type.key]
        );
      }
    }
  },
  // editData: PropTypes.shape({
  //   contactReasonId: PropTypes.string.isRequired,
  //   contactReasonNumber: PropTypes.string.isRequired,
  //   receiverAddress: PropTypes.string.isRequired,
  // }),
  editData: (props, propName, componentName) => {
    const { isEdit, [propName]: editData } = props;
    const editDataTypes = {
      contactReasonId: 'string',
      contactReasonNumber: 'string',
      receiverAddress: 'string',
    };
    // ONLY TRIGGER VALIDATIONS ON EDITING MODE
    if (isEdit) {
      if (!editData) {
        return getRequiredPropTypesError(editData, propName, componentName);
      }
      if (typeof editData !== 'object' || Array.isArray(editData)) {
        return getInvalidPropTypesError(
          editData,
          propName,
          componentName,
          'object'
        );
      }
      // Validate properties of editData object
      const validation = {
        required: { error: false, key: undefined },
        type: { error: false, key: undefined },
      };
      Object.entries(editDataTypes).forEach(([key, value]) => {
        if (
          validation.required.error === false &&
          // eslint-disable-next-line no-prototype-builtins
          editData.hasOwnProperty(key) &&
          !editData[key]
        ) {
          validation.required.error = true;
          validation.required.key = key;
        }
        if (
          validation.type.error === false &&
          // eslint-disable-next-line no-prototype-builtins
          editData.hasOwnProperty(key) &&
          // eslint-disable-next-line valid-typeof
          typeof editData[key] !== value
        ) {
          validation.type.error = true;
          validation.type.key = key;
        }
      });
      if (validation.required.error) {
        return getRequiredPropTypesError(
          editData[validation.required.key],
          `editData.${validation.required.key}`,
          componentName
        );
      }
      if (validation.type.error) {
        return getInvalidPropTypesError(
          editData[validation.type.key],
          `editData.${validation.type.key}`,
          componentName,
          editDataTypes[validation.type.key]
        );
      }
    }
    return null;
  },
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  contactReasons: PropTypes.arrayOf(
    PropTypes.shape({
      contactReasonId: PropTypes.string.isRequired,
      contactReasonKeyName: PropTypes.string.isRequired,
      translationId: PropTypes.string.isRequired,
      translationContent: PropTypes.string,
    })
  ).isRequired,
  onSubmitSuccess: PropTypes.func,
  onCloseDeleteModal: PropTypes.func,
};

ContactReasonModal.defaultProps = {
  isDelete: false,
  isEdit: false,
  editData: {},
  isShow: false,
  onClose: () => {},
  onSubmitSuccess: () => {},
  deleteData: {},
  onCloseDeleteModal: () => {},
};

export default ContactReasonModal;
