import BackToTopButton from 'components/BackToTopButton';
import Footer from 'components/Layout/components/Footer';
import Content from 'components/PublicHeader/Content';
import Header from 'components/PublicHeader/Header';
import Headline from 'components/PublicHeader/Headline';
import ScrollToTop from 'components/ScrollToTop';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import Introduction from '../components/Introduction';
import SelfRegistrationForm from '../components/SelfRegistrationForm';

const SelfRegistrationPage = (props) => {
  const { title, pageId } = props;
  useDocumentTitle(title);

  const { userId } = useParams();

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'self-registration',
    pageType: PAGE_TYPES.REGISTRATION,
  });

  return (
    <div className="registration-page__wrapper">
      <Header />
      <ScrollToTop />
      <Headline />
      <Introduction />
      <Content className="registration-page__divider__wrapper">
        <div className="registration-page__divider" />
      </Content>
      <SelfRegistrationForm userId={userId} />
      <Footer />
      <BackToTopButton />
    </div>
  );
};

export default SelfRegistrationPage;

SelfRegistrationPage.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};
