import { Col, Row } from 'antd';
import FormatPrice from 'components/FormatPrice';
import { DeleteBorderlessIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ProductComparisonCard(props) {
  const { productList, hasExtraProduct, removeItemFunc } = props;
  const { t } = useTranslation();

  const renderContent = () => {
    if (productList.length === 1) {
      const {
        categoryName,
        image,
        materialName,
        listPrice,
        currencyUnit,
        displayListPrice,
      } = productList[0];

      return (
        <div className="product-comparison-card__wrapper--single">
          <Row className="fadeIn-2">
            <Col
              className="product-comparison-card__img"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
            >
              <img src={image} alt={materialName || ''} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="product-comparison-card__main-content"
            >
              <h4>{categoryName}</h4>
              <h3>{materialName}</h3>
              <div className="product-comparison-card__list-price">
                <div className="product-comparison-card__list-price__left">
                  {t('productDetails.priceBox.listingPrice')}
                </div>
                {
                  // Todo: handle later when the price is 'CHF 8.5'
                }
                <div className="product-comparison-card__list-price__right">
                  {displayListPrice && listPrice ? (
                    <FormatPrice
                      currencyUnit={currencyUnit}
                      price={listPrice}
                    />
                  ) : (
                    t('productCard.onRequest')
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (productList.length > 1) {
      return (
        <div className="product-comparison-card__wrapper--multi">
          <Row gutter={50}>
            {productList.map((product) => (
              <Col
                className="fadeIn-2 product-comparison-card__wrapper"
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                key={`${product.materialName}-${product.categoryName}`}
              >
                <div className="product-comparison-card__img">
                  <img src={product?.image} alt={product.materialName} />
                </div>
                <h4 className="product-comparison-card__category-name--multi">
                  {product?.categoryName}
                </h4>
                <h3 className="product-comparison-card__name--multi">
                  {product?.materialName}
                </h3>
                {hasExtraProduct === true ? (
                  <button
                    type="button"
                    className="remove-product-btn"
                    onClick={() => removeItemFunc(product.materialNumber)}
                  >
                    <DeleteBorderlessIcon className="remove-cart-item__icon" />{' '}
                    {t('cart.removeItem')}
                  </button>
                ) : (
                  ''
                )}
              </Col>
            ))}
          </Row>
        </div>
      );
    }
    return '';
  };

  return <>{renderContent()}</>;
}

ProductComparisonCard.propTypes = {
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string,
      materialName: PropTypes.string,
      materialNumber: PropTypes.string,
      listPrice: PropTypes.number,
      currencyUnit: PropTypes.string,
      image: PropTypes.string,
      displayListPrice: PropTypes.bool,
    })
  ),
  hasExtraProduct: PropTypes.bool,
  removeItemFunc: PropTypes.func,
};

ProductComparisonCard.defaultProps = {
  productList: [],
  hasExtraProduct: false,
  removeItemFunc: () => {},
};

export default ProductComparisonCard;
