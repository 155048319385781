import { createSlice } from '@reduxjs/toolkit';

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState: {
    // Eg. breadcrumbs = [{ title: 'Home', path: ['/'] }]
    breadcrumbs: [],
  },
  reducers: {
    updateBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
  },
});

export const { updateBreadcrumbs } = breadcrumbSlice.actions

export default breadcrumbSlice.reducer;
