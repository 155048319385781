import B2becInfoLayout from 'components/B2becInfoLayout';
import { useBreadcrumbs, useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { useSynchronizeOrdersForGivenUserId } from 'pages/Admin/SupportArea/hooks';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddToCart } from './AddToCart';
import { Cart } from './Cart';
import Header from './Header';
import {
  handleAddToCartSupport,
  handleClearCacheSupport,
  handleDeleteCartSupport,
} from './hooks/supportHandler';
import { MachinesList } from './MachinesList';
import { OrderDetails } from './OrderDetails';
import { OrderList } from './OrderList';
import styles from './SupportArea.module.scss';

function SupportArea({ title, breadcrumbs, pageId }) {
  useBreadcrumbs(breadcrumbs);
  useDocumentTitle(title);
  const { t } = useTranslation();
  const inputRef = useRef();
  const materialNumberInputRef = useRef();
  const [selectedOpt, setSelectedOpt] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');
  const [userId, setUserId] = useState('');
  const [countryId, setCountryId] = useState('');
  const shouldShowOrderHistory = countryId && customerNumber;
  const { executeSynchronizeOrders, isSynchronizing } =
    useSynchronizeOrdersForGivenUserId(userId);

  useAdobeAnalysis(null, {
    pageType: PAGE_TYPES.STANDARD,
    pageId,
    pageName: 'Support Area',
  });

  const handleRun = (selectedOption) => {
    setSelectedOpt(selectedOption.option);
    setCustomerNumber(selectedOption.customerNumber);
    setUserId(selectedOption.userId);
    setCountryId(selectedOption.countryId);

    switch (selectedOption.option) {
      case 'cache': {
        if (selectedOption.countryId) {
          handleClearCacheSupport(selectedOption.countryId);
        }
        break;
      }
      case 'deleteCart': {
        if (selectedOption.userId) {
          handleDeleteCartSupport(selectedOption.userId);
        }
        break;
      }
      case 'orderDetails': {
        if (inputRef.current?.handleGet) {
          inputRef.current.handleGet();
        }
        break;
      }
      case 'addToCart': {
        if (materialNumberInputRef.current) {
          const materialNumber = materialNumberInputRef.current;
          handleAddToCartSupport(
            materialNumber,
            selectedOption.countryId,
            selectedOption.userId
          );
        }
        break;
      }
      case 'synchronizeOrders': {
        if (selectedOption.userId) {
          executeSynchronizeOrders(selectedOption.userId);
        }
        break;
      }
      default: {
        // TODO
      }
    }
  };

  const renderComponent = useCallback(
    (cusNumber) => {
      switch (selectedOpt) {
        case 'machines': {
          return <MachinesList userId={userId} />;
        }
        case 'orders': {
          return (
            shouldShowOrderHistory && (
              <OrderList countryId={countryId} customerNumber={cusNumber} />
            )
          );
        }
        case 'cart': {
          return <Cart userId={userId} countryId={countryId} />;
        }
        default:
          return <></>;
      }
    },
    [countryId, selectedOpt, shouldShowOrderHistory, userId]
  );

  return (
    <div className={styles.wrapper}>
      <B2becInfoLayout>
        <B2becInfoLayout.Title title={t('pageTitle.supportArea')} />
        <Header onRun={handleRun} isSynchronizing={isSynchronizing} />
        {customerNumber && renderComponent(customerNumber)}
        {selectedOpt === 'orderDetails' && (
          <OrderDetails userId={userId} countryId={countryId} ref={inputRef} />
        )}
        {selectedOpt === 'addToCart' && (
          <AddToCart ref={materialNumberInputRef} />
        )}
      </B2becInfoLayout>
    </div>
  );
}

SupportArea.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default SupportArea;
