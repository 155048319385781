import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import styles from './EmployeeInformation.module.scss';

const getValue = (source, key) => {
  const { isLoading } = source;
  if (isLoading) {
    return <Skeleton width="90%" />;
  }

  return source[key] || 'N/A';
};

const EmployeeInformation = (props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.employeeInformation}>
      <h2>
        {t(
          'adminWorkspace.userManagement.userDetails.userInformationSection.title'
        )}
      </h2>
      <div className={styles.userData}>
        <strong>{getValue(props, 'displayName')}</strong>
        <span>{getValue(props, 'email')}</span>
        <span>{getValue(props, 'phoneNumber')}</span>
      </div>
      <div className={styles.userData}>
        <strong>
          {t(
            'adminWorkspace.userManagement.userDetails.userInformationSection.roleAssigned'
          )}
        </strong>
        <span>{getValue(props, 'userPolicy')}</span>
      </div>
      <div className={styles.userData}>
        <strong>
          {t(
            'adminWorkspace.userManagement.userDetails.userInformationSection.country'
          )}
        </strong>
        <span>{getValue(props, 'country')}</span>
      </div>
      <div className={styles.userData}>
        <strong>
          {t(
            'adminWorkspace.userManagement.userDetails.userInformationSection.language'
          )}
        </strong>
        <span>{getValue(props, 'language')}</span>
      </div>
    </div>
  );
};

export default EmployeeInformation;
