import './styles.scss';

import CustomButton from 'components/CustomButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ConfirmModal from '../../../components/ConfirmModal';

const ActionButtonWithModal = (props) => {
  const {
    title,
    description,
    isLoading,
    isDisabled,
    buttonName,
    modalTitle,
    modalContent,
    onConfirmAction,
    className,
  } = props;

  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <section className={`action__wrapper ${className}`}>
      <div className="action__title">{title}</div>
      <div className="action__description">{description}</div>

      <CustomButton
        className="action__btn"
        onClick={() => setIsShowModal(true)}
        loading={isLoading}
        disabled={isDisabled}
      >
        {buttonName}
      </CustomButton>

      <ConfirmModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        modalTitle={modalTitle}
        modalContent={modalContent}
        confirmActionHandler={onConfirmAction}
      />
    </section>
  );
};

ActionButtonWithModal.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonName: PropTypes.string,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  onConfirmAction: PropTypes.func,
  className: PropTypes.string,
};

ActionButtonWithModal.defaultProps = {
  isLoading: false,
  isDisabled: false,
  title: '',
  description: '',
  buttonName: '',
  modalTitle: '',
  modalContent: '',
  className: null,
  onConfirmAction: () => {},
};

export default React.memo(ActionButtonWithModal);
