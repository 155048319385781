import { NotificationManager } from 'components/Notification';
import { put, takeLatest } from 'redux-saga/effects';
import { adminSAPlog } from 'services/fapi/adminFapi';
import {
  getSAPLog,
  getSAPLogError,
  getSAPLogSuccess,
} from 'store/slices/SAPLogSlice';

function* getSapLogSaga() {
  try {
    const response = yield adminSAPlog.getSapLogRequest();
    yield put(getSAPLogSuccess(response.data));
  } catch (error) {
    yield put(getSAPLogError());
    NotificationManager.error({
      message: 'notification.error.load',
    });
  }
}

export default function* cartSaga() {
  yield takeLatest(getSAPLog.type.toString(), getSapLogSaga);
}
