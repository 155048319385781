import { Input } from 'antd';
import { SearchIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsSearchingProduct } from 'store/selectors/portfolioSelector';

import styles from './SearchBar.module.scss';

const { Search } = Input;

const SearchBar = ({
  searchTerm,
  onSearchTermChange,
  onSearchClick,
  ...rest
}) => {
  const { t } = useTranslation();

  const isSearching = useSelector(selectIsSearchingProduct);

  const handleSearch = (value) => {
    onSearchClick(value);
  };

  return (
    <Search
      {...rest}
      onChange={onSearchTermChange}
      onSearch={handleSearch}
      allowClear
      bordered
      size="large"
      loading={isSearching}
      className="custom-search-input"
      value={searchTerm}
      placeholder={t('buttonTexts.search')}
      enterButton={t('userManagement.search')}
      prefix={<SearchIcon className={styles['prefix-icon']} />}
    />
  );
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
};

export default SearchBar;
