import './styles.scss';

import { Col, Row } from 'antd';
import missingImage from 'assets/img/missing_image.webp';
import SparePartsImage from 'assets/img/spare-parts-overview-page.webp';
import clsx from 'clsx';
import B2BecLink from 'components/B2BLink';
import NotFound from 'components/NotFound';
import B2becCardSkeleton from 'components/Skeletons/B2becCardSkeleton';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDeviceDetect from 'hooks/useDeviceDetect';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ACTION_STATUS, SHOP_CATEGORY_TYPE } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import BREAKPOINTS from 'libs/constants/breakpoints';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  getIsLoadingSubmenuProductCategoryList,
  getSubmenuProductCategoryFetchStatus,
  selectSubmenuProductCategories,
} from 'store/selectors/categorySelector';
import { selectShowSparePartLink } from 'store/selectors/userSelector';

import PermissionWrapper from '../../../HOCs/permissionWrapper';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../libs/constants/modulerights';
import CategoryItem from './CategoryItem';
import categoryOverviewData from './categoryOverviewData.json';

function CategoryOverviewPage(props) {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const history = useHistory();

  const { type = SHOP_CATEGORY_TYPE?.MACHINES } = useParams();
  const productTypeLinkPrefix =
    type === SHOP_CATEGORY_TYPE?.MACHINES ? '' : `/${type}`;

  useEffect(() => {
    // check if type is not one of known category types
    if (type && !Object.values(SHOP_CATEGORY_TYPE).includes(type)) {
      history.push('/404page');
    }
  }, [type, history]);

  const { title, breadcrumbs, pageId } = props;
  const titleData = [];
  const breadcrumbsData = [];
  if (type !== SHOP_CATEGORY_TYPE.MACHINES) {
    titleData.push(t(`pageTitle.${type}`));
    breadcrumbsData.push({
      titleData: [t(`breadcrumbTitle.${type}`)],
      pathData: [type],
    });
  }
  const shouldShowSparePart = useSelector(selectShowSparePartLink);

  useDocumentTitle(title, titleData);
  useBreadcrumbs(breadcrumbs, breadcrumbsData);

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'product categories',
    pageType: PAGE_TYPES.CATEGORIES,
  });

  const deviceWidth = window.innerWidth;

  const isLoading = useSelector(getIsLoadingSubmenuProductCategoryList);
  const fetchStatus = useSelector(getSubmenuProductCategoryFetchStatus);
  const productCategories = useSelector(selectSubmenuProductCategories);

  const [machines, accessories, detergents] = productCategories?.map(
    ({ categoryId }) => categoryId
  );

  const CATEGORY_ID = useMemo(
    () => ({
      machines,
      accessories,
      detergents,
    }),
    [accessories, detergents, machines]
  );

  const categoryData = useMemo(() => {
    const id = CATEGORY_ID[type];

    return productCategories.find(({ categoryId }) => categoryId === id);
  }, [CATEGORY_ID, productCategories, type]);

  const selectImageBasedOnScreenWidth = useCallback(
    (imageArray) => {
      let imgType = 'thumb';

      if (deviceWidth >= BREAKPOINTS.xl) {
        imgType = 'full';
      } else if (deviceWidth >= BREAKPOINTS.lg) {
        imgType = 'large';
      } else if (deviceWidth >= BREAKPOINTS.md) {
        imgType = 'medium';
      } else if (deviceWidth >= BREAKPOINTS.sm) {
        imgType = 'small';
      }

      return imageArray?.find((item) => item.type === imgType);
    },
    [deviceWidth]
  );

  return fetchStatus === ACTION_STATUS.FAILED ? (
    <NotFound />
  ) : (
    <div
      className={clsx('category-overview__wrapper', {
        'pt-3': !isMobile,
      })}
    >
      <div className="container-full-width-page category-overview__heading">
        <div className="category-overview__heading__container">
          <h1 className="category-overview__title mb-5 mt-5">
            {t(`productCategoryOverview.${type}.title`)}
          </h1>
          <p className="category-overview__description mb-5">
            {t(`productCategoryOverview.${type}.description`)}
          </p>
        </div>
      </div>
      {isLoading ? (
        <B2becCardSkeleton imageHeight={155.88} number={8} />
      ) : (
        <>
          <Row gutter={[16, 32]}>
            {categoryData?.subCategories.map((category, index) => {
              let imageSrc;

              // update static image for machine's 7th category
              if (type === SHOP_CATEGORY_TYPE?.MACHINES && index === 7) {
                imageSrc = missingImage;
              } else {
                imageSrc = selectImageBasedOnScreenWidth(category?.images)?.url;
              }

              return category?.categoryName && category?.categoryId ? (
                <Col key={category.categoryId} span={24} md={12} lg={8} xl={6}>
                  <CategoryItem
                    url={`${productTypeLinkPrefix}/category/${category.categoryId}`}
                    imgUrl={imageSrc}
                    imgDescription={category.categoryName}
                    title={category.categoryName}
                    subCategories={
                      categoryData.categoryId !== CATEGORY_ID.machines
                        ? []
                        : category.subCategories
                    }
                    categoryId={category.categoryId}
                  />
                </Col>
              ) : null;
            })}
          </Row>
          {type === SHOP_CATEGORY_TYPE?.MACHINES ? (
            <>
              <h2 className="category-overview__middle-section__title mb-5 mt-6">
                {t('productCategoryOverview.middleSection.title')}
              </h2>
              <Row gutter={[16, 32]}>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <B2BecLink to="/categories/accessories">
                    <img
                      className="category-overview__middle-section__image"
                      src={categoryOverviewData.accessories.imgUrl}
                      alt={t(
                        'productCategoryOverview.middleSection.accessories'
                      )}
                    />
                    <h3 className="category-overview__middle-section__image__subtitle mt-2">
                      {t('productCategoryOverview.middleSection.accessories')}
                    </h3>
                  </B2BecLink>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <B2BecLink to="/categories/detergents">
                    <img
                      className="category-overview__middle-section__image"
                      src={categoryOverviewData.detergents.imgUrl}
                      alt={t(
                        'productCategoryOverview.middleSection.detergents'
                      )}
                    />
                    <h3 className="category-overview__middle-section__image__subtitle mt-2">
                      {t('productCategoryOverview.middleSection.detergents')}
                    </h3>
                  </B2BecLink>
                </Col>
                {shouldShowSparePart && (
                  <PermissionWrapper
                    permission={
                      MODULE_SHOP_NAVIGATION_RIGHTS.OPEN_SPARE_PART_SHOP
                    }
                  >
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                      <B2BecLink to="/spare-parts">
                        <div className="spare-parts">
                          <img
                            className="category-overview__middle-section__image"
                            src={SparePartsImage}
                            alt={t('searchResultsPage.header.spareParts')}
                          />
                          <h3 className="category-overview__middle-section__image__subtitle mt-2">
                            {t('searchResultsPage.header.spareParts')}
                          </h3>
                        </div>
                      </B2BecLink>
                    </Col>
                  </PermissionWrapper>
                )}
              </Row>
            </>
          ) : null}
        </>
      )}
    </div>
  );
}

CategoryOverviewPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default CategoryOverviewPage;
