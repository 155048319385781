import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TechnicalData = (props) => {
  const { t } = useTranslation();
  const { anchorId, technicalData } = props;

  const renderTechnicalDataTable = () =>
    technicalData.map((item, index) => {
      if (item) {
        const key = `${item?.name}-${index}`;

        return (
          <tr key={key}>
            <td>
              {item?.name} {item?.unit ? `(${item?.unit})` : ''}
            </td>
            <td>{item?.value}</td>
          </tr>
        );
      }

      return null;
    });

  return (
    <div className="technical-data__wrapper pt-4 pb-4">
      <h3 id={anchorId}>{t('productDetails.technicalData')}</h3>
      <table className="technical-data__table mt-4">
        <tbody>{renderTechnicalDataTable()}</tbody>
      </table>
    </div>
  );
};

TechnicalData.propTypes = {
  anchorId: PropTypes.string,
  technicalData: PropTypes.arrayOf(PropTypes.shape()),
};
TechnicalData.defaultProps = {
  anchorId: '',
  technicalData: [],
};

export default TechnicalData;
