import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {},
  tokens: null,
  isCheckingAuthentication: true,
  isLoggedIn: false,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    signIn() {},
    signInViaIdentityProvider() {},
    checkAuthentication(state) {
      state.isCheckingAuthentication = true;
    },
    setAuthenticated(state, { payload }) {
      const { tokens } = payload;

      state.isCheckingAuthentication = false;
      state.isLoggedIn = !!tokens;
      state.tokens = tokens;
    },
    setNotAuthenticated(state) {
      state.isCheckingAuthentication = false;
    },
    setUserInfo(state, { payload }) {
      state.userInfo = payload;
    },
    signOut(state) {
      state.userInfo = {};
    },
  },
});

export const authenticationActions = {
  ...authenticationSlice.actions,
};

export default authenticationSlice.reducer;
