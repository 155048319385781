import './styles.scss';

import { DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {
  convertDateToMoment,
  getFormatType,
} from '../../libs/utils/formatDate';

const CustomDateTimePicker = (props) => {
  const { onChangeDateTimePicker, currentValue } = props;

  const onOk = (value) => {
    onChangeDateTimePicker(value);
  };

  return (
    <DatePicker
      showTime
      defaultValue={convertDateToMoment(currentValue)}
      format={getFormatType().dateTime}
      onOk={onOk}
      onChange={onOk}
    />
  );
};
CustomDateTimePicker.propTypes = {
  onChangeDateTimePicker: PropTypes.func.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};
CustomDateTimePicker.defaultProps = {
  currentValue: moment(),
};
export default CustomDateTimePicker;
