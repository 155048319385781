import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { selectIsFetchingHierarchy } from 'store/selectors/roleSelector';

import styles from './RoleInformation.module.scss';

const RoleInformation = (props) => {
  const { hierarchy, description } = props;
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsFetchingHierarchy);

  return (
    <Row
      gutter={[
        { xs: 0, sm: 0 },
        { xs: 16, sm: 16 },
      ]}
    >
      <Col xs={24} sm={24} md={8}>
        <div className={styles.title}>{t('roleManagement.parentRole')}</div>
        <div className={styles.value}>
          {isLoading ? <Skeleton width={100} /> : hierarchy}
        </div>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <div className={styles.title}>{t('productDetails.description')}</div>
        <span className={styles.value}>
          {isLoading ? <Skeleton /> : description}
        </span>
      </Col>
    </Row>
  );
};

export default RoleInformation;

RoleInformation.propTypes = {
  hierarchy: PropTypes.string,
  description: PropTypes.string,
};

RoleInformation.defaultProps = {
  hierarchy: 'N/A',
  description: 'N/A',
};
