import { VOUCHER_STATUS } from 'libs/constants/statuses';

const checkCurrentDateBetweenStartAndEndDate = (startDate, endDate, currDate) =>
  startDate <= currDate && currDate <= endDate;

export const statusHandler = (from, to) => {
  const currDate = new Date().valueOf();
  const startDate = new Date(from).valueOf();
  const endDate = new Date(to).valueOf();

  if (!!startDate && !!endDate) {
    const isCurrDateBetween = checkCurrentDateBetweenStartAndEndDate(
      startDate,
      endDate,
      currDate
    );

    if (isCurrDateBetween) {
      return VOUCHER_STATUS.active;
    }

    return VOUCHER_STATUS.inactive;
  }

  if (!!startDate && !endDate) {
    return currDate < startDate
      ? VOUCHER_STATUS.inactive
      : VOUCHER_STATUS.active;
  }

  if (!startDate && !!endDate) {
    return currDate <= endDate
      ? VOUCHER_STATUS.active
      : VOUCHER_STATUS.inactive;
  }

  return VOUCHER_STATUS.active;
};
