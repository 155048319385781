import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const USER_REQUEST_ACCESS_NEW_CUSTOMER =
  '/v1.0/user/registration/request-access/new-customer';
const USER_REQUEST_ACCESS_EXIST_CUSTOMER =
  '/v1.0/user/registration/request-access/existing-customer';
const USER_VALIDATE_INVITATION_TOKEN = '/v1.0/user/invitation/validate';
const USER_REGISTER_INVITATION = '/v1.0/user/invitation/register';
const USER_REGISTER_NEWSLETTER_AVAILABILITY =
  '/v1.0/user/newsletter/availability';
const USER_REGISTER_NEWSLETTER_AVAILABILITY_COUNTRIES =
  '/v1.0/user/newsletter/availability/countries';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const fapiInvitationService = {
  getRegistrationInformation(userId) {
    return fapiClient.get(`${USER_REGISTER_NEWSLETTER_AVAILABILITY}/${userId}`);
  },

  getNewsletterAvailableList() {
    return fapiClient.get(USER_REGISTER_NEWSLETTER_AVAILABILITY_COUNTRIES);
  },

  requestNewCustomerRegistration(body) {
    return fapiClient.post(USER_REQUEST_ACCESS_NEW_CUSTOMER, {
      body,
    });
  },

  requestExistingCustomerRegistration(body) {
    return fapiClient.post(USER_REQUEST_ACCESS_EXIST_CUSTOMER, {
      body,
    });
  },

  validateRegistrationToken(token) {
    return fapiClient.post(USER_VALIDATE_INVITATION_TOKEN, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: `${token}`,
    });
  },

  registerNewUserWithPassword(body) {
    return fapiClient.post(USER_REGISTER_INVITATION, {
      body,
    });
  },
};
