import { UserOutlined } from '@ant-design/icons';
import { Avatar, Checkbox } from 'antd';
import FormatPrice from 'components/FormatPrice';
import { ReorderIcon } from 'components/Icons';
import { checkListPriceIsZero } from 'libs/utils/common';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import B2becTranslation from '../../../../../components/B2becTranslation';

const tableColumns = (
  addToCartHandler,
  handleCellClick,
  isLoading,
  onCheckHandler,
  comparingProductIds,
  hasAddProductToCartPermission,
  hasProductComparisonPermission
) =>
  [
    {
      dataIndex: 'pictureUrl',
      onCell: ({ materialNumber }) => ({
        onClick: () => handleCellClick(materialNumber),
      }),
      width: '10%',
      render: (text) =>
        isLoading ? (
          <Skeleton />
        ) : text ? (
          <Avatar size={64} shape="square" src={text} />
        ) : (
          <Avatar size={64} shape="square" icon={<UserOutlined />} />
        ),
    },
    {
      title: <B2becTranslation value="cart.table.itemNo" />,
      width: '15%',
      dataIndex: 'materialNumber',
      onCell: ({ materialNumber }) => ({
        onClick: () => handleCellClick(materialNumber),
      }),
      render: (text) => (isLoading ? <Skeleton /> : text),
    },
    {
      title: <B2becTranslation value="cart.table.itemName" />,
      dataIndex: 'materialName',
      width: '15%',
      onCell: ({ materialNumber }) => ({
        onClick: () => handleCellClick(materialNumber),
      }),
      render: (text) => (isLoading ? <Skeleton /> : text),
    },
    {
      title: <B2becTranslation value="category" />,
      dataIndex: 'categoryName',
      width: '15%',
      onCell: ({ materialNumber }) => ({
        onClick: () => handleCellClick(materialNumber),
      }),
      render: (text) => (isLoading ? <Skeleton /> : text),
    },
    {
      title: <B2becTranslation value="productComparison.tableColumn.title" />,
      width: '20%',
      dataIndex: 'comparison',
      align: 'left',
      hidden: !hasProductComparisonPermission,
      render: (_, record) => {
        const isComparingProduct = (comparingProductIds || []).includes(
          record?.materialNumber
        );

        return isLoading ? (
          <Skeleton />
        ) : (
          <Checkbox
            onChange={(e) => onCheckHandler(record, e)}
            checked={isComparingProduct}
            className="kaercher-checkbox"
          >
            <B2becTranslation value="productComparison.button" />
          </Checkbox>
        );
      },
    },
    {
      title: (
        <p style={{ marginRight: '40px' }}>
          <B2becTranslation value="productCard.listPrice" />
        </p>
      ),
      dataIndex: 'listPrice',
      align: 'right',
      width: '15%',
      onCell: ({ materialNumber }) => ({
        onClick: () => handleCellClick(materialNumber),
      }),
      render: (text, record) =>
        isLoading ? (
          <Skeleton />
        ) : checkListPriceIsZero(record.listPrice) ? (
          <B2becTranslation value="productCard.onRequest" />
        ) : (
          <FormatPrice price={text} currencyUnit={record?.currencyUnit} />
        ),
    },
    {
      dataIndex: 'materialNumber',
      align: 'right',
      width: '10%',
      render: (_, record) => {
        const isBProgramProduct = record?.requiresInstruction;
        const shouldDisplayAddToCart =
          record?.displayAddToCart && hasAddProductToCartPermission;

        const shouldHideAddToCartButton =
          !shouldDisplayAddToCart ||
          isBProgramProduct ||
          checkListPriceIsZero(record?.listPrice || record?.price);

        return isLoading ? (
          <Skeleton />
        ) : shouldHideAddToCartButton ? null : (
          <button
            className="button-as-link order-history__btn--reorder"
            type="button"
            onClick={() => addToCartHandler(record.materialNumber)}
          >
            <ReorderIcon />
          </button>
        );
      },
    },
  ].filter((col) => !col.hidden);
export default tableColumns;
