import FormatPrice from 'components/FormatPrice';
import React from 'react';

import B2becTranslation from '../../../../components/B2becTranslation';
import CheckoutNameBlock from '../../components/CheckoutNameBlock';

const CheckoutNameTitleBlock = () => {
  return (
    <div className="checkout-name-title__wrapper">
      <B2becTranslation value="checkout.step3.middleSection.table.name" />
      <B2becTranslation value="checkout.step3.middleSection.table.quantity" />
    </div>
  );
};

export default (currencyUnit) => [
  {
    title: '',
    dataIndex: 'imgUrl',
    key: 'imgUrl',
    width: '7%',
    render: (text) => (
      <img
        className="order-overview__middle-section__table__image"
        src={text}
        alt={text}
      />
    ),
  },
  {
    title: (
      <B2becTranslation value="checkout.step3.middleSection.table.materialNumber" />
    ),
    dataIndex: 'materialNumber',
    key: 'materialNumber',
    align: 'left',
    render: (text) => <p>{text}</p>,
  },
  {
    title: <CheckoutNameTitleBlock />,
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    render: (_, record) => <CheckoutNameBlock record={record} />,
  },
  {
    title: (
      <B2becTranslation value="checkout.step3.middleSection.table.price" />
    ),
    dataIndex: 'price',
    key: 'price',
    align: 'right',
    render: (text) => <FormatPrice price={text} currencyUnit={currencyUnit} />,
  },
  {
    title: (
      <B2becTranslation value="checkout.step3.middleSection.table.total" />
    ),
    dataIndex: 'total',
    key: 'total',
    align: 'right',
    render: (text) => {
      return (
        <div className="order-overview__middle-section__table__total-column">
          <FormatPrice
            price={text}
            currencyUnit={currencyUnit}
            className="order-overview__middle-section__table__total-column__price"
          />
        </div>
      );
    },
  },
];
