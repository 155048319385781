import './styles.scss';

import { Input } from 'antd';
import { CustomFormItem } from 'components/B2bUserForm';
import React from 'react';

const VatNumberInput = () => (
  <CustomFormItem
    className="self-registration__form__vat-number"
    name="vatNumber"
    rules={[{ required: true }]}
  >
    <Input />
  </CustomFormItem>
);

const CustomerNumberInput = () => (
  <CustomFormItem
    className="self-registration__form__vat-number"
    name="sapCustomerNumber"
  >
    <Input />
  </CustomFormItem>
);

export { VatNumberInput, CustomerNumberInput };
