import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const PROMOTION_ENDPOINT = '/v1.0/product/promotion';

const adminFapiClient = new HttpClient(environment.adminFAPIUrl);

const getProductInfo = (materialNumber) => {
  return adminFapiClient.get(`/v1.0/product/${materialNumber}`);
};

const getPromotionList = () => {
  return adminFapiClient.get(PROMOTION_ENDPOINT);
};

const updatePromotionList = (list) => {
  return adminFapiClient.post(PROMOTION_ENDPOINT, {
    body: list,
  });
};

export const adminFapiPromotion = {
  getProductInfo,
  getPromotionList,
  updatePromotionList,
};
