import './styles.scss';

import B2BecLink from 'components/B2BLink';
import React from 'react';
import { useSelector } from 'react-redux';
import Sticky from 'react-stickynode';

import { selectLogo } from '../../../store/selectors/configSelector';
import Content from '../Content';

function Header() {
  const logo = useSelector(selectLogo);
  return (
    <Sticky innerZ={1} top={0}>
      <Content className="public-page__header__wrapper">
        <B2BecLink to="">
          <img
            className="public-page__header__logo"
            src={logo}
            alt="Kaercher Logo"
          />
        </B2BecLink>
      </Content>
    </Sticky>
  );
}

export default Header;
