import { createSlice } from '@reduxjs/toolkit';
import { TRACK_DATA } from 'libs/constants/adobeAnalytics';
import {
  mappingCartAnalysis,
  replaceSpaceByDash,
} from 'libs/utils/adobeAnalysis';
import { isValidArray } from 'libs/utils/array';
import { isEmptyObject } from 'libs/utils/object';

const initialState = {
  data: {
    application: {
      applicationInfo: {
        namespace: 'kaercher_b2b',
        platform: 'b2b',
        language: '',
        country: '',
        currency: '',
      },
    },
    page: {
      pageInfo: {
        pageName: '',
        pageId: '',
        pageType: '',
        onsiteSearchTerm: '',
        onsiteSearchResult: '',
      },
      category: {
        primaryCategory: '',
        subCategory1: '',
        subCategory2: '',
      },
    },
    event: {
      eventName: [],
    },
    cart: {
      exists: '',
      item: [],
    },
    transaction: {
      transactionID: '',
      total: {
        shipmentMethod: '',
        shipping: '',
        postalCode: '',
        paymentMethod: '',
        voucherCode: '',
        discountValue: '',
        transactionTotal: '',
        item: [],
      },
      profile: {
        profileInfo: {
          companyName: '',
          companyID: '',
          companyGroup: '',
        },
      },
    },
    attribute: {
      comparisonProducts: [],
    },
    profile: {
      profileInfo: {
        userID: '',
        userType: '',
      },
    },
    link: {
      linkName: '',
    },
  },
  status: {
    [TRACK_DATA.PAGEINFO]: false,
    [TRACK_DATA.APPLICATION]: false,
    [TRACK_DATA.PROFILE]: false,
    [TRACK_DATA.CART]: false,
    [TRACK_DATA.MINICART]: false,
    [TRACK_DATA.CATEGORIES]: false,
    [TRACK_DATA.ONSITE]: false,
    [TRACK_DATA.COMPARISON]: false,
    [TRACK_DATA.TRANSACTION]: false,
    [TRACK_DATA.COMPANY]: false,
    [TRACK_DATA.LINK_NAME]: false,
  },
};

const adobeSlice = createSlice({
  name: 'adobe',
  initialState,
  reducers: {
    trackPageInfo(state, { payload }) {
      if (!isEmptyObject(payload)) {
        Object.entries(payload).forEach(([key, value]) => {
          if (value) {
            if (key === 'pageName') {
              state.data.page.pageInfo[key] = replaceSpaceByDash(value);
            }
            state.data.page.pageInfo[key] = value;
          }
        });

        state.status[TRACK_DATA.PAGEINFO] = true;
      }
    },
    trackProfileInformation(state, { payload }) {
      if (!isEmptyObject(payload)) {
        Object.entries(payload).forEach(([key, value]) => {
          if (value) {
            state.data.profile.profileInfo[key] = value;
          }
        });

        state.status[TRACK_DATA.PROFILE] = true;
      }
    },
    trackApplication(state, { payload }) {
      if (!isEmptyObject(payload)) {
        Object.entries(payload).forEach(([key, value]) => {
          if (value) {
            state.data.application.applicationInfo[key] = value;
          }
        });

        state.status[TRACK_DATA.APPLICATION] = true;
      }
    },
    trackCart(state, { payload }) {
      const [cartType, cartItems] = Object.entries(payload)[0];
      const cartHasItems = isValidArray(cartItems);
      state.data.cart.exists = +cartHasItems;
      state.data.cart.item = mappingCartAnalysis(cartItems);
      state.status[cartType] = true;
    },
    trackClearedCart(state) {
      state.data.cart.exists = 0;
      state.data.cart.item = [];
      state.status[TRACK_DATA.CART] = false;
      state.status[TRACK_DATA.MINICART] = false;
    },
    trackTransaction(state, { payload }) {
      if (!isEmptyObject(payload)) {
        Object.entries(payload).forEach(([key, value]) => {
          if (value || !isEmptyObject(value)) {
            state.data.transaction[key] = value;
          }
        });
        state.status[TRACK_DATA.TRANSACTION] = true;
      }
    },
    trackCompanyInfo(state, { payload }) {
      if (!isEmptyObject(payload)) {
        Object.entries(payload).forEach(([key, value]) => {
          if (value || !isEmptyObject(value)) {
            state.data.transaction.profile.profileInfo[key] = value;
          }
        });
        state.status[TRACK_DATA.COMPANY] = true;
      }
    },
    trackCategory(state, { payload }) {
      if (!isEmptyObject(payload)) {
        Object.entries(payload).forEach(([key, value]) => {
          state.data.page.category[key] = value;
        });
        state.status[TRACK_DATA.CATEGORIES] = true;
      }
    },
    trackOnSiteSearch(state, { payload }) {
      if (!isEmptyObject(payload)) {
        Object.entries(payload).forEach(([key, value]) => {
          if (value || !isEmptyObject(value)) {
            state.data.page.pageInfo[key] = value;
          }
        });
        state.status[TRACK_DATA.ONSITE] = true;
      }
    },
    trackProductsComparison(state, { payload }) {
      state.data.attribute.comparisonProducts = payload;
      state.status[TRACK_DATA.COMPARISON] = true;
    },
    trackLinkName(state, { payload = {} }) {
      const linkName = payload || '';
      state.data.link.linkName = linkName;
      state.status[TRACK_DATA.LINK_NAME] = true;
    },
    clearTrackedNavigationLink(state) {
      state.status[TRACK_DATA.LINK_NAME] = false;
    },
    updateTrackStatus(state, { payload }) {
      const { trackSection, trackStatus = true } = payload;
      state.status[trackSection] = trackStatus;
    },
  },
});

export const {
  trackCart,
  trackPageInfo,
  trackCategory,
  trackLinkName,
  trackClearedCart,
  trackApplication,
  trackTransaction,
  trackCompanyInfo,
  trackOnSiteSearch,
  updateTrackStatus,
  trackProfileInformation,
  trackProductsComparison,
  clearTrackedNavigationLink,
} = adobeSlice.actions;

export default adobeSlice.reducer;
