import './styles.scss';

import { Col, Form, Input, Row } from 'antd';
import clsx from 'clsx';
import { useCheckoutContext } from 'contexts/checkout-context';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ReferenceNumber() {
  const { t } = useTranslation();
  const { isOrderReferenceMandatory } = useCheckoutContext();

  const validateReferenceNumberField = () => {
    const maxLen = 35;
    const rules = [
      {
        max: maxLen,
        message: t('errors.maxInputExceeded'),
      },
      {
        required: isOrderReferenceMandatory,
      },
    ];

    return rules;
  };

  return (
    <Row className="payment-method__wrapper checkout-step1-section__wrapper">
      <Col xl={5} lg={5} md={4} sm={24} xs={24}>
        <h3
          className={clsx('checkout-step2__reference-number', {
            'mandatory-title': isOrderReferenceMandatory,
          })}
        >
          {t('checkout.step2.referenceNumber')}
        </h3>
      </Col>
      <Col xl={13} lg={13} md={20} sm={24} xs={24}>
        <Form.Item
          name="referenceNumber"
          className="custom-form-item"
          initialValue=""
          rules={validateReferenceNumberField()}
        >
          <Input placeholder={t('checkout.step2.referenceNumberPlaceholder')} />
        </Form.Item>
      </Col>
    </Row>
  );
}
