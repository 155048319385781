import './styles.scss';

import { Col, Divider, Grid, Row } from 'antd';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { ASYNC_STATUS } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { USER_INVITATION_STATUS } from 'libs/constants/user';
import { mappedUserDetailsData } from 'libs/utils/userDetailMapping';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { adminFapiUserManagement } from 'services/adminFapi';
import { selectAssignedRolesByUserId } from 'store/selectors/roleSelector';

import B2becInfoLayout from '../../../../components/B2becInfoLayout';
import { NotificationManager } from '../../../../components/Notification';
import PermissionWrapper from '../../../../HOCs/permissionWrapper';
import useAsync from '../../../../hooks/useAsync';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { MODULE_USER_MANAGEMENT_RIGHTS } from '../../../../libs/constants/modulerights';
import {
  selectAvailableLanguages,
  selectIsFetchingUserConfigurations,
} from '../../../../store/selectors/userSelector';
import { getAssignedRolesByUserId } from '../../../../store/slices/roleSlice';
import DeleteInvitationSection from './components/DeleteInvitationSection';
import DeleteUserSection from './components/DeleteUserSection';
import InvoiceAddressSection from './components/InvoiceAddressSection';
import LoggingHistorySection from './components/LoggingHistorySection';
import PasswordSection from './components/PasswordSection';
import RevokeInvitationSection from './components/RevokeInvitationSection';
import SubheadingSection from './components/SubheadingSection';
import UserInformationSection from './components/UserInformationSection';
import UserStatusSection from './components/UserStatusSection';

function UserDetailsPage(props) {
  const { title, breadcrumbs, pageId } = props;
  const dispatch = useDispatch();

  const { setPageInfoData } = useAdobeAnalysis();

  const history = useHistory();
  const location = useLocation();
  const isInvitation = location?.pathname?.includes('invitation');

  const { userId } = useParams();

  const { setTitleData, setIsTitleTranslated } = useDocumentTitle(title);
  const [setBreadcrumbsData] = useBreadcrumbs(breadcrumbs);

  const isFetchingUserConfigurations = useSelector(
    selectIsFetchingUserConfigurations
  );
  const availableLanguages = useSelector(selectAvailableLanguages);
  const assignedRoles = useSelector(selectAssignedRolesByUserId(userId));

  useEffect(() => {
    if (!isInvitation) {
      dispatch(getAssignedRolesByUserId(userId));
    }
  }, [dispatch, userId, isInvitation]);

  const [userDetails, setUserDetails] = useState({});

  const {
    invitedOn,
    revokedOn,
    createdAt,
    lastLogin,
    customerAddress,
    sapCustomerNumber,
    status: userStatus,
    invitationStatus,
    userInfo,
  } = userDetails || {};

  const getUserDetailsData = useCallback(
    (cancelToken) => (uid) => {
      return adminFapiUserManagement.getUserDetailsById(uid, cancelToken);
    },
    []
  );

  const getInvitationUserDetails = useCallback(
    (cancelToken) => (invitationId) => {
      return adminFapiUserManagement.getInvitedUserDetailsById(
        invitationId,
        cancelToken
      );
    },
    []
  );

  const onSetUserDetails = useCallback(
    (data) => {
      let modifiedData = { ...data };
      let cleanLanguage = modifiedData?.userInfo?.language;

      // To remove countryId attached with language
      // Example: en-UK => en, de-CH => de
      if (cleanLanguage?.length > 2) {
        cleanLanguage = cleanLanguage.slice(0, 2);
      }

      modifiedData = {
        ...modifiedData,
        userInfo: {
          ...modifiedData.userInfo,
          language: cleanLanguage,
        },
      };

      const displayName = modifiedData?.userInfo?.displayName;

      setUserDetails(modifiedData);
      setIsTitleTranslated(true);
      setTitleData([displayName]);
      setBreadcrumbsData([
        {
          titleData: [displayName],
          pathData: [userId],
        },
      ]);

      setPageInfoData({
        pageName: `user details:${displayName}`,
        pageId,
        pageType: PAGE_TYPES.USER_MANAGEMENT,
      });
    },
    [
      setIsTitleTranslated,
      setTitleData,
      setBreadcrumbsData,
      setPageInfoData,
      userId,
      pageId,
    ]
  );

  const onSuccess = useCallback(
    (response) => {
      const { data } = response;
      const reponseData = isInvitation ? mappedUserDetailsData(data) : data;
      onSetUserDetails(reponseData);
    },
    [onSetUserDetails, isInvitation]
  );

  const onError = useCallback(
    (error) => {
      NotificationManager.error({
        message: 'notification.error.userDetails',
        description: error.message,
      });
      history.replace('/404-not-found');
    },
    [history]
  );

  const { execute, status: getUserDetailsStatus } = useAsync(
    getUserDetailsData,
    false,
    onSuccess,
    onError,
    true
  );
  const {
    execute: executeGetInvitationDetails,
    status: getInvitationUserDetailsStatus,
  } = useAsync(getInvitationUserDetails, false, onSuccess, onError, true);

  const isLoading = useMemo(
    () =>
      getUserDetailsStatus === ASYNC_STATUS.PENDING ||
      getInvitationUserDetailsStatus === ASYNC_STATUS.PENDING,
    [getUserDetailsStatus, getInvitationUserDetailsStatus]
  );

  useEffect(() => {
    if (userId) {
      if (isInvitation) {
        executeGetInvitationDetails(userId);
      } else {
        execute(userId);
      }
    }
  }, [userId, isInvitation, executeGetInvitationDetails, execute]);

  const { lg } = Grid.useBreakpoint();

  const renderUserInformationSection = () => (
    <UserInformationSection
      isLoading={isLoading}
      isLoadingAvailableLanguages={isFetchingUserConfigurations}
      availableLanguages={availableLanguages}
      firstName={userInfo?.firstName}
      lastName={userInfo?.lastName}
      name={userInfo?.displayName}
      email={userInfo?.email}
      phoneNumber={userInfo?.phoneNumber}
      countryId={userInfo?.countryId}
      preferredLanguageCode={userInfo?.language}
      userId={userInfo?.userId || userInfo?.invitationId}
      onEditSuccess={() => {
        isInvitation ? executeGetInvitationDetails(userId) : execute(userId);
        dispatch(getAssignedRolesByUserId(userId));
      }}
      userStatus={userStatus}
      salutation={userInfo?.title}
      customerNumber={sapCustomerNumber}
      isInvitation={isInvitation}
      assignedRoles={assignedRoles}
    />
  );

  const renderPasswordSection = () => (
    <PasswordSection isLoading={isLoading} userId={userId} />
  );

  const renderInvoiceAddressSection = () => (
    <InvoiceAddressSection
      isLoading={isLoading}
      name={customerAddress?.name}
      name2={customerAddress?.name2}
      street={customerAddress?.street}
      street4={customerAddress?.street4}
      houseNumber={customerAddress?.houseNumber}
      zip={customerAddress?.zip}
      region={customerAddress?.region}
      city={customerAddress?.city}
      country={customerAddress?.country}
      companyBranch="Industry Branch information"
      numberOfEmployees="10,000-50,000"
      customerNumber={customerAddress?.partnerNumber}
      vatNumber={customerAddress?.taxNumber}
    />
  );

  const renderUserStatusSection = () => (
    <UserStatusSection isLoading={isLoading} userStatus={userStatus} />
  );

  const renderLoggingHistorySection = () => (
    <LoggingHistorySection
      isLoading={isLoading}
      createdOn={createdAt}
      lastLogin={lastLogin}
      invitedOn={invitedOn}
      revokedOn={revokedOn}
      lastOrderCreation={undefined}
    />
  );

  const renderDeleteUserSection = () => {
    if (isInvitation) {
      return (
        <>
          <PermissionWrapper
            permission={MODULE_USER_MANAGEMENT_RIGHTS.DELETE_INVITATION}
          >
            <RevokeInvitationSection
              isLoading={isLoading}
              userId={userId}
              isReactivation={
                invitationStatus === USER_INVITATION_STATUS.REVOKED
              }
            />
            <DeleteInvitationSection
              isLoading={isLoading}
              invitationId={userId}
            />
          </PermissionWrapper>
        </>
      );
    }
    return (
      <PermissionWrapper permission={MODULE_USER_MANAGEMENT_RIGHTS.DELETE_USER}>
        <DeleteUserSection
          className="user-details__delete-user__wrapper"
          isLoading={isLoading}
          userId={userId}
        />
      </PermissionWrapper>
    );
  };

  const renderMobileView = () => (
    <article>
      {renderUserInformationSection()}
      {!isInvitation && sapCustomerNumber !== '' ? (
        <>
          <Divider className="user-details__divider" />
          {renderInvoiceAddressSection()}
        </>
      ) : null}
      <Divider className="user-details__divider" />
      {renderUserStatusSection()}
      {renderLoggingHistorySection()}
      {!isInvitation && (
        <>
          <Divider className="user-details__divider" />
          {renderPasswordSection()}
        </>
      )}
      <Divider className="user-details__divider" />
      {renderDeleteUserSection()}
    </article>
  );

  const renderLeftColumnDesktop = () => (
    <article className="user-details__content__column--left">
      {renderUserInformationSection()}
      {!isInvitation && (
        <>
          <PermissionWrapper
            permission={MODULE_USER_MANAGEMENT_RIGHTS.RESET_USER_PASSWORD}
          >
            <Divider className="user-details__divider" />
            {renderPasswordSection()}
          </PermissionWrapper>
          {sapCustomerNumber !== '' && (
            <>
              <Divider className="user-details__divider" />
              {renderInvoiceAddressSection()}
            </>
          )}
        </>
      )}
    </article>
  );

  const renderRightColumnDesktop = () => (
    <article className="user-details__content__column--right">
      {renderUserStatusSection()}
      {renderLoggingHistorySection()}
      {renderDeleteUserSection()}
    </article>
  );

  const renderContentSection = () => (
    <section className="user-details__content__wrapper">
      {!lg ? (
        renderMobileView()
      ) : (
        <Row>
          <Col span={16}>{renderLeftColumnDesktop()}</Col>
          <Col span={8}>{renderRightColumnDesktop()}</Col>
        </Row>
      )}
    </section>
  );

  return (
    <B2becInfoLayout className="user-details__wrapper">
      <B2becInfoLayout.Title
        title={isLoading ? <Skeleton width={260} /> : userInfo?.displayName}
      >
        <SubheadingSection
          isLoading={isLoading}
          customerNumber={sapCustomerNumber}
          userStatus={userStatus}
          email={userInfo?.email}
        />
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        {renderContentSection()}
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
}

UserDetailsPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

UserDetailsPage.defaultProps = {};

export default UserDetailsPage;
