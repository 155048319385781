import B2BecLink from 'components/B2BLink';
import CustomButton from 'components/CustomButton';
import FormatPrice from 'components/FormatPrice';
import { useGetComparisonFormLocal } from 'pages/ProductComparison/hooks/useGetComparisonFormLocal';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addProductToCart } from 'store/slices/cartSlice';

import usePermission from '../../../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../../libs/constants/modulerights';
import styles from './ProductComparisonCard.module.scss';

const ProductComparisonCard = ({
  materialNumber,
  materialName,
  image,
  listPrice,
  currencyUnit,
  displayListPrice,
  requiresInstruction: isBProgramProduct,
  displayAddToCart: shouldDisplayAddToCart,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getListPriceFromLocal } = useGetComparisonFormLocal();
  const listPriceLocal = getListPriceFromLocal(materialNumber);
  const price = useMemo(() => {
    return listPrice || listPriceLocal;
  }, [listPrice, listPriceLocal]);
  const isPriceEqualZero = +price === 0 || price === '';
  const { hasPermission: hasAddToCartPermission } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.ADD_PRODUCT_TO_SHOPPING_CART
  );

  const shouldHideAddToCartButton =
    !shouldDisplayAddToCart ||
    isBProgramProduct ||
    isPriceEqualZero ||
    !hasAddToCartPermission;

  const onAddProductToCart = (productId) => {
    dispatch(addProductToCart({ materialNumber: productId }));
  };

  return (
    <div className={styles['product-comparison-card']}>
      <B2BecLink to={`/product/${materialNumber}`}>
        <div className={styles.image}>
          <img
            src={image}
            title={materialName}
            alt={materialName}
            style={{ width: '100%' }}
          />
        </div>
        <h3 className={styles.name}>{materialName}</h3>
        <span className={styles.number}>
          {`${t('productCard.itemNumber')} ${materialNumber}`}
        </span>
        <div className={styles.price}>
          <span>{t('productComparison.listingPrice')}</span>
          <span>
            {!displayListPrice || isPriceEqualZero ? (
              t('productCard.onRequest')
            ) : (
              <FormatPrice currencyUnit={currencyUnit} price={price} />
            )}
          </span>
        </div>
      </B2BecLink>
      {shouldHideAddToCartButton ? null : (
        <div className="product-comparison-card__add-cart">
          <CustomButton
            block
            onClick={() => onAddProductToCart(materialNumber)}
          >
            {t('productComparison.addToCart')}
          </CustomButton>
        </div>
      )}
    </div>
  );
};

ProductComparisonCard.defaultProps = {
  image: '',
  listPrice: 0,
  currencyUnit: '',
  requiresInstruction: false,
  displayAddToCart: true,
  displayListPrice: true,
};

ProductComparisonCard.propTypes = {
  materialName: PropTypes.string.isRequired,
  materialNumber: PropTypes.string.isRequired,
  image: PropTypes.string,
  listPrice: PropTypes.number,
  currencyUnit: PropTypes.string,
  requiresInstruction: PropTypes.bool,
  displayListPrice: PropTypes.bool,
  displayAddToCart: PropTypes.bool,
};

export default ProductComparisonCard;
