import { createSlice } from '@reduxjs/toolkit';
import { ASYNC_STATUS } from 'libs/constants';

const initialState = {
  productReferences: [],
  miniCart: {
    fetchState: ASYNC_STATUS.IDLE,
    items: [],
    totalQuantity: 0,
  },
  cartData: {
    items: [],
    currencyUnit: null,
    additionalCost: 0,
    cartSubTotal: 0,
    cartTotal: 0,
    cartTotalVAT: 0,
    shippingCosts: 0,
    voucherValue: {
      code: '',
      totalDiscount: '',
      materialNumbers: [],
      categoryIds: [],
    },
    checkoutSettings: {
      includingVAT: false,
      recyclingFee: false,
      shippingNotes: false,
    },
    vat: 0,
  },
  fetchCartState: ASYNC_STATUS.IDLE,
  isLoading: false,
  isSmallLoading: false,
  isQuickEntryLoading: false,
  isAddingToCartFromPriceBox: false,
  isLoadingPriceBox: false,
  isLoadingAfterRedeemingVoucher: false,
  isEmptyingCart: false,
  priceBoxData: {
    materialNumber: null,
    listPrice: null,
    netPrice: null,
    currencyUnit: null,
    deliveryDates: null,
    displayDeliveryDate: undefined,
  },
  deliveryDates: [],
  error: null,
  reorderIds: {},
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addEmptyRow(state, action) {
      state.cartData.items.push(action.payload);
    },
    addProductToCart(state) {
      state.isLoading = false;
      state.isSmallLoading = true;
      state.isQuickEntryLoading = true;
      state.fetchCartState = ASYNC_STATUS.PENDING;
      state.miniCart.fetchState = ASYNC_STATUS.PENDING;
    },
    addProductToCartSuccess(state, { payload }) {
      state.fetchCartState = ASYNC_STATUS.SUCCESS;
      state.isSmallLoading = false;
      state.isQuickEntryLoading = false;
      state.miniCart = {
        fetchState: ASYNC_STATUS.SUCCESS,
        items: payload.map((item) => ({
          materialNumber: item.materialNumber,
          quantity: item.quantity,
          productReference: item.productReference,
        })),
        totalQuantity: payload.reduce((sum, item) => sum + item.quantity, 0),
      };
      state.cartData.items = payload;
    },
    addProductToCartError(state) {
      state.fetchCartState = ASYNC_STATUS.ERROR;
      state.miniCart.fetchState = ASYNC_STATUS.ERROR;
      state.isLoading = false;
      state.isSmallLoading = false;
      state.isQuickEntryLoading = false;
    },
    changeCartProductReference() {},
    emptyCart(state) {
      state.isEmptyingCart = true;
    },
    emptyCartSucceeded(state) {
      state.isEmptyingCart = false;
      state.cartData = {
        ...initialState.cartData,
        currencyUnit: state.cartData.currencyUnit,
      };
      state.miniCart.items = [];
      state.miniCart.totalQuantity = 0;
    },
    emptyCartFailed(state) {
      state.isEmptyingCart = false;
    },
    getMiniCart(state) {
      state.miniCart.fetchState = ASYNC_STATUS.PENDING;
    },
    getMiniCartSuccess(state, { payload }) {
      state.miniCart.fetchState = ASYNC_STATUS.SUCCESS;
      state.miniCart.items = payload;
      state.miniCart.totalQuantity = payload.reduce(
        (sum, item) => sum + item.quantity,
        0
      );
      state.productReferences = payload.map(
        ({ materialNumber, productReference }) => ({
          materialNumber,
          productReference,
        })
      );
    },
    getMiniCartError(state) {
      state.miniCart.fetchState = ASYNC_STATUS.ERROR;
    },
    getCartItems(state) {
      state.isLoading = true;
      state.fetchCartState = ASYNC_STATUS.PENDING;
      state.miniCart.fetchState = ASYNC_STATUS.PENDING;
    },
    getCartItemsError(state) {
      state.isLoading = false;
      state.fetchCartState = ASYNC_STATUS.ERROR;
      state.miniCart.fetchState = ASYNC_STATUS.ERROR;
      state.isSmallLoading = false;
    },
    getCartItemsSuccess(state, { payload }) {
      state.isLoading = false;
      state.fetchCartState = ASYNC_STATUS.SUCCESS;
      state.isSmallLoading = false;
      state.cartData = payload;
      state.miniCart.fetchState = ASYNC_STATUS.SUCCESS;
      state.miniCart.items = payload?.items.map((item) => ({
        materialNumber: item.materialNumber,
        quantity: item.reqQuantity,
        productReference: item.productReference,
      }));
      state.miniCart.totalQuantity = payload?.items.reduce(
        (sum, item) => sum + item.quantity,
        0
      );
    },
    getCartItemsAfterRedeemingVoucher(state) {
      state.isLoadingAfterRedeemingVoucher = true;
    },
    getCartItemsAfterRedeemingVoucherError(state) {
      state.isLoadingAfterRedeemingVoucher = false;
    },
    getCartItemsAfterRedeemingVoucherSucess(state, { payload }) {
      state.isLoadingAfterRedeemingVoucher = false;
      state.cartData = payload;
    },
    getPriceBoxData(state) {
      state.isLoadingPriceBox = true;
    },
    getPriceBoxDataSuccess(state, { payload }) {
      state.isLoadingPriceBox = false;
      state.priceBoxData = {
        materialNumber: payload.materialNumber,
        listPrice: payload.listPrice,
        netPrice: payload.netPrice,
        currencyUnit: payload.currencyUnit,
        deliveryDates: payload.deliveryDates,
        isDangerous: payload.isDangerous,
        hasMinQuantity: payload.hasMinQuantity,
      };
    },
    getPriceBoxDataError(state) {
      state.isLoadingPriceBox = false;
    },
    increaseCartItemQuantity(state) {
      state.isAddingToCartFromPriceBox = true;
    },
    increaseCartItemQuantitySuccess(state, { payload }) {
      state.isAddingToCartFromPriceBox = false;
      state.miniCart = {
        fetchState: ASYNC_STATUS.SUCCESS,
        items: payload,
        totalQuantity: payload.reduce((sum, item) => sum + item.quantity, 0),
      };
    },
    increaseCartItemQuantityError(state) {
      state.isAddingToCartFromPriceBox = false;
    },
    removeCartItem() {},
    removeCartItemSuccess(state, { payload }) {
      state.miniCart.items = payload.map((item) => ({
        materialNumber: item.materialNumber,
        quantity: item.quantity,
        productReference: item.productReference,
      }));
      state.miniCart.totalQuantity = payload.reduce(
        (sum, item) => sum + item.quantity,
        0
      );
      state.cartData = payload;
    },
    removeProductAddedFromQuickEntry(state) {
      state.cartData.items = state.cartData.items.filter(
        (item) => !item.emptyRow
      );
    },
    resetPriceBoxData(state) {
      state.priceBoxData = initialState.priceBoxData;
    },
    removeVoucherFromCart() {},
    removeVoucherFromCartSuccess(state) {
      state.cartData.voucherValue.code = '';
    },
    removeVoucherFromCartError(state, { payload }) {
      state.cartData.voucherValue.code = payload.currentVoucher;
    },
    replaceCartItems(state) {
      state.isLoading = true;
    },
    replaceCartItemsError(state) {
      state.isLoading = false;
    },
    reorder: (state, { payload }) => {
      const { salesOrderNumber } = payload;
      state.reorderIds[salesOrderNumber] = true;
    },
    reorderSucceeded(state, { payload }) {
      state.reorderIds[payload] = false;
    },
    reorderFailed(state, { payload }) {
      state.reorderIds[payload] = false;
    },
    setCartItemQuantity(state) {
      state.isLoading = false;
      state.fetchCartState = ASYNC_STATUS.PENDING;
      state.miniCart.fetchState = ASYNC_STATUS.PENDING;
    },
    setCartItemQuantitySuccess(state, { payload }) {
      state.isLoading = false;
      state.fetchCartState = ASYNC_STATUS.SUCCESS;
      state.miniCart = {
        items: payload.map((item) => ({
          materialNumber: item.materialNumber,
          quantity: item.quantity,
          productReference: item.productReference,
        })),
        totalQuantity: payload.reduce((sum, item) => sum + item.quantity, 0),
        fetchState: ASYNC_STATUS.SUCCESS,
      };
    },
    setCartItemQuantityError(state) {
      state.isLoading = false;
      state.fetchCartState = ASYNC_STATUS.ERROR;
      state.miniCart.fetchState = ASYNC_STATUS.ERROR;
    },
    searchProductById(state) {
      state.isSmallLoading = true;
    },
    searchProductByIdSuccess(state) {
      state.isSmallLoading = false;
    },
    searchProductByIdError(state, action) {
      state.isSmallLoading = false;
      state.error = action.payload;
    },
    setFetchCartStateToIdle(state) {
      state.fetchCartState = ASYNC_STATUS.IDLE;
    },
    updateProductInCart() {},
    updateProductInCartSuccess() {},
    updateProductInCartError() {},
  },
});

export const {
  addEmptyRow,
  addProductToCart,
  addProductToCartError,
  addProductToCartSuccess,
  changeCartProductReference,
  emptyCart,
  emptyCartFailed,
  emptyCartSucceeded,
  getMiniCart,
  getMiniCartError,
  getMiniCartSuccess,
  getCartItems,
  getCartItemsError,
  getCartItemsSuccess,
  getCartItemsAfterRedeemingVoucher,
  getCartItemsAfterRedeemingVoucherSucess,
  getCartItemsAfterRedeemingVoucherError,
  getPriceBoxData,
  getPriceBoxDataError,
  getPriceBoxDataSuccess,
  increaseCartItemQuantity,
  increaseCartItemQuantityError,
  increaseCartItemQuantitySuccess,
  resetPriceBoxData,
  reorder,
  reorderSucceeded,
  reorderFailed,
  removeCartItem,
  removeCartItemSuccess,
  removeProductAddedFromQuickEntry,
  removeVoucherFromCart,
  removeVoucherFromCartError,
  removeVoucherFromCartSuccess,
  replaceCartItems,
  replaceCartItemsError,
  setCartItemQuantity,
  setCartItemQuantityError,
  setCartItemQuantitySuccess,
  searchProductById,
  searchProductByIdError,
  searchProductByIdSuccess,
  setFetchCartStateToIdle,
  updateProductInCart,
  updateProductInCartSuccess,
  updateProductInCartError,
} = cartSlice.actions;

export default cartSlice.reducer;
