import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './B2bCustomModal.module.scss';

const getModalProps = ({ isFullScreen, ...props }) => {
  if (isFullScreen) {
    return {
      ...props,
      wrapClassName: styles.modal,
      footer: null,
      closable: false,
    };
  }

  return props;
};

const B2bCustomModal = (props) => {
  const modalProps = getModalProps(props);

  return <Modal {...modalProps} />;
};

B2bCustomModal.propTypes = {
  isFullScreen: PropTypes.bool,
};

B2bCustomModal.defaultProps = {
  isFullScreen: false,
};

export default B2bCustomModal;
