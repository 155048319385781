import axios from 'axios';

export function searchDebounce() {
  let timeout;
  let source;

  return (searchText, countryId, customerNumber, handleSearchProducts) => {
    clearTimeout(timeout);

    if (source) {
      source.cancel(`cancelled to replace new search key ${searchText}`);
    }

    return new Promise((resolve) => {
      timeout = setTimeout(async () => {
        try {
          source = axios.CancelToken.source();
          let response = {};
          response = await handleSearchProducts({
            query: searchText.trim(),
            countryId: countryId,
            customerNumber: customerNumber,
            cancelToken: source.token,
          });
          resolve(response.data);
        } catch {
          resolve([]);
        }
      }, 300);
    });
  };
}

export const searchHandler = searchDebounce();
