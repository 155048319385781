import { Buffer } from 'buffer';

export const isStringContainsEmptySpaces = (str) =>
  str.replace(/\s/g, '').length === 0;

export const isStringNotEmpty = (str) => {
  if (typeof str !== 'string') return false;
  return Boolean(str?.trim());
};

export const getInvoiceNumberFromFileName = (fileName) =>
  fileName.split('_').pop().match(/\d/g).join('');

export const base64 = {
  encode: (string) => Buffer.from(string).toString('base64'),
  decode: (string) => Buffer.from(string, 'base64').toString('ascii'),
};

export const replaceConflictingCharacters = (char) => {
  switch (char) {
    case '<':
      return '&lt;';
    case '>':
      return '&gt;';
    case '&':
      return '&amp;';
    case "'":
      return '&apos;';
    case '"':
      return '&quot;';
    default:
      return char;
  }
};

export const xmlFormatter = (string = '') => {
  // remove new line, spaces and tabs (more than 1 indent) of the string
  const normalizedString = string.replace(/[\n\t]+|[ ]{2,}/g, '');
  // replace all occurrences of the conflicting characters with their corresponding HTML entities.
  return normalizedString.replace(/>([^<]*)</g, (_, content) => {
    // The resulting escaped content is then wrapped in the > and < characters and returned as the replacement string.
    return `>${content.replace(/[<>&'"]/g, replaceConflictingCharacters)}<`;
  });
};

export const replaceCamelToDash = (string) =>
  string.replace(/([a-z])([A-Z])/g, '$1 $2');
