import './styles.scss';
import '../../../assets/scss/_common.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { parseHtml } from '../../../libs/utils/common';

const ApplicationArea = (props) => {
  const { t } = useTranslation();
  const { anchorId, applications } = props;

  const renderApplications = () =>
    applications.map((application) =>
      application ? (
        <div className="application-area__list-item" key={application}>
          {parseHtml(application)}
        </div>
      ) : null
    );

  return (
    <div className="application-area__wrapper pt-4 pb-4">
      <h3 id={anchorId}>{t('productDetails.applicationAreas')}</h3>
      <div className="application-area__list mt-4">{renderApplications()}</div>
    </div>
  );
};

ApplicationArea.propTypes = {
  anchorId: PropTypes.string,
  applications: PropTypes.arrayOf(PropTypes.string),
};
ApplicationArea.defaultProps = {
  anchorId: '',
  applications: [],
};

export default ApplicationArea;
