import { put, takeLatest } from 'redux-saga/effects';
import { fapiCustomerService } from 'services/fapi';

import { customerActions } from 'store/slices/customerSlice';

const getCustomerInfo = async () => {
  const response = await fapiCustomerService.getCustomerInfo();
  const { deliveryAddresses: customerDeliveryAddress, ...customerData } =
    response.data;

  const deliveryAddresses = customerDeliveryAddress.reduce(
    (allAddresses, currentAddress) => {
      if (currentAddress.defautAddress) {
        return [currentAddress, ...allAddresses];
      }
      return [...allAddresses, currentAddress];
    },
    []
  );

  return {
    ...customerData,
    deliveryAddresses,
  };
};

function* getCustomerInfoSaga() {
  try {
    const data = yield getCustomerInfo();
    yield put(customerActions.getCustomerInfoSuccess(data));
  } catch (error) {
    yield put(customerActions.getCustomerInfoError());
  }
}

export default function* watchCustomer() {
  yield takeLatest(customerActions.getCustomerInfo.type, getCustomerInfoSaga);
}
