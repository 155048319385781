import { portfolioAdapter } from 'store/slices/portfolioSlice';

export const selectPortfolioOverviewList = portfolioAdapter.getSelectors(
  (state) => state.portfolio
);
export const selectIsPortfolioLoading = (state) => state?.portfolio?.isLoading;

export const selectIsSearchingProduct = (state) => state.portfolio.isSearching;

export const selectTotalPortfolios = (state) => state?.portfolio?.totalCount;

export const addedProductsSelector = (state) => state.portfolio.addedProducts;

export const isAddingSelector = (materialNumber) => (state) =>
  !!state.portfolio.addingProducts[materialNumber];

export const selectedProductSelector = (state) =>
  state.portfolio.selectedProduct;
