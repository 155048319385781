import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { removeKey } from 'libs/utils/object';

export const rolesAdapter = createEntityAdapter({
  selectId: (role) => role.roleId,
});

const roleSlice = createSlice({
  name: 'roles',
  initialState: rolesAdapter.getInitialState({
    isLoading: false,
    roleOptions: {
      isLoading: false,
      data: [],
    },
    duplicate: {
      roleDetails: {},
      isDuplicating: false,
      isFetchingDetails: false,
      fetchingIds: {},
    },
    hierarchy: {
      list: [],
      isLoading: false,
    },
    isFetchingUserAssignedRoles: false,
    roleDetail: null,
    assignableCountryRoleList: [],
    isLoadingAssigableRoles: false,
    rightList: [],
    assignedRoles: {},
  }),
  reducers: {
    getHierarchy: (state) => {
      state.hierarchy.list = [];
      state.hierarchy.isLoading = true;
    },
    getHierarchySuccess: (state, { payload }) => {
      state.hierarchy.list = payload;
      state.hierarchy.isLoading = false;
    },
    getHierarchyFailed: (state) => {
      state.hierarchy.isLoading = false;
    },
    getRoleDetails: (state) => {
      state.isLoading = true;
      state.roleDetail = {};
    },
    getRoleDetailsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.roleDetail = payload;
    },
    getRoleDetailsFailed: (state) => {
      state.isLoading = false;
    },
    getRolesList: (state) => {
      if (state.isLoading === false) {
        state.isLoading = true;
      }
    },
    getRolesListSuccess: (state, action) => {
      if (state.isLoading === true) {
        rolesAdapter.setAll(state, action.payload);
        state.isLoading = false;
      }
    },
    getRolesListFailed: (state) => {
      if (state.isLoading === true) {
        rolesAdapter.removeAll(state);
        state.isLoading = false;
      }
    },
    getListParentRole: (state) => {
      if (state.roleOptions.isLoading === false) {
        state.roleOptions.isLoading = true;
      }
    },
    getListParentRoleSuccess: (state, action) => {
      if (state.roleOptions.isLoading === true) {
        state.roleOptions.data = action.payload;
        state.roleOptions.isLoading = false;
      }
    },
    getListParentRoleFailed: (state) => {
      if (state.roleOptions.isLoading === true) {
        state.roleOptions.isLoading = false;
      }
    },
    createRole: (state) => {
      if (state.isLoading === false) {
        state.isLoading = true;
      }
    },
    createRoleSuccess: (state) => {
      if (state.isLoading === true) {
        state.isLoading = false;
      }
    },
    createRoleFailed: (state) => {
      if (state.isLoading === true) {
        state.isLoading = false;
      }
    },
    updateRoleDetail: (state) => {
      if (state.isLoading === false) {
        state.isLoading = true;
      }
    },
    updateRoleDetailSuccess: (state) => {
      if (state.isLoading === true) {
        state.isLoading = false;
      }
    },
    updateRoleDetailFailed: (state) => {
      if (state.isLoading === true) {
        state.isLoading = false;
      }
    },
    deleteRole: rolesAdapter.removeOne,
    duplicateRoleById: (state) => {
      state.duplicate.isFetchingDetails = true;
    },
    duplicateRoleByIdSuccess: (state, { payload }) => {
      const modifiedPayload = removeKey('roleName', payload);

      state.duplicate.roleDetails = modifiedPayload;
      state.duplicate.isFetchingDetails = false;
      state.duplicate.isDuplicating = true;
    },
    duplicateRoleByIdFailed: (state) => {
      state.duplicate.isFetchingDetails = false;
      state.duplicate.isDuplicating = false;
    },
    setFetchingId: (state, { payload }) => {
      const { roleId, isFetching } = payload;

      state.duplicate.fetchingIds[roleId] = isFetching;
    },
    resetDuplicateState: (state) => {
      state.duplicate.roleDetails = {};
      state.duplicate.isDuplicating = false;
      state.duplicate.isFetchingDetails = false;
      state.duplicate.fetchingIds = {};
    },
    getAssignableRolesByUserId(state) {
      state.isLoadingAssigableRoles = true;
    },
    getAssignableRolesByUserIdSuccess(state, { payload }) {
      const { assignableRolesBySaleOrgs, saleOrgs } = payload;
      state.assignableCountryRoleList = assignableRolesBySaleOrgs;
      state.assignableCountries = saleOrgs;
      state.isLoadingAssigableRoles = false;
    },
    getAssignableRolesByUserIdFailed(state) {
      state.isLoadingAssigableRoles = false;
    },
    getUserRoles(state) {
      state.isFetchingUserAssignedRoles = true;
    },
    getUserRolesSuccess(state, action) {
      state.rightList = action.payload;
      state.isFetchingUserAssignedRoles = false;
    },
    getUserRolesFailed(state) {
      state.isFetchingUserAssignedRoles = false;
    },
    getAssignedRolesByUserId() {},
    getAssignedRolesByUserIdSuccess(state, { payload }) {
      const { assignedRoles, userId } = payload;
      state.assignedRoles[userId] = assignedRoles;
    },
    getAssignedRolesByUserIdFailed() {},
    clearAssignedRolesByUserId(state, { payload }) {
      state.assignedRoles[payload] = [];
    },
  },
});

export const {
  createRole,
  createRoleSuccess,
  createRoleFailed,
  updateRoleDetail,
  updateRoleDetailSuccess,
  updateRoleDetailFailed,
  getListParentRole,
  getListParentRoleSuccess,
  getListParentRoleFailed,
  deleteRole,
  duplicateRoleById,
  duplicateRoleByIdSuccess,
  duplicateRoleByIdFailed,
  setFetchingId,
  resetDuplicateState,
  getHierarchy,
  getHierarchySuccess,
  getHierarchyFailed,
  getRoleDetails,
  getRoleDetailsSuccess,
  getRoleDetailsFailed,
  getRolesList,
  getRolesListSuccess,
  getRolesListFailed,
  getUserRoles,
  getUserRolesSuccess,
  getUserRolesFailed,
  getAssignableRolesByUserId,
  getAssignableRolesByUserIdSuccess,
  getAssignableRolesByUserIdFailed,
  getAssignedRolesByUserId,
  getAssignedRolesByUserIdSuccess,
  getAssignedRolesByUserIdFailed,
  clearAssignedRolesByUserId,
} = roleSlice.actions;

export default roleSlice.reducer;
