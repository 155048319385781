import { createSelector } from '@reduxjs/toolkit';

export const getIsLoading = (state) =>
  state.user.isFetchingUserDetails || state.customer.isLoading;

export const getCustomerAddress = (state) =>
  state.user.userDetails?.customerAddress ||
  state.customer.data?.customerAddress;

export const getDeliveryAddresses = (state) =>
  state.user.userDetails?.deliveryAddresses ||
  state.customer.data?.deliveryAddresses;

export const getCustomerAddressPartnerNumber = (state) =>
  state.user.userDetails?.customerAddress?.partnerNumber ||
  state.customer.data?.customerAddress?.partnerNumber ||
  state.user.userDetails?.sapCustomerNumber;

export const getBillingAddress = (state) =>
  state.user.userDetails?.billingAddress || state.customer.data?.billingAddress;

export const getCustomerFetchStatus = (state) =>
  state?.customer?.customerFetchStatus;

export const getCustomerPhone = (state) =>
  state.user?.userDetails?.customerAddress?.phoneNumber ||
  state.customer.data?.customerAddress?.phoneNumber;

export const getCustomerNumber = (state) =>
  state.user.userDetails?.customerNumber ||
  state?.customer?.data?.customerNumber;

export const getCustomerName = createSelector(
  (state) =>
    state.user.userDetails?.customerAddress?.name ||
    state.customer.data?.customerAddress?.name,
  (state) =>
    state.user.userDetails?.customerAddress?.name2 ||
    state.customer.data?.customerAddress?.name2,
  (name1, name2) => [name1, name2].filter((name) => name).join(', ')
);
