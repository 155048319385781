import { createSelector } from '@reduxjs/toolkit';

import { selectUserId } from './authenticationSelectors';

export const selectIsAddProductToWishlistModalVisible = (state) =>
  state.wishlist.isAddModalVisible;
export const selectIsCreateWishlistModalVisible = (state) =>
  state.wishlist.isCreateModalVisible;
export const selectProductDetail = (state) => state.wishlist.productDetail;
export const selectFetchWishlistsState = (state) =>
  state.wishlist.fetchWishlistsState;
export const selectWishlistOverview = (state) => state.wishlist.overview;
export const selectLoadingIds = (state) => state.wishlist.loadingIds;

export const selectIsWishlistLoading = (state) =>
  state?.wishlist?.wishlistDetails?.isLoading;
export const selectIsAddingWishlistToCart = (state) =>
  state?.wishlist?.wishlistDetails?.isAddingWishlistToCart;
export const selectWishlistTotalProduct = (state) =>
  state?.wishlist?.wishlistDetails?.totalProduct;
export const selectWishlistProducts = (state) =>
  state?.wishlist?.wishlistDetails?.items?.items;
export const selectIsRemovingWishlistProduct = (state) =>
  state?.wishlist?.wishlistDetails?.isRemoving;
export const selectIsDeletingWishlist = (state) =>
  state?.wishlist?.isDeletingWishlist;

export const selectWishlistInfo = createSelector(
  (state) => state?.wishlist?.wishlistDetails,
  ({ wishlistName, ownerName, ownerId }) => ({
    wishlistName,
    wishlistOwnerName: ownerName,
    wishlistOwnerId: ownerId,
  })
);

export const selectIsSharedWishlistDetail = createSelector(
  selectWishlistInfo,
  selectUserId,
  (wishlistInfo, currentUserId) =>
    wishlistInfo?.wishlistOwnerId !== currentUserId
);
