import { itemCategories, specialAttributes } from 'libs/constants/cart';

import { isValidArray } from './array';

export const sortCartItemsWithPairs = (cartItems, pairAttributes) => {
  const { currentMaterial, pairMaterial } = pairAttributes;
  const pairItems = cartItems
    .filter((item) => !!item[currentMaterial])
    .reduce((pairs, originalItem) => {
      const res = cartItems.find(
        (item) => item?.materialNumber === originalItem[currentMaterial]
      );

      return [
        ...pairs,
        { ...originalItem },
        {
          ...res,
          [pairMaterial]: originalItem?.materialNumber,
        },
      ];
    }, []);

  return pairItems;
};

export const mappedSpecialItems = (cartItems) => {
  if (!isValidArray(cartItems)) return [];
  const cartItemsByObject = cartItems.reduce((acc, item) => {
    const key = `${item.materialNumber}|${item.itemCateg}`;
    return { ...acc, [key]: item };
  }, {});

  Object.keys(specialAttributes).forEach((attribute) => {
    Object.values(cartItemsByObject).forEach((item) => {
      const { materialNumber } = item;
      const pairedMaterialNumber = item[attribute];
      const pairAttribute = specialAttributes[attribute];

      if (pairedMaterialNumber) {
        const category = itemCategories[attribute] ?? itemCategories.default;
        const specialItem = `${pairedMaterialNumber}|${category}`;
        if (cartItemsByObject[specialItem]) {
          cartItemsByObject[specialItem][pairAttribute] = materialNumber;
        }
      }
    });
  });

  return Object.values(cartItemsByObject);
};
