import { PRODUCT_COMPARISON } from 'libs/constants/productTypes';
import { useGetComparisonFormLocal } from 'pages/ProductComparison/hooks/useGetComparisonFormLocal';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { trackProductsComparison } from 'store/slices/adobeAnalyticSlice';

const useProductComparison = () => {
  const dispatch = useDispatch();
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const { listProductIds, deleteProductIds } = useGetComparisonFormLocal();
  const [comparingProductIds, setComparingProductIds] =
    useState(listProductIds);

  const [productInformation, setProductInformation] = useState({
    materialNumber: '',
    materialName: '',
    categoryName: '',
    listPrice: '',
    currencyUnit: '',
    image: '',
    displayListPrice: true,
  });

  const getListProductIds = async () => {
    const compareProducts = await JSON.parse(
      localStorage.getItem(PRODUCT_COMPARISON)
    );
    // get value of materialNumbers
    const comparisonAnalysisList = [];

    const productIds = (compareProducts || []).map(
      ({ materialNumber, materialName }) => {
        comparisonAnalysisList.push(`${materialNumber}:${materialName}`);
        return materialNumber;
      }
    );

    return { productIds, comparisonAnalysisList };
  };

  const onCompareHandler = useCallback(
    (isChecked, product) => {
      if (isChecked) {
        // send current product data to modal
        setProductInformation({
          materialNumber: product?.materialNumber,
          materialName: product?.materialName,
          categoryName: product?.categoryName,
          listPrice: product?.listPrice,
          currencyUnit: product?.currencyUnit,
          image: product?.pictureUrl || product?.imageUrl,
          displayListPrice: product?.displayListPrice,
        });

        setShowComparisonModal(true);
      } else {
        // uncheck -> remove from comparison list
        deleteProductIds(product?.materialNumber);

        // refresh compare product ids list
        getListProductIds().then(({ productIds, comparisonAnalysisList }) => {
          setComparingProductIds(productIds);
          dispatch(trackProductsComparison(comparisonAnalysisList));
        });
      }
    },
    [dispatch, deleteProductIds]
  );

  useEffect(() => {
    let isMounted = true;
    getListProductIds().then(({ productIds }) => {
      if (isMounted) {
        setComparingProductIds(productIds);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [onCompareHandler]);

  return {
    showComparisonModal,
    setShowComparisonModal,
    comparingProductIds,
    onCompareHandler,
    productInformation,
  };
};

export default useProductComparison;
