import clsx from 'clsx';
import { SERVICE_PACKAGES_TYPES } from 'libs/constants';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import B2becTranslation from '../../components/B2becTranslation';
import CustomButton from '../../components/CustomButton';

const LEGEND_TYPE = {
  included: 'service-packages__table__color--included',
  optional: 'service-packages__table__color--optional',
  notIncluded: 'service-packages__table__color--not-included',
};

const renderNormalHeaderCell = (content) => {
  return (
    <div className="service-packages__table__header__wrapper">
      <div className="service-packages__table__header__cell">&nbsp;</div>
      <div className="service-packages__table__header__cell">&nbsp;</div>
      <div className="service-packages__table__header__cell service-packages__table__header__cell--dark">
        {content}
      </div>
    </div>
  );
};

const renderCustomHeaderCell = (title, legendName, legendType) => {
  return (
    <div className="service-packages__table__header__wrapper">
      <div
        className={clsx(
          'service-packages__table__header__cell',
          'service-packages__table__header__legend',
          'service-packages__table__header__cell--center-horizontal'
        )}
      >
        <span
          className={clsx(
            'service-packages__table__header__legend__icon',
            legendType
          )}
        />
        {legendName}
      </div>
      <div
        className={clsx(
          'service-packages__table__header__cell',
          'service-packages__table__header__cell--customized',
          'service-packages__table__header__cell--center-horizontal'
        )}
      >
        {title}
      </div>
      <div
        className={clsx(
          'service-packages__table__header__cell',
          'service-packages__table__header__cell--dark'
        )}
      >
        &nbsp;
      </div>
    </div>
  );
};

const renderFooterCell = (content, buttonTitle, onClickLearnMore) => {
  const isDisabled = onClickLearnMore === undefined;
  return (
    <div className="service-packages__table__footer__wrapper">
      {content && (
        <div className="service-packages__table__footer__title">{content}</div>
      )}
      {buttonTitle && (
        <CustomButton
          className="service-packages__table__footer__button"
          disabled={isDisabled}
          onClick={onClickLearnMore}
        >
          {buttonTitle}
        </CustomButton>
      )}
    </div>
  );
};

const renderDataCell = (isLoading, data, noLeftBorderred) => {
  const { included, optional, notIncluded, content } = data || {};
  const className = clsx('service-packages__table__color-cell', {
    'service-packages__table__color-cell--no-left-bordered': noLeftBorderred,
    'service-packages__table__color--included': !isLoading && included,
    'service-packages__table__color--optional': !isLoading && optional,
    'service-packages__table__color--not-included': !isLoading && notIncluded,
  });
  return (
    <div className={className}>
      {!!content && <B2becTranslation value={content} />}
    </div>
  );
};

const tableColumns = (isLoading, onClickLearnMore) => {
  const handleClickLearnMore = (name) => {
    if (typeof onClickLearnMore === 'function') {
      return () => onClickLearnMore(name);
    }

    return undefined;
  };

  return [
    {
      width: '40%',
      title: renderNormalHeaderCell(
        <B2becTranslation value="service.servicePackages.overview.table.benefits.title" />
      ),
      dataIndex: 'benefitTranslationKey',
      render: (text, { extraRow }) => {
        if (extraRow) {
          return renderFooterCell();
        }
        return (
          <div className="service-packages__table__benefit">
            {<B2becTranslation value={text} /> || <Skeleton />}
          </div>
        );
      },
    },
    {
      width: '20%',
      title: renderCustomHeaderCell(
        <B2becTranslation value="service.servicePackages.karcherInspect.title" />,
        <B2becTranslation value="service.servicePackages.overview.table.included" />,
        LEGEND_TYPE.included
      ),
      dataIndex: 'inspect',
      render: (_, { inspect, extraRow }) => {
        if (extraRow) {
          return renderFooterCell(
            <B2becTranslation value="service.servicePackages.karcherInspect.title" />,
            <B2becTranslation value="service.servicePackages.overview.table.buttonTitle" />,
            handleClickLearnMore(SERVICE_PACKAGES_TYPES.INSPECT)
          );
        }
        return renderDataCell(isLoading, inspect, true);
      },
    },
    {
      width: '20%',
      title: renderCustomHeaderCell(
        <B2becTranslation value="service.servicePackages.karcherMaintain.title" />,
        <B2becTranslation value="service.servicePackages.overview.table.optional" />,
        LEGEND_TYPE.optional
      ),
      dataIndex: 'maintain',
      render: (_, { maintain, extraRow }) => {
        if (extraRow) {
          return renderFooterCell(
            <B2becTranslation value="service.servicePackages.karcherMaintain.title" />,
            <B2becTranslation value="service.servicePackages.overview.table.buttonTitle" />,
            handleClickLearnMore(SERVICE_PACKAGES_TYPES.MAINTAIN)
          );
        }
        return renderDataCell(isLoading, maintain);
      },
    },
    {
      width: '20%',
      title: renderCustomHeaderCell(
        <B2becTranslation value="service.servicePackages.karcherFullService.title" />,
        <B2becTranslation value="service.servicePackages.overview.table.notIncluded" />,
        LEGEND_TYPE.notIncluded
      ),
      dataIndex: 'fullService',
      render: (_, { fullService, extraRow }) => {
        if (extraRow) {
          return renderFooterCell(
            <B2becTranslation value="service.servicePackages.karcherFullService.title" />,
            <B2becTranslation value="service.servicePackages.overview.table.buttonTitle" />,
            handleClickLearnMore(SERVICE_PACKAGES_TYPES.FULLSERVICE)
          );
        }
        return renderDataCell(isLoading, fullService);
      },
    },
  ];
};

export default tableColumns;
