import { put, takeLatest } from 'redux-saga/effects';
import { fapiUserService } from 'services/fapi';

import { STATUS_CODE } from '../../libs/constants';
import { contactActions } from 'store/slices/contactSlice';

function* submitContactRequestSaga({ payload }) {
  try {
    const { contactReasonId, contactReasonName, message } = payload;
    const response = yield fapiUserService.submitContactRequest(
      contactReasonId,
      contactReasonName,
      message
    );
    if (response.status === STATUS_CODE.SUCCESS) {
      yield put(contactActions.submitContactRequestSuccess());
    } else {
      yield put(contactActions.submitContactRequestError());
    }
  } catch (error) {
    yield put(contactActions.submitContactRequestError());
  }
}

function* watchCustomer() {
  yield takeLatest(
    contactActions.submitContactRequest.type,
    submitContactRequestSaga
  );
}

export default watchCustomer;
