import { isValidArray } from 'libs/utils/array';

/**
 *
 * @param {Object} obj The object needs to be checked
 * @param {String || String[]} propertyName Property names need to check
 * @returns Boolean True if the object has all passed property names
 */
const checkHasPropertiesInObject = (obj, ...propertyNames) => {
  if (obj === null) return false;
  if (obj === undefined) return false;

  if (isValidArray(propertyNames, 1)) {
    return propertyNames.every((propertyName) =>
      Object.prototype.hasOwnProperty.call(obj, propertyName)
    );
  }

  return Object.prototype.hasOwnProperty.call(obj, propertyNames);
};

const isObjectChecker = (obj) => {
  return !!(
    obj &&
    typeof obj === 'object' &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

const isEmptyObject = (obj) => {
  if (isObjectChecker(obj)) {
    return (
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    );
  }
  return true;
};

const compareObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

const removeKey = (key = '', { [key]: _, ...object } = {}) => object;

const removeKeys = (keys = [], object = {}) =>
  keys.reduce((r, k) => removeKey(k, r), object);

const isEqual = (obj1 = {}, obj2 = {}) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const hasAllPropertiesValid = (object) => {
  if (isEmptyObject(object)) return false;

  const hasInvalidProperties = Object.values(object).some((value) => {
    if (typeof value === 'number' || typeof value === 'boolean') {
      return false;
    }
    if (typeof value === 'object' && !!value) {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return isEmptyObject(value);
    }
    return !value;
  });
  return !hasInvalidProperties;
};

export {
  checkHasPropertiesInObject,
  isObjectChecker,
  isEmptyObject,
  removeKey,
  removeKeys,
  compareObjects,
  isEqual,
  hasAllPropertiesValid,
};
