import './styles.scss';

import clsx from 'clsx';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImageWithFallBack from '../../../components/ImageWithFallback';

function ProductDocuments(props) {
  const { anchorId, documents } = props;
  const { t } = useTranslation();
  const [hoverState, setHoverState] = useState({
    key: undefined,
    isHovered: false,
  });

  const renderProductTile = (url, type, key) => {
    const documentName = t(
      `productDetails.documentSection.documentName.${type}`
    );

    return (
      <div key={key} className="product-details__document-tile">
        <a
          className="product-details__document-tile__img__wrapper"
          href={url[0].url}
          target="_blank"
          rel="noopener noreferrer"
          download
          onMouseEnter={() => setHoverState({ key, isHovered: true })}
          onMouseLeave={() => setHoverState({ key, isHovered: false })}
        >
          <ImageWithFallBack
            className={clsx(
              'product-details__document-tile__img__background',
              {
                'product-details__document-tile__img__background--hovered':
                  key === hoverState.key && hoverState.isHovered,
              }
            )}
            src={url[1].url}
            alt={documentName}
          />
          {key === hoverState.key && hoverState.isHovered && (
            <>
              <div className="product-details__document-tile__img__button" />
              <div className="product-details__document-tile__img__overlay" />
              <div className="product-details__document-tile__img__border">
                <ImageWithFallBack src={url[1].url} alt={type} />
              </div>
            </>
          )}
        </a>
        <p className="product-details__document-tile__subtitle">
          {documentName}
        </p>
      </div>
    );
  };

  return (
    <div className="pt-4 pb-4">
      <h3 id={anchorId}>{t('productDetails.documentSection.title')}</h3>
      <div className="product-details__document-wrapper mt-4">
        {isValidArray(documents)
          ? documents.map((doc, index) =>
              renderProductTile(doc.urls, doc.type, index)
            )
          : null}
      </div>
    </div>
  );
}

ProductDocuments.propTypes = {
  anchorId: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      urls: PropTypes.arrayOf(PropTypes.shape({})),
      type: PropTypes.string,
    })
  ),
};

ProductDocuments.defaultProps = {
  anchorId: '',
  documents: [],
};

export default ProductDocuments;
