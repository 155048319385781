import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const FAPI_PRODUCT_PORTFOLIO_ENDPOINT = '/v1.0/productportfolio';

const GET_PRODUCT_PORTFOLIO_LIST = `${FAPI_PRODUCT_PORTFOLIO_ENDPOINT}/list`;
const GET_SUGGESTIONS_PRODUCTS_LIST = `${FAPI_PRODUCT_PORTFOLIO_ENDPOINT}/suggest`;
const IMPORT_FILE = `${FAPI_PRODUCT_PORTFOLIO_ENDPOINT}/import`;
const EXPORT_FILE = `${FAPI_PRODUCT_PORTFOLIO_ENDPOINT}/export`;

const adminFAPIClient = new HttpClient(environment.adminFAPIUrl);

export const fapiProductPortfolioService = {
  getProductPortfolioList({
    countryId,
    customerNumber,
    pageNumber,
    pageSize = 10,
    sortBy,
    orderBy,
    searchTerm,
  }) {
    return adminFAPIClient.get(GET_PRODUCT_PORTFOLIO_LIST, {
      queryParams: {
        pageNumber,
        pageSize,
        sortBy,
        orderBy,
        searchTerm,
        countryId,
        customerNumber,
      },
    });
  },
  // new param field: language
  addProduct(data) {
    return adminFAPIClient.post(FAPI_PRODUCT_PORTFOLIO_ENDPOINT, {
      body: data,
    });
  },
  updateProduct(data) {
    return adminFAPIClient.put(FAPI_PRODUCT_PORTFOLIO_ENDPOINT, { body: data });
  },
  deleteProduct(data) {
    return adminFAPIClient.delete(FAPI_PRODUCT_PORTFOLIO_ENDPOINT, {
      body: data,
    });
  },
  getSuggestionsProductsList({
    query = '',
    countryId,
    customerNumber,
    cancelToken,
  }) {
    return adminFAPIClient.get(GET_SUGGESTIONS_PRODUCTS_LIST, {
      queryParams: {
        query,
        countryId,
        customerNumber,
        from: 0,
        size: 10,
      },
      cancelToken,
    });
  },
  importFile(formData, selectedScope = {}) {
    const { countryId, customerNumber } = selectedScope;
    const params = new URLSearchParams({});
    if (countryId) {
      params.append('countryId', countryId);
    }
    if (customerNumber) {
      params.append('customerNumber', customerNumber);
    }
    return adminFAPIClient.post(`${IMPORT_FILE}?${params.toString()}`, {
      body: formData,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
  exportFile(customerNumber) {
    return adminFAPIClient.get(EXPORT_FILE, {
      queryParams: { customerNumber },
    });
  },
};
