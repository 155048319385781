import { v4 as uuidv4 } from 'uuid';
import { parseString } from 'xml2js';

import ociTemplate from '../punchouTemplates/oci';
import {
  credentialTemplate,
  itemTemplate,
  poomTemplate,
} from '../punchouTemplates/procurement';
import { isValidArray } from './array';
import { checkHasPropertiesInObject, isObjectChecker } from './object';
import { base64, xmlFormatter } from './stringUltis';

/**
 *
 * @param {string} queryString key2=value2&key4=value4&key1=value1
 * @param {Array} queryKeys [key1, key2, key3, key4]
 * @param {object} initialInfo {}
 * @returns {
 *  key1: value1,
 *  key2: value2,
 *  key4: value4
 * }
 */
export const getInfoFromUrl = (
  queryString = '',
  queryKeys = [],
  initialInfo = {}
) => {
  const decodedQueryString = decodeURIComponent(queryString);
  const regex = new RegExp(
    queryKeys.flatMap((key) => [`${key}=`, `&${key}=`]).join('|')
  );
  const queryValues = decodedQueryString.split(regex).filter(Boolean);

  return queryKeys
    .flatMap((queryKey) => {
      if (decodedQueryString.includes(queryKey)) {
        return [{ queryKey, order: decodedQueryString.indexOf(queryKey) }];
      }
      return [];
    })
    .sort((a, b) => a?.order - b?.order)
    .reduce(
      (res, c, i) => ({ ...res, [c.queryKey]: queryValues[i] }),
      initialInfo
    );
};

export const trimCxmlValue = (string = '') => string.replace(/^\s+|\s+$/g, '');

const handleArrayTypeDocument = (cxmlDocument) => {
  if (cxmlDocument.length > 1) {
    return cxmlDocument.map((item) => flattenedCxmlDoc(item));
  }
  return flattenedCxmlDoc(cxmlDocument[0]);
};

const handleObjectTypeDocument = (cxmlDocument) => {
  const flattendObj = {};
  for (const [key, value] of Object.entries(cxmlDocument)) {
    if (key === '$') {
      // if the key is '$', the nested value of it should be moved to the same level of '$'
      // and remove the current key '$'
      for (const [nestedKey, nestedValue] of Object.entries(value)) {
        flattendObj[nestedKey] = nestedValue;
      }
    } else if (key === '_') {
      // if the key is '_', add a attribute named 'value' and assign that key's value to it
      flattendObj.value = trimCxmlValue(value);
    } else {
      flattendObj[key] = flattenedCxmlDoc(value);
    }
  }
  return flattendObj;
};

export const flattenedCxmlDoc = (cxmlDocument) => {
  if (isValidArray(cxmlDocument)) {
    return handleArrayTypeDocument(cxmlDocument);
  }
  if (isObjectChecker(cxmlDocument)) {
    return handleObjectTypeDocument(cxmlDocument);
  }

  return typeof cxmlDocument === 'string'
    ? trimCxmlValue(cxmlDocument)
    : cxmlDocument;
};

export const convertCxmlDoc = (document) => {
  let convertedDocument = null;
  // convert the document from xml format to the json format
  parseString(document, (err, result) => {
    if (result) {
      // restructure the converted json
      convertedDocument = flattenedCxmlDoc(result);
    }
    if (err) {
      console.error('parse error:', err);
    }
  });
  return convertedDocument;
};

const CREDENTIALS = {
  BUYER: 'From',
  SUPPLIER: 'To',
  SENDER: 'Sender',
};

const CREDENTIAL_DOMAINS = ['networkId', 'DUNS'];

const getCredentialDomain = (credentials) => {
  const credentialDomainsLowercase = new Set(
    CREDENTIAL_DOMAINS.map((domain) => domain.toLowerCase())
  );

  return credentials.find((cred) => {
    const domain = cred?.Credential?.domain?.toLowerCase();
    return credentialDomainsLowercase.has(domain);
  });
};

const getHeaderCredential = (obj, _key) => {
  if (checkHasPropertiesInObject(obj, _key)) {
    const isSender = _key === CREDENTIALS.SENDER;
    // if the key is Sender, duplicate the information from the Supplier
    const key = isSender ? CREDENTIALS.SUPPLIER : _key;
    const userAgent = 'mKB Punchout Site';

    if (isValidArray(obj[key])) {
      if (isSender) {
        // The Supplier credential from the POSReq might have multiple credentials
        // Select the correct credential which domain is in the list 'CREDENTIAL_DOMAINS'
        const credential = getCredentialDomain(obj[key]);
        return credentialTemplate({
          domain: credential?.Credential?.domain,
          Identity: credential?.Credential?.Identity,
          userAgent,
        });
      }

      return obj[key].reduce((acc, curr) => {
        const credential = credentialTemplate({
          domain: curr?.Credential?.domain,
          Identity: curr?.Credential?.Identity,
        });
        return acc + credential;
      }, '');
    }
    if (isObjectChecker(obj[key])) {
      const credential = obj[key]?.Credential;
      if (isSender) {
        credential.userAgent = userAgent;
      }
      return credentialTemplate(credential);
    }
  }
  return '';
};

const extractDataFromPOSRequest = ({ cXML }) => {
  const { Header, Request } = cXML;
  const getCredentialsByKey = (key) => getHeaderCredential(Header, key);

  return {
    browserFromPostUrl: Request?.PunchOutSetupRequest?.BrowserFormPost?.URL,
    buyerCookie: Request?.PunchOutSetupRequest?.BuyerCookie,
    credentials: {
      buyer: getCredentialsByKey(CREDENTIALS.BUYER),
      supplier: getCredentialsByKey(CREDENTIALS.SUPPLIER),
      sender: getCredentialsByKey(CREDENTIALS.SENDER),
    },
  };
};

const punchoutOrderMessage = (cartData, posr) => {
  const { credentials, buyerCookie } = posr;

  /* todo:
   * Depeneds on different customers, there will be different fixed data for some fields
   * will create constant for customers in the future if neccessary
   */

  const payloadId = [uuidv4(), '@', window.location.hostname].join('');

  const mappedPoomData = {
    payloadId,
    buyerCookie,
    credentials,
    currency: cartData?.currencyUnit,
    totalPrice: cartData?.cartSubTotal,
    shippingCost: cartData?.shippingCosts,
    vat: cartData?.vat,
  };

  const mappedCartItems = () => {
    if (!isValidArray(cartData?.items)) return [];
    return cartData?.items.reduce(
      (accumlator, cartItem) => accumlator + itemTemplate(cartItem),
      ''
    );
  };

  const poom = poomTemplate(mappedPoomData, mappedCartItems());
  // must replace all occurrences of conflicting characters in the xml content
  return xmlFormatter(poom);
};

export const hiddenInput = (name, value) => {
  const inputElement = document.createElement('input');
  inputElement.type = 'hidden';
  inputElement.value = value;
  inputElement.name = name;

  return inputElement;
};

export const submitOciForm = ({ cartData, url }) => {
  const form = document.createElement('form');

  form.setAttribute('id', 'OciSubmitForm');
  form.method = 'POST';
  form.action = url;

  const { vat: cartVAT, cartTotal, cartTotalVAT } = cartData;
  const calcVatPercentage = () => {
    if (cartVAT > 0) {
      return parseFloat(cartVAT / cartTotal).toFixed(2);
    }

    const itemsVAT = cartData.items.reduce(
      (summedTax, currentItem) => summedTax + currentItem?.tax,
      0
    );
    return parseFloat(itemsVAT / cartTotalVAT).toFixed(2);
  };

  const createInput = (name, value) => form.append(hiddenInput(name, value));

  for (let index = 0; index < cartData?.items.length; index += 1) {
    const cartItem = cartData?.items[index];
    const cartIndex = index + 1;

    const template = ociTemplate({
      ...cartItem,
      vatPercentage: calcVatPercentage(),
    });

    for (const [key, value] of Object.entries(template)) {
      const ociKey = key.replace('index', cartIndex);
      createInput(ociKey, value);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

export const submitProcurementForm = (cartData, punchoutSetupRequest) => {
  const extractedData = extractDataFromPOSRequest(punchoutSetupRequest);

  const form = document.createElement('form');
  form.setAttribute('id', 'cxml_form');
  form.setAttribute('enctype', 'application/x-www-form-urlencoded');
  form.method = 'POST';
  form.action = extractedData?.browserFromPostUrl;

  const poom = punchoutOrderMessage(cartData, extractedData);
  const inputElement = hiddenInput('cxml-base64', base64.encode(poom));

  form.append(inputElement);
  document.body.appendChild(form);
  form.submit();
};
