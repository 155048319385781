import { LoadingOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { SearchIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsUserListFetching } from 'store/slices/usersManagementSlice';

const { Search } = Input;

const SearchUserInput = ({ onSearchUsers }) => {
  const { t } = useTranslation();

  const isUserListFetching = useSelector(selectIsUserListFetching);

  const onSearchHandler = useCallback(
    (value) => {
      onSearchUsers(value);
    },
    [onSearchUsers]
  );

  return (
    <Search
      className="custom-search-input"
      placeholder={t('buttonTexts.search')}
      prefix={<SearchIcon className="user-management__search-icon" />}
      bordered
      size="large"
      allowClear
      enterButton={
        isUserListFetching ? <LoadingOutlined /> : t('userManagement.search')
      }
      onSearch={onSearchHandler}
    />
  );
};

SearchUserInput.propTypes = {
  onSearchUsers: PropTypes.func.isRequired,
};

export default SearchUserInput;
