import { Table } from 'antd';
import B2becTable from 'components/B2becTable';
import B2becTableExpandIcon from 'components/B2becTableExpandIcon';
import { isValidArray } from 'libs/utils/array';
import { checkHasPropertiesInObject } from 'libs/utils/object';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { clustersSelector } from 'store/selectors/rightSelector';

import NotAvailableRights from './NotAvailableRights';
import styles from './RightTable.module.scss';

const MKB_PREFIX = 'mkb';

export const generateRightTableDataSource = (dataSource) => {
  if (!Array.isArray(dataSource)) {
    return [];
  }

  return dataSource.reduce((result, cluster) => {
    const assignableRights = cluster.assignableRights || [];
    const isRecordClusterDisabled = assignableRights.every(
      (assignableRight) => !assignableRight?.isAssignable
    );

    const mappedChildren = isValidArray(assignableRights)
      ? assignableRights?.map((assignableRight) => {
        return {
          ...assignableRight,
          clusterName: cluster?.clusterName,
        };
      })
      : [];

    return [
      ...result,
      {
        rightId: cluster?.clusterName || '',
        children: mappedChildren,
        isRecordClusterDisabled,
      },
    ];
  }, []);
};

export const trimWhiteSpaceInClusterName = (clusterName) => {
  return clusterName?.toLowerCase()?.replace(/\s/g, '');
};

const RightTable = ({ onChange, value, disabled }) => {
  const dataSource = useSelector(clustersSelector);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (selected) => {
      // Only includes items that represent MKB rights,
      // excluding any items that represent clusters.
      const selectedRights = selected.filter((item) =>
        item?.startsWith(MKB_PREFIX)
      );
      onChange(selectedRights);
    },
    [onChange]
  );

  const rightsByCluster = useMemo(() => {
    return generateRightTableDataSource(dataSource);
  }, [dataSource]);

  if (!rightsByCluster.length) {
    return <NotAvailableRights />;
  }

  return (
    <B2becTable
      className={styles.wrapper}
      rowKey="rightId"
      expandIcon={B2becTableExpandIcon}
      rowSelection={{
        hideSelectAll: true,
        checkStrictly: false,
        onChange: handleChange,
        selectedRowKeys: value,
        getCheckboxProps: (record) => {
          if (checkHasPropertiesInObject(record, ['children'])) {
            return {
              disabled: record?.isRecordClusterDisabled,
            };
          }

          if (checkHasPropertiesInObject(record, ['isAssignable'])) {
            return {
              disabled: record?.isAssignable === false,
            };
          }

          return {
            disabled,
          };
        },
      }}
      dataSource={rightsByCluster}
    >
      <Table.Column title="" width={50} />
      <Table.Column
        title="Right Name"
        dataIndex="rightId"
        render={(text, record) => {
          if (
            checkHasPropertiesInObject(record, ['children']) &&
            !record?.rightId?.startsWith(MKB_PREFIX)
          ) {
            const trimmedClusterName = trimWhiteSpaceInClusterName(text);
            return t(`rights.${trimmedClusterName}.name`);
          }

          const { rightId, clusterName } = record;
          const splittedRightId = rightId.split(':');

          if (isValidArray(splittedRightId)) {
            const rightId = splittedRightId[1];
            const trimmedClusterName = trimWhiteSpaceInClusterName(clusterName);

            return t(t(`rights.${trimmedClusterName}.${rightId}`));
          }

          return text;
        }}
      />
    </B2becTable>
  );
};

RightTable.defaultProps = {
  value: [],
  onChange: () => { },
  disabled: false,
};

RightTable.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default RightTable;
