import B2bLoadingFullPage from 'components/B2bLoadingFullPage';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import { selectDateFormat } from 'store/selectors/configSelector';
import { selectIsFetchingSetupData } from 'store/slices/setupSlice';

const withUserAndDateFormat = (Component) => {
  return (props) => {
    const dateFormat = useSelector(selectDateFormat);
    const isFetchingSetupData = useSelector(selectIsFetchingSetupData);
    const userId = useSelector(selectUserId);
    const isLoading = userId && !dateFormat;

    if (isLoading || isFetchingSetupData) {
      return <B2bLoadingFullPage />;
    }
    return <Component {...props} />;
  };
};

export default withUserAndDateFormat;
