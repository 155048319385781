import React from 'react';

import SuggestionItem from './SuggestionItem';

export const generateSuggestionItemsTransparent = (isIpadLandscape) => {
  return Array.from(Array(isIpadLandscape ? 6 : 8).keys()).map((key) => ({
    key,
    value: String(key),
    label: (
      <SuggestionItem
        isTransparent
        name={String(key)}
        materialNumber={String(key)}
        isIpadLandscape={isIpadLandscape}
      />
    ),
    disabled: true,
  }));
};

export const generateSuggestionItemsLoading = (isIpadLandscape) => {
  return Array.from(Array(isIpadLandscape ? 6 : 8).keys()).map((key) => ({
    key,
    value: String(key),
    label: (
      <SuggestionItem
        isLoading
        name={String(key)}
        materialNumber={String(key)}
        isIpadLandscape={isIpadLandscape}
      />
    ),
    disabled: true,
  }));
};
