import './styles.scss';

import FormatPrice from 'components/FormatPrice';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import {
  selectCartData,
  selectCheckoutSetting,
  selectHasMoqProduct,
} from 'store/selectors/cartSelector';

const CartSummaryMobile = ({ isLoading }) => {
  const { t } = useTranslation();
  const cartData = useSelector(selectCartData);
  const shouldHideCartTotalPrice = useSelector(selectHasMoqProduct);

  const {
    includingVAT: isIncludingVAT,
    headLvlConditions: headLevelConditions,
  } = useSelector(selectCheckoutSetting);

  const renderAppliedVoucher = useCallback(() => {
    const { code: voucherCode, totalDiscount } = cartData?.voucherValue;

    return voucherCode ? (
      <>
        <div className="full-width-page-component__wrapper cart-summary-mobile__footer">
          <span className="cart-summary-mobile__text cart-summary-mobile__text--large cart-summary-mobile__text--bold cart-summary-mobile__text--uppercase">
            {t('voucher.fields.voucherCode')}
          </span>
          <span
            className="cart-summary-mobile__value cart-summary-mobile__text--bold cart-summary-mobile__text--uppercase cart-summary-mobile__text--large"
            align="right"
          >
            {isLoading ? (
              <Skeleton />
            ) : (
              <FormatPrice
                price={totalDiscount}
                currencyUnit={cartData?.currencyUnit}
              />
            )}
          </span>
        </div>
      </>
    ) : null;
  }, [t, isLoading, cartData.voucherValue, cartData.currencyUnit]);

  return (
    <div className="cart-summary-mobile__wrapper">
      <div>
        <div>
          <span className="cart-summary-mobile__text cart-summary-mobile__text__sub-total">
            {t('cart.table.subtotal')}
          </span>
          <span
            className="cart-summary-mobile__value cart-summary-mobile__text--medium"
            align="right"
          >
            {isLoading ? (
              <Skeleton />
            ) : !shouldHideCartTotalPrice ? (
              <FormatPrice
                price={cartData.cartSubTotal}
                currencyUnit={cartData.currencyUnit}
              />
            ) : null}
          </span>
        </div>
        {!!headLevelConditions && (
          <div>
            <span className="cart-summary-mobile__text">
              {t('cart.table.additionalCost')}
            </span>
            <span className="cart-summary-mobile__value" align="right">
              {isLoading ? (
                <Skeleton />
              ) : (
                <FormatPrice
                  price={cartData.additionalCost}
                  currencyUnit={cartData.currencyUnit}
                />
              )}
            </span>
          </div>
        )}
        <div>
          <span className="cart-summary-mobile__text">
            {t('cart.table.shippingCosts')}
          </span>
          <span className="cart-summary-mobile__value" align="right">
            {isLoading ? (
              <Skeleton />
            ) : (
              <FormatPrice
                price={cartData.shippingCosts}
                currencyUnit={cartData.currencyUnit}
              />
            )}
          </span>
        </div>
        <div className="full-width-page-component__wrapper cart-summary-mobile__footer">
          <span className="cart-summary-mobile__text cart-summary-mobile__text--large cart-summary-mobile__text--bold cart-summary-mobile__text--uppercase">
            {t('total')}
          </span>
          <span
            className="cart-summary-mobile__value cart-summary-mobile__text--bold cart-summary-mobile__text--uppercase cart-summary-mobile__text--large"
            align="right"
          >
            {isLoading ? (
              <Skeleton />
            ) : !shouldHideCartTotalPrice ? (
              <FormatPrice
                price={cartData.cartTotal}
                currencyUnit={cartData.currencyUnit}
              />
            ) : null}
          </span>
        </div>

        {renderAppliedVoucher()}

        {isIncludingVAT && (
          <>
            <div className="full-width-page-component__wrapper cart-summary-mobile__footer">
              <span className="cart-summary-mobile__text cart-summary-mobile__text--large cart-summary-mobile__text--bold cart-summary-mobile__text--uppercase">
                {t('vat')}
              </span>
              <span
                className="cart-summary-mobile__value cart-summary-mobile__text--bold cart-summary-mobile__text--uppercase cart-summary-mobile__text--large"
                align="right"
              >
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <FormatPrice
                    price={cartData?.vat}
                    currencyUnit={cartData?.currencyUnit}
                  />
                )}
              </span>
            </div>
            <div className="full-width-page-component__wrapper cart-summary-mobile__footer">
              <span className="cart-summary-mobile__text cart-summary-mobile__text--large cart-summary-mobile__text--bold cart-summary-mobile__text--uppercase">
                {t('totalWithVat')}
              </span>
              <span
                className="cart-summary-mobile__value cart-summary-mobile__text--bold cart-summary-mobile__text--uppercase cart-summary-mobile__text--large"
                align="right"
              >
                {isLoading ? (
                  <Skeleton />
                ) : !shouldHideCartTotalPrice ? (
                  <FormatPrice
                    price={cartData?.cartTotalVAT}
                    currencyUnit={cartData?.currencyUnit}
                  />
                ) : null}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

CartSummaryMobile.propTypes = {
  isLoading: PropTypes.bool,
};

CartSummaryMobile.defaultProps = {
  isLoading: false,
};

export default CartSummaryMobile;
