import './styles.scss';

import clsx from 'clsx';
import FormatPrice from 'components/FormatPrice';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import CustomButton from '../../../../../components/CustomButton';
import ImageWithFallBack from '../../../../../components/ImageWithFallback';
import {
  getInvalidPropTypesError,
  getRequiredPropTypesError,
} from '../../../../../libs/utils/propTypes';
import Tag from '../Tag';

function SpecialItem(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    isLoading,
    tagName,
    img,
    name,
    materialNumber,
    listPrice,
    currencyUnit,
    btnTitle,
  } = props;

  const renderImage = () =>
    isLoading ? (
      <Skeleton className="special-item__image" />
    ) : (
      <ImageWithFallBack
        className="special-item__image"
        src={img?.src}
        alt={`${img?.alt} Product`}
        height={148}
        width={148}
      />
    );

  const renderName = () => (
    <div
      className={clsx('special-item__name', {
        'special-item__name--loading': isLoading,
      })}
    >
      {isLoading ? <Skeleton height={20} /> : name}
    </div>
  );

  const renderMaterialNumber = () => (
    <div
      className={clsx('special-item__material-number', {
        'special-item__material-number--loading': isLoading,
      })}
    >
      {isLoading ? (
        <Skeleton height={30} />
      ) : (
        `${t('myWorkspace.specialsForYou.specialItem.itemNumber', {
          materialNumber,
        })}`
      )}
    </div>
  );

  const renderListPrice = () => (
    <div
      className={clsx('special-item__price--list', {
        'special-item__price--loading': isLoading,
      })}
    >
      {isLoading ? (
        <Skeleton height={20} />
      ) : listPrice ? (
        <FormatPrice price={listPrice} currencyUnit={currencyUnit} />
      ) : (
        t('myWorkspace.specialsForYou.inValidListPrice')
      )}
    </div>
  );

  const renderButton = () => (
    <CustomButton
      className="special-item__button"
      onClick={() => history.push(linkGenerator(`/product/${materialNumber}`))}
      disabled={isLoading}
    >
      {isLoading ? (
        <Skeleton width={87} />
      ) : (
        btnTitle || t('myWorkspace.specialsForYou.specialItem.btnTitle')
      )}
    </CustomButton>
  );

  return (
    <div className="special-item__wrapper">
      {tagName && <Tag isLoading={isLoading} name={tagName} />}
      <div className="special-item__middle-section__wrapper">
        <div className="special-item__middle-section--left">
          {renderImage()}
        </div>
        <div className="special-item__middle-section--right">
          {renderName()}
          {renderMaterialNumber()}
          <div className="special-item__price__wrapper">
            {renderListPrice()}
          </div>
        </div>
      </div>
      <div className="special-item__lower-section__wrapper">
        <div className="special-item__lower-section--left" />
        <div className="special-item__lower-section--right">
          {renderButton()}
        </div>
      </div>
    </div>
  );
}

SpecialItem.propTypes = {
  isLoading: PropTypes.bool,
  tagName: PropTypes.string,
  img: PropTypes.oneOfType([
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string.isRequired,
    }),
    PropTypes.object,
  ]),
  // name: PropTypes.string.isRequired,
  name: (props, propName, componentName) => {
    const { isLoading, [propName]: name } = props;
    if (!isLoading) {
      if (!name) {
        return getRequiredPropTypesError(name, propName, componentName);
      }
      if (typeof name !== 'string') {
        return getInvalidPropTypesError(
          name,
          propName,
          componentName,
          'string'
        );
      }
    }
    return null;
  },
  // materialNumber: PropTypes.string.isRequired,
  materialNumber: (props, propName, componentName) => {
    const { isLoading, [propName]: materialNumber } = props;
    if (!isLoading) {
      if (!materialNumber) {
        return getRequiredPropTypesError(
          materialNumber,
          propName,
          componentName
        );
      }
      if (typeof materialNumber !== 'string') {
        return getInvalidPropTypesError(
          materialNumber,
          propName,
          componentName,
          'string'
        );
      }
    }
    return null;
  },
  listPrice: (props, propName, componentName) => {
    const { isLoading, [propName]: netPrice } = props;
    if (!isLoading) {
      if (netPrice !== 0 && !netPrice) {
        return getRequiredPropTypesError(netPrice, propName, componentName);
      }
      if (typeof netPrice !== 'number') {
        return getInvalidPropTypesError(
          netPrice,
          propName,
          componentName,
          'number'
        );
      }
    }
    return null;
  },
  currencyUnit: PropTypes.string,
  btnTitle: PropTypes.node,
};

SpecialItem.defaultProps = {
  isLoading: false,
  tagName: '',
  img: { src: '' },
  name: '',
  materialNumber: '',
  listPrice: 0,
  currencyUnit: '',
  btnTitle: <Trans i18nKey="myWorkspace.specialsForYou.specialItem.btnTitle" />,
};

export default SpecialItem;
