import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const SearchContext = React.createContext();

function SearchProvider({ children }) {
  const [isSearchFocused, setIsSearchFocused] = React.useState(false);

  const value = useMemo(
    () => ({
      isSearchFocused,
      setIsSearchFocused,
    }),
    [isSearchFocused]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}

function useSearchContext() {
  const searchContext = React.useContext(SearchContext);

  if (searchContext === undefined) {
    throw new Error('useSearchContext must be used within the SearchProvider');
  }

  return searchContext;
}

SearchProvider.propTypes = {
  children: PropTypes.node,
};

SearchProvider.defaultProps = {
  children: {},
};

export { SearchProvider, useSearchContext };
