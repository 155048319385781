import { call, put, takeLatest } from 'redux-saga/effects';
import { fapiRightClusterService } from 'services/adminFapi';

import {
  getRightsList,
  getRightsListFailed,
  getRightsListSuccess,
} from '../slices/rightSlice';

function* getRightsListSaga() {
  try {
    const response = yield call(fapiRightClusterService.getList);
    yield put(getRightsListSuccess(response.data));
  } catch (error) {
    yield put(getRightsListFailed());
  }
}

export default function* rightSaga() {
  yield takeLatest(getRightsList.type, getRightsListSaga);
}
