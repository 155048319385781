import environment from 'environments/environment.dev';
import { FAPI_SERVICE_CASE_ENDPOINT } from 'libs/constants/b2bApiEndpoints';

import HttpClient from './apiHttpClient';

class ServiceCaseService {
  constructor(baseUrl) {
    this.httpClient = new HttpClient(baseUrl);
  }

  createServiceCase(serviceCaseData = {}) {
    const url = `${FAPI_SERVICE_CASE_ENDPOINT}/create`;

    return this.httpClient.post(url, {
      body: serviceCaseData,
    });
  }

  validateMaterialNumber(materialNumber, countryId) {
    const params = new URLSearchParams({
      countryId,
    }).toString();
    const url = `${FAPI_SERVICE_CASE_ENDPOINT}/${materialNumber}/validate?${params}`;

    return this.httpClient.get(url);
  }

  uploadExcludeMachinesList(countryId, fileName, file) {
    const params = new URLSearchParams({
      country: countryId,
      fileName,
    });
    const formData = new FormData();
    formData.append('FormFile', file);
    const url = `${FAPI_SERVICE_CASE_ENDPOINT}/excluded-upload?${params.toString()}`;

    return this.httpClient.post(
      url,
      {
        body: formData,
      },
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
  }

  downloadExcludedMachinesList(countryId) {
    const params = new URLSearchParams({
      countryId,
    }).toString();
    const url = `${FAPI_SERVICE_CASE_ENDPOINT}/excluded-download?${params}`;

    return this.httpClient.get(url);
  }
}

export const serviceCaseService = new ServiceCaseService(
  environment.serviceFAPIUrl
);
