import { getSessionCookieKey } from 'contexts/punchout-context';
import { SESSION_BASED_EXPIRY_TIME } from 'libs/constants';
import { getCookieData, renewCookie } from 'libs/utils/cookies';
import { compareObjects, isEmptyObject } from 'libs/utils/object';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHasSessionBasedShoppingCart } from 'store/selectors/userSelector';

const useSessionBased = () => {
  const hasSessionBased = useSelector(selectHasSessionBasedShoppingCart);
  const [cookieValue, setCookieValue] = useState(null);

  useEffect(() => {
    async function setInitialCookieValue() {
      const cookie = await getCookieData(getSessionCookieKey());
      setCookieValue(cookie);
    }
    setInitialCookieValue();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const currentCookieValue = await getCookieData(getSessionCookieKey());
      if (
        !isEmptyObject(cookieValue) &&
        !isEmptyObject(currentCookieValue) &&
        compareObjects(cookieValue, currentCookieValue)
      ) {
        setCookieValue(currentCookieValue);
        if (currentCookieValue?.value) {
          renewCookie(getSessionCookieKey(), SESSION_BASED_EXPIRY_TIME);
        }
      }
    }, 5000);

    if (!hasSessionBased) {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [cookieValue, hasSessionBased]);

  return { sessionId: cookieValue?.value, hasSessionBased };
};

export default useSessionBased;
