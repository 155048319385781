import environment from 'environments/environment.dev';
import { FAPI_EMPLOYEE_ENDPOINT } from 'libs/constants/b2bApiEndpoints';

import HttpClient from '../apiHttpClient';

const adminFAPIClient = new HttpClient(environment.adminFAPIUrl);

export const fapiEmployeeService = {
  getUserEmployeesById(userId, cancelToken) {
    return adminFAPIClient.get(`${FAPI_EMPLOYEE_ENDPOINT}/${userId}`, {
      cancelToken,
    });
  },

  getEmployeeSuggestionList(query) {
    const queryString = new URLSearchParams({ query });
    return adminFAPIClient.get(
      `${FAPI_EMPLOYEE_ENDPOINT}/suggestion?${queryString.toString()}`
    );
  },

  getEmployeeUserAvailableLanguages(employeeId) {
    const queryString = new URLSearchParams({ userId: employeeId });
    return adminFAPIClient.get(
      `${FAPI_EMPLOYEE_ENDPOINT}/available-languages?${queryString.toString()}`
    );
  },

  addEmployeeUser(params) {
    return adminFAPIClient.post(`${FAPI_EMPLOYEE_ENDPOINT}/create`, {
      body: {
        ...params,
      },
    });
  },

  updateEmployee({ userId, userPolicy, language }) {
    return adminFAPIClient.put(`${FAPI_EMPLOYEE_ENDPOINT}/update`, {
      body: { userId, userPolicy, language },
    });
  },

  deleteEmployeeAccount(userId, cancelToken) {
    return adminFAPIClient.delete(
      `${FAPI_EMPLOYEE_ENDPOINT}/delete?userId=${userId}`,
      {
        cancelToken,
      }
    );
  },
};
