import {
  IPAD_LANDSCAPE_WIDTH,
  SMALL_DEVICE_WIDTH,
  VERY_SMALL_DEVICE_WIDTH,
} from '../constants';
import { OFFSET_TOP, STICKY_STATUS } from '../constants/header';

export const breakpointsBase = ({
  largeValue, // (__, 1024)
  mediumValue, // [1024, 640)
  smallValue, // [640, 480)
  extraSmallValue, // [480, __)
  defaultValue = '0',
}) => {
  if (window.innerWidth > IPAD_LANDSCAPE_WIDTH) {
    return largeValue || defaultValue;
  }
  if (
    window.innerWidth <= IPAD_LANDSCAPE_WIDTH &&
    window.innerWidth > SMALL_DEVICE_WIDTH
  ) {
    return mediumValue || defaultValue;
  }
  if (
    window.innerWidth <= SMALL_DEVICE_WIDTH &&
    window.innerWidth > VERY_SMALL_DEVICE_WIDTH
  ) {
    return smallValue || defaultValue;
  }
  if (window.innerWidth <= VERY_SMALL_DEVICE_WIDTH) {
    return extraSmallValue || defaultValue;
  }

  return defaultValue;
};

// ------ MAIN CONTENT section --------
export const renderBannerHeight = (
  isInMyWorkspace,
  breadcrumbs,
  stickyStatus
) => {
  const pageIsScrolled = stickyStatus === STICKY_STATUS.STATUS_FIXED;

  if (isInMyWorkspace) {
    return pageIsScrolled
      ? breakpointsBase({
          largeValue: '130px',
          mediumValue: '40px',
          smallValue: '40px',
          extraSmallValue: '40px',
          defaultValue: '130px',
        })
      : breakpointsBase({
          largeValue: '130px',
          mediumValue: '40px',
          smallValue: '40px',
          extraSmallValue: '40px',
          defaultValue: '130px',
        });
  }

  return breakpointsBase({
    largeValue: '116px',
    mediumValue: '54px',
    smallValue: '54px',
    extraSmallValue: '54px',
    defaultValue: breadcrumbs?.length > 0 ? '54px' : '70px',
    // mediumValue: '60',
    // extraSmallValue: '162px',
    // defaultValue: breadcrumbs?.length > 0 ? '61px' : '70px',
  });
};

export const renderBannerMarginHeight = (isInMyWorkspace, stickyStatus) => {
  const pageIsScrolled = stickyStatus === STICKY_STATUS.STATUS_FIXED;

  if (isInMyWorkspace) {
    return pageIsScrolled
      ? breakpointsBase({
          // mediumValue: '45px',
          // smallValue: '45px',
          extraSmallValue: '50px',
          defaultValue: '50px',
        })
      : breakpointsBase({
          mediumValue: '0px',
          // smallValue: '50px',
          extraSmallValue: '0px',
          defaultValue: '50px',
        });
  }

  return pageIsScrolled
    ? breakpointsBase({
        mediumValue: '45px',
        // smallValue: '50px',
        extraSmallValue: '44px',
        defaultValue: '60px',
      })
    : breakpointsBase({
        mediumValue: '45px',
        // smallValue: '35px',
        extraSmallValue: '0px',
        defaultValue: '44px',
      });
};

export const renderBannerStickyTop = (isInMyWorkspace, isIpadLandscape) => {
  if (isInMyWorkspace) {
    return isIpadLandscape
      ? breakpointsBase({
          defaultValue: -150,
        })
      : breakpointsBase({
          defaultValue: -56,
        });
  }

  return breakpointsBase({
    defaultValue: 0,
  });
};

export const renderBreadcrumbStickyTop = () => {
  return breakpointsBase({
    largeValue: 173,
    defaultValue: 75,
  });
};

export const renderBreadcrumbMarginHeight = (stickyStatus) => {
  const pageIsScrolled = stickyStatus === STICKY_STATUS.STATUS_FIXED;

  return pageIsScrolled
    ? breakpointsBase({
        defaultValue: '60px',
      })
    : breakpointsBase({
        defaultValue: '0px',
      });
};

export const renderHeaderSearchStickyTop = () => {
  return breakpointsBase({
    largeValue: OFFSET_TOP.HEADER_SEARCH,
    defaultValue: OFFSET_TOP.HEADER_SEARCH_IN_MOBILE,
  });
};
