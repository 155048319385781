import { isValidArray } from './array';

export const replaceSpaceByDash = (str) =>
  // abc def xyz  --->   abc_def_xyz
  (str ?? '').toLowerCase().replaceAll(' ', '_');

export const replaceSpaceWithCamelCase = (str) =>
  // abc def xyz  --->   abcDefXyz
  (str ?? '')
    .split(' ')
    .map((c, i) =>
      i === 0
        ? c[0].toLowerCase() + c.slice(1)
        : c[0].toUpperCase() + c.slice(1)
    )
    .join('');

export const getLanguageCode = (str) =>
  // de-AT --->   de
  // en    --->   en
  (str ?? '').split('-')[0];

export const getCategoriesFromProductDetail = (categoryPath) => {
  let [primaryCategory, subCategory1, subCategory2] = ['', '', ''];

  if (isValidArray(categoryPath)) {
    [primaryCategory, subCategory1, subCategory2] = categoryPath.map(
      (category) => category?.name
    );
  }
  return { primaryCategory, subCategory1, subCategory2 };
};

export const getCategories = (categoryPath = [], categoryId) => {
  /*
   * categoryPath [machine, professional, category, sub-category, class]
   * categoryId is the parent category
   *
   * output: [primaryCategory, subCategory1, subCategory2] = [category, sub-category, class]
   */

  const [primaryCategory = '', subCategory1 = '', subCategory2 = ''] =
    categoryPath
      .slice(
        categoryPath.findIndex(
          (category) => category?.id.toString() === categoryId
        )
      )
      .map((category) => category?.name);

  return {
    primaryCategory,
    subCategory1,
    subCategory2,
  };
};

export const mappingCartAnalysis = (cartProducts = []) => {
  return cartProducts.map((item) => ({
    singlePriceWithTax: item?.price,
    totalPriceWithTax: item?.total,
    quantity: item?.quantity,
    productInfo: {
      productName: item?.name,
      productID: item?.materialNumber,
      ean: '',
    },
  }));
};

const mapTransactionData = (products = []) => {
  return products.map((product) => ({
    productName: product?.name,
    productID: product?.materialNumber,
    price: product?.price,
    quantity: product?.quantity,
  }));
};

export const analyzeTransaction = ({
  orderData = {
    salesOrderNumber: '',
    shippingCosts: 0,
    cartTotal: 0,
    voucherValue: {},
    items: [],
  },
  deliveryData = { deliveryAddress: {} },
}) => {
  const { salesOrderNumber, items, shippingCosts, cartTotal, voucherValue } =
    orderData;

  const {
    deliveryAddress: { zip },
  } = deliveryData;

  return {
    transactionID: salesOrderNumber,
    total: {
      shipmentMethod: 'Standard Delivery',
      shipping: shippingCosts,
      postalCode: zip,
      paymentMethod: 'invoice',
      voucherCode: voucherValue?.code,
      discountValue: voucherValue?.totalDiscount,
      transactionTotal: cartTotal,
      item: mapTransactionData(items),
    },
  };
};

export const analyzeLinkName = (linkName, ...values) => {
  if (isValidArray(values)) {
    return [linkName, ...values].join('_');
  }
  return linkName;
};
