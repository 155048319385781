import './styles.scss';

import { Carousel, Grid } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addProductToCart } from 'store/slices/cartSlice';
import {
  sendProductDetail,
  showAddProductToWishlistModal,
} from 'store/slices/wishlistSlice';

import usePermission from '../../hooks/usePermission';
import { ALL_SEARCH_RESULTS_PER_SECTION } from '../../libs/constants';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../libs/constants/modulerights';
import AddToCartBtn from '../AddToCartBtn';
import B2becPagination from '../B2becPagination';
import ProductCard from '../ProductCard';

const mergeArray = (length = 0) => {
  return (items = []) => {
    if (items.length < length) {
      const listItemsPared = Array(length)
        .fill({
          materialNumber: new Date().toDateString(),
          isEmpty: true,
        })
        .reduce((pre, c, index) => {
          const result = pre;
          const data = items[index];
          result[index] = data || c;

          return result;
        }, []);
      return listItemsPared;
    }

    return items;
  };
};

const { useBreakpoint } = Grid;

const B2BProductCarousel = (props) => {
  const carouselEl = React.useRef();
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const {
    listItems,
    className,
    productLimitPerSection,
    productPerPage,
    isCompareCheckboxDisplayed,
    onCheckHandler,
    comparingProductIds,
  } = props;

  const { hasPermission: hasViewListPricePermission } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_LIST_PRICE
  );

  const settings = () => {
    if (breakpoint.xl) {
      return { slidesToShow: productPerPage, slidesToScroll: productPerPage };
    }

    if (breakpoint.md) {
      return { slidesToShow: 2, slidesToScroll: 2 };
    }

    return {
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  };

  const onAddProductToCart = useCallback(
    (materialId) => {
      dispatch(addProductToCart({ materialNumber: materialId }));
    },
    [dispatch]
  );

  const handleAddToWishlist = ({ materialName, materialNumber, img }) => {
    dispatch(
      sendProductDetail({
        materialName,
        materialNumber,
        img,
      })
    );
    dispatch(showAddProductToWishlistModal());
  };

  const configs = settings();

  const listItemsMapFunction = mergeArray(configs.slidesToShow);

  return (
    <div className={`b2b-carousel ${className}`}>
      <Carousel {...settings()} ref={carouselEl} dots={false}>
        {listItemsMapFunction(listItems)
          .slice(0, ALL_SEARCH_RESULTS_PER_SECTION)
          .map((item) => (
            <div
              key={`${item?.materialNumber}-${item?.name}-${item?.category}`}
              className="b2b-carousel__item"
            >
              {item.isEmpty ? null : (
                <ProductCard
                  className="--no-shadow"
                  {...item}
                  requiresInstruction={item.requiresInstruction}
                  listPrice={item?.listPrice || item?.price}
                  currencyUnit={item?.currencyUnit || item?.currency}
                  isCompareCheckboxDisplayed={isCompareCheckboxDisplayed}
                  shouldDisplayListPrice={
                    item?.displayListPrice && hasViewListPricePermission
                  }
                  onCheckHandler={onCheckHandler}
                  comparingProductIds={comparingProductIds}
                  addToCartBtn={
                    <AddToCartBtn
                      handleClick={() =>
                        onAddProductToCart(item?.materialNumber)
                      }
                    />
                  }
                  onAddToWishlist={() =>
                    handleAddToWishlist({
                      materialName: item?.name,
                      materialNumber: item?.materialId || item?.materialNumber,
                      img: item?.img || item?.imageUrl,
                    })
                  }
                />
              )}
            </div>
          ))}
      </Carousel>
      <div className="b2b-carousel__dots mt-4">
        <B2becPagination
          size="small"
          total={productLimitPerSection}
          pageSize={configs.slidesToShow}
          onPageChange={(page, pageSize) => {
            const offset = page * pageSize - pageSize;

            carouselEl.current.goTo(offset);
          }}
        />
      </div>
    </div>
  );
};

B2BProductCarousel.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      name: PropTypes.string,
      img: PropTypes.string,
      category: PropTypes.string,
      materialNumber: PropTypes.string,
      serialNo: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
  productLimitPerSection: PropTypes.number,
  productPerPage: PropTypes.number,
  className: PropTypes.string,
  isCompareCheckboxDisplayed: PropTypes.bool,
  onCheckHandler: PropTypes.func,
  comparingProductIds: PropTypes.arrayOf(PropTypes.string),
};

B2BProductCarousel.defaultProps = {
  productLimitPerSection: 6,
  productPerPage: 3,
  className: null,
  isCompareCheckboxDisplayed: false,
  onCheckHandler: () => {},
  comparingProductIds: [],
};

export default B2BProductCarousel;
