import './styles.scss';

import { Col, Divider, Form, Row } from 'antd';
import Translator from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import { NotificationManager } from 'components/Notification';
import ProductListWithPagination from 'components/ProductListWithPagination';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { ASYNC_STATUS } from 'libs/constants';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetchingMachinesList,
  selectMachineActionStatus,
  selectMachinesList,
  selectMachinesListTotal,
} from 'store/selectors/machineSelector';
import { getSelectedMachineData } from 'store/selectors/serviceCaseSelector';
import { getMachinesList } from 'store/slices/machineSlice';
import { setSelectedMachineInfo } from 'store/slices/serviceCaseSlice';

import SelectNewMachineBlock from '../../components/SelectNewMachineBlock';

const MachineSelectStep = (props) => {
  const { currentTab, handleChangeTab } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isMobile } = useDeviceDetect();

  const selectedMachineData = useSelector(getSelectedMachineData);
  const isFetchingMachinesList = useSelector(selectIsFetchingMachinesList);
  const machinesList = useSelector(selectMachinesList);
  const total = useSelector(selectMachinesListTotal);
  const actionStatus = useSelector(selectMachineActionStatus);

  const {
    id: machineId,
    serialNumber,
    isExcludedMachine,
    validateStatus,
  } = selectedMachineData;

  const onChangeTabHandler = useCallback(() => {
    if (isExcludedMachine) {
      return;
    }

    handleChangeTab(+currentTab + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [handleChangeTab, currentTab, isExcludedMachine]);

  const onSelectMachineHandler = useCallback(
    (machine) => {
      dispatch(
        setSelectedMachineInfo({
          id: machine?.materialNumber,
          name: machine?.name,
          image: machine?.imgUrl,
          serialNumber: machine?.serialNumber,
          faqList: [],
          isExcludedMachine: false,
          validateStatus: ASYNC_STATUS.SUCCESS,
        })
      );

      setTimeout(() => {
        window.scrollTo(0, 0);
        handleChangeTab(+currentTab + 1);
      }, 1000);
    },
    [handleChangeTab, currentTab, dispatch]
  );

  const isDisableNextStepButton =
    isExcludedMachine || validateStatus === ASYNC_STATUS.PENDING;

  const getMachineListHandler = useCallback(
    (currentPage, pageSize) => {
      dispatch(
        getMachinesList({
          pageNumber: currentPage,
          pageSize,
          sortOrder: 'ByOrderDateDesc',
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentTab !== 1 && !machineId && !serialNumber) {
      // prevent to change tab if user doesn't select machine
      NotificationManager.error({
        message: 'notification.error.mustSelectionMachine',
      });

      handleChangeTab(1);
    }
  }, [currentTab, handleChangeTab, machineId, serialNumber]);

  return (
    <div className="service-step__machine">
      <div className="service-step__machine__description">
        <Translator
          value="service.selectMachine.description"
          isComponent
          htmlTags={[<div key="description" />]}
        />
      </div>

      {/* filter area */}
      <div className="service-step__machine__filter-area">
        {/* Filter area */}
      </div>

      {/* machine selection list */}
      <ProductListWithPagination
        getListHandler={getMachineListHandler}
        classNames="service-step__machine-list"
        isLoading={isFetchingMachinesList}
        total={total}
        actionStatus={actionStatus}
        listData={machinesList}
        onSelectMachineHandler={onSelectMachineHandler}
        isMachineSelection
        isMobile={isMobile}
      />

      {/* find and select machine by id */}
      <div className="service-step__search-machine">
        <Divider />
        <SelectNewMachineBlock selectedMachineData={selectedMachineData} />
      </div>

      {/* buttons wrapper */}
      <Row gutter={[24, 24]}>
        <Divider />
        <Col span={24} md={12} lg={{ span: 8, push: 16 }}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldError }) => {
              const serialError = getFieldError('serialNumber');
              const isDisabled = (() => {
                if (!machineId) {
                  return true;
                }
                return !!serialError.length || isDisableNextStepButton;
              })();
              return (
                <CustomButton
                  block
                  disabled={isDisabled}
                  onClick={onChangeTabHandler}
                >
                  {t('service.button.next')}
                </CustomButton>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

MachineSelectStep.propTypes = {
  currentTab: PropTypes.number.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
};

MachineSelectStep.defaultProps = {};

export default MachineSelectStep;
