import { put, takeLatest } from 'redux-saga/effects';
import { adminFapiVoucher } from 'services/fapi/adminFapi';

import { voucherActions } from 'store/slices/voucherSlice';

function* getVoucherListSaga() {
  try {
    const { data, status } = yield adminFapiVoucher.getVoucherList();

    if (status === 200) {
      yield put(voucherActions.getVoucherListSuccess(data));
    } else {
      yield put(voucherActions.getVoucherListFailure());
    }
  } catch (_) {
    yield put(voucherActions.getVoucherListFailure());
  }
}

function* voucherSaga() {
  yield takeLatest(voucherActions.getVoucherList.type, getVoucherListSaga);
}

export default voucherSaga;
