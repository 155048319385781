import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const adminFAPIClient = new HttpClient(environment.adminFAPIUrl);

const RIGHT_ENDPOINT = '/v1.0/right';

export const fapiRightService = {
  getList() {
    return adminFAPIClient.get(`${RIGHT_ENDPOINT}/list`);
  },
  create(data) {
    return adminFAPIClient.post(RIGHT_ENDPOINT, {
      body: data,
    });
  },
  delete(rightId) {
    return adminFAPIClient.delete(RIGHT_ENDPOINT, {
      queryParams: { rightId },
    });
  },
};
