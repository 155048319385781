import environment from 'environments/environment.dev';
import { FAPI_REPORT_ENDPOINT } from 'libs/constants/b2bApiEndpoints';

import HttpClient from './apiHttpClient';

class ReportService {
  constructor(baseUrl) {
    this.httpClient = new HttpClient(baseUrl);
    this.baseEndpoint = FAPI_REPORT_ENDPOINT;
  }

  exportShoppingCart({ sessionId }) {
    const searchParams = new URLSearchParams();
    let url = `${this.baseEndpoint}/summary`;

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
      url = url.concat(`?${searchParams}`);
    }

    return this.httpClient.get(url);
  }
}

export const reportService = new ReportService(environment.shopFAPIUrl);
