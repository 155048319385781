import './styles.scss';

import { Modal } from 'antd';
import CustomButton from 'components/CustomButton';
import SmallLoadingIcon from 'components/SmallLoadingIcon';
import { isValidArray } from 'libs/utils/array';
import { formatMaterialID } from 'libs/utils/material-number';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCartData,
  selectIsSmallLoading,
} from 'store/selectors/cartSelector';
import { addProductToCart } from 'store/slices/cartSlice';

import RemoveCartItem from '../RemoveCartItem';

function AddQuickEntry() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [errMsg, setErrMsg] = useState(null);

  const items = useSelector(selectCartData);
  const isLoading = useSelector(selectIsSmallLoading);

  const handleDuplicateCardItems = useCallback(
    (str) => {
      if (
        str.length === 11 &&
        RegExp('^([0-9]{1}).([0-9]{3})-([0-9]{3}).([0-9]{1})').test(str)
      ) {
        const isProductAlreadyInCart =
          isValidArray(items) &&
          items.some((item) => item.materialNumber === str);

        if (isProductAlreadyInCart) {
          Modal.warning({
            title: t('cart.duplicate'),
          });
        } else {
          dispatch(addProductToCart({ materialNumber: str }));
        }
      } else {
        setErrMsg(t('errors.stringFormat'));
      }
    },
    [dispatch, t, items]
  );

  const handleInputValue = (str) => {
    if (str.length < 11) {
      if (RegExp('[0-9]{8}').test(str)) {
        setErrMsg(null);
        const formattedStr = formatMaterialID(str);
        setInputValue(formattedStr);
        return formattedStr;
      }
      setErrMsg(null);
      return str;
    }
    if (str.length === 11) {
      if (RegExp('^([0-9]{1}).([0-9]{3})-([0-9]{3}).([0-9]{1})').test(str)) {
        setErrMsg(null);
        return str;
      }
      setErrMsg(t('errors.stringFormat'));
      return str;
    }
    setErrMsg(t('errors.maxInputExceeded'));
    return null;
  };

  const searchProductById = (e) => {
    const formattedVal = handleInputValue(e.target.value.trim());
    if (formattedVal !== null) {
      setInputValue(formattedVal);
    }
  };

  return (
    <div className="quick-entry__wrapper">
      <div className="quick-entry__input__wrapper">
        <div className="quick-entry__left">
          <span className="quick-entry__title">
            {t('cart.addQuickEntryBtn')}
          </span>
          <input
            id="AddQuickEntry"
            data-testid="AddQuickEntry"
            onChange={searchProductById}
            value={inputValue}
            className="custom-input"
            disabled={isLoading}
            autoComplete="off"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            placeholder={t('cart.addQuickEntryPlaceHolder')}
          />
        </div>

        <div className="quick-entry__button__wrapper">
          <CustomButton
            type="ghost"
            className="quick-entry__button"
            onClick={() => {
              handleDuplicateCardItems(inputValue);
            }}
          >
            {isLoading ? <SmallLoadingIcon /> : t('cart.buttons.addArticle')}
          </CustomButton>
          <div className="quick-entry__button--remove-btn">
            <RemoveCartItem materialNumber={null} />
          </div>
        </div>
      </div>
      {errMsg ? (
        <p className="quick-entry__input__wrapper__message">{errMsg}</p>
      ) : null}
    </div>
  );
}

export default AddQuickEntry;
