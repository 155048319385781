import { JumpTopIcon } from 'components/Icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './BackToTopButton.module.scss';

function BackToTopButton() {
  const { t } = useTranslation();

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={styles.container}>
      <button
        aria-label="back-to-top-button"
        type="button"
        className={styles.wrapper}
        onClick={handleClick}
      >
        <span className={styles.text}>{t('backToTop')}</span>
        <JumpTopIcon />
      </button>
    </div>
  );
}

export default BackToTopButton;
