import LanguageDetector from 'i18next-browser-languagedetector';
import { findLocaleInUrl } from 'libs/constants/lang';

const PathDetector = {
  name: 'customPathDetector',
  lookup() {
    return findLocaleInUrl(window.location.pathname) || 'en';
  },
};

const Detector = new LanguageDetector(null, {
  order: ['customPathDetector', 'localStorage'],
  lookupLocalStorage: 'lng',
  caches: [],
  excludeCacheFor: ['cimode'],
});

Detector.addDetector(PathDetector);

export default Detector;
