const addressObject = ({
  name,
  name2,
  street,
  houseNumber,
  street4,
  zip,
  city,
  country,
}) => {
  return {
    name,
    name2,
    street,
    houseNumber,
    street4,
    zip,
    city,
    country,
  };
};

export const filterAddressByTerms = (dataSource, searchTerms) => {
  let result = dataSource;

  searchTerms.forEach((term) => {
    result = result.filter((address) => {
      const addressText = Object.values(addressObject(address)).join(' ');
      const reTerm = new RegExp(term, 'i');
      return reTerm.test(addressText);
    });
  });

  return result;
};

export const getPaginateData = (arr, size, page) => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return [];
  }

  return arr.slice(page * size - size, page * size);
};

export const addSearchTerms = (terms, newTerm) => {
  const termsLowerCase = terms.map((term) => term.toLowerCase());
  const newTermLowerCase = newTerm.toLowerCase();
  if (termsLowerCase.includes(newTermLowerCase)) {
    return terms;
  }

  return [...terms, newTerm];
};
