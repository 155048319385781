import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as LeftArrow } from '../../../../../../assets/icons/chevron-left.svg';

function BackButton(props) {
  const { name, onClick } = props;
  return (
    <button
      className="header-menu-drawer__back-button__wrapper"
      type="button"
      onClick={onClick}
    >
      <div className="header-menu-drawer__back-button">
        <span>{name}</span>
        <LeftArrow />
      </div>
    </button>
  );
}

BackButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  onClick: () => {},
};

export default BackButton;
