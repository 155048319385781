import './styles.scss';

import { Col, Row } from 'antd';
import B2becOverviewGuideBlock, {
  B2BEC_OVERVIEWGUIDE_TYPES,
} from 'components/B2becOverviewGuideBlock';
import React from 'react';

import PermissionWrapper from '../../../HOCs/permissionWrapper';
import { MODULE_MACHINE_RIGHTS } from '../../../libs/constants/modulerights';

const OverviewGuide = () => {
  return (
    <div className="overview-guide">
      <Row gutter={[24, 24]}>
        <PermissionWrapper permission={MODULE_MACHINE_RIGHTS.VIEW_MACHINE_LIST}>
          <Col span={24} lg={8}>
            <B2becOverviewGuideBlock
              type={B2BEC_OVERVIEWGUIDE_TYPES.MY_MACHINES}
            />
          </Col>
        </PermissionWrapper>
        <Col span={24} lg={8}>
          <B2becOverviewGuideBlock type={B2BEC_OVERVIEWGUIDE_TYPES.MY_ORDERS} />
        </Col>
        <Col span={24} lg={8}>
          <B2becOverviewGuideBlock type={B2BEC_OVERVIEWGUIDE_TYPES.PRODUCTS} />
        </Col>
      </Row>
    </div>
  );
};

export default OverviewGuide;
