import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const SUPPORT_GET_USERS_LIST = '/v1.0/support/users/list';
const SUPPORT_ADD_TO_CART = '/v1.0/support/item/add';
const SUPPORT_EMPTY_CART = '/v1.0/support/items/empty';
const SUPPORT_GET_MACHINES_LIST = '/v1.0/support/maschine/list';
const SUPPORT_GET_ORDER_DETAILS = '/v1.0/support/order/{orderNumber}/detail';
const SUPPORT_GET_ORDERS_LIST = '/v1.0/support/order/list';
const SUPPORT_GET_CART = '/v1.0/support/simulate';
const SUPPORT_SYNC_ORDERS = '/v1.0/support/ordersync';
const SUPPORT_CLEAR_CACHE = '/v1.0/utility/deletecountrykeys';

const fapiClient = new HttpClient(environment.adminFAPIUrl);

const getUsersList = (payload) => {
  const queryParams = {
    pageNumber: payload.current,
    pageSize: payload.pageSize,
    sortBy: payload.sortBy,
    orderBy: payload.orderBy,
    searchTerm: payload.searchTerm,
    selectedLetter: payload.selectedLetter,
    countryId: payload.country,
  };
  return fapiClient.get(SUPPORT_GET_USERS_LIST, { queryParams });
};

const addProductToCustomerCart = ({
  materialNumber,
  quantity,
  countryId,
  userId,
}) =>
  fapiClient.post(SUPPORT_ADD_TO_CART, {
    body: { materialNumber, quantity, countryId, userId },
  });

const deleteCartForGivenUserId = ({ userId }) =>
  fapiClient.delete(SUPPORT_EMPTY_CART, { body: { userId } });

const getMachinesListByCustomerNumber = ({
  userId,
  pageNumber,
  pageSize,
  sortOrder,
}) =>
  fapiClient.get(SUPPORT_GET_MACHINES_LIST, {
    queryParams: { userId, pageNumber, pageSize, sortOrder },
  });

const getOrderByUser = (userId, countryId, orderNumber) =>
  fapiClient.get(
    SUPPORT_GET_ORDER_DETAILS.replace('{orderNumber}', orderNumber),
    { queryParams: { userId, countryId } }
  );

const getAllOrdersByCustomer = (
  customerId,
  countryId,
  pageNumber = 1,
  pageSize = 10
) =>
  fapiClient.get(SUPPORT_GET_ORDERS_LIST, {
    queryParams: { customerId, countryId, pageNumber, pageSize },
  });

const getCartDataByUserId = (userId) =>
  fapiClient.get(SUPPORT_GET_CART, { queryParams: { userId } });

const synchronizeOrdersByUserId = (userId) =>
  fapiClient.post(SUPPORT_SYNC_ORDERS, {
    queryParams: { userId },
    body: {},
  });

const clearCacheForCountry = (countryId) =>
  fapiClient.delete(SUPPORT_CLEAR_CACHE, {
    queryParams: { countryId },
  });

export const adminFapiSupport = {
  getUsersList,
  addProductToCustomerCart,
  deleteCartForGivenUserId,
  getMachinesListByCustomerNumber,
  getOrderByUser,
  getAllOrdersByCustomer,
  getCartDataByUserId,
  synchronizeOrdersByUserId,
  clearCacheForCountry,
};
