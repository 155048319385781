import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const USER_GET_PROFILE = '/v1.0/user/profile';
const USER_CHANGE_LANGUAGE = '/v1.0/user/changelanguage';
const USER_CHANGE_PASSWORD = '/v1.0/user/changepassword';
const CONTACT_REASON_LIST = '/v1.0/contactreason/contact';
const CREATE_CONTACT_REQUEST = '/v1.0/contactreason/user/contact';

const shopFAPIClient = new HttpClient(environment.shopFAPIUrl);

export const fapiUserService = {
  getUserDetailsById(_userId, cancelToken) {
    return shopFAPIClient.get(USER_GET_PROFILE, {
      cancelToken,
    });
  },

  changeUserLanguage(_userId, language) {
    return shopFAPIClient.post(USER_CHANGE_LANGUAGE, {
      queryParams: {
        language,
      },
      body: {},
    });
  },

  setNewPasswordApi(_userId, oldPassword, newPassword) {
    return shopFAPIClient.post(USER_CHANGE_PASSWORD, {
      body: { oldPassword, newPassword },
    });
  },

  getContactReasonList(pageSize, pageNumber) {
    return shopFAPIClient.get(CONTACT_REASON_LIST, {
      queryParams: { pageNumber, pageSize },
    });
  },

  submitContactRequest(contactReasonId, contactReasonName, message) {
    return shopFAPIClient.post(CREATE_CONTACT_REQUEST, {
      body: { contactReasonId, contactReasonName, message },
    });
  },
};
