import './styles.scss';

import B2becSpinner from 'components/B2becSpinner';
import CustomButton from 'components/CustomButton';
import usePermission from 'hooks/usePermission';
import { MODULE_DEBUGGING_RIGHTS } from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetchingSAPLog,
  selectSAPLogData,
  selectShouldShowSAPLogView,
} from 'store/selectors/sapLogSelectors';
import { showSAPLogView } from 'store/slices/SAPLogSlice';

const SAP_LOG_TYPE = {
  REQUEST: 'Request',
  RESPONSE: 'Response',
};

const SAPLogView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const SAPLogData = useSelector(selectSAPLogData);
  const isFetchingSAPLog = useSelector(selectIsFetchingSAPLog);
  const shouldShowSAPLogView = useSelector(selectShouldShowSAPLogView);
  const { hasPermission: canReadSAPLog } = usePermission(
    MODULE_DEBUGGING_RIGHTS.READ_DEBUG
  );

  const onCloseViewLog = useCallback(() => {
    dispatch(showSAPLogView(false));
  }, [dispatch]);

  const sapRequestLogs = useMemo(() => {
    return (SAPLogData ?? []).filter(
      (item) => item?.type === SAP_LOG_TYPE.REQUEST
    );
  }, [SAPLogData]);

  const sapResponseLogs = useMemo(() => {
    return (SAPLogData ?? []).filter(
      (item) => item?.type === SAP_LOG_TYPE.RESPONSE
    );
  }, [SAPLogData]);

  return canReadSAPLog && shouldShowSAPLogView ? (
    <div className="sap-log__wrapper">
      {isFetchingSAPLog && (
        <B2becSpinner isComponentLoading isLoading={isFetchingSAPLog} />
      )}
      <div>
        <CustomButton
          type="ghost"
          className="inverted"
          buttonSize="small"
          onClick={onCloseViewLog}
        >
          {t('common.button.hideMessages')}
        </CustomButton>
      </div>
      <SapLogListItem sapLogData={sapRequestLogs} />
      <SapLogListItem sapLogData={sapResponseLogs} />
    </div>
  ) : null;
};

const SapLogListItem = ({ sapLogData }) => (
  <div className="sap-log__list">
    {(sapLogData ?? []).map((item) => (
      <div className="sap-log__list--item" key={item?.execTime}>
        {JSON.stringify(item)}
      </div>
    ))}
  </div>
);

SapLogListItem.propTypes = {
  sapLogData: PropTypes.arrayOf(PropTypes.shape({})),
};

SapLogListItem.defaultProps = {
  sapLogData: [],
};

export default SAPLogView;
