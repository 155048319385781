import { Breadcrumb } from 'antd';
import B2BecLink from 'components/B2BLink';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import useDeviceDetect from '../../hooks/useDeviceDetect';

function B2becBreadcrumbs(props) {
  const { breadcrumbs } = props;

  const { isIpadLandscape } = useDeviceDetect();

  const breadcrumbsContainer = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const childrenNodes = breadcrumbsContainer.current?.children;
    if (childrenNodes?.length > 0) {
      const offsetWidth = childrenNodes[0]?.offsetWidth;
      const scrollWidth = childrenNodes[0]?.scrollWidth;
      if (scrollWidth > offsetWidth) {
        setIsOverflowed(true);
      } else {
        setIsOverflowed(false);
      }
    }
  }, [breadcrumbsContainer, breadcrumbs, setIsOverflowed]);

  const buildBreadcrumbs = useCallback(
    (originalBreadcrumbs) => {
      const breadcrumbsToShow = [];
      if (isIpadLandscape) {
        if (originalBreadcrumbs) {
          const { length } = originalBreadcrumbs;
          if (length >= 2) {
            // There are 2 or more than 2 breadcrumbs
            let parent = originalBreadcrumbs[length - 2]; // Parent Page
            if (parent.title) {
              // Title is exist to show
              breadcrumbsToShow.push(parent);
            } else {
              // Find another closest existing parent breadcrumbs to show
              let i = 3;
              while (length - i >= 0) {
                parent = originalBreadcrumbs[length - i]; // Parent Page
                if (parent.title) {
                  breadcrumbsToShow.push(parent);
                  break;
                }
                i += 1;
              }
            }
          }
        }
        return breadcrumbsToShow;
      }
      if (isOverflowed) {
        if (originalBreadcrumbs) {
          const { length } = originalBreadcrumbs;
          if (length > 0) {
            breadcrumbsToShow.push(originalBreadcrumbs[0]); // Home
          }
          if (length > 3) {
            // There are 4 breadcrumbs
            breadcrumbsToShow.push({ title: '...' });
            breadcrumbsToShow.push(originalBreadcrumbs[length - 2]); // Parent Page
            breadcrumbsToShow.push(originalBreadcrumbs[length - 1]); // Current Page
          } else if (length > 2) {
            // There are 3 breadcrumbs
            breadcrumbsToShow.push(originalBreadcrumbs[length - 2]); // Parent Page
            breadcrumbsToShow.push(originalBreadcrumbs[length - 1]); // Current Page
          } else if (length > 1) {
            // There are 2 breadcrumbs
            breadcrumbsToShow.push(originalBreadcrumbs[length - 1]); // Current Page
          }
        }
        return breadcrumbsToShow;
      }
      return originalBreadcrumbs && originalBreadcrumbs.length > 0
        ? originalBreadcrumbs
        : [];
    },
    [isIpadLandscape, isOverflowed]
  );

  const breadcrumbsToShow = buildBreadcrumbs(breadcrumbs);

  return (
    <div ref={breadcrumbsContainer}>
      <Breadcrumb separator=">">
        {breadcrumbsToShow.map(
          (breadcrumb) =>
            breadcrumb.title && (
              <Breadcrumb.Item key={`${breadcrumb.title}_${breadcrumb.path}`}>
                <B2BecLink to={breadcrumb.path}>
                  {isIpadLandscape ? `< ${breadcrumb.title}` : breadcrumb.title}
                </B2BecLink>
              </Breadcrumb.Item>
            )
        )}
      </Breadcrumb>
    </div>
  );
}

B2becBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
};

B2becBreadcrumbs.defaultProps = {};

export default B2becBreadcrumbs;
