import './styles.scss';

import { Collapse, Empty } from 'antd';
import ProductComparisonModal from 'components/ProductComparisonModal';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useProductComparison from 'hooks/useProductComparison';
import { PAGE_TYPES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { requestSearch } from 'store/slices/searchSlice';

import B2becAnchor from '../../components/B2becAnchor';
import B2becCollapse from '../../components/B2becCollapse';
import B2BProductCarousel from '../../components/B2BProductCarousel';
import CustomButton from '../../components/CustomButton';
import B2becCardSkeleton from '../../components/Skeletons/B2becCardSkeleton';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import {
  ALL_SEARCH_RESULTS_PER_SECTION,
  SEARCH_RESULTS_PER_SECTION_SIZE,
} from '../../libs/constants';
import { DEFAULT_LANGUAGES } from '../../libs/constants/lang';
import {
  selectAccessoryAllAmount,
  selectAccessoryList,
  selectAccessoryLoading,
  selectDetergentAllAmount,
  selectDetergentList,
  selectDetergentLoading,
  selectProductAllAmount,
  selectProductList,
  selectProductLoading,
} from '../../store/selectors/searchSelector';

const { Panel } = Collapse;

const AVAILABLE_PRODUCT_TYPES = ['machines', 'accessories', 'detergents'];

const createSectionData = (panelData, dataList, allDataAmount) => {
  if (!allDataAmount) {
    return null;
  }

  return {
    panelData,
    dataList,
    allDataAmount,
  };
};

const SearchResultsPage = (props) => {
  const { searchParam } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs, [{ pathData: [searchParam] }]);

  useAdobeAnalysis(TRACK_DATA.ONSITE, {
    pageId,
    pageType: PAGE_TYPES.SEARCH_RESULTS,
    pageName: 'AllSearchResults',
  });

  const numberOfSkeleton = 3;
  const heightOfSkeleton = 314;
  const pageSize = ALL_SEARCH_RESULTS_PER_SECTION;
  const pageNumber = 1;
  const languageCode =
    localStorage.getItem('lng') || DEFAULT_LANGUAGES[0].value;

  const {
    showComparisonModal,
    setShowComparisonModal,
    onCompareHandler,
    productInformation,
    comparingProductIds,
  } = useProductComparison();

  const onCheckHandler = useCallback(
    (product, event) => {
      const isChecked = event?.target?.checked;

      onCompareHandler(isChecked, product);
    },
    [onCompareHandler]
  );

  useEffect(() => {
    if (searchParam) {
      dispatch(
        requestSearch({
          searchAll: false,
          value: searchParam,
          size: pageSize,
          from: pageSize * (pageNumber - 1),
          lang: languageCode,
        })
      );
    }
  }, [dispatch, searchParam, pageSize, pageNumber, languageCode]);

  const {
    isProductLoading,
    isAccessoryLoading,
    isDetergentLoading,
    machinesList,
    accessoriesList,
    detergentsList,
    machinesAllAmounts,
    accessoryAllAmount,
    detergentAllAmount,
  } = useSelector((state) => ({
    // loading status
    isProductLoading: selectProductLoading(state),
    isAccessoryLoading: selectAccessoryLoading(state),
    isDetergentLoading: selectDetergentLoading(state),
    // list data
    machinesList: selectProductList(state),
    accessoriesList: selectAccessoryList(state),
    detergentsList: selectDetergentList(state),
    // all amount
    machinesAllAmounts: selectProductAllAmount(state),
    accessoryAllAmount: selectAccessoryAllAmount(state),
    detergentAllAmount: selectDetergentAllAmount(state),
  }));

  const availableData = AVAILABLE_PRODUCT_TYPES.reduce((result, c) => {
    let data = {};
    if (c === 'machines') {
      data = createSectionData(c, machinesList, machinesAllAmounts);
    }

    if (c === 'accessories') {
      data = createSectionData(c, accessoriesList, accessoryAllAmount);
    }

    if (c === 'detergents') {
      data = createSectionData(c, detergentsList, detergentAllAmount);
    }

    if (data !== null) {
      return { ...result, [c]: data };
    }

    return result;
  }, {});

  const listKeys = Object.keys(availableData);

  const listTranslatedKeys = listKeys.map((key) => ({
    key,
    translationKey: `searchResultsPage.header.${key}`,
  }));

  const listData = Object.values(availableData);

  const onShowAllHandler = (productType) => {
    history.push(linkGenerator(`/search-detail/${productType}/${searchParam}`));
  };

  const renderSection = (panelData, dataList, allDataAmount) => {
    let content = <Empty />;
    if (dataList) {
      content = (
        <div className="mb-4">
          <B2BProductCarousel
            className="product-card__wrapper mb-5"
            listItems={dataList}
            productLimitPerSection={
              dataList.length >= ALL_SEARCH_RESULTS_PER_SECTION
                ? ALL_SEARCH_RESULTS_PER_SECTION
                : dataList.length
            }
            productsPerPage={SEARCH_RESULTS_PER_SECTION_SIZE}
            isCompareCheckboxDisplayed
            onCheckHandler={onCheckHandler}
            comparingProductIds={comparingProductIds}
          />
          <div className="search-results__button-wrapper">
            <CustomButton
              type="ghost"
              onClick={() => onShowAllHandler(panelData)}
            >
              {t(`searchResultsPage.button.showAll.${panelData}`, {
                count: allDataAmount,
              })}
            </CustomButton>
          </div>
        </div>
      );
    }

    return (
      allDataAmount > 0 && (
        <Panel
          header={t(`searchResultsPage.header.${panelData}`)}
          key={panelData}
          id={panelData}
        >
          {content}
        </Panel>
      )
    );
  };

  return searchParam &&
    (isProductLoading || isDetergentLoading || isAccessoryLoading) ? (
    <div style={{ marginTop: '50px' }}>
      <B2becCardSkeleton
        number={numberOfSkeleton}
        imageHeight={heightOfSkeleton}
        columns={{ xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 8 }}
      />
    </div>
  ) : listTranslatedKeys.length > 0 ? (
    <>
      <div className="container-full-width-page">
        <div className="search-results">
          <div className="search-results__header">
            <div className="search-results__header__title mb-4">
              {t('searchResultsPage.title', {
                count:
                  +machinesAllAmounts +
                  +accessoryAllAmount +
                  +detergentAllAmount,
              }).trim()}{' '}
              <q>{searchParam}</q>
            </div>
          </div>
          <B2becAnchor listKeys={listTranslatedKeys} />

          <div className="search-results__content">
            <B2becCollapse
              defaultActiveKey={listKeys}
              expandIconPosition="right"
              bordered
              ghost
            >
              {listData.map(({ panelData, dataList, allDataAmount }) =>
                renderSection(panelData, dataList, allDataAmount)
              )}
            </B2becCollapse>
          </div>
        </div>
      </div>

      <ProductComparisonModal
        showModal={showComparisonModal}
        setShowModal={setShowComparisonModal}
        productsData={productInformation}
      />
    </>
  ) : (
    <div className="empty-content__wrapper">
      <Empty />
    </div>
  );
};

SearchResultsPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default SearchResultsPage;
