import './styles.scss';

import Footer from 'components/Layout/components/Footer';
import Header from 'components/PublicHeader/Header';
import ScrollToTop from 'components/ScrollToTop';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { parseHtml } from '../../../libs/utils/common';

const Imprint = ({ title, pageId }) => {
  useDocumentTitle(title);
  const { t, i18n } = useTranslation();

  useAdobeAnalysis(null, {
    pageName: 'Imprint',
    pageId,
    pageType: PAGE_TYPES.POLICY,
  });

  const renderSections = () => {
    const sections = [];
    let i = 1;

    while (i18n.exists(`imprint.section${i}`)) {
      sections.push(
        <div className="imprint-section" key={`section-${i}`}>
          <section>{parseHtml(t(`imprint.section${i}`))}</section>
        </div>
      );
      i += 1;
    }

    const addedDividerSections = sections.reduce((prev, curr, index) => {
      return [
        prev,
        // eslint-disable-next-line react/no-array-index-key
        <div key={`divide-${index}`} className="row">
          <hr className="hrbold" />
        </div>,
        curr,
      ];
    });

    return addedDividerSections;
  };

  return (
    <>
      <Header />
      <ScrollToTop />
      <div className="imprint-wrapper">
        <div className="imprint-wrapper__container-width">
          {renderSections()}
        </div>
      </div>
      <Footer />
    </>
  );
};

Imprint.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default Imprint;
