import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

const B2becSpinner = (props) => {
  const { isLoading, isComponentLoading } = props;

  return (
    <>
      {isLoading && (
        <div
          className={
            isComponentLoading ? 'spinner__component-loader' : 'spinner__loader'
          }
          data-testid="loading-icon"
        >
          <div className="spinner__loader__icon" />
        </div>
      )}
    </>
  );
};

export default B2becSpinner;

B2becSpinner.propTypes = {
  isLoading: PropTypes.bool,
  isComponentLoading: PropTypes.bool,
};
B2becSpinner.defaultProps = {
  isLoading: false,
  isComponentLoading: false,
};
