import B2becInfoLayout from 'components/B2becInfoLayout';
import CallToActionButton from 'components/CallToActionButton';
import ConfirmModal from 'components/ConfirmModal';
import CustomButton from 'components/CustomButton';
import { DeleteBorderlessIcon as DeleteIcon, EditIcon } from 'components/Icons';
import { useBreadcrumbs, useDeviceDetect, useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectIsAddingWishlistToCart,
  selectIsDeletingWishlist,
  selectIsSharedWishlistDetail,
  selectIsWishlistLoading,
  selectWishlistInfo,
} from 'store/selectors/wishlistSelectors';
import {
  addAllProductsToCart,
  deleteWishlist,
} from 'store/slices/wishlistSlice';

import EditWishListModal from '../../../components/Wishlist/EditWishlistModal';
import PermissionWrapper from '../../../HOCs/permissionWrapper';
import { MODULE_WISHLIST_RIGHTS } from '../../../libs/constants/modulerights';
import ProductList from './components/ProductList';
import styles from './WishlistDetail.module.scss';

const WishlistDetailPage = (props) => {
  const { title, breadcrumbs, pageId } = props;
  const { wishlistId, wishlistOwnerId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDisplayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [shouldDisplayEditModal, setShouldDisplayEditModal] = useState(false);

  const { wishlistName, wishlistOwnerName } = useSelector(selectWishlistInfo);
  const isAddingWishlistToCart = useSelector(selectIsAddingWishlistToCart);
  const isWishlistLoading = useSelector(selectIsWishlistLoading);
  const isDeletingWishlist = useSelector(selectIsDeletingWishlist);
  const isSharedWishlist = useSelector(selectIsSharedWishlistDetail);
  const isLoading = isWishlistLoading || isDeletingWishlist;

  useBreadcrumbs(breadcrumbs);
  useDocumentTitle(title);
  const { isMobile } = useDeviceDetect();

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'wishlist detail page',
    pageType: PAGE_TYPES.WISHLIST,
  });

  const onAddWishlistToCart = useCallback(() => {
    if (wishlistId && wishlistOwnerId) {
      dispatch(addAllProductsToCart({ wishlistId, wishlistOwnerId }));
    }
  }, [wishlistOwnerId, wishlistId, dispatch]);

  const onDeleteWishList = useCallback(() => {
    if (wishlistId && wishlistOwnerId && !isSharedWishlist) {
      dispatch(deleteWishlist({ wishlistId, wishlistOwnerId }));
    }
  }, [wishlistOwnerId, wishlistId, isSharedWishlist, dispatch]);

  return (
    <div>
      <B2becInfoLayout>
        <B2becInfoLayout.Title title={wishlistName}>
          <div className={styles['title-content-wrapper']}>
            <div className={styles['owner-name']}>{wishlistOwnerName}</div>
            <div className={styles['wishlist-actions-container']}>
              <PermissionWrapper
                permission={MODULE_WISHLIST_RIGHTS.DELETE_WISHLIST}
              >
                <CallToActionButton
                  leftIcon={<DeleteIcon />}
                  className={styles['actions-btn']}
                  onClick={() => setDisplayDeleteModal(true)}
                  disabled={isLoading || !!isSharedWishlist}
                />
              </PermissionWrapper>
              <PermissionWrapper
                permission={MODULE_WISHLIST_RIGHTS.EDIT_SHARE_WISHLIST}
              >
                <CallToActionButton
                  leftIcon={<EditIcon />}
                  className={styles['actions-btn']}
                  onClick={() => {
                    setShouldDisplayEditModal(true);
                  }}
                  disabled={isLoading || !!isSharedWishlist}
                />
              </PermissionWrapper>
              <PermissionWrapper
                permission={MODULE_WISHLIST_RIGHTS.ADD_WISHLIST_TO_CART}
              >
                {isMobile ? null : (
                  <CustomButton
                    onClick={onAddWishlistToCart}
                    className={styles['header-buttons']}
                    disabled={isLoading || isAddingWishlistToCart}
                  >
                    {t('buttonTexts.addToCart')}
                  </CustomButton>
                )}
              </PermissionWrapper>
            </div>
          </div>
        </B2becInfoLayout.Title>
        <B2becInfoLayout.Content>
          <ProductList
            wishlistId={wishlistId}
            wishlistOwnerId={wishlistOwnerId}
          />
        </B2becInfoLayout.Content>
      </B2becInfoLayout>

      {isMobile ? (
        <div className={styles['mobile-actions-section']}>
          <div className={styles['buttons-wrapper']}>
            <CustomButton
              onClick={onAddWishlistToCart}
              className={styles['header-buttons']}
              disabled={isLoading || isAddingWishlistToCart}
            >
              {t('buttonTexts.shopAll')}
            </CustomButton>
          </div>
        </div>
      ) : null}

      <ConfirmModal
        isShowModal={isDisplayDeleteModal && !isSharedWishlist}
        setIsShowModal={setDisplayDeleteModal}
        modalTitle={t('wishlist.modal.deleteWishlist.title')}
        modalContent={t('wishlist.modal.deleteWishlist.modalContent')}
        confirmActionHandler={onDeleteWishList}
      />

      {shouldDisplayEditModal && !isSharedWishlist && (
        <EditWishListModal
          ownerId={wishlistOwnerId}
          wishlistId={wishlistId}
          shouldShowModal={shouldDisplayEditModal}
          setShouldShowModal={setShouldDisplayEditModal}
        />
      )}
    </div>
  );
};

WishlistDetailPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

WishlistDetailPage.defaultProps = {};

export default WishlistDetailPage;
