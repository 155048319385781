import './styles.scss';

import { Divider } from 'antd';
import useDeviceDetect from 'hooks/useDeviceDetect';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCheckoutSetting } from 'store/selectors/cartSelector';

import ButtonsWrapperComponent from '../components/ButtonsWrapper';
import PaymentMethod from './PaymentMethod';
import ReferenceNumber from './ReferenceNumber';
import ShippingNotes from './ShippingNotes';
import ShippingOptions from './ShippingOptions';

const StepTwoComponent = (props) => {
  const { tab, setTabHandler, form } = props;
  const { isMobile } = useDeviceDetect();
  const checkoutSettings = useSelector(selectCheckoutSetting);

  return (
    <div className="step-two__wrapper fw-container">
      <ReferenceNumber />
      <Divider className="checkout-step1__divider" />
      <PaymentMethod />
      {!isMobile && <Divider className="checkout-step1__divider" />}
      <ShippingOptions />
      {checkoutSettings?.shippingNotes && (
        <>
          <Divider className="checkout-step1__divider" />
          <ShippingNotes form={form} />
        </>
      )}

      <div className="checkout__bottom-space" />
      <div className="fw-container">
        <ButtonsWrapperComponent
          tab={tab}
          setTabHandler={setTabHandler}
          form={form}
        />
      </div>
    </div>
  );
};

StepTwoComponent.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
  }).isRequired,
  tab: PropTypes.number,
  setTabHandler: PropTypes.func,
};
StepTwoComponent.defaultProps = {
  tab: 1,
  setTabHandler: () => {},
};

export default StepTwoComponent;
