export const isValidArray = (array, minLength = 0) =>
  Array.isArray(array) && array.length > minLength;

export const hasEmptyElements = (array) =>
  Array.isArray(array) && array.filter((item) => !!item).length === 0;

export const isEmptyArray = (array) =>
  Array.isArray(array) && array.length === 0;

/**
 *
 * @param   {Array}           items
 * @param   {Boolean}         isNumberTypeExcluded
 * @returns {Array | items}   An array contains the elements passed the test if it is valid,
 *                            otherwise returns the original one.
 */
export const filterEmptyElementInArray = (
  items = [],
  isNumberTypeExcluded = true
) => {
  if (isValidArray(items)) {
    let filterCallback = (item) => !!item;

    if (isNumberTypeExcluded) {
      filterCallback = (item) => {
        if (typeof item === 'number') {
          return true;
        }
        if (typeof item === 'string' && item.trim() === '') {
          return false;
        }
        return !!item;
      };
    }

    return items.filter(filterCallback);
  }

  return items;
};

/**
 * @param {String}                       separator   use for separating the array elements
 * @param {Array | unlimited arguments}  elements    list of elements that need to be merged into a string
 * @returns {String}
 */
export const mergeIntoStringWithSeparator =
  (separator = ' ') =>
  (...elements) => {
    if (isValidArray(elements)) {
      const filteredArray = filterEmptyElementInArray(elements.flat());
      return filteredArray.join(separator);
    }
    return '';
  };
