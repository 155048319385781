import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const portfolioAdapter = createEntityAdapter({
  selectId: (product) => product?.materialNumber,
});

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: portfolioAdapter.getInitialState({
    isLoading: false,
    totalCount: 0,
    isSearching: false,
    addingProducts: {},
    addedProducts: {},
    selectedProduct: undefined,
  }),
  reducers: {
    getPortfolioOverviewList(state) {
      if (state.isLoading === false) {
        state.isLoading = true;
        state.isSearching = true;
        state.totalCount = 0;
      }
    },
    getPortfolioOverviewListSuccess(state, { payload }) {
      if (state.isLoading === true) {
        portfolioAdapter.setAll(state, payload?.items);
        state.isLoading = false;
        state.isSearching = false;
        state.totalCount = payload?.totalCount;
      }
    },
    getPortfolioOverviewListFailed(state) {
      if (state.isLoading === true) {
        portfolioAdapter.removeAll(state);
        state.isSearching = false;
        state.isLoading = false;
      }
    },
    resetPorfolioOverviewList(state) {
      portfolioAdapter.removeAll(state);
      state.isSearching = false;
      state.isLoading = false;
    },
    addProduct(state, action) {
      state.addingProducts[action.payload.materialNumber] =
        action.payload.materialNumber;
    },
    addProductSuccess(state, action) {
      delete state.addingProducts[action.payload];
      state.addedProducts[action.payload] = action.payload;
    },
    addProductFailed(state, action) {
      delete state.addingProducts[action.payload];
    },
    clearAddedProducts(state) {
      state.addedProducts = {};
    },
    updateProduct(state) {
      state.updateStatus = 'updating';
    },
    updateProductSuccess(state) {
      state.updateStatus = 'idle';
    },
    updateProductFailed(state) {
      state.updateStatus = 'idle';
    },
    deleteProduct(state) {
      state.deleteStatus = 'deleting';
    },
    deleteProductSuccess(state) {
      state.deleteStatus = 'idle';
    },
    deleteProductFailed(state) {
      state.deleteStatus = 'idle';
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    clearSelectedProduct(state) {
      state.selectedProduct = undefined;
    },
  },
});

export const {
  getPortfolioOverviewList,
  getPortfolioOverviewListSuccess,
  getPortfolioOverviewListFailed,
  resetPorfolioOverviewList,
  addProduct,
  addProductSuccess,
  addProductFailed,
  clearAddedProducts,
  updateProduct,
  updateProductSuccess,
  updateProductFailed,
  deleteProduct,
  deleteProductSuccess,
  deleteProductFailed,
  setSelectedProduct,
  clearSelectedProduct,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
