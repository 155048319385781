import { createSlice } from '@reduxjs/toolkit';

import { ACTION_STATUS } from '../../libs/constants/index';

const initialState = {
  isLoading: false,
  customerFetchStatus: ACTION_STATUS.DEFAULT,
  data: {
    customerAddress: {},
    billingAddress: {},
    deliveryAddresses: [],
    customerNumber: '',
  },
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    updateCustomerSAP(state, { payload }) {
      state.data.customerAddress.partnerNumber = payload;
    },
    getCustomerInfo(state) {
      state.isLoading = true;
    },
    getCustomerInfoSuccess(state, { payload }) {
      state.isLoading = false;
      state.data = payload;
      state.customerFetchStatus = ACTION_STATUS.SUCCESS;
    },
    getCustomerInfoError(state) {
      state.isLoading = false;
      state.customerFetchStatus = ACTION_STATUS.FAILED;
    },
  },
});

export const customerActions = customerSlice.actions;

export default customerSlice.reducer;
