import './styles.scss';

import useDummyItems from 'hooks/useDummyItems';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCartItems } from 'store/selectors/cartSelector';

import CartItemMobile from '../CartItemMobile';

const CartListMobile = ({ isLoading }) => {
  const fakeProducts = useDummyItems();

  const cartItems = useSelector(selectCartItems);

  const renderListItems = () => {
    if (isLoading) {
      return fakeProducts.map((fakeProduct) => (
        <CartItemMobile
          key={fakeProduct?.materialNumber}
          cartItem={fakeProduct}
          isLoading={isLoading}
        />
      ));
    }

    return isValidArray(cartItems)
      ? cartItems.map((cartItem) => (
          <CartItemMobile
            key={cartItem?.materialNumber}
            cartItem={cartItem}
            isLoading={isLoading}
          />
        ))
      : null;
  };

  return <div className="cart-table-mobile-wrapper">{renderListItems()}</div>;
};

CartListMobile.propTypes = {
  isLoading: PropTypes.bool,
};
CartListMobile.defaultProps = {
  isLoading: false,
};

export default CartListMobile;
