import B2becPagination from 'components/B2becPagination';
import PermissionWrapper from 'HOCs/permissionWrapper';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import usePermission from 'hooks/usePermission';
import { PAGE_SIZE, SORT_ORDERS } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { MODULE_ORDER_RIGHTS } from 'libs/constants/modulerights';
import { linkGenerator } from 'libs/utils/language';
import { DatePeriod } from 'pages/OrderPage/OrdersHistoryPage/DatePeriod';
import { Search } from 'pages/OrderPage/OrdersHistoryPage/Search';
import { Status } from 'pages/OrderPage/OrdersHistoryPage/Status';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectIsFetchingOrdersList,
  selectOrdersList,
  selectOrderTotalCount,
} from 'store/selectors/ordersSelector';
import { ordersActions } from 'store/slices/orderSlice';

import styles from './OrdersHistory.module.scss';
import OrdersHistoryTable from './OrdersHistoryTable';

const SORT_FIELD = {
  orderDate: 'orderDate',
};

const convertDateToISOString = (date) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return '';
};

function OrdersHistoryPage(props) {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  const { hasPermission: hasViewOrderHistoryPermission } = usePermission(
    MODULE_ORDER_RIGHTS.VIEW_ORDER_HISTORY
  );

  useAdobeAnalysis(null, {
    pageName: 'order history overview',
    pageType: PAGE_TYPES.ORDER,
    pageId,
  });

  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(
    parseInt(`${params.get('page')}`, 10) || 1
  );
  const [sortBy, setSortBy] = useState(SORT_FIELD.orderDate);
  const [orderBy, setOrderBy] = useState(SORT_ORDERS.descend);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const totalCount = useSelector(selectOrderTotalCount);
  const orderList = useSelector(selectOrdersList);
  const isFetchingOrdersList = useSelector(selectIsFetchingOrdersList);

  const resetToFirstPage = useCallback(() => {
    setCurrentPage(1);
    history.push({
      pathname: linkGenerator('/orders'),
      search: `?page=${1}`,
    });
  }, [history]);

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      history.push({
        pathname: linkGenerator('/orders'),
        search: `?page=${page}`,
      });
    },
    [history]
  );

  const handleSearch = useCallback(
    (value) => {
      setSearchTerm(value);
      resetToFirstPage();
    },
    [resetToFirstPage]
  );

  const handleFilterByStatus = useCallback(
    (value) => {
      setSelectedStatus(value);
      resetToFirstPage();
    },
    [resetToFirstPage]
  );

  const handleDateChange = useCallback(
    (_, datesString) => {
      setDateFrom(convertDateToISOString(datesString[0]));
      setDateTo(convertDateToISOString(datesString[1]));
      resetToFirstPage();
    },
    [resetToFirstPage]
  );

  const handleChangeSorter = useCallback((_, __, sorter) => {
    const { field, order } = sorter;

    setSortBy(SORT_FIELD[field]);
    setOrderBy(SORT_ORDERS[order]);
  }, []);

  const search = useMemo(() => {
    return <Search onSearch={handleSearch} />;
  }, [handleSearch]);

  const status = useMemo(() => {
    return (
      <Status
        selectedStatus={selectedStatus}
        onSelectStatus={handleFilterByStatus}
      />
    );
  }, [selectedStatus, handleFilterByStatus]);

  const datePeriod = useMemo(() => {
    return <DatePeriod onDateChange={handleDateChange} />;
  }, [handleDateChange]);

  const orderHistoryTable = useMemo(() => {
    return (
      <OrdersHistoryTable
        items={orderList}
        isLoading={isFetchingOrdersList}
        currentPage={currentPage}
        onChange={handleChangeSorter}
      />
    );
  }, [currentPage, isFetchingOrdersList, orderList, handleChangeSorter]);

  const pagination = useMemo(() => {
    return (
      <B2becPagination
        pageSize={PAGE_SIZE[10]}
        total={totalCount}
        onPageChange={handlePageChange}
        current={currentPage}
      />
    );
  }, [currentPage, totalCount, handlePageChange]);

  useEffect(() => {
    if (hasViewOrderHistoryPermission) {
      dispatch(
        ordersActions.getOrdersList({
          pageNumber: currentPage,
          pageSize: PAGE_SIZE[10],
          orderBy,
          sortBy,
          searchTerm,
          selectedStatus,
          dateFrom,
          dateTo,
        })
      );
    }
  }, [
    dispatch,
    currentPage,
    orderBy,
    sortBy,
    searchTerm,
    selectedStatus,
    dateFrom,
    dateTo,
    hasViewOrderHistoryPermission,
  ]);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t('orders.history.title')}</h1>
      <PermissionWrapper permission={MODULE_ORDER_RIGHTS.VIEW_ORDER_HISTORY}>
        <div className={styles.header}>
          {search}
          <div className={styles.right}>
            {datePeriod}
            {status}
          </div>
        </div>
        {orderHistoryTable}
        {pagination}
      </PermissionWrapper>
    </div>
  );
}

OrdersHistoryPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default OrdersHistoryPage;
