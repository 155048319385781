import { isValidArray } from 'libs/utils/array';
import { useSelector } from 'react-redux';

import { selectUserRightList } from '../store/selectors/roleSelector';

export default function usePermission(permission) {
  const userRights = useSelector(selectUserRightList);
  const mappedRights = new Set(userRights);

  const checkPermission = (right) => mappedRights.has(right);

  const checkMultiplePermissions = (rights) => {
    if (isValidArray(rights)) {
      return rights.map(checkPermission);
    }

    return [];
  };

  if (isValidArray(permission)) {
    const verifiedPermissions = checkMultiplePermissions(permission);

    const hasPermission = verifiedPermissions.every(
      (verifiedPermission) => verifiedPermission
    );

    return {
      hasPermission,
      verifiedPermissions,
      checkPermission,
      checkMultiplePermissions,
    };
  }

  return {
    hasPermission: checkPermission(permission),
    checkPermission,
    checkMultiplePermissions,
  };
}
