import B2becButton from 'components/B2becButton';
import { useAsync } from 'hooks';
import useSessionBased from 'hooks/useSessionBased';
import { ASYNC_STATUS } from 'libs/constants';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductService } from 'services';

const DisisHomeButton = () => {
  const { t } = useTranslation();
  const { sessionId } = useSessionBased();

  const getHomePageLink = useCallback(() => {
    return ProductService.getDisisHomePageLink(sessionId);
  }, [sessionId]);

  const { value, status } = useAsync(getHomePageLink, true);

  return (
    <div style={{ marginTop: '24px' }}>
      <a href={value?.data || '/#'} target="_blank" rel="noopener noreferrer">
        <B2becButton loading={status === ASYNC_STATUS.PENDING} ghost>
          {t('sparePartsFinder.button.toDisis')}
        </B2becButton>
      </a>
    </div>
  );
};

export default DisisHomeButton;
