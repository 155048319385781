import './styles.scss';

import { Col, Row, Space } from 'antd';
import sparePartImage from 'assets/img/spare-parts.webp';
import { NotificationManager } from 'components/Notification';
import useAsync from 'hooks/useAsync';
import useSessionBased from 'hooks/useSessionBased';
import { ASYNC_STATUS, STATUS_CODE } from 'libs/constants';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductService } from 'services';

import BlockTwoCol, { displayTypes } from '../BlockTwoCol';
import CustomButton from '../CustomButton';

const ContentWrapper = (materialNumber, translation) => {
  const { t } = useTranslation();
  const { sessionId } = useSessionBased();

  const { execute: getProductSpareParts, status: getProductSparePartsStatus } =
    useAsync(
      () => ProductService.getProductSpareParts(materialNumber, sessionId),
      false
    );

  const getDisisLink = useCallback(() => {
    if (materialNumber) {
      getProductSpareParts().then(({ response, error }) => {
        if (response?.status === STATUS_CODE.SUCCESS) {
          window.open(response?.data, '_blank');
        }
        if (error) {
          NotificationManager.error({
            message: 'notification.error.getProductSpareParts',
            description: error?.response?.data,
          });
        }
      });
    }
  }, [materialNumber, getProductSpareParts]);

  const description = t('productDetails.spareParts.description').split('\n');
  const descriptionLines = description
    .map((v) => v.trim())
    .map((text, index) => ({ index, text }));

  const { allSparePart } = translation;
  const allSparePartText = t(
    `productDetails.spareParts.button.${allSparePart}`
  );
  return (
    <div>
      <h2 className="block-two-col__header">
        {t('productDetails.spareParts.title')}
      </h2>
      <div className="block-two-col__content mb-5">
        {descriptionLines.map((line) => (
          <p key={line.index}>{line.text}</p>
        ))}
      </div>
      <div className="block-two-col__footer">
        <Space className="block-two-col__btn-wrapper" direction="vertical">
          <CustomButton
            onClick={getDisisLink}
            block
            loading={getProductSparePartsStatus === ASYNC_STATUS.PENDING}
          >
            {allSparePartText}
          </CustomButton>
        </Space>
      </div>
    </div>
  );
};

function ImagesWrapper() {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <div
          className="spare-part-bg-img"
          style={{ backgroundImage: `url(${sparePartImage})` }}
        />
      </Col>
    </Row>
  );
}

function B2becSpareParts(props) {
  const { className, translation, materialNumber } = props;
  return (
    <div className="spare-parts-component__item">
      <BlockTwoCol
        className={className}
        type={displayTypes.NORMAL}
        left={ImagesWrapper()}
        right={ContentWrapper(materialNumber, translation)}
      />
    </div>
  );
}

B2becSpareParts.defaultProps = {
  materialNumber: null,
  className: 'spare-parts',
  translation: {
    allSparePart: 'allSparePart',
  },
};

B2becSpareParts.propTypes = {
  materialNumber: PropTypes.string,
  className: PropTypes.string,
  translation: PropTypes.shape({ allSparePart: PropTypes.string }),
};

export default B2becSpareParts;
