import { createSlice } from '@reduxjs/toolkit';

const supportAreaSlice = createSlice({
  name: 'rights',
  initialState: {
    machines: {
      list: [],
      isFetching: false,
      total: 0,
    },
  },
  reducers: {
    getMachinesListByCustomerNumber: (state) => {
      state.machines.isFetching = true;
    },
    getMachinesListByCustomerNumberSuccess: (state, { payload }) => {
      state.machines.isFetching = false;
      state.machines.list = payload.items;
      state.machines.total = payload.totalCount;
    },
    getMachinesListByCustomerNumberFailed: (state) => {
      state.machines.isFetching = false;
    },
  },
});

export const {
  getMachinesListByCustomerNumber,
  getMachinesListByCustomerNumberSuccess,
  getMachinesListByCustomerNumberFailed,
} = supportAreaSlice.actions;

export default supportAreaSlice.reducer;
