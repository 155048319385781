import environment from 'environments/environment.dev';

import HttpClient from './apiHttpClient';

const SETUP_ENDPOINT = '/v1.0/setup';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const setupService = {
  getSetupData() {
    return fapiClient.get(SETUP_ENDPOINT);
  },
};
