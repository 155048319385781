import './styles.scss';

import { Col, Form, Input, Row, Select } from 'antd';
import clsx from 'clsx';
import B2becCustomSelect from 'components/B2becCustomSelect';
import B2becSpinner from 'components/B2becSpinner';
import B2becTranslation from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import { notificationComponent } from 'components/Notification';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useAsync from 'hooks/useAsync';
import { ACTION_STATUS, ASYNC_STATUS, STATUS_CODE } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fapiUserService } from 'services/fapi';
import { getIsLoading, getSubmitStatus } from 'store/selectors/contactSelector';
import { contactActions } from 'store/slices/contactSlice';

import contactReasonsFromPhrase from '../Admin/ContactReasonManagement/contactReasonsFromPhrase';

const { Option } = Select;
const { TextArea } = Input;

const ContactFormPage = (props) => {
  const { pageId } = props;

  const [contactReasonsFromApi, setContactReasonsFromApi] = useState([]);

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { setPageInfoData } = useAdobeAnalysis();

  const { isLoading, submitStatus } = useSelector((state) => ({
    isLoading: getIsLoading(state),
    submitStatus: getSubmitStatus(state),
  }));

  const getAllContactReasonsFromApi = useCallback(() => {
    return fapiUserService.getContactReasonList(10, 1);
  }, []);

  const onGetApiSuccess = useCallback((response) => {
    if (response?.status === STATUS_CODE.SUCCESS) {
      setContactReasonsFromApi(response?.data);
    }
  }, []);

  const onGetApiError = useCallback((error) => {
    notificationComponent({
      type: NOTIFICATION_TYPE.ERROR,
      message: (
        <B2becTranslation value="notification.error.getAllContactReasons" />
      ),
      description: error?.message,
    });
  }, []);

  const { status: getAllContactReasonsApiStatus } = useAsync(
    getAllContactReasonsFromApi,
    true,
    onGetApiSuccess,
    onGetApiError
  );

  const compare = (contactReason1, contactReason2) => {
    const contactReasonNumberA = Number(contactReason1.indexNumber);
    const contactReasonNumberB = Number(contactReason2.indexNumber);
    if (contactReasonNumberA < contactReasonNumberB) {
      return -1;
    }
    if (contactReasonNumberA > contactReasonNumberB) {
      return 1;
    }
    return 0;
  };

  const finalContactReasonList = useMemo(() => {
    if (contactReasonsFromApi.length > 0) {
      // Map
      const mappedArray = contactReasonsFromApi.map((reason) => {
        const foundItem = contactReasonsFromPhrase.find(
          (phraseItem) => phraseItem?.key?.id === reason?.contactReasonId
        );
        return {
          ...reason,
          translationContent: t(foundItem?.key?.name || 'N/A'),
        };
      });
      return mappedArray.sort(compare);
    }
    return [];
  }, [contactReasonsFromApi, t]);

  const isLoadingContactReasons =
    getAllContactReasonsApiStatus === ASYNC_STATUS.PENDING;

  useEffect(() => {
    const contactStatus =
      submitStatus === ACTION_STATUS.DEFAULT ? 'start' : 'complete';

    setPageInfoData({
      pageName: `contact ${contactStatus}`,
      pageType: PAGE_TYPES.CONTACT,
      pageId,
    });
  }, [submitStatus, setPageInfoData, pageId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(contactActions.resetContact());
    };
  }, [dispatch]);

  const renderOptions = () =>
    finalContactReasonList.map((reason) => (
      <Option
        key={reason.contactReasonId}
        value={`${reason.contactReasonId}-${reason.translationContent}`}
      >
        {reason.translationContent}
      </Option>
    ));

  const handleSubmit = (values) => {
    const { contactReasonIdName, message } = values;
    const contactReasonObject = contactReasonIdName.split('-');
    const contactReasonId = contactReasonObject[0];
    const contactReasonName = contactReasonObject[1];
    form.resetFields();
    dispatch(
      contactActions.submitContactRequest({
        contactReasonId,
        contactReasonName,
        message,
      })
    );
  };

  const isDisabledSubmittingForm =
    isLoadingContactReasons || !isValidArray(contactReasonsFromApi);

  const renderSubmitForm = () => (
    <>
      <B2becSpinner isLoading={isLoading} />
      <div className="contact-form__wrapper mt-6">
        <h1 className="contact-form__title fontBold mb-4">
          {t('contactForm.title')}
        </h1>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="contactReasonIdName"
            label={t('contactForm.reason')}
            className="custom-form-item"
            rules={[
              {
                required: true,
                message: `${t('contactForm.validation.reasonIsRequired')}`,
              },
            ]}
          >
            <B2becCustomSelect
              placeholder={
                isLoadingContactReasons
                  ? t('form.placeHolder.loading')
                  : t('contactForm.pleaseSelectTheReason')
              }
            >
              {renderOptions()}
            </B2becCustomSelect>
          </Form.Item>

          <Form.Item
            name="message"
            label={t('contactForm.message')}
            className="custom-form-item"
            rules={[
              {
                required: true,
                message: `${t('contactForm.validation.messageIsRequired')}`,
              },
            ]}
          >
            <TextArea
              rows={4}
              style={{ resize: 'none' }}
              placeholder={t('contactForm.message')}
            />
          </Form.Item>
          <Row gutter={[50]}>
            <Col span={24}>
              <CustomButton
                className="btn btn--submit"
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 'auto' }}
                disabled={isDisabledSubmittingForm}
              >
                {t('contactForm.makeRequest')}
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );

  const renderSubmitResult = () => (
    <div className="contact-form__wrapper mt-6">
      <h1 className="contact-form__submit-result contact-form__submit-result__title contact-form__submit-result--center">
        {t('contactForm.thanksForYourRequest')}
      </h1>
      <h2
        className={clsx(
          'contact-form__submit-result contact-form__submit-result--center mt-6 mb-6',
          {
            'contact-form__submit-result--alarm':
              submitStatus !== ACTION_STATUS.SUCCESS,
          }
        )}
      >
        {submitStatus === ACTION_STATUS.SUCCESS
          ? t('contactForm.requestSuccessfully')
          : t('contactForm.yourRequestCouldNotBeSubmittedSuccessfully')}
      </h2>
      <div className="contact-form__submit-result__separator mt-6 mb-6" />
      <div className="contact-form__submit-result contact-form__submit-result--center fontBold mb-3">
        {t('checkout.confirmOrder.whatNext')}
      </div>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12}>
          <CustomButton
            onClick={() => dispatch(contactActions.resetContact())}
            type="primary"
            className="contact-form__button"
          >
            {t('contactForm.makeAnotherRequest')}
          </CustomButton>
        </Col>
        <Col xs={24} lg={12}>
          <CustomButton
            onClick={() => {
              history.push(linkGenerator('/my-workspace'));
            }}
            type="ghost"
            className="contact-form__button"
          >
            {t('checkout.confirmOrder.myWorkspace')}
          </CustomButton>
        </Col>
      </Row>
    </div>
  );

  return submitStatus === ACTION_STATUS.DEFAULT
    ? renderSubmitForm()
    : renderSubmitResult();
};

ContactFormPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default ContactFormPage;
