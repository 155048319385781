import { BillingAddress } from 'components/DisplayAddress';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const InvoiceAddress = (props) => {
  const { t } = useTranslation();
  const { billingInfo } = props;

  return (
    <div className="profile__main-block mt-5">
      <div className="profile__main-block__label mb-3">
        {t('myProfile.invoiceAddress')}
      </div>
      <BillingAddress data={billingInfo} />
    </div>
  );
};

InvoiceAddress.propTypes = {
  billingInfo: PropTypes.shape({
    names: PropTypes.arrayOf(PropTypes.string),
    street: PropTypes.string,
    street4: PropTypes.string,
    houseNumber: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

export default InvoiceAddress;
