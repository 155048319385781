import { useAsync } from 'hooks';
import { CONFIG_DATA_TYPES, CONFIG_NAMES } from 'libs/constants/configs';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fapiConfigurationService } from 'services/fapi';
import {
  selectCartData,
  selectIsAvoidProceedingCart,
  selectIsDisplayingDisableBProgramCheckbox,
} from 'store/selectors/cartSelector';
import {
  getBillingAddress,
  getCustomerAddress,
  getDeliveryAddresses,
} from 'store/selectors/customerSelector';
import { getCartItems } from 'store/slices/cartSlice';

const getCheckoutConfig = async () => {
  const checkoutConfigs = [
    {
      configurationKey: CONFIG_NAMES.SHOW_SERVICE_CODE,
      dataTypeDto: CONFIG_DATA_TYPES.BOOLEAN,
    },
    {
      configurationKey: CONFIG_NAMES.ORDER_REFERENCE_MANDATORY,
      dataTypeDto: CONFIG_DATA_TYPES.BOOLEAN,
    },
    {
      configurationKey: CONFIG_NAMES.SHIPPING_NOTES_MANDATORY,
      dataTypeDto: CONFIG_DATA_TYPES.BOOLEAN,
    },
  ];
  const response = await fapiConfigurationService.getConfigsByNames(
    checkoutConfigs
  );

  return response;
};

const CheckoutContext = React.createContext();

function CheckoutProvider({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const customerInfo = useSelector(getCustomerAddress);
  const addresses = useSelector(getDeliveryAddresses);
  const billingInfo = useSelector(getBillingAddress);
  const cartData = useSelector(selectCartData);
  const isAvoidProceedingCart = useSelector(selectIsAvoidProceedingCart);
  const isBProgramCheckboxDisplayed = useSelector(
    selectIsDisplayingDisableBProgramCheckbox
  );

  const [shouldDisableCheckout, setDisableCheckout] = useState(
    isBProgramCheckboxDisplayed
  );

  const { value: checkoutConfig } = useAsync(getCheckoutConfig, true);

  const {
    Show_Service_Code: shouldShowServiceCode = false,
    Order_reference_mandatory: isOrderReferenceMandatory = false,
    Shipping_notes_mandatory: isShippingNotesMandatory = false,
  } = checkoutConfig || {};
  const deliveryAddresses = useMemo(() => {
    if (addresses.length) {
      return addresses;
    }

    return [customerInfo];
  }, [customerInfo, addresses]);

  const memoizedCartData = useMemo(() => {
    if (isValidArray(cartData?.items)) {
      return cartData;
    }

    return null;
  }, [cartData]);

  const memoizedValue = useMemo(
    () => ({
      customerInfo,
      billingInfo,
      cartData: memoizedCartData,
      shouldShowServiceCode,
      isOrderReferenceMandatory,
      isShippingNotesMandatory,
      shouldDisableCheckout,
      setDisableCheckout,
      deliveryAddresses,
    }),
    [
      customerInfo,
      billingInfo,
      memoizedCartData,
      shouldShowServiceCode,
      isOrderReferenceMandatory,
      isShippingNotesMandatory,
      shouldDisableCheckout,
      setDisableCheckout,
      deliveryAddresses,
    ]
  );

  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  useEffect(() => {
    // if the shopping cart has replaced or moq items, prevent user redirecting to the checkout page
    if (isAvoidProceedingCart) {
      history.replace(linkGenerator('/cart'));
    }
  }, [isAvoidProceedingCart, history]);

  return (
    <CheckoutContext.Provider value={memoizedValue}>
      {children}
    </CheckoutContext.Provider>
  );
}

function useCheckoutContext() {
  const checkoutContext = React.useContext(CheckoutContext);

  if (checkoutContext === undefined) {
    throw new Error(
      'useCheckoutContext must be used within the CheckoutProvider'
    );
  }

  return checkoutContext;
}

CheckoutProvider.propTypes = {
  children: PropTypes.node,
};

CheckoutProvider.defaultProps = {
  children: {},
};

export { CheckoutProvider, useCheckoutContext };
