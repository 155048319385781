import B2becInfoLayout from 'components/B2becInfoLayout';
import B2BecPagination from 'components/B2becPagination';
import ConfirmModal from 'components/ConfirmModal';
import CustomButton from 'components/CustomButton';
import { useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useModal from 'hooks/useModal';
import { PAGE_SIZE, SORT_ORDERS } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectAddedRentableProductList,
  selectIsFetching,
  selectRentalList,
  selectTotalCount,
} from 'store/selectors/rentalSelectors';
import { selectUserCountryId } from 'store/selectors/userSelector';
import {
  addProductToRentalList,
  clearAddedRentableProductList,
  deleteProductFromRentableList,
  getRentableProductList,
} from 'store/slices/rentalSlice';

import AddProduct from '../../../components/AddProductModal/AddProduct';
import PermissionWrapper from '../../../HOCs/permissionWrapper';
import usePermission from '../../../hooks/usePermission';
import { MODULE_RENTAL_MANAGEMENT_RIGHTS } from '../../../libs/constants/modulerights';
import { isEmptyObject } from '../../../libs/utils/object';
import { adminFapiRental } from '../../../services/adminFapi';
import RentalTable from './components/RentalTable';
import styles from './RentalManagement.module.scss';

const SORT_FIELDS = {
  name: 'name',
  category: 'category',
  subCategory: 'subCategory',
};

const RentalManagement = (props) => {
  const { title, breadcrumbs, pageId } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRental, setSelectedRental] = useState();
  const [sortBy, setSortBy] = useState(SORT_FIELDS.name);
  const [orderBy, setOrderBy] = useState(SORT_ORDERS.ascend);

  const { Modal: AddProductModal, toggleModal } = useModal();
  const isLoading = useSelector(selectIsFetching);
  const rentalList = useSelector(selectRentalList);
  const totalCount = useSelector(selectTotalCount);
  const countryId = useSelector(selectUserCountryId);
  const addedRentableList = useSelector(selectAddedRentableProductList);
  const dispatch = useDispatch();

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  const { hasPermission: hasViewRentalPermission } = usePermission(
    MODULE_RENTAL_MANAGEMENT_RIGHTS.VIEW_RENTAL
  );

  useAdobeAnalysis(null, {
    pageId,
    pageName: ' Management',
    pageType: PAGE_TYPES.RENTAL_MANAGEMENT,
  });

  useEffect(() => {
    if (hasViewRentalPermission) {
      dispatch(
        getRentableProductList({
          pageNumber: currentPage,
          pageSize: PAGE_SIZE[10],
          sortBy,
          orderBy,
        })
      );
    }
  }, [currentPage, dispatch, orderBy, sortBy, hasViewRentalPermission]);

  const executeDeleteRental = useCallback(
    (materialNumber) => {
      dispatch(
        deleteProductFromRentableList({
          materialNumber,
          countryId,
          currentPage,
          totalCount,
          pageSize: PAGE_SIZE[10],
          sortBy,
          orderBy,
        })
      );
    },
    [countryId, dispatch, currentPage, totalCount, sortBy, orderBy]
  );

  const handleCellClick = useCallback(
    (materialNumber) => {
      history.push(linkGenerator(`/product/${materialNumber}`));
    },
    [history]
  );

  const handlePageChange = useCallback((current) => {
    setCurrentPage(current);
  }, []);

  const handleCloseAddProductModal = useCallback(() => {
    toggleModal(false);
    if (!isEmptyObject(addedRentableList)) {
      dispatch(
        getRentableProductList({
          pageNumber: currentPage,
          pageSize: PAGE_SIZE[10],
          sortBy,
          orderBy,
        })
      );
    }
  }, [toggleModal, addedRentableList, dispatch, currentPage, sortBy, orderBy]);

  const onAddRental = () => {
    toggleModal(true);
    dispatch(clearAddedRentableProductList());
  };

  const renderDeleteConfirmModal = useCallback(
    (materialNumber) => {
      if (!materialNumber) {
        return undefined;
      }
      return (
        <ConfirmModal
          isShowModal
          setIsShowModal={() => setSelectedRental(undefined)}
          modalTitle={t('rental.deleteRental')}
          modalContent={t('rental.deleteRentalConfirm')}
          confirmActionHandler={() => executeDeleteRental(materialNumber)}
        />
      );
    },
    [executeDeleteRental, t]
  );

  const handleChangeSorters = useCallback((_pagination, _filters, sorter) => {
    const { field, order } = sorter;
    setSortBy(SORT_FIELDS[field]);
    setOrderBy(SORT_ORDERS[order] || SORT_ORDERS.ascend);
    setCurrentPage(1);
  }, []);

  const handleAddProductToRentableList = useCallback(
    ({
      countryId: countryIdValue,
      materialNumber,
      name,
      category,
      subCategory,
      imgUrl,
    }) => {
      dispatch(
        addProductToRentalList({
          countryId: countryIdValue,
          materialNumber,
          name,
          category,
          subCategory,
          imgUrl,
        })
      );
    },
    [dispatch]
  );

  const handleSearchRentalbleProducts = useCallback(
    ({ query, cancelToken }) => {
      return adminFapiRental.getSuggestionsProductsList({
        query,
        cancelToken,
      });
    },
    []
  );

  return (
    <B2becInfoLayout>
      <B2becInfoLayout.Title>
        <div className={styles.rentalTitle}>
          <h1>{t('pageTitle.rentalManagement')}</h1>
          <PermissionWrapper
            permission={MODULE_RENTAL_MANAGEMENT_RIGHTS.ADD_PRODUCT}
          >
            <CustomButton onClick={onAddRental}>
              {t('adminWorkspace.rentalManagement.addProduct')}
            </CustomButton>
          </PermissionWrapper>
        </div>
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        <PermissionWrapper
          permission={MODULE_RENTAL_MANAGEMENT_RIGHTS.VIEW_RENTAL}
        >
          <RentalTable
            rentalList={rentalList}
            handleDeleteRental={setSelectedRental}
            handleCellClick={handleCellClick}
            isLoading={isLoading}
            onChangeSorter={handleChangeSorters}
          />
          <B2BecPagination
            total={totalCount}
            current={currentPage}
            onPageChange={handlePageChange}
            pageSize={PAGE_SIZE[10]}
          />
        </PermissionWrapper>

        {renderDeleteConfirmModal(selectedRental)}
      </B2becInfoLayout.Content>
      <AddProductModal width={800} closable={false} footer={null}>
        <AddProduct
          onClose={handleCloseAddProductModal}
          countryId={countryId}
          addedProducts={addedRentableList}
          handleAddProduct={handleAddProductToRentableList}
          handleSearchProducts={handleSearchRentalbleProducts}
        />
      </AddProductModal>
    </B2becInfoLayout>
  );
};

RentalManagement.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default React.memo(RentalManagement);
