import B2becPromotionItem, {
  B2becPromotionItemSkeleton,
  NOT_FOUND_PRODUCT,
} from 'components/B2becPromotionItem';
import useAsync from 'hooks/useAsync';
import { ASYNC_STATUS, isValidMaterial } from 'libs/constants';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { adminFapiPromotion } from 'services/adminFapi';

import MaterialNumberInput from '../MaterialNumberInput';

export const getNotFoundProductShape = (materialNumber) => ({
  materialNumber,
  materialName: NOT_FOUND_PRODUCT,
});

const getProductInfo = async (materialNumber) => {
  try {
    const response = await adminFapiPromotion.getProductInfo(materialNumber);

    return response.data;
  } catch {
    // TODO
  }

  return getNotFoundProductShape(materialNumber);
};

const PromotionItemWrapper = (props) => {
  const { disabled, onChange, value } = props;

  const { t } = useTranslation();

  const onSuccess = (data) => {
    if (typeof onChange === 'function') {
      onChange(data);
    }
  };

  useEffect(() => {
    // Trigger validator of current form item to show error when there is an invalid material number
    if (value.materialName === NOT_FOUND_PRODUCT) {
      onChange(value);
    }
  }, [value, onChange]);

  const { execute, status } = useAsync(
    (materialNumber) => getProductInfo(materialNumber),
    false,
    onSuccess
  );

  const handleChange = (materialNumber) => {
    if (isValidMaterial(materialNumber)) {
      execute(materialNumber);
    }

    onSuccess({ materialNumber });
  };

  const isLoading = status === ASYNC_STATUS.PENDING;

  return (
    <div className="promotion-item">
      <MaterialNumberInput
        disabled={disabled}
        isLoading={isLoading}
        onChange={handleChange}
        placeholder={t('cart.table.itemNo')}
        value={value?.materialNumber}
      />
      {isLoading ? (
        <B2becPromotionItemSkeleton />
      ) : (
        <B2becPromotionItem {...value} />
      )}
    </div>
  );
};

PromotionItemWrapper.defaultProps = {
  disabled: false,
  onChange: undefined,
  value: undefined,
};

PromotionItemWrapper.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    materialNumber: PropTypes.string,
    materialName: PropTypes.string,
  }),
};

export default PromotionItemWrapper;
