import { Hub } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const useCheckForRedirectUrlAfterLogin = () => {
  const history = useHistory();
  Hub.listen('auth', ({ payload: { event, data } }) => {
    if (event === 'customOAuthState') {
      const storedPathForRedirectAfterLogin = JSON.parse(data);
      history.push(storedPathForRedirectAfterLogin);
    }
  });
};

export default useCheckForRedirectUrlAfterLogin;
