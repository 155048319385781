import { createSlice } from '@reduxjs/toolkit';
import { ACTION_STATUS, ASYNC_STATUS } from 'libs/constants';

const initialState = {
  isLoading: false,
  actionStatus: ACTION_STATUS.DEFAULT,
  data: {},
  confirmData: {},
  isValidatedMachine: false,
  selectedMachine: {
    serviceEntry: null,
    isChangedService: false,
    isExcludedMachine: false,
    validateStatus: ASYNC_STATUS.IDLE,
    faqList: [],
    id: '',
    name: '',
    serialNumber: '',
    image: '',
  },
};

const serviceCaseSlice = createSlice({
  name: 'serviceCase',
  initialState,
  reducers: {
    createServiceCase: (state, { payload }) => {
      state.isLoading = true;
      state.data = payload;
      state.actionStatus = ACTION_STATUS.DEFAULT;
    },
    createServiceCaseSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.confirmData = payload;
      state.actionStatus = ACTION_STATUS.SUCCESS;
    },
    createServiceCaseError: (state) => {
      state.isLoading = false;
      state.confirmData = {};
      state.actionStatus = ACTION_STATUS.FAILED;
    },
    resetSelectedMachineInfo: (state) => {
      const currentServiceEntry = state.selectedMachine?.serviceEntry;

      state.selectedMachine = {
        serviceEntry: currentServiceEntry,
        isChangedService: false,
        isExcludedMachine: false,
        validateStatus: ASYNC_STATUS.IDLE,
        faqList: [],
        id: '',
        name: '',
        serialNumber: '',
        image: '',
      };
    },
    setSelectedMachineInfo: (state, { payload }) => {
      Object.entries(payload || {}).forEach(([key, value]) => {
        state.selectedMachine[key] = value;
      });
    },
    clearServiceCaseConfirmData: (state) => {
      state.confirmData = {};
      state.actionStatus = ACTION_STATUS.DEFAULT;
    },
  },
});

export const {
  createServiceCase,
  createServiceCaseSuccess,
  createServiceCaseError,
  resetSelectedMachineInfo,
  setSelectedMachineInfo,
  clearServiceCaseConfirmData,
} = serviceCaseSlice.actions;

export default serviceCaseSlice.reducer;
