import './styles.scss';

import B2BecOrderDetailTitle from 'components/B2BecOrderDetailTitle';
import CustomButton from 'components/CustomButton';
import { ChevronLeftIcon } from 'components/Icons';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import usePermission from 'hooks/usePermission';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { MODULE_ORDER_RIGHTS } from 'libs/constants/modulerights';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  selectIsFetchingOrderDetails,
  selectOrderDetails,
} from 'store/selectors/ordersSelector';
import { ordersActions } from 'store/slices/orderSlice';

import DownloadButton from '../components/DownloadButton';
import OrderDetailsTable from './OrderDetailsTable';

const OrderDetails = (props) => {
  const { orderNumber } = useParams();
  const { state: { currentPage } = {} } = useLocation();
  const { hasPermission: hasDownloadInvoicePermission } = usePermission(
    MODULE_ORDER_RIGHTS.DOWNLOAD_INVOICE
  );

  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title, [orderNumber]);
  useBreadcrumbs(breadcrumbs, [
    { titleData: [orderNumber], pathData: [orderNumber] },
  ]);

  const { setPageInfoData } = useAdobeAnalysis();

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const isFetchingOrderDetails = useSelector(selectIsFetchingOrderDetails);
  const orderDetails = useSelector(selectOrderDetails);

  const {
    invoicesNumbers,
    orderDate,
    referenceNumber,
    salesOrderNumber,
    status: orderStatus,
  } = orderDetails;

  useEffect(() => {
    dispatch(
      ordersActions.getOrderDetailByNumber({
        orderNumber,
        currentPage: currentPage || 1,
      })
    );

    setPageInfoData({
      pageName: `order ${orderNumber}`,
      pageId,
      pageType: PAGE_TYPES.ORDER_DETAILS,
    });
  }, [dispatch, setPageInfoData, currentPage, orderNumber, pageId]);

  useEffect(
    () => () => {
      dispatch(ordersActions.clearOrderDetails());
    },
    [dispatch]
  );

  const isDownloadButtonDisabled =
    isFetchingOrderDetails ||
    !isValidArray(invoicesNumbers) ||
    !hasDownloadInvoicePermission;

  const handleOnRow = useCallback(
    ({ materialNumber, isAvailable }) => {
      return {
        onClick: () => {
          if (!isFetchingOrderDetails && isAvailable && materialNumber !== '') {
            return history.push(linkGenerator(`/product/${materialNumber}`));
          }

          return null;
        },
      };
    },
    [history, isFetchingOrderDetails]
  );

  return (
    <div className="order-detail__wrapper">
      <B2BecOrderDetailTitle
        isLoading={isFetchingOrderDetails}
        orderDetails={orderDetails}
        showSummary
      />
      <OrderDetailsTable
        orderNumber={orderNumber}
        orderItems={orderDetails}
        isLoading={isFetchingOrderDetails}
        status={orderStatus}
        salesOrderNumber={salesOrderNumber}
        orderDate={orderDate}
        referenceNumber={referenceNumber}
        onRow={handleOnRow}
      />
      <div className="cart-bottom order-detail__bottom">
        <div className="cart-bottom__action">
          <DownloadButton
            orderNumber={orderNumber}
            orderDate={orderDate}
            referenceNumber={referenceNumber}
            orderStatus={orderStatus}
            invoiceNumbers={invoicesNumbers}
            button={
              <CustomButton disabled={isDownloadButtonDisabled}>
                {t('downloadInvoice')}
              </CustomButton>
            }
          />
        </div>
      </div>
      <CustomButton
        onClick={() =>
          history.push({
            pathname: linkGenerator('/orders'),
            search: `?page=${currentPage || 1}`,
          })
        }
        className="back-btn"
      >
        <ChevronLeftIcon />
        {t('searchResultsPage.button.backToOverview')}
      </CustomButton>
    </div>
  );
};

OrderDetails.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default OrderDetails;
