import clsx from 'clsx';
import CustomButton from 'components/CustomButton';
import SuggestionItem from 'components/Search/SuggestionItem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isAddingSelector } from 'store/selectors/portfolioSelector';

import styles from './ProductSearchItem.module.scss';

const ProductSearchItem = (props) => {
  const { countryId, customerNumber, handleAddProduct, ...itemProps } = props;
  const { t } = useTranslation();
  const isAdding = useSelector(isAddingSelector(itemProps.materialNumber));

  return (
    <div
      className={clsx(
        styles.suggestionItem,
        itemProps.isAdded && styles.suggestionItemDisabled
      )}
    >
      <SuggestionItem {...itemProps} />
      <CustomButton
        type="ghost"
        onClick={() =>
          handleAddProduct({
            countryId,
            customerNumber,
            materialNumber: itemProps.materialNumber,
            name: itemProps.name,
            category: itemProps.category,
            subCategory: itemProps.subCategory,
            imgUrl: itemProps.imageUrl,
          })
        }
        disabled={isAdding || itemProps.isAdded}
      >
        {!itemProps.isAdded
          ? t('productManagement.addProductModal.addButton')
          : t('rental.added')}
      </CustomButton>
    </div>
  );
};

ProductSearchItem.defaultProps = {
  customerNumber: '',
  handleAddProduct: () => {},
};

ProductSearchItem.propTypes = {
  countryId: PropTypes.string.isRequired,
  customerNumber: PropTypes.string,
  handleAddProduct: PropTypes.func,
};

export default ProductSearchItem;
