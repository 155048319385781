import '../styles.scss';

import B2becButton from 'components/B2becButton';
import { linkGenerator } from 'libs/utils/language';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const BottomSectionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="order-confirm__main__bottom">
      <span className="order-confirm__main__bottom__title">
        {t('checkout.confirmOrder.whatNext')}
      </span>
      <div className="order-confirm__main__bottom__button-group">
        <div className="order-confirm__main__bottom__panel order-confirm__main__bottom__left">
          <B2becButton
            block
            onClick={() => {
              history.push(linkGenerator('/orders'));
            }}
          >
            {t('checkout.confirmOrder.seeOrderHistory')}
          </B2becButton>
        </div>
        <div className="order-confirm__main__bottom__panel order-confirm__main__bottom__right">
          <B2becButton
            ghost
            block
            onClick={() => {
              history.push(linkGenerator('/my-workspace'));
            }}
          >
            {t('sidebar.myWorkspace')}
          </B2becButton>
        </div>
      </div>
    </div>
  );
};
export default BottomSectionComponent;
