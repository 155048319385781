export default window.digitalData = {
  application: {
    applicationInfo: {
      namespace: 'kaercher_b2b',
      platform: 'b2b',
      language: '',
      country: '',
      currency: '',
    },
  },
  page: {
    pageInfo: {
      pageName: '',
      pageID: '',
      pageType: '',
      onsiteSearchTerm: '',
      onsiteSearchResult: '',
    },
    category: {
      primaryCategory: '',
      subCategory1: '',
      subCategory2: '',
    },
  },
  event: {
    eventName: [],
  },
  cart: {
    exists: '',
    item: [],
  },
  transaction: {
    transactionID: '',
    total: {
      shipmentMethod: '',
      shipping: '',
      postalCode: '',
      paymentMethod: '',
      voucherCode: '',
      discountValue: '',
      transactionTotal: '',
      item: [],
    },
    profile: {
      profileInfo: {
        companyName: '',
        companyID: '',
        companyGroup: '',
      },
    },
  },
  attribute: {
    comparisonProducts: [],
  },
  profile: {
    profileInfo: {
      userID: '',
      userType: '',
    },
  },
  link: {
    linkName: '',
  },
};
