import './styles.scss';

import { CheckCircleFilled } from '@ant-design/icons';
import { Form, Modal } from 'antd';
import CustomButton from 'components/CustomButton';
import useAsync from 'hooks/useAsync';
import {
  ALL_SERVICE_PAGES_TYPES,
  SERVICE_PACKAGES_TYPES,
} from 'libs/constants';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fapiCustomerService } from 'services/fapi';

import { EnquiryForm } from './RequestInfoForm';

const { useForm } = Form;

const RequestInfoModal = ({
  onClose,
  companyName,
  phoneNumber,
  serviceName,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const submitRequestInformation = (params) =>
    fapiCustomerService.submitRequestInformation(params);

  const { execute, status, error } = useAsync(submitRequestInformation, false);

  const isSuccess = status === 'success';

  const isLoading = status === 'pending';

  const shouldShowInput = !isSuccess;

  const servicePackageName = t(`service.servicePackages.${serviceName}.title`);

  const handleRequestInformation = async ({ contactType: type, ...params }) => {
    const body = { ...params };
    if (type === 'contactEmail') {
      delete body.contactPhone;
    } else if (type === 'contactPhone') {
      delete body.contactEmail;
    }

    execute(body);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      const bodyRequest = {
        servicePackageName,
        ...values,
      };

      handleRequestInformation(bodyRequest);
    } catch (err) {
      // TODO handle exception
    }

    return {};
  };

  return (
    <Modal
      visible
      className="enquiry-modal"
      closable={false}
      footer={null}
      width={570}
    >
      <h3 className="enquiry-modal__header">{servicePackageName}</h3>
      {isSuccess ? (
        <div className="enquiry-modal-success">
          <CheckCircleFilled />
          <span className="enquiry-modal-success__message">
            {t('service.servicePackages.requestInformation.successfullMessage')}
          </span>
          <CustomButton onClick={onClose}>
            {t(
              'service.servicePackages.requestInformation.backToServicePackages'
            )}
          </CustomButton>
        </div>
      ) : null}
      {shouldShowInput ? (
        <>
          <div className="enquiry-modal__description">
            {t('service.servicePackages.requestInformation.description')}
          </div>

          {error ? (
            <div className="enquiry-modal-error">
              {t('service.servicePackages.requestInformation.errorMessage')}
            </div>
          ) : null}

          <EnquiryForm
            form={form}
            isLoading={isLoading}
            initialValues={{ companyName, contactPhone: phoneNumber }}
          />

          <div className="enquiry-modal__footer">
            <CustomButton type="ghost" onClick={onClose} disabled={isLoading}>
              {t('enquiryModal.button.cancel')}
            </CustomButton>
            <CustomButton onClick={handleSubmit} loading={isLoading}>
              {t('enquiryModal.button.submitForm')}
            </CustomButton>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

RequestInfoModal.defaultProps = {
  companyName: 'N/A',
  phoneNumber: '',
};

RequestInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceName: PropTypes.oneOf(ALL_SERVICE_PAGES_TYPES).isRequired,
  companyName: PropTypes.string,
  phoneNumber: PropTypes.string,
};

const useRequestInfoModal = () => {
  const [visible, setVisible] = useState(false);
  const [serviceName, setServiceName] = useState(
    SERVICE_PACKAGES_TYPES.INSPECT
  );

  const render = useCallback(
    ({ companyName, phoneNumber }) =>
      visible && (
        <RequestInfoModal
          onClose={() => setVisible(false)}
          companyName={companyName}
          phoneNumber={phoneNumber}
          serviceName={serviceName}
        />
      ),
    [serviceName, visible]
  );

  return {
    RequestInfoModal: render,
    showRequestInfoModal: (name) => {
      if (!name) {
        // TODO missing service name handler

        return;
      }

      setServiceName(name);
      setVisible(true);
    },
  };
};

export default useRequestInfoModal;
