import './styles.scss';

import { Col, Divider, Form, Input, Row } from 'antd';
import { useCheckoutContext } from 'contexts/checkout-context';
import usePermission from 'hooks/usePermission';
import { MODULE_CART_CHECKOUT_RIGHTS } from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonsWrapperComponent from '../components/ButtonsWrapper';
import CustomerAddress from './CustomerAddress';
import CustomerBillingAddress from './CustomerBillingAddress';
import DeliveryAddress from './DeliveryAddress';

const StepOneComponent = ({ tab, form, setTabHandler }) => {
  const { t } = useTranslation();
  const { shouldShowServiceCode } = useCheckoutContext();
  const { hasPermission } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.CHANGE_DELIVERY_ADDRESS
  );

  const disableDeliveryAddress = !hasPermission;

  return (
    <div className="checkout-step1__wrapper">
      <div className="fw-container">
        <Row gutter={24}>
          <Col lg={12} xs={24}>
            <div className="checkout-step1-section__wrapper mt-4">
              <h3 className="checkout-step1-section__heading">
                {t('checkout.step1.customerAddress')}
              </h3>
              <CustomerAddress />
            </div>
          </Col>
          <Col lg={12} xs={24}>
            <div className="checkout-step1-section__wrapper mt-4">
              <h3 className="checkout-step1-section__heading">
                {t('checkout.step1.billingAddress')}
              </h3>
              <CustomerBillingAddress />
            </div>
          </Col>
        </Row>
        <Divider className="checkout-step1__divider" />
      </div>
      {shouldShowServiceCode && (
        <>
          <div className="fw-container">
            <Row>
              <Col xl={8} xs={24}>
                <h3>{t('checkout.serviceCode')}</h3>
                <Form.Item name="serviceCode" className="custom-form-item">
                  <Input placeholder={t('checkout.typeHere')} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Divider className="checkout-step1__divider" />
        </>
      )}
      <div className="fw-container bg-gray">
        <DeliveryAddress form={form} disabled={disableDeliveryAddress} />
        <Divider className="checkout-step1__divider" />
      </div>
      <div className="checkout__bottom-space" />
      <div className="fw-container">
        <ButtonsWrapperComponent tab={tab} setTabHandler={setTabHandler} />
      </div>
    </div>
  );
};

StepOneComponent.propTypes = {
  form: PropTypes.shape({}).isRequired,
  tab: PropTypes.number,
  setTabHandler: PropTypes.func,
};
StepOneComponent.defaultProps = {
  tab: 1,
  setTabHandler: () => {},
};

export default StepOneComponent;
