import { Col, Empty, Row } from 'antd';
import B2becProductCard from 'components/B2becProductCard';
import { notificationComponent } from 'components/Notification';
import useAsync from 'hooks/useAsync';
import { ASYNC_STATUS } from 'libs/constants';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { machineService } from 'services';

import CustomButton from '../../../components/CustomButton';
import B2becCardSkeleton from '../../../components/Skeletons/B2becCardSkeleton';

const getMyMachines = () =>
  machineService.getMachinesList({
    pageNumber: 1,
    pageSize: 3,
    sortOrder: 'ByOrderDateDesc',
  });

const MyMachines = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [myMachineList, setMyMachineList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleGetMachineListSuccess = useCallback((response) => {
    setMyMachineList(response?.data?.items);
    setTotalCount(response?.data?.totalCount);
  }, []);

  const handleGetMachineListError = useCallback((error) => {
    notificationComponent({
      type: NOTIFICATION_TYPE.ERROR,
      description: error?.message,
    });
  }, []);

  const { status: getMyMachinesStatus } = useAsync(
    getMyMachines,
    true,
    handleGetMachineListSuccess,
    handleGetMachineListError
  );

  const isLoading = getMyMachinesStatus === ASYNC_STATUS.PENDING;

  return (
    <div className="my-machines__section">
      <h2>{t('myMachines.title')}</h2>
      {isLoading ? (
        <B2becCardSkeleton
          number={3}
          gutter={20}
          columns={{ xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 8 }}
          imageHeight={528}
          hasPrice={false}
        />
      ) : isValidArray(myMachineList) ? (
        <>
          <Row gutter={[24, 24]} className="product-card__wrapper pb-5">
            {myMachineList.map((item) => (
              <Col
                key={`${item?.materialNumber}-${item?.orderDate}-${item?.salesOrderNumber}`}
                xs={24}
                sm={24}
                md={12}
                xl={8}
                lg={8}
              >
                <B2becProductCard
                  orderDate={item?.orderDate}
                  name={item?.name}
                  imgUrl={item?.imgUrl}
                  materialNumber={item?.materialNumber}
                  categoryName={item.categoryName}
                />
              </Col>
            ))}
          </Row>
          <div className="my-machines__section__see-all">
            <CustomButton
              onClick={() => history.push(linkGenerator('/my-machines'))}
              type="ghost"
            >
              {t('myWorkspace.seeAllMachines', {
                total: totalCount,
              })}
            </CustomButton>
          </div>
        </>
      ) : (
        <Empty description={t('noData')} />
      )}
    </div>
  );
};

export default MyMachines;
