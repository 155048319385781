import './styles.scss';

import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';
import B2BecLink from 'components/B2BLink';
import { SHOP_CATEGORY_TYPE } from 'libs/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

const SHOP_CATEGORY_URL = {
  [SHOP_CATEGORY_TYPE.MACHINES]: '/categories',
  [SHOP_CATEGORY_TYPE.ACCESSORIES]: '/categories/accessories',
  [SHOP_CATEGORY_TYPE.DETERGENTS]: '/categories/detergents',
};

const B2becShopCategoryBlock = (props) => {
  const { type, isLoading } = props;
  const { t } = useTranslation();

  return (
    <div className={`b2bec-shop-category b2bec-shop-category--${type}`}>
      <B2BecLink
        className="b2bec-shop-category__link"
        to={SHOP_CATEGORY_URL[type]}
      >
        {isLoading ? (
          <Skeleton style={{ height: 258 }} />
        ) : (
          <div className="b2bec-shop-category__image" />
        )}
        <div className="b2bec-shop-category__content">
          <h3 className="b2bec-shop-category__title">
            {t(`shopByCategory.${type}`)}
          </h3>
          <span>{t('shopByCategory.browseNow')}</span>
          <RightArrow style={{ marginLeft: 6, height: 10 }} />
        </div>
      </B2BecLink>
    </div>
  );
};

B2becShopCategoryBlock.propTypes = {
  type: PropTypes.oneOf([
    SHOP_CATEGORY_TYPE.MACHINES,
    SHOP_CATEGORY_TYPE.ACCESSORIES,
    SHOP_CATEGORY_TYPE.DETERGENTS,
  ]).isRequired,
};

export default B2becShopCategoryBlock;
