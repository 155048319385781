import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

const useCheckLocation = () => {
  const { path } = useRouteMatch();
  const [isInMyWorkspace, setIsInMyWorkspace] = useState(false);

  useEffect(() => {
    if (path.includes('my-workspace')) {
      setIsInMyWorkspace(true);
    }
  }, [path]);

  return {
    isInMyWorkspace,
  };
};

export default useCheckLocation;
