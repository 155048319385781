import CustomButton from 'components/CustomButton';
import { useAsync } from 'hooks';
import usePermission from 'hooks/usePermission';
import { ASYNC_STATUS, BUTTON_TYPE } from 'libs/constants';
import { MODULE_SERVICE_RIGHTS } from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { serviceCaseService } from 'services';
import { selectUserCountryId } from 'store/selectors/userSelector';

const useValidateExcludedMaterialNumber = (
  materialNumber,
  userCountryId,
  onSuccessHandler,
  onErrorHandler
) => {
  const { execute: executeValidateMaterialNumber, status } = useAsync(
    () =>
      serviceCaseService.validateMaterialNumber(materialNumber, userCountryId),
    false,
    onSuccessHandler,
    onErrorHandler
  );

  return {
    executeValidateMaterialNumber,
    isVerifying: status === ASYNC_STATUS.PENDING,
  };
};

const B2becAvailableServiceButton = (props) => {
  const { t } = useTranslation();
  const {
    verifiedPermissions: [canCreateRepairService, canCreateMaintenanceService],
  } = usePermission([
    MODULE_SERVICE_RIGHTS.CREATE_REPAIR_SERVICE_CASE,
    MODULE_SERVICE_RIGHTS.CREATE_MAINTENANCE_SERVICE_CASE,
  ]);

  const { materialNumber, onSuccess, onError } = props;

  const userCountryId = useSelector(selectUserCountryId);
  const { executeValidateMaterialNumber, isVerifying } =
    useValidateExcludedMaterialNumber(
      materialNumber,
      userCountryId,
      onSuccess,
      onError
    );

  const isDisabled =
    isVerifying || (!canCreateRepairService && !canCreateMaintenanceService);

  return (
    <CustomButton
      type={BUTTON_TYPE.ACTION}
      onClick={executeValidateMaterialNumber}
      disabled={isDisabled}
    >
      {isVerifying
        ? t('service.selectMachine.verifyingMachine')
        : t('service.selectMachine.availableService')}
    </CustomButton>
  );
};

B2becAvailableServiceButton.propTypes = {
  materialNumber: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default B2becAvailableServiceButton;
