import { createSlice } from '@reduxjs/toolkit';
import { ACTION_STATUS } from 'libs/constants';

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
  isAdminView: false,
  isSetAdminView: ACTION_STATUS.DEFAULT,
},
  reducers: {
    setIsAdminView: (state) => {
      state.isAdminView = !state.isAdminView;
      state.isSetAdminView = ACTION_STATUS.SUCCESS;
    },
  },
});

export const { setIsAdminView } = menuSlice.actions;

export default menuSlice.reducer;
