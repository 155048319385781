import './styles.scss';

import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const { TabPane } = Tabs;

export const B2becSteps = (props) => {
  return <Tabs {...props} className="b2bec-tab__steps" />;
};

export const B2becStepItem = (props) => {
  return <TabPane {...props} className="b2bec-tab__step-item" />;
};

export const B2becStepItemLabel = (props) => {
  const { index, label } = props;

  return (
    <>
      <span className="b2bec-tab__step-item__index">{index}</span>
      <span className="b2bec-tab__step-item__label">{label}</span>
    </>
  );
};

B2becStepItemLabel.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};
