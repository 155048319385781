import './styles.scss';

import { Col, Empty, Row } from 'antd';
import B2becPagination from 'components/B2becPagination';
import B2becSpinner from 'components/B2becSpinner';
import CustomButton from 'components/CustomButton';
import { PlusIcon } from 'components/Icons';
import ProductCard from 'components/ProductCard';
import B2becCardSkeleton from 'components/Skeletons/B2becCardSkeleton';
import { ACTION_STATUS, PAGE_SIZE } from 'libs/constants';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ProductListWithPagination = (props) => {
  const {
    classNames,
    isLoading,
    total,
    actionStatus,
    listData,
    onSelectMachineHandler,
    isMachineSelection,
    isMobile,
    getListHandler,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [productList, setProductList] = useState([]);

  const { t } = useTranslation();

  const isMobileRef = useRef(null);

  const handlePageChange = useCallback((current) => {
    setCurrentPage(current);
  }, []);

  useEffect(() => {
    const pageSize = isMobile ? PAGE_SIZE[4] : PAGE_SIZE[12];
    getListHandler(currentPage, pageSize);
  }, [currentPage, isMobile, getListHandler]);

  // for the desktop version, we still use the pagination normally
  // for the mobile version, pagination won't be applied anymore
  // the new products will keep expanding to the current list
  useEffect(() => {
    // make sure api finished calling
    if (actionStatus === ACTION_STATUS.SUCCESS) {
      // initial the ref variable at the first time
      if (isMobileRef.current === null) {
        isMobileRef.current = isMobile;
      }

      if (isMobile) {
        setProductList((prevState) => {
          // when changing device from desktop to mobile
          // current listData still contains 12 items, so it need to be cleard
          // if it is not the changing case, just push new items to current list
          return isMobile === isMobileRef.current
            ? [...prevState, ...listData]
            : [];
        });
        isMobileRef.current = true;
      } else {
        setProductList(listData);
        isMobileRef.current = false;
      }
    }
  }, [isMobile, isMobileRef, listData, actionStatus]);

  return (
    <>
      <ProductList
        classNames={classNames}
        isLoading={isLoading}
        productList={productList}
        isMachineSelection={isMachineSelection}
        onSelectMachineHandler={onSelectMachineHandler}
      />

      {isMobile ? (
        <div className="expand__wrapper">
          <CustomButton
            className="expand__button"
            buttonSize="default"
            type="ghost"
            onClick={() => handlePageChange(+(currentPage + 1))}
          >
            {t('productSubCategoryOverview.button.seeMore')}
            <PlusIcon />
          </CustomButton>
        </div>
      ) : (
        <B2becPagination
          current={currentPage}
          total={total}
          pageSize={PAGE_SIZE[12]}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

const ProductList = (props) => {
  const {
    classNames,
    isLoading,
    productList,
    isMachineSelection,
    onSelectMachineHandler,
  } = props;
  const { t } = useTranslation();
  const elementRef = useRef([]);

  const [isVerifyingMachine, setIsVerifyingMachine] = useState(false);

  return (
    <div className={classNames}>
      {
        // add loading behavior while verifying product
        // prevent user clicks on other products and process verification when the first validation is not finished
        isVerifyingMachine && <B2becSpinner isLoading={isVerifyingMachine} />
      }

      {isLoading ? (
        <B2becCardSkeleton
          number={4}
          gutter={20}
          columns={{ xs: 24, sm: 24, md: 12, lg: 6, xl: 6, xxl: 6 }}
          imageHeight={530}
          hasPrice={false}
        />
      ) : isValidArray(productList) ? (
        <Row
          gutter={20}
          className="product-card__wrapper"
          style={{ marginTop: 30 }}
        >
          {productList.map((item, index) => (
            <Col
              xs={24}
              sm={24}
              md={6}
              xl={6}
              lg={6}
              key={`${item?.materialNumber || item?.materialId}-${
                item?.salesOrderNumber
              }-${item?.serialNumber}`}
              id={`${item?.materialNumber || item?.materialId}-${
                item?.salesOrderNumber
              }`}
              className="card-item"
              // store every elements to the ref variable
              ref={(element) => {
                elementRef.current[index] = element;
              }}
            >
              <ProductCard
                date={item?.orderDate}
                name={item?.name}
                img={item?.imgUrl}
                materialId={item?.materialNumber}
                salesOrderNumber={item?.salesOrderNumber}
                serialNumber={item?.serialNumber}
                category={item?.categoryName}
                // pass the ref to the child component
                ref={elementRef}
                // options
                isMachineSelection={isMachineSelection}
                selectMachineHandler={() => onSelectMachineHandler(item)}
                setVerifyStatus={setIsVerifyingMachine}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <Empty description={t('noData')} />
      )}
    </div>
  );
};

export default ProductListWithPagination;

ProductListWithPagination.propTypes = {
  classNames: PropTypes.string,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  actionStatus: PropTypes.string,
  listData: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectMachineHandler: PropTypes.func,
  isMachineSelection: PropTypes.bool,
  isMobile: PropTypes.bool,
  getListHandler: PropTypes.func,
};

ProductListWithPagination.defaultProps = {
  classNames: null,
  isLoading: false,
  total: PropTypes.number,
  actionStatus: PropTypes.string,
  listData: [],
  onSelectMachineHandler: () => {},
  isMachineSelection: false,
  isMobile: false,
  getListHandler: () => {},
};

ProductList.propTypes = {
  classNames: PropTypes.string,
  isLoading: PropTypes.bool,
  productList: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectMachineHandler: PropTypes.func,
  isMachineSelection: PropTypes.bool,
};

ProductList.defaultProps = {
  classNames: null,
  isLoading: false,
  productList: [],
  onSelectMachineHandler: () => {},
  isMachineSelection: false,
};
