import B2becInfoLayout from 'components/B2becInfoLayout';
import B2becPagination from 'components/B2becPagination';
import { MagnifyingGlassIcon } from 'components/Icons';
import { useBreadcrumbs, useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_SIZE } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductsTable } from './ProductsTable';
import styles from './Rental.module.scss';
import { Search } from './Search';
import { useFetchProductsList } from './useFetchProductsList';

const RentalPage = ({ title, breadcrumbs, pageId }) => {
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  useAdobeAnalysis(null, {
    pageName: 'rental overview page',
    pageId,
    pageType: PAGE_TYPES.WISHLIST,
  });

  const { t } = useTranslation();

  const {
    isLoading,
    currentPage,
    totalPage,
    productsList,
    handleSearch,
    handlePageChange,
    handleSortChange,
  } = useFetchProductsList();

  const search = useMemo(
    () => (
      <div className={styles.searchContainer}>
        <Search
          icon={MagnifyingGlassIcon}
          size="large"
          placeholder={t('buttonTexts.search')}
          onSearch={handleSearch}
        />
      </div>
    ),
    [handleSearch, t]
  );

  const productsTable = useMemo(
    () => (
      <div className={styles.tableContainer}>
        <ProductsTable
          productsList={productsList}
          currentPage={currentPage}
          isLoading={isLoading}
          onSortChange={handleSortChange}
        />
      </div>
    ),
    [productsList, currentPage, isLoading, handleSortChange]
  );

  const pagination = useMemo(
    () => (
      <B2becPagination
        pageSize={PAGE_SIZE[10]}
        total={totalPage}
        onPageChange={handlePageChange}
        current={currentPage}
      />
    ),
    [currentPage, totalPage, handlePageChange]
  );

  return (
    <B2becInfoLayout>
      <B2becInfoLayout.Title>
        <h1 className={styles.title}>{t('rental.title')}</h1>
        <p className={styles.subtitle}>{t('rental.subtitle')}</p>
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        {search}
        {productsTable}
        {pagination}
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
};

export default RentalPage;
