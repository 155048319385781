import { createSlice } from '@reduxjs/toolkit';
import { ASYNC_STATUS } from 'libs/constants';
import { apiSlice } from 'store/slices/apiSlice';

import { FAPI_WISHLIST_ENDPOINT } from '../../libs/constants/b2bFapiApiEndpoints';

export const wishlistExtendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchWishlists: builder.query({
      query: ({ ownerId }) => `${FAPI_WISHLIST_ENDPOINT}/${ownerId}`,
      providesTags: ['Wishlists'],
    }),
    createWishlist: builder.mutation({
      query: ({ wishlistName }) => {
        const queryParams = new URLSearchParams();

        if (wishlistName) {
          queryParams.set('wishlistName', wishlistName);
        }

        return {
          url: `${FAPI_WISHLIST_ENDPOINT}/create?${queryParams.toString()}`,
          method: 'POST',
          body: {},
          responseHandler: (response) => response.text(),
        };
      },
      invalidatesTags: ['Wishlists'],
    }),
    addProductToWishlist: builder.mutation({
      query: ({
        ownerId,
        materialNumber,
        materialName,
        quantity,
        wishlistId,
      }) => {
        const queryParams = new URLSearchParams();

        if (ownerId) {
          queryParams.append('ownerId', ownerId);
        }

        if (materialNumber) {
          queryParams.append('MaterialNumber', materialNumber);
        }

        if (materialName) {
          queryParams.append('Name', materialName);
        }

        if (quantity) {
          queryParams.append('Quantity', quantity);
        }

        return {
          url: `${FAPI_WISHLIST_ENDPOINT}/${wishlistId}/items?${queryParams}`,
          method: 'PUT',
          body: {},
          responseHandler: (response) => response.text(),
        };
      },
      invalidatesTags: ['Wishlists'],
    }),
    createAndAddProductToWishlist: builder.mutation({
      queryFn: async (
        { wishlistName, ownerId, materialNumber, materialName, quantity },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const queryParams = new URLSearchParams();

        if (wishlistName) {
          queryParams.set('wishlistName', wishlistName);
        }

        const { data: wishlistId, error } = await fetchWithBQ({
          url: `${FAPI_WISHLIST_ENDPOINT}/create?${queryParams.toString()}`,
          method: 'POST',
          body: {},
          responseHandler: (response) => response.text(),
        });

        if (error) throw error;

        queryParams.delete('wishlistName');

        if (ownerId) {
          queryParams.append('ownerId', ownerId);
        }

        if (materialNumber) {
          queryParams.append('MaterialNumber', materialNumber);
        }

        if (materialName) {
          queryParams.append('Name', materialName);
        }

        if (quantity) {
          queryParams.append('Quantity', quantity);
        }

        const addResult = await fetchWithBQ({
          url: `${FAPI_WISHLIST_ENDPOINT}/${wishlistId}/items?${queryParams}`,
          method: 'PUT',
          body: {},
          responseHandler: (response) => response.text(),
        });

        return addResult.data
          ? {
              data: addResult.data,
            }
          : {
              error: addResult.error,
            };
      },
      invalidatesTags: ['Wishlists'],
    }),
  }),
});

const wishlistDetailInitialStates = {
  isAddingWishlistToCart: false,
  isRemoving: false,
  ownerName: '',
  ownerId: '',
  wishlistName: '',
  items: {
    items: [],
  },
  totalProduct: 0,
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {
    overview: {
      items: [],
      pagination: {},
      updatedTime: new Date().toISOString(),
    },
    productDetail: {
      materialName: '',
      materialNumber: '',
      img: '',
      quantity: 1,
    },
    fetchWishlistsState: ASYNC_STATUS.IDLE,
    isDeletingWishlist: false,
    isAddModalVisible: false,
    wishlistDetails: {
      isLoading: false,
      ...wishlistDetailInitialStates,
    },
  },
  reducers: {
    showAddProductToWishlistModal(state) {
      state.isAddModalVisible = true;
    },
    hideAddProductToWishlistModal(state) {
      state.isAddModalVisible = false;
    },
    getWishlistOverview(state) {
      state.fetchWishlistsState = ASYNC_STATUS.PENDING;
    },
    getWishlistOverviewSuccess(state, action) {
      Object.assign(state.overview, action.payload);
      state.fetchWishlistsState = ASYNC_STATUS.IDLE;
    },
    getWishlistOverviewFailed(state) {
      Object.assign(state.overview, {
        items: [],
        pagination: {},
      });
      state.fetchWishlistsState = ASYNC_STATUS.IDLE;
    },
    sendProductDetail(state, { payload }) {
      state.productDetail.materialName = payload.materialName;
      state.productDetail.materialNumber = payload.materialNumber;
      state.productDetail.img = payload.img;
    },
    clearProductDetail(state) {
      state.productDetail.materialName = '';
      state.productDetail.materialNumber = '';
      state.productDetail.img = '';
    },
    getWishlistDetails: (state) => {
      state.wishlistDetails.isLoading = true;
    },
    getWishlistDetailsSuccess: (state, { payload }) => {
      state.wishlistDetails.isLoading = false;
      state.wishlistDetails = { ...payload };
    },
    getWishlistDetailsFailed: (state) => {
      state.wishlistDetails.isLoading = false;
      state.wishlistDetails = wishlistDetailInitialStates;
    },
    removeWishlistProduct: (state) => {
      state.wishlistDetails.isRemoving = true;
    },
    removeWishlistProductSuccess: (state, { payload }) => {
      state.wishlistDetails.isRemoving = false;
      state.wishlistDetails.totalProduct = +(
        state.wishlistDetails.totalProduct - 1
      );
      state.wishlistDetails.items.items =
        state.wishlistDetails.items.items.filter(
          (i) => i?.materialNumber !== payload?.materialNumber
        );
    },
    removeWishlistProductFailed: (state) => {
      state.wishlistDetails.isRemoving = false;
    },
    updateProductQuantity: () => {},
    updateProductQuantitySucceed: (state, { payload }) => {
      const { materialNumber, quantity } = payload.productData;

      const mappedWishlistItems = state.wishlistDetails.items.items.map(
        (item) => {
          if (item.materialNumber === materialNumber) {
            return { ...item, quantity };
          }

          return item;
        }
      );

      state.wishlistDetails.items.items = mappedWishlistItems;
    },
    updateWishlistSharing: (state, { payload }) => {
      if (payload?.site === 'overview') {
        const updateIndex = state?.overview?.items?.findIndex(
          (item) => item?.wishlistId === payload?.wishlistId
        );
        state.overview.items[updateIndex].wishlistName = payload?.wishlistName;
        state.overview.items[updateIndex].collaboratorCount =
          payload?.collaboratorCount;
      }
      if (payload?.site === 'detail') {
        state.wishlistDetails.wishlistName = payload?.wishlistName;
      }
    },
    addAllProductsToCart: (state) => {
      state.wishlistDetails.isAddingWishlistToCart = true;
    },
    addAllProductsToCartSuccess: (state) => {
      state.wishlistDetails.isAddingWishlistToCart = false;
    },
    addAllProductsToCartFailed: (state) => {
      state.wishlistDetails.isAddingWishlistToCart = false;
    },
    deleteWishlist: (state) => {
      state.isDeletingWishlist = true;
    },
    deleteWishlistSuccess: (state) => {
      state.isDeletingWishlist = false;
      state.overview.updatedTime = new Date().toISOString();
    },
    deleteWishlistFailed: (state) => {
      state.isDeletingWishlist = false;
    },
  },
});

export const {
  useFetchWishlistsQuery,
  useCreateWishlistMutation,
  useAddProductToWishlistMutation,
  useCreateAndAddProductToWishlistMutation,
} = wishlistExtendedApi;

export const {
  showAddProductToWishlistModal,
  hideAddProductToWishlistModal,
  getWishlistOverview,
  getWishlistOverviewSuccess,
  getWishlistOverviewFailed,
  sendProductDetail,
  clearProductDetail,
  getWishlistDetails,
  getWishlistDetailsSuccess,
  getWishlistDetailsFailed,
  removeWishlistProduct,
  removeWishlistProductFailed,
  removeWishlistProductSuccess,
  updateProductQuantity,
  updateProductQuantitySucceed,
  addAllProductsToCart,
  addAllProductsToCartSuccess,
  addAllProductsToCartFailed,
  deleteWishlist,
  deleteWishlistSuccess,
  deleteWishlistFailed,
  updateWishlistSharing,
} = wishlistSlice.actions;

export default wishlistSlice.reducer;
