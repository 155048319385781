import B2becCustomSelect from 'components/B2becCustomSelect';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAvailableLanguages,
  selectInitialLangCode,
  selectIsChangingLanguage,
  selectIsFetchingUserConfigurations,
} from 'store/selectors/userSelector';

import usePermission from '../../../../hooks/usePermission';
import { MODULE_PROFILE_RIGHTS } from '../../../../libs/constants/modulerights';

const LanguageBlock = ({ onChangeLanguage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isFetchingUserConfigurations = useSelector(
    selectIsFetchingUserConfigurations
  );
  const isChangingLanguage = useSelector(selectIsChangingLanguage);
  const availableLanguages = useSelector(selectAvailableLanguages);
  const initialLangCode = useSelector(selectInitialLangCode);

  const [selectedLanguage, setSelectedLanguage] = useState(initialLangCode);
  const { hasPermission: hasChangeLanguagePermision } = usePermission(
    MODULE_PROFILE_RIGHTS.CHANGE_LANGUAGE
  );
  const canDisableButton =
    isFetchingUserConfigurations ||
    isChangingLanguage ||
    !hasChangeLanguagePermision;

  const selectedValue = useMemo(() => {
    const matchedLang = availableLanguages.find(
      (item) => item?.value === selectedLanguage
    );
    if (!matchedLang?.value) {
      return '';
    }
    return selectedLanguage;
  }, [availableLanguages, selectedLanguage]);

  const handleChange = useCallback(
    (newLanguage) => {
      onChangeLanguage(newLanguage, initialLangCode);
      setSelectedLanguage(newLanguage);
    },
    [onChangeLanguage, initialLangCode]
  );

  useEffect(() => {
    setSelectedLanguage(initialLangCode);
  }, [dispatch, initialLangCode]);

  return (
    <div className="profile__main-block">
      <div className="profile__main-block__label">
        {t('myProfile.changeLanguage')}
      </div>

      <div className="profile__main-block__description mb-4">
        {t('myProfile.LanguageDesc')}
      </div>

      <B2becCustomSelect
        style={{ width: 234 }}
        options={availableLanguages}
        value={selectedValue}
        onChange={handleChange}
        loading={canDisableButton}
        disabled={canDisableButton}
      />
    </div>
  );
};

LanguageBlock.propTypes = {
  onChangeLanguage: PropTypes.func,
};

LanguageBlock.defaultProps = {
  onChangeLanguage: () => {},
};

export default LanguageBlock;
