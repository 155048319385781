import { useEffect, useState } from 'react';

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const handleChange = () => setMatches(media.matches);

    if (media.addListener) {
      media.addListener(handleChange);
    } else {
      media.addEventListener('resize', handleChange);
    }

    return () => {
      if (media.removeListener) {
        media.removeListener(handleChange);
      } else {
        media.removeEventListener('resize', handleChange);
      }
    };
  }, [matches, query]);

  return matches;
}
