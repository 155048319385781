import './styles.scss';

import Footer from 'components/Layout/components/Footer';
import Header from 'components/PublicHeader/Header';
import ScrollToTop from 'components/ScrollToTop';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { parseHtml } from '../../../libs/utils/common';

const GeneralTermsAndConditions = ({ title, pageId }) => {
  useDocumentTitle(title);
  const { t, i18n } = useTranslation();

  useAdobeAnalysis(null, {
    pageName: 'general terms and conditions',
    pageId,
    pageType: PAGE_TYPES.POLICY,
  });

  const renderSections = () => {
    const sections = [];
    let i = 1;

    while (i18n.exists(`generalTermsAndConditions.section${i}`)) {
      sections.push(
        <div className="general-terms__section" key={`section-${i}`}>
          <section>
            {parseHtml(t(`generalTermsAndConditions.section${i}`))}
          </section>
        </div>
      );
      i += 1;
    }
    return sections;
  };

  return (
    <>
      <Header />
      <ScrollToTop />
      <div className="general-terms__wrapper">
        <div className="general-terms__wrapper-width">{renderSections()}</div>
      </div>
      <Footer />
    </>
  );
};

GeneralTermsAndConditions.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default GeneralTermsAndConditions;
