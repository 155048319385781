import './styles.scss';

import { Collapse } from 'antd';
import React from 'react';

import { ReactComponent as DownArrowIconSVG } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as UpArrowIconSVG } from '../../assets/icons/chevron-up.svg';

export default (props) => (
  <Collapse
    className="custom-collapse"
    expandIcon={({ isActive }) =>
      isActive ? <DownArrowIconSVG /> : <UpArrowIconSVG />
    }
    {...props}
  />
);
