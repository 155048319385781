import { linkGenerator } from 'libs/utils/language';
import { isEmptyObject } from 'libs/utils/object';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const B2BecLink = ({ to, state, children, ...props }) => {
  /**
   * to: the path to link to
   */

  let toInObject = {
    pathname: linkGenerator(to),
  };

  if (!isEmptyObject(state)) {
    toInObject = {
      ...toInObject,
      state: {
        ...state,
      },
    };
  }

  return (
    <Link to={toInObject} {...props}>
      {children}
    </Link>
  );
};

B2BecLink.propTypes = {
  to: PropTypes.string,
  state: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

B2BecLink.defaultProps = {
  to: '',
  state: {},
  children: null,
};

export default B2BecLink;
