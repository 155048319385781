import './styles.scss';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { adminFapiUserManagement } from 'services/adminFapi';

import Translator from '../../../../../../components/B2becTranslation';
import { notificationComponent } from '../../../../../../components/Notification';
import useAsync from '../../../../../../hooks/useAsync';
import { ASYNC_STATUS, STATUS_CODE } from '../../../../../../libs/constants';
import { NOTIFICATION_TYPE } from '../../../../../../libs/constants/notification';
import { selectUserCountryId } from '../../../../../../store/selectors/userSelector';
import ActionButtonWithModal from '../ActionsModalComponent';

function PasswordSection(props) {
  const { isLoading, userId } = props;
  const { t } = useTranslation();

  const { countryId } = useSelector((state) => ({
    countryId: selectUserCountryId(state),
  }));

  const resetUserPassword = useCallback(
    (cancelToken) => (uid) =>
      adminFapiUserManagement.submitResetUserPassword(
        uid,
        countryId,
        cancelToken
      ),
    [countryId]
  );

  const { execute, status } = useAsync(
    resetUserPassword,
    false,
    undefined,
    undefined,
    true
  );

  const onResetPasswordHandler = useCallback(() => {
    if (userId) {
      execute(userId).then(({ response, error }) => {
        if (response?.status === STATUS_CODE.SUCCESS) {
          notificationComponent({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: <Translator value="notification.success.resetPassword" />,
            description: (
              <Translator value="notification.success.resetPasswordDescription" />
            ),
          });
        }
        if (error) {
          notificationComponent({
            type: NOTIFICATION_TYPE.ERROR,
            message: <Translator value="notification.error.resetPassword" />,
          });
        }
      });
    }
  }, [userId, execute]);

  const translateTextByKey = useCallback(
    (key) =>
      t(`adminWorkspace.userManagement.userDetails.passwordSection.${key}`),
    [t]
  );

  return (
    <ActionButtonWithModal
      isLoading={status === ASYNC_STATUS.PENDING}
      isDisabled={isLoading}
      title={translateTextByKey('title')}
      description={translateTextByKey('description')}
      buttonName={translateTextByKey('button')}
      modalTitle={t(
        'adminWorkspace.userManagement.userDetails.passwordSection.button'
      )}
      modalContent={t(
        'adminWorkspace.userManagement.userDetails.passwordSection.modal.description'
      )}
      onConfirmAction={onResetPasswordHandler}
    />
  );
}

PasswordSection.propTypes = {
  isLoading: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

PasswordSection.defaultProps = {
  isLoading: false,
};

export default React.memo(PasswordSection);
