import './styles.scss';

import { Select } from 'antd';
import clsx from 'clsx';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';

const B2becCustomSelect = forwardRef(
  (
    {
      onDropdownVisibleChange,
      getPopupContainer,
      className,
      loading,
      ...restProps
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOnDropdownVisibleChange = (visible) => {
      setIsOpen(visible);
      onDropdownVisibleChange(visible);
    };

    const suffixIcon = loading ? null : isOpen ? (
      <ChevronUpIcon />
    ) : (
      <ChevronDownIcon />
    );
    return (
      <div className="b2bec-select">
        <Select
          {...restProps}
          ref={ref}
          className={clsx('custom-ant-select', className)}
          popupClassName="custom-select-dropdown"
          onDropdownVisibleChange={(visible) =>
            handleOnDropdownVisibleChange(visible)
          }
          getPopupContainer={getPopupContainer}
          dropdownAlign={{ offset: [0, -1] }}
          suffixIcon={suffixIcon}
        />
      </div>
    );
  }
);

B2becCustomSelect.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  onDropdownVisibleChange: PropTypes.func,
  getPopupContainer: PropTypes.func,
};

B2becCustomSelect.defaultProps = {
  className: null,
  loading: false,
  onDropdownVisibleChange: () => {},
  getPopupContainer: (triggerNode) => triggerNode.parentElement,
};

export default B2becCustomSelect;
