import environment from 'environments/environment.dev';
import { VERSION_1 } from 'libs/constants/b2bApiEndpoints';
import { CUSTOMER_USER } from 'libs/constants/user';

import HttpClient from '../apiHttpClient';

const fapiClient = new HttpClient(environment.adminFAPIUrl);

const USER_INVITATION = `/${VERSION_1}/user/invitation`;

const getUserList = ({
  current,
  pageSize,
  sortBy,
  orderBy,
  searchTerm,
  selectedLetter,
  country,
  userType = CUSTOMER_USER,
}) => {
  const params = new URLSearchParams({
    pageNumber: current,
    pageSize,
    sortBy,
    orderBy,
    searchTerm,
    selectedLetter,
    country,
  });
  return fapiClient.get(`${VERSION_1}/${userType}/list?${params}`);
};

const getUserDetailsById = (userId, cancelToken) => {
  return fapiClient.get(`${VERSION_1}/user/detail/${userId}`, {
    cancelToken,
  });
};

const deleteUserAccount = (userId, deleteBy, cancelToken) => {
  return fapiClient.delete(`${VERSION_1}/user`, {
    body: { id: userId, deleteBy },
    cancelToken,
  });
};

const editUser = (userId) => {
  return (body) => {
    return fapiClient.put(`${VERSION_1}/user/detail`, {
      body: {
        userId,
        ...body,
      },
    });
  };
};

const submitResetUserPassword = (userId, countryId, cancelToken) => {
  const params = new URLSearchParams({
    userId,
    countryId,
  });
  return fapiClient.post(`${VERSION_1}/user/resetpassword?${params}`, {
    body: {},
    cancelToken,
  });
};

const editUserEmailAddress = (userId) => {
  return (body) => {
    return fapiClient.post(`${VERSION_1}/user/changeemail/${userId}`, {
      body,
    });
  };
};

const editCustomerSap = (userId) => {
  return (body) => {
    return fapiClient.put(`${VERSION_1}/user/sapcustomerid`, {
      body: {
        userId,
        ...body,
      },
    });
  };
};

const exportUserList = (userType) => {
  return fapiClient.get(`${VERSION_1}/user/exportuser/summary`, {
    queryParams: {
      userType,
    },
  });
};

const revokeInvitation = (
  invitationId,
  reactivateRevoke = false,
  cancelToken
) => {
  return fapiClient.put(`${USER_INVITATION}/revoke`, {
    body: {
      invitationId,
      reactivateRevoke,
    },
    cancelToken,
  });
};

const deleteInvitation = (invitationId, countryId, cancelToken) => {
  return fapiClient.delete(`${USER_INVITATION}`, {
    body: {
      invitationId,
      countryId,
    },
    cancelToken,
  });
};

const inviteNewUser = (body) => {
  return fapiClient.post(USER_INVITATION, {
    body,
  });
};

const getInvitedUserDetailsById = (invitationId, cancelToken) => {
  return fapiClient.get(`${USER_INVITATION}/${invitationId}`, {
    cancelToken,
  });
};

const updateInvitation = (invitationId) => {
  return (body) =>
    fapiClient.put(`${USER_INVITATION}/update`, {
      body: {
        ...body,
        invitationId,
      },
    });
};

const uploadInvitations = (file) => {
  const url = `${VERSION_1}/user/upload-invitation`;

  return fapiClient.post(url, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: file,
  });
};

export const adminFapiUserManagement = {
  getUserList,
  getUserDetailsById,
  deleteUserAccount,
  editUser,
  submitResetUserPassword,
  editUserEmailAddress,
  editCustomerSap,
  exportUserList,
  revokeInvitation,
  deleteInvitation,
  inviteNewUser,
  getInvitedUserDetailsById,
  updateInvitation,
  uploadInvitations,
};
