import environment from '../environments/environment.dev';
import { FAPI_SEARCH_ENDPOINT } from '../libs/constants/b2bApiEndpoints';
import HttpClient from './apiHttpClient';

class SearchService {
  constructor(baseUrl) {
    this.httpClient = new HttpClient(baseUrl);
  }

  getSearchResult(
    searchAll = false,
    query = '',
    size = 10,
    from = 0,
    lang,
    category
  ) {
    const searchEndpoint = searchAll
      ? `${FAPI_SEARCH_ENDPOINT}/searchwithincategories`
      : FAPI_SEARCH_ENDPOINT;

    const searchParams = new URLSearchParams({
      query,
      size,
    });
    if (searchAll) {
      searchParams.append('searchProductType', category);
    }
    if (lang !== 'en') {
      searchParams.append('l', lang);
    }
    if (from !== 0) {
      searchParams.append('from', from);
    }
    return this.httpClient.get(`${searchEndpoint}?${searchParams.toString()}`);
  };

  getSearchSuggestions = (query = '', size = 8, from = 0) => {
    const searchParams = new URLSearchParams({
      query,
      from,
      size,
    });

    return this.httpClient.get(
      `${FAPI_SEARCH_ENDPOINT}/suggestions?${searchParams.toString()}`
    );
  };
}

export const searchService = new SearchService(environment.shopFAPIUrl);
