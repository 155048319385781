import { createSlice } from '@reduxjs/toolkit';
import { SUPPORTED_LANGUAGES } from 'libs/constants/lang';

import { CONFIG_DATE_FORMAT, CONFIG_NAMES } from '../../libs/constants/configs';

const fallbackDateFormat = 'YYYY-MM-DD';
const fallbackNumberSeparator = 'en-GB';

const config = createSlice({
  name: 'config',
  initialState: {
    availableLanguages: {
      isLoading: false,
      isError: false,
      data: null,
    },
    availableCountries: {
      isLoading: false,
      isError: false,
      data: null,
    },
    currencyConfig: {
      currency_position_front: false,
      currency: undefined,
    },
    dateFormat: fallbackDateFormat,
    logo: 'karcher',
    serviceRepairPage: '',
    isCurrentTimeInSAPDowntime: false,
    locales: SUPPORTED_LANGUAGES,
    equipmentManagerLink: '',
    hideQuickEntry: true,
    hideHouseNumberEntryField: false,
    regionList: [],
    numberSeparator: fallbackNumberSeparator,
    servicePackagePage: '',
    showSparePartLink: false,
    availableSaleOrgs: [],
    shouldRenderServiceMenu: true,
  },
  reducers: {
    getAllConfig() { },
    getConfig(state, action) {
      const source = state[action.payload];
      if (source) {
        source.isLoading = true;
        source.isError = false;
        source.data = null;
      }
    },
    getConfigSuccess(state, action) {
      const source = state[action.payload.key];
      if (source) {
        source.isLoading = false;
        source.isError = false;
        source.data = action.payload.data;
      }
    },
    getConfigFailed(state, action) {
      const source = state[action.payload];
      if (source) {
        source.isLoading = false;
        source.isError = true;
        source.data = null;
      }
    },
    getUserConfigurationsSuccess(state, { payload }) {
      const {
        currency_position_front: currencyPositionFront,
        currency_symbol: currencySymbol,
        Logo: logo,
        date_format: dateFormat,
        Equipment_Manager_link: equipmentManagerLink,
        Hide_quick_entry: hideQuickEntry,
        Rental_Available: rentalAvailable,
        service_repair_page: serviceRepairPage,
        Address_scheme: addressScheme,
        Region_list: regionList,
        [CONFIG_NAMES.AVAILABLE_LANGUAGES]: availableLanguages,
        [CONFIG_NAMES.NUMBER_SEPARATOR]: numberSeparator,
        [CONFIG_NAMES.SERVICE_PACKAGE_PAGE]: servicePackagePage,
        [CONFIG_NAMES.SHOW_SPARE_PART_LINK]: showSparePartLink,
        [CONFIG_NAMES.SERVICE_MENU]: shouldRenderServiceMenu,
        [CONFIG_NAMES.HIDE_HOUSE_NUMBER_ENTRY_FIELD]: hideHouseNumberEntryField,
        [CONFIG_NAMES.AVAILABLE_SALESORG]: availableSaleOrgs,
      } = payload || {};

      state.currencyConfig.currency_position_front = currencyPositionFront;
      state.currencyConfig.currency_symbol = currencySymbol;
      state.logo = logo;
      state.rentalAvailable = rentalAvailable;
      state.shouldRenderServiceMenu = shouldRenderServiceMenu;
      state.availableSaleOrgs = availableSaleOrgs;
      if (
        CONFIG_DATE_FORMAT.findIndex(
          (configItem) => configItem.value === dateFormat
        ) !== -1
      ) {
        state.dateFormat = dateFormat;
      }
      if (addressScheme === 'extended') {
        try {
          state.regionList = JSON.parse(regionList);
        } catch {
          // TODO
        }
      }
      state.equipmentManagerLink = equipmentManagerLink;
      state.hideQuickEntry = hideQuickEntry;
      state.hideHouseNumberEntryField = hideHouseNumberEntryField;
      state.serviceRepairPage = serviceRepairPage;
      state.availableLanguages.data = availableLanguages;
      state.numberSeparator = numberSeparator;
      state.servicePackagePage = servicePackagePage;
      state.showSparePartLink = showSparePartLink;
      state.isLoading = false;
      state.isError = false;
    },
    getUserConfigurationsFailed() { },
    getSAPDowntimeSuccess(state, { payload }) {
      state.isCurrentTimeInSAPDowntime = payload;
    },
    getCountries() { },
    getCountriesSuccess(state) {
      state.isLoading = true;
      state.isError = false;
      state.isError = false;
    },
    getCurrencyConfigSuccess(state, action) {
      Object.assign(state.currencyConfig, action.payload);
    },
    getAvailableSaleOrgs() { },
    getAvailableSaleOrgsSuccess(state, { payload }) {
      state.availableSaleOrgs = payload;
    },
  },
});

export const {
  getAllConfig,
  getConfig,
  getConfigSuccess,
  getConfigFailed,
  getUserConfigurationsSuccess,
  getUserConfigurationsFailed,
  getSAPDowntimeSuccess,
  getCountries,
  getCurrencyConfigSuccess,
} = config.actions;

export default config.reducer;
