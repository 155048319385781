import { linkGenerator } from 'libs/utils/language';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CustomButton from '../../../../components/CustomButton';

const PasswordBlock = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <div className="profile__main-block">
        <div className="profile__main-block__label">
          {t('myProfile.password')}
        </div>
        <div className="profile__main-block__description">
          {t('myProfile.passwordDesc')}
        </div>
      </div>
      <CustomButton
        className="btn btn--submit mt-4"
        type="primary"
        onClick={() => history.push(linkGenerator('/profile/change-password'))}
      >
        {t('setPassword.title')}
      </CustomButton>
    </>
  );
};
export default PasswordBlock;
