import './styles.scss';

import { Upload } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { ReactComponent as UploadIconSVG } from 'assets/icons/upload.svg';
import B2becInfoLayout from 'components/B2becInfoLayout';
import CustomButton from 'components/CustomButton';
import { DownloadIcon } from 'components/Icons';
import { NotificationManager } from 'components/Notification';
import useAsync from 'hooks/useAsync';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import usePermission from 'hooks/usePermission';
import { ASYNC_STATUS } from 'libs/constants';
import { MODULE_ADMINISTRATION_RIGHTS } from 'libs/constants/modulerights';
import { ACCEPTED_FILE_EXTENSIONS } from 'libs/constants/upload';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { adminFapiServiceCaseService } from 'services/adminFapi';
import { selectUserCountryId } from 'store/selectors/userSelector';

const downloadExcludedMachinesListApi = (countryId) =>
  adminFapiServiceCaseService.downloadExcludedMachinesList(countryId);

export default function ServiceCasesManagement(props) {
  const { title, breadcrumbs } = props;
  const { t } = useTranslation();
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  const isMountedRef = useRef(null);
  const {
    verifiedPermissions: [
      canUploadExcludedMachinesList,
      canDownloadExcludedMachinesList,
    ],
  } = usePermission([
    MODULE_ADMINISTRATION_RIGHTS.UPLOAD_EXCLUDED,
    MODULE_ADMINISTRATION_RIGHTS.ROCK_LIST,
  ]);

  const countryId = useSelector(selectUserCountryId);

  const uploadFileApi = (fileName, file) => {
    return adminFapiServiceCaseService.uploadExcludeMachinesList(
      countryId,
      fileName,
      file
    );
  };

  const { execute: executeUploadFile, status: uploadingStatus } = useAsync(
    uploadFileApi,
    false
  );

  const isUploading = uploadingStatus === ASYNC_STATUS.PENDING;

  const handleDownloadCSVSuccess = useCallback((response) => {
    if (response?.status === 200 && response?.data) {
      const csvFileLink = document.createElement('a');
      const blob = new Blob([response.data], {
        type: 'text/csv',
      });
      const url = URL.createObjectURL(blob);

      csvFileLink.setAttribute('href', url);
      csvFileLink.setAttribute(
        'download',
        `excluded_machines_${Date.now()}.csv`
      );
      csvFileLink.click();

      URL.revokeObjectURL(url);

      NotificationManager.success({
        message: 'notification.success.downloadFile',
      });
    }
  }, []);

  const handleDownloadCSVError = useCallback((error) => {
    NotificationManager.error({
      message: 'notification.error.downloadFile',
      description: error?.response?.data,
    });
  }, []);

  const {
    execute: executeDownloadExcludedMachines,
    status: downloadingStatus,
  } = useAsync(
    downloadExcludedMachinesListApi,
    false,
    handleDownloadCSVSuccess,
    handleDownloadCSVError
  );

  const isDownloading = downloadingStatus === ASYNC_STATUS.PENDING;

  const uploadProductListHandler = useCallback(
    (e) => {
      isMountedRef.current = true;
      const fileName = e?.file?.name;
      const file = e?.file;

      executeUploadFile(fileName, file).then((response, error) => {
        if (isMountedRef.current && !response.error) {
          NotificationManager.success({
            message: 'notification.success.uploadFile',
          });
        }
        if (error || response.error) {
          NotificationManager.error({
            message: 'notification.error.uploadFile',
            description: error?.response?.data,
          });
        }
      });

      return () => {
        isMountedRef.current = false;
      };
    },
    [executeUploadFile]
  );

  const downloadExcludedMachinesHandler = useCallback(() => {
    isMountedRef.current = true;
    executeDownloadExcludedMachines(countryId);
    return () => {
      isMountedRef.current = false;
    };
  }, [executeDownloadExcludedMachines, countryId]);

  const renderUploadText = useCallback(
    (textKey) => {
      return isUploading ? t('buttonTexts.uploading') : t(textKey);
    },
    [isUploading, t]
  );

  const renderDownloadText = useCallback(
    (textKey) => {
      return isDownloading ? t('buttonTexts.downloading') : t(textKey);
    },
    [isDownloading, t]
  );

  return (
    <B2becInfoLayout className="service-management__container">
      <B2becInfoLayout.Title title={t('pageTitle.serviceManagement')}>
        <div className="service-management__buttons-container">
          {canDownloadExcludedMachinesList && (
            <CustomButton
              onClick={downloadExcludedMachinesHandler}
              className="service-management__download-btn"
              disabled={isDownloading}
            >
              <DownloadIcon />
              {renderDownloadText('serviceManagement.button.download')}
            </CustomButton>
          )}
          {canUploadExcludedMachinesList && (
            <Upload
              listType="file"
              showUploadList={false}
              beforeUpload={() => false}
              onChange={uploadProductListHandler}
              accept={ACCEPTED_FILE_EXTENSIONS.CSV}
            >
              <CustomButton
                disabled={isUploading}
                className="service-management__upload-btn"
              >
                <UploadIconSVG />
                {renderUploadText('serviceManagement.button.upload')}
              </CustomButton>
            </Upload>
          )}
        </div>
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        {canUploadExcludedMachinesList && (
          <Dragger
            listType="file"
            accept={ACCEPTED_FILE_EXTENSIONS.CSV}
            beforeUpload={() => false}
            multiple={false}
            showUploadList={false}
            disabled={isUploading}
            onChange={uploadProductListHandler}
          >
            <p className="ant-upload-drag-icon">
              <UploadIconSVG />
            </p>
            <p className="ant-upload-text">
              {renderUploadText('serviceManagement.draggerDescription')}
            </p>
          </Dragger>
        )}
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
}

ServiceCasesManagement.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};
