import './styles.scss';

import { Col, Form, Input, Row } from 'antd';
import B2becCustomSelect from 'components/B2becCustomSelect';
import B2becTranslation from 'components/B2becTranslation';
import { CustomFormItem } from 'components/B2bUserForm';
import CustomButton from 'components/CustomButton';
import { NotificationManager } from 'components/Notification';
import Content from 'components/PublicHeader/Content';
import useAsync from 'hooks/useAsync';
import { ASYNC_STATUS, isFutureTech, STATUS_CODE } from 'libs/constants';
import { DEFAULT_LANGUAGES } from 'libs/constants/lang';
import { USER_SALUTATION_ARRAY } from 'libs/constants/user';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { fapiInvitationService } from 'services/fapi';

import LegalAgreements from '../LegalAgreements';
import Password from '../Password';
import { validatePhoneForFT } from './helper';

function RegistrationForm(props) {
  const {
    disabledSubmit,
    isLoading,
    isTokenValid,
    invitationId,
    invitationToken,
    customerNumber,
    salutation,
    firstName,
    lastName,
    email: contactEmail,
    phoneNumber: contactNumber,
    countryId,
    language,
    createdBy,
  } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isShowNewsLetter, setShowNewsLetter] = useState(false);

  const registerNewUser = useCallback(
    (body) => fapiInvitationService.registerNewUserWithPassword(body),
    []
  );

  const displayErrors = useCallback(
    (errors) => {
      // Todo: BE returns only error as string
      // Ex: Email 'abc@xyz' is already in used.

      if (errors?.startsWith('Email')) {
        form.setFields([
          {
            name: 'contactEmail',
            errors: [errors],
          },
        ]);
      } else if (errors?.startsWith('PhoneNumber')) {
        form.setFields([
          {
            name: 'contactNumber',
            errors: [errors],
          },
        ]);
      }
    },
    [form]
  );

  const onSuccess = useCallback(
    (response) => {
      if (response?.status === STATUS_CODE.CREATED_SUCCESS) {
        NotificationManager.success({
          message: 'notification.success.registration.message',
          description: 'notification.success.registration.description',
          onClose: () => history.replace(linkGenerator('')),
        });
      } else {
        NotificationManager.error({
          message: 'notification.error.registration.message',
          description: 'notification.error.registration.description',
        });
      }
    },
    [history]
  );

  const onError = useCallback(
    (error) => {
      NotificationManager.error({
        message: 'notification.error.registration.message',
        description: 'notification.error.registration.description',
      });
      displayErrors(error?.response?.data);
    },
    [displayErrors]
  );

  const getNewsletterAvailableList = useCallback(
    () => fapiInvitationService.getNewsletterAvailableList(),
    []
  );

  const onCheckCultureIsValidForNewsletter = useCallback(
    (availableList) => {
      const availableCountry = availableList.find(
        (item) => item?.country.toLowerCase() === countryId.toLowerCase()
      );

      if (availableCountry) {
        return availableCountry.languages
          .map((lang) => lang.toLowerCase())
          .includes(language.toLowerCase());
      }
      return false;
    },
    [language, countryId]
  );

  const onGetListSuccess = useCallback(
    (response) => {
      setShowNewsLetter(onCheckCultureIsValidForNewsletter(response?.data));
    },
    [onCheckCultureIsValidForNewsletter]
  );

  // Todo: Uncomment when the newsletter works normally on production
  // const onGetListFail = useCallback(
  //   (error) => {
  //     notificationComponent({
  //       type: NOTIFICATION_TYPE.ERROR,
  //       message: t('notification.error.getCountries'),
  //       description: error?.response?.data,
  //     });
  //   },
  //   [t]
  // );

  const { execute, status } = useAsync(
    registerNewUser,
    false,
    onSuccess,
    onError
  );

  const { execute: executeGetNewsletterAvailableList } = useAsync(
    getNewsletterAvailableList,
    false,
    onGetListSuccess
  );
  useEffect(() => {
    if (isTokenValid) {
      const formValues = {
        salutation,
        firstName,
        lastName,
        contactEmail,
        contactNumber,
      };
      Object.keys(formValues).forEach((key) => {
        if (!formValues[key]) {
          delete formValues[key];
        }
      });
      form.setFieldsValue(formValues);
    }
  }, [
    isTokenValid,
    form,
    salutation,
    firstName,
    lastName,
    contactEmail,
    contactNumber,
  ]);

  useEffect(() => {
    if (isTokenValid && language && countryId) {
      executeGetNewsletterAvailableList();
    }
  }, [isTokenValid, language, countryId, executeGetNewsletterAvailableList]);

  const validateMessages = {
    required: (label = '') => {
      return (
        <B2becTranslation
          value="form.validate.required"
          variables={{ label }}
        />
      );
    },
  };

  const onSubcribeNewsletter = useCallback(
    (e) => {
      const isSubcribeNewsLetter = e?.target?.checked;
      form.setFieldsValue({ isSubcribeNewsLetter });
    },
    [form]
  );

  const onSubmit = (values) => {
    execute({
      id: invitationId,
      token: invitationToken,
      sapCustomerNumber: customerNumber,
      salutation: values?.salutation,
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.contactEmail,
      phoneNumber: values?.contactNumber,
      password: values?.password,
      country: countryId,
      language,
      createdBy,
      isSubscribeToNewsletterChecked: !!values?.isSubcribeNewsLetter,
    });
  };

  const onCancel = () => {
    history.push(linkGenerator(''));
  };

  const renderValidatingState = () => (
    <>
      <div className="registration-page__form__loading">
        {t('registration.form.validatingIdentity')}
      </div>
      <Skeleton height={20} />
    </>
  );

  const renderForm = () => (
    <>
      <p className="registration-page__form__requirements">
        {t('registration.form.requirements')}
      </p>
      <Form
        className="registration-page__form"
        layout="vertical"
        form={form}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={onSubmit}
      >
        <div className="registration-page__form__requirements">
          <h3>{t('registration.form.personalDetails')}</h3>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <CustomFormItem
                className="registration-page__form__salutation"
                name="salutation"
              >
                <B2becCustomSelect
                  options={USER_SALUTATION_ARRAY.map((item) => ({
                    label: t(`form.label.${item.label}`),
                    value: item.value,
                  }))}
                />
              </CustomFormItem>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <CustomFormItem
                className="registration-page__form__first-name"
                name="firstName"
                rules={[{ required: true }]}
              >
                <Input />
              </CustomFormItem>
            </Col>
            <Col span={12}>
              <CustomFormItem
                className="registration-page__form__last-name"
                name="lastName"
                rules={[{ required: true }]}
              >
                <Input />
              </CustomFormItem>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <CustomFormItem name="contactEmail" rules={[{ required: true }]}>
                <Input />
              </CustomFormItem>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <CustomFormItem
                className="registration-page__form__contact-number"
                name="contactNumber"
                rules={[
                  { required: true },
                  {
                    validator: (_, phoneNumber) => {
                      if (isFutureTech(countryId)) {
                        if (!validatePhoneForFT(phoneNumber)) {
                          return Promise.reject(
                            t('enquiryModal.form.validateMesages.phone')
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </CustomFormItem>
            </Col>
          </Row>
        </div>
        <Password />
        <LegalAgreements
          showNewsLetterCheckbox={isShowNewsLetter}
          onSubcribeNewsletter={onSubcribeNewsletter}
        />
        <div className="registration-page__form__actions">
          <Form.Item noStyle>
            <CustomButton
              className="registration-page__form__actions__cancel"
              type="ghost"
              onClick={onCancel}
              disabled={status === ASYNC_STATUS.PENDING}
            >
              {t('buttonTexts.cancel')}
            </CustomButton>
          </Form.Item>
          <Form.Item noStyle>
            <CustomButton
              className="registration-page__form__actions__submit"
              htmlType="submit"
              disabled={status === ASYNC_STATUS.PENDING || disabledSubmit}
            >
              {t('buttonTexts.register')}
            </CustomButton>
          </Form.Item>
        </div>
      </Form>
    </>
  );

  return (
    <Content className="registration-page__form__wrapper">
      {isLoading ? renderValidatingState() : renderForm()}
    </Content>
  );
}

RegistrationForm.propTypes = {
  disabledSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isTokenValid: PropTypes.bool,
  invitationId: PropTypes.string,
  invitationToken: PropTypes.string,
  customerNumber: PropTypes.string,
  salutation: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  countryId: PropTypes.string,
  language: PropTypes.string,
  createdBy: PropTypes.string,
};

RegistrationForm.defaultProps = {
  disabledSubmit: false,
  isLoading: false,
  isTokenValid: false,
  invitationId: '',
  invitationToken: '',
  customerNumber: '',
  salutation: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  countryId: '',
  language: DEFAULT_LANGUAGES[0].value,
  createdBy: '',
};

export default RegistrationForm;
