import './styles.scss';

import B2BecLink from 'components/B2BLink';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const SUB_ITEM_LIST_TYPES = {
  CATEGORY_OVERVIEW: 'category-overview',
  SUB_CATEGORY_OVERVIEW: 'sub-category-overview',
};

function SubItemList(props) {
  const { type, mainCategoryId, subCategoryId, listData } = props;

  const location = useLocation();
  const productTypeLinkPrefix = useMemo(() => {
    const result = location.pathname.match(/^\/accessories|\/detergents/);
    return result && result.length > 0 ? result[0] : '';
  }, [location.pathname]);

  if (listData.length === 0) {
    return null;
  }
  if (type === SUB_ITEM_LIST_TYPES.SUB_CATEGORY_OVERVIEW) {
    return (
      <ul className="sub-item__list m-0">
        {listData.map((item) => (
          <li key={item.categoryId} className="mb-1">
            <h3 className="m-0">
              <B2BecLink
                to={`${productTypeLinkPrefix}/category/${mainCategoryId}/subcategory/${subCategoryId}/class/${item.categoryId}`}
              >
                {item.categoryName}
              </B2BecLink>
            </h3>
          </li>
        ))}
      </ul>
    );
  }
  return (
    <ul className="sub-item__list m-0">
      {listData.map((item) => (
        <li key={item.categoryId} className="mb-1">
          <h3 className="m-0">
            <B2BecLink
              to={`${productTypeLinkPrefix}/category/${mainCategoryId}/subcategory/${item.categoryId}`}
            >
              {item.categoryName}
            </B2BecLink>
          </h3>
        </li>
      ))}
    </ul>
  );
}

SubItemList.propTypes = {
  type: PropTypes.oneOf([
    SUB_ITEM_LIST_TYPES.CATEGORY_OVERVIEW,
    SUB_ITEM_LIST_TYPES.SUB_CATEGORY_OVERVIEW,
  ]),
  mainCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

SubItemList.defaultProps = {
  type: SUB_ITEM_LIST_TYPES.CATEGORY_OVERVIEW,
  mainCategoryId: null,
  subCategoryId: null,
  listData: [],
};

export default SubItemList;
