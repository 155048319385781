import './styles.scss';

import { Avatar } from 'antd';
import clsx from 'clsx';
import B2becTranslation from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import FormatPrice from 'components/FormatPrice';
import usePermission from 'hooks/usePermission';
import { MODULE_CART_CHECKOUT_RIGHTS } from 'libs/constants/modulerights';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectCheckoutSetting,
  selectShouldDisplayVoucherLevel,
} from 'store/selectors/cartSelector';
import { replaceCartItems, setCartItemQuantity } from 'store/slices/cartSlice';

import { ReactComponent as WarningIcon } from '../../../../assets/icons/info.svg';
import AddProductImage from '../../../../assets/img/addproduct.svg';
import DeliveryDate from '../../../../components/B2bDeliveryDate';
import CartItemName from '../../CartItemName';
import CartItemQuantityWrapper from '../../CartItemTable/CartItemQuantityWrapper';
import RemoveCartItem from '../../RemoveCartItem';
import CartItemInvalid from './CartItemInvalid';

const CartItemMobile = ({ cartItem, isLoading }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    imgUrl,
    materialNumber,
    replacedByMaterialNumber, // this is the material number for the replaced product
    quantity,
    reqQuantity,
    price,
    total,
    error,
    emptyRow,
    deliveryData,
    currencyUnit,
    isDangerous,
    hasMinQuantity,
    discount,
  } = cartItem;

  const { positionLvlConditions: shouldShowRecyclingFee } = useSelector(
    selectCheckoutSetting
  );
  const isVoucherApplicableProduct = useSelector((state) =>
    selectShouldDisplayVoucherLevel(state, materialNumber)
  );

  const hasReplacedItem = !!replacedByMaterialNumber;
  const unconfirmedMoqProduct = !!hasMinQuantity && reqQuantity !== quantity;
  const { hasPermission: hasViewIndividualPricePermision } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIUAL_PRICE
  );

  const isHideProductPrice = (isOldProduct) =>
    unconfirmedMoqProduct || (hasReplacedItem && isOldProduct);

  const handleCellClick = (isOldProduct = false) => {
    if (materialNumber && typeof materialNumber === 'string') {
      if (hasReplacedItem && !isOldProduct) {
        // for replaced product, its id is the `replacedByMaterialNumer`
        history.push(linkGenerator(`/product/${replacedByMaterialNumber}`));
      }
      history.push(linkGenerator(`/product/${materialNumber}`));
    }
  };

  const handleConfirmReplaceItems = useCallback(() => {
    dispatch(
      replaceCartItems({
        oldMaterialNumber: materialNumber,
        replacedMaterialNumber: replacedByMaterialNumber,
        quantity,
      })
    );
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dispatch, materialNumber, replacedByMaterialNumber, quantity]);

  const handleConfirmMoqItems = useCallback(() => {
    dispatch(setCartItemQuantity({ materialNumber, quantity }));
  }, [dispatch, materialNumber, quantity]);

  const isDisabledOnClick = (isOldProduct = false) => {
    return isLoading ? null : handleCellClick(isOldProduct);
  };

  const renderMaterialNumber = (isOldProduct = false) => {
    if (isLoading) {
      return <Skeleton />;
    }

    const materialId =
      hasReplacedItem && !isOldProduct // for replaced product, its id is the `replacedByMaterialNumer`
        ? replacedByMaterialNumber
        : materialNumber;

    return error ? (
      <>
        <WarningIcon style={{ verticalAlign: 'middle' }} /> ${materialId}
      </>
    ) : (
      materialId
    );
  };

  const renderName = () => {
    return isLoading ? (
      <Skeleton />
    ) : (
      <CartItemName
        data={cartItem}
        shouldShowRecyclingFee={shouldShowRecyclingFee}
        isMobile
      />
    );
  };

  const loadCartImage = (isOldProduct = false) => {
    return isLoading ? (
      <Skeleton height={200} />
    ) : imgUrl ? (
      <Avatar
        shape="square"
        src={imgUrl}
        onClick={() => isDisabledOnClick(isOldProduct)}
      />
    ) : (
      <Avatar
        size={116}
        shape="square"
        src={AddProductImage}
        className="cart__image"
      />
    );
  };

  const renderTopLeftBlock = (isOldProduct = false) => {
    return (
      <div className="cart-item-mobile__block cart-item-mobile__left">
        <div className="cart-item-mobile__left__top">
          {loadCartImage(isOldProduct)}
        </div>
      </div>
    );
  };

  const renderRemoveButton = () => {
    return isLoading ? null : (
      <RemoveCartItem
        materialNumber={materialNumber}
        className="cart-item-mobile__remove-button"
        haveText={false}
      />
    );
  };

  const renderListPriceBlock = () => {
    return (
      <div className="cart-item-mobile__price-block cart-item-mobile__list-price">
        <span className="cart-item-mobile__list-price__text">
          {t('cart.table.individualPrice')}
        </span>
        <span className="cart-item-mobile__list-price__value">
          {isLoading ? (
            <Skeleton />
          ) : (
            <FormatPrice price={price} currencyUnit={currencyUnit} />
          )}
        </span>
      </div>
    );
  };

  const renderTotalPriceBlock = () => {
    return (
      <div className="cart-item-mobile__price-block cart-item-mobile__total-price">
        <div>
          <span className="cart-item-mobile__total-price__text">
            {t('total')}
          </span>
          <span className="cart-item-mobile__total-price__value">
            {isLoading ? (
              <Skeleton />
            ) : (
              <FormatPrice price={total} currencyUnit={currencyUnit} />
            )}
          </span>
        </div>
      </div>
    );
  };

  const renderVoucherDiscount = () => {
    return isVoucherApplicableProduct ? (
      <div className="cart-item-mobile__right__bottom cart-item-mobile__price-section">
        <div>
          <span className="cart-item-mobile__total-price__text">
            {t('cart.table.includedVoucher')}
          </span>
          <span className="cart-item-mobile__total-price__value--voucher">
            {isLoading ? (
              <Skeleton />
            ) : (
              <FormatPrice price={discount} currencyUnit={currencyUnit} />
            )}
          </span>
        </div>
      </div>
    ) : null;
  };

  const renderQuantityWrapper = () => {
    return (
      <div className="cart-item-mobile__quantity-section">
        {isLoading ? (
          <Skeleton height={35} />
        ) : (
          <CartItemQuantityWrapper
            materialNumber={materialNumber}
            quantity={reqQuantity}
            isParentDisabled={!!error}
          />
        )}
      </div>
    );
  };

  const renderTopRightBlock = (isOldProduct = false) => {
    const classes = clsx('cart-item-mobile__block', 'cart-item-mobile__right');

    return (
      <div className={classes}>
        <button
          className={clsx(
            'cart-item-mobile__right__top',
            isLoading && 'cart-item-mobile__block--disabled-loading'
          )}
          type="button"
          onClick={() => isDisabledOnClick(isOldProduct)}
        >
          <span className="cart-item-mobile__title">{renderName()}</span>
          <span className="cart-item-mobile__item-number">
            {t('cart.table.itemNo')}: {renderMaterialNumber(isOldProduct)}
          </span>
          <span className="cart-item-mobile__status">
            {isLoading ? (
              <Skeleton />
            ) : (
              <DeliveryDate deliveryData={deliveryData} />
            )}
          </span>

          {/* prices sections */}
          {isHideProductPrice(isOldProduct) ? null : (
            <>
              {renderVoucherDiscount()}
              {hasViewIndividualPricePermision && (
                <div className="cart-item-mobile__right__bottom cart-item-mobile__price-section">
                  {renderTotalPriceBlock()}
                  {renderListPriceBlock()}
                </div>
              )}
            </>
          )}
        </button>

        {/* quantity and item removal sections */}
        {hasReplacedItem && isOldProduct ? null : (
          <div className="cart-item-mobile__right__bottom">
            {renderQuantityWrapper()}
            {renderRemoveButton()}
          </div>
        )}
      </div>
    );
  };

  const renderBottomBlock = (isOldProduct = false) => {
    if (hasReplacedItem && isOldProduct) {
      // not show the 'product not available' for the replaced item section
      return (
        <span className="cart-item-mobile--replacement__hint">
          {t('cart.productNotAvailable')}
        </span>
      );
    }

    const renderDangerousGoodWarning = () =>
      // show dangerous good hints for the normal items and replaced items as well
      isDangerous && (
        <div className="cart-item-mobile__bottom--dangerous-good--wrapper">
          <WarningIcon />
          <p>{t('checkout.step3.middleSection.table.dangerousGoodsHint')}</p>
        </div>
      );

    return (
      <div className="cart-item-mobile__bottom">
        <p>{t('cart.table.statusDetailInfo')}</p>
        {renderDangerousGoodWarning()}
      </div>
    );
  };

  const renderCartItemMobile = () => {
    if (hasReplacedItem) {
      const isOldProductPart = true;
      return (
        <div className="cart-item-mobile--replacement__wrapper">
          {/* old product section */}
          <div className="cart-item-mobile__wrapper">
            <div className="cart-item-mobile--replacement__annoucement">
              <B2becTranslation
                value="cart.replacedAnnoucement"
                isComponent
                variables={{
                  materialNumber,
                  replacedByMaterialNumber,
                }}
                // eslint-disable-next-line react/jsx-key
                htmlTags={[<span />]}
              />
            </div>
            {renderTopLeftBlock(isOldProductPart)}
            {renderTopRightBlock(isOldProductPart)}
            {renderBottomBlock(isOldProductPart)}
          </div>
          {/* replaced product section */}
          <div className="cart-item-mobile__wrapper">
            {renderTopLeftBlock()}
            {renderTopRightBlock()}
            {renderBottomBlock()}
          </div>
          <div className="cart-item-mobile--replacement__confirm-btn">
            <CustomButton onClick={handleConfirmReplaceItems}>
              {t('buttonTexts.confirm')}
            </CustomButton>
          </div>
        </div>
      );
    }

    if (hasMinQuantity) {
      return (
        <div className="cart-item-mobile--moq__wrapper">
          <div className="cart-item-mobile__wrapper">
            <div className="cart-item-mobile--moq__annoucement">
              <B2becTranslation
                value="cart.moqHintMessages"
                isComponent
                htmlTags={[<span key="moq-hint-message" />]}
              />
            </div>

            {renderTopLeftBlock()}
            {renderTopRightBlock()}
            {renderBottomBlock()}
          </div>

          <div className="cart-item-mobile--replacement__confirm-btn">
            <CustomButton onClick={handleConfirmMoqItems}>
              {t('buttonTexts.confirm')}
            </CustomButton>
          </div>
        </div>
      );
    }

    return (
      <div className="cart-item-mobile__wrapper">
        {renderTopLeftBlock()}
        {renderTopRightBlock()}
        {renderBottomBlock()}
      </div>
    );
  };

  return emptyRow ? (
    /* For case add new or invalid item */
    <CartItemInvalid cartItem={cartItem} isLoading={isLoading} />
  ) : (
    /* For normal case */
    <>{renderCartItemMobile()}</>
  );
};

CartItemMobile.propTypes = {
  cartItem: PropTypes.shape({
    imgUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    materialNumber: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
      PropTypes.number,
    ]),
    replacedByMaterialNumber: PropTypes.string,
    quantity: PropTypes.number,
    reqQuantity: PropTypes.number,
    price: PropTypes.number,
    total: PropTypes.number,
    error: PropTypes.string,
    emptyRow: PropTypes.bool,
    deliveryData: PropTypes.arrayOf(PropTypes.shape({})),
    currencyUnit: PropTypes.string,
    isDangerous: PropTypes.bool,
    hasMinQuantity: PropTypes.bool,
  }),
  isLoading: PropTypes.bool,
};
CartItemMobile.defaultProps = {
  cartItem: {
    error: '',
  },
  isLoading: false,
};

export default CartItemMobile;
