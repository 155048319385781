export const ACCEPTED_FILE_EXTENSIONS = {
  CSV: '.csv',
  IMAGES: {
    GIF: 'image/gif',
    PNG: 'image/png',
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
    JFIF: 'image/jfif',
    PJPEG: 'image/pjpeg',
    PJP: 'image/pjp',
  },
};
