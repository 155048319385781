export default (data) => {
  const {
    name = '',
    quantity = 0,
    price = 0,
    currencyUnit = '',
    vatPercentage = '',
    materialNumber = '',
    longDescription = '',
    unit = 'PCE',
    schemaType = 'ECLASS-7.1',
    schemaTypeCategoryId = 21051690,
    leadtime = 10,
  } = data;
  return {
    'NEW_ITEM-DESCRIPTION[index]': name,
    'NEW_ITEM-QUANTITY[index]': quantity,
    'NEW_ITEM-LEADTIME[index]': leadtime,
    'NEW_ITEM-UNIT[index]': unit,
    'NEW_ITEM-PRICE[index]': price,
    'NEW_ITEM-CURRENCY[index]': currencyUnit,
    'NEW_ITEM-CUST_FIELD1[index]': vatPercentage,
    'NEW_ITEM-VENDORMAT[index]': materialNumber,
    'NEW_ITEM-EXT_CATEGORY_ID[index]': schemaTypeCategoryId,
    'NEW_ITEM-LONGTEXT_index:132[]': longDescription,
    'NEW_ITEM-EXT_SCHEMA_TYPE[index]': schemaType,
  };
};
