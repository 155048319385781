import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isRightLoading } from 'store/selectors/rightSelector';
import { getRightsList } from 'store/slices/rightSlice';

const NotAvailableRights = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isRightLoading);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getRightsList());
  }, [dispatch]);

  return <div>{isLoading ? t('right.loading') : t('right.notAvailable')}</div>;
};

export default React.memo(NotAvailableRights);
