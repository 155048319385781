import B2becQuantity from 'components/B2becQuantity';
import QUANTITY_TYPE from 'components/B2becQuantity/constants';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setCartItemQuantity } from 'store/slices/cartSlice';

const CartItemQuantityWrapper = ({
  materialNumber,
  quantity,
  isParentDisabled,
  hasMinQuantity,
}) => {
  const dispatch = useDispatch();
  const timeoutId = useRef();

  const handleChangeQuantity = useCallback(
    (materialNo, newQuantity) => {
      clearTimeout(timeoutId.current);

      timeoutId.current = setTimeout(() => {
        const isValidQuantity = RegExp('^[0-9]+$').test(newQuantity);
        if (isValidQuantity && newQuantity > 0) {
          dispatch(
            setCartItemQuantity({
              materialNumber: materialNo,
              quantity: newQuantity,
            })
          );
        }
      }, 280);
    },
    [dispatch]
  );

  return (
    <div className="cart-item-quantity-wrapper">
      <B2becQuantity
        materialNumber={materialNumber}
        initialQuantity={quantity}
        onChange={handleChangeQuantity}
        type={QUANTITY_TYPE.SHOPPING_CART}
        isParentDisabled={isParentDisabled}
        hasMinQuantity={hasMinQuantity}
      />
    </div>
  );
};

CartItemQuantityWrapper.propTypes = {
  materialNumber: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  quantity: PropTypes.number,
  isParentDisabled: PropTypes.bool,
  hasMinQuantity: PropTypes.bool,
};

CartItemQuantityWrapper.defaultProps = {
  materialNumber: '',
  quantity: 1,
  isParentDisabled: false,
  hasMinQuantity: false,
};

export default CartItemQuantityWrapper;
