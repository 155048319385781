const contactReasonsFromPhrase = [
  {
    id: 'eaa5091435bda7dfc1fe72f8d904816c',
    content: 'Question regarding delivery',
    key: {
      id: '44315f351a640f43b31467f0959720d9',
      name: 'contactForm.contactReason.1',
    },
  },
  {
    id: 'ac30173a759e109365ebd846c1bb0df3',
    content: 'Reason Number 10',
    key: {
      id: 'e9c42b9eb03e3b914877302dcb10c140',
      name: 'contactForm.contactReason.10',
    },
  },
  {
    id: '11913dd0bf632aa222a6992be66e6071',
    content: 'Service, maintenance',
    key: {
      id: '094edf799819847588391d26e6092949',
      name: 'contactForm.contactReason.2',
    },
  },
  {
    id: '04ac8fd604bcfa8faa295c2ac45c7bdd',
    content: 'Repair',
    key: {
      id: 'c75a2762ffbe4872d59fe4c5344cc476',
      name: 'contactForm.contactReason.3',
    },
  },
  {
    id: 'f5261e7fc60861843a5b8387f2e28823',
    content: 'Question about myKärcher business',
    key: {
      id: 'd990563806da16819d236f1e74691f9e',
      name: 'contactForm.contactReason.4',
    },
  },
  {
    id: 'd320d5ac6f91c6b8f0f807333f3af8f8',
    content: 'Product request',
    key: {
      id: '0e124d2f5fab6e17e980772e814f88c6',
      name: 'contactForm.contactReason.5',
    },
  },
  {
    id: '8f1409cc3da3e774f4c0333e57670d2b',
    content: 'Product advice',
    key: {
      id: 'd28d9f713da11ce19907344ea7f095a3',
      name: 'contactForm.contactReason.6',
    },
  },
  {
    id: 'c75aa3f3765075b79018fb9eab3d25ec',
    content: 'Complaint/Return',
    key: {
      id: '89e18bcf731c3ddf049d1b947442c904',
      name: 'contactForm.contactReason.7',
    },
  },
  {
    id: '0a7c23a0f5515c4631cfe02f7519dee0',
    content: 'Reason Number 8',
    key: {
      id: '26131230f88f08add4d91a3e28d71c30',
      name: 'contactForm.contactReason.8',
    },
  },
  {
    id: '2abfede368307ca3482942dbbebf4d5b',
    content: 'Reason Number 9',
    key: {
      id: 'a226701aefd06ee0cf4a2b0527a1aa6c',
      name: 'contactForm.contactReason.9',
    },
  },
];
export default contactReasonsFromPhrase;
