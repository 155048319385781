import './styles.scss';

import { HeartIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  sendProductDetail,
  showAddProductToWishlistModal,
} from 'store/slices/wishlistSlice';

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_icon_borderless.svg';
import PermissionWrapper from '../../../../HOCs/permissionWrapper';
import { MODULE_WISHLIST_RIGHTS } from '../../../../libs/constants/modulerights';
import ComparisonBlock from '../ComparisonBlock';
import ProductComparisonCard from '../ProductComparisonCard';

const ProductComparisonTable = ({
  memoListProduct,
  handleRemoveProduct,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listProduct = useMemo(
    () => JSON.parse(memoListProduct),
    [memoListProduct]
  );

  const listProductIds = Object.keys(listProduct);

  return (
    <table
      className={`product-comparison-table ${`number-items-${listProductIds.length}`}`}
    >
      <thead>
        <tr>
          <th>
            <div style={{ width: 400 }} />
          </th>
          {listProductIds.map((productId) => {
            const productData = listProduct[productId];

            if (!productData) {
              return (
                <th key={`header-${productId}`}>
                  <div style={{ width: 300 }} />
                </th>
              );
            }

            return (
              <th key={`header-${productId}`}>
                <div style={{ width: 300 }}>
                  <div className="product-comparison-card__action-buttons">
                    <PermissionWrapper
                      permission={
                        MODULE_WISHLIST_RIGHTS.ADD_PRODUCT_CHANGE_WISHLIST
                      }
                    >
                      <button
                        type="button"
                        className="button-as-link"
                        onClick={() => {
                          dispatch(
                            sendProductDetail({
                              materialName: productData.materialName,
                              materialNumber: productData.materialNumber,
                              img: productData.image,
                            })
                          );
                          dispatch(showAddProductToWishlistModal());
                        }}
                      >
                        <HeartIcon className="add-to-wishlist__button" />
                      </button>
                    </PermissionWrapper>
                    <button
                      className="button-as-link"
                      type="button"
                      onClick={() => handleRemoveProduct(productId)}
                    >
                      <DeleteIcon width="24" height="24" />
                    </button>
                  </div>
                  <ProductComparisonCard {...productData} />
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <ComparisonBlock
          type="technical"
          listProduct={listProduct}
          {...props}
        />
      </tbody>
      <tbody>
        <ComparisonBlock
          type="equipment"
          listProduct={listProduct}
          {...props}
        />
      </tbody>
      <tbody>
        <tr>
          <td colSpan="4" className="product-comparison-table__header">
            <h4 className="font-weight-bold">
              {t('productComparison.description')}
            </h4>
          </td>
        </tr>
        <tr>
          <td />
          {listProductIds.map((productId) => {
            const productData = listProduct[productId];

            return (
              <td key={`${productId}-description`}>
                <div className="product-comparison-table__product-description">
                  {productData?.longDescription?.split('<br/>').join('')}
                </div>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

ProductComparisonTable.propTypes = {
  memoListProduct: PropTypes.string.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
};

export default ProductComparisonTable;
