import './styles.scss';

import { Col, Divider, Form, Radio, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import clsx from 'clsx';
import B2becSpinner from 'components/B2becSpinner';
import Translator from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import { B2becButtonBack } from 'components/CustomButton/B2becButtonIcon';
import useAsync from 'hooks/useAsync';
import usePermission from 'hooks/usePermission';
import { ASYNC_STATUS, SERVICE_ENTRY, SERVICE_TYPE } from 'libs/constants';
import { MODULE_SERVICE_RIGHTS } from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedMachineData } from 'store/selectors/serviceCaseSelector';

import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import FailureDescriptionSection from '../../components/FailureDescriptionSection';
import UploadDamagedPicture from '../../components/UploadDamagedPicture';
import { apiGetProductServiceFAQs } from './SelectServiceHelper';

const ServiceSelectStep = ({ currentTab, handleChangeTab, form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceDetect();
  const [isShowFaq, setShowFaq] = useState(true);
  const selectedMachineData = useSelector(getSelectedMachineData);

  const {
    verifiedPermissions: [canCreateRepairService, canCreateMaintenanceService],
  } = usePermission([
    MODULE_SERVICE_RIGHTS.CREATE_REPAIR_SERVICE_CASE,
    MODULE_SERVICE_RIGHTS.CREATE_MAINTENANCE_SERVICE_CASE,
  ]);

  const {
    serviceEntry,
    id: machineId,
    name: machineName,
  } = selectedMachineData;

  const {
    execute,
    value: faqList,
    status,
  } = useAsync(apiGetProductServiceFAQs, false);

  useEffect(() => {
    if (machineId && machineName) {
      execute(machineId);
    }
  }, [dispatch, execute, machineId, machineName]);

  const onChangeServiceTypeHandler = useCallback(
    (e) => {
      setShowFaq(e?.target?.value !== SERVICE_TYPE.MAINTENANCE);
    },
    [setShowFaq]
  );

  const serviceTypes = useMemo(() => {
    const availableServiceTypes = [];
    if (canCreateRepairService) {
      availableServiceTypes.push({
        label: t('service.serviceType.repair'),
        value: SERVICE_TYPE.REPAIR,
      });
    }
    if (canCreateMaintenanceService) {
      availableServiceTypes.push({
        label: t('service.serviceType.maintenance'),
        value: SERVICE_TYPE.MAINTENANCE,
      });
    }
    return availableServiceTypes;
  }, [t, canCreateMaintenanceService, canCreateRepairService]);

  const renderDescriptionSection = () =>
    serviceEntry === SERVICE_ENTRY.MACHINE && (
      <div className="service-step__service__description__wrapper">
        <div className="service-step__service__description__title">
          {t('service.selectService.descriptionTitle')}
        </div>
        <div className="service-step__service__description__text">
          {t('service.selectService.descriptionText')}
        </div>
      </div>
    );

  const renderServiceSelectionArea = useCallback(
    () =>
      serviceEntry === SERVICE_ENTRY.MACHINE && (
        <div className="service-request__layout--shrink">
          <div className="service-step__service__select-desire-service-text">
            {t('service.selectService.selectDesiredService')}
          </div>
          <Form.Item
            className="custom-form-item"
            name="serviceType"
            initialValue={serviceTypes[0].value}
          >
            <Radio.Group onChange={onChangeServiceTypeHandler}>
              <Space direction={isMobile ? 'vertical' : 'horizontal'}>
                {serviceTypes.map((serviceType) => (
                  <Radio value={serviceType?.value} key={serviceType?.value}>
                    <span className="font-weight-medium">
                      {serviceType?.label}
                    </span>
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      ),
    [serviceEntry, t, serviceTypes, onChangeServiceTypeHandler, isMobile]
  );

  const isRequiredFurtherNotes = useMemo(() => {
    return !(Array.isArray(faqList) && faqList.length !== 0);
  }, [faqList]);

  const renderFailureDescription = useCallback(() => {
    return (
      isShowFaq && (
        <>
          {status === ASYNC_STATUS.PENDING ? (
            <B2becSpinner isLoading={status === ASYNC_STATUS.PENDING} />
          ) : (
            <div className="service-step__service__FAQ__wrapper">
              {faqList && (
                <FailureDescriptionSection form={form} FAQList={faqList} />
              )}
            </div>
          )}

          {/* further notes section */}
          <Row>
            <Col span={24} md={10} lg={10}>
              <div className="service-step__service__FAQ__wrapper service-request__layout--shrink">
                <Form.Item
                  label={
                    <div className="FAQ__failures__notes-title">
                      <Translator
                        value="service.selectService.addFurtherNotes"
                        isComponent
                        htmlTags={[
                          // eslint-disable-next-line react/jsx-key
                          <span
                            className={clsx(
                              isRequiredFurtherNotes
                                ? 'FAQ__failures__notes-title--required'
                                : 'FAQ__failures__notes-title--optional'
                            )}
                          />,
                        ]}
                      />
                    </div>
                  }
                  form={form}
                  name="failureDescriptionNotes"
                  className="custom-form-item"
                  required={isRequiredFurtherNotes}
                  rules={[
                    {
                      required: isRequiredFurtherNotes,
                      message: t('form.validate.furtherNotesRequired'),
                    },
                  ]}
                >
                  <TextArea
                    placeholder={t(
                      'service.selectService.addFurtherNotesPlaceholder'
                    )}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </>
      )
    );
  }, [isShowFaq, status, form, faqList, isRequiredFurtherNotes, t]);

  return (
    <div className="service-step__service">
      {renderDescriptionSection()}

      {/* Select service type section */}
      {renderServiceSelectionArea()}

      {/* Select failure descrition section */}
      {renderFailureDescription()}

      {/* Add damaged pictures section */}
      <div className="service-step__service__upload-damaged-picture">
        <UploadDamagedPicture form={form} />
      </div>

      <div className="service-request__layout--shrink">
        <Divider />
      </div>

      {/* Cost estimation section */}

      {/* buttons wrapper */}
      <Row gutter={[24, 24]}>
        {serviceEntry === SERVICE_ENTRY.REPAIR && (
          <Col
            span={24}
            xs={{ order: 2 }}
            sm={{ order: 2 }}
            md={{ span: 12, order: 1 }}
            lg={8}
          >
            <div className="service-request__layout--shrink">
              <B2becButtonBack
                type="ghost"
                block
                onClick={() => {
                  handleChangeTab(+currentTab - 1);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                {t('service.addressAndDate.button.backToSelectMachine')}
              </B2becButtonBack>
            </div>
          </Col>
        )}
        <Col
          span={24}
          xs={{ order: 1 }}
          sm={{ order: 1 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 8, push: 8 }}
        >
          <div className="service-request__layout--shrink">
            <CustomButton
              block
              onClick={() => {
                handleChangeTab(+currentTab + 1);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {t('service.button.next')}
            </CustomButton>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ServiceSelectStep.propTypes = {
  form: PropTypes.shape({}).isRequired,
  currentTab: PropTypes.number.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
};

export default ServiceSelectStep;
