import B2becTranslation from 'components/B2becTranslation';
import {
  notificationComponent,
  NotificationManager,
} from 'components/Notification';
import { SERVICE_ENTRY, SERVICE_TYPE } from 'libs/constants';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { isValidArray } from 'libs/utils/array';
import { isObjectChecker } from 'libs/utils/object';
import React from 'react';

const onCheckObjectHasEmptyValue = (obj) => {
  return Object.values(obj).some((value) => {
    if (isObjectChecker(value)) {
      return onCheckObjectHasEmptyValue(value);
    }
    return !value;
  });
};

const onFilterObjectHasEmptyValue = (obj) => {
  return Object.entries(obj).filter(([key, val]) => {
    if (isObjectChecker(val)) {
      return onFilterObjectHasEmptyValue(val);
    }
    if (!val) {
      return { [key]: val };
    }
    return false;
  });
};

export const onValidateFields = (
  form,
  tabIndex,
  serviceEntry,
  setCurrentTab
) => {
  const contactPersonFields = [
    'contactPersonFirstName',
    'contactPersonLastName',
    'contactEmail',
    'contactNumber',
  ];
  const deliveryAddressFields = [
    ['deliveryAddress', 'name'],
    ['deliveryAddress', 'street'],
    ['deliveryAddress', 'zip'],
    ['deliveryAddress', 'city'],
    ['deliveryAddress', 'country'],
  ];
  const failureDescriptionFields = [
    'failureDescriptions',
    'failureDescriptionNotes',
  ];

  const validateFields = (fields) => {
    form
      .validateFields(fields)
      .then((response) => {
        const isDataNotFilled = onCheckObjectHasEmptyValue(response);

        if (isDataNotFilled) {
          notificationComponent({
            type: NOTIFICATION_TYPE.ERROR,
            message: onFilterObjectHasEmptyValue(response).map(([key, val]) => {
              return (
                <span style={{ display: 'block' }} key={key}>
                  <B2becTranslation
                    value="form.validate.required"
                    variables={{ label: key }}
                  />
                </span>
              );
            }),
          });
        } else {
          setCurrentTab(+tabIndex);
        }
      })
      .catch((errorInfo) => {
        if (isValidArray(errorInfo?.errorFields)) {
          notificationComponent({
            type: NOTIFICATION_TYPE.ERROR,
            message: errorInfo?.errorFields.map((err) => {
              return (
                <span style={{ display: 'block' }} key={err?.name.join('-')}>
                  {err?.errors}
                </span>
              );
            }),
          });
        }
      });
  };

  const validateContactInformationAndDeliveryAddressHandler = (
    nextTabIndex
  ) => {
    if (+tabIndex >= nextTabIndex) {
      const deliveryAddress = form.getFieldValue('deliveryAddress');
      if (deliveryAddress?.isAddNew) {
        validateFields([...contactPersonFields, ...deliveryAddressFields]);
      } else {
        validateFields(contactPersonFields);
      }
    } else {
      setCurrentTab(+tabIndex);
    }
  };

  const validateFailureDescriptionHandler = (nextTabIndex) => {
    if (+tabIndex >= nextTabIndex) {
      form
        .validateFields(failureDescriptionFields)
        .then((response) => {
          const FAQs = response?.failureDescriptions;
          const FaqNotes = response?.failureDescriptionNotes;
          if ((FAQs && FAQs.length > 0) || !!FaqNotes) {
            // Validate Address and Date Selection Step
            validateContactInformationAndDeliveryAddressHandler(
              nextTabIndex + 1
            );
          } else {
            NotificationManager.error({
              message: 'form.validate.failureDescriptionRequired',
            });
          }
        })
        .catch((_) => {
          NotificationManager.error({
            message: 'form.validate.failureDescriptionRequired',
          });
        });
    } else {
      setCurrentTab(+tabIndex);
    }
  };

  switch (serviceEntry) {
    /*
      REPAIR (4 tabs)
        failure: 2
        contact: 3
      MACHINE (3 tabs)
        failure: 1
        contact: 2
      MAINTENANCE (3 tabs)
        contact: 2
    */
    case SERVICE_ENTRY.REPAIR: {
      // Validate Service Selection Step => then Address and Date Selection Step
      validateFailureDescriptionHandler(3);
      break;
    }
    case SERVICE_ENTRY.MACHINE: {
      const selectedService = form.getFieldValue('serviceType');

      if (selectedService === SERVICE_TYPE.MAINTENANCE) {
        // ONLY validate Address and Date Selection Step (no service selection required)
        validateContactInformationAndDeliveryAddressHandler(3);
      } else {
        // Validate Service Selection Step => then Address and Date Selection Step
        validateFailureDescriptionHandler(2);
      }
      break;
    }
    case SERVICE_ENTRY.MAINTENANCE: {
      // ONLY validate Address and Date Selection Step (no service selection required)
      validateContactInformationAndDeliveryAddressHandler(3);
      break;
    }
    default:
      break;
  }
};
