import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const adminFAPIClient = new HttpClient(environment.adminFAPIUrl);

const ROLE_ENDPOINT = '/v1.0/role';

export const fapiRoleService = {
  getRolesList() {
    return adminFAPIClient.get(`${ROLE_ENDPOINT}/list`);
  },
  getAssignedRolesByUserId(userId) {
    return adminFAPIClient.get(`${ROLE_ENDPOINT}/user-roles`, {
      queryParams: { userId },
    });
  },
  getRoleDetails(roleId) {
    return adminFAPIClient.get(`${ROLE_ENDPOINT}/${roleId}`);
  },
  createRole(data) {
    return adminFAPIClient.post(ROLE_ENDPOINT, {
      body: data,
    });
  },
  updateRole(data) {
    return adminFAPIClient.put(ROLE_ENDPOINT, {
      body: data,
    });
  },
  deleteRole(roleId) {
    return adminFAPIClient.delete(`${ROLE_ENDPOINT}/${roleId}`);
  },
  getParentRolesList() {
    return adminFAPIClient.get(`${ROLE_ENDPOINT}/parents`);
  },
  getAssignableRolesByUserId(userId) {
    return adminFAPIClient.get(`${ROLE_ENDPOINT}/assignable/country-role`, {
      queryParams: { userId },
    });
  },
  assignRolesToUser({ userId, roles }) {
    return adminFAPIClient.post(`${ROLE_ENDPOINT}/assign-many`, {
      body: { userId, roles },
    });
  },
  getUserRoles(userId) {
    return adminFAPIClient.get(`${ROLE_ENDPOINT}/context/user-roles`, {
      queryParams: { userId },
    });
  },
  getCurrentUserRoles() {
    return adminFAPIClient.get(`${ROLE_ENDPOINT}/context/user-roles`);
  },
};
