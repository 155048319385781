import moment from 'moment';

import { convertDateToMoment } from './formatDate';

export function isCurrentTimeInSAPDowntime(startTime, endTime) {
  const currentTime = moment();
  const convertedStartTime = convertDateToMoment(startTime);
  const convertedEndTime = convertDateToMoment(endTime);

  return (
    currentTime.isAfter(convertedStartTime) &&
    currentTime.isBefore(convertedEndTime)
  );
}
