import { AutoComplete, Input } from 'antd';
import clsx from 'clsx';
import { SearchIcon } from 'components/Icons';
import { useDebounce } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetUsersListByEmail } from '../hooks/useGetUsersListByEmail';
import styles from './SearchAutocomplete.module.scss';

const { Search } = Input;

export function SearchAutocomplete({ countryId, onChange }) {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const searchQuery = useDebounce(searchText, 300);
  const { emailsList, isFetchingUsersList, executeGetUsersListByEmail } =
    useGetUsersListByEmail(countryId);

  useEffect(() => {
    if (searchQuery) {
      executeGetUsersListByEmail(searchQuery);
    }
  }, [executeGetUsersListByEmail, searchQuery]);

  return (
    <AutoComplete
      className={styles.autocomplete}
      defaultActiveFirstOption={false}
      options={emailsList}
      getPopupContainer={(trigger) => trigger}
      onSelect={onChange}
      onSearch={setSearchText}
    >
      <Search
        bordered
        className={clsx('custom-search-input', styles.search)}
        size="large"
        placeholder={t('searchFor')}
        prefix={<SearchIcon className={styles.icon} />}
        loading={isFetchingUsersList}
        enterButton={t('buttonTexts.search')}
      />
    </AutoComplete>
  );
}
