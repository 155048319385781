import { ORDER_STATUS_DATA } from 'libs/constants/orderStatus';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FormatStatus.module.scss';

export default function FormatStatus(props) {
  const { t } = useTranslation();
  const { status, className, style } = props;
  const lowerCasedStatus = status?.toLowerCase();

  return (
    <span
      className={`${styles.icon} status-icon--${ORDER_STATUS_DATA[lowerCasedStatus]?.className} ${className}`}
      style={style}
    >
      {lowerCasedStatus ? t(ORDER_STATUS_DATA[lowerCasedStatus]?.title) : ''}
    </span>
  );
}

FormatStatus.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

FormatStatus.defaultProps = {
  status: '',
  className: '',
  style: {},
};
