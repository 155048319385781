import B2becSpinner from 'components/B2becSpinner';
import React from 'react';

import styles from './B2bLoadingFullPage.module.scss';

const B2bLoadingFullPage = () => {
  return (
    <div className={styles.container}>
      <B2becSpinner isLoading />
    </div>
  );
};

export default B2bLoadingFullPage;
