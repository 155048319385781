import { createSelector, createSlice } from '@reduxjs/toolkit';

const usersManagementSlice = createSlice({
  name: 'usersManagement',
  initialState: {
    isFetching: false,
    data: null,
    totalCount: 0,
    suggestionList: [],
    isAddingUser: false,
    isSuggestionsFetching: false,
    error: null,
    availableLanguages: [],
  },
  reducers: {
    getUserOverviewList(state) {
      state.isFetching = true;
      state.data = [];
    },
    getUserOverviewListSuccess(state, { payload }) {
      const { items, totalCount } = payload;

      state.isFetching = false;
      state.data = items;
      state.totalCount = totalCount;
    },
    getUserOverviewListFailed(state) {
      state.isFetching = false;
    },
    getSuggestionList(state) {
      state.isSuggestionsFetching = true;
    },
    getSuggestionListSuccess(state, { payload }) {
      state.isSuggestionsFetching = false;
      state.suggestionList = payload;
    },
    getSuggestionListFailed(state) {
      state.isSuggestionsFetching = false;
    },
    clearEmployeeSuggestionList(state) {
      state.suggestionList = [];
    },
    getEmployeeAvailableLanguages(state) {
      state.availableLanguages = [];
      state.isLoading = true;
    },
    getEmployeeAvailableLanguagesSuccess(state, { payload }) {
      state.availableLanguages = payload;
      state.isLoading = false;
    },
    getEmployeeAvailableLanguagesFailed(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
    addEmployeeUser(state) {
      state.isAddingUser = true;
      state.error = null;
    },
    addEmployeeUserSuccess(state) {
      state.isAddingUser = false;
      state.error = null;
    },
    addEmployeeUserFailed(state, { payload }) {
      state.isAddingUser = false;
      state.error = payload;
    },
  },
});

export const {
  getUserOverviewList,
  getUserOverviewListSuccess,
  getUserOverviewListFailed,
  getSuggestionList,
  getSuggestionListSuccess,
  getSuggestionListFailed,
  clearEmployeeSuggestionList,
  getEmployeeAvailableLanguages,
  getEmployeeAvailableLanguagesSuccess,
  getEmployeeAvailableLanguagesFailed,
  addEmployeeUser,
  addEmployeeUserSuccess,
  addEmployeeUserFailed,
} = usersManagementSlice.actions;

export const selectIsUserListFetching = (state) =>
  state.usersManagement.isFetching;

export const selectUserList = (state) => state.usersManagement.data;

export const selectTotalUsers = (state) => state.usersManagement.totalCount;

export const selectSuggestionFetching = (state) =>
  state?.usersManagement?.isSuggestionsFetching;

export const selectEmployeeSuggestionList = (state) =>
  state?.usersManagement?.suggestionList;

export const selectEmployeeAvailableLanguages = (state) =>
  state?.usersManagement?.availableLanguages;

export const selectRequestErrorMessage = (state) =>
  state?.usersManagement?.error;

export const selectLanguageOptions = createSelector(
  selectEmployeeAvailableLanguages,
  (availableLanguages) =>
    availableLanguages.map((language) => ({
      label: language?.name,
      value: language?.value,
    }))
);

export const selectIsAddingUser = (state) =>
  state?.usersManagement?.isAddingUser;

export default usersManagementSlice.reducer;
