import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import B2becInfoLayout from '../../../components/B2becInfoLayout';
import { B2becButtonBack } from '../../../components/CustomButton/B2becButtonIcon';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { PAGE_TYPES } from '../../../libs/constants/adobeAnalytics';
import { TECHNICAL_USER } from '../../../libs/constants/user';
import { linkGenerator } from '../../../libs/utils/language';
import AddTechnicalUserForm from './components/AddUserForm';

const AddTechnicalUserPage = (props) => {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageId,
    pageType: PAGE_TYPES.USER_MANAGEMENT,
    pageName: 'invite new technical user',
  });

  const history = useHistory();

  const { t } = useTranslation();

  const onBackHandler = () => {
    history.push(linkGenerator(`/user-management/${TECHNICAL_USER}`));
  };

  return (
    <B2becInfoLayout>
      <B2becInfoLayout.Title title={t('pageTitle.addTechnicalUser')}>
        <B2becButtonBack buttonSize="small" onClick={onBackHandler}>
          {t('buttonTexts.backToOverview')}
        </B2becButtonBack>
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        <AddTechnicalUserForm />
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
};

AddTechnicalUserPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default AddTechnicalUserPage;
