import './styles.scss';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

function B2becInfoLayout(props) {
  const { className, children } = props;

  return (
    <div className={clsx('b2bec-info-layout__wrapper', className)}>
      {children}
    </div>
  );
}

B2becInfoLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

B2becInfoLayout.defaultProps = {
  className: undefined,
  children: undefined,
};

const Title = ({ title, titleExtra, children }) => {
  return (
    <section
      className={clsx(
        'b2bec-info-layout__heading__wrapper',
        'container-full-width-page'
      )}
    >
      <div className="b2bec-info-layout__heading">
        <div className="b2bec-info-layout__heading__title__wrapper">
          {title ? (
            <h1 className="b2bec-info-layout__heading__title">{title}</h1>
          ) : undefined}
          {titleExtra}
        </div>
        <div className="b2bec-info-layout__heading__extra">{children}</div>
      </div>
    </section>
  );
};

Title.propTypes = {
  title: PropTypes.node,
  titleExtra: PropTypes.node,
  children: PropTypes.node,
};

Title.defaultProps = {
  title: undefined,
  titleExtra: undefined,
  children: undefined,
};

const Content = ({ children, className }) => {
  return (
    <section className={clsx('b2bec-info-layout__content__wrapper', className)}>
      {children}
    </section>
  );
};

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Content.defaultProps = {
  children: undefined,
  className: null,
};

B2becInfoLayout.Title = Title;
B2becInfoLayout.Content = Content;

export default B2becInfoLayout;
