import './styles.scss';

import { Space } from 'antd';
import DeliveryDate from 'components/B2bDeliveryDate';
import FormatPrice from 'components/FormatPrice';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CartItemName = ({ data, isMobile, shouldShowRecyclingFee }) => {
  const { t } = useTranslation();

  // old product has the 'replacedByMaterialNumber' property
  const isOldProduct = !!data?.replacedByMaterialNumber;
  // replacement hint only display for the desktop version
  const shouldDisplayReplacement = !isMobile && isOldProduct;

  return (
    <Space direction="vertical" className="cart-item-name">
      <strong className="cart-item-name__title mb-3">
        {data.name}
        {data?.error && (
          <span className="fontBold cart-item-name__error-message">
            {t('errors.nonExistentNumber')}
          </span>
        )}
      </strong>
      {shouldDisplayReplacement && <span>{t('cart.productNotAvailable')}</span>}
      {!!data?.error || data.emptyRow || isMobile ? null : (
        <DeliveryDate deliveryData={data.deliveryData} />
      )}

      {/* data.emptyRow is used to recognize add_quick_entry row which doesn't
        need to render recycling fee price. */}
      {shouldShowRecyclingFee && !data?.emptyRow ? (
        <span className="cart-item-name__recycling-fee">
          {t('cart.table.additionalCostsPositionLevel')}
          <FormatPrice
            price={data?.recyclingFee}
            currencyUnit={data?.currencyUnit}
          />
        </span>
      ) : null}
    </Space>
  );
};

CartItemName.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    emptyRow: PropTypes.bool,
    quantity: PropTypes.number,
    materialNumber: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    error: PropTypes.string,
    deliveryData: PropTypes.arrayOf(
      PropTypes.shape({
        numberOfItems: PropTypes.number,
        deliveryDate: PropTypes.string,
      })
    ),
    checkoutSettings: PropTypes.shape({
      includingVAT: PropTypes.bool,
    }),
    recyclingFee: PropTypes.number,
    currencyUnit: PropTypes.string,
    replacedByMaterialNumber: PropTypes.string,
    hasMinQuantity: PropTypes.bool,
  }).isRequired,
  isMobile: PropTypes.bool,
  shouldShowRecyclingFee: PropTypes.bool,
};

CartItemName.defaultProps = {
  isMobile: false,
  shouldShowRecyclingFee: false,
};

export default CartItemName;
