import PropTypes from 'prop-types';
import React from 'react';

import styles from '../EditWishlistModal.module.scss';

const SharingWishlistItem = (props) => {
  const { value = {} } = props;
  const { email } = value;

  return <span className={styles.emailSpan}>{email}</span>;
};
export default SharingWishlistItem;

SharingWishlistItem.propTypes = {
  value: PropTypes.shape({
    email: PropTypes.string,
  }),
};
SharingWishlistItem.defaultProps = {
  value: {
    email: '',
  },
};
