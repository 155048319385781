import environment from 'environments/environment.dev';
import { PAGE_SIZE } from 'libs/constants';

import HttpClient from '../apiHttpClient';

const CONFIGURATION_ENDPOINT = '/v1.0/configuration';

const adminFapiClient = new HttpClient(environment.adminFAPIUrl);

const getAllConfigs = (scope, pageNumber = 1, pageSize = PAGE_SIZE[10]) => {
  const { level, countryId, customerNumber } = scope;
  const params = new URLSearchParams({ level, pageNumber, pageSize });

  if (countryId) {
    params.append('countryId', countryId);
  }
  if (customerNumber) {
    params.append('customerNumber', customerNumber);
  }
  return adminFapiClient.get(
    `${CONFIGURATION_ENDPOINT}/list?${params.toString()}`
  );
};

const getAllGroups = () => {
  return adminFapiClient.get(`${CONFIGURATION_ENDPOINT}/groups`);
};

const getConfigsByGroup = (groupName, scope, pageNumber, pageSize) => {
  const { level, countryId, customerNumber } = scope;
  const params = new URLSearchParams({ level, pageNumber, pageSize });

  if (countryId) {
    params.append('countryId', countryId);
  }
  if (customerNumber) {
    params.append('customerNumber', customerNumber);
  }
  return adminFapiClient.get(
    `${CONFIGURATION_ENDPOINT}/list/${groupName}?${params.toString()}`
  );
};

const updateConfig = ({ level, countryId, customerNumber, name, value }) => {
  const params = { level, name, value: value.toString() };

  if (countryId) {
    params.countryId = countryId;
  }
  if (customerNumber) {
    params.qualifier = customerNumber;
  }

  return adminFapiClient.put(CONFIGURATION_ENDPOINT, {
    body: { ...params },
  });
};

const resetConfig = ({ level, countryId, qualifier, name }) => {
  const params = { level, name };

  if (countryId) {
    params.countryId = countryId;
  }
  if (qualifier) {
    params.qualifier = qualifier;
  }

  return adminFapiClient.put(`${CONFIGURATION_ENDPOINT}/reset`, {
    body: params,
  });
};

const getCustomersByCountry = (countryId) => {
  return adminFapiClient.get(`${CONFIGURATION_ENDPOINT}/customer/${countryId}`);
};

const getAllAvailableCountries = () => {
  return adminFapiClient.get(`${CONFIGURATION_ENDPOINT}/country`);
};

export const adminFapiConfiguration = {
  getAllConfigs,
  getAllGroups,
  getConfigsByGroup,
  updateConfig,
  resetConfig,
  getCustomersByCountry,
  getAllAvailableCountries,
};
