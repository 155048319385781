import B2becCustomSelect from 'components/B2becCustomSelect';
import { mergeIntoStringWithSeparator } from 'libs/utils/array';
import React, { useCallback, useMemo } from 'react';

const AddressSelect = (props) => {
  const { deliveryAddresses, placeholder, onChange } = props;

  const mergeAddress = (address) => {
    const {
      partnerNumber = '',
      name = '',
      name2 = '',
      name3 = '',
      street = '',
      houseNumber = '',
      street4 = '',
      zip = '',
      city = '',
      country = '',
    } = address;

    return mergeIntoStringWithSeparator()(
      partnerNumber,
      name,
      name2,
      name3,
      street,
      houseNumber,
      street4,
      zip,
      city,
      country
    );
  };

  const addressArray = useMemo(() => {
    const initialArray = [
      {
        key: 0,
        value: 0,
        label: placeholder,
      },
    ];

    return (deliveryAddresses || []).reduce((arr, address, index) => {
      arr.push({
        key: index + 1,
        value: index + 1,
        label: mergeAddress(address),
      });
      return arr;
    }, initialArray);
  }, [deliveryAddresses, placeholder]);

  const handleChange = useCallback(
    (selectedId) => {
      const selected = deliveryAddresses[selectedId - 1];
      onChange(selected, selectedId);
    },
    [deliveryAddresses, onChange]
  );

  return (
    <B2becCustomSelect
      onClick={(e) => {
        e.preventDefault();
      }}
      style={{ width: '100%' }}
      options={addressArray}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};

export default AddressSelect;
