import './styles.scss';

import clsx from 'clsx';
import { CONFIG_GROUP_TYPE_ALL } from 'libs/constants/configs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

function GroupFilter(props) {
  const { isLoading, groups, onSelectGroup } = props;
  const { t } = useTranslation();

  const [selectedGroup, setSelectedGroup] = useState(
    CONFIG_GROUP_TYPE_ALL.name
  );

  const onGroupSelected = (name) => {
    setSelectedGroup(name);
    onSelectGroup(name);
  };

  const renderGroupTitle = (group) => {
    if (group.name === CONFIG_GROUP_TYPE_ALL.name) {
      return t(`configurationManagement.filter.${CONFIG_GROUP_TYPE_ALL.name}`);
    }
    if (isLoading) {
      return <Skeleton width={50} height={13} />;
    }
    return group.name;
  };

  return (
    <div className="configuration-management__group-filter__wrapper">
      <div className="configuration-management__group-filter">
        {groups.map((group) => (
          <button
            className={clsx('configuration-management__group-filter__button', {
              'configuration-management__group-filter__button--selected':
                group.name === selectedGroup,
            })}
            key={group.key}
            type="button"
            onClick={() => onGroupSelected(group.name)}
          >
            {renderGroupTitle(group)}
          </button>
        ))}
      </div>
    </div>
  );
}

GroupFilter.propTypes = {
  isLoading: PropTypes.bool,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelectGroup: PropTypes.func,
};

GroupFilter.defaultProps = {
  isLoading: false,
  onSelectGroup: () => {},
};

export default GroupFilter;
