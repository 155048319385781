import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetchingSetupData: false,
  shouldShowMissingDataUserModal: false,
};

const setupSlice = createSlice({
  name: 'setup',
  initialState,
  reducers: {
    fetchSetupData: (state) => {
      state.isFetchingSetupData = true;
    },
    fetchSetupDataSuccess: (state) => {
      state.isFetchingSetupData = false;
    },
    fetchSetupDataFailure: (state) => {
      state.isFetchingSetupData = false;
    },
    showMissingDataUserModal: (state) => {
      state.shouldShowMissingDataUserModal = true;
    },
    hideMissingDataUserModal: (state) => {
      state.shouldShowMissingDataUserModal = false;
    },
  },
});

export const setupActions = setupSlice.actions;

export default setupSlice.reducer;

export const selectIsFetchingSetupData = (state) =>
  state.setup.isFetchingSetupData;

export const selectShouldShowMissingDataUserModal = (state) =>
  state.setup.shouldShowMissingDataUserModal;
