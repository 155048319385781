import { createSlice } from '@reduxjs/toolkit';

const rightSlice = createSlice({
  name: 'rights',
  initialState: {
    isLoading: false,
    clusters: [],
  },
  reducers: {
    getRightsList: (state) => {
      if (state.isLoading === false) {
        state.isLoading = true;
      }
    },
    getRightsListSuccess: (state, action) => {
      if (state.isLoading === true) {
        state.clusters = action.payload;
        state.isLoading = false;
      }
    },
    getRightsListFailed: (state) => {
      if (state.isLoading === true) {
        state.clusters = [];
        state.isLoading = false;
      }
    },
  },
});

export const { getRightsList, getRightsListSuccess, getRightsListFailed } =
  rightSlice.actions;

export default rightSlice.reducer;
