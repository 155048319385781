import environment from 'environments/environment.dev';
import HttpClient from 'services/apiHttpClient';

const GET_SAP_LOG = '/v1.0/saplog';

const fapiClient = new HttpClient(environment.adminFAPIUrl);

const getSapLogRequest = () => fapiClient.get(GET_SAP_LOG);

export const adminSAPlog = {
  getSapLogRequest,
};
