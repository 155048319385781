import useProductComparison from 'hooks/useProductComparison';
import PropTypes from 'prop-types';
import React from 'react';

import ProductComparisonModal from '../../../../components/ProductComparisonModal';
import ProductGridView from './ProductCardList';
import ProductListView from './ProductCardTable';

const ProductContent = ({ dataSrc, isListView, isLoading }) => {
  const {
    showComparisonModal,
    setShowComparisonModal,
    comparingProductIds,
    onCompareHandler,
    productInformation,
  } = useProductComparison();

  return (
    <>
      {isListView ? (
        <ProductListView
          isLoading={isLoading}
          dataSrc={dataSrc}
          comparingProductIds={comparingProductIds}
          onCompareHandler={onCompareHandler}
        />
      ) : (
        <ProductGridView
          products={dataSrc}
          comparingProductIds={comparingProductIds}
          onCompareHandler={onCompareHandler}
        />
      )}
      <ProductComparisonModal
        showModal={showComparisonModal}
        setShowModal={setShowComparisonModal}
        productsData={productInformation}
      />
    </>
  );
};

ProductContent.propTypes = {
  dataSrc: PropTypes.arrayOf(PropTypes.object),
  isListView: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ProductContent.defaultProps = {
  dataSrc: [],
  isListView: false,
  isLoading: false,
};

export default ProductContent;
