import './styles.scss';

// import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Content from '../Content';

function Headline() {
  const { t } = useTranslation();
  return (
    <Content className="public-page__headline">
      <h1>{t('registration.headline')}</h1>
    </Content>
  );
}

// Headline.propTypes = {};

// Headline.defaultProps = {};

export default Headline;
