import { LoadingOutlined } from '@ant-design/icons';
import CustomButton from 'components/CustomButton';
import { LINK_NAMES } from 'libs/constants/adobeAnalytics';
import { analyzeLinkName } from 'libs/utils/adobeAnalysis';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectVoucherCode } from 'store/selectors/cartSelector';
import { trackLinkName } from 'store/slices/adobeAnalyticSlice';

import styles from './RedeemVoucherInput.module.scss';

const RedeemVoucherInput = (props) => {
  const { t } = useTranslation();
  const { handleRedeemVoucher, isRedeeming, isDisabled } = props;
  const [voucherCode, setVoucherCode] = useState('');

  const voucherCodeFromStore = useSelector(selectVoucherCode);
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (e) => {
      if (
        voucherCode &&
        voucherCode !== '' &&
        voucherCode !== voucherCodeFromStore
      ) {
        dispatch(trackLinkName(analyzeLinkName(LINK_NAMES.APPLY_VOUCHER)));
        handleRedeemVoucher(voucherCode);
        setVoucherCode('');
      }

      e.currentTarget.blur();
    },
    [dispatch, voucherCode, handleRedeemVoucher, voucherCodeFromStore]
  );

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        value={voucherCode}
        disabled={isDisabled}
        onChange={(e) => {
          setVoucherCode(e.target.value);
        }}
      />
      <div className={styles.redeem__button}>
        <CustomButton
          type="ghost"
          buttonSize="small"
          onClick={handleClick}
          disabled={isRedeeming || isDisabled}
          icon={isRedeeming && <LoadingOutlined />}
        >
          {t('redeemVoucher.applyVoucher')}
        </CustomButton>
      </div>
    </div>
  );
};

RedeemVoucherInput.propTypes = {
  isRedeeming: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleRedeemVoucher: PropTypes.func.isRequired,
};
RedeemVoucherInput.defaultProps = {
  isRedeeming: false,
  isDisabled: false,
};
export default RedeemVoucherInput;
