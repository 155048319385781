import B2becPagination from 'components/B2becPagination';
import { useAsync } from 'hooks';
import { ASYNC_STATUS, PAGE_SIZE } from 'libs/constants';
import OrdersHistoryTable from 'pages/OrderPage/OrdersHistoryPage/OrdersHistoryTable';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { adminFapiSupport } from 'services/adminFapi';

import styles from './OrderList.module.scss';

const DEFAULT_PAGE_SIZE = PAGE_SIZE[10];

const handleGetOrderList = async (customerNumber, countryId, pageNumber) => {
  const response = await adminFapiSupport.getAllOrdersByCustomer(
    customerNumber,
    countryId,
    pageNumber,
    DEFAULT_PAGE_SIZE
  );
  return response.data;
};

function OrderList({ customerNumber, countryId }) {
  const [pageNumber, setPageNumber] = useState(1);
  const fetchOrderHistory = useCallback(() => {
    return handleGetOrderList(
      customerNumber,
      countryId,
      pageNumber,
      PAGE_SIZE[10]
    );
  }, [countryId, customerNumber, pageNumber]);

  const { value, status } = useAsync(fetchOrderHistory, true);
  const isLoading = status === ASYNC_STATUS.PENDING;

  return (
    <div className={styles.wrapper}>
      <OrdersHistoryTable
        items={value?.orderList?.items}
        totalCount={value?.orderList?.totalCount}
        currentPage={value?.orderList?.currentPage}
        isLoading={isLoading}
        hasActions={false}
        onReorder={() => undefined}
      />
      <B2becPagination
        pageSize={DEFAULT_PAGE_SIZE}
        total={value?.orderList?.totalCount}
        onPageChange={setPageNumber}
        current={value?.orderList?.currentPage}
      />
    </div>
  );
}

OrderList.propTypes = {
  customerNumber: PropTypes.string.isRequired,
  countryId: PropTypes.string.isRequired,
};

export { OrderList };
