import './styles.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import MailMainImage from '../../../../assets/img/mail-mainimage.png';
import Content from '../../../../components/PublicHeader/Content';

function Introduction() {
  const { t } = useTranslation();
  return (
    <Content className="registration-page__introduction__wrapper">
      <div className="registration-page__introduction">
        <img src={MailMainImage} alt="Mail Introduction" />
        <div className="registration-page__introduction__content">
          <h2>{t('registration.introduction.title')}</h2>
          <p>{t('registration.introduction.description')}</p>
        </div>
      </div>
    </Content>
  );
}

export default Introduction;
