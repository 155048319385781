import B2becCustomSelect from 'components/B2becCustomSelect';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAvailableSalesOrgs } from 'store/selectors/configSelector';

const B2bSelectSalesOrg = (props) => {
  const { placeHolder, ...selectProps } = props;
  const availableSalesOrgs = useSelector(selectAvailableSalesOrgs);

  const options = useMemo(() => {
    if (!availableSalesOrgs) {
      return [{ value: '', label: placeHolder }];
    }
    const formattedOptions = availableSalesOrgs?.map((salesOrg) => {
      return {
        label: salesOrg?.name,
        value: salesOrg?.code,
      };
    });

    if (placeHolder) {
      return [{ value: '', label: placeHolder }, ...formattedOptions];
    }

    return formattedOptions;
  }, [placeHolder, availableSalesOrgs]);

  return <B2becCustomSelect options={options} {...selectProps} />;
};

export default B2bSelectSalesOrg;
