export const VOUCHER_STATUS = {
  active: 'active',
  inactive: 'inactive',
};

export const RESPONSE_STATUS = {
  duplicateCode: 409,
  invalidZip: 301,
};

export const RIGHT_CHECKBOX_STATUS = {
  checked: 'checked',
  unchecked: 'unchecked',
  indeterminate: 'indeterminate',
};
