import './styles.scss';

import { Col, Form, Input, Row } from 'antd';
// import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactInformationSection = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-info__wrapper">
      <span className="contact-info__title">
        {t('service.addressAndDate.pleaseProvideYourContactInfo')}
      </span>
      <Row gutter={12}>
        <Col span={24} lg={12}>
          <Form.Item
            name="contactPersonFirstName"
            label={t('form.label.contactPersonFirstName')}
            className="custom-form-item"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('form.placeHolder.name')} />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item
            name="contactPersonLastName"
            label={t('form.label.contactPersonLastName')}
            className="custom-form-item"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('form.placeHolder.name')} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="contactEmail"
        label={t('form.label.contactEmail')}
        className="custom-form-item"
        rules={[{ required: true }]}
      >
        <Input placeholder={t('form.placeHolder.email')} />
      </Form.Item>
      <Form.Item
        name="contactNumber"
        label={t('form.label.contactNumber')}
        className="custom-form-item"
        rules={[{ required: true }]}
      >
        <Input placeholder={t('form.placeHolder.phoneNumber')} />
      </Form.Item>
    </div>
  );
};

ContactInformationSection.propTypes = {};

ContactInformationSection.defaultProps = {};

export default ContactInformationSection;
