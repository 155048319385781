import styles from './B2BecPagination.module.scss';

import { Pagination } from 'antd';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React from 'react';

import { PAGE_SIZE } from '../../libs/constants';

const B2becPagination = ({ onPageChange, pageSize, ...props }) => {
  const itemRender = (_, type, originalElement) => {
    if (type !== 'prev' && type !== 'next') {
      return originalElement;
    }

    return (
      <button type="button" className={styles.roundButton}>
        {type === 'next' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </button>
    );
  };

  return (
    <Pagination
      className={styles.wrapper}
      defaultCurrent={1}
      responsive
      itemRender={itemRender}
      onChange={onPageChange}
      pageSize={pageSize}
      showSizeChanger={false}
      {...props}
    />
  );
};

B2becPagination.propTypes = {
  total: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

B2becPagination.defaultProps = {
  total: PAGE_SIZE[10],
  pageSize: PAGE_SIZE[12],
};

export default B2becPagination;
