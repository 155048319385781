import { linkGenerator } from 'libs/utils/language';
import { all, getContext, put, takeLatest } from 'redux-saga/effects';
import { orderServiceFAPI } from 'services';
import { trackClearedCart } from 'store/slices/adobeAnalyticSlice';
import { getMiniCart } from 'store/slices/cartSlice';
import { ordersActions } from 'store/slices/orderSlice';

import { NotificationManager } from '../../components/Notification';
import { getSessionCookieKey } from '../../contexts/punchout-context';
import { clearCookie } from '../../libs/utils/cookies';
import { modifyPayloadIfHavingSessionBasedShoppingCart } from './cartSaga';

function* getOrdersListSaga({ payload }) {
  try {
    const modifiedPayload = yield modifyPayloadIfHavingSessionBasedShoppingCart(
      payload
    );
    const { data } = yield orderServiceFAPI.getOrdersList(modifiedPayload);

    yield put(ordersActions.getOrdersListSucceeded(data));
  } catch (error) {
    console.error(error);
    yield put(ordersActions.getOrdersListFailed());

    NotificationManager.error({
      message: 'notification.error.load',
    });
  }
}

function* getOrderDetailByNumberSaga({ payload }) {
  try {
    const { data } = yield orderServiceFAPI.getOrderDetailByNumber(
      payload.orderNumber
    );

    yield put(ordersActions.getOrderDetailByNumberSucceeded(data));
  } catch (error) {
    const { history } = yield getContext('dependencies');
    yield put(ordersActions.getOrderDetailByNumberFailed());

    NotificationManager.error({
      message: 'notification.error.somethingWentWrong',
      description: 'notification.error.nonexistentOrderNumber',
    });

    history.push({
      pathname: linkGenerator('/orders'),
      search: `?page=${payload?.currentPage ?? 1}`,
    });
  }
}

function* placeAnOrderSaga({ payload }) {
  try {
    const { history } = yield getContext('dependencies');
    const modifiedPayload = yield modifyPayloadIfHavingSessionBasedShoppingCart(
      { orderData: payload }
    );

    const { data } = yield orderServiceFAPI.placeAnOrder(modifiedPayload);

    yield clearCookie(getSessionCookieKey());

    yield put(
      ordersActions.placeAnOrderSucceeded({
        orderData: data,
        deliveryData: payload,
      })
    );

    yield all([
      put(getMiniCart()),
      put(trackClearedCart()),
      history.push(linkGenerator('/confirm-order')),
    ]);

    NotificationManager.success({
      message: 'notification.success.submitOrder',
    });
  } catch (err) {
    yield put(ordersActions.placeAnOrderFailed(err?.message));
    NotificationManager.error({
      message: 'notification.error.submitOrder',
    });
  }
}

export default function* cartSaga() {
  yield takeLatest(ordersActions.getOrdersList.toString(), getOrdersListSaga);
  yield takeLatest(
    ordersActions.getOrderDetailByNumber.toString(),
    getOrderDetailByNumberSaga
  );
  yield takeLatest(ordersActions.placeAnOrder.toString(), placeAnOrderSaga);
}
