import './styles.scss';

import { Divider } from 'antd';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import FormatPrice from 'components/FormatPrice';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fapiRentalService } from 'services/fapi';
import { selectUserEmail } from 'store/selectors/authenticationSelectors';
import { getProductDetailData } from 'store/selectors/productSelector';
import { addProductToCart } from 'store/slices/cartSlice';

import B2becQuantity from '../../../components/B2becQuantity';
import B2becTranslation from '../../../components/B2becTranslation';
import CustomButton from '../../../components/CustomButton';
import { useAsync } from '../../../hooks';
import usePermission from '../../../hooks/usePermission';
import { ASYNC_STATUS } from '../../../libs/constants';
import {
  MODULE_CART_CHECKOUT_RIGHTS,
  MODULE_SERVICE_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
} from '../../../libs/constants/modulerights';
import {
  selectIsAddingToCartFromPriceBox,
  selectShouldDisplayDeliveryDates,
} from '../../../store/selectors/cartSelector';
import {
  getCustomerName,
  getCustomerPhone,
} from '../../../store/selectors/customerSelector';
import RentalModal from '../../RentalPage/RentalModal';
import useEnquiryModal from '../EnquiryModal';

function PriceBox(props) {
  const {
    isLoading,
    isAvailable,
    materialNumber,
    currencyUnit,
    listingPrice,
    price,
    deliveryDates,
    requiresInstruction: isBProgramProduct,
    materialName,
    isDangerous,
    hasMinQuantity,
    shouldDisplayListPrice,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { displayAddToCart: shouldDisplayAddToCart } = useSelector(
    getProductDetailData,
    shallowEqual
  );
  const isAddingToCart = useSelector(selectIsAddingToCartFromPriceBox);
  const companyName = useSelector(getCustomerName);
  const phoneNumber = useSelector(getCustomerPhone);
  const email = useSelector(selectUserEmail);
  const shouldDisplayDeliveryDates = useSelector(
    selectShouldDisplayDeliveryDates
  );
  const {
    verifiedPermissions: [
      hasAddProductToCartPermission,
      hasCreateProductServicePermission,
    ],
  } = usePermission([
    MODULE_SHOP_NAVIGATION_RIGHTS.ADD_PRODUCT_TO_SHOPPING_CART,
    MODULE_SERVICE_RIGHTS.CREATE_PRODUCT_REQUEST,
  ]);

  const { hasPermission: hasViewIndividualPricePermision } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIUAL_PRICE
  );

  const [quantity, setQuantity] = useState(1);
  const onChangeQuantity = (newQuantity) => setQuantity(newQuantity);

  const onAddProductToCart = () => {
    dispatch(addProductToCart({ materialNumber, quantity }));
  };
  const onFindAlternatives = () => {
    // TODO: implement on button click
  };

  const getIsRentable = useCallback(async () => {
    const response = await fapiRentalService.getIsRentable(materialNumber);
    return response?.data;
  }, [materialNumber]);

  const { value: isRentable, status: isRentableLoading } = useAsync(
    getIsRentable,
    true
  );

  const { showEnquiryModal, EnquiryModal } = useEnquiryModal({
    materialNumber,
    materialName,
    companyName,
    phoneNumber,
    email,
  });

  const renderAddToCartButton = (options = {}) => {
    if (options.isShowEnquiryButton) {
      return (
        <CustomButton
          type="default"
          className="price-box__bottom__button"
          onClick={showEnquiryModal}
          disabled={!materialNumber || options.shouldDisableEnquiry}
        >
          {t('productDetails.priceBox.buttonEnquiry')}
        </CustomButton>
      );
    }

    return (
      <CustomButton
        type="default"
        className="price-box__bottom__button"
        onClick={onAddProductToCart}
        loading={isAddingToCart}
        disabled={isAddingToCart || !materialNumber}
      >
        {t('productDetails.priceBox.button')}
      </CustomButton>
    );
  };

  const renderRentableButton = () => {
    if (isRentable) {
      return isLoading ? (
        <Skeleton height={50} />
      ) : (
        <>
          <Divider style={{ margin: '10px 0' }} />
          <RentalModal deviceName={materialName}>
            <CustomButton type="ghost" style={{ width: '100%' }}>
              {t('productDetails.priceBox.requestRental')}
            </CustomButton>
          </RentalModal>
        </>
      );
    }
    return null;
  };

  const renderRentalInfo = () => {
    if (isRentable) {
      return isLoading ? (
        <Skeleton height={20} />
      ) : (
        <div className="price-box__delivery-info">
          {t('productDetails.priceBox.rentalInfo')}
        </div>
      );
    }
    return null;
  };

  const renderListingPrice = (lp, options = {}) => {
    const listPriceValue = options?.isShowOnRequest ? (
      t('productCard.onRequest')
    ) : (
      <FormatPrice price={lp} currencyUnit={currencyUnit} />
    );

    if (options.isHideListPrice) {
      return null;
    }

    return (
      <>
        <div className="price-box__listing-price__title">
          {t('productDetails.priceBox.listingPrice')}
        </div>
        <div className="price-box__listing-price__price">{listPriceValue}</div>
      </>
    );
  };

  const renderDeliveryDate = (dates) => {
    if (!dates || dates.length === 0) {
      return (
        <span>{t('productDetails.priceBox.unavailableDeliveryDate')}</span>
      );
    }
    // Find first minimum greater quantity (from server) than the user selected quantity
    const firstDateIndex = dates.findIndex((date) => quantity <= date.quantity);
    return (
      dates
        // If cannot find any (user selected quantity > max quantity in dates array from server)
        // => Show all delivery dates
        .slice(0, firstDateIndex + 1 > 0 ? firstDateIndex + 1 : dates.length)
        .map((date) => {
          const deliveryDate = formatDate(
            date.deliveryDate,
            getFormatType().default
          );
          return deliveryDate ? (
            <B2becTranslation
              key={date.deliveryDate}
              value="productDetails.priceBox.deliveryInfo"
              variables={{
                deliveryDate,
                count: date.quantity,
              }}
            />
          ) : (
            <span key={date.deliveryDate}>
              {t('productDetails.priceBox.unavailableDeliveryDate')}
            </span>
          );
        })
    );
  };

  const renderWarningText = (text) => {
    return (
      <p>
        <InfoIcon className="price-box__dangerous-goods__icon" />
        {text}
      </p>
    );
  };

  const renderAvailableState = () => {
    const isPriceEqualZero = +price === 0 || price === '';

    const isShowEnquiryButton =
      !shouldDisplayAddToCart ||
      isPriceEqualZero ||
      isBProgramProduct ||
      !hasAddProductToCartPermission;

    const isHideNetPrice =
      !shouldDisplayAddToCart ||
      isPriceEqualZero ||
      !hasViewIndividualPricePermision;

    const isHideListPrice = price >= listingPrice;

    return (
      <>
        <div className="price-box__top-wrapper">
          <div className="price-box__top">
            <div className="price-box__title fontBold">
              {isLoading ? (
                <Skeleton height={20} width="50%" />
              ) : (
                t('productDetails.priceBox.title')
              )}
            </div>
            <div className="price-box__price">
              {isLoading ? (
                <Skeleton height={20} width={100} />
              ) : isHideNetPrice ? (
                t('productCard.onRequest')
              ) : (
                <FormatPrice price={price} currencyUnit={currencyUnit} />
              )}
            </div>
            <div className="price-box__listing-price">
              {isLoading ? (
                <Skeleton height={30} />
              ) : (
                renderListingPrice(listingPrice, {
                  isHideListPrice,
                  isShowOnRequest: !shouldDisplayListPrice,
                })
              )}
            </div>
            {renderRentalInfo()}
            <div className="price-box__delivery-info">
              {isLoading ? (
                <Skeleton height={20} />
              ) : (
                <>
                  {shouldDisplayDeliveryDates
                    ? renderDeliveryDate(deliveryDates)
                    : null}
                  {t('productDetails.priceBox.shippingCostsInfo')}
                </>
              )}
            </div>
            <div className="price-box__dangerous-goods">
              {isLoading ? (
                <Skeleton height={20} />
              ) : (
                <>
                  {isDangerous &&
                    renderWarningText(
                      t('productDetails.priceBox.thisProductHasDangerousGoods')
                    )}
                </>
              )}
            </div>
            <div className="price-box__dangerous-goods">
              {isLoading ? (
                <Skeleton height={20} />
              ) : (
                <>
                  {hasMinQuantity &&
                    renderWarningText(t('productDetails.priceBox.moqHint'))}
                </>
              )}
            </div>
            <div className="price-box__item">
              {isLoading ? (
                <Skeleton height={20} />
              ) : (
                <span>{t('productDetails.priceBox.itemQuantity')}</span>
              )}
            </div>
            <div className="price-box__quantity">
              {isLoading ? (
                <Skeleton height={40} width={120} />
              ) : (
                <B2becQuantity
                  initialQuantity={1}
                  onChange={onChangeQuantity}
                  buttonClassName="price-box__change-button"
                  inputClassName="price-box__input"
                  isPriceEqualZero={isPriceEqualZero}
                />
              )}
            </div>
          </div>
        </div>
        <div className="price-box__bottom-wrapper">
          {isLoading ? (
            <Skeleton height={50} />
          ) : (
            renderAddToCartButton({
              isShowEnquiryButton,
              shouldDisplayAddToCart:
                shouldDisplayAddToCart && hasAddProductToCartPermission,
              shouldDisableEnquiry: !hasCreateProductServicePermission,
            })
          )}
          {isRentableLoading === ASYNC_STATUS.PENDING ? (
            <Skeleton height={50} />
          ) : (
            renderRentableButton()
          )}
        </div>
      </>
    );
  };

  const renderUnavailableState = () => (
    <div className="price-box--unavailable">
      <p className="price-box--unavailable__message">
        This product is no longer available.
      </p>
      <CustomButton
        className="price-box__bottom__button"
        onClick={onFindAlternatives}
      >
        Find Alternatives
      </CustomButton>
    </div>
  );

  return (
    <div className="price-box__wrapper">
      {isAvailable ? renderAvailableState() : renderUnavailableState()}
      <EnquiryModal />
    </div>
  );
}

PriceBox.propTypes = {
  isLoading: PropTypes.bool,
  isAvailable: PropTypes.bool,
  materialNumber: PropTypes.string,
  currencyUnit: PropTypes.string,
  listingPrice: PropTypes.number,
  price: PropTypes.number,
  deliveryDates: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number,
      materialNumber: PropTypes.string,
      deliveryDate: PropTypes.string,
    })
  ),
  requiresInstruction: PropTypes.bool,
  materialName: PropTypes.string,
  isDangerous: PropTypes.bool,
  hasMinQuantity: PropTypes.bool,
  shouldDisplayListPrice: PropTypes.bool,
};

PriceBox.defaultProps = {
  isLoading: false,
  isAvailable: false,
  materialNumber: '',
  currencyUnit: '',
  listingPrice: 0,
  price: 0,
  deliveryDates: [],
  requiresInstruction: false,
  materialName: '',
  isDangerous: false,
  hasMinQuantity: false,
  shouldDisplayListPrice: false,
};

export default PriceBox;
