import './styles.scss';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import ImageWithFallBack from '../../ImageWithFallback';

const SuggestionItem = ({
  isTransparent,
  isLoading,
  materialNumber,
  name,
  imageUrl,
  isIpadLandscape,
}) => (
  <div
    className={clsx(
      'suggestion-item__wrapper',
      isLoading && 'suggestion-item--loading'
    )}
    role="button"
    tabIndex="0"
    onKeyDown={(e) => e.preventDefault()}
  >
    {!isIpadLandscape && (
      <div className="suggestion-item__left">
        {isTransparent ? (
          <div className="suggestion-item__image" />
        ) : isLoading ? (
          <Skeleton width={60} height={60} />
        ) : (
          <ImageWithFallBack
            src={imageUrl}
            alt={name}
            className="suggestion-item__image"
          />
        )}
      </div>
    )}
    <div className="suggestion-item__right">
      {!isIpadLandscape && (
        <p className="suggestion-item__text suggestion-item__category">
          {isTransparent ? (
            <>&nbsp;</>
          ) : isLoading ? (
            <Skeleton />
          ) : (
            materialNumber || 'N/A'
          )}
        </p>
      )}
      <p className="suggestion-item__text suggestion-item__name ">
        {isTransparent ? <>&nbsp;</> : isLoading ? <Skeleton /> : name || 'N/A'}
      </p>
    </div>
  </div>
);

SuggestionItem.propTypes = {
  isTransparent: PropTypes.bool,
  isLoading: PropTypes.bool,
  materialNumber: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  isIpadLandscape: PropTypes.bool,
};

SuggestionItem.defaultProps = {
  isTransparent: false,
  isLoading: false,
  imageUrl: '',
  isIpadLandscape: false,
};

export default SuggestionItem;
