import { configureStore } from '@reduxjs/toolkit';
import environment from 'environments/environment.dev';
import { history } from 'libs/utils/history';
import createSagaMiddleware from 'redux-saga';
import {
  AuthenticationService,
  OrderService,
  ProductService,
  searchService,
  serviceCaseService,
  UserService,
} from 'services';
import { adminFapiPromotion } from 'services/adminFapi';
import { fapiConfigurationService } from 'services/fapi';

import rootSaga from './sagas';
import rootReducer from './slices';
import { apiSlice } from './slices/apiSlice';

const sagaMiddleware = createSagaMiddleware({
  context: {
    dependencies: {
      history,
      AuthenticationService,
      fapiConfigurationService,
      OrderService,
      ProductService,
      searchService,
      ServiceCaseService: serviceCaseService,
      UserService,
      adminFapiPromotion,
    },
  },
});

const store = configureStore({
  reducer: rootReducer,
  devTools: environment.enableReduxDevTool,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([sagaMiddleware, apiSlice.middleware]),
});

sagaMiddleware.run(rootSaga);

export default store;
