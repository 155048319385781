import { linkGenerator } from 'libs/utils/language';
import React from 'react';
import { useHistory } from 'react-router-dom';

const CallbackPage = () => {
  const history = useHistory();

  React.useEffect(() => {
    history.push(linkGenerator('/my-workspace'));
  }, [history]);

  return <></>;
};

export default CallbackPage;
