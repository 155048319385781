export const ZINDEX_DEFAULT = 10;

export const HEADER_MENU_OFFSET_TOP = 0;
export const HEADER_SEARCH_OFFSET_TOP = 88;
// export const HEADER_SEARCH_IN_MOBILE_OFFSET_TOP = 88;
export const HEADER_SEARCH_IN_MOBILE_OFFSET_TOP = 56;

export const OFFSET_TOP = {
  HEADER_MENU: HEADER_MENU_OFFSET_TOP,
  HEADER_SEARCH: HEADER_SEARCH_OFFSET_TOP,
  HEADER_SEARCH_IN_MOBILE: HEADER_SEARCH_IN_MOBILE_OFFSET_TOP,
};

export const STICKY_ZINDEX = {
  SEARCH_IN_MOBILE: ZINDEX_DEFAULT + 6,
  LOCALIZATION: ZINDEX_DEFAULT + 5,
  MENU: ZINDEX_DEFAULT + 4,
  SEARCH: ZINDEX_DEFAULT + 3,
  SIDE_BAR: ZINDEX_DEFAULT + 2,
  BREADCRUMB: ZINDEX_DEFAULT + 1,
  // BREADCRUMB: ZINDEX_DEFAULT + 3,
  YELLOW_BANNER: 2,
};

export const STICKY_STATUS = {
  STATUS_ORIGINAL: 0,
  STATUS_RELEASED: 1,
  STATUS_FIXED: 2,
};
