import './styles.scss';

import { Col, Form, Input, Row } from 'antd';
import B2becSpinner from 'components/B2becSpinner';
import B2becTranslation from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import { ChevronLeftIcon } from 'components/Icons';
import { notificationComponent } from 'components/Notification';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { linkGenerator } from 'libs/utils/language';
import {
  validateConfirmPassword,
  validatePasswordRequirements,
  validateRequiredMessage,
} from 'libs/utils/passwordValidators';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fapiUserService } from 'services/fapi';
import { selectUserId } from 'store/selectors/authenticationSelectors';

const ERROR_TYPES = {
  INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
  DEFAULT: 'DEFAULT',
};

const ERROR_CODES = {
  INCORRECT_PASSWORD: '203',
};

const errorTypeParse = (error) => {
  const response = error?.response;

  if (response?.status === 500) {
    const errorCode = response.data?.errorCode;
    if (errorCode === ERROR_CODES.INCORRECT_PASSWORD) {
      return ERROR_TYPES.INCORRECT_PASSWORD;
    }

    return errorCode;
  }

  return ERROR_TYPES.DEFAULT;
};

const { useForm } = Form;

const SetPasswordPage = (props) => {
  const [form] = useForm();
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageName: 'change password',
    pageType: PAGE_TYPES.PROFILE,
    pageId,
  });

  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const userId = useSelector(selectUserId);

  const handleChangePassword = useCallback(
    ({ currentPassword: oldPassword, newPassword }) =>
      fapiUserService.setNewPasswordApi(userId, oldPassword, newPassword),
    [userId]
  );

  const cancelChangeHandler = () => history.push(linkGenerator('/profile'));

  const onSubmit = async (submitValues) => {
    try {
      setIsLoading(true);
      await handleChangePassword(submitValues);
      setIsLoading(false);

      notificationComponent({
        type: NOTIFICATION_TYPE.SUCCESS,
        message: <B2becTranslation value="setPassword.messages.success" />,
      });

      history.replace(linkGenerator('/my-workspace'));
    } catch (e) {
      setIsLoading(false);
      const typeError = errorTypeParse(e);

      if (typeError !== ERROR_TYPES.INCORRECT_PASSWORD) {
        setError(typeError);

        return;
      }

      form.setFields([
        {
          name: 'currentPassword',
          errors: [
            <B2becTranslation
              key="currentPasswordIncorrect"
              value="setPassword.validationMessage.currentPasswordIncorrect"
            />,
          ],
        },
      ]);
    }
  };

  return (
    <>
      <B2becSpinner isLoading={isLoading} />
      <div className="set-password-form__title-wrapper">
        <h1>{t('setPassword.title')}</h1>
        <CustomButton
          onClick={() => cancelChangeHandler()}
          buttonSize="small"
          className="set-password-form__btn--back"
        >
          <ChevronLeftIcon style={{ marginRight: 10 }} />
          {t('searchResultsPage.button.backToOverview')}
        </CustomButton>
        <p className="set-password-form__title-wrapper--message">
          {t('setPassword.message')}
        </p>
      </div>

      {error ? (
        <div className="set-password-form__error-message validate-message">
          <B2becTranslation
            value="setPassword.validationMessage.errorMessageWithCode"
            variables={{ errorCode: error }}
          />
        </div>
      ) : null}

      <div className="set-password-form__container">
        <Form
          name="set-password-form__form"
          onFinish={onSubmit}
          layout="vertical"
          form={form}
          scrollToFirstError
        >
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label={t('setPassword.label.currentPassword')}
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: validateRequiredMessage(
                      'setPassword.label.currentPassword'
                    ),
                  },
                ]}
                className="custom-form-item"
              >
                <Input.Password
                  className="set-password-form__input"
                  autoComplete="currentPassword"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label={t('setPassword.label.newPassword')}
                name="newPassword"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: validateRequiredMessage(
                      'setPassword.label.newPassword'
                    ),
                  },
                  {
                    validator: validatePasswordRequirements,
                  },
                ]}
                className="custom-form-item"
              >
                <Input.Password
                  className="set-password-form__input"
                  autoComplete="newPassword"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label={t('setPassword.label.confirmPassword')}
                name="confirmPassword"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: validateRequiredMessage(
                      'setPassword.label.confirmPassword'
                    ),
                  },
                  ({ getFieldValue }) =>
                    validateConfirmPassword(getFieldValue('newPassword')),
                ]}
                dependencies={['newPassword']}
                className="custom-form-item"
              >
                <Input.Password
                  className="set-password-form__input"
                  autoComplete="newPassword"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div className="set-password-form__buttons-wrapper">
              <CustomButton
                className="btn btn--submit"
                type="primary"
                htmlType="submit"
              >
                {t('setPassword.title')}
              </CustomButton>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

SetPasswordPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default SetPasswordPage;
