import './styles.scss';

import ImageWithFallBack from 'components/ImageWithFallback';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SHOP_CATEGORY_TYPE } from '../../../libs/constants';

const SuggestionCategoryItem = ({
  categoryId,
  categoryName,
  type,
  menuImages,
  onClick,
}) => {
  const { t } = useTranslation();

  const getCategoryImage = (arrData) => {
    const selectedImage = arrData.find((item) => item.type === 'thumb');
    return selectedImage?.url;
  };

  return (
    <div
      className="search__dropdown__product-categories__category-item__wrapper"
      role="button"
      tabIndex="0"
      onKeyDown={(e) => e.preventDefault()}
      onClick={() => onClick(categoryId, type)}
    >
      <div className="search__dropdown__product-categories__category-item__left">
        <ImageWithFallBack
          src={
            type === SHOP_CATEGORY_TYPE.MACHINES
              ? getCategoryImage(menuImages)
              : menuImages[0].url
          }
          alt={categoryName}
          className="search__dropdown__product-categories__category-item__left__image"
        />
      </div>
      <div className="search__dropdown__product-categories__category-item__right">
        <div className="search__dropdown__product-categories__category-item__right__name">
          {t(categoryName)}
        </div>
      </div>
    </div>
  );
};

SuggestionCategoryItem.propTypes = {
  categoryId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  categoryName: PropTypes.string,
  type: PropTypes.string.isRequired,
  menuImages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

SuggestionCategoryItem.defaultProps = {
  categoryName: '',
};

export default SuggestionCategoryItem;
