import {
  checkIsLanguageSupported,
  getCultureFromRegistrationLink,
} from 'libs/utils/language';
import React, { useEffect, useMemo, useState } from 'react';

function CookieBannerScripts() {
  const [languageInLocalStorage, setLanguageInLocalStorage] = useState(
    localStorage.getItem('lng')
  );

  useEffect(() => {
    const changeLangHandler = (event) => {
      if (event.key === 'lng') {
        setLanguageInLocalStorage(event.value);
      }
    };
    document.addEventListener('changeLang', changeLangHandler);

    return () => {
      document.removeEventListener('changeLang', changeLangHandler);
    };
  }, []);

  const languageCode = useMemo(() => {
    if (languageInLocalStorage) {
      return languageInLocalStorage;
    }

    const paths = window.location.pathname;
    const languageOnPathName = paths.split('/').filter((path) => path)[0];

    // "registration router" has a different format to get the culture
    if (languageOnPathName === 'registration') {
      return getCultureFromRegistrationLink(paths);
    }
    // normal routers
    return checkIsLanguageSupported(languageOnPathName)
      ? languageOnPathName
      : 'en';
  }, [languageInLocalStorage]);

  const oneTrustLanguage = useMemo(() => {
    if (languageCode === 'de-FT') {
      return 'de-DE';
    }

    return languageCode;
  }, [languageCode]);

  useEffect(() => {
    const OptanonWrapper = () => {
      const paths = window.location.pathname;

      setTimeout(() => {
        const cookieButtonText =
          document.getElementById('ot-sdk-btn')?.textContent;
        if (
          paths.includes('data-protection-policy') &&
          (cookieButtonText === '' || typeof cookieButtonText === 'undefined')
        ) {
          window.location.reload(true);
        }
      }, 1500);
    };

    if (document.getElementById('oneTrustLib')) {
      document.getElementById('oneTrustLib').outerHTML = '';
    }

    const script = document.createElement('script');
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute('id', 'oneTrustLib');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('data-language', oneTrustLanguage);
    script.setAttribute(
      'data-domain-script',
      'd8f3a9b2-8a80-466f-b82f-6281c84ca3b0'
    );
    script.onload = OptanonWrapper;

    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, [oneTrustLanguage]);

  return null;
}

function AdobeAnalyticScripts() {
  // eslint-disable-next-line no-underscore-dangle
  useEffect(() => window?._satellite && window?._satellite?.pageBottom(), []);

  return null;
}

function ExternalFileScripts() {
  return (
    <>
      <CookieBannerScripts />
      <AdobeAnalyticScripts />
    </>
  );
}

export default ExternalFileScripts;
