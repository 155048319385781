export const formatSerialNumber = (serialNumber) => {
  if (typeof serialNumber === 'string' || typeof serialNumber === 'number') {
    return String(serialNumber).replace(/^ +/, '').replace(/^0+/, '');
  }
  return '';
};

export const validateSerialNumber = (serialValue) => {
  if (/^0/.test(serialValue)) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('WRONG_SERIAL_NUMBER');
  }

  if (/^[1-9]/.test(serialValue)) {
    if (/^[1-9]{1}\d{4,5}$/.test(serialValue)) {
      return Promise.resolve(serialValue);
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('WRONG_SERIAL_NUMBER');
  }

  if (/^[A-Z]/i.test(serialValue)) {
    if (serialValue.match(/^[A-Z]{1,}\d{5,}/i) === null) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('WRONG_SERIAL_NUMBER_WITH_LETTER');
    }
  }

  return Promise.resolve(serialValue);
};
