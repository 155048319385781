import { Checkbox } from 'antd';
import clsx from 'clsx';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import B2becTranslation from '../../../../components/B2becTranslation';

const generateCell = (
  key,
  className,
  content,
  isCenterHorizontal,
  isMiddleVertical,
  isDark
) => {
  return (
    <div
      key={key}
      className={clsx(
        className,
        'invoices-download-modal__table__row--nested',
        {
          'invoices-download-modal__table__cell__align--center-horizontal': isCenterHorizontal,
          'invoices-download-modal__table__cell__align--middle-vertical': isMiddleVertical,
          'invoices-download-modal__table__cell--dark': isDark,
        }
      )}
    >
      {content}
    </div>
  );
};

const tableColumns = (isLoading, onCheckboxChange) => {
  let rowIndexCheckbox = 1;
  let rowIndexInvoiceNumber = 1;
  let rowIndexProduct = 1;
  let rowIndexInformation = 1;
  return [
    {
      width: '10%',
      title: '',
      dataIndex: 'checked',
      render: (checked, { products, invoiceNumber }) => {
        return (
          <>
            {products.map((product, index) => {
              let cell;
              if (index === 0) {
                cell = generateCell(
                  `${invoiceNumber}-${product.materialNumber}`,
                  '',
                  isLoading ? (
                    <Skeleton width={24} height={24} />
                  ) : (
                    <Checkbox
                      className="invoices-download-modal__table__checkbox custom-input"
                      checked={checked}
                      onChange={(e) =>
                        onCheckboxChange(e.target.checked, invoiceNumber)
                      }
                    />
                  ),
                  true,
                  true,
                  rowIndexCheckbox % 2 === 0
                );
                rowIndexCheckbox += 1;
                return cell;
              }
              cell = generateCell(
                `${invoiceNumber}-${product.materialNumber}`,
                '',
                <>&nbsp;</>,
                false,
                false,
                rowIndexCheckbox % 2 === 0
              );
              rowIndexCheckbox += 1;
              return cell;
            })}
          </>
        );
      },
    },
    {
      width: '25%',
      title: (
        <B2becTranslation value="orders.history.invoiceDownloadModal.table.invoiceNumber" />
      ),
      dataIndex: 'invoiceNumber',
      render: (invoiceNumber, { products }) => {
        return (
          <>
            {products.map((product, index) => {
              let cell;
              if (index === 0) {
                cell = generateCell(
                  `${invoiceNumber}-${product.materialNumber}`,
                  'invoices-download-modal__table__invoiceNumber',
                  isLoading ? <Skeleton /> : invoiceNumber || 'N/A',
                  false,
                  true,
                  rowIndexInvoiceNumber % 2 === 0
                );
                rowIndexInvoiceNumber += 1;
                return cell;
              }
              cell = generateCell(
                `${invoiceNumber}-${product.materialNumber}`,
                '',
                <>&nbsp;</>,
                false,
                false,
                rowIndexInvoiceNumber % 2 === 0
              );
              rowIndexInvoiceNumber += 1;
              return cell;
            })}
          </>
        );
      },
    },
    {
      width: '20%',
      title: (
        <B2becTranslation value="orders.history.invoiceDownloadModal.table.product" />
      ),
      dataIndex: 'products',
      render: (products, { invoiceNumber }) => {
        return (
          <>
            {products.map((product) => {
              const cell = generateCell(
                `${invoiceNumber}-${product.materialNumber}`,
                'invoices-download-modal__table__image',
                isLoading ? (
                  <Skeleton width={54} height={54} />
                ) : (
                  <img src={product.pictureUrl} alt="Product" />
                ),
                false,
                true,
                rowIndexProduct % 2 === 0
              );
              rowIndexProduct += 1;
              return cell;
            })}
          </>
        );
      },
    },
    {
      width: '45%',
      title: (
        <B2becTranslation value="orders.history.invoiceDownloadModal.table.information" />
      ),
      dataIndex: 'products',
      render: (products, { invoiceNumber }) => {
        return (
          <>
            {products.map((product) => {
              const cell = generateCell(
                `${invoiceNumber}-${product.materialNumber}`,
                'invoices-download-modal__table__information',
                <>
                  <div>
                    {isLoading ? <Skeleton /> : product.materialNumber || 'N/A'}
                  </div>
                  <div>{isLoading ? <Skeleton /> : product.name || 'N/A'}</div>
                </>,
                false,
                true,
                rowIndexInformation % 2 === 0
              );
              rowIndexInformation += 1;
              return cell;
            })}
          </>
        );
      },
    },
  ];
};

export default tableColumns;
