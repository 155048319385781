import clsx from 'clsx';
import B2becButton from 'components/B2becButton';
import Footer from 'components/Layout/components/Footer';
import ScrollToTop from 'components/ScrollToTop';
import environment from 'environments/environment.dev';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import { authenticationActions } from 'store/slices/authenticationSlice';

import styles from './LandingPage.module.scss';

const {
  aws: { cognito },
} = environment;

const LandingPage = ({ title }) => {
  useDocumentTitle(title);
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const userId = useSelector(selectUserId);

  const { from } = location?.state || {
    from: { pathname: linkGenerator('/my-workspace') },
  };

  const handleLogin = () => {
    dispatch(
      authenticationActions.signInViaIdentityProvider({
        id: cognito.identityProvider.customers,
        from,
      })
    );
  };

  if (userId) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContainer}>
          <h2 className={styles.headerWelcomeTitle}>{t('welcome')}</h2>
        </div>
        <div className={styles.headerContainer}>
          <h3 className={styles.headerWelcomeText}>{t('welcome-text')}</h3>
        </div>
      </div>
      <ScrollToTop />
      <div className={styles.contentContainer}>
        <B2becButton
          className={clsx('kaercher-button', styles.loginButton)}
          onClick={handleLogin}
        >
          {t('login')}
        </B2becButton>
      </div>
      <Footer />
    </>
  );
};

LandingPage.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LandingPage;
