export const isMaterialNumberValid = (str) => {
  if (!str) {
    return false;
  }
  return (
    str.length === 11 &&
    RegExp('^([0-9]{1})\\.([0-9]{3})-([0-9]{3})\\.([0-9]{1})').test(str)
  );
};

export const formatMaterialID = (str, digits = 8) => {
  const lastDigits = +(digits - 7);
  const regexString = `^([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{${lastDigits}})`;

  const result = [...str.matchAll(RegExp(regexString, 'g'))][0];

  if (!result) {
    return str;
  }

  return `${result[1]}.${result[2]}-${result[3]}.${result[4]}`;
};
