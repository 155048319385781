import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsUserListFetching } from 'store/slices/usersManagementSlice';

import styles from './FilterByLetter.module.scss';

function FilterByLetter({ selectedLetter, onLetterClick }) {
  const isUserListFetching = useSelector(selectIsUserListFetching);
  const [alphabet] = useState(() => {
    const alphabetArray = [...Array(26)].map((_, i) =>
      String.fromCharCode('A'.charCodeAt(0) + i)
    );

    alphabetArray.unshift('ALL');

    return alphabetArray;
  });

  const handleLetterClick = (e) => {
    let chosenLetter = e.target.value;

    if (chosenLetter === 'ALL') {
      chosenLetter = '';
    }

    onLetterClick(chosenLetter);
  };

  return (
    <>
      {alphabet.map((letter) => {
        const classes = clsx(styles['alphabet-button'], {
          [styles['alphabet-button--selected']]:
            selectedLetter === letter ||
            (letter === 'ALL' && selectedLetter === ''),
        });

        return (
          <button
            disabled={isUserListFetching}
            key={letter}
            type="button"
            className={classes}
            value={letter}
            onClick={handleLetterClick}
          >
            {letter}
          </button>
        );
      })}
    </>
  );
}

FilterByLetter.propTypes = {
  selectedLetter: PropTypes.string.isRequired,
  onLetterClick: PropTypes.func.isRequired,
};

export default FilterByLetter;
