import './styles.scss';

import { Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const B2becTable = ({
  className,
  boxShadow,
  striped,
  children,
  ...otherProps
}) => {
  const { t } = useTranslation();
  return (
    <Table
      locale={{
        emptyText: t('noData'),
      }}
      className={`base-table ${className} ${boxShadow ? 'box-shadow' : ''} ${
        striped ? 'striped' : ''
      }`}
      pagination={false}
      {...otherProps}
    >
      {children}
    </Table>
  );
};

B2becTable.propTypes = {
  className: PropTypes.string,
  boxShadow: PropTypes.bool,
  striped: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
B2becTable.defaultProps = {
  className: '',
  boxShadow: false,
  striped: true,
  children: undefined,
};

export default B2becTable;
