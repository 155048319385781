import PropTypes from 'prop-types';
import React from 'react';

const getFullName = ({ firstName, lastName }) => {
  if (!firstName || !lastName) {
    return '';
  }

  return `${firstName} ${lastName}`;
}

const ContactInformation = (props) => {
  const { data } = props;
  const { email, phoneNumber, ...nameData } = data;

  return (
    <div className="contact-information">
      <span>{getFullName(nameData)}</span>
      <span>{email}</span>
      <span>{phoneNumber}</span>
    </div>
  );
};

ContactInformation.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
};

ContactInformation.defaultProps = {
  data: {
    firstName: '',
    lastName: ''
  },
};

export default ContactInformation;
