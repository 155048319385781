import PropTypes from 'prop-types';
import React from 'react';

const SelectedService = (props) => {
  const { serviceType } = props;
  return (
    <div className="selected-service">
      <span>{serviceType}</span>
    </div>
  );
};

SelectedService.defaultProps = {
  serviceType: 'maintenance',
};

SelectedService.propTypes = {
  serviceType: PropTypes.string,
};

export default SelectedService;
