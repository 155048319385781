import { LoadingOutlined } from '@ant-design/icons';
import { DownloadIcon } from 'components/Icons';
import { NotificationManager } from 'components/Notification';
import useAsync from 'hooks/useAsync';
import usePermission from 'hooks/usePermission';
import { ASYNC_STATUS } from 'libs/constants';
import { MODULE_ORDER_RIGHTS } from 'libs/constants/modulerights';
import { isValidArray } from 'libs/utils/array';
import { downloadPDF, getInvoices } from 'libs/utils/PDFHandler';
import { isStringNotEmpty } from 'libs/utils/stringUltis';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import DownloadModal from '../DownloadModal';
import styles from './DownloadButton.module.scss';

function DownloadButton({
  orderNumber,
  orderDate,
  referenceNumber,
  orderStatus,
  invoiceNumbers,
  button,
}) {
  const [showModal, setShowModal] = useState(false);
  const { hasPermission: hasDownloadInvoicePermission } = usePermission(
    MODULE_ORDER_RIGHTS.DOWNLOAD_INVOICE
  );

  const { execute, status, value } = useAsync(
    () => getInvoices(invoiceNumbers),
    false
  );

  const isLoading = status === ASYNC_STATUS.PENDING;

  useEffect(() => {
    if (status === ASYNC_STATUS.SUCCESS) {
      const { fileName, file } = isValidArray(value?.data)
        ? value?.data[0]
        : {};

      if (isStringNotEmpty(fileName) && isStringNotEmpty(file)) {
        downloadPDF({ fileName, file });
      } else {
        NotificationManager.error({
          message: 'notification.error.downloadInvoice',
        });
      }
    } else if (status === 'error') {
      NotificationManager.error({
        message: 'notification.error.downloadInvoice',
      });
    }
  }, [status, value]);

  const onClick = () => {
    if (invoiceNumbers.length === 1 && hasDownloadInvoicePermission) {
      execute();
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      {(button &&
        React.cloneElement(button, { onClick, loading: isLoading })) || (
        <button className={styles.button} type="button" onClick={onClick}>
          {isLoading ? <LoadingOutlined /> : <DownloadIcon />}
        </button>
      )}
      {showModal && (
        <DownloadModal
          showModal={showModal}
          setShowModal={setShowModal}
          orderNumber={orderNumber}
          orderDate={orderDate}
          referenceNumber={referenceNumber}
          orderStatus={orderStatus}
          hasDownloadInvoicePermission={hasDownloadInvoicePermission}
        />
      )}
    </>
  );
}

DownloadButton.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  orderDate: PropTypes.string,
  referenceNumber: PropTypes.string,
  orderStatus: PropTypes.string,
  invoiceNumbers: PropTypes.arrayOf(PropTypes.string),
  button: PropTypes.node,
};

DownloadButton.defaultProps = {
  orderDate: '',
  referenceNumber: '',
  orderStatus: '',
  invoiceNumbers: [],
  button: undefined,
};

export default DownloadButton;
