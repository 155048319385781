import {
  CaretDownOutlined,
  CaretUpOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import B2becTranslation from 'components/B2becTranslation';
import B2BLink from 'components/B2BLink';
import FormatPrice from 'components/FormatPrice';
import FormatStatus from 'components/FormatStatus';
import { ReorderIcon } from 'components/Icons';
import usePermission from 'hooks/usePermission';
import {
  MODULE_ORDER_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
} from 'libs/constants/modulerights';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import { isStringNotEmpty } from 'libs/utils/stringUltis';
import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { hasEmptyElements, isValidArray } from '../../../../libs/utils/array';
import DownloadButton from '../../components/DownloadButton';
import styles from './OrdersHistoryTable.module.scss';

const RenderCell = React.memo(function RenderCell({
  hasActions,
  children,
  ...props
}) {
  if (!hasActions) {
    return children;
  }
  return <B2BLink {...props}>{children}</B2BLink>;
});

const useRenderOrderColumns = ({
  isLoading,
  hasActions,
  currentPage,
  reorderIds,
  handleReorder,
  handleAnalyzeLinkName,
}) => {
  const {
    verifiedPermissions: [
      hasDownloadInvoicePermission,
      hasReorderPermission,
      canViewCart,
    ],
  } = usePermission([
    MODULE_ORDER_RIGHTS.DOWNLOAD_INVOICE,
    MODULE_ORDER_RIGHTS.REORDER_HISTORIC_ORDER,
    MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_CART,
  ]);

  return useMemo(() => {
    const columns = [
      {
        title: ({ sortOrder }) => {
          const ArrowIcon =
            sortOrder === 'ascend' ? CaretUpOutlined : CaretDownOutlined;
          return (
            <span>
              <B2becTranslation value="orders.history.orderDate" />
              <ArrowIcon style={{ marginLeft: 4 }} />
            </span>
          );
        },
        dataIndex: 'orderDate',
        render: (_, { salesOrderNumber, orderDate }) =>
          isLoading ? (
            <Skeleton />
          ) : (
            <RenderCell
              to={`/orders/${salesOrderNumber}`}
              onClick={() => handleAnalyzeLinkName(salesOrderNumber)}
              hasActions={hasActions}
              state={{ currentPage }}
            >
              {formatDate(orderDate, getFormatType().default) || (
                <B2becTranslation value="unavailableDate" />
              )}
            </RenderCell>
          ),
        sorter: (a, b) => new Date(a.orderDate) - new Date(b.orderDate),
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: <B2becTranslation value="orders.history.orderNR" />,
        dataIndex: 'salesOrderNumber',
        render: (text) =>
          isLoading ? (
            <Skeleton />
          ) : (
            <RenderCell
              to={`/orders/${text}`}
              onClick={() => handleAnalyzeLinkName(text)}
              hasActions={hasActions}
              state={{ currentPage }}
            >
              <strong className={styles.saleOrderNumber}>{text}</strong>
            </RenderCell>
          ),
      },
      {
        title: <B2becTranslation value="orders.history.referenceNR" />,
        dataIndex: 'referenceNumber',
        render: (text, { salesOrderNumber }) =>
          isLoading ? (
            <Skeleton />
          ) : (
            <RenderCell
              to={`/orders/${salesOrderNumber}`}
              onClick={() => handleAnalyzeLinkName(salesOrderNumber)}
              hasActions={hasActions}
              state={{ currentPage }}
            >
              <span className={styles.text}>{text}</span>
            </RenderCell>
          ),
      },
      {
        title: <B2becTranslation value="status" />,
        dataIndex: 'status',
        render: (text, { salesOrderNumber }) =>
          isLoading ? (
            <Skeleton />
          ) : (
            <RenderCell
              to={`/orders/${salesOrderNumber}`}
              onClick={() => handleAnalyzeLinkName(salesOrderNumber)}
              hasActions={hasActions}
              state={{ currentPage }}
            >
              <FormatStatus className={styles.text} status={text} />
            </RenderCell>
          ),
      },
      {
        title: <B2becTranslation value="total" />,
        dataIndex: 'totalPrice',
        align: 'right',
        render: (_, { salesOrderNumber, totalPrice, currencyUnit }) => {
          return isLoading ? (
            <Skeleton />
          ) : (
            <RenderCell
              to={`/orders/${salesOrderNumber}`}
              onClick={() => handleAnalyzeLinkName(salesOrderNumber)}
              hasActions={hasActions}
              state={{ currentPage }}
            >
              <FormatPrice
                className={styles.total}
                currencyUnit={currencyUnit}
                price={totalPrice}
                wrappedBy="strong"
              />
            </RenderCell>
          );
        },
      },
    ];

    if (hasActions) {
      if (hasDownloadInvoicePermission) {
        columns.push({
          title: <B2becTranslation value="downloadInvoice" />,
          align: 'right',
          render: (
            _,
            {
              salesOrderNumber,
              orderDate,
              referenceNumber,
              status,
              invoicesNumbers,
            }
          ) => {
            const shouldShowDownloadButton =
              isStringNotEmpty(salesOrderNumber) &&
              isValidArray(invoicesNumbers) &&
              !hasEmptyElements(invoicesNumbers) &&
              hasDownloadInvoicePermission;

            return isLoading ? (
              <Skeleton />
            ) : (
              shouldShowDownloadButton && (
                <div className={styles.actionWrapper}>
                  <DownloadButton
                    orderNumber={salesOrderNumber}
                    orderDate={orderDate}
                    referenceNumber={referenceNumber}
                    orderStatus={status}
                    invoiceNumbers={invoicesNumbers}
                  />
                </div>
              )
            );
          },
        });
      }

      if (hasReorderPermission) {
        columns.push({
          title: <B2becTranslation value="orders.history.reorder" />,
          dataIndex: 'orderNumber',
          align: 'right',
          width: '10%',
          render: (_, { salesOrderNumber }) => {
            if (!hasReorderPermission) return null;

            return isLoading ? (
              <Skeleton />
            ) : (
              <div className={styles.actionWrapper}>
                <button
                  className="button-as-link"
                  type="button"
                  onClick={() => {
                    handleReorder(salesOrderNumber, canViewCart);
                  }}
                >
                  {reorderIds[salesOrderNumber] ? (
                    <LoadingOutlined />
                  ) : (
                    <ReorderIcon />
                  )}
                </button>
              </div>
            );
          },
        });
      }
    }

    return columns;
  }, [
    currentPage,
    hasActions,
    hasDownloadInvoicePermission,
    hasReorderPermission,
    canViewCart,
    isLoading,
    reorderIds,
    handleAnalyzeLinkName,
    handleReorder,
  ]);
};

export default useRenderOrderColumns;
