import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const CUSTOMER_INFO = '/v1.0/customer/customer';
const CUSTOMER_CHANGE_INVOICE = '/v1.0/customer/contact/invoice-address-change';
const CUSTOMER_CHANGE_DELIVERY =
  '/v1.0/customer/contact/delivery-address-change';
const CUSTOMER_ADD_DELIVERY = '/v1.0/customer/contact/delivery-address-add';
const CUSTOMER_DELETE_DELIVERY =
  '/v1.0/customer/contact/delivery-address-delete';
const CUSTOMER_ENQUIRY_PRODUCT = '/v1.0/customer/contact/product-enquiry';
const SUBMIT_REQUEST_INFORMATION = 'v1.0/customer/contact/request-information';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const fapiCustomerService = {
  getCustomerInfo() {
    return fapiClient.get(CUSTOMER_INFO);
  },

  submitInvoiceChange(body) {
    return fapiClient.post(CUSTOMER_CHANGE_INVOICE, {
      body,
    });
  },

  changeDeliveryAddress(body) {
    return fapiClient.post(CUSTOMER_CHANGE_DELIVERY, {
      body,
    });
  },

  addDeliveryAddress(body) {
    return fapiClient.post(CUSTOMER_ADD_DELIVERY, {
      body,
    });
  },

  deleteDeliveryAddress(body) {
    return fapiClient.post(CUSTOMER_DELETE_DELIVERY, {
      body,
    });
  },

  submitProductEnquiry(body) {
    return fapiClient.post(`${CUSTOMER_ENQUIRY_PRODUCT}`, {
      body,
    });
  },

  submitRequestInformation(body) {
    return fapiClient.post(SUBMIT_REQUEST_INFORMATION, {
      body,
    });
  },
};
