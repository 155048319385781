export default {
  enableReduxDevTool: false,
  b2bDomain: 'https://business.kaercher.com',
  loginUrl: 'https://auth.kaercher.com',
  baseUrl: 'https://api.business.kaercher.com',
  shopFAPIUrl: 'https://shop-fapi.api.business.kaercher.com/api',
  adminFAPIUrl: 'https://admin-fapi.api.business.kaercher.com/api',
  serviceFAPIUrl: 'https://service-fapi.api.business.kaercher.com/api',
  aws: {
    cognito: {
      userPoolId: 'eu-central-1_8bPaMHJGc',
      oauthDomain: 'ak-b2bec-prod-users.auth.eu-central-1.amazoncognito.com',
      clientId: '13s220ib05otb6b34jva6b6i56',
      region: 'eu-central-1',
      identityProvider: {
        customers: 'b2b-ec-customer',
        employees: 'b2b-ec-employee',
      },
    },
  },
};
