import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

const ServiceBlockWrapper = (props) => {
  const { title, children } = props;
  return (
    <div className="service-information">
      <h3 className="service-information__header">{title}</h3>
      <div className="service-information__content">{children}</div>
    </div>
  );
};

ServiceBlockWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default ServiceBlockWrapper;
