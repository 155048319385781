import './styles.scss';

import { FileTextOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CollapsedRadio from '../../../../components/CollapsedRadio';

export default function PaymentMethod() {
  const { t } = useTranslation();

  const dataSrc = [
    {
      label: t('checkout.step2.invoice'),
      icon: <FileTextOutlined />,
      value: 'invoice',
      children: null,
    },
  ];

  return (
    <Row className="payment-method__wrapper checkout-step1-section__wrapper">
      <Col xl={5} lg={5} md={4} sm={24} xs={24}>
        <h3>{t('checkout.step2.paymentMethod')}</h3>
      </Col>
      <Col xl={13} lg={13} md={20} sm={24} xs={24}>
        <CollapsedRadio
          dataSrc={dataSrc}
          expandIcon
          formName="paymentMethod"
          initialValue={dataSrc[0].value}
          className="custom-form-item"
        />
      </Col>
    </Row>
  );
}
