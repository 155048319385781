import { Carousel, Empty } from 'antd';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CarouselArrows from '../../../components/CarouselArrows';
import BREAKPOINTS from '../../../libs/constants/breakpoints';

function ProductCarousel(props) {
  const { t } = useTranslation();
  const { className, style, dataSrc } = props;
  const deviceW = window.innerWidth;

  const imageBaseOnScreenWidth = useCallback(
    (arrData) => {
      let type = 'small';

      if (deviceW >= BREAKPOINTS.xl) {
        type = 'full';
      } else if (deviceW >= BREAKPOINTS.lg) {
        type = 'large';
      } else if (deviceW >= BREAKPOINTS.md) {
        type = 'medium';
      }

      const selectedImage = arrData.filter((item) => item.type === type);

      return selectedImage[0].url;
    },
    [deviceW]
  );

  const renderSliderContent = (arr) => {
    return arr.map((item) => (
      <div
        className="product-details__carousel__container__item"
        key={item.title}
      >
        <img
          src={imageBaseOnScreenWidth(item.urls)}
          alt={`${item.title}-${item.type}`}
        />
      </div>
    ));
  };

  return (
    <div
      className={`product-details__carousel ${className}`}
      style={{ ...style }}
    >
      <Carousel
        infinite
        prevArrow={<CarouselArrows ArrowIcon={ChevronLeftIcon} />}
        nextArrow={<CarouselArrows ArrowIcon={ChevronRightIcon} />}
        arrows
        fade
        speed={1000}
        dots={{ className: 'product-details__carousel__list' }}
        className="product-details__carousel__container"
      >
        {Array.isArray(dataSrc) && dataSrc.length > 0 ? (
          renderSliderContent(dataSrc)
        ) : (
          <Empty description={t('noData')} />
        )}
      </Carousel>
    </div>
  );
}

ProductCarousel.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  dataSrc: PropTypes.instanceOf(Array),
};

ProductCarousel.defaultProps = {
  className: '',
  style: {},
  dataSrc: [],
};

export default ProductCarousel;
