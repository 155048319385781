import B2becButton from 'components/B2becButton';
import B2becCustomSelect from 'components/B2becCustomSelect';
import B2becInfoLayout from 'components/B2becInfoLayout';
import B2bSelectSalesOrg from 'components/B2bUserForm/B2bSelectSalesOrg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserCountryId } from 'store/selectors/userSelector';

import { SearchAutocomplete } from './SearchAutocomplete';
import styles from './SupportArea.module.scss';

export default function Header({ onRun, isSynchronizing }) {
  const { t } = useTranslation();
  const options = [
    { value: '', label: t('supportArea.functionLabels.chooseAFunction') },
    { value: 'orders', label: t('supportArea.functionLabels.orders') },
    {
      value: 'orderDetails',
      label: t('supportArea.functionLabels.orderDetails'),
    },
    { value: 'cart', label: t('supportArea.functionLabels.cart') },
    { value: 'deleteCart', label: t('supportArea.functionLabels.deleteCart') },
    { value: 'addToCart', label: t('supportArea.functionLabels.addToCart') },
    { value: 'machines', label: t('supportArea.functionLabels.machines') },
    {
      value: 'synchronizeOrders',
      label: t('supportArea.functionLabels.synchronizeOrders'),
    },
    {
      value: 'cache',
      label: t('Clear Cache'),
    },
  ];
  const [selectedFunction, setSelectedFunction] = useState(options[0].value);
  const [selectedOption, setSelectedOption] = useState({});
  const countryId = useSelector(selectUserCountryId);
  const canRun =
    [selectedFunction, selectedOption.customerNumber].every(Boolean) ||
    (selectedFunction === 'cache' && selectedOption.countryId);

  const handleSelectOption = (value) => {
    onRun({ option: value });
    setSelectedFunction(value);
  };

  return (
    <B2becInfoLayout.Content className={styles.contentWrapper}>
      <div className={styles.selectWrapper}>
        <p>{t('supportArea.functionality')}</p>
        <B2becCustomSelect
          className={styles.selectBox}
          options={options}
          value={selectedFunction}
          onChange={handleSelectOption}
          getPopupContainer={(trigger) => trigger}
        />
      </div>
      <div className={styles.searchWrapper}>
        {selectedFunction === 'cache' ? (
          <B2bSelectSalesOrg
            style={{ width: '100%' }}
            onChange={(salesOrg) => setSelectedOption({ countryId: salesOrg })}
          />
        ) : (
          <SearchAutocomplete
            countryId={countryId}
            onChange={(_, data) => {
              setSelectedOption(data.data);
            }}
          />
        )}
      </div>
      <div className={styles.runWrapper}>
        <B2becButton
          className={styles.button}
          onClick={() => {
            onRun({
              option: selectedFunction,
              ...selectedOption,
            });
          }}
          disabled={!canRun}
          loading={isSynchronizing}
        >
          {t('buttonTexts.run')}
        </B2becButton>
      </div>
    </B2becInfoLayout.Content>
  );
}

Header.propTypes = {
  onRun: PropTypes.func.isRequired,
};
