import { BillingAddress } from 'components/DisplayAddress';
import { useCheckoutContext } from 'contexts/checkout-context';
import React from 'react';

const CustomerBillingAddress = () => {
  const { billingInfo } = useCheckoutContext();

  return (
    <div className="checkout-step1-section__wrapper mt-4 mb-4">
      <BillingAddress data={billingInfo} />
    </div>
  );
};

export default CustomerBillingAddress;
