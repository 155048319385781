import { NotificationManager } from 'components/Notification';
import { getSessionCookieKey } from 'contexts/punchout-context';
import { SESSION_BASED_EXPIRY_TIME } from 'libs/constants';
import { TRACK_DATA } from 'libs/constants/adobeAnalytics';
import { logErrors } from 'libs/utils/common';
import { getCookie, renewCookie } from 'libs/utils/cookies';
import { linkGenerator } from 'libs/utils/language';
import {
  all,
  debounce,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { cartService, orderServiceFAPI } from 'services';
import { selectHasSessionBasedShoppingCart } from 'store/selectors/userSelector';
import { trackCart, updateTrackStatus } from 'store/slices/adobeAnalyticSlice';

import {
  addProductToCart,
  addProductToCartError,
  changeCartProductReference,
  emptyCart,
  emptyCartFailed,
  emptyCartSucceeded,
  getCartItems,
  getCartItemsAfterRedeemingVoucher,
  getCartItemsAfterRedeemingVoucherError,
  getCartItemsAfterRedeemingVoucherSucess,
  getCartItemsError,
  getCartItemsSuccess,
  getMiniCart,
  getMiniCartError,
  getMiniCartSuccess,
  getPriceBoxData,
  getPriceBoxDataError,
  getPriceBoxDataSuccess,
  increaseCartItemQuantity,
  increaseCartItemQuantityError,
  increaseCartItemQuantitySuccess,
  removeCartItem,
  removeProductAddedFromQuickEntry,
  removeVoucherFromCart,
  removeVoucherFromCartError,
  removeVoucherFromCartSuccess,
  reorder,
  reorderFailed,
  reorderSucceeded,
  replaceCartItems,
  replaceCartItemsError,
  setCartItemQuantity,
  setCartItemQuantityError,
  setFetchCartStateToIdle,
  updateProductInCart,
} from '../slices/cartSlice';
import mapCartData from './apiDataMapping/cartMapping';

const TIMEOUT_TO_REFETCH_CART = 280;

export function* renewShoppingCartSession() {
  const hasSessionBasedShoppingCart = yield select(
    selectHasSessionBasedShoppingCart
  );

  if (hasSessionBasedShoppingCart) {
    renewCookie(getSessionCookieKey(), SESSION_BASED_EXPIRY_TIME);
  }
}

export function* modifyPayloadIfHavingSessionBasedShoppingCart(payload = {}) {
  const hasSessionBasedShoppingCart = yield select(
    selectHasSessionBasedShoppingCart
  );

  if (hasSessionBasedShoppingCart) {
    const sessionId = getCookie(getSessionCookieKey());

    if (!sessionId) {
      yield renewShoppingCartSession();
    }

    return {
      ...payload,
      sessionId: getCookie(getSessionCookieKey()),
    };
  }

  return payload;
}

function* getCartItemsSaga() {
  try {
    const payload = yield modifyPayloadIfHavingSessionBasedShoppingCart();
    const { data } = yield orderServiceFAPI.getShoppingCart(payload);
    const mappedCartData = mapCartData(data);

    yield put(getCartItemsSuccess(mappedCartData));
    yield put(trackCart({ [TRACK_DATA.CART]: mappedCartData.items }));
    yield renewShoppingCartSession();
  } catch (err) {
    console.error(err);
    yield put(updateTrackStatus({ trackSection: TRACK_DATA.CART }));
    yield put(getCartItemsError());
    NotificationManager.error({
      message: 'notification.error.load',
    });
  }
}

function* getMiniCartDataSaga() {
  try {
    const payload = yield modifyPayloadIfHavingSessionBasedShoppingCart();
    const {
      data: { items },
    } = yield cartService.getMiniCart(payload);

    yield put(getMiniCartSuccess(items));
    yield put(trackCart({ [TRACK_DATA.MINICART]: items }));
  } catch (error) {
    console.error('error when fetching mini cart data ', error?.message);
    yield put(updateTrackStatus({ trackSection: TRACK_DATA.MINICART }));
    yield put(getMiniCartError());
    NotificationManager.error({
      message: 'notification.error.miniCart',
    });
  }
}

function* updateProductInCartSaga({ payload }) {
  try {
    const modifiedPayload = yield modifyPayloadIfHavingSessionBasedShoppingCart(
      payload
    );

    yield cartService.updateProductInCart(modifiedPayload);
    yield put(
      updateTrackStatus({
        trackSection: TRACK_DATA.CART,
        trackStatus: false,
      })
    );
    yield put(getCartItems());
    yield renewShoppingCartSession();
  } catch (error) {
    yield put(setCartItemQuantityError());
    NotificationManager.error({
      message: 'notification.error.changeCartItemQuantity',
    });
  }
}

function* setCartItemQuantitySaga({ payload }) {
  try {
    const modifiedPayload = yield modifyPayloadIfHavingSessionBasedShoppingCart(
      payload
    );

    yield cartService.updateProductInCart(modifiedPayload);
    yield put(
      updateTrackStatus({
        trackSection: TRACK_DATA.CART,
        trackStatus: false,
      })
    );
    yield put(getCartItems());
    yield renewShoppingCartSession();
  } catch (error) {
    yield put(setCartItemQuantityError());
    NotificationManager.error({
      message: 'notification.error.changeCartItemQuantity',
    });
  }
}

function* addProductToCartSaga({ payload }) {
  try {
    const modifiedPayload = yield modifyPayloadIfHavingSessionBasedShoppingCart(
      payload
    );

    yield cartService.addProductToCart(modifiedPayload);
    yield renewShoppingCartSession();
    yield put(getCartItems());
    NotificationManager.success({
      message: 'notification.success.addCart',
    });
  } catch (err) {
    console.error(err);
    yield put(addProductToCartError());
    NotificationManager.error({
      message: 'notification.error.addCart',
    });
  }
}

function* changeCartProductReferenceSaga({ payload }) {
  try {
    yield cartService.updateProductInCart(payload);
    yield put(getCartItems());
  } catch (err) {
    NotificationManager.error({
      message: 'notification.error.changeCartItemProductReference',
    });
  }
}

function* increaseCartItemQuantitySaga({ payload }) {
  try {
    const modifiedPayload = yield modifyPayloadIfHavingSessionBasedShoppingCart(
      payload
    );

    const { data } = yield cartService.updateProductInCart(modifiedPayload);

    yield renewShoppingCartSession();
    yield put(increaseCartItemQuantitySuccess(data));
    NotificationManager.success({
      message: 'notification.success.addCart',
    });
  } catch (err) {
    yield put(increaseCartItemQuantityError(err));
    NotificationManager.error({
      message: 'notification.error.addCart',
    });
  }
}

function* getPriceBoxDataSaga({ payload }) {
  try {
    const { materialNumber } = payload;
    const { data } = yield orderServiceFAPI.getPriceBoxData(materialNumber);

    yield put(getPriceBoxDataSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(getPriceBoxDataError());
  }
}

function* removeProductFromCartSaga({ payload }) {
  if (typeof payload === 'string') {
    try {
      const modifiedPayload =
        yield modifyPayloadIfHavingSessionBasedShoppingCart({
          materialNumber: payload,
        });

      yield cartService.removeProductFromCart(modifiedPayload);
      yield put(getCartItems());
    } catch (err) {
      yield put(removeCartItem());
      yield NotificationManager.error({
        message: 'notification.error.delete',
      });
    }
  } else {
    yield put(removeProductAddedFromQuickEntry());
  }

  yield renewShoppingCartSession();
}

function* emptyCartSaga() {
  try {
    const payload = yield modifyPayloadIfHavingSessionBasedShoppingCart();

    yield cartService.emptyCart(payload);
    yield put(emptyCartSucceeded());
    yield renewShoppingCartSession();
    NotificationManager.success({
      message: 'notification.success.submit',
    });
  } catch (err) {
    yield put(emptyCartFailed());
    logErrors(err);
    NotificationManager.error({
      message: 'notification.error.submit',
    });
  }
}

function* removeVoucherFromCartSaga() {
  try {
    const payload = yield modifyPayloadIfHavingSessionBasedShoppingCart();
    yield cartService.removeVoucher(payload);
    yield put(removeVoucherFromCartSuccess());
    yield renewShoppingCartSession();
    yield put(getCartItems());

    NotificationManager.success({
      message: 'notification.success.removeVoucher',
    });
  } catch (error) {
    console.error('error when removing voucher code ', error);
    const currentVoucher = yield select(
      (state) => state.cart.cartData.voucherValue?.code
    );

    yield put(removeVoucherFromCartError(currentVoucher));

    NotificationManager.error({
      message: 'notification.error.removeVoucher',
      description: 'notification.error.somethingWentWrong',
    });
  }
}

function* replaceCartItemsSaga({ payload }) {
  try {
    yield cartService.replaceProductInCart(payload);
    yield renewShoppingCartSession();
    yield put(getCartItems());
  } catch (error) {
    yield put(replaceCartItemsError);
    NotificationManager.error({
      message: 'notification.error.replaceCartProduct',
      description: error?.response?.data,
    });
  }
}

function* getCartItemsAfterRedeemingVoucherSaga() {
  try {
    yield put(
      updateTrackStatus({
        trackSection: TRACK_DATA.CART,
        trackStatus: false,
      })
    );
    const payload = yield modifyPayloadIfHavingSessionBasedShoppingCart();
    const { data } = yield orderServiceFAPI.getShoppingCart(payload);
    const mappedCartData = mapCartData(data);

    yield put(getCartItemsAfterRedeemingVoucherSucess(mappedCartData));
    yield put(trackCart({ [TRACK_DATA.CART]: mappedCartData.items }));
    yield renewShoppingCartSession();
  } catch (err) {
    yield put(getCartItemsAfterRedeemingVoucherError());
    NotificationManager.error({
      message: 'notification.error.load',
    });
  }
}

function* requestReorderSaga({ payload }) {
  const { history } = yield getContext('dependencies');
  const { salesOrderNumber, canViewCart } = payload;

  try {
    const modifiedPayload = yield modifyPayloadIfHavingSessionBasedShoppingCart(
      { orderNumber: salesOrderNumber }
    );

    yield cartService.reorder(modifiedPayload);
    yield all([
      put(reorderSucceeded(salesOrderNumber)),
      put(setFetchCartStateToIdle()),
    ]);

    NotificationManager.success({
      message: 'notification.success.reOrder',
    });

    if (canViewCart) {
      history.push(linkGenerator('/cart'));
    }
  } catch (error) {
    console.error('error when reordering ', error);
    yield put(reorderFailed(salesOrderNumber));

    NotificationManager.error({
      message: 'notification.error.reOrder',
      description: 'notification.error.somethingWentWrong',
    });

    history.push(linkGenerator('/orders'));
  }
}

export default function* cartSaga() {
  yield takeLatest(getMiniCart.type.toString(), getMiniCartDataSaga);
  yield takeLatest(getCartItems.type.toString(), getCartItemsSaga);
  yield takeLatest(
    getCartItemsAfterRedeemingVoucher.type.toString(),
    getCartItemsAfterRedeemingVoucherSaga
  );
  yield debounce(
    TIMEOUT_TO_REFETCH_CART,
    increaseCartItemQuantity.type.toString(),
    increaseCartItemQuantitySaga
  );
  yield takeLatest(getPriceBoxData.type.toString(), getPriceBoxDataSaga);
  yield takeLatest(addProductToCart.type.toString(), addProductToCartSaga);
  yield takeLatest(
    setCartItemQuantity.type.toString(),
    setCartItemQuantitySaga
  );
  yield takeLatest(removeCartItem.type.toString(), removeProductFromCartSaga);
  yield takeLatest(emptyCart.type.toString(), emptyCartSaga);
  yield takeLatest(
    changeCartProductReference.type.toString(),
    changeCartProductReferenceSaga
  );
  yield takeLatest(removeVoucherFromCart.type, removeVoucherFromCartSaga);
  yield takeLatest(replaceCartItems.type.toString(), replaceCartItemsSaga);
  yield takeLatest(
    updateProductInCart.type.toString(),
    updateProductInCartSaga
  );
  yield takeLatest(reorder.type.toString(), requestReorderSaga);
}
