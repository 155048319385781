import { UserOutlined } from '@ant-design/icons';
import { Avatar, Table } from 'antd';
import B2becTable from 'components/B2becTable';
import FormatPrice from 'components/FormatPrice';
import FormatStatus from 'components/FormatStatus';
import { useDummyItems } from 'hooks';
import { formatQuantity } from 'libs/utils/handleNumber';
import CartItemName from 'pages/Cart/CartItemName';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import OrderDetailsCartSummary from './components/CartTable/OrderDetailsCartSummary';
import styles from './OrderDetailsTable.module.scss';

const OrderDetailsTable = (props) => {
  const { orderItems, isLoading, onRow } = props;
  const { t } = useTranslation();
  const fakeItems = useDummyItems();

  const currencyUnit = orderItems?.currencyUnit;
  const items = orderItems?.items;

  const dataSource = useMemo(
    () => (isLoading ? fakeItems : items),
    [fakeItems, isLoading, items]
  );

  const shouldShowRecyclingFee =
    !!orderItems?.checkoutSettings?.positionLvlConditions;

  return (
    <div className={styles.orderDetailsTable}>
      <B2becTable
        dataSource={dataSource}
        rowKey="materialNumber"
        onRow={onRow}
        summary={() =>
          orderItems && <OrderDetailsCartSummary {...orderItems} />
        }
      >
        <Table.Column
          dataIndex="imgUrl"
          width="10%"
          render={(imgUrl) =>
            isLoading ? (
              <Skeleton />
            ) : imgUrl ? (
              <Avatar size={64} shape="square" src={imgUrl} />
            ) : (
              <Avatar size={64} shape="square" icon={<UserOutlined />} />
            )
          }
        />
        <Table.Column
          width="15%"
          title={t('cart.table.itemNo')}
          dataIndex="materialNumber"
          render={(text) => (isLoading ? <Skeleton /> : <span>{text}</span>)}
        />
        <Table.Column
          title={t('cart.table.itemName')}
          dataIndex="name"
          render={(_, record) => {
            return isLoading ? (
              <Skeleton />
            ) : (
              <>
                <CartItemName
                  data={{ ...record, currencyUnit }}
                  shouldShowRecyclingFee={shouldShowRecyclingFee}
                />
                <FormatStatus status={record?.status} />
                {!record?.isAvailable ? (
                  <p className="unavailable-product__text">
                    {t('errors.unavailableProductDetails')}
                  </p>
                ) : null}
              </>
            );
          }}
        />
        <Table.Column
          width="13%"
          title={t('cart.table.quantity')}
          dataIndex="quantity"
          align="center"
          render={(text) =>
            isLoading ? <Skeleton /> : <span>{formatQuantity(text)}</span>
          }
        />
        <Table.Column
          width="15%"
          title={t('cart.table.individualPrice')}
          dataIndex="price"
          align="right"
          render={(text) =>
            isLoading ? (
              <Skeleton />
            ) : (
              <FormatPrice price={text} currencyUnit={currencyUnit} />
            )
          }
        />
        <Table.Column
          width="15%"
          title={t('total')}
          dataIndex="total"
          align="right"
          render={(text) =>
            isLoading ? (
              <Skeleton />
            ) : (
              <FormatPrice
                price={text}
                currencyUnit={currencyUnit}
                className="text--bold"
              />
            )
          }
        />
      </B2becTable>
    </div>
  );
};

export default OrderDetailsTable;
