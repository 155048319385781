import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

const DATA_KEYS = {
  technical: 'technicalsByName',
  equipment: 'equipmentsByName',
};

const ROW_KEYS = {
  technical: 'allTechnicals',
  equipment: 'allEquipments',
};

const getAllRows = (type) => {
  const key = ROW_KEYS[type];

  if (!key) {
    return () => [];
  }

  return (products = []) => {
    return products.reduce((result, { [key]: rows }) => {
      const filteredProducts = [...result, ...rows];

      return [...new Set(filteredProducts)];
    }, []);
  };
};

const ComparisonBlock = (props) => {
  const { t } = useTranslation();
  const { type = 'technical', listProduct = {}, isLoading, isLoaded } = props;
  const listProductIds = Object.keys(listProduct);
  const key = DATA_KEYS[type];

  const allRows = getAllRows(type)(Object.values(listProduct));

  const isEmpty = allRows.length === 0;

  const isNotFound = isLoaded && isEmpty;

  return (
    <>
      <tr>
        <td colSpan="4" className="product-comparison-table__header">
          <h4 className="font-weight-bold">{t(`productComparison.${type}`)}</h4>
        </td>
      </tr>
      {isLoading ? (
        <tr>
          <td>
            <Skeleton />
          </td>
          {listProductIds.map((productId) => (
            <td key={`skeleton-${productId}`}>
              <Skeleton />
            </td>
          ))}
        </tr>
      ) : null}
      {isNotFound ? (
        <tr>
          <td>{t('productComparison.notFound')}</td>
        </tr>
      ) : null}
      {allRows.map((dataKey) => {
        return (
          <tr key={dataKey}>
            <td>{dataKey}</td>
            {listProductIds.map((productId) => {
              const productData = listProduct[productId];

              let label = '————';

              if (productData && productData[key]) {
                const value = productData[key][dataKey];

                if (value) {
                  label =
                    value === true ? <span className="green-tick" /> : value;
                }
              }
              return (
                <td
                  className="font-weight-medium"
                  key={`${productId}-${dataKey}`}
                >
                  {label}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

ComparisonBlock.defaultProps = {
  isLoading: true,
  isLoaded: false,
};

ComparisonBlock.propTypes = {
  type: PropTypes.oneOf(Object.keys(DATA_KEYS)).isRequired,
  listProduct: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
};

export default React.memo(ComparisonBlock);
