import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const adminFAPIClient = new HttpClient(environment.adminFAPIUrl);

const RIGHT_CLUSTER_ENDPOINT = '/v1.0/rightcluster';

export const fapiRightClusterService = {
  getList() {
    return adminFAPIClient.get(`${RIGHT_CLUSTER_ENDPOINT}/list`);
  },
  create(data) {
    return adminFAPIClient.post(RIGHT_CLUSTER_ENDPOINT, {
      body: data,
    });
  },
};
