import B2becCustomSelect from 'components/B2becCustomSelect';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAvailableLanguages } from 'store/selectors/userSelector';

const B2bSelectLanguage = (props) => {
  const options = useSelector(selectAvailableLanguages);
  return <B2becCustomSelect options={options} {...props} />;
};

export default B2bSelectLanguage;
