import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

function Tag(props) {
  const { isLoading, name } = props;
  return (
    <div className="tag__wrapper">
      <div className="tag__arrow--left" />
      <div className="tag__name">
        {isLoading ? <Skeleton width={63} /> : name}
      </div>
      <div className="tag__arrow--right" />
    </div>
  );
}

Tag.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

Tag.defaultProps = {
  isLoading: false,
};

export default Tag;
