import { createSlice } from '@reduxjs/toolkit';
import { ACTION_STATUS } from 'libs/constants';

const initialState = {
  isLoading: false,
  submitStatus: ACTION_STATUS.DEFAULT,
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    submitContactRequest(state) {
      state.isLoading = true;
    },
    submitContactRequestSuccess(state) {
      state.isLoading = false;
      state.submitStatus = ACTION_STATUS.SUCCESS;
    },
    submitContactRequestError(state) {
      state.isLoading = false;
      state.submitStatus = ACTION_STATUS.FAILED;
    },
    resetContact(state) {
      state.isLoading = false;
      state.submitStatus = ACTION_STATUS.DEFAULT;
    },
  },
});

export const contactActions = contactSlice.actions;

export default contactSlice.reducer;
