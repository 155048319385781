import './styles.scss';

import { ChevronRightIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

function CategoryItem(props) {
  const {
    loading,
    id,
    name,
    subCats,
    shouldShowSubCatsDrawer,
    shouldShowAccsDetSubCatsDrawer,
    shouldShowAccsDetSubSubCatsDrawer,
    onClick,
  } = props;

  const onItemClick = () => {
    onClick(
      id,
      name,
      subCats,
      shouldShowSubCatsDrawer,
      shouldShowAccsDetSubCatsDrawer,
      shouldShowAccsDetSubSubCatsDrawer
    );
  };

  return loading ? (
    <div className="category-item category-item--loading">
      <Skeleton />
      <ChevronRightIcon />
    </div>
  ) : (
    <button
      className="category-item__wrapper--mobile"
      type="button"
      onClick={onItemClick}
    >
      <div className="category-item">
        <span>{name}</span>
        <ChevronRightIcon />
      </div>
    </button>
  );
}

CategoryItem.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  subCats: PropTypes.arrayOf(PropTypes.shape({})),
  shouldShowSubCatsDrawer: PropTypes.bool,
  shouldShowAccsDetSubCatsDrawer: PropTypes.bool,
  shouldShowAccsDetSubSubCatsDrawer: PropTypes.bool,
  onClick: PropTypes.func,
};

CategoryItem.defaultProps = {
  loading: false,
  subCats: [],
  shouldShowSubCatsDrawer: false,
  shouldShowAccsDetSubCatsDrawer: false,
  shouldShowAccsDetSubSubCatsDrawer: false,
  onClick: () => {},
};

export default CategoryItem;
