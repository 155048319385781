import './styles.scss';

import { CalendarOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import clsx from 'clsx';
import {
  createMomentFromDateAndTime,
  formatDate,
  getFormatType,
} from 'libs/utils/formatDate';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomCalendar from './CustomCalendar';

const CustomDateRangePicker = (props) => {
  const { t } = useTranslation();
  const { fromDate, toDate, setFromDate, setToDate } = props;
  const [isFromDateCalendarShown, setIsFromDateCalendarShown] = useState(false);
  const [isToDateCalendarShown, setIsToDateCalendarShown] = useState(false);
  const fromDateCalendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const [isDateValid, setIsDateValid] = useState(true);

  const handleClickFromDateOutside = (event) => {
    if (
      fromDateCalendarRef.current &&
      !fromDateCalendarRef.current.contains(event.target)
    ) {
      setIsFromDateCalendarShown(false);
    }
  };

  const handleClickToDateOutside = (event) => {
    if (
      toDateCalendarRef.current &&
      !toDateCalendarRef.current.contains(event.target)
    ) {
      setIsToDateCalendarShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickToDateOutside, true);
    document.addEventListener('click', handleClickFromDateOutside, true);
    return () => {
      document.removeEventListener('click', handleClickToDateOutside, true);
      document.removeEventListener('click', handleClickFromDateOutside, true);
    };
  }, []);

  return (
    <>
      <span className="custom-range__title">
        {t('voucher.voucherDuration')}
      </span>
      <div
        className={clsx('custom-range__wrapper', {
          'custom-range__wrapper--focus':
            isFromDateCalendarShown || isToDateCalendarShown,
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="custom-range__from-date"
          onClick={() => {
            setIsFromDateCalendarShown(!isFromDateCalendarShown);
          }}
        >
          <div className="custom-range__from-date__input custom-range__input">
            <CalendarOutlined className="custom-range__input__icon" />
            <Input
              value={
                fromDate
                  ? formatDate(fromDate, getFormatType().dateWithDaysOfWeek)
                  : null
              }
              placeholder={t('voucher.placeholder.startDate')}
            />
          </div>
          <div
            className={clsx('custom-range__from-date__calendar', {
              'custom-range__from-date__calendar--shown':
                isFromDateCalendarShown,
            })}
            ref={fromDateCalendarRef}
          >
            <Form.Item name="fromDate">
              <CustomCalendar
                onSelect={(date) => {
                  if (
                    toDate === null ||
                    createMomentFromDateAndTime(toDate) >=
                      createMomentFromDateAndTime(date)
                  ) {
                    setFromDate(date);
                    setIsDateValid(true);
                  } else {
                    setIsDateValid(false);
                  }
                }}
                value={fromDate}
              />
            </Form.Item>
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="custom-range__to-date"
          onClick={() => {
            setIsToDateCalendarShown(!isToDateCalendarShown);
          }}
        >
          <div className="custom-range__to-date__input custom-range__input">
            <CalendarOutlined className="custom-range__input__icon" />
            <Input
              value={
                toDate
                  ? formatDate(toDate, getFormatType().dateWithDaysOfWeek)
                  : null
              }
              placeholder={t('voucher.placeholder.endDate')}
            />
          </div>
          <div
            className={clsx('custom-range__to-date__calendar', {
              'custom-range__to-date__calendar--shown': isToDateCalendarShown,
            })}
            ref={toDateCalendarRef}
          >
            <Form.Item name="toDate">
              <CustomCalendar
                onSelect={(date) => {
                  if (
                    fromDate === null ||
                    createMomentFromDateAndTime(date) >=
                      createMomentFromDateAndTime(fromDate)
                  ) {
                    setToDate(date);
                    setIsDateValid(true);
                  } else {
                    setIsDateValid(false);
                  }
                }}
                value={toDate}
              />
            </Form.Item>
          </div>
        </div>
      </div>
      {!isDateValid ? (
        <p className="custom-range__message">
          Start date must less or equal to End date
        </p>
      ) : null}
    </>
  );
};

export default CustomDateRangePicker;
