import './styles.scss';

import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';
import PropTypes from 'prop-types';
import React from 'react';

function ServiceItem({ name, onClick, path, serviceEntry }) {
  const handleItemClick = () => {
    onClick(serviceEntry, path);
  };

  return (
    <button
      type="button"
      className="service-item__wrapper"
      onClick={handleItemClick}
    >
      <div className="service-item">
        <span>{name}</span>
        <RightArrow />
      </div>
    </button>
  );
}

ServiceItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string,
  serviceEntry: PropTypes.string.isRequired,
};

ServiceItem.defaultProps = {
  onClick: () => {},
  path: '/request-services',
};

export default ServiceItem;
