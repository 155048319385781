import './styles.scss';

import { Carousel, Empty } from 'antd';
import CarouselArrows from 'components/CarouselArrows';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons';
import { NotificationManager } from 'components/Notification';
import { useAsync } from 'hooks';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { ASYNC_STATUS } from 'libs/constants';
import { USER_TYPES } from 'libs/constants/user';
import { isValidArray } from 'libs/utils/array';
import { isEmptyObject } from 'libs/utils/object';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProductService } from 'services';
import { selectUserType } from 'store/selectors/authenticationSelectors';

import SpecialItem from './components/SpecialItem';

function SpecialsForYou() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { windowWidth } = useDeviceDetect();
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  const userType = useSelector(selectUserType);

  const getPromotionProductsList = useCallback(async () => {
    const response = await ProductService.getPromotionProducts();
    return response.data;
  }, []);

  const onFetchPromotionError = useCallback((error) => {
    NotificationManager.error({
      message: 'notification.error.promotionData',
      description: error?.message,
    });
  }, []);

  const {
    value: promotionData,
    status,
    execute: fetchPromotionList,
  } = useAsync(getPromotionProductsList, false, null, onFetchPromotionError);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [windowWidth]);

  const isFetching = status === ASYNC_STATUS.PENDING;

  useEffect(() => {
    if (userType === USER_TYPES.CUSTOMER_USER) {
      fetchPromotionList();
    }
  }, [dispatch, userType, fetchPromotionList]);

  const itemToShow = useMemo(() => {
    if (promotionData) {
      const { promotionProducts } = promotionData;
      return (
        isValidArray(promotionProducts) &&
        promotionProducts.filter((item) => !isEmptyObject(item))
      );
    }
    return [];
  }, [promotionData]);

  const renderLoadingState = () => {
    return Array.from(Array(6).keys()).map((key) => (
      <SpecialItem key={key} isLoading={isFetching} />
    ));
  };

  const renderStaticItems = () => (
    <div className="specials-for-you__items__wrapper">
      <div className="specials-for-you__items__wrapper--inner">
        {itemToShow.map((item) => (
          <div key={item.materialNumber}>
            <SpecialItem
              isLoading={isFetching}
              img={{ src: item.pictureUrl, alt: item.materialName }}
              name={item.materialName}
              materialNumber={item.materialNumber}
              listPrice={item.listPrice}
              currencyUnit={item.currencyUnit}
            />
          </div>
        ))}
      </div>
    </div>
  );

  const renderCarousel = () => (
    <div className="specials-for-you__carousel">
      <Carousel
        className="specials-for-you__carousel__container"
        dots={{ className: 'specials-for-you__carousel__dots' }}
        infinite
        arrows
        prevArrow={<CarouselArrows ArrowIcon={ChevronLeftIcon} />}
        nextArrow={<CarouselArrows ArrowIcon={ChevronRightIcon} />}
        speed={1000}
        slidesToScroll={1}
        variableWidth
      >
        {isFetching
          ? renderLoadingState()
          : itemToShow.map((item) => (
              <SpecialItem
                key={item.materialNumber}
                isLoading={isFetching}
                img={{ src: item.pictureUrl, alt: item.materialName }}
                name={item.materialName}
                materialNumber={item.materialNumber}
                listPrice={item.listPrice}
                currencyUnit={item.currencyUnit}
              />
            ))}
      </Carousel>
    </div>
  );

  const renderItems = () => {
    if (isFetching) {
      return renderCarousel();
    }
    const numberOfItems = itemToShow?.length;
    if (numberOfItems > 0) {
      // each item width = 320px + 24px (padding right)
      if (containerWidth > (320 + 24) * numberOfItems) {
        return renderStaticItems();
      }
      return renderCarousel();
    }
    return (
      <div className="specials-for-you__items__wrapper">
        <Empty description={t('noData')} />
      </div>
    );
  };

  return (
    <div className="specials-for-you__wrapper" ref={containerRef}>
      <h2>{t('myWorkspace.specialsForYou.title')}</h2>
      {renderItems()}
    </div>
  );
}

export default SpecialsForYou;
