import './styles.scss';

import { Carousel, Empty } from 'antd';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import CarouselArrows from '../../../../../components/CarouselArrows';
import BREAKPOINTS from '../../../../../libs/constants/breakpoints';

const SubCategoryCarousel = ({ images, isLoading }) => {
  const deviceW = document.documentElement.clientWidth;
  const { t } = useTranslation();

  const getImageBaseOnScreenWidth = useCallback(
    (imageList) => {
      let type = 'small';

      if (deviceW >= BREAKPOINTS.xl) {
        type = 'full';
      } else if (deviceW >= BREAKPOINTS.lg) {
        type = 'large';
      } else if (deviceW >= BREAKPOINTS.md) {
        type = 'medium';
      }

      return imageList?.find((image) => image.type === type);
    },
    [deviceW]
  );

  const selectedImage = getImageBaseOnScreenWidth(images);

  return (
    <div className="full-width-page-component__wrapper">
      <div className="sub-category__carousel">
        <Carousel
          autoplay
          infinite
          nextArrow={<CarouselArrows ArrowIcon={ChevronRightIcon} />}
          prevArrow={<CarouselArrows ArrowIcon={ChevronLeftIcon} />}
          arrows
          fade
          speed={1000}
          dots={{ className: 'sub-category__carousel__list' }}
          className="sub-category__carousel__container"
        >
          {isLoading ? (
            <Skeleton height={500} />
          ) : images?.length > 0 ? (
            <img src={selectedImage.url} alt={selectedImage.type} />
          ) : (
            <Empty description={t('noData')} />
          )}
        </Carousel>
      </div>
    </div>
  );
};

SubCategoryCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

SubCategoryCarousel.defaultProps = {
  images: [],
  isLoading: false,
};

export default SubCategoryCarousel;
