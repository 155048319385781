import { isValidArray } from 'libs/utils/array';
import { put, takeLatest } from 'redux-saga/effects';
import { adminFapiPromotion } from 'services/adminFapi';
import {
  getPromotedProductList,
  getPromotedProductListError,
  getPromotedProductListSuccess,
  updatePromotionList,
  updatePromotionListError,
  updatePromotionListSuccess,
} from 'store/slices/promotionSlice';

import { NotificationManager } from '../../components/Notification';
import { DEFAULT_PROMOTION_ITEMS, STATUS_CODE } from '../../libs/constants';

function* getListPromotionProducts() {
  try {
    const response = yield adminFapiPromotion.getPromotionList();
    const { data } = response;

    const { promotionProducts: products, invalidMaterialNumbers } = data;
    const result = {
      products,
      invalidMaterialNumbers,
    };

    if (!Array.isArray(products) || products.length === 0) {
      result.products = [...DEFAULT_PROMOTION_ITEMS];
    } else if (products.length === 6) {
      result.products = products;
    } else {
      result.products = [...DEFAULT_PROMOTION_ITEMS];
      products.forEach((value, index) => {
        result.products[index] = value;
      });
    }

    if (isValidArray(invalidMaterialNumbers)) {
      result.invalidMaterialNumbers = invalidMaterialNumbers;
    } else {
      result.invalidMaterialNumbers = [];
    }

    yield put(getPromotedProductListSuccess(result));
  } catch (error) {
    yield put(getPromotedProductListError());

    NotificationManager.error({
      message: 'notification.error.promotionData',
      description: error?.message,
    });
  }
}

function* updateListPromotionProducts({ payload: materialNumbers }) {
  try {
    const response = yield adminFapiPromotion.updatePromotionList(
      materialNumbers
    );

    if (response?.status === STATUS_CODE.SUCCESS) {
      yield put(updatePromotionListSuccess());

      NotificationManager.success({ message: 'notification.success.update' });
    } else {
      yield put(updatePromotionListError());

      NotificationManager.error({
        message: 'notification.error.update',
        description: String(response?.error),
      });
    }
  } catch (error) {
    yield put(updatePromotionListError());

    NotificationManager.error({
      message: 'notification.error.update',
      description: error?.message,
    });
  }
}

export default function* promotionSaga() {
  yield takeLatest(getPromotedProductList.type, getListPromotionProducts);
  yield takeLatest(updatePromotionList.type, updateListPromotionProducts);
}
