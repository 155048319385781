import './styles.scss';

import { Col, Divider, Grid, Row } from 'antd';
import clsx from 'clsx';
import { BillingAddress, DeliveryAddress } from 'components/DisplayAddress';
import { useCheckoutContext } from 'contexts/checkout-context';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InvoiceIcon } from '../../../../assets/icons/invoice.svg';
import Article from '../Article';
import CostSummary from '../CostSummary';

function UpperSection({ form, submitFormHandler }) {
  const { t } = useTranslation();
  // Billing Address
  // Use billing address instead of customer address
  const { shouldShowServiceCode, billingInfo } = useCheckoutContext();
  const { md } = Grid.useBreakpoint();

  // Delivery Address
  const currentDeliveryAddr = form.getFieldValue('currentDeliveryAddr');
  const name =
    currentDeliveryAddr === 1
      ? form.getFieldValue('myAddrName')
      : form.getFieldValue('newAddrName');
  const name2 =
    currentDeliveryAddr === 1
      ? form.getFieldValue('myAddrName2')
      : form.getFieldValue('newAddrName2');
  const street =
    currentDeliveryAddr === 1
      ? form.getFieldValue('myAddrStreet')
      : form.getFieldValue('newAddrStreet');
  const street4 =
    currentDeliveryAddr === 1
      ? form.getFieldValue('myAddrStreet4')
      : form.getFieldValue('newAddrStreet4');
  const houseNumber =
    currentDeliveryAddr === 1
      ? form.getFieldValue('myAddrHouseNumber')
      : form.getFieldValue('newAddrHouseNumber');
  const zip =
    currentDeliveryAddr === 1
      ? form.getFieldValue('myAddrZip')
      : form.getFieldValue('newAddrZip');
  const city =
    currentDeliveryAddr === 1
      ? form.getFieldValue('myAddrCity')
      : form.getFieldValue('newAddrCity');
  const country =
    currentDeliveryAddr === 1
      ? form.getFieldValue('myAddrCountry')
      : form.getFieldValue('newAddrCountry');
  const region =
    currentDeliveryAddr === 1
      ? form.getFieldValue('myAddrRegion')
      : form.getFieldValue('newAddrRegion');

  // Service Code
  const serviceCode = form.getFieldValue('serviceCode');

  // Payment Method
  const paymentMethod = form.getFieldValue('paymentMethod');

  // Shipping
  const shippingMethod = form.getFieldValue('shippingMethod');

  // Reference Number
  const referenceNumber = form.getFieldValue('referenceNumber');

  const renderBillingAddress = () => (
    <Article
      title={t('checkout.step3.upperSection.leftPart.billingAddress')}
      classNames="fw-container"
    >
      <BillingAddress data={billingInfo} />
    </Article>
  );
  const renderDeliveryAddress = () => (
    <Article
      title={t('checkout.step3.upperSection.leftPart.deliveryAddress')}
      classNames="fw-container"
    >
      <DeliveryAddress
        data={{
          name,
          name2,
          street,
          houseNumber,
          street4,
          zip,
          city,
          country,
          region,
        }}
      />
    </Article>
  );

  const renderServiceCode = useMemo(() => {
    if (!shouldShowServiceCode || !serviceCode) {
      return null;
    }

    return (
      <>
        <Row className={clsx(md && 'mb-6', !md && 'pl-5')}>
          <Col span={24}>
            <span>
              {t('checkout.serviceCode')}: {serviceCode}
            </span>
          </Col>
        </Row>
        <Divider className="divider--thin" />
      </>
    );
  }, [t, md, shouldShowServiceCode, serviceCode]);

  const renderPaymentMethod = () => (
    <Article
      classNames="order-overview__upper-section__payment-method mt-5 fw-container"
      title={t('checkout.step3.upperSection.leftPart.paymentMethod')}
    >
      <InvoiceIcon style={{ marginRight: 10 }} />{' '}
      {t(`checkout.step2.${paymentMethod}`)}
    </Article>
  );

  const renderShipping = () => (
    <Article
      classNames="order-overview__upper-section__payment-method mt-5 fw-container"
      title={t('checkout.step3.upperSection.leftPart.shipping')}
    >
      {shippingMethod || 'N/A'}
    </Article>
  );

  const renderReferenceNumber = () => (
    <Article
      classNames="order-overview__upper-section__reference-number mt-5 fw-container"
      title={t('checkout.step3.upperSection.leftPart.referenceNumber')}
    >
      {referenceNumber || 'N/A'}
    </Article>
  );

  const renderMobileView = () => (
    <section className="order-overview__upper-section">
      {renderDeliveryAddress()}
      <div className="order-overview__upper-section__divider fw-container mt-5 mb-4">
        <Divider className="divider--thin" />
      </div>
      {renderServiceCode}
      <div className="order-overview__upper-section__payment-title fw-container">
        {t('checkout.step3.upperSection.leftPart.mobile.paymentSection.title')}
      </div>
      {renderPaymentMethod()}
      {renderShipping()}
      {renderBillingAddress()}
      <div className="order-overview__upper-section__divider fw-container mt-4 mb-5">
        <Divider className="divider--thin" />
      </div>
      {renderReferenceNumber()}
      <CostSummary
        darkBackground
        showTitle
        showTotal
        form={form}
        submitFormHandler={submitFormHandler}
      />
    </section>
  );

  const renderDesktopView = () => (
    <section>
      <Row className="order-overview__upper-section">
        <Col
          className="order-overview__upper-section__left-part p-3"
          xs={24}
          sm={24}
          md={16}
          lg={16}
          xl={16}
        >
          <Row className="mb-6">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {renderBillingAddress()}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {renderDeliveryAddress()}
            </Col>
          </Row>
          {renderServiceCode}
          <Row className="mb-6">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {renderPaymentMethod()}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {renderReferenceNumber()}
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {renderShipping()}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} />
          </Row>
        </Col>
        <Col
          className="order-overview__upper-section__right-part"
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
        >
          <CostSummary
            darkBackground
            showTitle
            showTotal
            form={form}
            submitFormHandler={submitFormHandler}
          />
        </Col>
      </Row>
    </section>
  );

  return !md ? renderMobileView() : renderDesktopView();
}

UpperSection.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  submitFormHandler: PropTypes.func.isRequired,
};

UpperSection.defaultProps = {};

export default UpperSection;
