import parse from 'html-react-parser';

import { isValidArray } from './array';

export const generateID = () => Math.random().toString(36).substring(7);

export const checkListPriceIsZero = (listPrice) =>
  +listPrice === 0 || listPrice === '';

export const checkObjectIsEmpty = (value) =>
  value && Object.keys(value).length === 0 && value.constructor === Object;

function isErrorWithMessage(error) {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  );
}

function toErrorWithMessage(maybeError) {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch (error) {
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error) {
  return toErrorWithMessage(error).message;
}

export const logErrors = (error) => {
  // Prevent show log in production
  if (process.env.NODE_ENV === 'development') {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(`${error.response.status} - ${error.response.data}`);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
  }
};

export const parseHtml = (text) => {
  const convertedObject = parse(text);
  if (!isValidArray(convertedObject)) {
    if (!convertedObject.type) {
      const reactElement = parse(`<span>${convertedObject}</span>`);
      return [
        {
          ...reactElement,
          key: 0,
        },
      ];
    }
    return [{ ...convertedObject, key: 0 }];
  }
  return convertedObject.map((item, index) => {
    if (!item.type) {
      const reactElement = parse(`<span>${item}</span>`);
      return {
        ...reactElement,
        key: index,
      };
    }
    return item;
  });
};
