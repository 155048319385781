const groupEquipmentsByName = (equipments = []) => {
  return equipments.reduce((result, equipment) => {
    return {
      ...result,
      [equipment]: true,
    };
  }, {});
};

const groupTechnicalDataByName = (technicalData = []) => {
  return technicalData.reduce((result, { name, unit, value }) => {
    return {
      ...result,
      [`${name} ${unit}`]: value,
    };
  }, {});
};

class ComparisonItem {
  constructor({
    materialName,
    materialNumber,
    longDescription = '',
    equipments = [],
    technicalData = [],
    pictureUrl = '',
    isLoaded = false,
    currencyUnit,
    listPrice,
    requiresInstruction,
    displayAddToCart,
    displayListPrice,
  }) {
    this.materialName = materialName;
    this.materialNumber = materialNumber;
    this.longDescription = longDescription;
    this.equipmentsByName = groupEquipmentsByName(equipments);
    this.technicalsByName = groupTechnicalDataByName(technicalData);
    this.allEquipments = Object.keys(this.equipmentsByName);
    this.allTechnicals = Object.keys(this.technicalsByName);
    this.image = pictureUrl;
    this.isLoaded = isLoaded;
    this.requiresInstruction = requiresInstruction;
    this.listPrice = listPrice;
    this.currencyUnit = currencyUnit;
    const shouldDisplayAddToCart =
      typeof displayAddToCart === 'boolean' ? displayAddToCart : true;
    this.displayAddToCart = shouldDisplayAddToCart;
    this.displayListPrice = displayListPrice;
  }
}

export default ComparisonItem;
