import { createSlice } from '@reduxjs/toolkit';

const technicalSlice = createSlice({
  name: 'technical',
  initialState: {
    details: {
      isLoading: false,
      error: null,
      data: {},
    },
  },
  reducers: {
    getDetails(state) {
      state.details.isLoading = true;
      state.details.data = {};
      state.details.error = null;
    },
    getDetailsSuccess(state, action) {
      state.details.isLoading = false;
      state.details.error = null;
      state.details.data = action.payload;
    },
    getDetailsFailed(state) {
      state.details.isLoading = false;
      state.details.error = 'NOT_FOUND';
    },
    updateDetails(state) {
      state.details.isUpdating = true;
      state.details.error = null;
    },
    updateDetailsSuccess(state) {
      state.details.isUpdating = false;
      state.details.error = null;
    },
    updateDetailsFailed(state) {
      state.details.isUpdating = false;
      state.details.error = 'UPDATE_FAILED';
    },
  },
});

export const {
  getDetails,
  getDetailsSuccess,
  getDetailsFailed,
  updateDetails,
  updateDetailsSuccess,
  updateDetailsFailed,
} = technicalSlice.actions;

export default technicalSlice.reducer;
