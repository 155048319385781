import { NotificationManager } from 'components/Notification';
import { all, put, takeLatest } from 'redux-saga/effects';
import { searchService } from 'services';
import { trackOnSiteSearch } from 'store/slices/adobeAnalyticSlice';
import {
  getSearchSuggestions,
  getSearchSuggestionsFailed,
  getSearchSuggestionsSuccess,
  requestSearch,
  requestSearchFailed,
  requestSearchSuccess,
} from 'store/slices/searchSlice';

import { isStringContainsEmptySpaces } from '../../libs/utils/stringUltis';

// Change response data index from 1, 2, 3 to 0, 1, 2
function handleSearchResponseIndex(responseData) {
  const result = {};
  Object.keys(responseData).forEach((typeIndex) => {
    result[typeIndex - 1] = responseData[typeIndex];
  });
  return result;
}

function* requestSearchSaga({ payload }) {
  const {
    searchAll = false,
    value,
    size = 10,
    from = 0,
    lang,
    category,
  } = payload;
  try {
    const { data } = yield searchService.getSearchResult(
      searchAll,
      value,
      size,
      from,
      lang,
      category
    );
    const handledData = searchAll ? data : handleSearchResponseIndex(data);
    const products = Object.values(handledData);
    const totalProducts = products.reduce(
      (prev, curr) => +prev + +curr?.total,
      0
    );

    yield all([
      put(
        requestSearchSuccess({
          data: handledData,
          searchAll,
        })
      ),
      put(
        trackOnSiteSearch({
          onsiteSearchTerm: value,
          onsiteSearchResult: totalProducts,
        })
      ),
    ]);
  } catch (error) {
    yield put(requestSearchFailed());
    NotificationManager.error({
      message: 'notification.error.search',
      variables: { value },
      description: 'notification.error.searchDescription',
    });
  }
}

function* getSearchSuggestionsSaga({ payload }) {
  try {
    // Prevent calling api if search term is empty
    if (!isStringContainsEmptySpaces(payload)) {
      const { data } = yield searchService.getSearchSuggestions(payload);
      yield put(getSearchSuggestionsSuccess(data));
    }
  } catch (error) {
    yield put(getSearchSuggestionsFailed(error.toString()));
  }
}

function* searchSaga() {
  yield takeLatest(getSearchSuggestions.type, getSearchSuggestionsSaga);
  yield takeLatest(requestSearch.type, requestSearchSaga);
}

export default searchSaga;
