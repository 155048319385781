import './styles.scss';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const defaultColProps = { xs: 24, lg: 12 };

export const displayTypes = {
  NORMAL: 1,
  REVERSE: 2,
};

function BlockTwoCol(props) {
  const { className, left, right, type } = props;
  const leftProps =
    type === displayTypes.REVERSE
      ? { xs: 24, lg: { order: 0, span: 12 }, order: 1 }
      : defaultColProps;
  const rightProps = defaultColProps;

  return (
    <div className={`block-two-col ${className}`}>
      <Row gutter={[24, 24]}>
        <Col {...leftProps}>{left}</Col>
        <Col {...rightProps}>{right}</Col>
      </Row>
    </div>
  );
}

BlockTwoCol.defaultProps = {
  className: null,
  type: displayTypes.REVERSE,
};

BlockTwoCol.propTypes = {
  className: PropTypes.string,
  left: PropTypes.element.isRequired,
  right: PropTypes.element.isRequired,
  type: PropTypes.oneOf([displayTypes.NORMAL, displayTypes.REVERSE]),
};

export default BlockTwoCol;
