import { Collapse, Empty } from 'antd';
import B2becSpareParts from 'components/B2becSpareParts';
import {
  PRODUCT_LIMIT_PER_SECTION_SIZE,
  TOTAL_PRODUCT_PER_SECTION,
} from 'libs/constants';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectShowSparePartLink } from 'store/selectors/userSelector';

import usePermission from '../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../libs/constants/modulerights';
import B2becAnchor from '../B2becAnchor';
import B2becCollapse from '../B2becCollapse';
import B2BProductCarousel from '../B2BProductCarousel';
import CustomButton from '../CustomButton';

const { Panel } = Collapse;

const B2becMatchingProducts = (props) => {
  const { dataSource, onShowAllHandler, materialNumber } = props;
  const { t } = useTranslation();

  const showSparePartLink = useSelector(selectShowSparePartLink);
  const { hasPermission: hasShowSparePartPermission } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.OPEN_SPARE_PART_SHOP
  );

  const shouldShowSparePart = useMemo(() => {
    if (!hasShowSparePartPermission) {
      return false;
    }
    if (showSparePartLink === null || showSparePartLink === undefined) {
      // default value is true
      return true;
    }

    return showSparePartLink;
  }, [showSparePartLink, hasShowSparePartPermission]);

  const listData = useMemo(() => {
    return ['suitableProducts', 'accessories', 'detergents'].reduce(
      (result, c) => {
        const data = dataSource[c]?.data;

        if (data && data.length) {
          return { ...result, [c]: data };
        }

        return result;
      },
      {}
    );
  }, [dataSource]);

  const listKeys = Object.keys(listData);

  const listTranslatedKeys = useMemo(() => {
    const listAnchorKeys = shouldShowSparePart
      ? [...listKeys, 'spareParts']
      : listKeys;

    return listAnchorKeys.map((key) => ({
      key,
      translationKey: `searchResultsPage.header.${key}`,
    }));
  }, [shouldShowSparePart, listKeys]);

  return (
    <>
      <div className="bg-gray">
        <div className="search-results__anchor">
          <B2becAnchor listKeys={listTranslatedKeys} />
        </div>
      </div>
      <div className="search-results__content">
        <B2becCollapse
          defaultActiveKey={['suitableProducts', 'accessories', 'detergents']}
          expandIconPosition="right"
          bordered
          ghost
        >
          {listKeys.map((pannelData) => {
            let content = <Empty />;
            const data = listData[pannelData];
            if (isValidArray(data)) {
              const mappedData = data.map(
                ({
                  materialNumber: materialId,
                  materialName: name,
                  listPrice,
                  pictureUrl: img,
                  ...productData
                }) => {
                  return {
                    name,
                    materialId,
                    listPrice,
                    img,
                    ...productData,
                  };
                }
              );
              const productLimitPerSection =
                mappedData.length > PRODUCT_LIMIT_PER_SECTION_SIZE
                  ? TOTAL_PRODUCT_PER_SECTION
                  : mappedData.length;

              content = (
                <div className="mb-4">
                  <B2BProductCarousel
                    className="product-card__wrapper mb-5"
                    listItems={mappedData}
                    productLimitPerSection={productLimitPerSection}
                  />
                  <div className="search-results__button-wrapper">
                    <CustomButton
                      type="ghost"
                      onClick={() => onShowAllHandler(pannelData)}
                    >
                      {t(`searchResultsPage.button.showAll.${pannelData}`, {
                        count: dataSource[pannelData]?.totalCount,
                      })}
                    </CustomButton>
                  </div>
                </div>
              );
            }

            return (
              <Panel
                header={t(`searchResultsPage.header.${pannelData}`)}
                key={pannelData}
                id={pannelData}
              >
                {content}
              </Panel>
            );
          })}
        </B2becCollapse>

        {/* display spare part for matching products overview page only */}
        {shouldShowSparePart ? (
          <div
            id="spareParts"
            className="container-full-width-page product-details__content__background pt-6 pb-6"
            style={{ marginBottom: 28 }}
          >
            <B2becSpareParts
              className="spare-parts product-details--padding-left product-details--padding-right"
              materialNumber={materialNumber}
              translation={{ allSparePart: 'allSparePartOfMachine' }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

B2becMatchingProducts.defaultProps = {
  dataSource: {},
  onShowAllHandler: () => {},
};

B2becMatchingProducts.propTypes = {
  dataSource: PropTypes.shape({
    materialName: PropTypes.string,
  }),
  onShowAllHandler: PropTypes.func,
  materialNumber: PropTypes.string.isRequired,
};

export default B2becMatchingProducts;
