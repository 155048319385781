import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useEmployeeDetails = (employeeId) => {
  const [userId] = useState(employeeId);

  const employeeDetails = useSelector((state) => {
    return state.employee.userDetails[userId];
  });
  const isLoading = employeeDetails?.loading;
  const isFailed = employeeDetails?.error;

  const employeeData = useMemo(() => {
    try {
      const { isLoading: loading, error, ...data } = employeeDetails;

      if (loading || error) {
        return undefined;
      }

      return data;
    } catch {
      // TODO
    }
    return undefined;
  }, [employeeDetails]);

  return {
    isLoading,
    isFailed,
    employeeData,
  };
};
