import { createSlice } from '@reduxjs/toolkit';
import { ACTION_STATUS, DEFAULT_PROMOTION_ITEMS } from 'libs/constants';

const promotionSlice = createSlice({
  name: 'promotion',
  initialState: {
    fetchStatus: ACTION_STATUS.DEFAULT,
    isFetching: false,
    updateStatus: ACTION_STATUS.DEFAULT,
    isUpdating: false,
    invalidMaterialNumbers: [],
    products: [],
  },
  reducers: {
    getPromotedProductList(state) {
      state.isFetching = state.fetchStatus === ACTION_STATUS.DEFAULT;
    },
    getPromotedProductListSuccess(state, { payload }) {
      const { invalidMaterialNumbers, products } = payload;
      state.fetchStatus = ACTION_STATUS.SUCCESS;
      state.isFetching = false;
      state.invalidMaterialNumbers = invalidMaterialNumbers;
      state.products = products;
    },
    getPromotedProductListError(state) {
      state.fetchStatus = ACTION_STATUS.FAILED;
      state.isFetching = false;
      state.invalidMaterialNumbers = [];
      state.products = DEFAULT_PROMOTION_ITEMS;
    },
    updatePromotionList(state) {
      state.updateStatus = ACTION_STATUS.DEFAULT;
      state.isUpdating = true;
    },
    updatePromotionListSuccess(state) {
      state.updateStatus = ACTION_STATUS.SUCCESS;
      state.isUpdating = false;
    },
    updatePromotionListError(state) {
      state.updateStatus = ACTION_STATUS.FAILED;
      state.isUpdating = false;
    },
    resetPromotedProductList(state) {
      state.fetchStatus = ACTION_STATUS.DEFAULT;
      state.isFetching = false;
      state.updateStatus = ACTION_STATUS.DEFAULT;
      state.isUpdating = false;
      state.invalidMaterialNumbers = [];
      state.products = [];
    },
  },
});

export default promotionSlice.reducer;

export const {
  getPromotedProductList,
  getPromotedProductListSuccess,
  getPromotedProductListError,
  updatePromotionList,
  updatePromotionListSuccess,
  updatePromotionListError,
  resetPromotedProductList,
} = promotionSlice.actions;
