import './styles.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import B2becTable from '../../../../../components/B2becTable';
import useDummyItems from '../../../../../hooks/useDummyItems';
import renderColumns from './renderColumns';

const shapeOfContactReason = {
  contactReason: '',
  contactReasonId: '',
  indexNumber: '',
  receiverAddress: '',
  translationLink: '',
};

function ContactReasonTable({
  isLoading,
  handleEditContactReason,
  handleDeleteContactReason,
  contactReasonList,
  highlightedContactReasonId,
}) {
  const fakeContactReasonList = useDummyItems(
    6,
    shapeOfContactReason,
    'contactReasonId'
  );

  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (highlightedContactReasonId) {
      setIsHighlighted(true);
    }
  }, [highlightedContactReasonId]);

  const onHighlightAnimationEnd = () => setIsHighlighted(false);

  return (
    <B2becTable
      className="contact-reason-table__container"
      dataSource={isLoading ? fakeContactReasonList : contactReasonList}
      rowKey="contactReasonId"
      pagination={false}
      showSorterTooltip={false}
      columns={renderColumns(
        handleEditContactReason,
        handleDeleteContactReason,
        isLoading,
        highlightedContactReasonId,
        isHighlighted,
        onHighlightAnimationEnd
      )}
    />
  );
}
ContactReasonTable.propTypes = {
  isLoading: PropTypes.bool,
  handleEditContactReason: PropTypes.func.isRequired,
  handleDeleteContactReason: PropTypes.func.isRequired,
  contactReasonList: PropTypes.arrayOf(PropTypes.shape({})),
  highlightedContactReasonId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};
ContactReasonTable.defaultProps = {
  isLoading: false,
  contactReasonList: [],
  highlightedContactReasonId: false,
};
export default ContactReasonTable;
