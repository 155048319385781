import './styles.scss';

import { Input } from 'antd';
import clsx from 'clsx';
import { isValidMaterial } from 'libs/constants';
import { formatMaterialID } from "libs/utils/material-number";
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const MaterialNumberInput = (props) => {
  const {
    value: propsValue,
    onChange,
    disabled,
    isLoading,
    hasGreenTick,
    ...restProps
  } = props;

  const [value, setValue] = useState(propsValue);

  const isSuccess = isValidMaterial(value) && propsValue === value;

  const isShowGreenTick = hasGreenTick && !disabled && !isLoading && isSuccess;

  const handleChange = (event) => {
    const { value: valueChanged } = event.target;

    if (valueChanged === '') {
      onChange('');
    }

    if (valueChanged.length > 11) {
      return;
    }

    const formatedValue = formatMaterialID(valueChanged);
    if (onChange) {
      onChange(formatedValue);
    }

    setValue(formatedValue);
  };

  return (
    <div
      className={clsx('material-input', {
        'material-input--disabled': disabled,
      })}
    >
      <Input
        className="custom-input"
        value={value}
        onChange={handleChange}
        disabled={disabled || isLoading}
        {...restProps}
      />
      {isShowGreenTick && <span className="green-tick" />}
    </div>
  );
};

MaterialNumberInput.defaultProps = {
  value: undefined,
  onChange: undefined,
  disabled: false,
  isLoading: false,
  hasGreenTick: true,
};

MaterialNumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasGreenTick: PropTypes.bool,
};

export default MaterialNumberInput;
