import { Checkbox, Col, Row } from 'antd';
import { USER_ROLES } from 'libs/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ASSIGN_ROLES = [USER_ROLES.Customer, USER_ROLES.KaercherAdmin];

const AssignRolesCheckbox = ({ value, onChange, isInvitationForm }) => {
  const { t } = useTranslation();

  const assignRolesTranslated = ASSIGN_ROLES.map((role) => ({
    label: t(`assignRoles.${role.toLowerCase()}`),
    value: role,
  }));

  return (
    <Checkbox.Group value={value}>
      <Row gutter={[12, 12]}>
        {assignRolesTranslated.map((assignRole) => (
          <Col key={assignRole.value}>
            <Checkbox
              value={assignRole.value}
              onChange={() => onChange(assignRole.value)}
              disabled={
                isInvitationForm && assignRole.value !== ASSIGN_ROLES[0]
              }
            >
              {assignRole.label}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};

AssignRolesCheckbox.defaultProps = {
  value: undefined,
  onChange: undefined,
  isInvitationForm: false,
};

AssignRolesCheckbox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isInvitationForm: PropTypes.bool,
};

export default AssignRolesCheckbox;
