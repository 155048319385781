import { Col, Row, Typography } from 'antd';
import B2BecCartSummary from 'components/B2BecCartSummary';
import CustomButton from 'components/CustomButton';
import FormatStatus from 'components/FormatStatus';
import usePermission from 'hooks/usePermission';
import {
  MODULE_ORDER_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
} from 'libs/constants/modulerights';
import { isValidArray } from 'libs/utils/array';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { reorder } from 'store/slices/cartSlice';

import styles from './OrderDetailsPage.module.scss';

const { Title, Text } = Typography;

const Shipments = ({ shipments }) => {
  if (isValidArray(shipments)) {
    return shipments.map((shipment) => {
      const [shipmentURL] = shipment.match(/(https?:\/\/[^\s]+)/g);
      const shipmentNr = shipment.replace(shipmentURL, '').replace(':', '');

      return (
        <a
          key={shipmentNr}
          className={styles.shipmentLink}
          href={shipmentURL}
          target="_blank"
          rel="noopener noreferrer"
          title={shipmentNr}
        >
          {shipmentNr}
        </a>
      );
    });
  }

  return null;
};

const B2BecOrderDetailTitle = ({ isLoading, showSummary, orderDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    verifiedPermissions: [hasReorderPermission, canViewCart],
  } = usePermission([
    MODULE_ORDER_RIGHTS.REORDER_HISTORIC_ORDER,
    MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_CART,
  ]);

  const showShipments = isValidArray(orderDetails?.shipments);
  const salesOrderNumber = orderDetails?.salesOrderNumber;
  const orderDate = orderDetails?.orderDate;
  const referenceNumber = orderDetails?.referenceNumber;
  const status = orderDetails?.status;

  const reorderHandler = useCallback(() => {
    if (!salesOrderNumber || !hasReorderPermission) return;

    dispatch(reorder({ salesOrderNumber, canViewCart }));
  }, [dispatch, salesOrderNumber, hasReorderPermission, canViewCart]);

  return (
    <div className={styles.OrderDetailsPageTitle}>
      <h1>
        {t('orderDetail.order')} {salesOrderNumber}
      </h1>
      <Row gutter={[16, 16]}>
        <Col span={24} lg={{ span: showSummary ? 16 : 24 }}>
          <Row gutter={[16, 16]}>
            <Col span={showSummary ? 12 : 8}>
              <Title level={3}>{t('orderDetail.orderDate')}</Title>
              <Text>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  formatDate(orderDate, getFormatType().default) ||
                  t('unavailableDate')
                )}
              </Text>
            </Col>
            <Col span={showSummary ? 12 : 8}>
              <Title level={3}>{t('checkout.step2.referenceNumber')}</Title>
              <Text>{isLoading ? <Skeleton /> : referenceNumber}</Text>
            </Col>
            <Col span={showSummary ? 12 : 8}>
              <Title level={3}>{t('orderDetail.status')}</Title>
              <Text>
                {isLoading ? <Skeleton /> : <FormatStatus status={status} />}
              </Text>
            </Col>
            {showShipments && (
              <Col span={12}>
                <Title level={3}>{t('orders.history.shipmentNR')}</Title>
                <Text>
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <Shipments shipments={orderDetails?.shipments} />
                  )}
                </Text>
              </Col>
            )}
          </Row>
        </Col>
        {showSummary && (
          <Col span={24} lg={{ span: 8 }}>
            <div className={styles.summary}>
              <B2BecCartSummary
                currencyUnit={orderDetails?.currencyUnit}
                cartSubTotal={orderDetails?.cartSubTotal}
                cartTotal={orderDetails?.cartTotal}
                additionalCost={orderDetails?.additionalCost}
                shippingCosts={orderDetails?.shippingCosts}
                cartTotalVAT={orderDetails?.cartTotalVAT}
                vat={orderDetails?.vat}
                voucherValue={orderDetails?.voucherValue}
                checkoutSettings={orderDetails?.checkoutSettings}
                isLoading={isLoading}
              />
              {salesOrderNumber && (
                <CustomButton
                  block
                  onClick={reorderHandler}
                  disabled={isLoading || !hasReorderPermission}
                >
                  {t('reorder')}
                </CustomButton>
              )}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default B2BecOrderDetailTitle;
