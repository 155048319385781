import { NotificationManager } from 'components/Notification';
import useAsync from 'hooks/useAsync';
import { ASYNC_STATUS } from 'libs/constants';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cartService } from 'services';
import { selectVoucherCode } from 'store/selectors/cartSelector';
import {
  getCartItemsAfterRedeemingVoucher,
  removeVoucherFromCart,
} from 'store/slices/cartSlice';

import B2becTranslation from '../../../components/B2becTranslation';
import PermissionWrapper from '../../../HOCs/permissionWrapper';
import { MODULE_CART_CHECKOUT_RIGHTS } from '../../../libs/constants/modulerights';
import RedeemVoucherInput from './components/RedeemVoucherInput';
import RemoveVoucher from './components/RemoveVoucher';
import styles from './RedeemVoucher.module.scss';

const redeemVoucher = (voucherCode) =>
  cartService.redeemVoucher({ voucherCode });

const renderSuccessNotification = (description) => {
  NotificationManager.success({
    message: 'notification.success.redeemVoucher',
    description,
  });
};

const getErrorMessage = (validationCode) => {
  switch (validationCode) {
    case 'ERR_01':
      return 'doesNotExist';
    case 'ERR_02':
      return 'isOutdated';
    case 'ERR_03':
      return 'isNotValidYet';
    case 'ERR_04':
      return 'noMatchingProducts';
    default:
      return 'isInvalid';
  }
};

const renderErrorNotification = (validationCode) => {
  const errorMessage = `notification.error.redeemVoucher.${getErrorMessage(
    validationCode
  )}`;
  NotificationManager.error({
    message: errorMessage,
  });
};

const RedeemVoucher = ({ isDisabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const voucherCode = useSelector(selectVoucherCode);

  const handleVoucherRedemption = useCallback(
    ({ data }) => {
      const { validationCode } = data;

      if (validationCode !== '') {
        renderErrorNotification(validationCode);
      } else {
        dispatch(getCartItemsAfterRedeemingVoucher());
        renderSuccessNotification();
      }
    },
    [dispatch]
  );

  const { execute: executeRedeemVoucher, status: redeemVoucherStatus } =
    useAsync(redeemVoucher, false, handleVoucherRedemption);

  const handleRemoveVoucher = () => {
    dispatch(removeVoucherFromCart());
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.redeemVoucherContainer}>
        <p className={styles.title}>{t('redeemVoucher.title')}</p>
        <RedeemVoucherInput
          handleRedeemVoucher={executeRedeemVoucher}
          isDisabled={isDisabled}
          isRedeeming={redeemVoucherStatus === ASYNC_STATUS.PENDING}
        />
      </div>
      <div className={styles.information}>
        {voucherCode ? (
          <>
            <B2becTranslation
              value="redeemVoucher.thisVoucherCodeHasBeenAddedToYourShoppingCart"
              isComponent
              variables={{ voucherNumber: voucherCode }}
              htmlTags={[
                <span key="voucher-code" className={styles.voucherCode} />,
              ]}
            />
            <PermissionWrapper
              permission={MODULE_CART_CHECKOUT_RIGHTS.REMOVE_VOUCHER_FROM_CART}
            >
              <RemoveVoucher
                onRemoveVoucher={handleRemoveVoucher}
                isDisabled={isDisabled}
              />
            </PermissionWrapper>
          </>
        ) : null}
      </div>
    </div>
  );
};

RedeemVoucher.propTypes = {
  isDisabled: PropTypes.bool,
};

RedeemVoucher.defaultProps = {
  isDisabled: false,
};

export default RedeemVoucher;
