import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SelectedMachine = ({ data }) => {
  const {
    id: machineId,
    name: machineName,
    serialNumber,
    image: machineImage,
    // machine category is only got from the machine page
    // currently machine page doesn't store to the redux
    machineCategory,
  } = data;
  const { t } = useTranslation();

  return (
    <div className="selected-machine">
      <div className="selected-machine__image">
        <img src={machineImage} alt={machineName} />
      </div>
      <div className="selected-machine__information">
        <span>{machineCategory}</span>
        <span>{machineName}</span>
        <span>{`${t(
          'service.serviceOverview.label.itemNumber'
        )}: ${machineId}`}</span>
        <span>{`${t(
          'service.serviceOverview.label.serialNumber'
        )}: ${serialNumber}`}</span>
      </div>
    </div>
  );
};

SelectedMachine.propTypes = {
  data: PropTypes.shape({
    machineCategory: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    serialNumber: PropTypes.string,
    image: PropTypes.string,
  }),
};

SelectedMachine.defaultProps = {
  data: {
    machineCategory: null,
    machineName: '',
    machineId: '',
    serialNumber: '',
    machineImage: '',
  },
};

export default SelectedMachine;
