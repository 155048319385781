import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ASYNC_STATUS } from '../libs/constants';

/**
 * A callback to return promise from axios request.
 * @callback promiseFunction
 *
 * @param {...*} any params for proceeding axios request.
 * @return {Promise} promise from axios request.
 */
/**
 * A callback to return a function that returns a promise from axios request.
 * @callback cancellablePromiseFunction
 *
 * @param {axios.CancelToken.source.token} cancelToken - axios token to cancel this current request later.
 * @return {promiseFunction} function that returns a promise from axios request.
 */
/**
 * React Hook to manage asynchronous tasks.
 *
 * @param {promiseFunction|cancellablePromiseFunction} asyncFunction - A function to return a promise from axios
 * request or a function that return the same promise if @param {cancellable} is true.
 * @param {Boolean} [immediate=true] - whether if this request will be triggered immediately on componentDidMount
 * or not.
 * @param {Function} [onSuccess=undefined] - a callback to return response if the request is succeeded.
 * @param {Function} [onError=undefined] - a callback to return error if the request is failed.
 * @param {Boolean} [cancellable=false] - whether if this request is cancellable or not.
 */
const useAsync = (
  asyncFunction,
  immediate = true,
  onSuccess,
  onError,
  cancellable = false
) => {
  const [status, setStatus] = useState(ASYNC_STATUS.IDLE);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  const mounted = useRef(false);
  const cancelToken = useRef(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const execute = useCallback(
    (...params) => {
      setStatus(ASYNC_STATUS.PENDING);
      setValue(null);
      setError(null);

      let promise;
      if (cancellable) {
        cancelToken.current = axios.CancelToken.source();
        promise = asyncFunction(cancelToken.current.token)(...params);
      } else {
        promise = asyncFunction(...params);
      }

      return promise
        .then((response) => {
          if (mounted.current) {
            setValue(response);
            setStatus(ASYNC_STATUS.SUCCESS);

            if (typeof onSuccess === 'function') {
              onSuccess(response);
            }
          }

          return { response };
        })
        .catch((err) => {
          if (mounted.current) {
            if (axios.isCancel(err)) {
              // Request cancelled
            } else {
              setError(err);
              setStatus(ASYNC_STATUS.ERROR);

              if (typeof onError === 'function') {
                onError(err);
              }
            }
          }

          return {
            error: err,
          };
        });
    },
    [asyncFunction, onError, onSuccess, cancellable]
  );

  useEffect(() => {
    if (immediate) {
      execute();
    }
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel();
        // Renew token immediately after cancellation to make another request later
        cancelToken.current = axios.CancelToken.source();
      }
    };
  }, [execute, immediate]);

  return { execute, status, value, error };
};

export default useAsync;
