import { NotificationManager } from 'components/Notification';
import { PRODUCT_MATCHING_TYPES } from 'libs/constants';
import { filterEmptyElementInArray } from 'libs/utils/array';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ProductService } from 'services';

import { productActions } from 'store/slices/productSlice';

function* getProductDetailByLanguageSaga({ payload }) {
  try {
    const { data } = yield call(
      ProductService.requestProductDetailByLang,
      payload
    );
    const { technicalData, equipments, applications, documents, categoryId } =
      data;
    const { data: categoryPath } = yield call(
      ProductService.requestCategoryPath,
      categoryId
    );

    const filteredTechnicalData = filterEmptyElementInArray(technicalData);
    const filteredEquipments = filterEmptyElementInArray(equipments);
    const filteredApplications = filterEmptyElementInArray(applications);
    const filteredDocuments = filterEmptyElementInArray(documents);

    const filteredData = {
      ...data,
      technicalData: filteredTechnicalData,
      equipments: filteredEquipments,
      applications: filteredApplications,
      documents: filteredDocuments,
      categoryPath,
    };

    yield put(productActions.getProductDetailsByLanguageSuccess(filteredData));
  } catch (error) {
    NotificationManager.error({
      message: 'notification.error.load',
    });
    yield put(productActions.getProductDetailsByLanguageFailed());
  }
}

function* getProductsByProductType(payload) {
  const { productType, materialNumber, pageNumber, sortOrder = 2 } = payload;
  const { data } = yield call(
    ProductService.getMatchingProducts,
    productType,
    materialNumber,
    pageNumber,
    sortOrder
  );

  yield put(
    productActions.getMatchingProductsSuccess({
      productType,
      matchingProducts: data,
    })
  );
}

function* getMatchingProductsSaga({ payload }) {
  try {
    if (payload.productType === PRODUCT_MATCHING_TYPES.ALL) {
      const productTypes = [
        PRODUCT_MATCHING_TYPES.ACCESSORIES,
        PRODUCT_MATCHING_TYPES.DETERGENTS,
        PRODUCT_MATCHING_TYPES.SUITABLE,
      ];

      yield all(
        productTypes.map((productType) =>
          call(getProductsByProductType, { ...payload, productType })
        )
      );
    } else {
      yield call(getProductsByProductType, payload);
    }
  } catch (error) {
    console.error('error', error);
    yield put(productActions.getMatchingProductsFailed());
  }
}

function* productSaga() {
  yield takeLatest(
    productActions.getProductDetailsByLanguage.type,
    getProductDetailByLanguageSaga
  );
  yield takeLatest(
    productActions.getMatchingProducts.type,
    getMatchingProductsSaga
  );
}

export default productSaga;
