import { WishlistService } from 'services';

export const getUserSuggestion = async (searchText) => {
  try {
    const response = await WishlistService.getSearchSuggestions(searchText);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const renameWishlist = async (requestBody) => {
  try {
    const response = await WishlistService.renameWishlist(requestBody);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const shareWishlistToUsers = async (requestBody) => {
  try {
    const response = await WishlistService.shareWishlistToUsers(requestBody);
    return Promise.resolve(response?.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const shareWishlistToCompany = async (wishlistId) => {
  try {
    const response = await WishlistService.shareWishlistToCompany(wishlistId);
    return Promise.resolve(response?.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const widthdrawSharingWishlistToCompany = async (wishlistId) => {
  try {
    const response = await WishlistService.widthdrawSharingWishlistToCompany(
      wishlistId
    );
    return Promise.resolve(response?.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
