export const getAddress = (
  address,
  regions = {},
  ignores = [],
  separator = ' '
) => {
  const mergeData = (keys) =>
    keys
      .reduce((result, key) => {
        if (key === 'region') {
          const region = regions[address[key]];
          if (region) {
            return [...result, region.label];
          }
        } else if (address[key] && !ignores.includes(key)) {
          return [...result, address[key]];
        }

        return result;
      }, [])
      .join(separator);

  return {
    name: mergeData(['name', 'name2', 'name3']),
    address: mergeData(['street', 'houseNumber']),
    street4: mergeData(['street4']),
    location: mergeData(['zip', 'region', 'city']),
    displayText: mergeData([
      'partnerNumber',
      'name',
      'name2',
      'name3',
      'street',
      'houseNumber',
      'street4',
      'zip',
      'region',
      'city',
      'country',
    ]),
  };
};
