import './styles.scss';

import { Anchor, Grid } from 'antd';
import { useSearchContext } from 'contexts/search';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { VERY_SMALL_DEVICE_WIDTH } from '../../libs/constants';

const { useBreakpoint } = Grid;

const { Link } = Anchor;

const anchorTop = {
  xxl: 204,
  xl: 204,
  lg: 204, // 110
  md: 157, // 110
  sm: 154, // 110
  xs: 163, // 110
};

const anchorMarginTop = {
  xxl: 204,
  xl: 204,
  lg: 204, // 132
  md: 157, // 132
  sm: 217, // 132
  xs: 223, // 132
};

const calcHeaderHeight = (breakpointObj, isSearchFocused) => {
  // convert object of breakpoint into array
  const breakpoints = Object.entries(breakpointObj);
  // get the first found breakpoint from sorted array
  const breakpoint =
    breakpoints.length !== 0 &&
    breakpoints.reverse().find(([, value]) => !!value);
  // "breakpoint" contains [key, value]. Ex: ["sm", true]
  // "breakpoint[0]" is the key. Ex: "sm"
  // in case "breakpoint" is undefined, can not get the key
  if (breakpoint) {
    if (breakpoint[0] === 'xs' && window.innerWidth > VERY_SMALL_DEVICE_WIDTH) {
      return isSearchFocused ? 237 : 185;
    }

    return isSearchFocused
      ? anchorMarginTop[breakpoint[0]]
      : anchorTop[breakpoint[0]];
  }
  return 116;
};

const B2becAnchor = (props) => {
  const { listKeys, anchorOffset } = props;
  const { t } = useTranslation();
  const { isSearchFocused } = useSearchContext();
  const breakpoint = useBreakpoint();
  const headerHeight = calcHeaderHeight(breakpoint, isSearchFocused);

  return (
    <Anchor
      className="custom-anchor"
      offsetTop={headerHeight}
      targetOffset={headerHeight + anchorOffset}
    >
      {listKeys.map((panelData) => (
        <Link
          className="custom-anchor__link"
          key={panelData.key}
          href={`#${panelData.key}`}
          title={t(panelData.translationKey)}
        />
      ))}
    </Anchor>
  );
};

B2becAnchor.propTypes = {
  listKeys: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      translationKey: PropTypes.string,
    })
  ),
  anchorOffset: PropTypes.number,
};

B2becAnchor.defaultProps = {
  listKeys: [],
  anchorOffset: 50,
};

export default B2becAnchor;
