import axios from 'axios';
import environment from 'environments/environment.dev';
import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import {
  FALLBACK_LANGUAGES,
  replaceLanguageInUrl,
  SUPPORTED_LANGUAGES,
} from 'libs/constants/lang';
import LanguageDetector from 'libs/utils/languageDectector';
import { initReactI18next } from 'react-i18next';

import commonEN from './lang/common-en.json';

const { REACT_APP_I18NEXT_NAMESPACE = 'common' } = process.env;

const bundledResources = {
  en: {
    [REACT_APP_I18NEXT_NAMESPACE]: commonEN,
  },
};

let translationData = commonEN;

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: REACT_APP_I18NEXT_NAMESPACE,
    defaultNS: REACT_APP_I18NEXT_NAMESPACE,
    fallbackLng: 'en',
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    backend: {
      backends: [
        // Convert local resources to backend entity
        resourcesToBackend(bundledResources), // primary backend
        HttpBackend, // fallback backend
      ],
      backendOptions: [
        {}, // options for primary backend (local resource) => Empty
        {
          // {{ns}} = namespace = 'common'; {{lng}} = current requesting language
          loadPath: '{{lng}}',
          request: (options, lng, payload, callback) => {
            const getUrl = () => {
              if (SUPPORTED_LANGUAGES.includes(lng)) {
                return `/locales/common-${lng}.json`;
              }

              const fallback = FALLBACK_LANGUAGES[lng.slice(0, 2)];

              if (fallback) {
                return `/locales/common-${fallback}.json`;
              }

              return undefined;
            };

            const url = getUrl();

            if (!url) {
              callback(null, {
                status: 500,
              });
            }

            // Send request to fetch corresponding language file
            axios
              .get(url, {
                // url = loadPath
                baseURL: environment.b2bDomain,
              })
              .then((response) => {
                // Only set translationData if response content-type is JSON
                // Else, skip when content-type is text/html (non-exist translation file)
                if (
                  response?.data &&
                  response?.headers?.['content-type'] === 'application/json'
                ) {
                  translationData = response?.data;
                }
                // Callback to propagate response data (json translation file) back to i18n
                callback(null, {
                  data: translationData,
                  status: 200,
                });
              })
              .catch((e) => {
                console.error('Translation Data Request Error:', e);
                callback(e, {
                  status: 500,
                });
              });
          },
        },
      ],
    },
  });

i18n.on('languageChanged', (lng) => {
  const { search, hash } = window.location;

  const pathname = replaceLanguageInUrl(window.location.pathname, lng);
  const url = `${pathname}${search === '' ? '' : search}${
    hash === '' ? '' : hash
  }`;

  window.history.replaceState(null, document.title, url);
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
