import './styles.scss';

import { Form, TimePicker } from 'antd';
import clsx from 'clsx';
import { getFormatType } from 'libs/utils/formatDate';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CustomTimeRangePicker = (props) => {
  const { t } = useTranslation();
  const { fromTime, toTime, setFromTime, setToTime } = props;
  const [isFromTimePickerShown, setIsFromTimePickerShown] = useState(false);
  const [isToTimePickerShown, setIsToTimePickerShown] = useState(false);
  const fromTimePickerRef = useRef(null);
  const toTimePickerRef = useRef(null);

  const handleClickFromTimePickerOutside = (event) => {
    if (
      fromTimePickerRef.current &&
      !fromTimePickerRef.current.contains(event.target)
    ) {
      setIsFromTimePickerShown(false);
    }
  };

  const handleClickToTimePickerOutside = (event) => {
    if (
      toTimePickerRef.current &&
      !toTimePickerRef.current.contains(event.target)
    ) {
      setIsToTimePickerShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickToTimePickerOutside, true);
    document.addEventListener('click', handleClickFromTimePickerOutside, true);
    return () => {
      document.removeEventListener(
        'click',
        handleClickToTimePickerOutside,
        true
      );
      document.removeEventListener(
        'click',
        handleClickFromTimePickerOutside,
        true
      );
    };
  }, []);

  return (
    <>
      <span className="custom-range__title">{t('voucher.voucherTime')}</span>
      <div
        className={clsx('custom-range__wrapper', {
          'custom-range__wrapper--focus':
            isFromTimePickerShown || isToTimePickerShown,
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="custom-range__from-time">
          <Form.Item name="fromTime">
            <TimePicker
              use12Hours
              showNow={false}
              format={getFormatType().time}
              onChange={setFromTime}
              value={fromTime}
              allowClear={false}
              placeholder={t('voucher.placeholder.startTime')}
            />
          </Form.Item>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="custom-range__to-time">
          <Form.Item name="toTime">
            <TimePicker
              use12Hours
              showNow={false}
              format={getFormatType().time}
              onChange={setToTime}
              value={toTime}
              allowClear={false}
              placeholder={t('voucher.placeholder.endTime')}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

CustomTimeRangePicker.propTypes = {
  fromTime: PropTypes.shape({}),
  toTime: PropTypes.shape({}),
  setFromTime: PropTypes.func.isRequired,
  setToTime: PropTypes.func.isRequired,
};

CustomTimeRangePicker.defaultProps = {
  fromTime: moment(),
  toTime: moment(),
};

export default CustomTimeRangePicker;
