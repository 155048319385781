import B2becSearchSuggestion from 'components/B2becSearchSuggestion';
import { CloseIcon } from 'components/Icons';
import SuggestionItem from 'components/Search/SuggestionItem';
import { formatMaterialID } from "libs/utils/material-number";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProductSearchItem from '../ProductSearchItem';
import styles from './AddProduct.module.scss';
import { searchHandler } from './helper';

const renderItem = (props) => {
  return {
    key: props.materialNumber,
    value: props.materialNumber,
    label: <ProductSearchItem {...props} />,
  };
};

function AddProduct(props) {
  const {
    countryId,
    customerNumber,
    onClose,
    addedProducts,
    handleAddProduct,
    handleSearchProducts,
  } = props;
  const [options, setOptions] = useState([]);
  const [status, setStatus] = useState('idle');
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const getSuggestion = useCallback(searchHandler, []);

  const handleSearch = async (text) => {
    setStatus('pending');
    setSearchText(text);
    setOptions([]);
    const response = await getSuggestion(
      text,
      countryId,
      customerNumber,
      handleSearchProducts
    );
    const formattedResponse = response.map(({ materialNumber, ...value }) => {
      return {
        ...value,
        materialNumber: formatMaterialID(materialNumber, materialNumber.length),
      };
    });

    setOptions(formattedResponse);
    setStatus('idle');
  };

  const renderOptions = useMemo(() => {
    return options.map((value) => {
      const isAdded =
        value.isAdded || addedProducts[value.materialNumber] !== undefined;
      return renderItem({
        ...value,
        countryId,
        customerNumber,
        isAdded,
        handleAddProduct,
      });
    });
  }, [options, addedProducts, countryId, customerNumber, handleAddProduct]);

  const renderNotFoundContent = (searchTerm, searchStatus) => {
    if (!searchTerm) {
      return undefined;
    }

    if (searchStatus === 'pending') {
      return (
        <div style={{ width: '100%' }}>
          {`${t('searchFor')} "${searchTerm}"`}
          <SuggestionItem materialNumber="loading" name="loading" isLoading />
          <SuggestionItem materialNumber="loading" name="loading" isLoading />
          <SuggestionItem materialNumber="loading" name="loading" isLoading />
        </div>
      );
    }

    return <div>{`${t('searchNoResults')} "${searchTerm}"`}</div>;
  };

  useEffect(() => {
    const isOnlyOneOptionAdded =
      options.length === 1 &&
      addedProducts[options[0].materialNumber] !== undefined;

    if (isOnlyOneOptionAdded) {
      onClose();
    }
  }, [addedProducts, onClose, options]);

  return (
    <div className={styles.addProduct}>
      <div className={styles.header}>
        {t('productManagement.addProductModal.modalTitle')}
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <B2becSearchSuggestion
        options={renderOptions}
        onSearch={handleSearch}
        onChange={(value) => {
          if (value === '') {
            setSearchText('');
            setOptions([]);
          }
        }}
        open
        notFoundContent={renderNotFoundContent(searchText, status)}
      />
    </div>
  );
}

AddProduct.defaultProps = {
  customerNumber: '',
  countryId: '',
  onClose: () => {},
};

AddProduct.propTypes = {
  countryId: PropTypes.string,
  onClose: PropTypes.func,
  customerNumber: PropTypes.string,
  handleAddProduct: PropTypes.func.isRequired,
  handleSearchProducts: PropTypes.func.isRequired,
};

export default AddProduct;
