import environment from '../environments/environment.dev';
import {
  FAPI_SHARED_WISHLIST_ENDPOINT,
  FAPI_WISHLIST_ENDPOINT,
} from '../libs/constants/b2bFapiApiEndpoints';
import HttpClient from './apiHttpClient';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const WishlistService = {
  getWishlistOverview({
    ownerId,
    searchQuery = '',
    pageNumber = 1,
    pageSize = 6,
  }) {
    if (!ownerId) {
      throw Error('missing ownerId');
    }

    const searchParams = new URLSearchParams({
      searchQuery,
      pageNumber,
      pageSize,
    });

    return fapiClient.get(
      `${FAPI_WISHLIST_ENDPOINT}/overview/${ownerId}?${searchParams.toString()}`
    );
  },

  getWishlistsList({ ownerId }) {
    return fapiClient.get(`${FAPI_WISHLIST_ENDPOINT}/${ownerId}`);
  },

  createWishlist({ newWishlistName }) {
    const queryParams = new URLSearchParams();

    if (newWishlistName) {
      queryParams.append('wishlistName', newWishlistName);
    }

    return fapiClient.post(`${FAPI_WISHLIST_ENDPOINT}/create?${queryParams}`, {
      body: {},
    });
  },

  addProductToWishlist({
    ownerId,
    materialNumber,
    materialName,
    quantity,
    wishlistId,
  }) {
    const queryParams = new URLSearchParams();

    if (ownerId) {
      queryParams.append('ownerId', ownerId);
    }

    if (materialNumber) {
      queryParams.append('MaterialNumber', materialNumber);
    }

    if (materialName) {
      queryParams.append('Name', materialName);
    }

    if (quantity) {
      queryParams.append('Quantity', quantity);
    }

    return fapiClient.put(
      `${FAPI_WISHLIST_ENDPOINT}/${wishlistId}/items?${queryParams}`,
      {
        body: {},
      }
    );
  },

  getWishlistDetail({
    wishlistOwnerId,
    wishlistId,
    pageNumber = 1,
    pageSize = 10,
    searchQuery,
  }) {
    const stringParams = new URLSearchParams({
      pageNumber,
      pageSize,
      searchQuery,
    });
    return fapiClient.get(
      `${FAPI_WISHLIST_ENDPOINT}/${wishlistOwnerId}/detail/${wishlistId}?${stringParams.toString()}`
    );
  },

  addWishlistProductsToCart({ wishlistOwnerId, wishlistId, sessionId }) {
    return fapiClient.post(
      `${FAPI_WISHLIST_ENDPOINT}/${wishlistOwnerId}/add-to-cart`,
      {
        queryParams: { wishlistId, sessionId },
        body: {},
      }
    );
  },

  updateWishlistProductQuantity({ wishlistOwnerId, wishlistId, productData }) {
    return fapiClient.put(
      `${FAPI_WISHLIST_ENDPOINT}/${wishlistOwnerId}/detail/${wishlistId}/update`,
      {
        body: productData,
      }
    );
  },

  removeProductInWishlist({ wishlistOwnerId, wishlistId, materialNumber }) {
    const stringParams = new URLSearchParams({
      ownerId: wishlistOwnerId,
      materialNumber,
    });
    return fapiClient.delete(
      `${FAPI_WISHLIST_ENDPOINT}/${wishlistId}/items/?${stringParams.toString()}`
    );
  },

  deleteWishlist({ wishlistOwnerId, wishlistId }) {
    const stringParams = new URLSearchParams({
      ownerId: wishlistOwnerId,
      wishlistId,
    });
    return fapiClient.delete(
      `${FAPI_WISHLIST_ENDPOINT}?${stringParams.toString()}`
    );
  },

  renameWishlist(payload) {
    const { ownerId, wishlistId, newName } = payload;
    const queryParams = new URLSearchParams({
      newName,
    });
    return fapiClient.put(
      `${FAPI_WISHLIST_ENDPOINT}/${ownerId}/rename/${wishlistId}?${queryParams.toString()}`,
      {
        body: {},
      }
    );
  },

  getSearchSuggestions(query, cancelToken) {
    const queryParams = new URLSearchParams({
      emailOrUserName: query,
    });
    return fapiClient.get(
      `${FAPI_SHARED_WISHLIST_ENDPOINT}/searchusers/?${queryParams.toString()}`,
      {
        cancelToken,
      }
    );
  },

  shareWishlistToUsers(query) {
    const { wishlistId, userIds } = query;
    const queryParams = new URLSearchParams({
      wishlistId,
    });
    return fapiClient.post(
      `${FAPI_SHARED_WISHLIST_ENDPOINT}/wishlists/${wishlistId}/users?${queryParams.toString()}`,
      {
        body: userIds,
      }
    );
  },

  getWishlistSharedDetail(wishlistId) {
    const queryParams = new URLSearchParams({
      wishlistId,
    });
    return fapiClient.get(
      `${FAPI_SHARED_WISHLIST_ENDPOINT}/${wishlistId}/details?${queryParams.toString()}`
    );
  },

  shareWishlistToCompany(wishlistId) {
    const queryParams = new URLSearchParams({
      wishlistId,
    });
    return fapiClient.put(
      `${FAPI_SHARED_WISHLIST_ENDPOINT}/wishlists/${wishlistId}/company?${queryParams.toString()}`,
      { body: {} }
    );
  },

  widthdrawSharingWishlistToCompany(wishlistId) {
    const queryParams = new URLSearchParams({
      wishlistId,
    });
    return fapiClient.delete(
      `${FAPI_SHARED_WISHLIST_ENDPOINT}/wishlists/${wishlistId}/company?${queryParams.toString()}`
    );
  },
};
