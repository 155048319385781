import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getClassTypesAndProductCount,
  getProductSubCategoryOverviewData,
} from 'store/slices/categorySlice';

import SubCategory from './index';

const SubCategoryPage = (props) => {
  const { categoryId, subCategoryId, classTypeId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductSubCategoryOverviewData({ categoryId }));
  }, [categoryId, dispatch]);

  useEffect(() => {
    if (classTypeId) {
      dispatch(getClassTypesAndProductCount({ subCategoryId }));
    }
    // only get list class if custom go directly to class page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategoryId, dispatch]);

  return (
    <SubCategory
      categoryId={categoryId}
      subCategoryId={subCategoryId}
      classTypeId={classTypeId}
      {...props}
    />
  );
};

export default SubCategoryPage;
