export const defaultSortOptions = [
  {
    // Currently, don't need to render as a sort option
    // so it doesn't have the text property
    key: 'class.desc',
    sortOrder: 0,
  },
  {
    key: 'name.asc',
    text: 'sorting.sortBy.nameAscending',
    sortOrder: 1,
  },
  {
    key: 'name.desc',
    text: 'sorting.sortBy.nameDescending',
    sortOrder: 2,
  },
  {
    key: 'price.asc',
    text: 'sorting.sortBy.priceAscending',
    sortOrder: 3,
  },
  {
    key: 'price.desc',
    text: 'sorting.sortBy.priceDescending',
    sortOrder: 4,
  },
];

export const getSortOption = (key, sortOptions = defaultSortOptions) =>
  sortOptions.find((sortOption) => sortOption.key === key);
