import { Input } from 'antd';
import clsx from 'clsx';
import { SearchIcon } from 'components/Icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Search.module.scss';

export const Search = ({ placeholder, searchTerm, onSearch, ...rest }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSearch = (searchValue) => {
    onSearch(searchValue);
  };

  return (
    <div className={styles.search}>
      <Input.Search
        {...rest}
        allowClear
        bordered
        enterButton={t('buttonTexts.search')}
        className={clsx('custom-search-input', styles.input)}
        placeholder={t('buttonTexts.search')}
        prefix={<SearchIcon className={styles.icon} />}
        size="large"
        value={value}
        onChange={handleChange}
        onSearch={handleSearch}
      />
    </div>
  );
};
