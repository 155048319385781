import { Form } from 'antd';
import AddressSelect from 'components/DeliveryAddressBlock';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const DeliveryAddressBlock = (props) => {
  const { t } = useTranslation();
  const { deliveryAddresses, handleDeliveryAddressChange } = props;

  const handleSelectAddress = useCallback(
    (_, selectedId) => {
      handleDeliveryAddressChange(selectedId);
    },
    [handleDeliveryAddressChange]
  );

  return (
    <div className="profile__main-block mt-5">
      <div className="profile__main-block__label">
        {t('checkout.step1.deliveryAddress')}
      </div>
      <Form.Item name="deliveryAddress" className="custom-form-item mt-4">
        <AddressSelect
          deliveryAddresses={deliveryAddresses}
          placeholder={t('myProfile.form.deliveryAddress.placeHolder')}
          onChange={handleSelectAddress}
        />
      </Form.Item>
      <div className="profile__main-block__description">
        {t('myProfile.deliveryAddressDesc')}&ensp;
        <span className="profile__main-block__description--bold">
          {t('myProfile.deliveryAddressDesc2')}
        </span>
      </div>
    </div>
  );
};
export default DeliveryAddressBlock;

DeliveryAddressBlock.propTypes = {
  deliveryAddresses: PropTypes.arrayOf(PropTypes.shape()),
  handleDeliveryAddressChange: PropTypes.func.isRequired,
  customerInfo: PropTypes.shape({
    name: PropTypes.string,
    street: PropTypes.string,
    houseNumber: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};
DeliveryAddressBlock.defaultProps = {
  deliveryAddresses: [],
};
