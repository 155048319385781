export const PHRASE_PROJECT_URL = 'https://eu.phrase.com/';

export const {
  REACT_APP_PHRASE_API_URL: PHRASE_API_URL,
  REACT_APP_PHRASE_PROJECT_ID: PHRASE_PROJECT_ID,
  REACT_APP_PHRASE_TOKEN: PHRASE_TOKEN,
  REACT_APP_PHRASE_ENGLISH_ID: PHRASE_ENGLISH_ID,
} = process.env;

export const DEFAULT_LANGUAGES = [{ label: 'English', value: 'en' }];

// Valid languages in Phrase
export const SUPPORTED_LANGUAGES = [
  'cs-CZ',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'de-FT',
  'en',
  'en-DE',
  'en-GB',
  'fi-FI',
  'fr-BE',
  'fr-CH',
  'fr-FR',
  'hu-HU',
  'ja-JP',
  'kw-GB',
  'nl-BE',
  'nl-NL',
  'no-NO',
  'pl-PL',
  'sv-SE',
  'ro-RO',
  'en-US',
  'en-CA',
  'en-AU',
  'en-ZA',
];

export const FALLBACK_LANGUAGES = {
  en: 'en',
  de: 'de-DE',
  hu: 'hu-HU',
  no: 'no-NO',
  pl: 'pl-PL',
  sv: 'sv-SE',
  fi: 'fi-FI',
  fr: 'fr-FR',
  nl: 'nl-NL',
  cs: 'cs-CZ',
  da: 'da-DK',
  ja: 'ja-JP',
  kw: 'kw-GB',
  ro: 'ro-RO',
};

export const AllLanguages = Object.keys(FALLBACK_LANGUAGES);

export const SupportedLocales = [...SUPPORTED_LANGUAGES, ...AllLanguages];

export const LocalePattern = SupportedLocales.join('|');

export const findLocaleInUrl = (url) => {
  return url.match(`/(?<locale>${LocalePattern})`)?.groups?.locale;
};

export const removeLanguageFromUrl = (url) => {
  return url
    .replace(/^\/([a-z]{2}-[A-Z]{2})$/, '')
    .replace(/^\/([a-z]{2})$/, '')
    .replace(/^\/([a-z]{2}-[A-Z]{2})\//, '')
    .replace(/^\/([a-z]{2})\//, '')
    .replace(/\/{1,}$/, '/')
    .replace(/^\//, '')
    .replace(/\/$/, '');
};

export const replaceLanguageInUrl = (url, language) => {
  const urlWithoutLanguage = removeLanguageFromUrl(url);
  if (urlWithoutLanguage) {
    return `/${language}/${urlWithoutLanguage}`;
  }
  return `/${language}`;
};
