import './styles.scss';

import { Input } from 'antd';
import { CustomFormItem } from 'components/B2bUserForm';
import {
  validateConfirmPassword,
  validatePasswordRequirements,
} from 'libs/utils/passwordValidators';
// import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Password() {
  const { t } = useTranslation();
  return (
    <div className="registration-page__form__password-section">
      <h3>
        {t('adminWorkspace.userManagement.userDetails.passwordSection.title')}
      </h3>
      <p className="registration-page__form__password-section__requirements">
        {t('registration.form.password.requirements')}
      </p>
      <CustomFormItem
        className="registration-page__form__password"
        name="password"
        validateFirst
        rules={[
          {
            required: true,
          },
          {
            validator: validatePasswordRequirements,
          },
        ]}
      >
        <Input.Password />
      </CustomFormItem>
      <CustomFormItem
        className="registration-page__form__repeat-password"
        name="repeatPassword"
        validateFirst
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) =>
            validateConfirmPassword(
              getFieldValue('password'),
              'form.validate.repeatPassword'
            ),
        ]}
        dependencies={['password']}
      >
        <Input.Password />
      </CustomFormItem>
    </div>
  );
}

// Password.propTypes = {};

// Password.defaultProps = {};

export default Password;
