import accessoriesDetergentsImage from 'assets/img/acc-detergents-image.webp';

const invoiceData = [
  {
    checked: false,
    invoiceNumber: '03265478965',
    products: [
      {
        imageSrc: accessoriesDetergentsImage,
        materialNumber: '123.456.789',
        name: 'HD 7/16-4 MXA Kfz',
      },
      {
        imageSrc: accessoriesDetergentsImage,
        materialNumber: '123.456.780',
        name: 'HD 7/16-4 MXA Kfz',
      },
    ],
  },
  {
    checked: false,
    invoiceNumber: '03265478966',
    products: [
      {
        imageSrc: accessoriesDetergentsImage,
        materialNumber: '123.456.789',
        name: 'HD 7/16-4 MXA Kfz',
      },
      {
        imageSrc: accessoriesDetergentsImage,
        materialNumber: '123.456.780',
        name: 'HD 7/16-4 MXA Kfz',
      },
      {
        imageSrc: accessoriesDetergentsImage,
        materialNumber: '123.456.781',
        name: 'HD 7/16-4 MXA Kfz',
      },
    ],
  },
];

export default invoiceData;
