import './styles.scss';

import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_TYPES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackProductsComparison } from 'store/slices/adobeAnalyticSlice';

import { ReactComponent as GoBackIconSVG } from '../../assets/icons/chevron-left.svg';
import CustomButton from '../../components/CustomButton';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import ProductComparisonTable from './components/ProductComparisonTable';
import { useGetComparisonFormLocal } from './hooks/useGetComparisonFormLocal';
import ComparisonItem from './shared/ComparisonItem';
import {
  productComparisonActions,
  selectIsProductDetailsIsLoaded,
  selectIsProductDetailsIsLoading,
  selectProductDetails,
} from 'store/slices/productComparisonSlice';

const mergeProductDetails = (productDetails = {}, excludes = []) => {
  return Object.keys(productDetails).reduce((result, productId) => {
    const { [productId]: productData } = productDetails;
    if (excludes.includes(productId) || !productData) {
      return result;
    }

    return {
      ...result,
      [productId]: new ComparisonItem(productData),
    };
  }, {});
};

const ProductComparison = (props) => {
  const { t } = useTranslation();
  const [listRemoved, setListRemoved] = useState([]);
  const history = useHistory();
  const { title, breadcrumbs, pageId } = props;

  useAdobeAnalysis(TRACK_DATA.COMPARISON, {
    pageName: 'Products comparison',
    pageId,
    pageType: PAGE_TYPES.COMPARISON,
  });

  const isLoaded = useRef(false);
  const dispatch = useDispatch();
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  const isProductDetailsLoading = useSelector(selectIsProductDetailsIsLoading);
  const isProductDetailsLoaded = useSelector(selectIsProductDetailsIsLoaded);
  const productDetails = useSelector(selectProductDetails);

  const { listProductIds, deleteProductIds } = useGetComparisonFormLocal();

  const memoProductDetails = JSON.stringify(productDetails);

  const listProduct = useMemo(() => {
    return mergeProductDetails(JSON.parse(memoProductDetails), listRemoved);
  }, [listRemoved, memoProductDetails]);

  const isEmptyList = listProductIds.length === 0;

  const shouldShowErrorMessage =
    !isProductDetailsLoading && !isProductDetailsLoaded;

  const shouldShowTable = !shouldShowErrorMessage && !isEmptyList;

  const handleRemoveProduct = (productId) => {
    setListRemoved((current) => {
      return [...current, productId];
    });

    deleteProductIds(productId);
  };

  useEffect(() => {
    if (listProductIds.length > 0 && !isLoaded.current) {
      dispatch(
        productComparisonActions.getProductComparisonData({
          materialNumber: listProductIds,
        })
      );
      isLoaded.current = true;
    }
  }, [dispatch, listProductIds]);

  useEffect(() => {
    return () => dispatch(productComparisonActions.resetProductComparison());
  }, [dispatch]);

  useEffect(() => {
    if (
      listProductIds.length > 0 &&
      listProduct &&
      Object.keys(listProduct).length !== 0
    ) {
      const listAnalysisProducts = (listProductIds || []).map(
        (productId) =>
          `${listProduct[productId]?.materialNumber}:${listProduct[productId]?.materialName}`
      );

      dispatch(trackProductsComparison(listAnalysisProducts));
    }
  }, [dispatch, listProductIds, listProduct]);

  return (
    <div className="container-full-width-page">
      <div className="product-comparison">
        <div className="product-comparison__header">
          <div className="product-comparison__header__title">
            {t('productComparison.title')}
          </div>

          <CustomButton
            className="back-btn"
            buttonSize="small"
            onClick={() => history.goBack()}
          >
            <GoBackIconSVG />
            {t('productComparison.backToPreviousPage')}
          </CustomButton>
        </div>
        <div className="product-comparison__content">
          {isEmptyList ? t('productComparison.dontHaveAnyProducts') : null}
          {isProductDetailsLoading ? t('productComparison.loading') : null}
          {shouldShowErrorMessage ? t('productComparison.cannotGetData') : null}
          {shouldShowTable ? (
            <ProductComparisonTable
              memoListProduct={JSON.stringify(listProduct)}
              handleRemoveProduct={handleRemoveProduct}
              isLoading={isProductDetailsLoading}
              isLoaded={isProductDetailsLoaded}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

ProductComparison.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default ProductComparison;
