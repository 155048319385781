import { LoadingOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { ACCEPTED_UPLOAD_FILE_EXTENSIONS } from '../../libs/constants';
import CustomButton from '../CustomButton';
import { UploadIcon } from '../Icons';
import styles from './B2becUpload.module.scss';

const B2becUpload = (props) => {
  const { isUploading, customRequest, text, buttonClass } = props;

  const uploadButton = (
    <div className={styles.uploadBlock}>
      <CustomButton
        disabled={isUploading}
        className={clsx(styles.customButton, buttonClass)}
      >
        {isUploading ? <LoadingOutlined /> : <UploadIcon />}
        {text}
      </CustomButton>
    </div>
  );

  return (
    <Upload
      showUploadList={false}
      accept={ACCEPTED_UPLOAD_FILE_EXTENSIONS}
      disabled={isUploading}
      customRequest={customRequest}
    >
      {uploadButton}
    </Upload>
  );
};

B2becUpload.propTypes = {
  isUploading: PropTypes.bool,
  customRequest: PropTypes.func,
  text: PropTypes.string,
  buttonClass: PropTypes.string,
};

B2becUpload.defaultProps = {
  isUploading: false,
  customRequest: () => {},
  text: '',
  buttonClass: '',
};

export default B2becUpload;
