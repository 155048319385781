import qs from 'querystring';

import {
  CART_ENDPOINT,
  CART_ENDPOINT_V2,
  CART_REPORT_ENDPOINT,
  ITEM_ENDPOINT,
  ITEM_ENDPOINT_V2,
  MACHINE_ENDPOINT,
  MACHINE_ENDPOINT_V2,
  ORDER_ENDPOINT_V2,
  SAP_LOG_ENDPOINT,
  VOUCHER_ENDPOINT,
} from '../libs/constants/b2bApiEndpoints';
import { httpClient } from './apiHttpClient';

export class OrderService {
  static getAllCartItems({ sessionId }) {
    const searchParams = new URLSearchParams();

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
    }

    const url = `${CART_ENDPOINT}/simulate${
      sessionId ? `?${searchParams}` : ''
    }`;

    return httpClient.get(url);
  }

  static submitCheckoutOrder({ orderData, sessionId }) {
    const searchParams = new URLSearchParams();

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
    }

    const url = `${CART_ENDPOINT}${sessionId ? `?${searchParams}` : ''}`;

    return httpClient.post(url, {
      body: orderData,
    });
  }

  static getAllOrders({
    pageNumber = 1,
    pageSize = 10,
    searchTerm,
    sessionId,
    sortBy = 'desc',
    orderBy = 'orderDate',
    selectedStatus = '',
    dateFrom,
    dateTo,
  }) {
    const getOrderListParams = new URLSearchParams({
      pageNumber,
      pageSize,
      orderBy,
      sortBy,
      searchTerm,
      status: selectedStatus,
    });

    if (sessionId) {
      getOrderListParams.append('sessionId', sessionId);
    }

    if (dateFrom) {
      getOrderListParams.append('dateFrom', dateFrom);
    }

    if (dateTo) {
      getOrderListParams.append('dateTo', dateTo);
    }

    return httpClient.get(`${CART_ENDPOINT}/list?${getOrderListParams}`);
  }

  static getAllOrdersByCustomer(
    customerId,
    countryId,
    pageNumber = 1,
    pageSize = 10
  ) {
    const getOrderListParams = new URLSearchParams({
      customerId,
      countryId,
    });
    return httpClient.get(
      `${ORDER_ENDPOINT_V2}/list/${pageNumber}/${pageSize}?${getOrderListParams}`
    );
  }

  static getOrderByUser(userId, countryId, orderNumber) {
    const getOrderByUserParams = new URLSearchParams({
      orderNumber,
      userId,
      countryId,
    });
    return httpClient.get(`${ORDER_ENDPOINT_V2}?${getOrderByUserParams}`);
  }

  static getOrderByNumber(orderNumber) {
    return httpClient.get(`${CART_ENDPOINT}/${orderNumber}`);
  }

  static getProductListByOrderNumber(orderNumber) {
    return httpClient.get(`${CART_ENDPOINT}/${orderNumber}/invoice/materials`);
  }

  static getPriceBoxData(materialNumber) {
    return httpClient.get(
      `${CART_ENDPOINT}/simulate-material/${materialNumber}`
    );
  }

  static getMiniCartData({ sessionId }) {
    const searchParams = new URLSearchParams();

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
    }

    const url = `${ITEM_ENDPOINT}/list${sessionId ? `?${searchParams}` : ''}`;

    return httpClient.get(url);
  }

  static setCartItemQuantity({ materialNumber, quantity, sessionId }) {
    return httpClient.put(`${ITEM_ENDPOINT}`, {
      body: { materialNumber, quantity, sessionId },
    });
  }

  static addProductToCart({ materialNumber, quantity, sessionId }) {
    return httpClient.post(ITEM_ENDPOINT, {
      body: { materialNumber, quantity, sessionId },
    });
  }

  static addProductToCustomerCart({
    materialNumber,
    quantity,
    countryId,
    userId,
  }) {
    return httpClient.post(ITEM_ENDPOINT_V2, {
      body: { materialNumber, quantity, countryId, userId },
    });
  }

  static increaseCartItemQuantity({ materialNumber, quantity, sessionId }) {
    return httpClient.post(`${ITEM_ENDPOINT}`, {
      body: { materialNumber, quantity, sessionId },
    });
  }

  static removeProductFromCart({ materialNumber, sessionId }) {
    const searchParams = new URLSearchParams();

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
    }

    const url = `${ITEM_ENDPOINT}/${materialNumber}${
      sessionId ? `?${searchParams}` : ''
    }`;

    return httpClient.delete(url);
  }

  static emptyCart({ sessionId }) {
    const searchParams = new URLSearchParams();

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
    }

    const url = `${ITEM_ENDPOINT}/list${sessionId ? `?${searchParams}` : ''}`;

    return httpClient.delete(url);
  }

  static deleteCartForGivenUserId({ userId }) {
    const url = `${ITEM_ENDPOINT_V2}/list`;

    return httpClient.delete(url, {
      body: {
        userId,
      },
    });
  }

  static reorder({ orderNumber, sessionId }) {
    const searchParams = new URLSearchParams();

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
    }

    const url = `${ITEM_ENDPOINT}/add-from-order/${orderNumber}${
      sessionId ? `?${searchParams}` : ''
    }`;

    return httpClient.post(url, {
      body: {},
    });
  }

  static getMyMachineList(params) {
    const queryParams = new URLSearchParams(params);
    return httpClient.get(`${MACHINE_ENDPOINT}/list?${queryParams}`);
  }

  static getMachinesListByCustomerNumber({
    customerNumber,
    pageNumber,
    pageSize,
    sortOrder,
    cancelToken,
  }) {
    const queryParams = new URLSearchParams({ pageSize, sortOrder });

    if (pageNumber) {
      queryParams.set('pageNumber', pageNumber);
    }

    return httpClient.get(
      `${MACHINE_ENDPOINT_V2}/list/${customerNumber}?${queryParams.toString()}`,
      { cancelToken }
    );
  }

  static changeCartProductReference(
    materialNumber,
    productReference,
    quantity
  ) {
    return httpClient.put(ITEM_ENDPOINT, {
      body: { materialNumber, productReference, quantity },
    });
  }

  static exportCartSummary({ sessionId }) {
    const searchParams = new URLSearchParams();

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
    }

    const url = `${CART_REPORT_ENDPOINT}${sessionId ? `?${searchParams}` : ''}`;

    return httpClient.get(url);
  }

  static getMultipleInvoicesByInvoiceNumbers(invoiceNumbers) {
    return httpClient.get(`${CART_ENDPOINT}/invoice`, {
      queryParams: {
        invoicesNumbers: invoiceNumbers,
      },
      paramsSerializer: {
        serialize: (params) => qs.stringify(params),
      },
    });
  }

  static getVoucherList() {
    return httpClient.get(VOUCHER_ENDPOINT);
  }

  static updateVoucherInfo(voucherInfo) {
    return httpClient.put(`${VOUCHER_ENDPOINT}`, {
      body: voucherInfo,
    });
  }

  static createVoucherInfo(voucherInfo) {
    return httpClient.post(VOUCHER_ENDPOINT, {
      body: voucherInfo,
    });
  }

  static deleteVoucherInfo(voucherId) {
    return httpClient.delete(`${VOUCHER_ENDPOINT}/${voucherId}/`);
  }

  static redeemVoucher(voucherCode) {
    return httpClient.get(
      `${CART_ENDPOINT}/redeem/${voucherCode}`,
      {},
      {
        disable: true,
      }
    );
  }

  static getSapLogRequest() {
    return httpClient.get(SAP_LOG_ENDPOINT);
  }

  static validateZipCode(country, zipCode) {
    return httpClient.put(`${CART_ENDPOINT}/zip-code/validate`, {
      body: {
        country,
        zipCode,
      },
    });
  }

  static replaceCartItem(oldMaterialNumber, replacedMaterialNumber, quantity) {
    return httpClient.post(`${ITEM_ENDPOINT}/replace/${oldMaterialNumber}`, {
      body: {
        materialNumber: replacedMaterialNumber,
        quantity,
      },
    });
  }

  static getCartDataByUserId(userId, countryId) {
    const queryParams = new URLSearchParams({
      userId,
      countryId,
    });

    return httpClient.get(`${CART_ENDPOINT_V2}/simulate?${queryParams}`);
  }
}
