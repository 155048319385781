import '../styles.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectCartTotalVAT,
  selectCartTotalWithoutVAT,
  selectCurrencyUnit,
  selectIsIncludingVAT,
  selectSalesOrderNumber,
  selectVAT,
} from 'store/selectors/checkoutSelector';

import OrderSummary from './OrderSummary';

const MiddleSectionComponent = () => {
  const cartTotal = useSelector(selectCartTotalWithoutVAT);
  const salesOrderNumber = useSelector(selectSalesOrderNumber);
  const currencyUnit = useSelector(selectCurrencyUnit);
  const isIncludingVAT = useSelector(selectIsIncludingVAT);
  const vat = useSelector(selectVAT);
  const cartTotalVAT = useSelector(selectCartTotalVAT);

  return (
    <OrderSummary
      salesOrderNumber={salesOrderNumber}
      currencyUnit={currencyUnit}
      cartTotal={cartTotal}
      includingVAT={isIncludingVAT}
      vat={vat}
      cartTotalVAT={cartTotalVAT}
    />
  );
};
export default MiddleSectionComponent;
