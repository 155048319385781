import { generateID } from 'libs/utils/common';
import { useMemo } from 'react';

export const generateDummy = (
  number,
  dataShape = {},
  rowKey = 'materialNumber'
) => {
  return Array(number)
    .fill(null)
    .map(() =>
      dataShape
        ? {
            ...dataShape,
            [rowKey]: generateID(),
          }
        : {
            [rowKey]: generateID(),
            materialName: '',
            listPrice: '',
            pictureUrl: '',
          }
    );
};

const useDummyItems = (
  number = 6,
  dataShape = {},
  rowKey = 'materialNumber'
) => {
  const dummyItems = useMemo(
    () => generateDummy(number, dataShape, rowKey),
    [number, dataShape, rowKey]
  );

  return dummyItems;
};

export default useDummyItems;
