import { isValidArray } from 'libs/utils/array';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectAssignableCountries,
  selectAssignableCountryRolesList,
  selectAssignedRolesByUserId,
} from 'store/selectors/roleSelector';

function useAssignRoles() {
  const { userId } = useParams();
  const assignableCountries = useSelector(selectAssignableCountries);
  const assignedRoles = useSelector(selectAssignedRolesByUserId(userId));
  const assignableCountryRolesList = useSelector(
    selectAssignableCountryRolesList
  );
  const firstCountryValue =
    isValidArray(assignableCountries) && assignableCountries[0]?.value;

  const findAssignableRoles = useCallback(
    (selectedCountry) => {
      if (selectedCountry) {
        const matchItem = assignableCountryRolesList.find(
          (item) => item.country === selectedCountry
        );
        if (matchItem) {
          return matchItem?.roles;
        }
      }
      return [];
    },
    [assignableCountryRolesList]
  );

  const assignableRoles = useMemo(() => {
    return new Set(
      findAssignableRoles(firstCountryValue)?.map((role) => role.value)
    );
  }, [findAssignableRoles, firstCountryValue]);

  const filterAssignedRoles = () => {
    if (!isValidArray(assignedRoles)) {
      return [];
    }

    return assignedRoles
      .filter((assignedRole) => {
        return assignableRoles.has(assignedRole?.roleId);
      })
      .map((assignedRole) => assignedRole?.roleId);
  };

  const selectedRoles = filterAssignedRoles();

  const countryRoles = useMemo(() => {
    if (!isValidArray(assignableCountries)) {
      return [];
    }

    return [
      {
        salesOrg: firstCountryValue,
        roles: selectedRoles,
      },
    ];
  }, [firstCountryValue, assignableCountries, selectedRoles]);

  return {
    assignableCountryRolesList,
    countryRoles,
    assignedRoles,
    firstCountryValue,
    findAssignableRoles,
  };
}

export { useAssignRoles };
