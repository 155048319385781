import './styles.scss';

import { Card, Col, Row } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import useDeviceDetect from '../../../hooks/useDeviceDetect';
import useDummyItems from '../../../hooks/useDummyItems';

const B2becCardSkeleton = ({
  number,
  imageHeight,
  className,
  styles,
  gutter,
  columns,
  hasPrice,
}) => {
  const fakeProducts = useDummyItems(number);
  const { isLargeScreen } = useDeviceDetect();

  const renderMobileSkeletonProductCard = () => (
    <Card className={`product-card__content-container ${className}`}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Skeleton height={140} width={140} />
        </div>

        <div className="product-card__content--right-wrapper product-card-skeleton__content--right-wrapper">
          <p className="product-card__content-name">
            <Skeleton />
          </p>

          <p className="product-card__content-name">
            <Skeleton />
          </p>

          <p className="product-card__content-name">
            <Skeleton />
          </p>
        </div>
      </div>
    </Card>
  );

  const renderSkeletonProductCard = () => (
    <Card
      className={
        hasPrice
          ? `card-item__container--price ${className}`
          : `card-item__container--non-price ${className}`
      }
    >
      <div className="ant-card-cover">
        <Skeleton height={imageHeight} />
      </div>
      <div className="card-item__title skeleton-card__title">
        <Skeleton />
      </div>
      <div className="card-item__item-number">
        <Skeleton />
      </div>
    </Card>
  );

  const containerClass = clsx(
    isLargeScreen
      ? 'card-skeleton__container'
      : 'full-width-page-component__wrapper'
  );

  return (
    <div className={containerClass}>
      <Row
        gutter={gutter}
        className={`product-card__wrapper ${className}`}
        style={styles}
      >
        {fakeProducts.map(({ materialNumber }) => (
          <Col
            xs={columns.xs}
            sm={columns.sm}
            md={columns.md}
            lg={columns.lg}
            xl={columns.xl}
            xxl={columns.xxl}
            key={materialNumber}
            className="card-item"
          >
            {isLargeScreen
              ? renderSkeletonProductCard()
              : renderMobileSkeletonProductCard()}
          </Col>
        ))}
      </Row>
    </div>
  );
};

B2becCardSkeleton.propTypes = {
  number: PropTypes.number,
  imageHeight: PropTypes.number,
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  gutter: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
      })
    ),
    PropTypes.number,
  ]),
  columns: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xxl: PropTypes.number,
  }),
  hasPrice: PropTypes.bool,
};

B2becCardSkeleton.defaultProps = {
  number: 6,
  imageHeight: 320,
  className: '',
  styles: {},
  gutter: [
    { xs: 0, sm: 0, md: 24, lg: 32, xl: 32, xxl: 32 },
    { xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 },
  ],
  columns: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 6 },
  hasPrice: true,
};

export default B2becCardSkeleton;
