import B2becTable from 'components/B2becTable';
import { generateDummy } from 'hooks/useDummyItems';
import usePermission from 'hooks/usePermission';
import { LINK_NAMES } from 'libs/constants/adobeAnalytics';
import { MODULE_ORDER_RIGHTS } from 'libs/constants/modulerights';
import { analyzeLinkName } from 'libs/utils/adobeAnalysis';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectReOrderIds } from 'store/selectors/cartSelector';
import { selectDateFormat } from 'store/selectors/configSelector';
import { trackLinkName } from 'store/slices/adobeAnalyticSlice';
import { reorder } from 'store/slices/cartSlice';

import styles from './OrdersHistoryTable.module.scss';
import useRenderOrderColumns from './tableColumns';

const shapeOfProduct = {
  orderDate: '',
  referenceNumber: '',
  status: null,
  customerId: '',
  shipments: [],
  totalPrice: 0,
};

const fakeItems = generateDummy(6, shapeOfProduct);

const OrdersHistoryTable = (props) => {
  const { items, currentPage, isLoading, hasActions, ...tableProps } = props;
  const dispatch = useDispatch();
  const dateFormat = useSelector(selectDateFormat);
  const { hasPermission: hasReorderPermission } = usePermission(
    MODULE_ORDER_RIGHTS.REORDER_HISTORIC_ORDER
  );
  const reorderIds = useSelector(selectReOrderIds);

  const rowKey = isLoading ? 'materialNumber' : 'salesOrderNumber';
  const showLoading = isLoading || !dateFormat;

  const dataSource = useMemo(() => {
    if (showLoading) {
      return fakeItems;
    }
    return items;
  }, [items, showLoading]);

  const handleReorder = useCallback(
    (salesOrderNumber, canViewCart) => {
      if (!hasReorderPermission) return;

      dispatch(
        trackLinkName(
          analyzeLinkName(LINK_NAMES.ORDER_HISTORY_OVERVIEW.RE_ORDER)
        )
      );
      dispatch(reorder({ salesOrderNumber, canViewCart }));
    },
    [dispatch, hasReorderPermission]
  );

  const handleAnalyzeLinkName = useCallback(
    (orderNumber) => {
      dispatch(
        trackLinkName(
          analyzeLinkName(
            LINK_NAMES.ORDER_HISTORY_OVERVIEW.ORDER_DETAIL,
            orderNumber
          )
        )
      );
    },
    [dispatch]
  );

  const columns = useRenderOrderColumns({
    isLoading,
    hasActions,
    currentPage,
    reorderIds,
    handleReorder,
    handleAnalyzeLinkName,
  });

  return (
    <B2becTable
      key={dateFormat}
      className={styles.wrapper}
      columns={columns}
      dataSource={dataSource}
      rowKey={rowKey}
      showSorterTooltip={false}
      boxShadow={false}
      {...tableProps}
    />
  );
};

OrdersHistoryTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  currentPage: PropTypes.number,
  hasActions: PropTypes.bool,
};

OrdersHistoryTable.defaultProps = {
  items: [],
  currentPage: 1,
  isLoading: false,
  hasActions: true,
};

export default OrdersHistoryTable;
