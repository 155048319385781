import { Col, Form, Input, Row } from 'antd';
import B2becCustomSelect from 'components/B2becCustomSelect';
import { CustomFormItem } from 'components/B2bUserForm';
import CustomButton from 'components/CustomButton';
import AssignRolesCheckbox from 'pages/Admin/InviteNewUserPage/components/AssignRolesCheckbox';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  hideEditEmployeeForm,
  selectIsEditEmployeeFormLoading,
  updateEmployee,
} from 'store/slices/employeeSlice';
import {
  selectIsUserListFetching,
  selectLanguageOptions,
} from 'store/slices/usersManagementSlice';

import styles from './EditEmployeeForm.module.scss';

const { useForm } = Form;

const EditEmployeeForm = ({ initialValues, ...props }) => {
  const { form } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const languageOptions = useSelector(selectLanguageOptions);
  const isEditEmployeeFormLoading = useSelector(
    selectIsEditEmployeeFormLoading
  );
  const isFetchingAvailableLanguages = useSelector(selectIsUserListFetching);

  const handleSubmit = (values) => {
    const { userPolicy, language } = values;
    const data = {
      userId,
      userPolicy,
      language,
    };

    dispatch(updateEmployee(data));
  };

  return (
    <div className={styles.wrapper}>
      <Form
        layout="vertical"
        className="b2b-user-form"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialValues}
        {...props}
      >
        <Row gutter={18}>
          <Col span={12}>
            <CustomFormItem name="firstName">
              <Input disabled />
            </CustomFormItem>
          </Col>
          <Col span={12}>
            <CustomFormItem name="lastName">
              <Input disabled />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem name="email" label="contactEmail">
              <Input disabled />
            </CustomFormItem>
          </Col>
          <Col span={12}>
            <CustomFormItem name="countryId" label="country">
              <B2becCustomSelect disabled />
            </CustomFormItem>
          </Col>
          <Col span={12}>
            <CustomFormItem name="language">
              <B2becCustomSelect
                options={languageOptions}
                loading={isFetchingAvailableLanguages}
                disabled={isFetchingAvailableLanguages}
              />
            </CustomFormItem>
          </Col>
          <Col span={24} className="b2b-user-form__assign-roles">
            <CustomFormItem name="userPolicy" label="assignRoles">
              <AssignRolesCheckbox />
            </CustomFormItem>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={9}>
            <CustomButton
              type="ghost"
              onClick={() => dispatch(hideEditEmployeeForm())}
              disabled={isEditEmployeeFormLoading}
            >
              {t('buttonTexts.cancel')}
            </CustomButton>
          </Col>
          <Col span={9}>
            <CustomButton
              htmlType="submit"
              disabled={isEditEmployeeFormLoading}
            >
              {t('buttonTexts.saveChanges')}
            </CustomButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

EditEmployeeForm.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
};

export default EditEmployeeForm;
