import B2becCustomSelect from 'components/B2becCustomSelect';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Status.module.scss';

export const ORDER_STATUSES_TO_FILTER = [
  {
    value: '',
    label: 'orderStatus.all',
  },
  {
    value: 'invoiced',
    label: 'orderStatus.invoiced',
  },
  {
    value: 'open',
    label: 'orderStatus.open',
  },
  {
    value: 'in_progress',
    label: 'orderStatus.inProgress',
  },
  {
    value: 'delivered',
    label: 'orderStatus.delivered',
  },
  {
    value: 'cancelled',
    label: 'orderStatus.cancelled',
  },
];

export const Status = ({ selectedStatus, onSelectStatus }) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return ORDER_STATUSES_TO_FILTER.map((status) => ({
      ...status,
      label: t(status.label),
    }));
  }, [t]);

  const handleChange = (value) => {
    onSelectStatus(value);
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{t('filter.status')}</span>
      <B2becCustomSelect
        className={styles.select}
        value={selectedStatus}
        options={options}
        onChange={handleChange}
      />
    </div>
  );
};
