import { Col, Row } from 'antd';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BlockTwoCol from '../../../components/BlockTwoCol';
import CustomSpace from '../../../components/BlockTwoCol/CustomSpace';
import CustomButton from '../../../components/CustomButton';
import ImageWithFallBack from '../../../components/ImageWithFallback';

const ContentWrapper = (materialNumber) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <h2 className="block-two-col__header">
        {t('productDetails.compatibleMachines.title')}
      </h2>
      <div className="block-two-col__content mb-5">
        {t('productDetails.compatibleMachines.description')}
      </div>
      <div className="block-two-col__footer">
        {/* jump to ProductMatchingPage */}
        <CustomSpace className="block-two-col__btn-wrapper">
          <CustomButton
            block
            onClick={() =>
              history.push(
                linkGenerator(`/product/${materialNumber}/suitableProducts`)
              )
            }
          >
            {t('productDetails.compatibleMachines.button.showAll')}
          </CustomButton>
        </CustomSpace>
      </div>
    </>
  );
};

function MachinesWrapper() {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <ImageWithFallBack src="https://s1.kaercher-media.com/media/image/selection/886/d0/geraete.webp" />
      </Col>
    </Row>
  );
}

function CompatibleMachines({ materialNumber }) {
  return (
    <BlockTwoCol
      className="compatible-machines product-details--padding-left product-details--padding-right"
      left={ContentWrapper(materialNumber)}
      right={MachinesWrapper()}
    />
  );
}

CompatibleMachines.propTypes = {
  materialNumber: PropTypes.string,
};

CompatibleMachines.defaultProps = {
  materialNumber: '',
};

export default CompatibleMachines;
