import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

function Content(props) {
  const { className, children } = props;
  return (
    <div className={`public-page__content__wrapper ${className}`}>
      <div className="public-page__content">{children}</div>
    </div>
  );
}

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Content.defaultProps = {
  className: null,
};

export default Content;
