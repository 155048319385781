import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Equipment = (props) => {
  const { t } = useTranslation();
  const { anchorId, equipments } = props;

  const renderEquipmentList = () =>
    equipments.map((equipment) => {
      return equipment ? (
        <li key={equipment}>
          <span>{equipment}</span>
        </li>
      ) : null;
    });

  return (
    <div className="equipment__wrapper pt-4 pb-4">
      <h3 id={anchorId}>{t('productDetails.equipment')}</h3>
      <ul className="equipment__list mt-4">{renderEquipmentList()}</ul>
    </div>
  );
};

Equipment.propTypes = {
  anchorId: PropTypes.string,
  equipments: PropTypes.arrayOf(PropTypes.string),
};
Equipment.defaultProps = {
  anchorId: '',
  equipments: [],
};

export default Equipment;
