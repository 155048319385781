import { AutoComplete } from 'antd';
import { SearchIcon } from 'components/Icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './B2becSearchSuggestion.module.scss';

function B2becSearchSuggestion({
  shouldShowRightButton,
  shouldDisableInput,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <AutoComplete
      defaultActiveFirstOption={false}
      showArrow={false}
      className={styles.autoComplete}
      {...restProps}
    >
      <div className={styles.searchSuggestion}>
        <span className={styles.icon}>
          <SearchIcon />
        </span>
        <input disabled={shouldDisableInput} />
        {shouldShowRightButton && (
          <span className={styles.searchButton}>
            {t('userManagement.search')}
          </span>
        )}
      </div>
    </AutoComplete>
  );
}

export default B2becSearchSuggestion;
