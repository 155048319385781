export const PAGE_TYPES = {
  STANDARD: 'standard',
  PRODUCT_DETAILS: 'product details page',
  CONTACT: 'contact page',
  MACHINE: 'machines page',
  CART: 'shopping cart page',
  CHECKOUT: 'checkout page',
  CONFIRMATION: 'confirmation pages',
  ORDER: 'order history page',
  ORDER_DETAILS: 'order details page',
  PRODUCT_MATCHING: 'products matching page',
  CATEGORIES: 'product categories page',
  SUB_CATEGORIES: 'product subcategories page',
  SEARCH_RESULTS: 'search product results page',
  PROFILE: 'my profile page',
  COMPARISON: 'products comparison page',
  SERVICE_CASES: 'service cases pages',
  VOUCHER: 'voucher management pages',
  RENTAL_MANAGEMENT: 'rental management page',
  PROMOTION: 'Promotion management pages',
  USER_MANAGEMENT: 'user management pages',
  PRODUCT_MANAGEMENT: 'product management pages',
  REGISTRATION: 'user registration pages',
  ROLE_MANAGEMENT: 'role management pages',
  POLICY: 'policy pages',
  ERROR: 'error page',
  WISHLIST: 'wishlists page',
  RENTAL: 'rental page',
  NOT_AUTHORIZED_PAGE: 'not authorized page',
};

const LINK_SCOPES = {
  SIDEBAR_NAVIGATION: 'sidebar_navigation',
  ORDER_HISTORY_OVERVIEW: 'orders_history_overview',
};

export const LINK_NAMES = {
  SIDEBAR_NAVIGATION: {
    MY_WORKSPACE: `${LINK_SCOPES.SIDEBAR_NAVIGATION}:my_workspace`,
    MY_MACHINES: `${LINK_SCOPES.SIDEBAR_NAVIGATION}:my_machines`,
    RENTAL: `${LINK_SCOPES.SIDEBAR_NAVIGATION}:rental`,
    ORDERS: `${LINK_SCOPES.SIDEBAR_NAVIGATION}:orders`,
    CONTACT: `${LINK_SCOPES.SIDEBAR_NAVIGATION}:contact_kaercher`,
    WISHLISTS: `${LINK_SCOPES.SIDEBAR_NAVIGATION}:wishlists`,
  },
  ORDER_HISTORY_OVERVIEW: {
    RE_ORDER: `${LINK_SCOPES.ORDER_HISTORY_OVERVIEW}:re-order`,
    ORDER_DETAIL: `${LINK_SCOPES.ORDER_HISTORY_OVERVIEW}:order_detail_`, // with <Order ID> at the end
  },
  PDF_EXPORT: 'pdf_export',
  APPLY_VOUCHER: 'apply_voucher',
};

// todo: rename it later
export const TRACK_DATA = {
  HOME: 'home',
  ONSITE: 'onsiteSearch',
  CATEGORIES: 'categories',
  PAGEINFO: 'pageInfo',
  CART: 'cart',
  MINICART: 'miniCart',
  COMPARISON: 'comparison',
  TRANSACTION: 'transaction',
  COMPANY: 'companyProfile',
  APPLICATION: 'application',
  PROFILE: 'profile',
  LINK_NAME: 'navigationLinkName',
};

export const PAGE_DEPENDENCIES = {
  [TRACK_DATA.HOME]: [
    TRACK_DATA.PROFILE,
    TRACK_DATA.APPLICATION,
    TRACK_DATA.MINICART,
  ],
  [TRACK_DATA.CART]: [TRACK_DATA.CART],
  [TRACK_DATA.ONSITE]: [TRACK_DATA.ONSITE],
  [TRACK_DATA.CATEGORIES]: [TRACK_DATA.CATEGORIES],
  [TRACK_DATA.COMPARISON]: [TRACK_DATA.COMPARISON],
  [TRACK_DATA.TRANSACTION]: [TRACK_DATA.TRANSACTION, TRACK_DATA.COMPANY],
  [TRACK_DATA.LINK_NAME]: [TRACK_DATA.LINK_NAME],
};
