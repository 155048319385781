import './styles.scss';

import B2BecLink from 'components/B2BLink';
import CustomButton from 'components/CustomButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const B2BEC_OVERVIEWGUIDE_TYPES = {
  MY_MACHINES: 'myMachines',
  MY_ORDERS: 'myOrders',
  PRODUCTS: 'products',
};

const B2BEC_OVERVIEWGUIDE_URL = {
  [B2BEC_OVERVIEWGUIDE_TYPES.MY_MACHINES]: '/my-machines',
  [B2BEC_OVERVIEWGUIDE_TYPES.MY_ORDERS]: '/orders',
  [B2BEC_OVERVIEWGUIDE_TYPES.PRODUCTS]: '/categories',
};

const RenderLink = ({ link, isExternalLink, children }) => {
  if (isExternalLink) {
    return (
      <a href={link} target="noreferrer">
        {children}
      </a>
    );
  }

  return <B2BecLink to={link}>{children}</B2BecLink>;
};

RenderLink.propTypes = {
  link: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export const B2becGuideBlock = (props) => {
  const { type, Icon, link, isExternalLink, scope } = props;
  const { t } = useTranslation();

  return (
    <div className="b2bec-overview-guide">
      <h3 className="overview-guide__title">{t(`${scope}.${type}.title`)}</h3>
      <div className="b2bec-overview-guide__icon">{Icon}</div>
      <div className="b2bec-overview-guide__description">
        <Trans
          i18nKey={`${scope}.${type}.description`}
          components={[<span key={link} />]}
        />
      </div>
      <div className="b2bec-overview-guide__button">
        <RenderLink link={link} isExternalLink={isExternalLink}>
          <CustomButton buttonSize="small" title={t(`${scope}.${type}.button`)}>
            <Trans i18nKey={`${scope}.${type}.button`} />
          </CustomButton>
        </RenderLink>
      </div>
    </div>
  );
};

B2becGuideBlock.defaultProps = {
  isExternalLink: false,
  scope: 'adminWorkspace',
};

B2becGuideBlock.propTypes = {
  Icon: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool,
  scope: PropTypes.string,
};

const B2becOverviewGuideBlock = ({ type }) => (
  <B2becGuideBlock
    Icon={
      <img
        // eslint-disable-next-line import/no-dynamic-require, global-require
        src={require(`../../assets/img/overviewGuide/${type}.webp`)}
        alt={type}
        width={63}
        height={62}
      />
    }
    type={type}
    scope="overviewGuide"
    link={B2BEC_OVERVIEWGUIDE_URL[type]}
  />
);

B2becOverviewGuideBlock.defaultProps = {
  type: B2BEC_OVERVIEWGUIDE_TYPES.MY_MACHINES,
};

B2becOverviewGuideBlock.propTypes = {
  type: PropTypes.oneOf([
    B2BEC_OVERVIEWGUIDE_TYPES.MY_MACHINES,
    B2BEC_OVERVIEWGUIDE_TYPES.MY_ORDERS,
    B2BEC_OVERVIEWGUIDE_TYPES.PRODUCTS,
  ]),
};

export default B2becOverviewGuideBlock;
