import { FAPI_CONFIGURATION_ENDPOINT } from 'libs/constants/b2bApiEndpoints';

import environment from '../../environments/environment.dev';
import mapConfigData from '../../store/sagas/apiDataMapping/configMapping';
import HttpClient from '../apiHttpClient';

class ConfigurationService {
  constructor(baseUrl) {
    this.httpClient = new HttpClient(baseUrl);
  }

  async getConfigsByNames(configArray) {
    const { data } = await this.httpClient.post('/list', {
      body: configArray,
    });
    return mapConfigData(data);
  }

  getCountryList() {
    return this.httpClient.get('/country');
  }
}

export const fapiConfigurationService = new ConfigurationService(
  `${environment.shopFAPIUrl}${FAPI_CONFIGURATION_ENDPOINT}`
);
