import './styles.scss';

import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import B2becTranslation from 'components/B2becTranslation';
import { PRODUCT_TYPES_TITLE } from 'libs/constants/productTypes';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const tableColumns = (isLoading) => {
  const renderDisplayStatus = (status) => {
    return isLoading ? (
      <Skeleton />
    ) : status ? (
      <CheckCircleFilled className="portfolio__status--checked" />
    ) : (
      <CloseCircleFilled className="portfolio__status--unchecked" />
    );
  };

  return [
    {
      title: <B2becTranslation value="productManagement.materialNumber" />,
      dataIndex: 'materialNumber',
      width: '9%',
      render: (text) => {
        return isLoading ? <Skeleton /> : <span>{text}</span>;
      },
    },
    {
      title: <B2becTranslation value="productManagement.productGroup" />,
      dataIndex: 'productGroup',
      width: '8%',
      render: (text) => {
        const productTypeTitle = PRODUCT_TYPES_TITLE[`${text}`]?.toLowerCase();

        return isLoading ? (
          <Skeleton />
        ) : (
          <B2becTranslation value={`productType.${productTypeTitle}`} />
        );
      },
    },
    {
      title: <B2becTranslation value="productManagement.productSubcategory1" />,
      dataIndex: 'subCategory1',
      width: '8%',
      render: (text) => {
        return isLoading ? <Skeleton /> : <span>{text}</span>;
      },
    },
    {
      title: <B2becTranslation value="productManagement.productSubcategory2" />,
      dataIndex: 'subCategory2',
      width: '8%',
      render: (text) => {
        return isLoading ? <Skeleton /> : <span>{text}</span>;
      },
    },
    {
      title: <B2becTranslation value="productManagement.productClass" />,
      dataIndex: 'productClass',
      width: '8%',
      render: (text) => {
        return isLoading ? <Skeleton /> : <span>{text}</span>;
      },
    },
    {
      title: <B2becTranslation value="productManagement.productName" />,
      dataIndex: 'productName',
      width: '8%',
      render: (text) => {
        return isLoading ? <Skeleton /> : <span>{text}</span>;
      },
    },
    {
      title: <B2becTranslation value="productManagement.displayInNavigation" />,
      dataIndex: 'displayInNavigation',
      width: '8%',
      render: (text) => <>{renderDisplayStatus(text)}</>,
    },
    {
      title: <B2becTranslation value="productManagement.displayListPrice" />,
      dataIndex: 'displayListPrice',
      width: '8%',
      render: (text) => <>{renderDisplayStatus(text)}</>,
    },
    {
      title: <B2becTranslation value="productManagement.displayDeliveryDate" />,
      dataIndex: 'displayDeliveryDate',
      width: '8%',
      render: (text) => <>{renderDisplayStatus(text)}</>,
    },
    {
      title: <B2becTranslation value="productManagement.displayAddToCart" />,
      dataIndex: 'displayAddToCart',
      width: '8%',
      render: (text) => <>{renderDisplayStatus(text)}</>,
    },
    {
      title: (
        <B2becTranslation value="productManagement.disableBProgramCheckbox" />
      ),
      dataIndex: 'disableBProgramCheckbox',
      width: '8%',
      render: (text) => <>{renderDisplayStatus(text)}</>,
    },
  ];
};

export default tableColumns;
