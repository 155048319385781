import axios from 'axios';
import environment from 'environments/environment.dev';

import { AuthenticationService } from './authenticationService';

class HttpClient {
  constructor(baseURL = environment.baseUrl) {
    this.httpClient = axios.create({
      baseURL,
      timeout: 1800000, // after 30 minutes
    });

    this.httpClient.CancelToken = axios.CancelToken;
    this.httpClient.isCancel = axios.isCancel;

    this.httpClient.interceptors.request.use(
      this._interceptBeforeRequest,
      this._interceptRequestError
    );

    this.httpClient.interceptors.response.use(
      this._interceptResponseData,
      this._interceptResponseError
    );
  }

  _interceptBeforeRequest = async (config) => {
    if (!config.url) {
      return Promise.reject(new Error('[ApiHttpClient] URL must not be blank'));
    }

    const accessToken = (await AuthenticationService.getTokens())?.jwt
      .accessToken;

    if (!config.headers.Authorization && accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  };

  _interceptRequestError = (error) => {
    // Do something with request error
    return Promise.reject(error);
  };

  _interceptResponseData = (response) => {
    // Do something with response data
    return response;
  };

  _interceptResponseError = (error) => {
    // Do something with response error
    return Promise.reject(error);
  };

  get(url, options = {}, retryOptions = {}) {
    return this.request('GET', url, options, retryOptions);
  }

  head(url, options = {}, retryOptions = {}) {
    return this.request('HEAD', url, options, retryOptions);
  }

  put(url, options = {}, retryOptions = {}) {
    return this.request('PUT', url, options, retryOptions);
  }

  patch(url, options = {}, retryOptions = {}) {
    return this.request('PATCH', url, options, retryOptions);
  }

  post(url, options = {}, retryOptions = {}) {
    return this.request('POST', url, options, retryOptions);
  }

  delete(url, options = {}, retryOptions = {}) {
    return this.request('DELETE', url, options, retryOptions);
  }

  options(url, options = {}, retryOptions = {}) {
    return this.request('OPTIONS', url, options, retryOptions);
  }

  /**
   * A callback to further control if a request should be retried. By default, it retries if the result did not have
   * a response. default: error => !error.response.
   * @param {Object} error - axios.AxiosError object.
   * @param {Object} error.response - Response object.
   * @returns {Boolean} The flag to decide if this request should be retried or not.
   */
  /**
   * General http request method.
   * @param {axios.Method} method - Http method.
   * @param {string} url - Request URL.
   * @param {Object} options - Axios request options.
   *
   * @returns {Object} Response of http request.
   */
  async request(method, url, options = {}) {
    const {
      headers,
      queryParams,
      paramsSerializer,
      body,
      cancelToken,
      transformRequest,
    } = options;

    if (
      body &&
      (method === 'GET' || method === 'HEAD' || method === 'OPTIONS')
    ) {
      const msg = 'GET, HEAD, OPTIONS must not have request body !';
      throw new Error(msg);
    }

    if (
      !body &&
      (method === 'POST' || method === 'PUT' || method === 'PATCH')
    ) {
      const msg = 'POST, PUT, PATCH must have request body !';
      throw new Error(msg);
    }

    const requestConfig = {
      headers,
      method,
      url,
      params: queryParams,
      paramsSerializer,
      data: body,
      cancelToken,
      transformRequest,
    };
    const response = await this.httpClient.request(requestConfig);

    return response;
  }

  cancelTokenSource() {
    return this.httpClient.CancelToken.source();
  }

  isCancel() {
    return this.httpClient.isCancel;
  }
}
export const httpClient = Object.freeze(new HttpClient());

export default HttpClient;
