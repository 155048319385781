import { Col, Row } from 'antd';
import { B2becPromotionItemSkeleton } from 'components/B2becPromotionItem';
import { DEFAULT_PROMOTION_ITEMS } from 'libs/constants';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import MaterialNumberInput from '../MaterialNumberInput';

const PromotionItemListSkeleton = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[30, 30]}>
      {DEFAULT_PROMOTION_ITEMS.map((_, index) => {
        const key = `skeleton__${index}`;

        return (
          <Col key={key} span={24} md={12} xl={8}>
            <div className="promotion-page__label">
              <Trans
                i18nKey="promotion.productLabel"
                values={{ productNo: index + 1 }}
              />
            </div>
            <div className="promotion-item">
              <MaterialNumberInput
                disabled
                placeholder={t('cart.table.itemNo')}
              />
              <B2becPromotionItemSkeleton />
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default PromotionItemListSkeleton;
