import { all } from 'redux-saga/effects';

import authenticationSaga from './authenticationSaga';
import cartSaga from './cartSaga';
import categorySaga from './categorySaga';
import configSaga from './configSaga';
import contactSaga from './contactSaga';
import customerSaga from './customerSaga';
import employeeSaga from './employeeSaga';
import machineSaga from './machineSaga';
import ordersSaga from './ordersSaga';
import productComparisonSaga from './productComparisonSaga';
import productPortfolioSaga from './productPortfolioSaga';
import productSaga from './productSaga';
import promotionSaga from './promotionSaga';
import rentalSaga from './rentalSaga';
import rightSaga from './rightSaga';
import roleSaga from './roleSaga';
import sapLogSaga from './sapLogSaga';
import searchSaga from './searchSaga';
import serviceCaseSaga from './serviceCaseSaga';
import setupSaga from './setupSaga';
import technicalSaga from './technicalSaga';
import userManagementSaga from './userManagementSaga';
import userSaga from './userSaga';
import voucherSaga from './voucherSaga';
import wishlistSaga from './wishlistSaga';

export default function* rootSaga() {
  yield all([
    authenticationSaga(),
    cartSaga(),
    userSaga(),
    customerSaga(),
    ordersSaga(),
    productSaga(),
    contactSaga(),
    machineSaga(),
    categorySaga(),
    searchSaga(),
    productComparisonSaga(),
    serviceCaseSaga(),
    voucherSaga(),
    userManagementSaga(),
    promotionSaga(),
    employeeSaga(),
    roleSaga(),
    rightSaga(),
    technicalSaga(),
    sapLogSaga(),
    configSaga(),
    productPortfolioSaga(),
    wishlistSaga(),
    rentalSaga(),
    setupSaga(),
  ]);
}
