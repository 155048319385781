import { useEffect, useState } from 'react';

import {
  EXTREME_SMALL_DEVICE_WIDTH,
  IPAD_LANDSCAPE_WIDTH,
  VERY_SMALL_DEVICE_WIDTH,
} from '../libs/constants';
import BREAKPOINTS from '../libs/constants/breakpoints';

const TIMEOUT = 275;

const debounce = (func, wait, immediate) => {
  let timeout;
  return (...args) => {
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(this, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(this, args);
    }
  };
};

const getWindowWidth = () => {
  if (typeof window === 'undefined') {
    return 1024;
  }
  return window.innerWidth;
};

const useDeviceDetect = () => {
  const [windowWidth, setInnerWidth] = useState(getWindowWidth());

  useEffect(() => {
    const handleResize = debounce(() => {
      setInnerWidth(getWindowWidth());
    }, TIMEOUT);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isExtraSmallScreen = windowWidth < BREAKPOINTS.sm;
  const isSmallScreen = windowWidth >= BREAKPOINTS.sm;
  const isMediumScreen = windowWidth >= BREAKPOINTS.md;
  const isLargeScreen = windowWidth >= BREAKPOINTS.lg;
  const isXLScreen = windowWidth >= BREAKPOINTS.xl;
  const isXXLScreen = windowWidth >= BREAKPOINTS.xxl;
  const isExtremeSmallMobile = windowWidth <= EXTREME_SMALL_DEVICE_WIDTH;
  const isVerySmallMobile = windowWidth <= VERY_SMALL_DEVICE_WIDTH;
  const isMobile = windowWidth < BREAKPOINTS.md;
  const isIpadLandscape = windowWidth <= IPAD_LANDSCAPE_WIDTH;

  return {
    windowWidth,
    isExtremeSmallMobile,
    isIpadLandscape,
    isMobile,
    isVerySmallMobile,
    isExtraSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isXLScreen,
    isXXLScreen,
  };
};

export default useDeviceDetect;
