import { LoadingOutlined } from '@ant-design/icons';
import B2becButton from 'components/B2becButton';
import { PlusIcon } from 'components/Icons';
import LoadingSkeleton from 'components/Skeletons';
import useHover from 'hooks/useHover';
import { useMediaQuery } from 'hooks/useMediaQuery';
import BREAKPOINTS from 'libs/constants/breakpoints';
import PropTypes, { oneOfType } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './WishlistItem.module.scss';

export function WishlistItem({
  wishlistId,
  wishlistName,
  totalProduct,
  loadingIds,
  onClick,
}) {
  const { t } = useTranslation();
  const hoverRef = React.useRef(null);
  const isHover = useHover(hoverRef);
  const isLargerThan992 = useMediaQuery(`(min-width:${BREAKPOINTS.lg}px)`);

  return (
    <div ref={hoverRef} className={styles.wrapper}>
      <div className={styles.content}>
        <span className={styles.name}>{wishlistName}</span>
        <span className={styles['sub-information']}>
          {t('plural.productWithCount', { count: totalProduct ?? 0 })}
        </span>
      </div>
      <div className={styles.action}>
        {isLargerThan992 && isHover ? (
          <B2becButton
            ghost
            loading={loadingIds[wishlistId]}
            className={styles['add-button']}
            onClick={onClick}
          >
            {t('buttonTexts.add')}
          </B2becButton>
        ) : null}
        {!isLargerThan992 && (
          <div className={styles.action}>
            <button
              type="button"
              className={`button-as-link ${styles['add-button']}`}
              onClick={onClick}
            >
              {loadingIds[wishlistId] ? <LoadingOutlined /> : <PlusIcon />}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function WishlistItemLoading() {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.content} ${styles.skeletonContent}`}>
        <span className={styles.name}>
          <LoadingSkeleton style={{ width: '100%', height: 20 }} />
        </span>
        <span className={styles['sub-information']}>
          <LoadingSkeleton style={{ width: '100%', height: 20 }} />
        </span>
      </div>
    </div>
  );
}

WishlistItem.Loading = WishlistItemLoading;

WishlistItem.propTypes = {
  wishlistId: PropTypes.string.isRequired,
  wishlistName: PropTypes.string.isRequired,
  totalProduct: PropTypes.number.isRequired,
  loadingIds: oneOfType([PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
};
