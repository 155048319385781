import { adminFapiRental } from 'services/adminFapi';
import { NotificationManager } from '../../components/Notification';
import {
  addProductToRentalList,
  addProductToRentalListSuccess,
  deleteProductFromRentableList,
  deleteProductFromRentableListSuccess,
  getRentableProductList,
  getRentableProductListError,
  getRentableProductListSuccess,
} from '../slices/rentalSlice';
import { put, takeLatest } from 'redux-saga/effects';

function* handleGetRentableProductList({ payload }) {
  try {
    const response = yield adminFapiRental.getRentableProductList(payload);
    if (response?.status === 200) {
      yield put(getRentableProductListSuccess(response.data));
    } else {
      yield put(getRentableProductListError());
    }
  } catch (error) {
    yield put(getRentableProductListError());
    NotificationManager.error({
      message: 'notification.error.getRentalList',
    });
  }
}

function* handleDeleteProductFromRentableList({ payload }) {
  try {
    const {
      materialNumber,
      countryId,
      currentPage,
      totalCount,
      pageSize,
      sortBy,
      orderBy,
    } = payload;
    const response = yield adminFapiRental.deleteProductFromRentableList({
      materialNumber,
      countryId,
    });
    if (response?.status === 200) {
      NotificationManager.success({
        message: 'notification.success.deleteRental',
      });
      //Handle the case: user delete the last item of the page
      let newPageNumber = currentPage;
      if (
        totalCount - 1 > 0 &&
        totalCount - 1 === (currentPage - 1) * pageSize
      ) {
        newPageNumber = currentPage - 1;
      }
      yield put(
        getRentableProductList({
          pageNumber: newPageNumber,
          pageSize,
          sortBy,
          orderBy,
        })
      );
    } else {
      yield put(deleteProductFromRentableListSuccess(payload.materialNumber));
    }
  } catch (error) {
    NotificationManager.error({
      message: 'notification.error.addRental',
    });
  }
}

function* addRental({ payload }) {
  try {
    const response = yield adminFapiRental.addRental(payload);
    if (response?.status === 200) {
      yield put(addProductToRentalListSuccess(payload));
      NotificationManager.success({
        message: 'notification.success.addRental',
      });
    }
  } catch (error) {
    NotificationManager.error({
      message: 'notification.error.addRental',
    });
  }
}

export default function* rentalSaga() {
  yield takeLatest(getRentableProductList.type, handleGetRentableProductList);
  yield takeLatest(
    deleteProductFromRentableList.type,
    handleDeleteProductFromRentableList
  );
  yield takeLatest(addProductToRentalList.type, addRental);
}
