import './styles.scss';

import BackToTopButton from 'components/BackToTopButton';
import Footer from 'components/Layout/components/Footer';
import Content from 'components/PublicHeader/Content';
import Header from 'components/PublicHeader/Header';
import Headline from 'components/PublicHeader/Headline';
import ScrollToTop from 'components/ScrollToTop';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_TYPES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import { getLanguageCode } from 'libs/utils/adobeAnalysis';
import { handleChangeLanguage, linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fapiInvitationService } from 'services/fapi';
import { trackApplication } from 'store/slices/adobeAnalyticSlice';

import CustomButton from '../../components/CustomButton';
import { NotificationManager } from '../../components/Notification';
import useAsync from '../../hooks/useAsync';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useModal from '../../hooks/useModal';
import { ASYNC_STATUS, STATUS_CODE } from '../../libs/constants';
import Introduction from './components/Introduction';
import RegistrationForm from './components/RegistrationForm';

function RegistrationPage(props) {
  const { title, pageId } = props;
  useDocumentTitle(title);

  const { token, language, countryId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const hiddenToken = useRef(token);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [invitationInfo, setInvitationInfo] = useState({});

  const dispatch = useDispatch();
  useAdobeAnalysis(TRACK_DATA.APPLICATION, {
    pageId,
    pageName: 'registration',
    pageType: PAGE_TYPES.REGISTRATION,
  });

  useEffect(() => {
    dispatch(trackApplication({ language: getLanguageCode(language) }));
  }, [language, dispatch]);

  // Registration invitation is revoked
  const { Modal, toggleModal } = useModal();
  const [showRevokeNoticeModal, setShowRevokeNoticeModal] = useState(false);

  useEffect(() => {
    if (token) {
      window.history.replaceState(null, document.title, '/registration');
    }
  }, [token]);

  useEffect(() => {
    if (language && countryId) {
      handleChangeLanguage(language, countryId);
    }
  }, [history, language, countryId]);

  useEffect(() => {
    if (showRevokeNoticeModal) {
      toggleModal(true);
    }
  }, [showRevokeNoticeModal, toggleModal]);

  const validateToken = useCallback(
    () => fapiInvitationService.validateRegistrationToken(hiddenToken.current),
    []
  );

  const onSuccess = useCallback(
    (response) => {
      if (response?.status === STATUS_CODE.SUCCESS) {
        const { data } = response;

        setIsTokenValid(!!data?.isValid);
        if (data?.isValid) {
          if (data?.value?.invitationStatus === 'Revoked') {
            setShowRevokeNoticeModal(true);
          } else {
            setInvitationInfo(data?.value || {});
          }
        } else {
          NotificationManager.error({
            message: 'notification.error.validateIdentity.invalid.message',
            description:
              'notification.error.validateIdentity.invalid.description',
            duration: 3,
            onClose: () => history.replace(linkGenerator('')),
          });
        }
      }
    },
    [history]
  );

  const onError = useCallback((error) => {
    NotificationManager.error({
      message: 'notification.error.validateIdentity.failed.message',
      description: 'notification.error.validateIdentity.failed.description',
    });
    console.error(error);
  }, []);

  const { status } = useAsync(validateToken, !!token, onSuccess, onError);

  const isLoading = status === ASYNC_STATUS.PENDING;

  const onOk = () => {
    toggleModal(false);
    history.replace('');
  };

  const renderModalAction = () => (
    <div className="registration-page__revoked-notice-modal__action">
      <CustomButton onClick={onOk}>
        {t('registration.form.revokedNoticeModal.actionBtn')}
      </CustomButton>
    </div>
  );

  return (
    <div className="registration-page__wrapper">
      <ScrollToTop />
      <Header />
      <Headline />
      <Introduction />
      <Content className="registration-page__divider__wrapper">
        <div className="registration-page__divider" />
      </Content>
      <RegistrationForm
        disabledSubmit={showRevokeNoticeModal}
        isLoading={isLoading}
        isTokenValid={isTokenValid}
        invitationToken={hiddenToken.current}
        {...invitationInfo}
      />
      <Footer />
      <BackToTopButton />
      <Modal
        className="registration-page__revoked-notice-modal"
        title={t('registration.form.revokedNoticeModal.title')}
        width={570}
        closable={false}
        footer={renderModalAction()}
      >
        {t('registration.form.revokedNoticeModal.content')}
      </Modal>
    </div>
  );
}

RegistrationPage.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

// RegistrationPage.defaultProps = {};

export default RegistrationPage;
