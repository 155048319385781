import './styles.scss';

import { Button } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const FilterBar = ({
  classTypes,
  onFilter,
  hasClassTypeId,
  allClass,
  isIpadLandscape,
}) => {
  ///
  // If property 'hasClassTypeId' equals null => initial selected value is 'All type'
  // else, selected value will be 'hasClassTypeId'
  // In Desktop view, classTypes include 'All' type, that is the first element in the array
  // In Mobile view, classTypes don't include 'All' type which is a seperate property "allClass"
  ///
  const [selectedClassTypeId, setSelectedClassTypeId] = useState(
    hasClassTypeId ||
      (isIpadLandscape ? allClass.categoryId : classTypes[0]?.categoryId)
  );

  const listElement = useRef(null);

  const [classIsScrollable, setClassIsScrollable] = useState(false);

  useEffect(() => {
    // check if classes make the wrapper scrollable
    if (listElement.current?.scrollWidth > listElement.current?.offsetWidth) {
      setClassIsScrollable(true);
    }
  }, [listElement, classTypes]);

  // if there are no classes or classe don't make the wrapper scrollable, remove padding bottom
  const paddingBotValue = useMemo(
    () => (classTypes?.length === 0 || !classIsScrollable) && '0',
    [classTypes, classIsScrollable]
  );

  const filteredByClassTypeId = useCallback(
    (classTypeID) => {
      setSelectedClassTypeId(classTypeID);
      onFilter(classTypeID);
    },
    [onFilter]
  );

  const renderAllClassButton = useCallback(() => {
    const classes = clsx('filter-bar__list__item', {
      'filter-bar__list__item--selected':
        +selectedClassTypeId === allClass.categoryId,
    });

    return (
      isIpadLandscape && (
        <Button
          className={`${classes} filter-bar__list__item--top`}
          onClick={() => filteredByClassTypeId(allClass.categoryId)}
        >
          {allClass.categoryName} ({allClass.productCount})
        </Button>
      )
    );
  }, [allClass, filteredByClassTypeId, selectedClassTypeId, isIpadLandscape]);

  const renderClassTypes = useCallback(() => {
    return classTypes.map((classType) => {
      const classes = clsx('filter-bar__list__item', {
        'filter-bar__list__item--selected':
          +selectedClassTypeId === classType.categoryId,
      });

      return (
        <li key={classType.categoryId}>
          <Button
            className={classes}
            onClick={() => filteredByClassTypeId(classType.categoryId)}
          >
            {classType.categoryName} ({classType.productCount})
          </Button>
        </li>
      );
    });
  }, [classTypes, filteredByClassTypeId, selectedClassTypeId]);

  return (
    <div
      className="filter-bar__wrapper"
      ref={listElement}
      style={{ paddingBottom: paddingBotValue }}
    >
      {renderAllClassButton()}
      <ul className="filter-bar__list">{renderClassTypes()}</ul>
    </div>
  );
};

export default FilterBar;

FilterBar.propTypes = {
  classTypes: PropTypes.arrayOf(PropTypes.shape),
  onFilter: PropTypes.func.isRequired,
  hasClassTypeId: PropTypes.string,
  allClass: PropTypes.objectOf(PropTypes.shape),
  isIpadLandscape: PropTypes.bool,
};

FilterBar.defaultProps = {
  classTypes: [],
  hasClassTypeId: '',
  allClass: {},
  isIpadLandscape: false,
};
