import PropTypes from 'prop-types';
import React from 'react';

function B2becActionButton({ children, onClick, classNames, style, ...props }) {
  return (
    <button
      type="button"
      className={`table-action-button ${classNames}`}
      style={style}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}

B2becActionButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  classNames: PropTypes.string,
  style: PropTypes.shape({}),
};

B2becActionButton.defaultProps = {
  onClick: () => {},
  classNames: '',
  style: {},
};

export default B2becActionButton;
