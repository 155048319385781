import './styles.scss';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const MonthSelection = (props) => {
  const { selectedMonth, months, onChange } = props;
  const handleMonthPrev = () => {
    onChange(selectedMonth - 1);
  };

  const handleMonthNext = () => {
    onChange(selectedMonth + 1);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="month-selection"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Button onClick={handleMonthPrev}>
        <LeftOutlined />
      </Button>

      <span className="month-selection__text">{months[selectedMonth]}</span>
      <Button onClick={handleMonthNext}>
        <RightOutlined />
      </Button>
    </div>
  );
};
MonthSelection.propTypes = {
  selectedMonth: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  months: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};
export default MonthSelection;
