import { NotificationManager } from 'components/Notification';
import { useAsync } from 'hooks';
import {
  ASYNC_STATUS,
  PAGE_SIZE,
  SORT_FIELDS,
  SORT_ORDERS,
} from 'libs/constants';
import { CUSTOMER_USER } from 'libs/constants/user';
import { useCallback, useState } from 'react';
import { adminFapiSupport } from 'services/adminFapi';

const getUsersListByEmail = (countryId) => (email) => {
  return adminFapiSupport.getUsersList({
    current: 1,
    pageSize: PAGE_SIZE[10],
    sortBy: SORT_FIELDS.lastName,
    orderBy: SORT_ORDERS.ascend,
    searchTerm: email,
    selectedLetter: '',
    country: countryId,
    userType: CUSTOMER_USER,
  });
};

export function useGetUsersListByEmail(countryId) {
  const [emailsList, setEmailsList] = useState([]);

  const handleGetUsersListSuccess = useCallback(
    (response) => {
      const { data } = response;
      const transformedOptions = data?.items?.map((user) => ({
        value: user?.email,
        label: user?.email,
        data: {
          customerNumber: user?.sapCustomerNumber,
          countryId,
          userId: user?.userId,
        },
      }));
      setEmailsList(transformedOptions);
    },
    [countryId]
  );

  const handleGetUsersListError = useCallback((error) => {
    NotificationManager.error({
      message: 'notification.error.userDetails',
    });
    console.error('Failed to fetch users list:', error);
  }, []);

  const getUsersList = useCallback(
    (email) => {
      return getUsersListByEmail(countryId)(email);
    },
    [countryId]
  );

  const {
    execute: executeGetUsersListByEmail,
    status: fetchingUsersListStatus,
  } = useAsync(
    getUsersList,
    false,
    handleGetUsersListSuccess,
    handleGetUsersListError
  );

  const isFetchingUsersList = fetchingUsersListStatus === ASYNC_STATUS.PENDING;

  return {
    emailsList,
    isFetchingUsersList,
    executeGetUsersListByEmail,
  };
}
