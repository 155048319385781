import { Avatar } from 'antd';
import AddProductImage from 'assets/img/addproduct.svg';
import B2becTranslation from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import FormatPrice from 'components/FormatPrice';
import { InfoIcon } from 'components/Icons';
import { itemCategories } from 'libs/constants/cart';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import CartItemName from '../CartItemName';
import RemoveCartItem from '../RemoveCartItem';
import CartItemQuantityWrapper from './CartItemQuantityWrapper';

const hasQuantityChanged = (record) => {
  if (record?.quantity === undefined || record?.reqQuantity === undefined) {
    return false;
  }

  return record?.quantity !== record?.reqQuantity;
};

const renderAnnoucement = (record) => {
  const { materialNumber, replacedByMaterialNumber, hasMinQuantity } = record;

  // OLD PRODUCT SECTION - record has `replacedByMaterialNumber`
  if (replacedByMaterialNumber) {
    return (
      <div className="replaced-annoucement">
        <B2becTranslation
          value="cart.replacedAnnoucement"
          isComponent
          variables={{
            materialNumber,
            replacedByMaterialNumber,
          }}
          // eslint-disable-next-line react/jsx-key
          htmlTags={[<strong />]}
        />
      </div>
    );
  }

  if (hasMinQuantity) {
    return (
      <div className="moq-annoucement">
        <B2becTranslation
          value="cart.moqHintMessages"
          isComponent
          // eslint-disable-next-line react/jsx-key
          htmlTags={[<strong />]}
        />
      </div>
    );
  }

  if (hasQuantityChanged(record)) {
    return (
      <div className="moq-annoucement">
        <B2becTranslation
          value="cart.quantityChanged"
          isComponent
          // eslint-disable-next-line react/jsx-key
          htmlTags={[<strong />]}
        />
      </div>
    );
  }

  return null;
};

const tableColumns = ({
  isLoading,
  handleCellClick,
  shouldShowRecyclingFee,
  t,
  confirmReplacementHandler,
  isInsideSupportArea,
  confirmMoqHandler,
  shouldDisplayVoucherLevel,
  currencyUnit,
  hasDeleteProductFromCartPermission,
  hasViewIndividualPricePermision,
}) => {
  const isDisabledOnClick = (materialNumber) =>
    isLoading ? null : handleCellClick(materialNumber);

  const isOldProductSection = (record) => !!record?.replacedByMaterialNumber;
  const isReplacedSection = (record) => !!record?.oldMaterialNumber;
  const hasMOQ = (record) => !!record?.hasMinQuantity;

  const isQuickEntryRow = (materialNumber) =>
    typeof materialNumber !== 'string';

  return [
    {
      dataIndex: 'imgUrl',
      onCell: ({ materialNumber, ...record }) => ({
        onClick: () => {
          if (isReplacedSection(record)) {
            return null;
          }
          return isDisabledOnClick(materialNumber);
        },
      }),
      render: (text, record) => {
        const renderRemoveButton = () => {
          if (isReplacedSection(record)) {
            // NOTED: when removing item in replacement section
            // the old product will be removed, not the new one
            // so we pass the OLD MATERIAL NUMBER to the removal function
            return (
              <RemoveCartItem
                materialNumber={record?.oldMaterialNumber}
                isOnFlexStart
              />
            );
          }
          if (hasMOQ(record)) {
            return (
              <RemoveCartItem
                materialNumber={record?.materialNumber}
                isOnFlexStart
              />
            );
          }
          return null;
        };

        return isLoading ? (
          <Skeleton />
        ) : (
          <>
            {renderAnnoucement(record)}
            <Avatar size={80} shape="square" src={text || AddProductImage} />
            {!isInsideSupportArea && renderRemoveButton()}
          </>
        );
      },
    },
    {
      title: t('cart.table.itemNo'),
      dataIndex: 'materialNumber',
      onCell: ({ materialNumber }) => ({
        onClick: () => isDisabledOnClick(materialNumber),
      }),
      render: (text, { error }) => {
        if (isLoading) {
          return <Skeleton />;
        }

        return error ? (
          <>
            <InfoIcon style={{ verticalAlign: 'middle' }} /> {text}
          </>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: t('cart.table.itemName'),
      dataIndex: 'name',
      onCell: ({ materialNumber }) => ({
        onClick: () => isDisabledOnClick(materialNumber),
      }),
      render: (_, record) =>
        isLoading ? (
          <Skeleton />
        ) : (
          <CartItemName
            data={record}
            shouldShowRecyclingFee={shouldShowRecyclingFee}
          />
        ),
    },
    {
      title: t('cart.table.quantity'),
      dataIndex: 'quantity',
      align: 'center',
      render: (
        _,
        {
          materialNumber,
          quantity,
          error,
          hasMinQuantity,
          originalMaterial,
          itemCateg,
          ...record
        }
      ) => {
        const isBonusItem =
          !!originalMaterial && itemCateg === itemCategories.bonusMaterial;

        const quantityPart = (productId, isShowRemovePart = true) => {
          const shouldShowRemovePart =
            hasDeleteProductFromCartPermission &&
            isShowRemovePart &&
            !isInsideSupportArea &&
            !isBonusItem;
          const shouldDisableQuantityPart =
            !!error || isInsideSupportArea || isBonusItem;

          return (
            <>
              <CartItemQuantityWrapper
                materialNumber={productId}
                quantity={quantity}
                isParentDisabled={shouldDisableQuantityPart}
                hasMinQuantity={hasMinQuantity}
              />
              {shouldShowRemovePart && (
                <RemoveCartItem materialNumber={productId} />
              )}
            </>
          );
        };

        const renderQuantityPart = () => {
          if (isOldProductSection(record) || isQuickEntryRow(materialNumber)) {
            return null;
          }
          if (isReplacedSection(record)) {
            return quantityPart(record?.oldMaterialNumber, false);
          }
          if (hasMinQuantity) {
            return quantityPart(materialNumber, false);
          }

          return quantityPart(materialNumber);
        };

        return isLoading ? <Skeleton /> : renderQuantityPart();
      },
    },
    {
      title: hasViewIndividualPricePermision
        ? t('cart.table.individualPrice')
        : '',
      dataIndex: 'price',
      align: 'right',
      onCell: ({ materialNumber }) => ({
        onClick: () => isDisabledOnClick(materialNumber),
      }),
      render: (
        text,
        { quantity, reqQuantity, materialNumber, discount, ...record }
      ) => {
        if (!hasViewIndividualPricePermision) {
          return null;
        }
        const renderInvidualPrice = () => {
          if (isOldProductSection(record) || hasQuantityChanged(record)) {
            return null;
          }
          return (
            <>
              <FormatPrice price={text} currencyUnit={currencyUnit} />

              {shouldDisplayVoucherLevel({ discount }) ? (
                <div className="voucher-text">
                  {t('cart.table.includedVoucher')}
                </div>
              ) : null}
            </>
          );
        };

        return isLoading ? <Skeleton /> : renderInvidualPrice();
      },
    },
    {
      title: t('total'),
      dataIndex: 'total',
      align: 'right',
      hidden: !hasViewIndividualPricePermision,
      onCell: ({ materialNumber, ...record }) => ({
        onClick: () => {
          if (isReplacedSection(record)) {
            return null;
          }
          return isDisabledOnClick(materialNumber);
        },
      }),
      render: (
        text,
        { quantity, reqQuantity, materialNumber, discount, ...record }
      ) => {
        const renderConfirmButton = () => {
          if (isReplacedSection(record)) {
            return (
              <CustomButton
                className="border-section--confirm-btn"
                onClick={() =>
                  confirmReplacementHandler(
                    record?.oldMaterialNumber,
                    materialNumber,
                    quantity
                  )
                }
              >
                {t('buttonTexts.confirm')}
              </CustomButton>
            );
          }

          if (hasQuantityChanged(record)) {
            return (
              <CustomButton
                className="border-section--confirm-btn"
                onClick={(e) =>
                  confirmMoqHandler(e, {
                    materialNumber,
                    quantity,
                  })
                }
              >
                {t('buttonTexts.confirm')}
              </CustomButton>
            );
          }

          return null;
        };

        const renderTotalPrice = () => {
          if (isOldProductSection(record) || hasQuantityChanged(record)) {
            return null;
          }

          return (
            <div className="price-column">
              <FormatPrice
                price={text}
                currencyUnit={currencyUnit}
                className="price-column__price boldText"
              />

              {shouldDisplayVoucherLevel({ discount }) ? (
                <FormatPrice
                  price={discount}
                  currencyUnit={currencyUnit}
                  className="price-column__price--voucher"
                />
              ) : null}
            </div>
          );
        };

        return isLoading ? (
          <Skeleton />
        ) : (
          <>
            {renderTotalPrice()}
            {!isInsideSupportArea && renderConfirmButton()}
          </>
        );
      },
    },
  ].filter((col) => !col.hidden);
};
export default tableColumns;
