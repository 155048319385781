import adobe from './adobeAnalyticSlice';
import { apiSlice } from './apiSlice';
import authentication from './authenticationSlice';
import breadcrumb from './breadcrumbSlice';
import cart from './cartSlice';
import category from './categorySlice';
import config from './configSlice';
import employee from './employeeSlice';
import machine from './machineSlice';
import menu from './menuSlice';
import orders from './orderSlice';
import portfolio from './portfolioSlice';
import promotion from './promotionSlice';
import right from './rightSlice';
import role from './roleSlice';
import sapLog from './SAPLogSlice';
import search from './searchSlice';
import serviceCase from './serviceCaseSlice';
import supportArea from './supportAreaSlice';
import technical from './technicalSlice';
import user from './userSlice';
import usersManagement from './usersManagementSlice';
import rental from './rentalSlice';
import wishlist from './wishlistSlice';
import contact from './contactSlice';
import customer from './customerSlice';
import productComparison from './productComparisonSlice';
import product from './productSlice';
import voucher from './voucherSlice';
import setup from './setupSlice';

export default {
  authentication,
  breadcrumb,
  cart,
  customer,
  orders,
  product,
  contact,
  machine,
  rental,
  category,
  search,
  productComparison,
  serviceCase,
  menu,
  voucher,
  usersManagement,
  promotion,
  sapLog,
  config,
  portfolio,
  employee,
  role,
  right,
  technical,
  user,
  wishlist,
  [apiSlice.reducerPath]: apiSlice.reducer,
  supportArea,
  adobe,
  setup,
};
