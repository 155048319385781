import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import WishlistItem, { WishlistItemSkeleton } from './WishlistItem';

const WishlistList = (props) => {
  const { items, isLoading } = props;
  if (isLoading) {
    return (
      <Row gutter={[24, 24]}>
        {[0, 1, 2, 3, 4, 5].map((item) => (
          <Col key={item} xl={8} md={12} span={24}>
            <WishlistItemSkeleton />
          </Col>
        ))}
      </Row>
    );
  }
  return (
    <div>
      <Row gutter={[24, 24]}>
        {items.map((item) => (
          <Col key={item.wishlistId} xl={8} md={12} span={24}>
            <WishlistItem {...item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

WishlistList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      wishlistId: PropTypes.string,
      wishlistName: PropTypes.string,
      collaboratorCount: PropTypes.number,
      productCount: PropTypes.number,
      productPictures: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  isLoading: PropTypes.bool,
};

WishlistList.defaultProps = {
  isLoading: false,
};

export default React.memo(WishlistList);
