import { Checkbox, Col, Row } from 'antd';
import { USER_STATUS_ARRAY } from 'libs/constants/user';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const UserStatusCheckbox = ({ value, onChange, isDisabled }) => {
  const { t } = useTranslation();

  const renderStatusLabel = (labelValue) => {
    return t(
      `adminWorkspace.userManagement.userDetails.userStatus.${labelValue}`
    );
  };

  return (
    <Checkbox.Group value={value} disabled={isDisabled}>
      <Row gutter={[12, 12]}>
        {USER_STATUS_ARRAY.map((userStatus) => (
          <Col key={userStatus.value}>
            <Checkbox
              value={userStatus.value}
              onChange={() => onChange(userStatus.value)}
            >
              {renderStatusLabel(userStatus.label)}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};

UserStatusCheckbox.defaultProps = {
  value: undefined,
  onChange: undefined,
  isDisabled: false,
};

UserStatusCheckbox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default UserStatusCheckbox;
