import clsx from 'clsx';
import { BillingAddress } from 'components/DisplayAddress';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../SearchAddress.module.scss';

const DeliveryAddressItem = (props) => {
  const { address, value, onChange } = props;
  return (
    <div className={styles.address} key={address.partnerNumber}>
      <div
        aria-hidden="true"
        className={clsx(
          styles.addressInner,
          value?.partnerNumber === address.partnerNumber &&
            styles.addressSelected
        )}
        onClick={() => onChange(address)}
      >
        <BillingAddress data={address} />
      </div>
    </div>
  );
};

DeliveryAddressItem.propTypes = {
  address: PropTypes.shape({}).isRequired,
  value: PropTypes.shape({ partnerNumber: PropTypes.string }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DeliveryAddressItem;
