import { createSlice } from '@reduxjs/toolkit';
import { ACTION_STATUS } from 'libs/constants';

const initialState = {
  isLoading: false,
  getProductSubmenuCategoryFetchStatus: ACTION_STATUS.DEFAULT,
  productCategories: [],
  productOverview: {
    classTypes: [],
    isLoading: false,
    productCount: 0,
    products: [],
    pagination: {
      total: 0,
    },
    images: [],
    categoryName: '',
    text: '',
    actionStatus: ACTION_STATUS.DEFAULT,
    categoryPath: [],
  },
  isLoadingSubCategoryOverview: false,
  subCategoryOverviewData: {
    categoryId: '',
    categoryName: '',
    subCategories: [
      {
        categoryId: '',
        categoryName: '',
        subCategories: [
          {
            categoryId: '',
            categoryName: '',
            images: [],
            text: '',
          },
        ],
        images: [],
        text: '',
      },
    ],
    images: [],
    text: '',
  },
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getSubmenuProductCategoryList: () => {},
    getSubmenuProductCategoryListSuccess: (state, { payload }) => {
      state.getProductSubmenuCategoryFetchStatus = ACTION_STATUS.SUCCESS;
      state.productCategories = payload;
    },
    getSubmenuProductCategoryListError: (state) => {
      state.getProductSubmenuCategoryFetchStatus = ACTION_STATUS.FAILED;
    },
    getProductsByClassType: (state) => {
      state.productOverview.isLoading = true;
      state.productOverview.actionStatus = ACTION_STATUS.DEFAULT;
    },
    getProductsByClassTypeSuccess: (
      state,
      { payload: { data, onlyProduct } }
    ) => {
      const {
        products,
        productCount,
        subCategories,
        images,
        categoryName,
        text,
        categoryPath,
      } = data;
      if (!onlyProduct) {
        state.productOverview.classTypes = subCategories;
        state.productOverview.productCount = productCount;
      }
      state.productOverview.pagination.total = productCount;
      state.productOverview.isLoading = false;
      state.productOverview.products = products;
      state.productOverview.images = images;
      state.productOverview.categoryName = categoryName;
      state.productOverview.text = text;
      state.productOverview.actionStatus = ACTION_STATUS.SUCCESS;
      state.productOverview.categoryPath = categoryPath;
    },
    getProductsByClassTypeError: (state) => {
      state.productOverview.isLoading = false;
      state.productOverview.actionStatus = ACTION_STATUS.FAILED;
    },
    getClassTypesAndProductCount: (state) => {
      state.productOverview.actionStatus = ACTION_STATUS.DEFAULT;
    },
    getClassTypesAndProductCountSuccess: (state, { payload }) => {
      const { subCategories, productCount } = payload;
      state.productOverview.classTypes = subCategories;
      state.productOverview.productCount = productCount;
      state.productOverview.actionStatus = ACTION_STATUS.SUCCESS;
    },
    getClassTypesAndProductCountError: (state) => {
      state.productOverview.actionStatus = ACTION_STATUS.FAILED;
    },
    getProductSubCategoryOverviewData: (state) => {
      state.isLoadingSubCategoryOverview = true;
    },
    getProductSubCategoryOverviewDataSuccess: (state, { payload }) => {
      state.isLoadingSubCategoryOverview = false;
      state.subCategoryOverviewData = payload;
    },
    getProductSubCategoryOverviewDataError: (state) => {
      state.isLoadingSubCategoryOverview = false;
    },
    resetProductSubCategoryOverviewData: (state) => {
      state.subCategoryOverviewData = initialState.subCategoryOverviewData;
    },
    resetProductOverviewData: (state) => {
      state.productOverview = initialState.productOverview;
    },
  },
});

export const {
  getSubmenuProductCategoryList,
  getSubmenuProductCategoryListSuccess,
  getSubmenuProductCategoryListError,
  getProductsByClassType,
  getProductsByClassTypeSuccess,
  getProductsByClassTypeError,
  getClassTypesAndProductCount,
  getClassTypesAndProductCountSuccess,
  getClassTypesAndProductCountError,
  getProductSubCategoryOverviewData,
  getProductSubCategoryOverviewDataSuccess,
  getProductSubCategoryOverviewDataError,
  resetProductSubCategoryOverviewData,
  resetProductOverviewData,
} = categorySlice.actions;

export default categorySlice.reducer;
