import B2BecLink from 'components/B2BLink';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t('errors.pageNotFound')}</h1>
      <p className={styles.description}>{t('errors.pageNotFoundMessage')}</p>
      <p className={styles['back-button']}>
        <B2BecLink to="">{t('errors.backHomeButton')}</B2BecLink>
      </p>
    </div>
  );
};

export default NotFound;
