import { Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DeliveryDate = (props) => {
  const { deliveryData } = props;
  const { t } = useTranslation();

  const translationKey =
    Array.isArray(deliveryData) && deliveryData.length > 1
      ? 'estimatedDeliveryDateWithNumber'
      : 'estimatedDeliveryDate';

  return (
    <Space direction="vertical" className="cart-item-name__delivery-date">
      {deliveryData?.map((data) => {
        return (
          <span
            style={{ display: 'block' }}
            key={`${data.numberOfItems}-${data.deliveryDate}`}
          >
            {t(`cart.table.${translationKey}`, {
              count: data.numberOfItems,
              deliveryDate: data.deliveryDate,
            })}
          </span>
        );
      })}
    </Space>
  );
};

DeliveryDate.defaultProps = {
  deliveryData: [],
};

DeliveryDate.propTypes = {
  deliveryData: PropTypes.arrayOf(
    PropTypes.shape({
      numberOfItems: PropTypes.number,
      deliveryDate: PropTypes.string,
    })
  ),
};

export default DeliveryDate;
