import { filterEmptyElementInArray } from 'libs/utils/array';
import { put, takeEvery } from 'redux-saga/effects';
import { OrderService, ProductService } from 'services';

import { STATUS_CODE } from '../../libs/constants';
import { productComparisonActions } from 'store/slices/productComparisonSlice';

function* getProductDetails({ payload }) {
  const { materialNumber } = payload;
  try {
    const { data } = yield ProductService.getComparisonDetails(materialNumber);

    const reducedProductDetails = data.reduce((result, product) => {
      const { materialNumber: productId, equipments, technicalData } = product;

      const filteredEquipments = filterEmptyElementInArray(equipments);
      const filteredTechnicalData = filterEmptyElementInArray(technicalData);

      return {
        ...result,
        [productId]: {
          ...product,
          equipments: filteredEquipments,
          technicalData: filteredTechnicalData,
        },
      };
    }, {});

    const dataById = materialNumber.reduce((result, productId) => {
      const productData = reducedProductDetails[productId];

      return {
        ...result,
        [productId]: productData,
      };
    }, {});

    yield put(
      productComparisonActions.getProductComparisonDataSuccess(dataById)
    );
  } catch (error) {
    yield put(
      productComparisonActions.getProductComparisonDataFailure({
        materialNumber,
      })
    );
  }
}

function* getProductPrice(action) {
  const { materialNumber } = action.payload;
  try {
    const response = yield OrderService.getPriceBoxData(materialNumber);
    if (response.status === STATUS_CODE.SUCCESS) {
      yield put(
        productComparisonActions.getProductComparisonPriceSuccess(response.data)
      );
    }
  } catch (error) {
    yield put(
      productComparisonActions.getProductComparisonPriceFailure({
        materialNumber,
      })
    );
  }
}

function* watchProductComparison() {
  yield takeEvery(
    productComparisonActions.getProductComparisonData.type,
    getProductDetails
  );
  yield takeEvery(
    productComparisonActions.getProductComparisonPrice.type,
    getProductPrice
  );
}

export default watchProductComparison;
