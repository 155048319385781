import { Col, Form, Input, Row } from 'antd';
import { CustomFormItem } from 'components/B2bUserForm';
import CustomButton from 'components/CustomButton';
import { NotificationManager } from 'components/Notification';
import { useAsync } from 'hooks';
import { ASYNC_STATUS } from 'libs/constants';
import { validatePasswordRequirements } from 'libs/utils/passwordValidators';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { adminFapiTechnicalUser } from 'services/adminFapi';

import styles from './TechnicalUserSetPassword.module.scss';

const TechnicalUserSetPassword = (props) => {
  const { userId, username, onCancel } = props;
  const { t } = useTranslation();

  const handleSuccess = useCallback(() => {
    NotificationManager.success({ message: 'notification.success.update' });
    onCancel();
  }, [onCancel]);

  const handleSetPassword = useCallback(
    ({ password }) => {
      return adminFapiTechnicalUser.setTechnicalUserPassword({
        userId,
        username,
        password,
      });
    },
    [userId, username]
  );

  const { execute, status } = useAsync(handleSetPassword, false, handleSuccess);
  const isUpdating = status === ASYNC_STATUS.PENDING;
  const isError = status === ASYNC_STATUS.ERROR;

  return (
    <>
      {isError && (
        <div className={styles.errorMessage}>
          {t('setPassword.validationMessage.errorMessageWithCode', {
            errorCode: '001',
          })}
        </div>
      )}
      <Form layout="vertical" className="mt-4" onFinish={execute}>
        <Row>
          <Col span={12}>
            <CustomFormItem
              name="password"
              rules={[
                { required: true },
                {
                  validator: validatePasswordRequirements,
                },
              ]}
            >
              <Input.Password autoComplete="off" />
            </CustomFormItem>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <Form.Item noStyle>
              <CustomButton
                type="ghost"
                disabled={isUpdating}
                onClick={onCancel}
              >
                {t('buttonTexts.cancel')}
              </CustomButton>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item noStyle>
              <CustomButton htmlType="submit" disabled={isUpdating}>
                {t('buttonTexts.saveChanges')}
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default React.memo(TechnicalUserSetPassword);
