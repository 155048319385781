import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

function Article(props) {
  const { classNames, title, children } = props;
  return (
    <div className={`article-wrapper ${classNames}`}>
      <h3 className="m-0">{title}</h3>
      <div className="article-wrapper__details mt-4 mb-0">{children}</div>
    </div>
  );
}

Article.propTypes = {
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Article.defaultProps = {
  classNames: null,
  title: 'Title',
  children: 'Details',
};

export default Article;
