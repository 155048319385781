import { createSlice } from '@reduxjs/toolkit';

const employeeSlice = createSlice({
  name: 'employees',
  initialState: {
    userDetails: {},
    editEmployeeForm: {
      isVisible: false,
      isLoading: false,
    },
  },
  reducers: {
    getEmployeeDetail(state, action) {
      state.userDetails = {
        [action.payload]: {
          loading: true,
        },
      };
    },
    getEmployeeDetailSuccess(state, action) {
      const { userId } = action.payload;
      state.userDetails = {
        [userId]: action.payload,
      };
    },
    getEmployeeDetailFailed(state, action) {
      state.userDetails = {
        [action.payload.userId]: {
          error: true,
        },
      };
    },
    showEditEmployeeForm(state) {
      state.editEmployeeForm.isVisible = true;
    },
    hideEditEmployeeForm(state) {
      state.editEmployeeForm.isVisible = false;
    },
    updateEmployee(state) {
      state.editEmployeeForm.isLoading = true;
    },
    updateEmployeeSuccess(state, { payload }) {
      const { userId, language, userPolicy } = payload;

      state.editEmployeeForm.isLoading = false;
      state.userDetails[userId].language = language;
      state.userDetails[userId].userPolicy = userPolicy;
    },
    updateEmployeeFailed(state) {
      state.editEmployeeForm.isLoading = false;
    },
  },
});

// Destructure and export the plain action creators
export const {
  getEmployeeDetail,
  getEmployeeDetailSuccess,
  getEmployeeDetailFailed,
  showEditEmployeeForm,
  hideEditEmployeeForm,
  updateEmployee,
  updateEmployeeSuccess,
  updateEmployeeFailed,
} = employeeSlice.actions;

export const selectIsEditEmployeeFormLoading = (state) =>
  state.employee.editEmployeeForm.isLoading;
export const selectIsEditEmployeeFormVisible = (state) =>
  state.employee.editEmployeeForm.isVisible;

export default employeeSlice.reducer;
