import B2becTable from 'components/B2becTable';
import useDummyItems from 'hooks/useDummyItems';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSubmenuProductCategories } from 'store/selectors/categorySelector';

import renderColumns from './renderColumns';

const shapeOfVoucher = {
  name: '',
  voucherCode: '',
  discount: 0,
  startDate: '',
  endDate: '',
  status: '',
};

function VouchersTable({
  isLoading,
  voucherList,
  handleEditVoucher,
  handleDeleteVoucher,
}) {
  const fakeVoucherList = useDummyItems(6, shapeOfVoucher);
  const productCategories = useSelector(selectSubmenuProductCategories);
  const { t } = useTranslation();

  const findCategoryName = useCallback((categoryId, categoryArray = []) => {
    let matchItem;
    matchItem = categoryArray.find(
      (category) => category?.categoryId.toString() === categoryId.toString()
    );
    if (matchItem) {
      return matchItem?.categoryName;
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < categoryArray.length; i++) {
      if (matchItem) {
        break;
      }
      const category = categoryArray[i];
      if (isValidArray(category?.subCategories)) {
        matchItem = findCategoryName(categoryId, category?.subCategories);
      }
    }
    return matchItem;
  }, []);

  const findApplicableProducts = useCallback(
    (materialNumberArray, categoryIdArray) => {
      if (
        !isValidArray(materialNumberArray, -1) ||
        !isValidArray(categoryIdArray, -1)
      ) {
        return 'N/A';
      }
      const materialNumberArrayLength = materialNumberArray?.length;
      const categoryIdArrayArrayLength = categoryIdArray?.length;

      if (materialNumberArrayLength + categoryIdArrayArrayLength === 0) {
        return t('voucherManagement.applicableProducts.all');
      }
      if (materialNumberArrayLength + categoryIdArrayArrayLength > 1) {
        return t('voucherManagement.applicableProducts.multiple');
      }
      if (materialNumberArrayLength === 1) {
        return materialNumberArray[0];
      }
      if (categoryIdArrayArrayLength === 1) {
        return findCategoryName(categoryIdArray[0], productCategories, t);
      }

      return 'N/A';
    },
    [findCategoryName, productCategories, t]
  );

  return (
    <B2becTable
      dataSource={isLoading ? fakeVoucherList : voucherList}
      pagination={false}
      showSorterTooltip={false}
      columns={renderColumns(
        handleEditVoucher,
        handleDeleteVoucher,
        isLoading,
        findApplicableProducts
      )}
      className="voucher-table__container"
      rowKey={isLoading ? 'materialNumber' : 'id'}
    />
  );
}

VouchersTable.propTypes = {
  isLoading: PropTypes.bool,
  voucherList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      voucherCode: PropTypes.string,
      discount: PropTypes.number,
      startDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      endDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      status: PropTypes.string,
    })
  ),
  handleEditVoucher: PropTypes.func.isRequired,
};

VouchersTable.defaultProps = {
  isLoading: false,
  voucherList: [],
};

export default VouchersTable;
