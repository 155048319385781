import PropTypes from 'prop-types';
import React from 'react';
import { PUNCHOUT_TYPES } from 'libs/constants/punchoutTypes';
import { useTranslation } from 'react-i18next';

import B2becCustomSelect from 'components/B2becCustomSelect';

const PunchoutTypeSelection = ({ value, onChange, isDisabled }) => {
  const { t } = useTranslation();

  const options = Object.values(PUNCHOUT_TYPES).map((type) => ({
    label: t(`adminWorkspace.userManagement.userDetails.punchoutType.${type}`),
    value: type,
  }));

  return (
    <B2becCustomSelect
      value={value}
      options={options}
      disabled={isDisabled}
      onChange={onChange}
    />
  );
};

PunchoutTypeSelection.defaultProps = {
  value: PUNCHOUT_TYPES.OCI,
  onChange: () => {},
  isDisabled: false,
};

PunchoutTypeSelection.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default PunchoutTypeSelection;
