import { Form, Input } from 'antd';
import B2becInfoLayout from 'components/B2becInfoLayout';
import { CustomFormItem } from 'components/B2bUserForm';
import CustomButton from 'components/CustomButton';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import RightTable from './RightTable';
import styles from './RoleForm.module.scss';
import RoleSelect from './RoleSelect';

const { useForm } = Form;

const RoleForm = (props) => {
  const { disabled, handleSubmit, handleCancel, ...formProps } = props;
  const [form] = useForm();
  const { t } = useTranslation();

  const { roleId } = formProps.initialValues || {};

  const handleCreateRole = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <div className={styles.roleForm}>
      <Form
        className="b2b-user-form"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        {...formProps}
      >
        <B2becInfoLayout>
          <B2becInfoLayout.Title>
            <CustomFormItem
              className={styles.field}
              label="roleName"
              name="roleName"
              rules={[{ required: true }]}
            >
              <Input disabled={disabled} />
            </CustomFormItem>
            <div className={styles.fieldGroup}>
              <CustomFormItem
                className={styles.field}
                label="roleParent"
                name="hierarchy"
              >
                <RoleSelect disabled={disabled} ignoreRoleId={roleId} />
              </CustomFormItem>
              <CustomFormItem
                className={styles.field}
                label="roleDescription"
                name="description"
              >
                <Input disabled={disabled} />
              </CustomFormItem>
            </div>
          </B2becInfoLayout.Title>
          <B2becInfoLayout.Content>
            <div className="custom-form-item">
              <Form.Item name="rights" noStyle rules={[{ required: true }]}>
                <RightTable disabled={disabled} />
              </Form.Item>
            </div>
          </B2becInfoLayout.Content>
          <div className={styles.footer}>
            <CustomButton
              type="ghost"
              disabled={disabled}
              onClick={handleCancel}
            >
              {t('buttonTexts.cancel')}
            </CustomButton>
            <CustomButton
              onClick={handleCreateRole}
              disabled={disabled}
              loading={disabled}
            >
              {t('buttonTexts.saveChanges')}
            </CustomButton>
          </div>
        </B2becInfoLayout>
      </Form>
    </div>
  );
};

RoleForm.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
};

RoleForm.defaultProps = {
  disabled: false,
  handleSubmit: undefined,
  handleCancel: undefined,
};

export default React.memo(RoleForm);
