import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environment from 'environments/environment.dev';
import { AuthenticationService } from 'services';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.shopFAPIUrl,
    prepareHeaders: async (headers) => {
      const accessToken = (await AuthenticationService.getTokens())?.jwt
        ?.accessToken;

      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Wishlists'],
  endpoints: () => ({}),
});
