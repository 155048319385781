import { Table } from 'antd';
import FormatPrice from 'components/FormatPrice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

const OrderDetailsCartSummary = ({
  cartSubTotal,
  cartTotal,
  shippingCosts,
  voucherValue,
  additionalCost,
  isLoading,
  vat,
  cartTotalVAT,
  currencyUnit,
  checkoutSettings,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={5}>
          <span>{t('cart.table.subtotal')}</span>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          {isLoading ? (
            <Skeleton />
          ) : (
            <FormatPrice price={cartSubTotal} currencyUnit={currencyUnit} />
          )}
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={5}>
          <span>{t('cart.table.shippingCosts')}</span>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          {isLoading ? (
            <Skeleton />
          ) : (
            <FormatPrice price={shippingCosts} currencyUnit={currencyUnit} />
          )}
        </Table.Summary.Cell>
      </Table.Summary.Row>
      {!!checkoutSettings?.headLvlConditions && (
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={5}>
            <span>{t('cart.table.additionalCost')}</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            {isLoading ? (
              <Skeleton />
            ) : (
              <FormatPrice price={additionalCost} currencyUnit={currencyUnit} />
            )}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}

      {voucherValue ? (
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={5}>
            <span>{t('voucher.fields.voucherCode')}</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            {isLoading ? (
              <Skeleton />
            ) : (
              <FormatPrice
                price={voucherValue?.totalDiscount}
                currencyUnit={currencyUnit}
              />
            )}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      ) : null}

      <Table.Summary.Row className="total-section">
        <Table.Summary.Cell colSpan={5}>
          <span>{t('total')}</span>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          {isLoading ? (
            <Skeleton />
          ) : (
            <FormatPrice price={cartTotal} currencyUnit={currencyUnit} />
          )}
        </Table.Summary.Cell>
      </Table.Summary.Row>

      {checkoutSettings?.includingVAT && (
        <>
          <Table.Summary.Row className="total-section vat">
            <Table.Summary.Cell colSpan={5}>
              <span>{t('vat')}</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {isLoading ? (
                <Skeleton />
              ) : (
                <FormatPrice price={vat} currencyUnit={currencyUnit} />
              )}
            </Table.Summary.Cell>
          </Table.Summary.Row>

          <Table.Summary.Row className="total-section">
            <Table.Summary.Cell colSpan={5}>
              <span>{t('totalWithVat')}</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {isLoading ? (
                <Skeleton />
              ) : (
                <FormatPrice price={cartTotalVAT} currencyUnit={currencyUnit} />
              )}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      )}
    </>
  );
};

export default OrderDetailsCartSummary;
