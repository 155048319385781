export const mappedUserDetailsData = (userData) => {
  const {
    invitationId,
    countryId,
    email,
    customerNumber,
    language,
    status,
    salutation,
    firstName,
    lastName,
    phoneNumber,
    invitationStatus,
    invitedOn,
    revokedOn,
    createdBy,
  } = userData;

  const displayName = [firstName, lastName].filter((i) => i).join(' ');

  return {
    sapCustomerNumber: customerNumber,
    invitedOn,
    revokedOn,
    createdBy,
    invitationStatus,
    status,
    userInfo: {
      userId: invitationId,
      title: salutation,
      firstName,
      lastName,
      phoneNumber,
      displayName,
      language,
      countryId,
      email,
    },
    ...userData,
  };
};
