import './styles.scss';

import { Col, Row } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { USER_STATUS } from '../../../../../../libs/constants/user';

function UserStatusSection(props) {
  const { isLoading, userStatus } = props;

  const status = Object.values(USER_STATUS).includes(userStatus?.toLowerCase())
    ? userStatus?.toLowerCase()
    : USER_STATUS.UNAUTHORIZED;

  const { t } = useTranslation();

  return (
    <section className="user-details__user-status__wrapper">
      <div className="user-details__user-status__title">
        {t('adminWorkspace.userManagement.userDetails.userStatusSection.title')}
      </div>
      <Row gutter={[0, 16]} justify="space-between">
        <Col>
          <div
            className={clsx('user-details__user-status__unauthorized', {
              'user-details__user-status--activated':
                !isLoading && status === USER_STATUS.UNAUTHORIZED,
            })}
          >
            {isLoading ? (
              <Skeleton width={36} />
            ) : (
              t(
                'adminWorkspace.userManagement.userDetails.userStatus.unauthorized'
              )
            )}
          </div>
        </Col>
        <Col>
          <div
            className={clsx('user-details__user-status__in-progress', {
              'user-details__user-status--activated':
                !isLoading && status === USER_STATUS.IN_PROGRESS,
            })}
          >
            {isLoading ? (
              <Skeleton width={86} />
            ) : (
              t(
                'adminWorkspace.userManagement.userDetails.userStatus.inProgress'
              )
            )}
          </div>
        </Col>
        <Col>
          <div
            className={clsx('user-details__user-status__authorized', {
              'user-details__user-status--activated':
                !isLoading && status === USER_STATUS.AUTHORIZED,
            })}
          >
            {isLoading ? (
              <Skeleton width={85} />
            ) : (
              t(
                'adminWorkspace.userManagement.userDetails.userStatus.authorized'
              )
            )}
          </div>
        </Col>
      </Row>
    </section>
  );
}

UserStatusSection.propTypes = {
  isLoading: PropTypes.bool,
  userStatus: PropTypes.string,
};

UserStatusSection.defaultProps = {
  isLoading: false,
  userStatus: USER_STATUS.UNAUTHORIZED,
};

export default UserStatusSection;
