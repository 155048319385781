import { NotificationManager } from 'components/Notification';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getEmployeeDetail,
  getEmployeeDetailFailed,
  getEmployeeDetailSuccess,
  hideEditEmployeeForm,
  updateEmployee,
  updateEmployeeFailed,
  updateEmployeeSuccess,
} from '../slices/employeeSlice';
import { fapiEmployeeService } from 'services/adminFapi';

function* getEmployeeDetailSaga({ payload }) {
  const userId = payload;
  try {
    yield getEmployeeDetail();
    const { data } = yield call(
      fapiEmployeeService.getUserEmployeesById,
      userId
    );
    yield put(getEmployeeDetailSuccess(data));
  } catch (error) {
    yield put(getEmployeeDetailFailed({ userId, error }));
  }
}

function* updateEmployeeSaga({ payload }) {
  try {
    yield call(fapiEmployeeService.updateEmployee, payload);
    yield put(updateEmployeeSuccess(payload));
    yield put(hideEditEmployeeForm());
    NotificationManager.success({
      message: 'notification.success.update',
    });
  } catch (error) {
    yield put(updateEmployeeFailed());
    NotificationManager.error({
      message: 'notification.error.update',
    });
  }
}

export default function* employeeSaga() {
  yield takeLatest(getEmployeeDetail.type, getEmployeeDetailSaga);
  yield takeLatest(updateEmployee.type, updateEmployeeSaga);
}
