import { COUNTRY_BY_SALESORG, isFutureTech } from 'libs/constants';
import { DEFAULT_LANGUAGES, SUPPORTED_LANGUAGES } from 'libs/constants/lang';

import i18n from '../../i18n';

export const ChangeLang = (lang) => {
  i18n.changeLanguage(lang);
  localStorage.setItem('lng', lang);

  const event = new Event('changeLang');
  event.value = lang;
  event.key = 'lng';
  document.dispatchEvent(event);
};

export function handleChangeLanguage(locale, countryId) {
  const getCorrectLocale = () => {
    const language = locale.slice(0, 2);
    if (isFutureTech(countryId) && language === 'de') {
      return 'de-FT';
    }
    const country = COUNTRY_BY_SALESORG[countryId];
    const newLocale = `${language}${country ? `-${country}` : ''}`;
    if (SUPPORTED_LANGUAGES.includes(newLocale)) {
      return newLocale;
    }
    return language;
  };

  ChangeLang(getCorrectLocale());
}

export const getLanguage = (lang) => lang?.replace(/[-_][a-zA-Z]+$/i, '');

export const getLanguageFromLocalStorage = () =>
  localStorage.getItem('lng') || DEFAULT_LANGUAGES[0].value;

export const getI18nLanguage = () => {
  if (checkValidCultureFormat(i18n.language)) {
    return i18n.language;
  }

  return 'en';
};

/**
 * Check whether the culture is supported or unsupported
 * @param   {String} culture  The language to check
 * @returns {Bool}   result   Is the culture supported
 */
export const checkIsLanguageSupported = (culture) => {
  if (!culture) return false;

  return SUPPORTED_LANGUAGES.includes(culture);
};

export const checkValidCultureFormat = (culture) =>
  /^[a-z]{2}(?:[_-][A-Z]{2})?$/.test(culture);

/**
 * Removed supported language codes prefix in the URL
 * @param {String} urlString The URL path
 * @returns {String} output is a removed lang code URL
 */
export const removeLangCodesPrefix = (currentUrl) => {
  const i18nLanguage = getI18nLanguage();

  /* Input examples:
   * /en/product/1.234-567.8
   * /de-AT/product/1.234-567.8
   * Output:
   * /product/1.234-567.8
   */
  return currentUrl
    .split('/')
    .filter((e) => e !== i18nLanguage)
    .join('/');
};

/**
 * Append culture to a link
 * @param   {String} link The link to navigate to
 * @returns {String}      The link contains culture
 */
export const linkGenerator = (link) => {
  const paths = link?.split('/')?.filter((path) => path !== '');
  const i18nLanguage = getI18nLanguage();

  /**
   * Example: ["contact-form"], ["vouchers"]
   */
  if (paths && paths.length > 0) {
    const culture = paths[0];

    /**
     * Check if the URL already contains the culture
     * then return it without adding the culture
     * Example:
     * - localhost:3000/fr-CH/category/11023648/subcategory/11023648
     */
    const isCultureFormatValid = checkValidCultureFormat(culture);
    const isCultureAlreadyInUrl = checkIsLanguageSupported(culture);

    if (isCultureFormatValid && isCultureAlreadyInUrl) {
      return link;
    }
  }

  const appendedCultureUrl = `/${i18nLanguage}${link}`;

  return appendedCultureUrl;
};

export const getCultureFromRegistrationLink = (link) => {
  const paths = link?.split('/')?.filter((path) => path);

  const [lang, country] = paths.slice(-2);

  const culture = [lang, country].join('-');
  if (checkValidCultureFormat(culture) && checkIsLanguageSupported(culture)) {
    return culture;
  }
  return '';
};

export function cleanLanguage(language) {
  // To remove countryId attached with language
  // Example: en-UK => en, de-CH => de
  let lang = language;

  if (lang?.length > 2) {
    lang = language.slice(0, 2);
  }

  return lang;
}
