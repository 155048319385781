import { NotificationManager } from 'components/Notification';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fapiRentalService } from 'services/fapi';

const SORT_FIELDS = {
  name: 'Name',
  category: 'Category',
  subCategory: 'SubCategory',
};

const SORT_ORDERS = {
  ascend: 'Asc',
  descend: 'Desc',
};

export const useFetchProductsList = () => {
  const history = useHistory();
  const [sortBy, setSortBy] = useState(SORT_FIELDS.category);
  const [orderBy, setOrderBy] = useState(SORT_ORDERS.ascend);
  const [searchQuery, setSearchQuery] = useState('');
  const [productsList, setProductsList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProducts = useCallback(async () => {
    try {
      const {
        data: { items, totalCount },
      } = await fapiRentalService.getList({
        searchQuery,
        pageNumber: currentPage,
        sortBy,
        orderBy,
      });

      setProductsList(items);
      setTotalPage(totalCount);
    } catch (error) {
      NotificationManager.error({
        message: 'notification.error.load',
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, searchQuery, sortBy, orderBy]);

  const resetToFirstPage = useCallback(() => {
    setCurrentPage(1);
    history.push({
      search: '?page=1',
    });
  }, [history]);

  const handleSearch = useCallback(
    (value) => {
      setSearchQuery(value);
      resetToFirstPage();
    },
    [resetToFirstPage]
  );

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      history.push({
        search: `?page=${page}`,
      });
    },
    [history]
  );

  const handleSortChange = useCallback((_, __, sorter) => {
    const { field, order } = sorter;

    setSortBy(SORT_FIELDS[field]);
    setOrderBy(SORT_ORDERS[order]);
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return {
    isLoading,
    currentPage,
    totalPage,
    orderBy,
    sortBy,
    productsList,
    handleSearch,
    handlePageChange,
    handleSortChange,
  };
};
