import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateBreadcrumbs } from 'store/slices/breadcrumbSlice';

export const translateDynamicText = (text = '', t) => {
  return text
    .split(/\$[0-9][0-9]*/) // Example: = ['breadcrumbTitle.orderDetails ', '']
    .map((word, index, words) => {
      return index < words.length - 1
        ? word.trim() !== ''
          ? `${word.replace(word.trim(), t(word.trim()))}$${index + 1}` // translate & insert back the placeholder ($)
          : `${word}$${index + 1}`
        : word; // do not insert back the placeholder ($) for last word in split array
      // because it is already inserted in the word before
    }) // Example: = ['Order $1', '']
    .join(''); // 'Order $1'
};

export const translateFixedText = (text = '', t) => {
  return text
    .split(' ') // Example: = ['breadcrumbTitle.orderHistory']
    .map((word) =>
      word.trim() !== '' ? word.replace(word.trim(), t(word.trim())) : word
    )
    .join(' '); // 'Orders'
};

const translateTitle = (breadcrumb, t) => {
  // Example:
  // breadcrumb = {
  //   title: 'breadcrumbTitle.orderDetails $1',
  //   path: '/orders/$1',
  //   dynamicTitle: true,
  //   dynamicPath: true,
  // }
  // DYNAMIC TITLE CASE
  if (breadcrumb.dynamicTitle && breadcrumb.title) {
    // Translate the breadcrumbs title by splitting by $1-$99..99 then join them back
    return translateDynamicText(breadcrumb.title, t);
  }

  // Example:
  // breadcrumb = {
  //   title: 'breadcrumbTitle.orderHistory',
  //   path: '/orders',
  // }
  // NOT DYNAMIC TITLE CASE
  if (breadcrumb.title) {
    // Translate the breadcrumbs title by splitting by spaces then join them back
    const translatedTitle = translateFixedText(breadcrumb.title, t);

    return translatedTitle !== breadcrumb.title ? translatedTitle : undefined;
  }

  // Default value
  return '';
};

export const replacePlaceholdersWithData = (
  rawText = '',
  getDataToReplace = () => ''
) => {
  let placeholderIndex = 0; // index of placeholders to place data into
  let newText = rawText;

  // Find placeholders ($1-$99..99) on raw text to place data into
  const foundPlaceholders = rawText.match(/\$[0-9][0-9]*/g) || [];
  foundPlaceholders.forEach((placeholder) => {
    // Check if data is correct to place data into placeholders
    if (getDataToReplace(placeholderIndex)) {
      newText = newText.replace(
        placeholder,
        getDataToReplace(placeholderIndex)
      );
      placeholderIndex += 1;
    } else {
      // If there is any undefined data, hide it from user or show default value
      newText = undefined;
    }
  });
  return newText;
};

/**
 * React Hook to return generated breadcrumbs.
 * Example:
 * breadcrumbs: [
 *  { title: 'breadcrumbTitle.home', path: '/' }, // breadcrumbTitle.home: translation key, will be translated here
 *  { title: 'breadcrumbTitle.productCategoryOverview', path: '/categories' },
 *  { title: '$1', path: '/category/$1' },  // 1st object of dataArray. $1->$99[..]99 are placeholders to place data
 *  { title: '$1', path: '/category/$1/subcategory/$2' }, // 2nd object of dataArray
 * ]
 * dataArray: [
 *  { titleData: ['Pressure Washer'], pathData: ['123456'] },
 *  { titleData: ['Hot Water Pressure Washer'], pathData: ['123', '456'] },
 * ]
 * generatedBreadcrumbs = [
 *  { title: 'Home', path: '/' },
 *  { title: 'Products', path: '/categories' },
 *  { title: 'Pressure Washer', path: '/category/123456' },
 *  { title: 'Hot Water Pressure Washer', path: '/category/123/subcategory/456' },
 * ]
 *
 * @param {Object[]} breadcrumbs - The prototype of breadcrumbs.
 * @param {Object[]} dataArray[] - The array of objects in the order that respective to the placeholders inside the
 * prototype of breadcrumbs above.
 * @param {Object[]} dataArray[].titleData - The array of data to place into breadcrumbs title in order.
 * @param {Object[]} dataArray[].pathData - The array of data to place into breadcrumbs path in order.
 */
const useBreadcrumbs = (breadcrumbs, dataArray = []) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [breadcrumbsData, setBreadcrumbsData] = useState(dataArray);

  useEffect(() => {
    let dataArrayIndex = 0; // index of breadcrumbsData (dataArray)
    const generatedBreadcrumbs = breadcrumbs.map((breadcrumb) => {
      const newBreadcrumb = { ...breadcrumb };

      // Translate the title
      const translatedTitle = translateTitle(breadcrumb, t);

      // Place data into title
      if (breadcrumb.dynamicTitle && translatedTitle) {
        newBreadcrumb.title = replacePlaceholdersWithData(
          translatedTitle,
          (placeholderIndex) =>
            breadcrumbsData &&
            breadcrumbsData[dataArrayIndex] &&
            breadcrumbsData[dataArrayIndex].titleData &&
            breadcrumbsData[dataArrayIndex].titleData[placeholderIndex]
        );
      } else {
        newBreadcrumb.title = translatedTitle;
      }

      // Place data into path
      if (breadcrumb.dynamicPath && breadcrumb.path) {
        newBreadcrumb.path = replacePlaceholdersWithData(
          breadcrumb.path,
          (placeholderIndex) =>
            breadcrumbsData &&
            breadcrumbsData[dataArrayIndex] &&
            breadcrumbsData[dataArrayIndex].pathData &&
            breadcrumbsData[dataArrayIndex].pathData[placeholderIndex]
        );
      }

      // Done replacing data for current dynamic breadcrumb, go to next element in data array
      if (breadcrumb.dynamicTitle || breadcrumb.dynamicPath) {
        dataArrayIndex += 1;
      }
      return newBreadcrumb;
    });

    // Show generated breadcrumbs to users
    dispatch(updateBreadcrumbs(generatedBreadcrumbs));

    return () => {
      // Reset breadcrumbs to default one on page change
      dispatch(updateBreadcrumbs([]));
    };
  }, [t, dispatch, breadcrumbs, breadcrumbsData]);

  const returnValue = [setBreadcrumbsData];
  returnValue.setBreadcrumbsData = setBreadcrumbsData;
  return returnValue;
};

export default useBreadcrumbs;
