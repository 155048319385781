import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

function CarouselArrows(props) {
  const { className, style, ArrowIcon, onClick } = props;
  return (
    <div
      className={`custom-carousel__arrow ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      {ArrowIcon && ArrowIcon !== '' ? <ArrowIcon /> : ''}
    </div>
  );
}

CarouselArrows.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  ArrowIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
};

CarouselArrows.defaultProps = {
  className: '',
  style: {},
  ArrowIcon: '',
};

export default CarouselArrows;
