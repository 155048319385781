import './styles.scss';

import { Checkbox, Col, Form, Row } from 'antd';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Translator from 'components/B2becTranslation';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import useDisableBodyScroll from '../../../../hooks/useDisableBodyScroll';
import { isValidArray } from '../../../../libs/utils/array';

const FailureDescription = ({ form, FAQList }) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  const [solutionList, setSolutionList] = useState([]);
  const [isShowSolutionListMobile, setIsShowSolutionListMobile] = useState(
    false
  );
  const [selectedFAQs, setSelectedFAQs] = useState([]);
  const [solutionDisplayIndex, setSolutionDisplayIndex] = useState(-1);

  useDisableBodyScroll(isShowSolutionListMobile);

  useEffect(() => {
    setSolutionList(FAQList[solutionDisplayIndex]?.solutions);
  }, [solutionDisplayIndex, FAQList, setSolutionList]);

  /*
    |  Action   |  Array  |   Push       |   Pop       |
    ---------------------------------------------------
    |  Add 3    | 3       | 3  (lastest) |             |
    |  Add 1    | 3,1     | 1  (lastest) |             |
    |  Add 5    | 3,1,5   | 5  (lastest) |             |
    |  Remove 5 | 3,1     |              | 3 (largest) |
    |  Add 2    | 3,1,2   | 2  (lastest) |             |
    |  Add 7    | 3,1,2,7 | 7  (lastest) |             |
    |  Remove 1 | 3,2,7   |              | 7 (largest) |
    |  Remove 7 | 3,2     |              | 3 (largest) |
    |  Remove 3 | 2       |              | 2 (largest) |
  */

  const onFAQCheck = useCallback(
    (e, index) => {
      if (e?.target?.checked) {
        // push the selected FAQ to the list
        setSelectedFAQs((prevFAQs) => [...prevFAQs, index]);
        // get the LATEST FAQ from the list
        setSolutionDisplayIndex(index);
      } else {
        // remove unselected from the list
        const updatedFAQs = selectedFAQs.filter(
          (FAQIndex) => FAQIndex !== index
        );
        setSelectedFAQs(updatedFAQs);
        // get the LARGEST FAQ INDEX from the list (the bottom most selected issue on UI)
        setSolutionDisplayIndex(Math.max.apply(null, updatedFAQs));
      }
    },
    [setSelectedFAQs, selectedFAQs]
  );

  const onShowSolutionListMobile = (solutions) => {
    setSolutionList(solutions);
    setIsShowSolutionListMobile(true);
  };

  const renderSolutionList = () => {
    return (
      <div className="FAQ__solutions__wrapper service-request__layout--shrink">
        <div className="FAQ__solutions__title__wrapper">
          {!isMobile && (
            <div>
              <InfoIcon className="FAQ__info-icon" />
            </div>
          )}
          <div className="FAQ__solutions__title">
            {t('service.selectService.solutionsTitle')}
          </div>
          {isMobile && (
            <div>
              <CloseIcon
                className="FAQ__close-icon"
                onClick={() => setIsShowSolutionListMobile(false)}
              />
            </div>
          )}
        </div>
        <ol className="FAQ__solutions__list">
          {(solutionList || []).map((solution, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{solution}</li>
          ))}
        </ol>
      </div>
    );
  };

  const renderIssueItem = (serviceFAQ, index) => (
    <div className="FAQ__failures__issue" key={serviceFAQ?.issue}>
      <Checkbox
        value={serviceFAQ?.issue}
        className="custom-form-item"
        onChange={(e) => onFAQCheck(e, index)}
      >
        {serviceFAQ?.issue}
      </Checkbox>
      {isMobile &&
        selectedFAQs.includes(index) &&
        isValidArray(serviceFAQ?.solutions) && (
          <InfoIcon
            className="FAQ__info-icon"
            onClick={() => onShowSolutionListMobile(serviceFAQ?.solutions)}
          />
        )}
    </div>
  );

  return (
    isValidArray(FAQList) && (
      <>
        {isMobile && isShowSolutionListMobile && isValidArray(solutionList) && (
          <div className="FAQ__solutions__wrapper--mobile fadeInUp">
            {renderSolutionList()}
          </div>
        )}
        <Row gutter={24}>
          <Col span={24} md={10}>
            <div className="FAQ__failures service-request__layout--shrink">
              <div className="FAQ__failures__title">
                <Translator
                  value="service.selectService.selectFailureDescription"
                  isComponent
                  htmlTags={[
                    <span className="FAQ__failures__title--optional" />,
                  ]}
                />
              </div>
              <Form.Item
                className="FAQ__failures__issue__wrapper"
                form={form}
                name="failureDescriptions"
              >
                <Checkbox.Group>
                  <div>
                    {FAQList.map((serviceFAQ, index) =>
                      renderIssueItem(serviceFAQ, index)
                    )}
                  </div>
                </Checkbox.Group>
              </Form.Item>
            </div>
          </Col>

          {!isMobile && isValidArray(solutionList) && (
            <Col span={24} md={14}>
              {renderSolutionList()}
            </Col>
          )}
        </Row>
      </>
    )
  );
};

FailureDescription.propTypes = {
  form: PropTypes.shape({}).isRequired,
  FAQList: PropTypes.arrayOf(
    PropTypes.shape({
      issue: PropTypes.string,
      solutions: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

export default FailureDescription;
