import { ReactComponent as Delete } from 'assets/icons/delete_icon_borderless.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import B2becActionButton from 'components/B2becActionButon';
import B2becTranslation from 'components/B2becTranslation';
import PermissionWrapper from 'HOCs/permissionWrapper';
import { MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS } from 'libs/constants/modulerights';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import FormatStatus from '../FormatStatus';

const renderColumns = (
  handleEditVoucher,
  handleDeleteVoucher,
  isLoading,
  findApplicableProducts
) =>
  [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: (
        <B2becTranslation value="voucherManagement.tableColumn.voucherName" />
      ),
      dataIndex: 'name',
      ellipsis: true,
      width: '10%',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
    },
    {
      title: (
        <B2becTranslation value="voucherManagement.tableColumn.voucherCode" />
      ),
      dataIndex: 'voucherCode',
      ellipsis: true,
      width: '10%',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
    },
    {
      title: (
        <B2becTranslation value="voucherManagement.tableColumn.discount" />
      ),
      dataIndex: 'discount',
      width: '6%',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}%</span>),
    },
    {
      title: (
        <B2becTranslation value="voucherManagement.tableColumn.products" />
      ),
      dataIndex: 'products',
      width: '10%',
      render: (_, record) => {
        if (isLoading) {
          return <Skeleton />;
        }
        const materialNumberArray = record?.materialNumbers || [];
        const categoryIdArray = record?.categoryIds || [];
        const applicableProducts = findApplicableProducts(
          materialNumberArray,
          categoryIdArray
        );
        return <span>{applicableProducts}</span>;
      },
    },
    {
      title: (
        <B2becTranslation value="voucherManagement.tableColumn.startDate" />
      ),
      dataIndex: 'startDate',
      width: '10%',
      render: (text) => {
        const isDateValid = formatDate(text, getFormatType().dateTime);

        if (isLoading) {
          return <Skeleton />;
        }

        if (isDateValid) {
          return <span>{isDateValid} HRS</span>;
        }

        return <B2becTranslation value="unavailableDate" />;
      },
    },
    {
      title: <B2becTranslation value="voucherManagement.tableColumn.endDate" />,
      dataIndex: 'endDate',
      width: '10%',
      render: (text) => {
        const isDateValid = formatDate(text, getFormatType().dateTime);

        if (isLoading) {
          return <Skeleton />;
        }

        if (isDateValid) {
          return <span>{isDateValid} HRS</span>;
        }

        return <B2becTranslation value="unavailableDate" />;
      },
    },
    {
      title: <B2becTranslation value="voucherManagement.tableColumn.status" />,
      dataIndex: '',
      width: '7%',
      render: (_, { startDate, endDate }) => {
        return isLoading ? (
          <Skeleton />
        ) : (
          <FormatStatus startDate={startDate} endDate={endDate} />
        );
      },
    },
    {
      dataIndex: '',
      width: '6%',
      render: (record) => (
        <div className="voucher-table__action-wrapper">
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <B2becActionButton onClick={() => handleEditVoucher(record)}>
                <Edit />
              </B2becActionButton>
              <PermissionWrapper
                permission={
                  MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.DELETE_VOUCHER
                }
              >
                <B2becActionButton onClick={() => handleDeleteVoucher(record)}>
                  <Delete />
                </B2becActionButton>
              </PermissionWrapper>
            </>
          )}
        </div>
      ),
    },
  ].filter((col) => col.dataIndex !== 'id');

export default renderColumns;
