import PropTypes from 'prop-types';
import React from 'react';

import B2becTable from '../../../../../components/B2becTable';
import useDummyItems from '../../../../../hooks/useDummyItems';
import usePermission from '../../../../../hooks/usePermission';
import { MODULE_RENTAL_MANAGEMENT_RIGHTS } from '../../../../../libs/constants/modulerights';
import VouchersTable from '../../../VoucherManagement/components/VouchersTable';
import styles from '../../RentalManagement.module.scss';
import renderColumns from './renderColumns';

const shapeOfRental = {
  name: '',
  materialNumber: '',
  category: '',
  subcategory: '',
};

const RentalTable = ({
  isLoading,
  rentalList,
  handleDeleteRental,
  handleCellClick,
  onChangeSorter,
}) => {
  const fakeRentalList = useDummyItems(6, shapeOfRental);
  const { hasPermission: hasDeleteRentalPermission } = usePermission(
    MODULE_RENTAL_MANAGEMENT_RIGHTS.DELETE_PRODUCT
  );

  return (
    <B2becTable
      dataSource={isLoading ? fakeRentalList : rentalList}
      showSorterTooltip={false}
      columns={renderColumns(
        handleDeleteRental,
        isLoading,
        handleCellClick,
        hasDeleteRentalPermission
      )}
      rowKey="materialNumber"
      onChange={onChangeSorter}
      className={styles.wrapper}
    />
  );
};

RentalTable.propTypes = {
  isLoading: PropTypes.bool,
  rentalList: PropTypes.arrayOf(PropTypes.shape({})),
  handleDeleteRental: PropTypes.func.isRequired,
  handleCellClick: PropTypes.func.isRequired,
  onChangeSorter: PropTypes.func.isRequired,
};

VouchersTable.defaultProps = {
  isLoading: false,
  voucherList: [],
};

export default RentalTable;
