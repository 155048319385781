import environment from 'environments/environment.dev';
import { FAPI_CART_ENDPOINT } from 'libs/constants/b2bApiEndpoints';

import HttpClient from './apiHttpClient';

class CartService {
  constructor(baseUrl) {
    this.httpClient = new HttpClient(baseUrl);
    this.baseEndpoint = FAPI_CART_ENDPOINT;
  }

  getMiniCart({ sessionId }) {
    const searchParams = new URLSearchParams();
    let url = this.baseEndpoint;

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
      url = url.concat(`?${searchParams}`);
    }

    return this.httpClient.get(url);
  }

  emptyCart({ sessionId }) {
    const searchParams = new URLSearchParams();
    let url = `${this.baseEndpoint}/empty`;

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
      url = url.concat(`?${searchParams}`);
    }

    return this.httpClient.delete(url);
  }

  reorder({ orderNumber, sessionId }) {
    const searchParams = new URLSearchParams();
    let url = `${this.baseEndpoint}/add/${orderNumber}`;

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
      url = url.concat(`?${searchParams.toString()}`);
    }

    return this.httpClient.get(url);
  }

  addProductToCart({
    materialNumber,
    quantity = 1,
    productReference,
    voucherId,
    sessionId,
  }) {
    const url = `${this.baseEndpoint}/item/add`;

    return this.httpClient.post(url, {
      body: {
        materialNumber,
        quantity,
        productReference,
        voucherId,
        sessionId,
      },
    });
  }

  updateProductInCart({
    materialNumber,
    quantity,
    productReference,
    voucherId,
    sessionId,
  }) {
    const url = `${this.baseEndpoint}/item/${materialNumber}/update`;

    return this.httpClient.post(url, {
      body: {
        quantity,
        productReference,
        voucherId,
        sessionId,
      },
    });
  }

  replaceProductInCart({
    oldMaterialNumber,
    replacedMaterialNumber,
    quantity,
    productReference,
    voucherId,
    sessionId,
  }) {
    const url = `${this.baseEndpoint}/item/${oldMaterialNumber}/replace`;

    return this.httpClient.put(url, {
      body: {
        materialNumber: replacedMaterialNumber,
        quantity,
        productReference,
        voucherId,
        sessionId,
      },
    });
  }

  removeProductFromCart({ materialNumber }) {
    const url = `${this.baseEndpoint}/item/${materialNumber}/remove`;

    return this.httpClient.delete(url);
  }

  removeVoucher({ sessionId }) {
    const searchParams = new URLSearchParams();
    let url = `${this.baseEndpoint}/voucher/remove`;

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
      url = url.concat(`?${searchParams}`);
    }

    return this.httpClient.delete(url, { body: {} });
  }

  redeemVoucher({ voucherCode, sessionId }) {
    const searchParams = new URLSearchParams();
    let url = `${this.baseEndpoint}/voucher/redeem`;

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
      url = url.concat(`?${searchParams}`);
    }

    return this.httpClient.put(url, { body: { voucherCode } });
  }
}

export const cartService = new CartService(environment.shopFAPIUrl);
