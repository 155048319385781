import environment from 'environments/environment.dev';
import { FAPI_ORDER_ENDPOINT } from 'libs/constants/b2bApiEndpoints';
import qs from 'querystring';

import HttpClient from './apiHttpClient';

class OrderService {
  constructor(baseUrl) {
    this.httpClient = new HttpClient(baseUrl);
    this.baseEndpoint = FAPI_ORDER_ENDPOINT;
  }

  getOrdersList({
    pageNumber = 1,
    pageSize = 10,
    searchTerm,
    sessionId,
    sortBy = 'desc',
    orderBy = 'orderDate',
    selectedStatus = '',
    dateFrom,
    dateTo,
  }) {
    const params = new URLSearchParams({
      pageNumber,
      pageSize,
      orderBy,
      sortBy,
      searchTerm,
      status: selectedStatus,
    });

    if (sessionId) {
      params.append('sessionId', sessionId);
    }

    if (dateFrom) {
      params.append('dateFrom', dateFrom);
    }

    if (dateTo) {
      params.append('dateTo', dateTo);
    }

    const url = `${this.baseEndpoint}/list?${params}`;

    return this.httpClient.get(url);
  }

  getOrderDetailByNumber(orderNumber) {
    const url = `${this.baseEndpoint}/${orderNumber}`;

    return this.httpClient.get(url);
  }

  placeAnOrder({ orderData, sessionId }) {
    const searchParams = new URLSearchParams();
    let url = `${this.baseEndpoint}/create`;

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
      url = url.concat(`?${searchParams}`);
    }

    return this.httpClient.post(url, {
      body: orderData,
    });
  }

  getShoppingCart({ sessionId }) {
    const searchParams = new URLSearchParams();
    let url = `${this.baseEndpoint}/simulate`;

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
      url = url.concat(`?${searchParams}`);
    }

    return this.httpClient.get(url);
  }

  getPriceBoxData(materialNumber) {
    const url = `${this.baseEndpoint}/simulate/${materialNumber}`;

    return this.httpClient.get(url);
  }

  getMultipleInvoicesByInvoiceNumbers(invoiceNumbers) {
    const url = `${this.baseEndpoint}/invoice`;

    return this.httpClient.get(url, {
      queryParams: {
        invoiceNumbers,
      },
      paramsSerializer: {
        serialize: (params) => qs.stringify(params),
      },
    });
  }

  getProductsListByOrderNumber(orderNumber) {
    return this.httpClient.get(`${this.baseEndpoint}/${orderNumber}/invoice`);
  }

  validateZipCode(country, zipCode) {
    return this.httpClient.put(`${this.baseEndpoint}/zip-code/validate`, {
      body: {
        country,
        zipCode,
      },
    });
  }
}

export const orderService = new OrderService(environment.shopFAPIUrl);
