import { NotificationManager } from 'components/Notification';
import { SESSION_BASED_EXPIRY_TIME } from 'libs/constants';
import { isValidArray } from 'libs/utils/array';
import {
  clearCookie,
  getCookie,
  renewCookie,
  setCookie,
} from 'libs/utils/cookies';
import { hasAllPropertiesValid } from 'libs/utils/object';
import {
  convertCxmlDoc,
  submitOciForm,
  submitProcurementForm,
} from 'libs/utils/punchoutHandler';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { punchoutService } from 'services';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import { selectCartData } from 'store/selectors/cartSelector';
import {
  selectHasSessionBasedShoppingCart,
  selectIsProcurementTechnicalUser,
} from 'store/selectors/userSelector';
import { emptyCart, getMiniCart } from 'store/slices/cartSlice';
import { v4 as uuidv4 } from 'uuid';

const handleGetCachedData = async () => {
  const response = await punchoutService.getCachedCxmlData();
  return response?.data?.cxml;
};

const handleCleanCachedData = async () =>
  punchoutService.deleteCachedCxmlData();

const PunchoutContext = React.createContext();

const LOCAL_STORAGE_CART_SESSION_KEY = 'sessionId';

let sessionCookieKey = '';

export const getSessionCookieKey = () => sessionCookieKey;

const Provider = ({ children, userId }) => {
  const dispatch = useDispatch();
  const [isSubmited, setIsSubmited] = useState(false);

  const isProcurementUser = useSelector(selectIsProcurementTechnicalUser);
  const cartData = useSelector(selectCartData);
  const hasSessionBasedShoppingCart = useSelector(
    selectHasSessionBasedShoppingCart
  );

  const COOKIE_KEY = useMemo(() => {
    return isProcurementUser
      ? 'PUNCHOUT_SESSION_ID'
      : `${LOCAL_STORAGE_CART_SESSION_KEY}-${userId}`;
  }, [isProcurementUser, userId]);

  const localSessionId = getCookie(COOKIE_KEY);
  const urlFromCookie = getCookie('HOOK_URL');

  const clearShoppingCartSession = useCallback(() => {
    if (isProcurementUser) {
      handleCleanCachedData().then(({ error }) => console.error(error));
    }
    clearCookie(COOKIE_KEY);
  }, [COOKIE_KEY, isProcurementUser]);

  const renewShoppingCartSession = useCallback(() => {
    renewCookie(COOKIE_KEY, SESSION_BASED_EXPIRY_TIME);
  }, [COOKIE_KEY]);

  const sessionId = useMemo(() => {
    if (localSessionId) {
      return localSessionId;
    }

    const session = uuidv4();

    const expiredTime = new Date().getTime() + SESSION_BASED_EXPIRY_TIME;
    setCookie({ name: COOKIE_KEY, value: session, expires: expiredTime });

    return session;
  }, [COOKIE_KEY, localSessionId]);

  const onSubmitFailedHandler = () => {
    NotificationManager.error({
      message: 'notification.error.submitOrder',
      description: 'notification.error.noPunchoutUrlFound',
    });
  };

  const onSubmitCartViaOCI = useCallback(() => {
    // must check user is tech and have valid session
    if (urlFromCookie) {
      submitOciForm({ cartData, url: urlFromCookie });
      setIsSubmited(true);
    } else {
      onSubmitFailedHandler();
    }
  }, [cartData, urlFromCookie]);

  const onSubmitCartToProcurement = useCallback(async () => {
    try {
      const cachedData = await handleGetCachedData();
      const convertedCachedData = convertCxmlDoc(cachedData);
      if (hasAllPropertiesValid(convertedCachedData)) {
        submitProcurementForm(cartData, convertedCachedData);
        setIsSubmited(true);
      } else {
        console.error('cached cxml data:', convertedCachedData?.cxml);
        onSubmitFailedHandler();
      }
    } catch (err) {
      console.error('error while handling cxml cached data', err);
    }
  }, [cartData]);

  const onReturnToProcurement = useCallback(async () => {
    const emptyCartData = {
      items: [],
      currencyUnit: null,
      additionalCost: 0,
      cartSubTotal: 0,
      cartTotal: 0,
      cartTotalVAT: 0,
      shippingCosts: 0,
      voucherValue: {
        code: '',
        totalDiscount: '',
        materialNumbers: [],
        categoryIds: [],
      },
      vat: 0,
    };
    try {
      const cachedData = await handleGetCachedData();
      const convertedCachedData = convertCxmlDoc(cachedData);

      if (hasAllPropertiesValid(convertedCachedData)) {
        submitProcurementForm(emptyCartData, convertedCachedData);
        setIsSubmited(true);
      } else {
        console.error('cached cxml data:', convertedCachedData?.cxml);
        onSubmitFailedHandler();
      }
    } catch (err) {
      console.error('error while handling cxml cached data', err);
    }
  }, []);

  const onSubmitPunchoutData = useCallback(() => {
    if (cartData && isValidArray(cartData?.items)) {
      if (isProcurementUser) {
        onSubmitCartToProcurement();
      } else {
        onSubmitCartViaOCI();
      }
    }
  }, [
    cartData,
    isProcurementUser,
    onSubmitCartViaOCI,
    onSubmitCartToProcurement,
  ]);

  const [value, setValue] = React.useState({});

  useEffect(() => {
    if (hasSessionBasedShoppingCart) {
      sessionCookieKey = COOKIE_KEY;
    }
  }, [hasSessionBasedShoppingCart, COOKIE_KEY]);

  useEffect(() => {
    if (hasSessionBasedShoppingCart && sessionId) {
      dispatch(getMiniCart());
    }
  }, [dispatch, hasSessionBasedShoppingCart, sessionId]);

  useEffect(() => {
    if (isSubmited) {
      dispatch(emptyCart());
      clearShoppingCartSession();
    }
  }, [dispatch, isSubmited, clearShoppingCartSession]);

  useEffect(() => {
    if (hasSessionBasedShoppingCart) {
      setValue({
        sessionId,
        clearShoppingCartSession,
        renewShoppingCartSession,
        onSubmitPunchoutData,
        onReturnToProcurement,
      });
    }
  }, [
    hasSessionBasedShoppingCart,
    sessionId,
    clearShoppingCartSession,
    renewShoppingCartSession,
    onSubmitPunchoutData,
    onReturnToProcurement,
  ]);

  return (
    <PunchoutContext.Provider value={value}>
      {children}
    </PunchoutContext.Provider>
  );
};

const ProviderMemo = React.memo(Provider);

export default PunchoutContext;

export const PunchoutProvider = ({ children }) => {
  const userId = useSelector(selectUserId);

  if (userId) {
    return <ProviderMemo userId={userId}>{children}</ProviderMemo>;
  }

  return children;
};

Provider.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

Provider.defaultProps = {
  userId: null,
};

PunchoutProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
