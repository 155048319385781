import defaultDigitalData from 'digitalData';
import { PAGE_DEPENDENCIES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import { isValidArray } from 'libs/utils/array';
import {
  compareObjects,
  isEmptyObject,
  isObjectChecker,
} from 'libs/utils/object';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAdobeLinkName,
  selectAdobeStatus,
  selectAllAdobeData,
} from 'store/selectors/adobeAnalysisSelectors';
import {
  clearTrackedNavigationLink,
  trackPageInfo,
} from 'store/slices/adobeAnalyticSlice';

const useAdobeAnalysis = (trackData, pageInfo) => {
  const dispatch = useDispatch();
  const adobeTrackedData = useSelector(selectAllAdobeData);
  const adobeTrackedStatus = useSelector(selectAdobeStatus);
  const adobeLinkName = useSelector(selectAdobeLinkName);

  const [trackSections, setTrackSections] = useState([]);

  useEffect(() => {
    if (trackData) {
      setTrackSections(isValidArray(trackData) ? trackData : [trackData]);
    }
  }, [trackData]);

  const [pageInfoData, setPageInfoData] = useState(pageInfo);
  const [executable, setIsExecutable] = useState(false);

  useEffect(() => {
    if (isObjectChecker(pageInfoData)) {
      setTimeout(() => {
        dispatch(trackPageInfo(pageInfoData));
      }, 500);
    }
  }, [dispatch, pageInfoData]);

  const dependencies = useMemo(() => {
    const defaultDependencies = [TRACK_DATA.PAGEINFO];

    const concatenatedDependencies = trackSections.reduce(
      (dependenciesList, trackSection) => {
        if (PAGE_DEPENDENCIES[trackSection]) {
          return dependenciesList.concat(PAGE_DEPENDENCIES[trackSection]);
        }
        return dependenciesList;
      },
      defaultDependencies
    );
    // to do: check and remove the duplicated dependency items
    return concatenatedDependencies;
  }, [trackSections]);

  const onCheckDependencies = useCallback(() => {
    const hasDependencies =
      dependencies.filter((section) => !adobeTrackedStatus[section]).length > 0;

    setIsExecutable(!hasDependencies);
  }, [dependencies, adobeTrackedStatus]);

  const triggerPageTracking = useCallback(() => {
    const isEqualPage =
      window.digitalData?.page?.pageInfo?.pageName ===
      adobeTrackedData?.page?.pageInfo?.pageName;

    if (executable) {
      if (!isEmptyObject(adobeTrackedData) && !isEqualPage) {
        window.digitalData = adobeTrackedData;

        dispatch(clearTrackedNavigationLink());
      }

      if (
        isEqualPage &&
        !isEmptyObject(adobeTrackedData) &&
        !compareObjects(window.digitalData, adobeTrackedData)
      ) {
        // reset the pageName property with the defaul data
        window.digitalData = defaultDigitalData;

        // re-trigger the current tracked data
        setTimeout(() => {
          window.digitalData = adobeTrackedData;
        }, 1000);

        dispatch(clearTrackedNavigationLink());
      }
    }
  }, [dispatch, executable, adobeTrackedData]);

  useEffect(() => {
    onCheckDependencies();
  }, [onCheckDependencies]);

  useEffect(() => {
    triggerPageTracking();
  }, [triggerPageTracking]);

  useEffect(() => {
    if (adobeLinkName) {
      window.digitalData = {
        ...window.digitalData,
        link: {
          ...window.digitalData?.link,
          linkName: adobeLinkName,
        },
      };
    }
  }, [adobeLinkName]);

  return {
    triggerPageTracking,
    setPageInfoData,
  };
};

export default useAdobeAnalysis;
