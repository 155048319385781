import { NotificationManager } from 'components/Notification';
import { isEmptyObject } from 'libs/utils/object';
import { put, takeLatest } from 'redux-saga/effects';
import { ProductService } from 'services';
import {
  getClassTypesAndProductCount,
  getClassTypesAndProductCountError,
  getClassTypesAndProductCountSuccess,
  getProductsByClassType,
  getProductsByClassTypeError,
  getProductsByClassTypeSuccess,
  getProductSubCategoryOverviewData,
  getProductSubCategoryOverviewDataError,
  getProductSubCategoryOverviewDataSuccess,
  getSubmenuProductCategoryList,
  getSubmenuProductCategoryListError,
  getSubmenuProductCategoryListSuccess,
} from 'store/slices/categorySlice';

function removeFaultyProductCategory(data) {
  return data?.categories?.filter(
    (category) => isEmptyObject(category) === false
  );
}

function* getSubmenuProductCategoryListSaga() {
  try {
    const { data } = yield ProductService.getSubmenuProductCategoryList();

    const modifiedData = removeFaultyProductCategory(data);
    yield put(getSubmenuProductCategoryListSuccess(modifiedData));
  } catch (error) {
    yield put(getSubmenuProductCategoryListError());
    NotificationManager.error({
      message: 'notification.error.load',
    });
  }
}

function* getClassTypesAndProductCountSaga({ payload: { subCategoryId } }) {
  try {
    const { data } = yield ProductService.getClassTypesAndProductCount(
      +subCategoryId
    );

    yield put(getClassTypesAndProductCountSuccess(data));
  } catch (error) {
    yield put(getClassTypesAndProductCountError());
    NotificationManager.error({
      message: 'notification.error.load',
    });
  }
}

function* getProductsByClassTypeSaga({
  payload: {
    categoryId,
    pageNumber,
    sortOrder = 1,
    pageSize = 12,
    onlyProduct = false,
  },
}) {
  try {
    const { data } = yield ProductService.getProductsByClassType(
      +categoryId,
      pageNumber,
      sortOrder,
      pageSize
    );

    yield put(getProductsByClassTypeSuccess({ data, onlyProduct }));
  } catch (error) {
    yield put(getProductsByClassTypeError());
    NotificationManager.error({
      message: 'notification.error.load',
    });
  }
}

function* getProductSubCategoryOverviewDataSaga({ payload }) {
  const { categoryId } = payload;
  try {
    const response = yield ProductService.getProductSubCategoryOverviewData(
      categoryId
    );
    yield put(getProductSubCategoryOverviewDataSuccess(response.data));
  } catch (error) {
    yield put(getProductSubCategoryOverviewDataError());
    NotificationManager.error({
      message: 'notification.error.load',
    });
  }
}

export default function* categorySaga() {
  yield takeLatest(
    getSubmenuProductCategoryList.type.toString(),
    getSubmenuProductCategoryListSaga
  );
  yield takeLatest(
    getClassTypesAndProductCount.type.toString(),
    getClassTypesAndProductCountSaga
  );
  yield takeLatest(
    getProductsByClassType.type.toString(),
    getProductsByClassTypeSaga
  );
  yield takeLatest(
    getProductSubCategoryOverviewData.type.toString(),
    getProductSubCategoryOverviewDataSaga
  );
}
