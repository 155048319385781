/* eslint-disable no-param-reassign */
import { isValidArray } from 'libs/utils/array';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import { mappedSpecialItems } from 'libs/utils/shoppingCart';
import { isStringNotEmpty } from 'libs/utils/stringUltis';

const deliveryDatesByMaterialNumber = (deliveryDates = []) =>
  (deliveryDates ?? [])?.reduce((result, c) => {
    const {
      materialNumber,
      quantity: numberOfItems,
      deliveryDate,
      itemCateg,
    } = c;
    const cartItem = [materialNumber, itemCateg].join('|');
    (result[cartItem] || (result[cartItem] = [])).push({
      numberOfItems,
      deliveryDate: formatDate(deliveryDate, getFormatType().default),
    });
    return result;
  }, {});

const deliveryDatesByDate = (deliveryDates = []) =>
  (deliveryDates ?? [])?.reduce((result, c) => {
    const { quantity: numberOfItems, deliveryDate } = c;
    const deliveryDateFormatted = formatDate(
      deliveryDate,
      getFormatType().default
    );
    (
      result[deliveryDateFormatted] || (result[deliveryDateFormatted] = [])
    ).push({
      numberOfItems,
      deliveryDate: formatDate(deliveryDate, getFormatType().default),
    });
    return result;
  }, {});

const mapCartData = (data) => {
  let result = {
    additionalCost: 0,
    cartSubTotal: 0,
    cartTotal: 0,
    cartTotalVAT: 0,
    currencyUnit: '',
    items: [],
    shippingCosts: 0,
    voucherValue: {
      name: '',
      discount: 0,
    },
    checkoutSettings: {
      includingVAT: false,
      positionLvlConditions: false,
      shippingNotes: false,
    },
    vat: 0,
  };
  if (data) {
    result.additionalCost = data.additionalCost || 0;
    result.cartSubTotal = data.cartSubTotal || 0;
    result.cartTotal = data.cartTotal || 0;
    result.cartTotalVAT = data.cartTotalVAT || 0;
    result.currencyUnit = data.currencyUnit || '';
    result.shippingCosts = data.shippingCosts || 0;
    result.voucherValue = data.voucherValue || {};
    result.vat = data.vat || 0;
    // configurations
    result.checkoutSettings = {
      ...data.checkoutSettings,
      positionLvlConditions: isStringNotEmpty(
        data.checkoutSettings?.positionLvlConditions
      ),
    } || {
      includingVAT: false,
      positionLvlConditions: false,
      shippingNotes: false,
    };

    const items = [];
    const deliveryDates = deliveryDatesByMaterialNumber(data.deliveryDates);
    const deliveryDatesByDateData = deliveryDatesByDate(data.deliveryDates);

    if (isValidArray(data.items)) {
      data.items.forEach((item) => {
        const cartItem = `${item?.materialNumber}|${item?.itemCateg}`;
        const deliveryData = deliveryDates[cartItem];

        items.push({
          ...item,
          deliveryData,
          currencyUnit: data.currencyUnit || '',
        });
      });
    }

    result = {
      ...result,
      items: mappedSpecialItems(items),
      deliveryDatesByDateData,
    };
  }

  return result;
};

export default mapCartData;
