import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import B2becTable from '../../../components/B2becTable';
import useDummyItems from '../../../hooks/useDummyItems';
import tableColumns from './TableColumns';

const ProductCardTable = (props) => {
  const { dataSrc, isLoading } = props;
  const history = useHistory();
  const shapeOfProduct = {
    imgUrl: '',
    name: '',
    orderDate: '',
  };

  const fakeItems = useDummyItems(6, shapeOfProduct);
  const handleCellClick = (materialNumber) => {
    return history.push(linkGenerator(`/product/${materialNumber}`));
  };

  const matchingProductClick = (materialNumber) => {
    return history.push(linkGenerator(`/product/${materialNumber}/all`));
  };

  return (
    <B2becTable
      style={{ marginTop: 20 }}
      className="order-details-cart-table"
      columns={tableColumns(matchingProductClick, handleCellClick, isLoading)}
      dataSource={isLoading ? fakeItems : dataSrc}
      rowKey={isLoading ? 'materialNumber' : 'key'}
    />
  );
};

ProductCardTable.propTypes = {
  dataSrc: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

ProductCardTable.defaultProps = { dataSrc: [], isLoading: false };

export default ProductCardTable;
