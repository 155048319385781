import { createSelector } from '@reduxjs/toolkit';
import { DEFAULT_PROMOTION_ITEMS } from 'libs/constants';
import { isValidArray } from 'libs/utils/array';

export const selectFetchStatus = (state) => state?.promotion?.fetchStatus;
export const selectIsFetching = (state) => state?.promotion?.isFetching;
export const selectUpdateStatus = (state) => state?.promotion?.updateStatus;
export const selectIsUpdating = (state) => state?.promotion?.isUpdating;
export const selectProducts = (state) => state?.promotion?.products;
export const selectInvalidMaterialNumbers = (state) =>
  state?.promotion?.invalidMaterialNumbers;

export const selectPromotionProductsToShow = createSelector(
  selectProducts,
  selectIsFetching,
  (promotionProducts, isLoadingPromotionProducts) => {
    if (isLoadingPromotionProducts) {
      return DEFAULT_PROMOTION_ITEMS;
    }
    if (isValidArray(promotionProducts)) {
      // Filter non-empty object
      return promotionProducts.filter(
        (item) =>
          item && Object.keys(item).length !== 0 && item.constructor === Object
      );
    }
    // To show No Data state
    return [];
  }
);
