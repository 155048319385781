import './styles.scss';

import { Col, Empty, Row } from 'antd';
import B2becPagination from 'components/B2becPagination';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_SIZE } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetchingMachinesList,
  selectMachinesList,
  selectMachinesListTotal,
} from 'store/selectors/machineSelector';
import { getMachinesList } from 'store/slices/machineSlice';

import { ReactComponent as DownArrowIconSVG } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as UpArrowIconSVG } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as ListViewIcon } from '../../assets/icons/ListView.svg';
import { ReactComponent as TileViewIcon } from '../../assets/icons/TileView.svg';
import ProductCard from '../../components/ProductCard';
import B2becCardSkeleton from '../../components/Skeletons/B2becCardSkeleton';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import ProductListView from './ProductCardTable';

const AVAILABLE_SORT_DATA = ['ByOrderDateAsc', 'ByOrderDateDesc'];

function MyMachinesPage(props) {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageName: 'my machine',
    pageId,
    pageType: PAGE_TYPES.MACHINE,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const elementRef = useRef([]);

  const isFetchingMachinesList = useSelector(selectIsFetchingMachinesList);
  const machineList = useSelector(selectMachinesList);
  const total = useSelector(selectMachinesListTotal);

  const [sortOrder, setSortOrder] = useState('ByOrderDateDesc');
  const [showListView, setShowListView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

  const handleSort = () => {
    setCurrentPage(1);
    setSortOrder((sortValue) => {
      return AVAILABLE_SORT_DATA.find((key) => key !== sortValue);
    });
  };

  const handlePageChange = useCallback((current) => {
    setShouldScrollToTop(true);
    setCurrentPage(current);
  }, []);

  useLayoutEffect(() => {
    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
    }

    return () => setShouldScrollToTop(false);
  }, [shouldScrollToTop]);

  useEffect(() => {
    dispatch(
      getMachinesList({
        pageNumber: currentPage,
        pageSize: PAGE_SIZE[12],
        sortOrder,
      })
    );
  }, [dispatch, currentPage, sortOrder]);

  const renderProductGridView = () => {
    return isFetchingMachinesList ? (
      <B2becCardSkeleton
        gutter={20}
        columns={{ xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 6 }}
        imageHeight={530}
        hasPrice={false}
        number={8}
      />
    ) : isValidArray(machineList) ? (
      <Row
        gutter={20}
        className="product-card__wrapper"
        style={{ marginTop: 30 }}
      >
        {machineList.map((item, index) => (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={8}
            xxl={6}
            key={item?.key}
            id={`${item?.materialNumber || item?.materialId}-${
              item?.salesOrderNumber
            }`}
            className="card-item"
            ref={(element) => {
              elementRef.current[index] = element;
            }}
          >
            <ProductCard
              date={item?.orderDate}
              name={item?.name}
              img={item?.imgUrl}
              materialId={item?.materialNumber}
              category={item?.categoryName}
              serialNumber={item?.serialNumber}
              salesOrderNumber={item?.salesOrderNumber}
              ref={elementRef}
            />
          </Col>
        ))}
      </Row>
    ) : (
      <Empty description={t('noData')} />
    );
  };

  const renderContent = (arr) => {
    if (showListView) {
      return (
        <ProductListView dataSrc={arr} isLoading={isFetchingMachinesList} />
      );
    }
    return renderProductGridView();
  };

  return (
    <div className="my-machines__wrapper">
      <Row style={{ marginTop: '15px' }}>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1>{t('myMachines.title')}</h1>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: 'right' }}
          className="my-machines__sorting-wrapper"
        >
          <button
            disabled={isFetchingMachinesList}
            type="button"
            className="sort-btn my-machines__sort-btn"
            onClick={handleSort}
          >
            <span className="my-machines__sort-icons">
              <UpArrowIconSVG />
              <DownArrowIconSVG />
            </span>
            <span className="sort-btn__texts">{t('sorting.button.sort')}</span>
          </button>
          <span className="change-view-icons">
            <TileViewIcon onClick={() => setShowListView(false)} />
            <ListViewIcon onClick={() => setShowListView(true)} />
          </span>
        </Col>
      </Row>

      {renderContent(machineList)}

      <B2becPagination
        total={total}
        current={currentPage}
        onPageChange={handlePageChange}
        pageSize={PAGE_SIZE[12]}
      />
    </div>
  );
}

MyMachinesPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default MyMachinesPage;
