import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  totalCount: 0,
  data: {
    details: {
      items: [],
      shippingCosts: 0,
      checkoutSettings: {
        includingVAT: false,
        headLvlConditions: '',
        positionLvlConditions: false,
        shippingNotes: false,
      },
    },
  },
  orderData: {},
  deliveryData: {},
  isPlacingAnOrder: false,
  isFetchingOrdersList: false,
  isFetchingOrderDetails: false,
  // reorderIds: {},
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    getOrdersList: (state) => {
      state.isFetchingOrdersList = true;
    },
    getOrdersListSucceeded(state, { payload: { orderList } }) {
      state.isFetchingOrdersList = false;
      state.items = orderList?.items ?? [];
      state.totalCount = orderList?.totalCount ?? 0;
    },
    getOrdersListFailed(state) {
      state.isFetchingOrdersList = false;
    },
    getOrderDetailByNumber: (state) => {
      state.isFetchingOrderDetails = true;
    },
    getOrderDetailByNumberSucceeded(state, { payload }) {
      state.isFetchingOrderDetails = false;
      state.data.details = payload;
    },
    getOrderDetailByNumberFailed(state) {
      state.isFetchingOrderDetails = false;
    },
    // reorder: (state, { payload }) => {
    //   state.reorderIds[payload] = true;
    // },
    // reorderSucceeded(state, { payload }) {
    //   state.reorderIds[payload] = false;
    // },
    // reorderFailed(state, { payload }) {
    //   state.reorderIds[payload] = false;
    // },
    placeAnOrder(state) {
      state.isPlacingAnOrder = true;
    },
    placeAnOrderSucceeded(state, { payload }) {
      const { orderData, deliveryData } = payload || {};

      state.isPlacingAnOrder = false;
      state.orderData = orderData;
      state.deliveryData = deliveryData;
    },
    placeAnOrderFailed(state) {
      state.isPlacingAnOrder = false;
    },
    clearOrderDetails(state) {
      state.data.details = initialState.data.details;
    },
    clearOrderData(state) {
      state.orderData = initialState.orderData;
      state.deliveryData = initialState.deliveryData;
    },
  },
});

export const ordersActions = {
  ...ordersSlice.actions,
};

export default ordersSlice.reducer;
