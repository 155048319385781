export const specialAttributes = {
  replacedByMaterialNumber: 'oldMaterialNumber',
  bonusMaterial: 'originalMaterial',
};

export const itemCategories = {
  bonusMaterial: 'ZANN',
  oldMaterialNumber: 'ZNE',
  replacedByMaterialNumber: 'ZNS',
  default: 'ZNN',
};
