import { createSelector } from '@reduxjs/toolkit';

export const getIsLoadingProductDetail = (state) => state?.product?.isLoading;

export const getProductFetchStatus = (state) => state?.product?.actionStatus;

export const getProductDetailData = (state) => state?.product?.data;

export const getProductsBySubCategory = (state) => state.product.data.list;

export const selectIsFetchingMatchingProducts = (state) =>
  state.product.matchingProducts.isFetchingMatchingProducts;

export const selectMaterialName = (state) =>
  state.product.matchingProducts.accessories.materialName ||
  state.product.matchingProducts.detergents.materialName ||
  state.product.matchingProducts.suitableProducts.materialName;

const mapProducts = (products = []) =>
  products?.map(
    ({
      materialNumber: materialId,
      materialName: name,
      listPrice: price,
      pictureUrl: img,
      ...productData
    }) => ({
      name,
      materialId,
      price,
      img,
      ...productData,
    })
  );

export const selectAccessoryProducts = createSelector(
  [(state) => state.product.matchingProducts.accessories.items],
  mapProducts
);

export const selectSuitableProducts = createSelector(
  [(state) => state.product.matchingProducts.suitableProducts.items],
  mapProducts
);

export const selectDetergentProducts = createSelector(
  [(state) => state.product.matchingProducts.detergents.items],
  mapProducts
);

export const selectTotalAccessoryProducts = (state) =>
  state.product.matchingProducts.accessories.totalCount;

export const selectTotalDetergentProducts = (state) =>
  state.product.matchingProducts.detergents.totalCount;

export const selectTotalSuitableProducts = (state) =>
  state.product.matchingProducts.suitableProducts.totalCount;

export const selectProductsOfAllTypes = createSelector(
  [
    selectAccessoryProducts,
    selectSuitableProducts,
    selectDetergentProducts,
    selectTotalAccessoryProducts,
    selectTotalDetergentProducts,
    selectTotalSuitableProducts,
  ],
  (
    accessoryProducts,
    suitableProducts,
    detergentProducts,
    totalAccessoryProducts,
    totalDetergentProducts,
    totalSuitableProducts
  ) => ({
    suitableProducts: {
      data: suitableProducts,
      totalCount: totalSuitableProducts,
    },
    detergents: {
      data: detergentProducts,
      totalCount: totalDetergentProducts,
    },
    accessories: {
      data: accessoryProducts,
      totalCount: totalAccessoryProducts,
    },
  })
);

export const selectHasFetchedMatchingProductsError = (state) =>
  state.product.matchingProducts.hasFetchedError;

export const selectTechnicalData = (state) => state.product.data.technicalData;

export const selectEquipments = (state) => state.product.data.equipments;

export const selectApplications = (state) => state.product.data.applications;

export const selectDocuments = (state) => state.product.data.documents;

export const selectHasSuitableProducts = (state) =>
  state.product.data.hasSuitableProducts;

export const selectHasDetergents = (state) => state.product.data.hasDetergents;

export const selectHasAccessories = (state) =>
  state.product.data.hasAccessories;

export const selectMaterialNumber = (state) =>
  state.product.data.materialNumber;

export const selectLongDescription = (state) =>
  state.product.data.longDescription;

export const selectType = (state) => state.product.data.type;

export const selectDataToRenderAnchor = createSelector(
  [
    selectLongDescription,
    selectTechnicalData,
    selectEquipments,
    selectApplications,
    selectDocuments,
  ],
  (longDescription, technicalData, equipments, applications, documents) => ({
    longDescription,
    technicalData,
    equipments,
    applications,
    documents,
  })
);
