import { ReactComponent as Delete } from 'assets/icons/delete_icon_borderless.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import B2becActionButton from 'components/B2becActionButon';
import B2becTranslation from 'components/B2becTranslation';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const renderColumns = (
  handleEditContactReason,
  handleDeleteContactReason,
  isLoading,
  highlightedContactReasonId,
  isHighlighted,
  onHighlightAnimationEnd
) =>
  [
    {
      dataIndex: 'contactReasonId',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
    },
    {
      title: (
        <B2becTranslation value="contactReasonManagement.tableColumn.contactReasonNumber" />
      ),
      dataIndex: 'indexNumber',
      ellipsis: true,
      width: '10%',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
    },
    {
      title: (
        <B2becTranslation value="contactReasonManagement.tableColumn.contactReason" />
      ),
      dataIndex: 'translationContent',
      ellipsis: true,
      width: '10%',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
    },
    {
      title: (
        <B2becTranslation value="contactReasonManagement.tableColumn.receiverAddress" />
      ),
      dataIndex: 'receiverAddress',
      ellipsis: true,
      width: '10%',
      render: (text, record) => {
        return isLoading ? (
          <Skeleton />
        ) : (
          {
            props: {
              style: {
                animation:
                  isHighlighted &&
                  record?.contactReasonId === highlightedContactReasonId
                    ? 'contact-reason-table__cell--highlighting 1s'
                    : 'unset',
              },
            },
            children: (
              <span onAnimationEnd={onHighlightAnimationEnd}>{text}</span>
            ),
          }
        );
      },
    },
    {
      title: (
        <B2becTranslation value="contactReasonManagement.tableColumn.translationLink" />
      ),
      dataIndex: 'translationLink',
      ellipsis: true,
      width: '10%',
      render: (text) =>
        isLoading ? (
          <Skeleton />
        ) : (
          <span>
            <a href={text} target="_blank" rel="noopener noreferrer">
              {text}
            </a>
          </span>
        ),
    },
    {
      dataIndex: '',
      width: '6%',
      render: (_, record) => (
        <div className="contact-reason-table__action-wrapper">
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <B2becActionButton
                onClick={() => handleEditContactReason(record)}
              >
                <Edit />
              </B2becActionButton>
              <B2becActionButton
                onClick={() => handleDeleteContactReason(record)}
              >
                <Delete />
              </B2becActionButton>
            </>
          )}
        </div>
      ),
    },
  ].filter((col) => col?.dataIndex !== 'contactReasonId');

export default renderColumns;
