export const getIsLoadingSubmenuProductCategoryList = (state) =>
  state?.category?.isLoading;
export const selectSubmenuProductCategories = (state) =>
  state.category?.productCategories;
export const getSubmenuProductCategoryFetchStatus = (state) =>
  state?.category?.getProductSubmenuCategoryFetchStatus;

export const getProductOverviewData = (state) => state.category.productOverview;
export const selectIsLoadingSubCategoryOverview = (state) =>
  state?.category?.isLoadingSubCategoryOverview;
export const selectSubCategoryOverviewData = (state) =>
  state?.category?.subCategoryOverviewData;
