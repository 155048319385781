import { USER_STATUS, USER_TYPES } from 'libs/constants/user';
import { getLanguageCode } from 'libs/utils/adobeAnalysis';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserType } from 'store/selectors/authenticationSelectors';
import {
  selectUserCountryId,
  selectUserCurrency,
  selectUserLanguage,
  selectUserStatusLowerCase,
} from 'store/selectors/userSelector';
import { trackApplication } from 'store/slices/adobeAnalyticSlice';
import { getMiniCart } from 'store/slices/cartSlice';

export default function ActionsDispatcher() {
  const dispatch = useDispatch();

  const userStatus = useSelector(selectUserStatusLowerCase);
  const countryId = useSelector(selectUserCountryId);
  const language = useSelector(selectUserLanguage);
  const currency = useSelector(selectUserCurrency);
  const userType = useSelector(selectUserType);

  useEffect(() => {
    if (
      userStatus === USER_STATUS.AUTHORIZED &&
      userType !== USER_TYPES.EMPLOYEE_USER
    ) {
      dispatch(getMiniCart());
    }
  }, [dispatch, userStatus, userType]);

  useEffect(() => {
    if (countryId && currency && language) {
      dispatch(
        trackApplication({
          country: countryId,
          currency,
          language: getLanguageCode(language),
        })
      );
    }
  }, [dispatch, countryId, currency, language]);

  return null;
}
