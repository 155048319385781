import { createSlice } from '@reduxjs/toolkit';

const SAPLogSlice = createSlice({
  name: 'sapLog',
  initialState: {
    isFetching: false,
    data: {
      list: [],
      shouldShowLogView: false,
    },
  },
  reducers: {
    showSAPLogView: (state, action) => {
      state.data.shouldShowLogView = action.payload;
    },
    getSAPLog: (state) => {
      state.isFetching = true;
      state.data.shouldShowLogView = true;
    },
    getSAPLogSuccess: (state, action) => {
      state.isFetching = false;
      state.data.list = action.payload;
    },
    getSAPLogError: (state) => {
      state.isFetching = false;
      state.data.list = [];
    }
  },
});

export const { 
  showSAPLogView, 
  getSAPLog, 
  getSAPLogSuccess, 
  getSAPLogError 
} = SAPLogSlice.actions

export default SAPLogSlice.reducer;
