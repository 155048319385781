import { Form, Input } from 'antd';
import { formatMaterialID } from 'libs/utils/material-number';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './AddToCart.module.scss';

const MATERIAL_NUMBER_STANDARD_PATTERN =
  '^([0-9]{1}).([0-9]{3})-([0-9]{3}).([0-9]{1})';

const AddToCart = (_props, ref) => {
  const { t } = useTranslation();
  const [quickEntryValue, setQuickEntryValue] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const handleInputValue = (str) => {
    if (str.length < 11) {
      if (RegExp('[0-9]{8}').test(str)) {
        setErrMsg('');
        const formattedStr = formatMaterialID(str);
        setQuickEntryValue(formattedStr);
        return formattedStr;
      }
      setErrMsg('');
      return str;
    }
    if (str.length === 11) {
      if (RegExp(MATERIAL_NUMBER_STANDARD_PATTERN).test(str)) {
        setErrMsg('');
        return str;
      }
      setErrMsg('errors.stringFormat');
      return str;
    }
    setErrMsg('errors.maxInputExceeded');
    return null;
  };

  const onChangeQuickEntryValue = useCallback((e) => {
    const inputValue = handleInputValue(e.target.value.trim());
    if (inputValue !== null) {
      setQuickEntryValue(inputValue);
    }
  }, []);

  useEffect(() => {
    if (ref) {
      ref.current = quickEntryValue;
    }

    return () => {
      if (ref) {
        ref.current = '';
      }
    };
  }, [quickEntryValue, ref]);

  return (
    <div>
      <div className={styles.wrapper}>
        <Form.Item
          className="custom-form-item"
          label={t('cart.addQuickEntryBtn')}
          labelCol={{ span: 24 }}
        >
          <Input
            className={styles.input}
            placeholder={t('cart.addQuickEntryPlaceHolder')}
            value={quickEntryValue}
            onChange={onChangeQuickEntryValue}
          />
        </Form.Item>
        {errMsg.length > 0 ? (
          <div>
            <p className={styles['error-messages']}>{t(errMsg)}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.forwardRef(AddToCart);
