import { Checkbox, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShoppingCartType = ({ value: propValue, onChange, isDisabled }) => {
  const { t } = useTranslation();
  const value = propValue ? 'session' : 'user';

  return (
    <Checkbox.Group value={value} disabled={isDisabled}>
      <Row gutter={[12, 12]}>
        <Col>
          <Checkbox value="session" onChange={() => onChange(true)}>
            {t(
              'adminWorkspace.userManagement.userDetails.shoppingCartType.session'
            )}
          </Checkbox>
        </Col>
        <Col>
          <Checkbox value="user" onChange={() => onChange(false)}>
            {t(
              'adminWorkspace.userManagement.userDetails.shoppingCartType.user'
            )}
          </Checkbox>
        </Col>
      </Row>
    </Checkbox.Group>
  );
};

ShoppingCartType.defaultProps = {
  value: undefined,
  onChange: undefined,
  isDisabled: false,
};

ShoppingCartType.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default ShoppingCartType;
