const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  isFetchingUserDetails: false,
  isFetchingUserConfigurations: false,
  userDetails: null,
  userConfigurations: {},
  isChangingLanguage: false,
  customerNumber: '',
  countryId: '',
  userId: '',
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserConfigurations(state) {
      state.isFetchingUserConfigurations = true;
    },
    getUserConfigurationsSucceeded(state, { payload }) {
      state.isFetchingUserConfigurations = false;
      state.userConfigurations = payload;
    },
    getUserConfigurationsFailed(state) {
      state.isFetchingUserConfigurations = false;
    },
    changeUserSettingsLanguage(state, { payload }) {
      const { language, countryId } = payload;

      state.userSettings = {
        ...state.userSettings,
        language,
        culture: `${language}-${countryId}`,
      };
    },
    getUserDetails(state) {
      state.isFetchingUserDetails = true;
    },
    getUserDetailsSucceeded(state, { payload }) {
      state.isFetchingUserDetails = false;
      state.userDetails = payload;
    },
    getUserDetailsFailed(state) {
      state.isFetchingUserDetails = false;
    },
    requestChangeLanguage(state) {
      state.isChangingLanguage = true;
    },
    requestChangeLanguageSucceeded(state) {
      state.isChangingLanguage = false;
    },
    requestChangeLanguageFailed(state) {
      state.isChangingLanguage = false;
    },
    getCurrentTechnicalUserInfo(state) {
      state.isFetchingUserDetails = true;
    },
    getCurrentTechnicalUserInfoSucceeded(state, { payload }) {
      state.userDetails = payload;
      state.isFetchingUserDetails = false;
    },
    getCurrentTechnicalUserInfoFailed(state) {
      state.isFetchingUserDetails = false;
    },
    clearUserDetails(state) {
      state.userDetails = null;
      state.userSettings = null;
    },
    setCustomerNumber(state, { payload }) {
      Object.assign(state, payload);
    },
    clearCustomerNumber(state) {
      state.customerNumber = '';
      state.countryId = '';
      state.userId = '';
    },
  },
});

export const userActions = {
  ...user.actions,
};

export default user.reducer;
