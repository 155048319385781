import './styles.scss';

import { Calendar, Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import MonthSelection from '../MonthSelection';

const CustomCalendar = (props) => {
  const { onSelect, value: currentValue } = props;

  // eslint-disable-next-line no-unused-vars
  const renderHeader = ({ value = moment(), type, onChange, onTypeChange }) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const year = value ? value.year() : moment().year();
    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }
    return (
      <div className="custom-calendar__header">
        <Select
          size="small"
          dropdownMatchSelectWidth={false}
          className="custom-calendar__header__year"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onChange={(newYear) => {
            const now = value.clone().year(newYear);
            onChange(now);
          }}
          value={String(year)}
        >
          {options}
        </Select>
        <div className="custom-calendar__header__month">
          <MonthSelection
            selectedMonth={value ? value.month() : moment().month()}
            months={months}
            onChange={(newSelectedMonth) => {
              const newValue = value ? value.clone() : moment();
              newValue.month(parseInt(newSelectedMonth, 10));
              onChange(newValue);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="custom-calendar">
      <Calendar
        value={currentValue}
        headerRender={renderHeader}
        fullscreen={false}
        onSelect={onSelect}
        {...props}
      />
    </div>
  );
};

CustomCalendar.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({}),
};

CustomCalendar.defaultProps = {
  value: moment(),
};

export default CustomCalendar;
