import { Row } from 'antd';
import B2becButton from 'components/B2becButton';
import B2becSpinner from 'components/B2becSpinner';
import { useBreadcrumbs, useDocumentTitle } from 'hooks';
import { ASYNC_STATUS } from 'libs/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { fapiConfigurationService } from 'services/fapi';

import useAsync from '../../hooks/useAsync';
import { CONFIG_DATA_TYPES, CONFIG_NAMES } from '../../libs/constants/configs';
import ContactFormPage from '.';

const getContactReasonUrl = async () => {
  try {
    const data = await fapiConfigurationService.getConfigsByNames([
      {
        configurationKey: CONFIG_NAMES.SHOW_CONTACT_FORM,
        dataTypeDto: CONFIG_DATA_TYPES.STRING,
      },
    ]);

    return data[CONFIG_NAMES.SHOW_CONTACT_FORM];
  } catch {
    // TODO
  }

  return undefined;
};

const ContactFormPageWrapper = (props) => {
  const { title, breadcrumbs } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const { value, status } = useAsync(getContactReasonUrl, true);

  const isLoading =
    status === ASYNC_STATUS.PENDING || status === ASYNC_STATUS.IDLE;

  if (isLoading) {
    return <B2becSpinner isLoading />;
  }

  if (value) {
    return (
      <div className="contact-form__wrapper mt-6">
        <h1 className="contact-form__title fontBold mb-4">
          {t('contactForm.title')}
        </h1>
        <div>{t('contactForm.contactReasonMessage')}</div>
        <Row justify="end" className="mt-4">
          <B2becButton href={value} target="_blank">
            {t('contactForm.clickHereForContact')}
          </B2becButton>
        </Row>
      </div>
    );
  }

  return <ContactFormPage {...props} />;
};

export default ContactFormPageWrapper;
