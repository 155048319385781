import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

function CategoryTitle(props) {
  const { name, onClick } = props;
  return (
    <button
      className="header-menu-drawer__title-button"
      type="button"
      onClick={onClick}
    >
      {name}
    </button>
  );
}

CategoryTitle.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

CategoryTitle.defaultProps = {
  onClick: () => {},
};

export default CategoryTitle;
