import './styles.scss';

import { Col, Form, Input, Radio, Row } from 'antd';
import { SearchIcon } from 'components/Icons';
import { useCheckoutContext } from 'contexts/checkout-context';
import useDeviceDetect from 'hooks/useDeviceDetect';
import useModal from 'hooks/useModal';
import useRegion from 'hooks/useRegion';
import { getAddress } from 'libs/utils/addressUtils';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getCustomerAddress,
  getDeliveryAddresses,
} from 'store/selectors/customerSelector';
import { selectUserCountry } from 'store/selectors/userSelector';

import DeliveryAddressMobile from '../DeliveryAddressMobile';
import NewDeliveryAddress from '../NewDeliveryAddress';
import SearchAddress from '../SearchAddress';

const radioStyle = {
  height: '30px',
  lineHeight: '22px',
};

const DeliveryAddress = ({ form, disabled }) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const { Modal, toggleModal } = useModal();

  const [shouldFocusOnSearchInput, setShouldFocusOnSearchInput] =
    useState(false);

  const {
    customerInfo: { partnerNumber },
  } = useCheckoutContext();

  const [currentDeliveryAddr, setCurrentDeliveryAddr] = useState(1);
  const country = useSelector(selectUserCountry);
  const customerAddress = useSelector(getCustomerAddress);
  const deliveryAddresses = useSelector(getDeliveryAddresses);

  const [deliveryAddressesForMobile, setDeliveryAddressesForMobile] = useState(
    () => deliveryAddresses?.slice(0, 3)
  );

  const { isShowRegion } = useRegion();

  const getName = (item) => {
    return getAddress(item).name;
  };

  const setMyAddrValues = (
    myAddrCustomerNumber = null,
    myAddrName = null,
    myAddrName2 = null,
    myAddrStreet = null,
    myAddrStreet4 = null,
    myAddrHouseNumber = null,
    myAddrZip = null,
    myAddrRegion = null,
    myAddrCity = null,
    myAddrCountry = null,
    myAddrPhoneNumber = null,
    myAddrEmail = null
  ) => {
    form.setFieldsValue({
      myAddrPartnerNumber: myAddrCustomerNumber,
      myAddrName,
      myAddrName2,
      myAddrStreet,
      myAddrStreet4,
      myAddrHouseNumber,
      myAddrZip,
      myAddrRegion,
      myAddrCity,
      myAddrCountry,
      myAddrPhoneNumber,
      myAddrEmail,
    });
  };

  const setNewAddrValues = ({
    newAddrCustomerNumber = null,
    newAddrName = null,
    newAddrName2 = null,
    newAddrStreet = null,
    newAddrStreet4 = null,
    newAddrHouseNumber = null,
    newAddrZip = null,
    newAddrRegion = null,
    newAddrCity = null,
    newAddrCountry = null,
    newAddrPhoneNumber = null,
    newAddrEmail = null,
  }) => {
    form.setFieldsValue({
      newAddrPartnerNumber: newAddrCustomerNumber,
      newAddrName,
      newAddrName2,
      newAddrStreet,
      newAddrStreet4,
      newAddrHouseNumber,
      newAddrZip,
      newAddrRegion,
      newAddrCity,
      newAddrCountry,
      newAddrPhoneNumber,
      newAddrEmail,
    });
  };

  const handleSelectAddress = (selectedAddress) => {
    if (isMobile) {
      setDeliveryAddressesForMobile([selectedAddress]);
      toggleModal(false);
    }

    setMyAddrValues(
      selectedAddress.partnerNumber,
      getName(selectedAddress),
      '',
      selectedAddress.street,
      selectedAddress.street4,
      selectedAddress.houseNumber,
      selectedAddress.zip,
      selectedAddress.region,
      selectedAddress.city,
      selectedAddress.country,
      selectedAddress.phoneNumber,
      selectedAddress.eMail
    );
  };

  const handleChangeDeliveryAddr = (event) => {
    const newDeliveryAddr = event.target.value;
    setCurrentDeliveryAddr(newDeliveryAddr);
    form.setFieldsValue({
      currentDeliveryAddr: newDeliveryAddr,
    });
    if (newDeliveryAddr === 1) {
      if (deliveryAddresses?.length) {
        setMyAddrValues(
          deliveryAddresses[0]?.partnerNumber,
          getName(deliveryAddresses[0]),
          '',
          deliveryAddresses[0]?.street,
          deliveryAddresses[0]?.street4,
          deliveryAddresses[0]?.houseNumber,
          deliveryAddresses[0]?.zip,
          deliveryAddresses[0]?.region,
          deliveryAddresses[0]?.city,
          deliveryAddresses[0]?.country,
          deliveryAddresses[0]?.phoneNumber,
          deliveryAddresses[0]?.eMail
        );
      } else {
        setMyAddrValues(
          partnerNumber,
          getName(customerAddress),
          '',
          customerAddress?.street,
          customerAddress?.street4,
          customerAddress?.houseNumber,
          customerAddress?.zip,
          customerAddress?.region,
          customerAddress?.city,
          customerAddress?.country,
          customerAddress?.phoneNumber,
          customerAddress?.eMail
        );
      }
    }
    if (newDeliveryAddr === 2 && country) {
      setNewAddrValues({ newAddrCountry: country });
    }
  };

  const openModal = () => {
    toggleModal(true);
    setShouldFocusOnSearchInput(true);
  };

  const renderSearchInputMobile = () => {
    return (
      <div className="custom-form-item mb-5">
        <Input
          onClick={openModal}
          placeholder={t('checkout.step1.addressSearch')}
          prefix={<SearchIcon />}
        />
      </div>
    );
  };

  const onShowAllAddresses = () => {
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const renderSearchAddressBlock = () => {
    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const myAddrPartnerNumber = getFieldValue('myAddrPartnerNumber');

          return (
            <SearchAddress
              value={{ partnerNumber: myAddrPartnerNumber }}
              dataSource={deliveryAddresses || [customerAddress]}
              onChange={handleSelectAddress}
              onCancel={closeModal}
              shouldFocusOnSearchInput={shouldFocusOnSearchInput}
            />
          );
        }}
      </Form.Item>
    );
  };

  const renderMyAddressSection = () => {
    return (
      <div className="delivery-content" style={{ display: 'none' }}>
        <Form.Item name="partnerNumber" className="mt-4 mb-4">
          <Input disabled />
        </Form.Item>
        <Form.Item name="myAddrName" className="mt-2">
          <Input disabled />
        </Form.Item>
        <div style={{ display: 'none' }}>
          <Form.Item name="myAddrZip">
            <Input disabled />
          </Form.Item>
          {isShowRegion && (
            <Form.Item name="myAddrRegion">
              <Input disabled />
            </Form.Item>
          )}
          <Form.Item name="myAddrCity">
            <Input disabled />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <span style={{ display: 'inline-block', width: '20%' }}>
            {t('form.label.contactNumber')}:&nbsp;&nbsp;
          </span>
          <Form.Item name="myAddrPhoneNumber">
            <Input disabled />
          </Form.Item>
        </div>
        <div style={{ display: 'flex' }}>
          <span style={{ display: 'inline-block', width: '20%' }}>
            {t('form.label.contactEmail')}:&nbsp;&nbsp;
          </span>
          <Form.Item
            name="myAddrEmail"
            style={{ display: 'inline-block', width: '80%' }}
          >
            <Input disabled />
          </Form.Item>
        </div>
        <Form.Item name="myAddrCountry">
          <Input disabled />
        </Form.Item>
        {/* Hidden Field */}
        <Form.Item name="myAddrName2" hidden>
          <Input
            disabled
            className="delivery-content__field-input--disabled"
            data-testid="my-address-name-2"
          />
        </Form.Item>
        <Form.Item name="myAddrPartnerNumber" hidden>
          <Input
            disabled
            className="delivery-content__field-input--disabled"
            data-testid="partner-number"
          />
        </Form.Item>
        <Form.Item name="myAddrStreet" hidden>
          <Input
            disabled
            className="delivery-content__field-input--disabled"
            data-testid="my-address-street"
            style={{ width: '10rem' }}
          />
        </Form.Item>
        <Form.Item name="myAddrStreet4" hidden>
          <Input
            disabled
            className="delivery-content__field-input--disabled"
            data-testid="my-address-street4"
            style={{ width: '10rem' }}
          />
        </Form.Item>
        <Form.Item name="myAddrHouseNumber" hidden>
          <Input
            disabled
            className="delivery-content__field-input--disabled"
            data-testid="houseNumber"
          />
        </Form.Item>
      </div>
    );
  };

  return (
    <Row className="delivery__wrapper mt-5">
      <Col span={24}>
        <div className="checkout-step1-section__heading">
          <span className="checkout-step1-section__heading__left">
            {t('checkout.step1.deliveryAddress')}
          </span>
          {isMobile && (
            <button
              type="button"
              className="button-as-link"
              onClick={onShowAllAddresses}
            >
              {t('checkout.step1.allAddresses')}
            </button>
          )}
        </div>
      </Col>
      {isMobile && renderSearchInputMobile()}
      {isMobile && (
        <Modal
          closable={false}
          footer={null}
          className="SearchAddressModal"
          bodyStyle={{
            overflowY: 'auto',
          }}
        >
          {renderSearchAddressBlock()}
        </Modal>
      )}
      {isMobile ? (
        <Col span={24}>
          <DeliveryAddressMobile
            deliveryAddresses={deliveryAddressesForMobile}
            form={form}
            handleChangeDeliveryAddr={handleChangeDeliveryAddr}
          />
        </Col>
      ) : (
        <Col span={24}>
          <Radio.Group
            value={currentDeliveryAddr}
            onChange={handleChangeDeliveryAddr}
            className="custom-input"
            style={{ width: '100%' }}
            disabled={disabled}
          >
            <Radio key={1} style={radioStyle} value={1}>
              <span className="mt-6 mb-4 delivery__option__title">
                {t('form.label.myAddress')}
              </span>
            </Radio>
            <Radio key={2} style={radioStyle} value={2}>
              <span className="mt-6 mb-4 delivery__option__title">
                {t('form.label.newAddress')}
              </span>
            </Radio>
          </Radio.Group>
          {currentDeliveryAddr === 2 ? (
            <NewDeliveryAddress form={form} />
          ) : (
            <div className="mt-4">{renderSearchAddressBlock()}</div>
          )}
          {renderMyAddressSection()}
        </Col>
      )}
      <Form.Item name="currentDeliveryAddr" className="ml-4 mt-2" hidden>
        <Input disabled data-testid="currentDeliveryAddr" />
      </Form.Item>
    </Row>
  );
};

DeliveryAddress.propTypes = {
  form: PropTypes.shape({}).isRequired,
};

export default DeliveryAddress;
