const PRODUCT_TYPES = {
  MACHINE: 1,
  ACCESSORY: 2,
  DETERGENT: 3,
  MARKETING: 4,
};

export const PRODUCT_TYPES_TITLE = {
  [PRODUCT_TYPES.MACHINE]: 'Machine',
  [PRODUCT_TYPES.ACCESSORY]: 'Accessory',
  [PRODUCT_TYPES.DETERGENT]: 'Detergent',
  [PRODUCT_TYPES.MARKETING]: 'Marketing',
};

export default PRODUCT_TYPES;

export const PRODUCT_COMPARISON = 'productComparison';
export const PRODUCT_COMPARISON_EXTRA = 'productComparisonExtra';
