import { Table } from 'antd';
import B2becInfoLayout from 'components/B2becInfoLayout';
import B2becTable from 'components/B2becTable';
import CallToActionButton from 'components/CallToActionButton';
import CustomButton from 'components/CustomButton';
import {
  DeleteBorderlessIcon as DeleteIcon,
  DuplicateIcon,
} from 'components/Icons';
import { useDocumentTitle } from 'hooks';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  isRoleLoading,
  roleSelectors,
  selectFetchingIds,
} from 'store/selectors/roleSelector';
import {
  deleteRole,
  duplicateRoleById,
  getListParentRole,
  getRolesList,
} from 'store/slices/roleSlice';

import styles from './RoleManagementPage.module.scss';
import RoleModal from './RoleModal';

export const INDELIBLE_ROLES = ['Admin', 'Registered User'];

const RoleManagementPage = (props) => {
  const { title, breadcrumbs } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchingIds = useSelector(selectFetchingIds);
  const isRolesListLoading = useSelector(isRoleLoading);
  const [deleteRecord, setDeleteRecord] = useState({});
  const [isShowModal, setIsShowModal] = useState(false);

  const dataSource = useSelector(roleSelectors.selectAll);

  useEffect(() => {
    dispatch(getRolesList());
  }, [dispatch]);

  const handleDuplicate = (e, roleId) => {
    e.stopPropagation();

    if (roleId) {
      dispatch(duplicateRoleById(roleId));
    }
  };

  const handleDeleteRole = (event, record) => {
    event.stopPropagation();
    setDeleteRecord(record);
    setIsShowModal(true);
  };

  const onCloseModal = () => {
    setIsShowModal(false);
  };

  const handleDeleteSuccess = () => {
    dispatch(deleteRole(deleteRecord?.roleId));
    dispatch(getListParentRole());
    onCloseModal();
  };

  return (
    <B2becInfoLayout className={styles.roleManagementPage}>
      <B2becInfoLayout.Title>
        <div className={styles.title}>
          <h1>{t('pageTitle.roleManagement')}</h1>
          <CustomButton
            onClick={() => {
              history.push(linkGenerator('/roles-management/create-role'));
            }}
          >
            {t('adminWorkspace.roleManagement.addNewRole')}
          </CustomButton>
        </div>
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        <B2becTable
          dataSource={dataSource}
          loading={isRolesListLoading}
          rowKey="roleId"
          onRow={(record) => ({
            onClick: () => {
              return history.push(
                linkGenerator(`/roles-management/${record.roleId}`)
              );
            },
          })}
        >
          <Table.Column title="" dataIndex="roleId" render={() => ''} />
          <Table.Column
            title={t('adminWorkspace.roleManagement.name')}
            dataIndex="roleName"
            render={(value) => <strong>{value}</strong>}
          />
          <Table.Column
            title={t('adminWorkspace.roleManagement.parentRole')}
            dataIndex="parentRoleText"
          />
          <Table.Column
            title={t('adminWorkspace.roleManagement.tableDescription')}
            dataIndex="description"
          />
          <Table.Column
            title={t('adminWorkspace.roleManagement.numberOfUsers')}
            dataIndex="numberOfUsers"
          />
          <Table.Column
            key="action"
            width={100}
            render={(_, record) => {
              const isIndelibleRole = INDELIBLE_ROLES.includes(
                record?.roleName
              );
              const isAdminRole = record?.roleName === INDELIBLE_ROLES[0];

              return (
                <div className={styles.actionButtons}>
                  {!isAdminRole && (
                    <CallToActionButton
                      loading={fetchingIds[record?.roleId]}
                      leftIcon={<DuplicateIcon />}
                      onClick={(e) => handleDuplicate(e, record?.roleId)}
                    />
                  )}
                  {!isIndelibleRole && (
                    <CallToActionButton
                      leftIcon={<DeleteIcon />}
                      onClick={(event) => handleDeleteRole(event, record)}
                    />
                  )}
                </div>
              );
            }}
          />
        </B2becTable>
        {isShowModal && (
          <RoleModal
            deleteRecord={deleteRecord}
            isShowModal={isShowModal}
            onCloseModal={onCloseModal}
            handleDeleteSuccess={handleDeleteSuccess}
          />
        )}
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
};

RoleManagementPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default React.memo(RoleManagementPage);
