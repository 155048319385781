import usePermission from 'hooks/usePermission';
import PropTypes from 'prop-types';
import React from 'react';

const PermissionWrapper = ({ permission, children }) => {
  const { hasPermission } = usePermission(permission);

  if (hasPermission) {
    return <>{children}</>;
  }

  return null;
};
PermissionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  permission: PropTypes.string.isRequired,
};
export default PermissionWrapper;
