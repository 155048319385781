import './styles.scss';

import { Radio } from 'antd';
import { REGISTRATION_CUSTOMER_TYPE } from 'libs/constants/registration';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomerRequestTypeSelection = (props) => {
  const { onSetCustomerType } = props;
  const { t } = useTranslation();

  const REGISTRATION_TYPE_OPTIONS = [
    {
      label: t('customerTypes.newCustomer'),
      value: REGISTRATION_CUSTOMER_TYPE.NEW_CUSTOMER,
    },
    {
      label: t('customerTypes.existingCustomer'),
      value: REGISTRATION_CUSTOMER_TYPE.EXISTING_CUSTOMER,
    },
  ];

  const onChangeCustomerType = (e) => {
    onSetCustomerType(e?.target?.value);
  };

  return (
    <div className="self-registration__customer-type-selection__wrapper">
      <Radio.Group
        onChange={onChangeCustomerType}
        defaultValue={REGISTRATION_CUSTOMER_TYPE.NEW_CUSTOMER}
      >
        {REGISTRATION_TYPE_OPTIONS.map((registrationType) => (
          <Radio
            value={registrationType?.value}
            key={registrationType?.value}
            className="custom-form-item"
          >
            {registrationType?.label}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default CustomerRequestTypeSelection;

CustomerRequestTypeSelection.propTypes = {
  onSetCustomerType: PropTypes.func,
};

CustomerRequestTypeSelection.defaultProps = {
  onSetCustomerType: () => {},
};
