export const itemTemplate = ({
  quantity = 0,
  materialNumber = '',
  name = '',
  price = 0,
  currencyUnit = 'EUR',
  leadTime = 10,
  classification = 47121800,
  unitOfMeasure = 'C62',
  manufacturerPartID = '',
  manufacturerName = 'Alfred Kärcher SE & Co. KG',
}) => {
  return `<ItemIn quantity='${quantity}' itemType='item'>
  <ItemID>
      <SupplierPartID>${materialNumber}</SupplierPartID>
  </ItemID>
  <ItemDetail>
      <UnitPrice>
          <Money currency='${currencyUnit}'>
              ${price}
          </Money>
      </UnitPrice>
      <Description xml:lang='en'>${name}</Description>
      <UnitOfMeasure>${unitOfMeasure}</UnitOfMeasure>
      <Classification domain='UNSPSC'>${classification}</Classification>
      <ManufacturerPartID>${manufacturerPartID}</ManufacturerPartID>
      <ManufacturerName>${manufacturerName}</ManufacturerName>
      <LeadTime>${leadTime}</LeadTime>
  </ItemDetail>
</ItemIn>`;
};

export const credentialTemplate = (credential) => {
  const userAgent = Object.getOwnPropertyDescriptor(credential, 'userAgent')
    ? `<UserAgent>${credential.userAgent}</UserAgent>`
    : '';

  return `<Credential domain='${credential.domain}'>
      <Identity>${credential.Identity}</Identity>
    </Credential>${userAgent}`;
};

export const poomTemplate = (cxmlData, cartItemsTemplate = '') => {
  const {
    payloadId,
    lang = 'de_DE',
    credentials,
    buyerCookie,
    currency,
    totalPrice,
    shippingCost,
    vat,
  } = cxmlData;

  const { buyer, supplier, sender } = credentials;
  const timestamp = new Date().toISOString();

  return `<?xml version='1.0' encoding='UTF-8'?>
  <!DOCTYPE cXML SYSTEM 'http://xml.cxml.org/schemas/cXML/1.2.048/cXML.dtd'>
  <cXML payloadID='${payloadId}' timestamp='${timestamp}' xml:lang='${lang}'>
      <Header>
          <From>${supplier}</From>
          <To>${buyer}</To>
          <Sender>${sender}</Sender>
      </Header>
      <Message>
          <PunchOutOrderMessage>
              <BuyerCookie>${buyerCookie}</BuyerCookie>
              <PunchOutOrderMessageHeader operationAllowed='edit'>
                  <Total>
                      <Money currency='${currency}'>${totalPrice}</Money>
                  </Total>
                  <Shipping>
                      <Money currency='${currency}'>${shippingCost}</Money>
                      <Description xml:lang='en'>Freight Cost</Description>
                  </Shipping>
                  <Tax>
                      <Money currency='${currency}'>${vat}</Money>
                      <Description xml:lang='en'>VAT</Description>
                  </Tax>
              </PunchOutOrderMessageHeader>
              ${cartItemsTemplate}
          </PunchOutOrderMessage>
      </Message>
  </cXML>`;
};
