import PropTypes from 'prop-types';
import React from 'react';

const DangerouslySetInnerElement = ({ rawHTML, wrapperTag }) => {
  // reference: https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml

  // the rawHTML string may contain some special symbols like &quot; &middot; etc
  return React.createElement(wrapperTag, {
    dangerouslySetInnerHTML: { __html: rawHTML },
  });
};

export default DangerouslySetInnerElement;

DangerouslySetInnerElement.propTypes = {
  rawHTML: PropTypes.string,
  wrapperTag: PropTypes.string,
};

DangerouslySetInnerElement.defaultProps = {
  rawHTML: '',
  wrapperTag: 'div',
};
