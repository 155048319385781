import { createSelector } from '@reduxjs/toolkit';
import { isValidArray } from 'libs/utils/array';
import { rolesAdapter } from 'store/slices/roleSlice';

export const selectRoleDetails = (state) => state.role.roleDetail;
export const selectHierarchyList = (state) => state.role.hierarchy.list;
export const selectIsLoading = (state) => state.role.isLoading;
export const selectIsFetchingHierarchy = (state) =>
  state.role.hierarchy.isLoading;
export const selectUserRightList = (state) => state.role.rightList;

export const getRoleHierarchy = createSelector(
  selectHierarchyList,
  selectRoleDetails,
  (hierarchyList, roleDetails) => {
    return isValidArray(hierarchyList) && Object.keys(roleDetails).length !== 0
      ? hierarchyList.find(
          (hierarchy) => hierarchy?.hierarchyId === roleDetails?.hierarchy
        ).hierarchyPath
      : roleDetails?.hierarchy;
  }
);

export const roleSelectors = rolesAdapter.getSelectors((state) => state.role);

export const roleOptionsSelector = (state) => state.role.roleOptions.data;

export const isRoleOptionsLoading = (state) => state.role.roleOptions.isLoading;

export const isRoleLoading = (state) => !!state.role.isLoading;

export const selectIsFetchingDuplicatedRoleDetails = (state) =>
  state.role.duplicate.isFetchingDetails;
export const selectIsDuplicatingRole = (state) =>
  state.role.duplicate.isDuplicating;
export const selectFetchingIds = (state) => state.role.duplicate.fetchingIds;
export const selectDuplicatedRoleDetails = (state) =>
  state.role.duplicate.roleDetails;
export const selectAssignableCountryRolesList = (state) =>
  state.role?.assignableCountryRoleList;
export const selectAssignableCountries = (state) =>
  state?.role?.assignableCountries;

export const selectAssignedRolesByUserId = (userId) => (state) =>
  state.role.assignedRoles[userId];

export const selectIsFetchingUserAssignedRoles = (state) =>
  state.role.isFetchingUserAssignedRoles;
