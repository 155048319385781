import { Avatar } from 'antd';
import { ReactComponent as Delete } from 'assets/icons/delete_icon_borderless.svg';
import B2becActionButton from 'components/B2becActionButon';
import B2becTranslation from 'components/B2becTranslation';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import AddProductImage from '../../../../../assets/img/addproduct.svg';

const renderColumns = (
  handleDeleteRent,
  isLoading,
  onCellClick,
  hasDeleteRentalPermission
) =>
  [
    {
      dataIndex: 'imgUrl',
      onCell: ({ materialNumber }) => ({
        onClick: () => !isLoading && onCellClick(materialNumber),
      }),
      width: '10%',
      render: (text) => {
        return isLoading ? (
          <Skeleton />
        ) : (
          <Avatar size={80} shape="square" src={text || AddProductImage} />
        );
      },
    },
    {
      title: <B2becTranslation value="rentalManagement.tableColumn.name" />,
      dataIndex: 'name',
      onCell: ({ materialNumber }) => ({
        onClick: () => !isLoading && onCellClick(materialNumber),
      }),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      ellipsis: true,
      width: '25%',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
    },
    {
      title: (
        <B2becTranslation value="rentalManagement.tableColumn.materialNumber" />
      ),
      dataIndex: 'materialNumber',
      onCell: ({ materialNumber }) => ({
        onClick: () => !isLoading && onCellClick(materialNumber),
      }),
      ellipsis: true,
      width: '15%',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
    },
    {
      title: <B2becTranslation value="rentalManagement.tableColumn.category" />,
      dataIndex: 'category',
      onCell: ({ materialNumber }) => ({
        onClick: () => !isLoading && onCellClick(materialNumber),
      }),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '20%',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
    },
    {
      title: (
        <B2becTranslation value="rentalManagement.tableColumn.subcategory" />
      ),
      dataIndex: 'subCategory',
      onCell: ({ materialNumber }) => ({
        onClick: () => !isLoading && onCellClick(materialNumber),
      }),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '20%',
      render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
    },
    {
      dataIndex: '',
      width: '10%',
      hidden: !hasDeleteRentalPermission,
      render: (record) => (
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <B2becActionButton
                onClick={() => handleDeleteRent(record?.materialNumber)}
              >
                <Delete />
              </B2becActionButton>
            </>
          )}
        </div>
      ),
    },
  ].filter((col) => !col.hidden);

export default renderColumns;
