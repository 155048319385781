import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  replacePlaceholdersWithData,
  translateDynamicText,
  translateFixedText,
} from './useBreadcrumbs';

/**
 * React Hook to set document title.
 * Example:
 * initialTitle: 'orderDetail.order $1'
 * dataArray: ['123456']
 * document.title = 'Order 123456'
 *
 * @param {String} initialTitle - The prototype of title.
 * @param {Object[]} dataArray[] - The array of objects in the order that respective to the placeholders inside the
 * prototype of title above.
 * @param {Boolean} isTranslated - Flag to mark the title as translated or not.
 */
const useDocumentTitle = (
  initialTitle = '',
  dataArray = [],
  isTranslated = false
) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(initialTitle);
  const [titleData, setTitleData] = useState(dataArray);
  const [isTitleTranslated, setIsTitleTranslated] = useState(isTranslated);

  useEffect(() => {
    let translatedTitle = title || '';

    // Search for placeholders to check if this title is dynamic or not
    const isDynamic = translatedTitle.search(/\$[0-9][0-9]*/) !== -1;

    // Title is not translated
    if (!isTitleTranslated) {
      translatedTitle = isDynamic
        ? translateDynamicText(translatedTitle, t)
        : translateFixedText(translatedTitle, t);

      // For this case: initialTitle = '$1' => after translation => translatedTitle = '$1' => correct
      // Only check for not dynamic case
      if (!isDynamic) {
        translatedTitle =
          // translatedTitle unchanged after translation => error => show default one
          translatedTitle && translatedTitle !== title ? translatedTitle : '';
      }
    }
    // Dynamic case with placeholders ($1-$99..99)
    if (isDynamic) {
      translatedTitle = replacePlaceholdersWithData(
        translatedTitle,
        (placeholderIndex) => titleData && titleData[placeholderIndex]
      );
    }

    document.title = translatedTitle || t('pageTitle.default');

    return () => {
      // Reset page title to default one on page change
      document.title = t('pageTitle.default');
    };
  }, [t, title, titleData, isTitleTranslated]);

  const returnValue = [setTitle, setTitleData, setIsTitleTranslated];
  returnValue.setTitle = setTitle;
  returnValue.setTitleData = setTitleData;
  returnValue.setIsTitleTranslated = setIsTitleTranslated;
  return returnValue;
};

export default useDocumentTitle;
