import clsx from 'clsx';
import React from 'react';

import styles from './Skeleton.module.scss';

function LoadingSkeleton({ className, ...rest }) {
  return <div className={clsx(styles.skeleton, className)} {...rest} />;
}

export default LoadingSkeleton;
