import { WarningOutlined } from '@ant-design/icons';
import B2BecLink from 'components/B2BLink';
import Footer from 'components/Layout/components/Footer';
import Header from 'components/PublicHeader/Header';
import { useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './NotAuthorizedPage.module.scss';

const NotAuthorizedPage = ({ title, pageId }) => {
  useDocumentTitle(title);
  const { t } = useTranslation();

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'Not Authorized page',
    pageType: PAGE_TYPES.ERROR,
  });

  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          <WarningOutlined />
        </h1>
        <p className={styles.description}>{t('errors.notAuthorizedMessage')}</p>
        <p className={styles['back-button']}>
          <B2BecLink to="">{t('errors.backHomeButton')}</B2BecLink>
        </p>
      </div>
      <Footer />
    </>
  );
};

NotAuthorizedPage.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default NotAuthorizedPage;
