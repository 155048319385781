import { notification } from 'antd';
import B2becTranslation from 'components/B2becTranslation';
import React from 'react';

const MAX_TOASTS = 3;
const TOAST_DISPLAY_TIME = 3.75;

export const notificationComponent = (props) => {
  const { type = 'HIDE_ERROR', message, description = '', ...rest } = props;

  return notification[type]({
    message,
    description,
    duration: TOAST_DISPLAY_TIME,
    maxCount: MAX_TOASTS,
    ...rest,
  });
};

const getConfigs = ({
  message,
  description,
  variables,
  descriptionVars,
  ...rest
}) => {
  let messageProps = null;
  let descriptionProps = null;

  if (message) {
    messageProps = { ...messageProps, value: message };
  }

  if (variables) {
    messageProps = { ...messageProps, variables };
  }

  if (description) {
    descriptionProps = { ...descriptionProps, value: description };
  }

  const MessageComponent = <B2becTranslation {...messageProps} />;
  const DescriptionComponent = description ? (
    <B2becTranslation {...descriptionProps} />
  ) : null;

  const configs = {
    duration: TOAST_DISPLAY_TIME,
    maxCount: MAX_TOASTS,
    message: MessageComponent,
    description: DescriptionComponent,
    ...rest,
  };

  return configs;
};

const success = (props) => notification.success({ ...getConfigs(props) });
const info = (props) => notification.info({ ...getConfigs(props) });
const warning = (props) => notification.warning({ ...getConfigs(props) });
const error = (props) => notification.error({ ...getConfigs(props) });

export const NotificationManager = {
  success,
  info,
  warning,
  error,
};
