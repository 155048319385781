import B2bLoadingFullPage from 'components/B2bLoadingFullPage';
import { getInfoFromUrl } from 'libs/utils/punchoutHandler';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authenticationActions } from 'store/slices/authenticationSlice';

export default function TechnicalLoginPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const { href = '', origin = '', pathname = '' } = window?.location;
    const queryString = href.replace(origin + pathname, '').slice(1);
    const queryKeys = ['username', 'password'];
    const punchout = getInfoFromUrl(queryString, queryKeys);

    dispatch(
      authenticationActions.signIn({
        username: decodeURIComponent(punchout?.username),
        password: decodeURIComponent(punchout?.password),
      })
    );
  }, [dispatch]);

  return <B2bLoadingFullPage />;
}
