import clsx from 'clsx';
import React from 'react';

import styles from './Search.module.scss';

export const Search = ({ icon: Icon, size = 'large', onSearch, ...rest }) => {
  const debounceTimeoutRef = React.useRef(null);

  const handleSearch = (e) => {
    const { value } = e.target;
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      onSearch(value);
    }, 350);
  };

  return (
    <div className={styles.inputContainer}>
      <div className={styles.iconContainer}>
        <Icon className={styles.icon} />
      </div>
      <input
        {...rest}
        autoComplete="off"
        type="text"
        className={clsx(styles.input, styles[`${size}`])}
        onChange={handleSearch}
      />
    </div>
  );
};
