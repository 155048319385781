import React from 'react';

import B2becTranslation from '../../../../../components/B2becTranslation';

export const validateRequiredMessage = (labelId) => (
  <>
    <B2becTranslation value={labelId} />{' '}
    <B2becTranslation value="voucher.validationMessage.required" />
  </>
);

export const validateDiscount = (discount) => ({
  validator(_, value) {
    if (!value) {
      return Promise.resolve();
    }

    if (discount > 0 && discount <= 100) {
      return Promise.resolve();
    }

    return Promise.reject(
      <B2becTranslation value="voucher.validations.discountBetween1And100" />
    );
  },
});

export const validateDuplicateVoucherCode = (
  voucherCode,
  voucherList,
  isEditVoucher
) => ({
  validator(_, value) {
    if (!value) {
      return Promise.resolve();
    }

    if (isEditVoucher) {
      return Promise.resolve();
    }

    if (!voucherList) {
      return Promise.resolve();
    }

    if (!voucherList.some((voucher) => voucher.voucherCode === voucherCode)) {
      return Promise.resolve();
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(
      <B2becTranslation value="voucher.validations.voucherCodeNotDuplicate" />
    );
  },
});

export const validateVoucherCodePattern = (_, value) => {
  try {
    if (/^([^ ]).+([^ ])$/.test(value)) {
      return Promise.resolve();
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(
      <B2becTranslation value="voucher.validations.voucherCodeNotIncludeSpace" />
    );
  } catch (error) {
    return Promise.reject(error);
  }
};
