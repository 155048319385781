import { createSlice } from '@reduxjs/toolkit';
import { ACTION_STATUS } from 'libs/constants';

const initialState = {
  items: [],
  totalCount: 0,
  isFetchingMachinesList: false,
  actionStatus: ACTION_STATUS.DEFAULT,
};

const machineSlice = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    getMachinesList: (state) => {
      state.isFetchingMachinesList = true;
      state.actionStatus = ACTION_STATUS.DEFAULT;
    },
    getMachinesListSucceeded(state, { payload }) {
      state.isFetchingMachinesList = false;
      state.items = payload.items;
      state.totalCount = payload.totalCount;
      state.actionStatus = ACTION_STATUS.SUCCESS;
    },
    getMachinesListFailed(state) {
      state.isFetchingMachinesList = false;
      state.actionStatus = ACTION_STATUS.FAILED;
    },
  },
});

export const {
  getMachinesList,
  getMachinesListFailed,
  getMachinesListSucceeded,
} = machineSlice.actions;

export default machineSlice.reducer;
