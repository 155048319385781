import { ProductService } from 'services';

export const removeEmptyIssue = (issues) => {
  return issues.reduce((result, issue) => {
    if (!issue.issue) {
      return result;
    }

    const solutions = issue.solutions.filter((solution) => !!solution);

    if (!solutions.length) {
      return result;
    }

    return [...result, { issue: issue.issue, solutions }];
  }, []);
};

export const apiGetProductServiceFAQs = async (materialNumber) => {
  try {
    const response = await ProductService.getMachineServiceFAQs(materialNumber);
    return removeEmptyIssue(response?.data?.data);
  } catch {
    // TODO
  }
  return [];
};
