import clsx from 'clsx';
import B2becTranslation from 'components/B2becTranslation';
import React, { useEffect, useRef } from 'react';

const B2becExcludedMessage = () => {
  const ref = useRef();

  useEffect(() => {
    // TODO
    if (ref.current) {
      const yTop =
        ref.current.getBoundingClientRect().top + window.pageYOffset - 250;
      window.scrollTo({ top: yTop, behavior: 'smooth' });
    }
  }, []);

  return (
    <div ref={ref} className={clsx('card-item__excluded-massages-wrapper')}>
      <B2becTranslation
        value="service.selectMachine.excludedMachineMessages"
        isComponent
        htmlTags={[
          <div key="1" />,
          <a
            key="2"
            className="select-machine__excluded-machine__redirect-link"
            href="https://www.kaercher.com/at/services/home-garden/kaercher-service-reparatur.html"
          >
            placeholder
          </a>,
        ]}
      />
    </div>
  );
};

export default B2becExcludedMessage;
