import './styles.scss';

import { Button } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const CustomButton = ({
  children,
  isQuantity,
  isPriceBox,
  onClick,
  disabled,
  className,
  type,
  isInverted,
  buttonSize,
  isPriceEqualZero,
  ...rest
}) => {
  const classNames = clsx('custom-button', 'kaercher-button', className, {
    'custom-button--quantity': isQuantity,
    inverted: isInverted,
    'kaercher-button__size--small': buttonSize !== 'default',
  });

  return (
    <Button
      {...rest}
      type={type}
      className={classNames}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isQuantity: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isInverted: PropTypes.bool,
  onClick: PropTypes.func,
  buttonSize: PropTypes.string,
  isPriceEqualZero: PropTypes.bool,
  isPriceBox: PropTypes.bool,
};
CustomButton.defaultProps = {
  children: 'button_name',
  isQuantity: false,
  className: '',
  type: 'primary',
  disabled: false,
  isInverted: false,
  onClick: () => {},
  buttonSize: 'default',
  isPriceEqualZero: false,
  isPriceBox: false,
};

export default CustomButton;
