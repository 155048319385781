import './styles.scss';

import { ReactComponent as LeftIconArrow } from 'assets/icons/chevron-left.svg';
import PropTypes from 'prop-types';
import React from 'react';

import CustomButton from './index';

const B2becButtonIcon = ({ icon: IconComponent, children, ...props }) => {
  return (
    <CustomButton {...props}>
      <IconComponent className="custom-button__icon" />
      {children}
    </CustomButton>
  );
};

B2becButtonIcon.propTypes = {
  icon: PropTypes.elementType.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
B2becButtonIcon.defaultProps = {};

export const B2becButtonBack = (props) => {
  return <B2becButtonIcon icon={LeftIconArrow} {...props} />;
};

export default B2becButtonIcon;
