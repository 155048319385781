import { Col, Form, Input, Radio, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const types = {
  contactEmail: 'email',
  contactPhone: 'any',
};

const B2becContactVia = () => {
  const [typeContact, setTypeContact] = React.useState('contactEmail');
  const { t } = useTranslation();

  return (
    <Form.Item
      name={typeContact}
      rules={[
        { required: true },
        {
          type: types[typeContact],
        },
      ]}
      noStyle
      label={t(`enquiryModal.form.contactType.${typeContact}`)}
    >
      <Row align="middle">
        <Col span={12}>
          <Radio.Group
            value={typeContact}
            onChange={(e) => setTypeContact(e.target.value)}
          >
            <Radio value="contactEmail">
              {t('enquiryModal.form.contactType.contactEmail')}
            </Radio>
            <Radio value="contactPhone">
              {t('enquiryModal.form.contactType.contactPhone')}
            </Radio>
          </Radio.Group>
        </Col>
        <Col span={12}>
          <Input />
        </Col>
      </Row>
    </Form.Item>
  );
};

export default B2becContactVia;
