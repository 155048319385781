import './styles.scss';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { parseHtml } from '../../../libs/utils/common';

const ProductDescription = (props) => {
  const { t } = useTranslation();
  const { anchorId, longDescription } = props;

  return (
    <div className="product-description__wrapper pt-4 pb-4">
      <Row>
        <Col>
          <h3 id={anchorId}>{t('productDetails.productDescription')}</h3>
          <div className="product-description__text mt-4">
            {parseHtml(
              (longDescription &&
                longDescription.replace(/(<br( *\/)?>\s*)*$/gm, '')) ||
                ''
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

ProductDescription.propTypes = {
  anchorId: PropTypes.string,
  longDescription: PropTypes.string,
};
ProductDescription.defaultProps = {
  anchorId: '',
  longDescription: '',
};
export default ProductDescription;
