import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

function B2becTooltip(props) {
  const { title, content } = props;
  return (
    <Tooltip title={title} {...props}>
      <span>{content}</span>
    </Tooltip>
  );
}

B2becTooltip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  // className: PropTypes.string,
  // style: PropTypes.shape({}),
};

B2becTooltip.defaultProps = {
  title: '',
  content: '',
  // className: '',
  // style: {},
};

export default B2becTooltip;
