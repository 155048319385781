const SERVICE_V1 = 'v1';
const SERVICE_V2 = 'v2';
const ORDER_SERVICE = 'orderservice';
const PRODUCT_SERVICE = 'productservice';
const SEARCH_SERVICE = 'searchservice';
const USER_SERVICE = 'userservice';
const SERVICE_CASE_SERVICE = 'servicecaseservice';
const CONFIGURATION_SERVICE = 'configurationservice';

// Order Services
export const CART_ENDPOINT = `/${ORDER_SERVICE}/${SERVICE_V1}/order`;
export const VOUCHER_ENDPOINT = `/${ORDER_SERVICE}/${SERVICE_V1}/voucher`;
export const ITEM_ENDPOINT = `/${ORDER_SERVICE}/${SERVICE_V1}/item`;
export const MACHINE_ENDPOINT = `/${ORDER_SERVICE}/${SERVICE_V1}/machine`;
export const CART_REPORT_ENDPOINT = `/${ORDER_SERVICE}/${SERVICE_V1}/report/summary`;
export const SAP_LOG_ENDPOINT = `/${ORDER_SERVICE}/${SERVICE_V1}/saplog`;
export const ORDER_ENDPOINT_V2 = `/${ORDER_SERVICE}/${SERVICE_V2}/order`;

// Product Services
export const PRODUCT_ENDPOINT = `/${PRODUCT_SERVICE}/${SERVICE_V1}/product`;
export const TREE_ENDPOINT = `/${PRODUCT_SERVICE}/${SERVICE_V1}/tree`;
export const PORTFOLIO_ENDPOINT = `/${PRODUCT_SERVICE}/${SERVICE_V1}/productportfolio`;

// Search Service
export const SEARCH_ENDPOINT = `/${SEARCH_SERVICE}/${SERVICE_V1}/search`;

// User Services
export const USER_SERVICE_ENDPOINT = `/${USER_SERVICE}/${SERVICE_V1}`;
export const USER_ENDPOINT = `/${USER_SERVICE}/${SERVICE_V1}/user`;
export const EMPLOYEE_ENDPOINT = `/${USER_SERVICE}/${SERVICE_V1}/employee`;
export const TECHNICAL_ENDPOINT = `/${USER_SERVICE}/${SERVICE_V1}/technicaluser`;
export const CUSTOMER_ENDPOINT = `/${USER_SERVICE}/${SERVICE_V1}/customer`;
export const USER_ACTIVITY_ENDPOINT = `/${USER_SERVICE}/${SERVICE_V1}/activity`;
export const USER_INVITATION_ENDPOINT = `${USER_ENDPOINT}/invitation`;
export const USER_REGISTRATION_ENDPOINT = `${USER_ENDPOINT}/registration`;
export const REGISTRATION_REQUEST_ACCESS_ENDPOINT = `${USER_REGISTRATION_ENDPOINT}/request-access`;
export const USER_NEWSLETTER_ENDPOINT = `${USER_ENDPOINT}/newsletter/availability`;

// Service Case Service
export const SERVICE_CASE_ENDPOINT = `/${SERVICE_CASE_SERVICE}/${SERVICE_V1}/servicecase`;

// Configuration Service
export const CONFIGURATION_ENDPOINT = `/${CONFIGURATION_SERVICE}/${SERVICE_V1}/configuration`;

// Role Service
export const ROLE_ENDPOINT = `/${USER_SERVICE}/${SERVICE_V1}/role`;

// Wishlist Service
export const SHARED_WISHLIST_ENDPOINT = `/${PRODUCT_SERVICE}/${SERVICE_V1}/sharedwishlist`;
export const WISHLIST_ENDPOINT = `/${PRODUCT_SERVICE}/${SERVICE_V1}/wishlist`;

// Version 2
// Order Service
export const MACHINE_ENDPOINT_V2 = `/${ORDER_SERVICE}/${SERVICE_V2}/machine`;
export const ITEM_ENDPOINT_V2 = `/${ORDER_SERVICE}/${SERVICE_V2}/item`;
export const CART_ENDPOINT_V2 = `/${ORDER_SERVICE}/${SERVICE_V2}/order`;

// FAPI Endpoints
export const VERSION_1 = 'v1.0';

export const FAPI_BASE_ENDPOINT = `/${VERSION_1}`;

// Service Case Service
export const FAPI_SERVICE_CASE_ENDPOINT = `/${VERSION_1}/servicecase`;

// Search Service
export const FAPI_SEARCH_ENDPOINT = `/${VERSION_1}/search`;

// Configuration Service
export const FAPI_CONFIGURATION_ENDPOINT = `/${VERSION_1}/configuration`;

// Product Services
export const FAPI_PRODUCT_ENDPOINT = `/${VERSION_1}/product`;
export const FAPI_TREE_ENDPOINT = `/${VERSION_1}/tree`;

// User Services
export const FAPI_EMPLOYEE_ENDPOINT = `/${VERSION_1}/employee`;

// Technical Services
export const FAPI_TECHNICAL_ENDPOINT = `/${VERSION_1}/technicaluser`;

// Machine Service
export const FAPI_MACHINE_ENDPOINT = `/${VERSION_1}/machine`;

// Order Service
export const FAPI_ORDER_ENDPOINT = `/${VERSION_1}/order`;

// Cart Service
export const FAPI_CART_ENDPOINT = `/${VERSION_1}/cart`;

// Report Service
export const FAPI_REPORT_ENDPOINT = `/${VERSION_1}/report`;
