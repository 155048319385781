import React from 'react';
import { useTranslation } from 'react-i18next';

const WhatHappensNext = () => {
  const { t } = useTranslation();
  return (
    <span>{t('service.serviceOverview.whatHappensNext.description')}</span>
  );
};

export default WhatHappensNext;
