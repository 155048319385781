import environment from 'environments/environment.dev';

import HttpClient from './apiHttpClient';

const PUNCHOUT_ENDPOINT = '/v1.0/punchout/cacheddata';

class PunchoutService {
  constructor(baseUrl) {
    this.httpClient = new HttpClient(baseUrl);
  }

  getCachedCxmlData() {
    return this.httpClient.get(PUNCHOUT_ENDPOINT);
  }

  deleteCachedCxmlData() {
    return this.httpClient.delete(PUNCHOUT_ENDPOINT);
  }
}

export const punchoutService = new PunchoutService(environment.shopFAPIUrl);
