import { Checkbox, Form, Input, InputNumber } from 'antd';
import CustomDateTimePicker from 'components/B2becCustomDateTimePicker';
import B2becCustomSelect from 'components/B2becCustomSelect';
import {
  CONFIG_ADDRESS_SCHEME,
  CONFIG_DATA_TYPES,
  CONFIG_DATE_FORMAT,
  CONFIG_LOGOS,
  CONFIG_NUMBERS_SEPARATOR,
} from 'libs/constants/configs';
import { convertDateToISOString } from 'libs/utils/formatDate';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

const EditableConfigurationCell = ({
  isEditing,
  dataIndex,
  dataType,
  record,
  children,
  form,
  editingConfig,
  configs,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [checkboxValue, setCheckboxValue] = useState(record?.value);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const selectBoxOptions = (recordName) => {
    switch (recordName) {
      case 'Numbers_Separator':
        return CONFIG_NUMBERS_SEPARATOR;
      case 'Logo':
        return CONFIG_LOGOS;
      case 'date_format':
        return CONFIG_DATE_FORMAT;
      case 'Address_scheme':
        return CONFIG_ADDRESS_SCHEME;
      default:
        return [];
    }
  };

  const onChangeCheckbox = useCallback(
    (e) => {
      // set changed value before submitting
      form.setFieldsValue({
        ...record,
        value: e?.target?.checked,
      });
      setCheckboxValue(e?.target?.checked);
    },
    [form, record]
  );

  const onChangeDateTimePicker = useCallback(
    (value) => {
      form.setFieldsValue({
        ...record,
        value: convertDateToISOString(value),
      });
    },
    [form, record]
  );

  const handleChangeSelect = useCallback(
    (value) => {
      form.setFieldsValue({
        ...record,
        value,
      });
    },
    [form, record]
  );

  useEffect(() => {
    if (dataType === CONFIG_DATA_TYPES.BOOLEAN) {
      if (!editingConfig) {
        // reset initial checkbox value after user discard the changes without saving
        setCheckboxValue(record?.value);
      }
    }
  }, [editingConfig, dataType, record]);

  const inputNode = useMemo(() => {
    switch (dataType) {
      case CONFIG_DATA_TYPES.NUMBER:
        return <InputNumber />;

      case CONFIG_DATA_TYPES.BOOLEAN: {
        return (
          <Checkbox onChange={(e) => onChangeCheckbox(e)}>
            {checkboxValue.toString().toLowerCase() === 'true'
              ? t('configurationManagement.table.value.boolean.true')
              : t('configurationManagement.table.value.boolean.false')}
          </Checkbox>
        );
      }

      case CONFIG_DATA_TYPES.DATE: {
        return (
          <div>
            <CustomDateTimePicker
              onChangeDateTimePicker={(value) => onChangeDateTimePicker(value)}
              currentValue={record.value}
            />
          </div>
        );
      }

      case CONFIG_DATA_TYPES.STRING: {
        if (
          record &&
          record?.group === 'UI' &&
          [
            'Numbers_Separator',
            'Logo',
            'date_format',
            'Address_scheme',
          ].includes(record?.name)
        ) {
          return (
            <B2becCustomSelect
              onChange={handleChangeSelect}
              options={selectBoxOptions(record?.name)}
              getPopupContainer={() => document.body}
            />
          );
        }
      }
      // falls back intentionally
      // eslint-disable-next-line no-fallthrough
      default:
        // case dataTypes string and password
        return <Input />;
    }
  }, [
    record,
    dataType,
    checkboxValue,
    t,
    handleChangeSelect,
    onChangeCheckbox,
    onChangeDateTimePicker,
  ]);

  const initialValuePropName = useMemo(() => {
    if (dataType === CONFIG_DATA_TYPES.BOOLEAN) {
      return (record?.value || '').toString().toLowerCase() === 'true'
        ? 'checked'
        : '';
    }
    return 'value';
  }, [dataType, record]);

  return (
    <td {...restProps}>
      {isEditing ? (
        <Form.Item
          className="custom-form-item"
          style={{ margin: 0 }}
          name={dataIndex}
          valuePropName={initialValuePropName}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableConfigurationCell;

EditableConfigurationCell.propTypes = {
  isEditing: PropTypes.bool,
  dataIndex: PropTypes.string,
  dataType: PropTypes.string,
  record: PropTypes.shape({
    createdOn: PropTypes.string,
    dataType: PropTypes.string,
    description: PropTypes.string,
    group: PropTypes.string,
    isInherit: PropTypes.bool,
    lastChanged: PropTypes.string,
    name: PropTypes.string,
    qualifier: PropTypes.string,
    updatedBy: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.shape({}),
    ]),
  }),
  children: PropTypes.node,
  editingConfig: PropTypes.string,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
  }),
};

EditableConfigurationCell.defaultProps = {
  isEditing: false,
  dataIndex: '',
  dataType: '',
  record: {},
  children: <></>,
  form: {},
  editingConfig: null,
};
