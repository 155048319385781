import B2becCustomSelect from 'components/B2becCustomSelect';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isRoleOptionsLoading,
  roleOptionsSelector,
} from 'store/selectors/roleSelector';
import { getListParentRole } from 'store/slices/roleSlice';

const RoleSelect = (props) => {
  const { ignoreRoleId, ...selectProps } = props;
  const dispatch = useDispatch();
  const roleOptions = useSelector(roleOptionsSelector);
  const isLoading = useSelector(isRoleOptionsLoading);
  const numberOfRoles = roleOptions.length;
  const availableRoles = useMemo(() => {
    if (!ignoreRoleId) {
      return roleOptions;
    }
    return roleOptions.filter((option) => {
      return !option.value.includes(ignoreRoleId);
    });
  }, [ignoreRoleId, roleOptions]);

  useEffect(() => {
    if (isLoading === false && numberOfRoles === 0) {
      dispatch(getListParentRole());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return <B2becCustomSelect {...selectProps} options={availableRoles} />;
};

RoleSelect.propTypes = {
  ignoreRoleId: PropTypes.string,
};

RoleSelect.defaultProps = {
  ignoreRoleId: '',
};

export default React.memo(RoleSelect);
