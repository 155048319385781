import clsx from 'clsx';
import { NotificationManager } from 'components/Notification';
import { useAsync } from 'hooks';
import { ASYNC_STATUS } from 'libs/constants';
import CartItemTable from 'pages/Cart/CartItemTable';
import React, { useCallback, useState } from 'react';
import { adminFapiSupport } from 'services/adminFapi';
import mapCartData from 'store/sagas/apiDataMapping/cartMapping';

import styles from './Cart.module.scss';

const Cart = ({ userId, countryId }) => {
  const [status, setStatus] = useState(ASYNC_STATUS.IDLE);
  const [cartData, setCartData] = useState({
    items: [],
    currencyUnit: null,
    additionalCost: 0,
    cartSubTotal: 0,
    cartTotal: 0,
    cartTotalVAT: 0,
    shippingCosts: 0,
    voucherValue: {
      code: '',
    },
    checkoutSettings: {
      includingVAT: false,
      recyclingFee: false,
      shippingNotes: false,
      headLvlConditions: '',
      positionLvlConditions: '',
    },
    vat: 0,
  });

  const getCartData = useCallback(() => {
    const handleGetCartData = async (userIdVal, countryIdVal) => {
      try {
        setStatus(ASYNC_STATUS.PENDING);
        const { data } = await adminFapiSupport.getCartDataByUserId(
          userIdVal,
          countryIdVal
        );
        setStatus(setStatus(ASYNC_STATUS.SUCCESS));
        const mappedCartData = mapCartData(data);
        setCartData(mappedCartData);
      } catch (error) {
        setStatus(ASYNC_STATUS.ERROR);
        NotificationManager.error({
          message: 'notification.error.load',
        });
      }

      return [];
    };

    return handleGetCartData(userId, countryId);
  }, [userId, countryId]);

  useAsync(getCartData, true);
  const isLoading = status === ASYNC_STATUS.PENDING;

  return (
    <div className={clsx('mt-5', styles.wrapper)}>
      <CartItemTable
        isLoading={isLoading}
        cartDataValue={cartData}
        isInsideSupportArea
      />
    </div>
  );
};

export { Cart };
