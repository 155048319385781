import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import ReoderIcon from '../../assets/icons/reorder.svg';

export default function AddToCartBtn(props) {
  const { className, style, handleClick } = props;
  return (
    <button
      type="button"
      onClick={handleClick}
      style={style}
      className={`add-to-cart-btn__container ${className}`}
    >
      <img src={ReoderIcon} alt="Add to Cart" />
    </button>
  );
}

AddToCartBtn.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  handleClick: PropTypes.func,
};

AddToCartBtn.defaultProps = {
  className: '',
  style: {},
  handleClick: () => {},
};
