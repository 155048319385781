import PropTypes from 'prop-types';
import React from 'react';

import styles from './SmallLoadingIcon.module.scss';

function SmallLoadingIcon(props) {
  const { className, style } = props;
  return (
    <div className={`${className} ${styles['loading-wrapper']}`} style={style}>
      <div className={styles['dot--flashing']} />
    </div>
  );
}

export default SmallLoadingIcon;

SmallLoadingIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

SmallLoadingIcon.defaultProps = {
  className: null,
  style: {},
};
