import { NotificationManager } from 'components/Notification';
import { useAsync } from 'hooks';
import { adminFapiSupport } from 'services/adminFapi';

const handleSynchronizeOrdersSuccess = () => {
  NotificationManager.success({
    message: 'notification.success.synchronize',
  });
};

const handleSynchronizeOrdersFailed = (error) => {
  console.error(error);
  NotificationManager.error({
    message: 'notification.error.synchronize',
  });
};

export function useSynchronizeOrdersForGivenUserId() {
  const { execute: executeSynchronizeOrders, status } = useAsync(
    (userId) => adminFapiSupport.synchronizeOrdersByUserId(userId),
    false,
    handleSynchronizeOrdersSuccess,
    handleSynchronizeOrdersFailed
  );

  return { executeSynchronizeOrders, isSynchronizing: status === 'pending' };
}
