import { useBreadcrumbs, useDocumentTitle } from 'hooks';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  isRoleLoading,
  selectDuplicatedRoleDetails,
  selectIsDuplicatingRole,
  selectIsFetchingDuplicatedRoleDetails,
} from 'store/selectors/roleSelector';
import { getRightsList } from 'store/slices/rightSlice';
import {
  createRole,
  getRolesList,
  resetDuplicateState,
} from 'store/slices/roleSlice';

import RoleForm from '../RoleForm';

const getRealParentRole = (hierarchy, roleId) => {
  try {
    const hierarchies = hierarchy.split('/');
    return hierarchies.filter((id) => id !== roleId).join('/');
  } catch {
    // TODO
  }
  return hierarchy;
};

const RoleCreatePage = (props) => {
  const { title, breadcrumbs } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  useBreadcrumbs(breadcrumbs);
  useDocumentTitle(title);

  const isCreating = useSelector(isRoleLoading);
  const isDuplicatingRole = useSelector(selectIsDuplicatingRole);
  const isFetchingDuplicatedRoleDetails = useSelector(
    selectIsFetchingDuplicatedRoleDetails
  );
  const duplicatedRoleDetails = useSelector(selectDuplicatedRoleDetails);

  const formValue = useMemo(() => {
    if (duplicatedRoleDetails) {
      const {
        roleId,
        rightIds: rights,
        hierarchy: parentRole,
        ...role
      } = duplicatedRoleDetails;
      const hierarchy = getRealParentRole(parentRole, roleId);

      return {
        ...role,
        hierarchy,
        rights,
      };
    }
    return undefined;
  }, [duplicatedRoleDetails]);

  const canDuplicateRole =
    !isFetchingDuplicatedRoleDetails && formValue && isDuplicatingRole;

  const handleCreateRole = useCallback(
    ({ hierarchy: parentRole, ...values }) => {
      dispatch(createRole({ ...values, parentRole }));
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    history.push(linkGenerator('/roles-management'));
  }, [history]);

  useEffect(() => {
    dispatch(getRolesList());
    dispatch(getRightsList());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(resetDuplicateState());
    },
    [dispatch]
  );

  return (
    <RoleForm
      initialValues={canDuplicateRole ? formValue : { hierarchy: '' }}
      handleSubmit={handleCreateRole}
      handleCancel={handleCancel}
      disabled={isCreating}
    />
  );
};

RoleCreatePage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default React.memo(RoleCreatePage);
