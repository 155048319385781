import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const CONTACT_REASON = '/v1.0/contactreason';
const fapiClient = new HttpClient(environment.adminFAPIUrl);

const getContactReasonList = (pageSize, pageNumber) => {
  const queryParams = new URLSearchParams({ pageNumber, pageSize });
  return fapiClient.get(`${CONTACT_REASON}?${queryParams}`);
};

const addContactReason = (
  contactReasonId,
  contactReasonNumber,
  receiverAddress
) => {
  return fapiClient.post(CONTACT_REASON, {
    body: {
      contactReasonId,
      indexNumber: String(contactReasonNumber),
      receiverAddress,
    },
  });
};

const editContactReason = (
  contactReasonId,
  contactReasonNumber,
  receiverAddress
) => {
  return fapiClient.put(CONTACT_REASON, {
    body: {
      contactReasonId,
      indexNumber: String(contactReasonNumber),
      receiverAddress,
    },
  });
};

const deleteContactReason = (contactReasonId) => {
  const queryParams = new URLSearchParams({ contactReasonId });
  return fapiClient.delete(`${CONTACT_REASON}?${queryParams}`);
};

export const adminFapiContactReason = {
  getContactReasonList,
  addContactReason,
  editContactReason,
  deleteContactReason,
};
