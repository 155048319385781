import './styles.scss';

import { Layout } from 'antd';
import { useSearchContext } from 'contexts/search';
import { useCheckLocation, useDeviceDetect } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Sticky from 'react-stickynode';

import { STICKY_ZINDEX } from '../../libs/constants/header';
import {
  renderBannerHeight,
  renderBannerMarginHeight,
  renderBannerStickyTop,
  renderBreadcrumbMarginHeight,
  renderBreadcrumbStickyTop,
} from '../../libs/utils/breakpointsValue';
import { getBreadcrumbs } from '../../store/selectors/breadcrumbSelectors';
import B2becBreadcrumbs from '../B2becBreadcrumbs';

const { Content } = Layout;

function MainContent({ children }) {
  const breadcrumbs = useSelector(getBreadcrumbs);

  const { isIpadLandscape, isMobile } = useDeviceDetect();
  const { isSearchFocused } = useSearchContext();
  const { isInMyWorkspace } = useCheckLocation();

  const isSearchFocusOnMobile =
    isSearchFocused && (isMobile || isIpadLandscape);

  return (
    <div className="main-content__wrapper">
      <div className="main-content__header">
        <Sticky
          innerZ={STICKY_ZINDEX.YELLOW_BANNER}
          top={renderBannerStickyTop(isInMyWorkspace, isIpadLandscape)}
        >
          {(stickyStatus) => (
            <div
              className="main-content__wrapper__banner"
              style={{
                height: renderBannerHeight(
                  isInMyWorkspace,
                  breadcrumbs,
                  stickyStatus.status
                ),
                marginTop:
                  isSearchFocusOnMobile &&
                  renderBannerMarginHeight(
                    isInMyWorkspace,
                    stickyStatus.status
                  ),
              }}
            />
          )}
        </Sticky>
      </div>

      <Content>
        <Sticky
          innerZ={STICKY_ZINDEX.BREADCRUMB}
          top={renderBreadcrumbStickyTop(isSearchFocusOnMobile)}
        >
          {(stickyStatus) => (
            <div
              className="main-content__breadcrumbs__wrapper"
              style={{
                marginTop:
                  isSearchFocusOnMobile &&
                  renderBreadcrumbMarginHeight(stickyStatus.status),
              }}
            >
              <B2becBreadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          )}
        </Sticky>
        {children}
      </Content>
    </div>
  );
}

MainContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

MainContent.defaultProps = {
  children: () => <></>,
};

export default MainContent;
