import { Col, Form, Input, Row } from 'antd';
import B2becAddressForm from 'components/B2becAddressForm';
import B2becTranslation from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import { isFormDirty } from 'libs/utils/validatorUtils';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import {
  getCustomerAddress,
  getIsLoading,
} from 'store/selectors/customerSelector';

const { useForm } = Form;

const { TextArea } = Input;

const validateMessages = {
  required: (label) => {
    return (
      <B2becTranslation
        value="form.validate.required"
        isComponent={false}
        variables={{ label }}
      />
    );
  },
};

const InvoiceChangeForm = (props) => {
  const { disabled, onFinish, isSubmiting } = props;
  const [form] = useForm();
  const { t } = useTranslation();

  const customerAddress = useSelector(getCustomerAddress);
  const isCustomerLoading = useSelector(getIsLoading);

  const handleRequestInvoiceChange = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(false);

      onFinish(values);
    } catch {
      // TODO handle exception
    }
  };

  const renderSkeleton = () => {
    return (
      <div className="b2bec-change-invoice-skeleton">
        <Form.Item
          label={t('form.label.companyName')}
          className="custom-form-item"
        >
          <Skeleton style={{ height: 46 }} />
        </Form.Item>

        <Form.Item
          label={t('form.label.extraText')}
          className="custom-form-item"
        >
          <Skeleton style={{ height: 66 }} />
        </Form.Item>

        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              label={t('form.label.street')}
              className="custom-form-item"
            >
              <Skeleton style={{ height: 46 }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('form.label.houseNumber')}
              className="custom-form-item"
            >
              <Skeleton style={{ height: 46 }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label={t('form.label.zip')} className="custom-form-item">
              <Skeleton style={{ height: 46 }} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label={t('form.label.city')}
              className="custom-form-item"
            >
              <Skeleton style={{ height: 46 }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t('form.label.country')}
          className="custom-form-item"
          style={{ width: 320 }}
        >
          <Skeleton style={{ height: 46 }} />
        </Form.Item>

        <Form.Item
          label={t('form.label.annotation')}
          className="custom-form-item"
        >
          <Skeleton style={{ height: 166 }} />
        </Form.Item>
      </div>
    );
  };

  const renderForm = useCallback(() => {
    return (
      <>
        <B2becAddressForm />
        <Form.Item
          name="annotation"
          label={t('form.label.annotation')}
          className="custom-form-item"
          disabled={disabled}
        >
          <TextArea rows={7} style={{ resize: 'none' }} disabled={disabled} />
        </Form.Item>

        <Form.Item name="partnerNumber" noStyle>
          <Input type="hidden" />
        </Form.Item>
      </>
    );
  }, [disabled, t]);

  return (
    <div className="b2bec-change-invoice__form">
      <Form
        layout="vertical"
        form={form}
        validateMessages={validateMessages}
        requiredMark={false}
        initialValues={customerAddress}
        key={customerAddress?.partnerNumber}
      >
        {isCustomerLoading ? renderSkeleton() : renderForm()}
        <div className="b2bec-change-invoice__form__footer">
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const values = getFieldsValue(false);
              const isDirty = isFormDirty(customerAddress, values);
              const isDisabled =
                !isDirty || isCustomerLoading || disabled || isSubmiting;

              return (
                <CustomButton
                  onClick={handleRequestInvoiceChange}
                  loading={isSubmiting}
                  disabled={isDisabled}
                >
                  {t('changeInvoiceAddress.button.requestChanges')}
                </CustomButton>
              );
            }}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

InvoiceChangeForm.defaultProps = {
  disabled: false,
  isSubmiting: false,
};

InvoiceChangeForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  isSubmiting: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default React.memo(InvoiceChangeForm);
