import { Col, Row } from 'antd';
import CustomButton from 'components/CustomButton';
import NotFound from 'components/NotFound';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fapiEmployeeService } from 'services/adminFapi';
import {
  getEmployeeDetail,
  selectIsEditEmployeeFormVisible,
  showEditEmployeeForm,
} from 'store/slices/employeeSlice';
import { getEmployeeAvailableLanguages } from 'store/slices/usersManagementSlice';

import PermissionWrapper from '../../../../HOCs/permissionWrapper';
import { MODULE_USER_MANAGEMENT_RIGHTS } from '../../../../libs/constants/modulerights';
import EditEmployeeForm from '../EditEmployeeForm';
import EmployeeInformation from '../EmployeeInformation';
import DeleteUserSection from '../UserDetailsPage/components/DeleteUserSection';
import styles from './EmployeeDetailsPage.module.scss';
import { useEmployeeDetails } from './useEmployeeDetails';

const EmployeeDetailsPage = (props) => {
  const { breadcrumbs, title } = props;
  const [setBreadcrumbsData] = useBreadcrumbs(breadcrumbs);
  const { setTitleData, setIsTitleTranslated } = useDocumentTitle(title);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { isLoading, isFailed, employeeData } = useEmployeeDetails(userId);
  const isEditEmployeeFormVisible = useSelector(
    selectIsEditEmployeeFormVisible
  );

  const handleShowEditEmployeeForm = () => {
    dispatch(showEditEmployeeForm());
  };

  useEffect(() => {
    dispatch(getEmployeeDetail(userId));
    dispatch(getEmployeeAvailableLanguages(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    setIsTitleTranslated(true);
    if (employeeData) {
      setTitleData([employeeData.displayName]);
      setBreadcrumbsData({
        titleData: [employeeData.displayName],
        pathData: ['employee', employeeData.userId],
      });
    }
  }, [
    employeeData,
    setBreadcrumbsData,
    setIsTitleTranslated,
    setTitleData,
    t,
    userId,
  ]);

  if (isFailed) {
    return <NotFound />;
  }

  return (
    <div className={styles.detailPage}>
      <div className={styles.header}>
        <div className={styles.container}>
          <h1>{employeeData?.displayName || 'N/A'}</h1>
          <div className={styles.userDataHeader}>
            {t(
              'adminWorkspace.userManagement.userDetails.subheading.emailAddress'
            )}
          </div>
          <div className={styles.userDataContent}>
            {isLoading ? (
              <Skeleton width={200} />
            ) : (
              employeeData?.email || 'N/A'
            )}
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <Row className={styles.container}>
          <Col span={16} className={styles.employeeInformation}>
            {isEditEmployeeFormVisible ? (
              <EditEmployeeForm initialValues={employeeData} />
            ) : (
              <>
                <EmployeeInformation {...employeeData} isLoading={isLoading} />
                <PermissionWrapper
                  permission={MODULE_USER_MANAGEMENT_RIGHTS.UPDATE_USER}
                >
                  <CustomButton
                    disabled={isLoading}
                    onClick={handleShowEditEmployeeForm}
                  >
                    {t(
                      'adminWorkspace.userManagement.userDetails.userInformationSection.button'
                    )}
                  </CustomButton>
                </PermissionWrapper>
              </>
            )}
          </Col>
          <PermissionWrapper
            permission={MODULE_USER_MANAGEMENT_RIGHTS.DELETE_USER}
          >
            <Col span={8} className={styles.userStatus}>
              <DeleteUserSection
                userId={userId}
                handleDeleteUser={fapiEmployeeService.deleteEmployeeAccount}
                isLoading={isLoading}
              />
            </Col>
          </PermissionWrapper>
        </Row>
      </div>
    </div>
  );
};

EmployeeDetailsPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default React.memo(EmployeeDetailsPage);
