// REFACTOR LATTER

export const getMainProductImage = (imgArr) => {
  try {
    if (imgArr && imgArr.length > 0) {
      const mainImage = imgArr.find(
        (imgs) => imgs.type === 'main' || !!imgs.type
      );
      if (mainImage.urls && mainImage.urls.length > 0) {
        const selectedImage = mainImage.urls.filter(
          (img) => img.type === 'medium' || !!img.type
        );
        return selectedImage[0].url;
      }
    }
  } catch {
    // TODO
  }
  return '';
};
