import './styles.scss';

import { Input } from 'antd';
import FormatPrice from 'components/FormatPrice';
import usePermission from 'hooks/usePermission';
import { MODULE_CART_CHECKOUT_RIGHTS } from 'libs/constants/modulerights';
import { formatQuantity } from 'libs/utils/handleNumber';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { changeCartProductReference } from 'store/slices/cartSlice';

function CartItem(props) {
  const {
    isLoading,
    img,
    name,
    materialNumber,
    price,
    quantity,
    total,
    currencyUnit,
    productReference: initialProductReference,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [productReference, setProductReference] = useState(
    initialProductReference
  );

  const { hasPermission: hasViewIndividualPricePermision } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIUAL_PRICE
  );

  const timerRef = useRef();
  const handleChangeProductReference = (value) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (price === 0) {
      return;
    }

    timerRef.current = setTimeout(() => {
      dispatch(
        changeCartProductReference({
          materialNumber,
          productReference: value,
          quantity,
        })
      );
    }, 300);
  };

  const renderImage = () =>
    isLoading ? (
      <div className="order-overview__middle-section__cart-item__img--loading">
        <Skeleton />
      </div>
    ) : (
      <img
        className="order-overview__middle-section__cart-item__img"
        src={img.src}
        alt={img.alt}
      />
    );

  const renderQuantity = () =>
    isLoading ? (
      <div className="order-overview__middle-section__cart-item__quantity order-overview__middle-section__cart-item__quantity--loading">
        <Skeleton />
      </div>
    ) : (
      <div className="order-overview__middle-section__cart-item__quantity">
        {formatQuantity(quantity, 3)}
      </div>
    );

  const renderName = () => (
    <h3 className="order-overview__middle-section__cart-item__name">
      {isLoading ? <Skeleton /> : name}
    </h3>
  );

  const renderMaterialNumber = () => (
    <div className="order-overview__middle-section__cart-item__material-number">
      {isLoading ? (
        <Skeleton />
      ) : (
        `${t(
          'checkout.step3.middleSection.mobile.cartItem.materialNumber'
        )} ${materialNumber}`
      )}
    </div>
  );

  const renderPrice = () => (
    <div>
      <div className="order-overview__middle-section__cart-item__price__unit-title">
        {isLoading ? (
          <Skeleton />
        ) : (
          t('checkout.step3.middleSection.mobile.cartItem.price')
        )}
      </div>
      <div className="order-overview__middle-section__cart-item__price__unit">
        {isLoading ? (
          <Skeleton width={50} height={15} />
        ) : (
          <FormatPrice price={price} currencyUnit={currencyUnit} />
        )}
      </div>
    </div>
  );

  const renderTotal = () => (
    <div className="order-overview__middle-section__cart-item__price__total__wrapper">
      <div className="order-overview__middle-section__cart-item__price__total-title">
        {isLoading ? (
          <Skeleton />
        ) : (
          t('checkout.step3.middleSection.mobile.cartItem.total')
        )}
      </div>
      <div className="order-overview__middle-section__cart-item__price__total">
        {isLoading ? (
          <Skeleton width={50} height={15} />
        ) : (
          <FormatPrice price={total} currencyUnit={currencyUnit} />
        )}
      </div>
    </div>
  );

  const renderProductReference = () => (
    <div>
      <div className="order-overview__middle-section__cart-item__product-reference__title">
        {isLoading ? (
          <Skeleton width={120} />
        ) : (
          t(
            'checkout.step3.middleSection.mobile.cartItem.productReference.title'
          )
        )}
      </div>
      {isLoading ? (
        <div className="order-overview__middle-section__cart-item__product-reference__input">
          <Skeleton height={50} />
        </div>
      ) : (
        <Input.TextArea
          className="custom-input order-overview__middle-section__cart-item__product-reference__input"
          name="productReference"
          type="text"
          placeholder={t(
            'checkout.step3.middleSection.mobile.cartItem.productReference.description'
          )}
          rows={2}
          value={productReference}
          disabled={price === 0}
          onChange={(e) => {
            if (productReference !== e.target.value) {
              setProductReference(e.target.value);
              handleChangeProductReference(e.target.value);
            }
          }}
          onFocus={(e) => e.target.select()}
        />
      )}
    </div>
  );

  return (
    <div className="order-overview__middle-section__cart-item__wrapper">
      <div className="order-overview__middle-section__cart-item__upper-section">
        <div className="order-overview__middle-section__cart-item__left-part">
          {renderImage()}
          {renderQuantity()}
        </div>
        <div className="order-overview__middle-section__cart-item__right-part">
          <div>
            {renderName()}
            {renderMaterialNumber()}
          </div>
          {hasViewIndividualPricePermision && (
            <div className="order-overview__middle-section__cart-item__price-section">
              {renderPrice()}
              {renderTotal()}
            </div>
          )}
        </div>
      </div>
      <div className="order-overview__middle-section__cart-item__lower-section">
        {renderProductReference()}
      </div>
    </div>
  );
}

CartItem.propTypes = {
  isLoading: PropTypes.bool,
  img: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  materialNumber: PropTypes.string.isRequired,
  price: PropTypes.number,
  quantity: PropTypes.number,
  total: PropTypes.number,
  currencyUnit: PropTypes.string,
  productReference: PropTypes.string,
};

CartItem.defaultProps = {
  isLoading: false,
  price: 0,
  quantity: 1,
  total: 0,
  currencyUnit: '',
  productReference: null,
};

export default CartItem;
