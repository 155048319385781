import './styles.scss';

import Translator from 'components/B2becTranslation';
import { notificationComponent } from 'components/Notification';
import useAsync from 'hooks/useAsync';
import { ASYNC_STATUS, STATUS_CODE } from 'libs/constants';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { adminFapiUserManagement } from 'services/adminFapi';

import ActionButtonWithModal from '../ActionsModalComponent';

function RevokeInvitationSection(props) {
  const { isLoading, userId, isReactivation } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const revokeInvitation = useCallback(
    (cancelToken) => (invitationId) =>
      adminFapiUserManagement.revokeInvitation(
        invitationId,
        isReactivation,
        cancelToken
      ),
    [isReactivation]
  );

  const { execute, status } = useAsync(
    revokeInvitation,
    false,
    undefined,
    undefined,
    true
  );

  const invitationStatus = useMemo(
    () => (isReactivation ? 'reactivateInvitation' : 'revokeInvitation'),
    [isReactivation]
  );

  const onRevokeInvitation = useCallback(() => {
    if (userId) {
      execute(userId).then(({ response, error }) => {
        if (response?.status === STATUS_CODE.SUCCESS) {
          notificationComponent({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: (
              <Translator value={`notification.success.${invitationStatus}`} />
            ),
          });

          history.push(linkGenerator('/user-management'));
        }
        if (error) {
          notificationComponent({
            type: NOTIFICATION_TYPE.ERROR,
            message: (
              <Translator value={`notification.error.${invitationStatus}`} />
            ),
          });
        }
      });
    }
  }, [userId, execute, history, invitationStatus]);

  const translateTextByKey = useCallback(
    (key) =>
      t(
        `adminWorkspace.userManagement.userDetails.${invitationStatus}Section.${key}`
      ),
    [t, invitationStatus]
  );

  return (
    <ActionButtonWithModal
      className="user-details__revoke-invitation__wrapper"
      isLoading={status === ASYNC_STATUS.PENDING}
      isDisabled={isLoading}
      title={translateTextByKey('title')}
      description={translateTextByKey('description')}
      buttonName={translateTextByKey('button')}
      modalTitle={t(`userManagement.${invitationStatus}.title`)}
      modalContent={t(`userManagement.${invitationStatus}.modalContent`)}
      onConfirmAction={onRevokeInvitation}
    />
  );
}

RevokeInvitationSection.propTypes = {
  isLoading: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  isReactivation: PropTypes.bool,
};

RevokeInvitationSection.defaultProps = {
  isLoading: false,
  isReactivation: false,
};

export default React.memo(RevokeInvitationSection);
