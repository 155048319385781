// products
export const selectProductLoading = (state) =>
  state?.search?.data?.machine?.isLoading;
export const selectProductList = (state) => state?.search?.data?.machine?.list;
export const selectProductAllAmount = (state) =>
  state?.search?.data?.machine?.allAmount;

// accessories
export const selectAccessoryLoading = (state) =>
  state?.search?.data?.accessory?.isLoading;
export const selectAccessoryList = (state) =>
  state?.search?.data?.accessory?.list;
export const selectAccessoryAllAmount = (state) =>
  state?.search?.data?.accessory?.allAmount;

// detergents
export const selectDetergentLoading = (state) =>
  state?.search?.data?.detergent?.isLoading;
export const selectDetergentList = (state) =>
  state?.search?.data?.detergent?.list;
export const selectDetergentAllAmount = (state) =>
  state?.search?.data?.detergent?.allAmount;

// all results
export const selectIsAllResultLoading = (state) =>
  state?.search?.allResult?.isLoading;
export const selectAllResultList = (state) => state?.search?.allResult?.list;
export const selectAllResultAmount = (state) =>
  state?.search?.allResult?.allAmount;

export const selectAllCategoryNames = (state) =>
  state?.search?.overviewCategories;

export const selectIsSuggestionsFetching = (state) =>
  state.search.isFetchingSuggesstions;
export const selectSuggestions = (state) => state.search.suggestions;

export const selectSearchTerm = (state) => state.search.searchTerm;
