import './styles.scss';

import { Col, Row } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { USER_STATUS } from '../../../../../../libs/constants/user';

function SubheadingSection(props) {
  const { isLoading, customerNumber, userStatus, email } = props;

  const { t } = useTranslation();

  const status = {
    code: userStatus?.toLowerCase(),
  };
  switch (status.code) {
    case USER_STATUS.UNAUTHORIZED:
      status.name = t(
        'adminWorkspace.userManagement.userDetails.userStatus.unauthorized'
      );
      break;
    case USER_STATUS.IN_PROGRESS:
      status.name = t(
        'adminWorkspace.userManagement.userDetails.userStatus.inProgress'
      );
      break;
    case USER_STATUS.AUTHORIZED:
      status.name = t(
        'adminWorkspace.userManagement.userDetails.userStatus.authorized'
      );
      break;
    default:
      status.code = USER_STATUS.UNAUTHORIZED;
      status.name = t(
        'adminWorkspace.userManagement.userDetails.userStatus.unauthorized'
      );
  }
  return (
    <Row
      className="user-details__subheading__wrapper"
      gutter={[
        { xs: 0, sm: 0 },
        { xs: 16, sm: 16 },
      ]}
    >
      <Col xs={24} sm={24} md={8}>
        <div className="user-details__subheading__title">
          {t(
            'adminWorkspace.userManagement.userDetails.subheading.customerNumber'
          )}
        </div>
        <div className="user-details__subheading__subtitle">
          {isLoading ? <Skeleton width={100} /> : customerNumber || 'N/A'}
        </div>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <div className="user-details__subheading__title">
          {t('adminWorkspace.userManagement.userDetails.subheading.userStatus')}
        </div>
        <span
          className={clsx('user-details__subheading__user-status', {
            'user-details__subheading__user-status--loading': isLoading,
            'user-details__subheading__user-status--unauthorized':
              status.code === USER_STATUS.UNAUTHORIZED,
            'user-details__subheading__user-status--in-progress':
              status.code === USER_STATUS.IN_PROGRESS,
            'user-details__subheading__user-status--authorized':
              status.code === USER_STATUS.AUTHORIZED,
          })}
        >
          {isLoading ? <Skeleton /> : status.name || 'N/A'}
        </span>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <div className="user-details__subheading__title">
          {t(
            'adminWorkspace.userManagement.userDetails.subheading.emailAddress'
          )}
        </div>
        <div className="user-details__subheading__subtitle">
          {isLoading ? <Skeleton width={100} /> : email || 'N/A'}
        </div>
      </Col>
    </Row>
  );
}

SubheadingSection.propTypes = {
  isLoading: PropTypes.bool,
  customerNumber: PropTypes.string,
  userStatus: PropTypes.string,
  email: PropTypes.string,
};

SubheadingSection.defaultProps = {
  isLoading: false,
  customerNumber: 'N/A',
  userStatus: USER_STATUS.UNAUTHORIZED,
  email: 'N/A',
};

export default SubheadingSection;
