import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedProductSelector } from 'store/selectors/portfolioSelector';
import {
  deleteProduct,
  setSelectedProduct,
  updateProduct,
} from 'store/slices/portfolioSlice';

const useProductConfig = () => {
  const selectedProduct = useSelector(selectedProductSelector);
  const updateStatus = useSelector((state) => state.portfolio.updateStatus);
  const dispatch = useDispatch();
  const productPortfolioId = selectedProduct?.productPortfolioId;
  const isUpdating = updateStatus === 'updating';

  const handleSetToDefault = useCallback(() => {
    dispatch(deleteProduct({ productPortfolioId }));
  }, [dispatch, productPortfolioId]);

  const handleUpdateConfig = useCallback(
    (value) => {
      const {
        displayAddToCart,
        displayDeliveryDate,
        displayInNavigation,
        displayListPrice,
        disableBProgramCheckbox,
      } = value;
      dispatch(
        updateProduct({
          productPortfolioId,
          displayAddToCart,
          displayDeliveryDate,
          displayInNavigation,
          displayListPrice,
          disableBProgramCheckbox,
        })
      );
    },
    [dispatch, productPortfolioId]
  );

  const handleSelectProduct = useCallback(
    (value) => {
      dispatch(setSelectedProduct(value));
    },
    [dispatch]
  );

  return {
    isUpdating,
    selectedProduct,
    handleSetToDefault,
    handleUpdateConfig,
    handleSelectProduct,
  };
};

export default useProductConfig;
