import B2becInfoLayout from 'components/B2becInfoLayout';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SparePartsFinderInput from './components/SparePartsFinderInput';
import styles from './SparePartsFinder.module.scss';

const SparePartsFinder = (props) => {
  const { title, breadcrumbs } = props;
  const { t } = useTranslation();

  useBreadcrumbs(breadcrumbs);
  useDocumentTitle(title);

  return (
    <B2becInfoLayout className={styles.SparePartsFinder}>
      <B2becInfoLayout.Title title={t('pageTitle.sparePartsFinder')} />
      <B2becInfoLayout.Content>
        <div className={styles.description}>
          <h2>{t('sparePartsFinder.onlineShopSpareParts')}</h2>
          {t('sparePartsFinder.onlineShopSparePartsDescription')}
        </div>
        <div className={styles.description}>
          <h2>{t('sparePartsFinder.findProduct')}</h2>
          {t('sparePartsFinder.findProductDescription')}
        </div>
        <SparePartsFinderInput />
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
};

SparePartsFinder.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SparePartsFinder;
