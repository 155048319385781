import B2becCustomSelect from 'components/B2becCustomSelect';
import { USER_SALUTATION_ARRAY } from 'libs/constants/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const B2bSalutation = (props) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return USER_SALUTATION_ARRAY.map((item) => ({
      label: t(`form.label.${item.label}`),
      value: item.value,
    }));
  }, [t]);
  return <B2becCustomSelect options={options} {...props} />;
};

export default B2bSalutation;
