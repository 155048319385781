import { DeliveryAddress } from 'components/DisplayAddress';
import { useCheckoutContext } from 'contexts/checkout-context';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomerAddress = () => {
  const { t } = useTranslation();
  const { customerInfo } = useCheckoutContext();
  const { partnerNumber } = customerInfo;

  return (
    <div className="checkout-step1-section-container__text mt-4">
      <p>
        {t('checkout.step1.customerNumber')}: {partnerNumber}
      </p>
      <DeliveryAddress data={customerInfo} />
    </div>
  );
};

export default CustomerAddress;
