import environment from 'environments/environment.dev';
import HttpClient from 'services/apiHttpClient';

const GET_VOUCHER_LIST = '/v1.0/voucher';
const CREATE_VOUCHER = '/v1.0/voucher';
const UPDATE_VOUCHER = '/v1.0/voucher/{voucherId}/update';
const DELETE_VOUCHER = '/v1.0/voucher/{voucherId}/delete';

const fapiClient = new HttpClient(environment.adminFAPIUrl);

const getVoucherList = () => fapiClient.get(GET_VOUCHER_LIST);
const createVoucherInfo = (voucherData) =>
  fapiClient.post(CREATE_VOUCHER, { body: voucherData });
const updateVoucherInfo = (voucherId, voucherData) =>
  fapiClient.put(UPDATE_VOUCHER.replace('{voucherId}', voucherId), {
    body: voucherData,
  });
const deleteVoucherInfo = (voucherId) =>
  fapiClient.delete(DELETE_VOUCHER.replace('{voucherId}', voucherId));

export const adminFapiVoucher = {
  getVoucherList,
  createVoucherInfo,
  updateVoucherInfo,
  deleteVoucherInfo,
};
