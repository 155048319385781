import './styles.scss';

import { Col, Form, Radio, Row, Input } from 'antd';
import FormatPrice from 'components/FormatPrice';
import { useCheckoutContext } from 'contexts/checkout-context';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DeliveryDate from '../../../../components/B2bDeliveryDate';

export default function ShippingOptions() {
  const { t } = useTranslation();
  const { cartData } = useCheckoutContext();

  const dataSrc = cartData
    ? [
        {
          title: t('common.shippingOptions.standardDelivery'),
          value: cartData.shippingCosts,
          desc: 'Get it by Friday, Oct 30 , November 5',
          currencyUnit: cartData?.currencyUnit,
        },
      ]
    : null;

  const deliveryDatesByDateData = cartData
    ? cartData.deliveryDatesByDateData
    : [];

  const deliveryData = Object.keys(deliveryDatesByDateData).map(
    (deliveryDate) => {
      const numberOfItems = deliveryDatesByDateData[deliveryDate]
        .map(({ numberOfItems: quantity }) => quantity)
        .reduce((sum, quantity) => sum + quantity, 0);

      return {
        deliveryDate,
        numberOfItems,
      };
    },
    []
  );

  const renderContent = (data) => {
    return (
      <Radio.Group
        className="shipping-options--wrapper custom-form-item"
        style={{
          marginBottom: cartData?.checkoutSettings?.shippingNotes ? 0 : '30px',
        }}
      >
        {data.map((item) => (
          <Radio key={item.value} value={item.value}>
            <div className="shipping-options--container">
              <div className="shipping-options--container--left">
                <strong className="shipping-options__title">
                  {item.title}
                </strong>
                <div className="delivery-date">
                  <DeliveryDate deliveryData={deliveryData} />
                </div>
              </div>
              <div className="shipping-options--container--right">
                <FormatPrice
                  price={item?.value}
                  currencyUnit={item?.currencyUnit}
                  wrappedBy="strong"
                />
              </div>
            </div>
          </Radio>
        ))}
      </Radio.Group>
    );
  };

  return (
    <Row className="payment-method__wrapper checkout-step1-section__wrapper">
      <Col xl={5} lg={5} md={4} sm={24} xs={24}>
        <h3 className="shipping-options__heading">
          {t('checkout.step2.shippingOptions')}
        </h3>
      </Col>
      {dataSrc && (
        <Col xl={13} lg={13} md={20} sm={24} xs={24}>
          <Form.Item
            noStyle
            name="shippingMethod"
            initialValue={dataSrc[0].title}
          >
            <Input type="hidden"></Input>
          </Form.Item>
          <Form.Item name="shippingOptions" initialValue={dataSrc[0].value}>
            {renderContent(dataSrc)}
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}
