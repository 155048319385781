import environment from 'environments/environment.dev';
import { FAPI_MACHINE_ENDPOINT } from 'libs/constants/b2bApiEndpoints';

import HttpClient from './apiHttpClient';

class MachineService {
  constructor(baseUrl) {
    this.httpClient = new HttpClient(baseUrl);
  }

  getMachinesList({ pageNumber, pageSize, sortOrder }) {
    const queryParams = new URLSearchParams({
      pageNumber,
      pageSize,
      sortOrder,
    });
    const url = `${FAPI_MACHINE_ENDPOINT}/getall?${queryParams.toString()}`;

    return this.httpClient.get(url);
  }
}

export const machineService = new MachineService(environment.shopFAPIUrl);
