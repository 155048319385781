import React from 'react';

export const useGetComparisonFormLocal = () => {
  const comparisonList = localStorage.getItem('productComparison');

  const listProduct = React.useMemo(() => {
    try {
      return JSON.parse(comparisonList);
    } catch {
      return [];
    }
  }, [comparisonList]);

  const listProductIds = React.useMemo(() => {
    return (listProduct || []).map(({ materialNumber }) => materialNumber);
  }, [listProduct]);

  const deleteProductIds = React.useCallback(
    (productId) => {
      const newList = listProduct.filter(
        ({ materialNumber }) => materialNumber !== productId
      );

      localStorage.setItem('productComparison', JSON.stringify(newList));
    },
    [listProduct]
  );

  const getListPriceFromLocal = React.useCallback(
    (materialNumber) => {
      if (Array.isArray(listProduct)) {
        return (
          listProduct.find(
            (product) => product.materialNumber === materialNumber
          )?.listPrice || 0
        );
      }
      return 0;
    },
    [listProduct]
  );

  return {
    listProductIds,
    deleteProductIds,
    getListPriceFromLocal,
  };
};
