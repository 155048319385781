import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getSelectedMachineData,
  selectSubmitServiceData,
  selectSubmitServiceResponse,
} from 'store/selectors/serviceCaseSelector';
import {
  clearServiceCaseConfirmData,
  setSelectedMachineInfo,
} from 'store/slices/serviceCaseSlice';

import RequestServiceConfirmation from './RequestServiceConfirmation';

const RequestServiceConfirmationPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { title, breadcrumbs, pageId } = props;
  useBreadcrumbs(breadcrumbs);
  useDocumentTitle(title);

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'request service confirmation',
    pageType: PAGE_TYPES.CONFIRMATION,
  });

  const { submitServiceResponse, submitServiceData, selectedMachineData } =
    useSelector((state) => ({
      submitServiceResponse: selectSubmitServiceResponse(state),
      submitServiceData: selectSubmitServiceData(state),
      selectedMachineData: getSelectedMachineData(state),
    }));

  const { serviceEntry } = selectedMachineData;

  useEffect(() => {
    if (submitServiceResponse && submitServiceData) {
      if (
        Object.keys(submitServiceResponse).length === 0 ||
        Object.keys(submitServiceData).length === 0
      ) {
        // these properties will reset the selected machine data and store the current service entry
        dispatch(
          setSelectedMachineInfo({
            serviceEntry,
            isChangedService: true,
          })
        );

        history.replace(linkGenerator('/request-services'));
      }
    }
    return () => {
      dispatch(clearServiceCaseConfirmData());
    };
  }, [
    submitServiceResponse,
    submitServiceData,
    serviceEntry,
    history,
    dispatch,
  ]);

  return (
    <div className="request-service-confirmation">
      <RequestServiceConfirmation
        data={submitServiceData}
        responseData={submitServiceResponse}
      />
    </div>
  );
};

RequestServiceConfirmationPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default RequestServiceConfirmationPage;
