import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fapiRoleService } from 'services/adminFapi';

import Translator from '../../../../components/B2becTranslation';
import CustomButton from '../../../../components/CustomButton';
import { NotificationManager } from '../../../../components/Notification';
import useAsync from '../../../../hooks/useAsync';
import useModal from '../../../../hooks/useModal';
import { ASYNC_STATUS } from '../../../../libs/constants';
import styles from '../RoleManagementPage.module.scss';

const RoleModal = (props) => {
  const { t } = useTranslation();

  const {
    deleteRecord: { roleId, roleName, numberOfUsers },
    isShowModal,
    onCloseModal,
    handleDeleteSuccess,
  } = props;
  const { Modal, toggleModal } = useModal();

  const hasAnyAssignedUsers = parseInt(numberOfUsers, 10) > 0;

  useEffect(() => {
    toggleModal(isShowModal);
  }, [toggleModal, isShowModal]);

  const handleDeleteRole = () => {
    return fapiRoleService.deleteRole(roleId);
  };

  const deleteRoleSuccess = () => {
    handleDeleteSuccess();
    NotificationManager.success({
      message: 'notification.success.delete',
    });
  };

  const deleteRoleError = (error) => {
    console.error('error', error);
    onCloseModal();
    NotificationManager.error({
      message: 'notification.error.delete',
    });
  };

  const { execute: executeDeleteRole, status: deleteRoleStatus } = useAsync(
    handleDeleteRole,
    false,
    deleteRoleSuccess,
    deleteRoleError
  );

  const handleOk = () => {
    if (hasAnyAssignedUsers) {
      onCloseModal();
    } else {
      executeDeleteRole();
    }
  };

  const handleCancel = () => {
    onCloseModal();
  };

  const renderDeleteModalFooter = () => (
    <div className={styles.actionButtons}>
      <CustomButton type="ghost" onClick={handleCancel}>
        {t('buttonTexts.cancel')}
      </CustomButton>
      <CustomButton
        loading={deleteRoleStatus === ASYNC_STATUS.PENDING}
        onClick={handleOk}
      >
        {!hasAnyAssignedUsers
          ? t('buttonTexts.delete')
          : t('buttonTexts.confirm')}
      </CustomButton>
    </div>
  );

  return (
    <Modal
      title={t('adminWorkspace.roleManagement.modal.delete.title')}
      width={570}
      getContainer={false}
      footer={renderDeleteModalFooter()}
      closable={false}
    >
      <div>
        {!hasAnyAssignedUsers ? (
          <Translator
            value="adminWorkspace.roleManagement.modal.delete.content"
            isComponent
            variables={{ roleName }}
            htmlTags={[<br />]}
          />
        ) : (
          <Translator
            value="adminWorkspace.roleManagement.modal.delete.canNotDeleteRole"
            isComponent
            variables={{ roleName }}
            htmlTags={[<br />]}
          />
        )}
      </div>
    </Modal>
  );
};

RoleModal.propTypes = {
  deleteRecord: PropTypes.shape({
    roleId: PropTypes.string,
    roleName: PropTypes.string,
    numberOfUsers: PropTypes.number,
  }).isRequired,
  isShowModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  handleDeleteSuccess: PropTypes.func,
};

RoleModal.defaultProps = {
  isShowModal: false,
  onCloseModal: () => {},
  handleDeleteSuccess: () => {},
};
export default RoleModal;
