export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const USER_NOT_FOUND = 'USER_NOT_FOUND';

export const NOTIFICATION_MESSAGES = {
  USER_NOT_FOUND: 'This user is not found',
};
