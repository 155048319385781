import { getAddress } from 'libs/utils/addressUtils';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectRegions } from 'store/selectors/configSelector';

const useRegion = () => {
  const regions = useSelector(selectRegions);
  const formatedRegions = useMemo(() => {
    return Object.values(regions).map((region) => ({
      value: region.id,
      label: region.label,
    }));
  }, [regions]);
  const isShowRegion = formatedRegions.length > 0;

  const getAddressWithRegion = useCallback(
    (data, ignores, separator) => getAddress(data, regions, ignores, separator),
    [regions]
  );

  return {
    regions: formatedRegions,
    isShowRegion,
    getAddressWithRegion,
  };
};

export default useRegion;
