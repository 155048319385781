import PropTypes from 'prop-types';
import React from 'react';

import styles from './Tag.module.scss';

export const Tag = (props) => {
  const { children, onRemove, disabled } = props;
  return (
    <div className={styles.tag} disabled={disabled}>
      <span>{children}</span>
      <span
        disabled={disabled}
        className={styles.button}
        onClick={onRemove}
        aria-hidden
      >
        X
      </span>
    </div>
  );
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  onRemove: PropTypes.func.isRequired,
};

Tag.defaultProps = {};
