export const selectIsSubmitServiceLoading = (state) =>
  state?.serviceCase?.isLoading;

export const selectSubmitServiceData = (state) => state?.serviceCase?.data;

export const selectSubmitServiceResponse = (state) =>
  state?.serviceCase?.confirmData;

export const selectSubmitServiceStatus = (state) =>
  state?.serviceCase?.actionStatus;

export const selectMachineValidateStatus = (state) =>
  state?.serviceCase?.isValidatedMachine;

export const getSelectedMachineData = (state) =>
  state?.serviceCase?.selectedMachine;
