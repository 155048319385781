import B2becTable from 'components/B2becTable';
import useDummyItems from 'hooks/useDummyItems';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectIsUserListFetching } from 'store/slices/usersManagementSlice';

import usePermission from '../../../../../hooks/usePermission';
import { MODULE_USER_MANAGEMENT_RIGHTS } from '../../../../../libs/constants/modulerights';
import { renderColumns } from './renderTableColumns';
import styles from './UsersTable.module.scss';

const shapeOfUser = {
  companyName: '',
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  sapCustomerNumber: '',
  status: '',
};

const UserOverviewTable = ({
  onChangeSorter,
  onCellClick,
  hideHeaders,
  userList,
}) => {
  const fakeUserList = useDummyItems(6, shapeOfUser);
  const isUserListFetching = useSelector(selectIsUserListFetching);
  const { hasPermission: hasViewUserDetailPermission } = usePermission(
    MODULE_USER_MANAGEMENT_RIGHTS.VIEW_USER_DETAIL
  );

  const columns = useMemo(() => {
    const listColumn = renderColumns(
      isUserListFetching,
      onCellClick,
      hasViewUserDetailPermission
    );
    return listColumn.filter(
      (column) => !hideHeaders.includes(column.dataIndex)
    );
  }, [
    hideHeaders,
    isUserListFetching,
    onCellClick,
    hasViewUserDetailPermission,
  ]);

  return (
    <div className={styles.usersTable}>
      <B2becTable
        columns={columns}
        rowKey={isUserListFetching ? 'materialNumber' : 'key'}
        dataSource={isUserListFetching ? fakeUserList : userList}
        onChange={onChangeSorter}
      />
    </div>
  );
};

UserOverviewTable.defaultProps = {
  hideHeaders: [],
};

UserOverviewTable.propTypes = {
  onChangeSorter: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
  hideHeaders: PropTypes.arrayOf(PropTypes.string),
};

export default UserOverviewTable;
