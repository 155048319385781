import clsx from 'clsx';
import React from 'react';

import styles from './NewWishlistNameInput.module.scss';

export function NewWishlistNameInput({
  className,
  placeholder,
  value,
  onChange,
  ...rest
}) {
  const classNames = clsx(styles.input, className);
  return (
    <input
      {...rest}
      className={classNames}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
}
