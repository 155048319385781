import { Checkbox, Form } from 'antd';
import Column from 'antd/lib/table/Column';
import B2becTable from 'components/B2becTable';
import B2becTranslation from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearSelectedProduct } from 'store/slices/portfolioSlice';

import useProductConfig from '../../hooks/useProductConfig';
import styles from './ProductPortfolioEdit.module.scss';

const renderCheckbox = (dataIndex, disabled, onChange) => {
  return (value) => (
    <div className="custom-form-item">
      <Checkbox
        disabled={disabled}
        checked={value}
        onChange={(e) => onChange({ [dataIndex]: e.target.checked })}
      />
    </div>
  );
};

const PRODUCT_TYPES = { 1: 'machine', 2: 'accessory', 3: 'detergent' };

const ProductConfigTable = (props) => {
  const { value, disabled, onChange } = props;
  const { t } = useTranslation();

  const handleChange = (valueChanged) => {
    onChange({ ...value, ...valueChanged });
  };

  return (
    <B2becTable
      className={styles.productConfigTable}
      dataSource={[value]}
      rowKey="materialNumber"
    >
      <Column
        title={t('productManagement.materialNumber')}
        dataIndex="materialNumber"
      />
      <Column
        title={t('productManagement.productGroup')}
        dataIndex="productGroup"
        render={(type) => {
          const productType = PRODUCT_TYPES[type];
          if (!productType) {
            return '';
          }
          return <B2becTranslation value={`productType.${productType}`} />;
        }}
      />
      <Column
        title={t('productManagement.productSubcategory1')}
        dataIndex="subCategory1"
      />
      <Column
        title={t('productManagement.productSubcategory2')}
        dataIndex="subCategory2"
      />
      <Column
        title={t('productManagement.productClass')}
        dataIndex="productClass"
      />
      <Column
        title={t('productManagement.productName')}
        dataIndex="productName"
      />
      <Column
        title={t('productManagement.displayInNavigation')}
        dataIndex="displayInNavigation"
        width={140}
        render={renderCheckbox('displayInNavigation', disabled, handleChange)}
      />
      <Column
        title={t('productManagement.displayListPrice')}
        dataIndex="displayListPrice"
        width={140}
        render={renderCheckbox('displayListPrice', disabled, handleChange)}
      />
      <Column
        title={t('productManagement.displayDeliveryDate')}
        dataIndex="displayDeliveryDate"
        width={140}
        render={renderCheckbox('displayDeliveryDate', disabled, handleChange)}
      />
      <Column
        title={t('productManagement.displayAddToCart')}
        dataIndex="displayAddToCart"
        width={140}
        render={renderCheckbox('displayAddToCart', disabled, handleChange)}
      />
      <Column
        title={t('productManagement.disableBProgramCheckbox')}
        dataIndex="disableBProgramCheckbox"
        width={140}
        render={renderCheckbox(
          'disableBProgramCheckbox',
          disabled,
          handleChange
        )}
      />
    </B2becTable>
  );
};

ProductConfigTable.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func,
};

ProductConfigTable.defaultProps = {
  value: {},
  onChange: () => {},
};

const ProductPortfolioEdit = (props) => {
  const { handleGetProductList } = props;
  const {
    isUpdating,
    selectedProduct,
    handleSetToDefault,
    handleUpdateConfig,
  } = useProductConfig();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSave = async () => {
    const values = form.getFieldValue('productConfig');
    handleUpdateConfig(values);
  };

  const handleCancel = () => {
    dispatch(clearSelectedProduct());
  };

  useEffect(() => {
    return () => handleGetProductList();
  }, [handleGetProductList]);

  return (
    <div className={styles.ProductPortfolioEdit}>
      <Form form={form} initialValues={{ productConfig: selectedProduct }}>
        <Form.Item name="productConfig">
          <ProductConfigTable disabled={isUpdating} />
        </Form.Item>
      </Form>
      <div className={styles.footer}>
        <CustomButton
          type="ghost"
          onClick={handleSetToDefault}
          disabled={isUpdating}
        >
          {t('buttonTexts.setToStandard')}
        </CustomButton>
        <CustomButton type="ghost" onClick={handleCancel} disabled={isUpdating}>
          {t('buttonTexts.cancel')}
        </CustomButton>
        <CustomButton onClick={handleSave} disabled={isUpdating}>
          {t('buttonTexts.save')}
        </CustomButton>
      </div>
    </div>
  );
};

export default ProductPortfolioEdit;
