/* eslint-disable no-param-reassign */
import {
  CUSTOMER_ENDPOINT,
  EMPLOYEE_ENDPOINT,
  USER_ACTIVITY_ENDPOINT,
  USER_ENDPOINT,
} from '../libs/constants/b2bApiEndpoints';
import {
  PHRASE_API_URL,
  PHRASE_PROJECT_ID,
  PHRASE_TOKEN,
} from '../libs/constants/lang';
import { httpClient } from './apiHttpClient';

export class UserService {
  static validateRegistrationToken(token) {
    return httpClient.post(`${USER_ENDPOINT}/invitation/validate`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: `${token}`,
    });
  }

  static registerNewUserWithPassword(body) {
    return httpClient.post(`${USER_ENDPOINT}/invitation/register`, {
      body,
    });
  }

  static getUserSettings(userId) {
    return httpClient.get(`${USER_ENDPOINT}/${userId}`);
  }

  static changeUserLanguage(userId, language) {
    return httpClient.post(`${USER_ENDPOINT}/${userId}/changelanguage`, {
      queryParams: {
        lang: language,
      },
      body: {},
    });
  }

  static setNewPasswordApi(userId, oldPassword, newPassword) {
    return httpClient.post(`${USER_ENDPOINT}/${userId}/changepassword`, {
      body: {
        oldPassword,
        newPassword,
      },
    });
  }

  static submitContactRequest(contactReasonId, contactReasonName, message) {
    return httpClient.post(`${USER_ENDPOINT}/contact`, {
      body: { contactReasonId, contactReasonName, message },
    });
  }

  static getCustomerInfo() {
    return httpClient.get(`${CUSTOMER_ENDPOINT}`);
  }

  static registerNewActivityApi(url, name) {
    return httpClient.post(`${USER_ACTIVITY_ENDPOINT}`, {
      body: {
        url,
        name,
      },
    });
  }

  static getActivityListApi(size = 5) {
    return httpClient.get(`${USER_ACTIVITY_ENDPOINT}/list/${size}`);
  }

  static submitInvoiceChange(params) {
    return httpClient.post(
      `${CUSTOMER_ENDPOINT}/contact/invoice-address-change`,
      { body: params }
    );
  }

  static changeDeliveryAddress(body) {
    return httpClient.post(
      `${CUSTOMER_ENDPOINT}/contact/delivery-address-change`,
      {
        body,
      }
    );
  }

  static addDeliveryAddress(body) {
    return httpClient.post(
      `${CUSTOMER_ENDPOINT}/contact/delivery-address-add`,
      {
        body,
      }
    );
  }

  static deleteDeliveryAddress(body) {
    return httpClient.post(
      `${CUSTOMER_ENDPOINT}/contact/delivery-address-delete`,
      {
        body,
      }
    );
  }

  static deleteUserAccount(userId, deleteBy, cancelToken) {
    return httpClient.delete(USER_ENDPOINT, {
      body: { id: userId, deleteBy },
      cancelToken,
    });
  }

  static deleteEmployeeAccount(userId, _deleteBy, cancelToken) {
    return httpClient.delete(`${EMPLOYEE_ENDPOINT}/delete?userId=${userId}`, {
      cancelToken,
    });
  }

  static getUserEmployeesById(userId, cancelToken) {
    return httpClient.get(`${EMPLOYEE_ENDPOINT}/${userId}`, {
      cancelToken,
    });
  }

  static editCustomerSap(userId) {
    return (body) => {
      return httpClient.put(`${USER_ENDPOINT}/sapcustomerid`, {
        body: {
          userId,
          ...body,
        },
      });
    };
  }

  static getAllTranslationsFromPhrase(localeId, tags) {
    return httpClient.get(
      `${PHRASE_API_URL}/projects/${PHRASE_PROJECT_ID}/locales/${localeId}/translations?q=tags:${tags}`,
      {
        headers: {
          Authorization: `Bearer ${PHRASE_TOKEN}`,
        },
      }
    );
  }

  // employee
  static getEmployeeSuggestionList(query) {
    const queryString = new URLSearchParams({ query });
    return httpClient.get(
      `${EMPLOYEE_ENDPOINT}/suggestion?${queryString.toString()}`
    );
  }

  static getEmployeeUserAvailableLanguages(employeeId) {
    const queryString = new URLSearchParams({ employeeId });
    return httpClient.get(
      `${EMPLOYEE_ENDPOINT}/available-languages?${queryString.toString()}`
    );
  }

  static addEmployeeUser(params) {
    const requestParams = new URLSearchParams(params);
    return httpClient.post(
      `${EMPLOYEE_ENDPOINT}/create?${requestParams.toString()}`,
      {
        body: {},
      }
    );
  }
}
