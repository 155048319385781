const SERVICE_V1 = 'v1.0';

// Configuration Service
export const FAPI_CONFIGURATION_ENDPOINT = `/${SERVICE_V1}/configuration/list`;

// Product Services
export const FAPI_PRODUCT_ENDPOINT = `/${SERVICE_V1}/product`;
export const FAPI_TREE_ENDPOINT = `/${SERVICE_V1}/tree`;

// Wishlist Service
export const FAPI_SHARED_WISHLIST_ENDPOINT = `/${SERVICE_V1}/sharedwishlist`;
export const FAPI_WISHLIST_ENDPOINT = `/${SERVICE_V1}/wishlist`;
