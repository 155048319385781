import PropTypes from 'prop-types';
import React from 'react';

const FailureDescription = (props) => {
  const { data } = props;
  return (
    <div className="failure-description">
      {(data || []).map((line) => (
        <span key={line}>{line}</span>
      ))}
    </div>
  );
};

FailureDescription.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FailureDescription;
