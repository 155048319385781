import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productDetails: {
    data: {},
  },
  productPrice: {
    data: {},
  },
};

const productComparisonSlice = createSlice({
  name: 'productComparison',
  initialState,
  reducers: {
    getProductComparisonData: (state) => {
      state.productDetails.isLoading = true;
      state.productDetails.isLoaded = false;
    },
    getProductComparisonDataSuccess: (state, { payload }) => {
      state.productDetails.isLoading = false;
      state.productDetails.isLoaded = true;
      state.productDetails.data = {
        ...payload,
      };
    },
    getProductComparisonDataFailure: (state) => {
      state.productDetails.isLoading = false;
    },
    getProductComparisonPrice: (state, { payload }) => {
      const { materialNumber } = payload;
      const { data: productPriceData } = state.productPrice;

      productPriceData[materialNumber] = {
        ...payload,
        isLoading: true,
      };
    },
    getProductComparisonPriceSuccess: (state, { payload }) => {
      const { materialNumber } = payload;
      const { data: productPriceData } = state.productPrice;

      productPriceData[materialNumber] = {
        ...payload,
        isLoading: false,
      };
    },
    getProductComparisonPriceFailure: (state, { payload }) => {
      const { materialNumber } = payload;
      const { data: productPriceData } = state.productPrice;

      productPriceData[materialNumber] = {
        isLoading: false,
      };
    },

    resetProductComparison: () => initialState,
  },
});

export const productComparisonActions = productComparisonSlice.actions;

export default productComparisonSlice.reducer;

export const selectIsProductDetailsIsLoading = (state) =>
  state.productComparison.productDetails.isLoading;

export const selectIsProductDetailsIsLoaded = (state) =>
  state.productComparison.productDetails.isLoaded;

export const selectProductDetails = (state) =>
  state.productComparison.productDetails.data;
