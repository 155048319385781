const servicePackagesData = [
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.safetyInspection',
    inspect: {
      included: true,
    },
    maintain: {
      included: true,
    },
    fullService: {
      included: true,
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.inspection',
    inspect: {
      included: true,
    },
    maintain: {
      included: true,
    },
    fullService: {
      included: true,
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.preventativeMaintenance',
    inspect: {
      notIncluded: true,
    },
    maintain: {
      included: true,
    },
    fullService: {
      included: true,
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.repair',
    inspect: {
      notIncluded: true,
    },
    maintain: {
      notIncluded: true,
    },
    fullService: {
      included: true,
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.functionalGuarantee',
    inspect: {
      notIncluded: true,
    },
    maintain: {
      notIncluded: true,
    },
    fullService: {
      included: true,
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.responseTime',
    inspect: {
      notIncluded: true,
    },
    maintain: {
      notIncluded: true,
    },
    fullService: {
      included: true,
      content:
        'service.servicePackages.overview.table.responseTimeValue.fullService',
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.replacement',
    inspect: {
      notIncluded: true,
    },
    maintain: {
      notIncluded: true,
    },
    fullService: {
      optional: true,
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.consumables',
    inspect: {
      optional: true,
    },
    maintain: {
      optional: true,
    },
    fullService: {
      optional: true,
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.battery',
    inspect: {
      optional: true,
    },
    maintain: {
      optional: true,
    },
    fullService: {
      optional: true,
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.availability',
    inspect: {
      optional: true,
    },
    maintain: {
      optional: true,
    },
    fullService: {
      optional: true,
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.discount',
    inspect: {
      included: true,
      content: 'service.servicePackages.overview.table.discountValue.inspect',
    },
    maintain: {
      included: true,
      content: 'service.servicePackages.overview.table.discountValue.maintain',
    },
    fullService: {
      included: true,
      content:
        'service.servicePackages.overview.table.discountValue.fullService',
    },
  },
  {
    benefitTranslationKey:
      'service.servicePackages.overview.table.benefits.reducedRates',
    inspect: {
      included: true,
    },
    maintain: {
      included: true,
    },
    fullService: {
      included: true,
    },
  },
  {
    benefitTranslationKey: '',
    extraRow: true,
  },
];

export default servicePackagesData;
