import './styles.scss';

import { Button, Col, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import B2becPageTitle from 'components/B2becPageTitle';
import CustomButton from 'components/CustomButton';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ACTION_STATUS, isValidMaterial } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPromotedProductList,
  resetPromotedProductList,
  updatePromotionList,
} from 'store/slices/promotionSlice';

import { NOT_FOUND_PRODUCT } from '../../../components/B2becPromotionItem';
import {
  selectFetchStatus,
  selectInvalidMaterialNumbers,
  selectIsFetching,
  selectIsUpdating,
  selectProducts,
  selectUpdateStatus,
} from '../../../store/selectors/promotionSelector';
import PromotionItemListSkeleton from './components/PromotionItemListSkeleton';
import PromotionItemWrapper, {
  getNotFoundProductShape,
} from './components/PromotionItemWrapper';

const PromotionPage = (props) => {
  const { title, breadcrumbs, pageId } = props;

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageId,
    pageType: PAGE_TYPES.PROMOTION,
    pageName: 'Promotion management',
  });

  const [isEditable, setIsEditable] = useState(false);
  const [toBeUpdatedPromotionProducts, setToBeUpdatedPromotionProducts] =
    useState([]);
  const [form] = useForm();
  const { setInitialValues } = form.getInternalHooks('RC_FORM_INTERNAL_HOOKS');

  const dispatch = useDispatch();
  const {
    fetchStatus,
    isFetching,
    updateStatus,
    isUpdating,
    invalidMaterialNumbers,
    promotionProducts,
  } = useSelector((state) => ({
    fetchStatus: selectFetchStatus(state),
    isFetching: selectIsFetching(state),
    updateStatus: selectUpdateStatus(state),
    isUpdating: selectIsUpdating(state),
    invalidMaterialNumbers: selectInvalidMaterialNumbers(state),
    promotionProducts: selectProducts(state),
  }));

  const isFetchingList = isFetching;
  const isFetchingListCompleted = !isFetching;
  // const isFetchingListError = fetchStatus === ASYNC_STATUS.ERROR;

  const isUpdatingList = isUpdating;
  // const isUpdatingListCompleted = updateStatus === ASYNC_STATUS.SUCCESS;
  // const isUpdatingListError = updateStatus === ASYNC_STATUS.ERROR;

  const showEdit = isFetchingListCompleted;
  const disableSubmit = isUpdatingList;

  const mountRef = React.useRef(null);

  const refFormValues = useRef({
    isLoaded: false,
    data: undefined,
  });

  useEffect(() => {
    dispatch(getPromotedProductList());

    return () => {
      dispatch(resetPromotedProductList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateStatus !== ACTION_STATUS.DEFAULT) {
      // Hide Cancel & Save buttons after requesting update promotion products API
      setIsEditable(false);
    }
    if (updateStatus === ACTION_STATUS.SUCCESS) {
      refFormValues.current.data = { promotions: toBeUpdatedPromotionProducts };
      // To make resetFields on Cancel button clicked works as expected,
      // make sure to set form values to the toBeUpdatedPromotionProducts
      // after updating successfully
      setInitialValues(refFormValues.current.data, !mountRef.current);
    }
  }, [dispatch, updateStatus, toBeUpdatedPromotionProducts, setInitialValues]);

  if (
    !refFormValues.current.isLoaded &&
    fetchStatus !== ACTION_STATUS.DEFAULT // API request has done executing
  ) {
    if (Array.isArray(promotionProducts)) {
      let promotionProductsToShow = [...promotionProducts];
      if (invalidMaterialNumbers.length > 0) {
        promotionProductsToShow = [
          // Filter out empty objects, leave spaces for invalidMaterialNumbers
          ...promotionProducts.filter(
            (item) =>
              item &&
              Object.keys(item).length !== 0 &&
              item.constructor === Object
          ),
        ];
        invalidMaterialNumbers.forEach((materialNumber) => {
          // Push item with materialName = 'NOT_FOUND' to trigger validator of current form item to show error
          // See more in: src/pages/Admin/PromotionPage/components/PromotionItemWrapper/Cart.jsx
          promotionProductsToShow.push(getNotFoundProductShape(materialNumber));
        });
      }
      refFormValues.current.data = { promotions: promotionProductsToShow };
      refFormValues.current.isLoaded = true;
    }
  }

  if (refFormValues.current.data) {
    setInitialValues(refFormValues.current.data, !mountRef.current);

    mountRef.current = true;
  }

  const handleSubmit = (values) => {
    const { promotions } = values;
    const materialNumbers = promotions
      .map(({ materialNumber }) => materialNumber)
      .filter((v) => v);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    dispatch(updatePromotionList(materialNumbers));
    setToBeUpdatedPromotionProducts(promotions);
  };

  return (
    <div className="promotion-page">
      <B2becPageTitle>
        <h1>
          <Trans i18nKey="pageTitle.promotionManagement" />
        </h1>
      </B2becPageTitle>

      <div className="promotion-page__header">
        <Trans i18nKey="promotion.specialsForYou" />

        {showEdit ? (
          <Button type="text" onClick={() => setIsEditable(true)}>
            <EditIcon />
          </Button>
        ) : null}
      </div>

      <Form
        form={form}
        initialValues={refFormValues.current.data}
        onFinish={handleSubmit}
      >
        <div className="promotion-page__content">
          {isFetchingList ? <PromotionItemListSkeleton /> : null}

          {/* {isFetchingListError ? ( */}
          {/*  <Trans i18nKey="promotion.errors.cannotGetSpecialForYou" /> */}
          {/* ) : null} */}

          {isFetchingListCompleted ? (
            <Form.List name="promotions">
              {(fields) => {
                return (
                  <Row gutter={[30, 30]}>
                    {fields.map((field) => (
                      <Col span={24} md={12} xl={8} key={field.fieldKey}>
                        <div className="promotion-form-item__control">
                          <div className="promotion-page__label">
                            <Trans
                              i18nKey="promotion.productLabel"
                              values={{ productNo: field.fieldKey + 1 }}
                            />
                          </div>
                          <Form.Item
                            {...field}
                            rules={[
                              {
                                validator: (_, fieldValue) => {
                                  const isValid = isValidMaterial(
                                    fieldValue.materialNumber
                                  );

                                  if (fieldValue && fieldValue.materialNumber) {
                                    if (!isValid) {
                                      // eslint-disable-next-line prefer-promise-reject-errors
                                      return Promise.reject(
                                        <Trans i18nKey="errors.stringFormat" />
                                      );
                                    }
                                  }

                                  return Promise.resolve();
                                },
                              },
                              {
                                validator: (_, fieldValue) => {
                                  if (
                                    fieldValue.materialName ===
                                    NOT_FOUND_PRODUCT
                                  ) {
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(
                                      <Trans i18nKey="promotion.errors.productNotFound" />
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <PromotionItemWrapper disabled={!isEditable} />
                          </Form.Item>
                        </div>
                      </Col>
                    ))}
                  </Row>
                );
              }}
            </Form.List>
          ) : null}
        </div>
        <div hidden={!isEditable} className="promotion-page__footer">
          <Form.Item noStyle>
            <CustomButton
              type="ghost"
              onClick={() => {
                const listError = form.getFieldsError();

                listError.forEach((field) => form.resetFields(field.name));

                setIsEditable(false);
              }}
            >
              <Trans i18nKey="promotion.button.cancel" />
            </CustomButton>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              const isFieldsTouched = form.isFieldsTouched();
              const hasError = !!form
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

              const isDisabled = disableSubmit || !isFieldsTouched || hasError;

              return (
                <CustomButton
                  disabled={isDisabled}
                  onClick={() => form.submit()}
                >
                  <Trans i18nKey="promotion.button.saveChanges" />
                </CustomButton>
              );
            }}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

PromotionPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default PromotionPage;
