import './styles.scss';

import { Col, Row } from 'antd';
import clsx from 'clsx';
import B2becShopCategoryBlock from 'components/B2becShopCategoryBlock';
import { SHOP_CATEGORY_TYPE } from 'libs/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsFuturetech } from 'store/selectors/configSelector';

const ShopByCategories = () => {
  const { t } = useTranslation();
  const isFuturetech = useSelector(selectIsFuturetech);
  const className = isFuturetech ? 'futuretech' : 'karcher';

  return (
    <div className={clsx('shop-categories', className)}>
      <h2 className="shop-categories__title">{t('shopByCategory.title')}</h2>
      <Row gutter={[24, 24]}>
        <Col span={24} lg={8}>
          <B2becShopCategoryBlock type={SHOP_CATEGORY_TYPE.MACHINES} />
        </Col>
        <Col span={24} lg={8}>
          <B2becShopCategoryBlock type={SHOP_CATEGORY_TYPE.ACCESSORIES} />
        </Col>
        <Col span={24} lg={8}>
          <B2becShopCategoryBlock type={SHOP_CATEGORY_TYPE.DETERGENTS} />
        </Col>
      </Row>
    </div>
  );
};

export default ShopByCategories;
