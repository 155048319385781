import './styles.scss';

import axios from 'axios';
import environment from 'environments/environment.dev';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adminFapiContactReason } from 'services/adminFapi';

import B2becInfoLayout from '../../../components/B2becInfoLayout';
import B2becTranslation from '../../../components/B2becTranslation';
import CustomButton from '../../../components/CustomButton';
import { notificationComponent } from '../../../components/Notification';
import useAsync from '../../../hooks/useAsync';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import i18n from '../../../i18n';
import { ASYNC_STATUS, STATUS_CODE } from '../../../libs/constants';
import {
  PHRASE_ENGLISH_ID,
  PHRASE_PROJECT_ID,
} from '../../../libs/constants/lang';
import { NOTIFICATION_TYPE } from '../../../libs/constants/notification';
import ContactReasonModal from './components/ContactReasonModal';
import ContactReasonTable from './components/ContactReasonTable';
import contactReasonsFromPhrase from './contactReasonsFromPhrase';

const getAvailableLocales = async () => {
  try {
    const response = await axios.get(
      `${environment.b2bDomain}/locales/available_locales.json`
    );

    const localeId = response.data[i18n.language];

    return Promise.resolve(localeId);
  } catch {
    // TODO
  }

  return Promise.resolve(PHRASE_ENGLISH_ID);
};

const ContactReasonManagementPage = ({ title, breadcrumbs }) => {
  const { t } = useTranslation();
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  const [contactReasonsFromApi, setContactReasonsFromApi] = useState([]);
  const [editData, setEditData] = useState({ isEdit: false, data: {} });
  const [deleteData, setDeleteData] = useState({ isDelete: false, data: {} });
  const [isShowModal, setIsShowModal] = useState(false);

  const { value: localeId } = useAsync(getAvailableLocales, true);

  const handleEditContactReason = useCallback((record) => {
    setEditData({
      isEdit: true,
      data: {
        isSuccess: false,
        contactReasonId: record.contactReasonId,
        contactReasonNumber: record.indexNumber,
        receiverAddress: record.receiverAddress,
      },
    });
    setIsShowModal(true);
  }, []);

  const handleDeleteContactReason = useCallback((record) => {
    setDeleteData({
      isDelete: true,
      data: {
        contactReasonId: record.contactReasonId,
        translationContent: record.translationContent,
      },
    });
    setIsShowModal(true);
  }, []);

  const getAllContactReasonsFromApi = useCallback(() => {
    return adminFapiContactReason.getContactReasonList(10, 1);
  }, []);

  const onGetApiSuccess = useCallback((response) => {
    if (response?.status === STATUS_CODE.SUCCESS) {
      setContactReasonsFromApi(response?.data);
    }
  }, []);

  const onGetApiError = useCallback((error) => {
    notificationComponent({
      type: NOTIFICATION_TYPE.ERROR,
      message: (
        <B2becTranslation value="notification.error.getAllContactReasons" />
      ),
      description: error?.message,
    });
  }, []);

  const { status: getAllContactReasonsApiStatus } = useAsync(
    getAllContactReasonsFromApi,
    true,
    onGetApiSuccess,
    onGetApiError
  );

  const compare = (contactReason1, contactReason2) => {
    const contactReasonNumberA = Number(contactReason1.indexNumber);
    const contactReasonNumberB = Number(contactReason2.indexNumber);
    if (contactReasonNumberA < contactReasonNumberB) {
      return -1;
    }
    if (contactReasonNumberA > contactReasonNumberB) {
      return 1;
    }
    return 0;
  };

  const finalContactReasonList = useMemo(() => {
    if (contactReasonsFromApi.length > 0) {
      // Map
      const mappedArray = contactReasonsFromApi.map((reason) => {
        const foundItem = contactReasonsFromPhrase.find(
          (phraseItem) => phraseItem?.key?.id === reason?.contactReasonId
        );
        return {
          ...reason,
          translationContent: t(foundItem?.key?.name || 'N/A'),
          translationLink: `https://app.phrase.com/editor/v4/accounts/ad704585/projects/${PHRASE_PROJECT_ID}?locales=%2723a5ea8bb172e547f46c42d882d06402%27%252C%27${localeId}%27&nav=l&keyId=${reason?.contactReasonId}`,
        };
      });
      return mappedArray.sort(compare);
    }
    return [];
  }, [contactReasonsFromApi, localeId, t]);

  const isLoading = getAllContactReasonsApiStatus === ASYNC_STATUS.PENDING;

  const onAddBtnClick = useCallback(() => {
    setIsShowModal(true);
  }, []);

  const onCloseModal = useCallback(
    (contactReasonId) => {
      if (editData?.isEdit) {
        setEditData(
          // Because this callback is also used in onClick event of button
          // => contactReasonId may be event object
          // => Check if type === string (when this callback is called manually)
          contactReasonId && typeof contactReasonId === 'string'
            ? {
                isEdit: false,
                data: { isSuccess: !!contactReasonId, contactReasonId },
              }
            : { isEdit: false }
        );
      }
      setIsShowModal(false);
    },
    [editData]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteData({
      isEdit: false,
    });
    setIsShowModal(false);
  }, []);

  const onSubmitSuccess = useCallback(
    (submittedData) => {
      let newList;
      if (editData?.isEdit) {
        newList = contactReasonsFromApi.map((item) => {
          if (item.contactReasonId === submittedData.contactReason) {
            return {
              ...item,
              receiverAddress: submittedData.receiverAddress,
            };
          }
          return item;
        });
      } else if (deleteData?.isDelete) {
        newList = contactReasonsFromApi.filter(
          (item) => item.contactReasonId !== submittedData.contactReasonId
        );
      } else {
        newList = [
          {
            contactReasonId: submittedData.contactReason,
            indexNumber: submittedData.contactReasonNumber,
            receiverAddress: submittedData.receiverAddress,
          },
          ...contactReasonsFromApi,
        ];
        newList.sort(compare);
      }
      setContactReasonsFromApi(newList);
    },
    [editData, contactReasonsFromApi, deleteData]
  );

  const contactReasonsForModal = useMemo(() => {
    if (Array.isArray(contactReasonsFromPhrase)) {
      const modalData = contactReasonsFromPhrase.map((phraseItem) => ({
        contactReasonId: phraseItem?.key?.id,
        contactReasonKeyName: phraseItem?.key?.name,
        translationId: phraseItem?.id,
        translationContent: phraseItem?.content,
      }));
      if (editData?.isEdit) {
        // Is Editing Contact Reason => show only one editing option
        return modalData.filter(
          (item) => item.contactReasonId === editData?.data?.contactReasonId
        );
      }
      // Is Adding Contact Reason => remove already added ones from modal dropdown
      return modalData.filter((item) => {
        return (
          contactReasonsFromApi.findIndex(
            (apiItem) => apiItem.contactReasonId === item.contactReasonId
          ) === -1
        );
      });
    }
    return [];
  }, [editData, contactReasonsFromApi]);

  return (
    <B2becInfoLayout className="contact-reason-management__container">
      <B2becInfoLayout.Title
        title={t('pageTitle.contactReasons')}
        titleExtra={
          <div>
            <CustomButton
              disabled={isLoading || contactReasonsForModal.length === 0}
              onClick={onAddBtnClick}
            >
              {t('contactReasonManagement.button.addContactReason')}
            </CustomButton>
          </div>
        }
      />
      <B2becInfoLayout.Content>
        <ContactReasonTable
          contactReasonList={finalContactReasonList}
          isLoading={isLoading}
          handleEditContactReason={handleEditContactReason}
          handleDeleteContactReason={handleDeleteContactReason}
          highlightedContactReasonId={
            editData?.data?.isSuccess && editData?.data?.contactReasonId
          }
        />
        <ContactReasonModal
          isEdit={editData?.isEdit}
          editData={editData?.data}
          isShow={isShowModal}
          onClose={onCloseModal}
          contactReasons={contactReasonsForModal}
          onSubmitSuccess={onSubmitSuccess}
          isDelete={deleteData?.isDelete}
          deleteData={deleteData?.data}
          onCloseDeleteModal={handleCloseDeleteModal}
        />
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
};

ContactReasonManagementPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ContactReasonManagementPage;
