import { Input } from 'antd';
import { SearchIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SearchBar.module.scss';

const WishlistSearchBar = ({ searchTerm, onSearchHandler, ...props }) => {
  const { t } = useTranslation();

  return (
    <Input
      {...props}
      className={styles['wishlist-search']}
      placeholder={t('buttonTexts.search')}
      prefix={<SearchIcon className={styles['prefix-icon']} />}
      onChange={onSearchHandler}
      bordered
      size="large"
      allowClear
      value={searchTerm}
    />
  );
};

WishlistSearchBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onSearchHandler: PropTypes.func.isRequired,
};

export default WishlistSearchBar;
