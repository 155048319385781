import '../styles.scss';

import FormatPrice from 'components/FormatPrice';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const OrderSummary = (props) => {
  const {
    salesOrderNumber,
    currencyUnit,
    cartTotal,
    includingVAT,
    vat,
    cartTotalVAT,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="order-confirm__main__middle">
      <div className="order-confirm__main__middle__title">
        {t('checkout.confirmOrder.orderSummary')}
      </div>
      <div>
        {t('checkout.confirmOrder.orderNumber')}:&nbsp;
        <span data-testid="salesOrderNumber">{salesOrderNumber}</span>
      </div>

      <div>
        {t('checkout.confirmOrder.total')}:{' '}
        <FormatPrice price={cartTotal} currencyUnit={currencyUnit} />
      </div>
      {includingVAT ? (
        <>
          <div>
            {t('vat')}: <FormatPrice price={vat} currencyUnit={currencyUnit} />
          </div>

          <div>
            {t('totalWithVat')}:{' '}
            <FormatPrice price={cartTotalVAT} currencyUnit={currencyUnit} />
          </div>
        </>
      ) : null}
      <div>
        {t('checkout.confirmOrder.paymentMethod')}: {t('invoice')}
      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  salesOrderNumber: PropTypes.string.isRequired,
  currencyUnit: PropTypes.string.isRequired,
  cartTotal: PropTypes.number.isRequired,
  includingVAT: PropTypes.bool.isRequired,
  vat: PropTypes.number.isRequired,
  cartTotalVAT: PropTypes.number.isRequired,
};

export default OrderSummary;
