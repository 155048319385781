import { Col, Row, Tag } from 'antd';
import CustomButton from 'components/CustomButton';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { selectAvailableLanguages } from 'store/selectors/userSelector';

import PermissionWrapper from '../../../../../../HOCs/permissionWrapper';
import { MODULE_USER_MANAGEMENT_RIGHTS } from '../../../../../../libs/constants/modulerights';
import styles from './technicalUserInformation.module.scss';

const TechnicalUserInformationSection = ({
  onEdit,
  isLoading,
  preferredLanguageCode,
  countryId,
  assignedRoles,
  ...info
}) => {
  const { t } = useTranslation();

  const availableLanguages = useSelector(selectAvailableLanguages);

  const languageLabel = useMemo(() => {
    if (!preferredLanguageCode || preferredLanguageCode === 'N/A') {
      return 'N/A';
    }
    const matchedLang =
      isValidArray(availableLanguages) &&
      availableLanguages.find((lang) => lang?.value === preferredLanguageCode);

    if (!matchedLang?.label) {
      return preferredLanguageCode;
    }
    return matchedLang?.label;
  }, [availableLanguages, preferredLanguageCode]);

  return (
    <div className={styles.technicalUserDetails}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <div className={styles.subTitle}>
            {isLoading ? <Skeleton /> : info.name || 'N/A'}
          </div>
          <div>{isLoading ? <Skeleton /> : info.email || 'N/A'}</div>
          <div>{isLoading ? <Skeleton /> : info.phoneNumber || 'N/A'}</div>
        </Col>
        <Col xs={24} sm={12}>
          <div className={styles.subTitle}>
            {t(
              'adminWorkspace.userManagement.userDetails.userInformationSection.assignedRoles'
            )}
          </div>
          <div>
            {isLoading ? (
              <Skeleton />
            ) : (
              isValidArray(assignedRoles) && (
                <div className={styles.assignedRolesWrapper}>
                  {assignedRoles.map((role) => (
                    <Tag className={styles.assignedRole} key={role?.roleId}>
                      {role?.roleName}
                    </Tag>
                  ))}
                </div>
              )
            )}
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className={styles.subTitle}>{t('form.label.salesOrg')}</div>
          <div>{isLoading ? <Skeleton /> : <>{countryId}</>}</div>
        </Col>
        <Col xs={24} sm={12}>
          <div className={styles.subTitle}>
            {t(
              'adminWorkspace.userManagement.userDetails.userInformationSection.language'
            )}
          </div>
          <div>{isLoading ? <Skeleton /> : languageLabel}</div>
        </Col>
      </Row>
      <PermissionWrapper permission={MODULE_USER_MANAGEMENT_RIGHTS.UPDATE_USER}>
        <CustomButton
          className={styles.editButton}
          disabled={isLoading}
          onClick={onEdit}
        >
          {t(
            'adminWorkspace.userManagement.userDetails.userInformationSection.button'
          )}
        </CustomButton>
      </PermissionWrapper>
    </div>
  );
};

TechnicalUserInformationSection.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingAvailableLanguages: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  countryId: PropTypes.string,
  preferredLanguageCode: PropTypes.string,
  salutation: PropTypes.string,
  assignedRoles: PropTypes.arrayOf(
    PropTypes.shape({
      roleId: PropTypes.string,
      roleName: PropTypes.string,
    })
  ),
  onEdit: PropTypes.func,
};

TechnicalUserInformationSection.defaultProps = {
  isLoading: false,
  isLoadingAvailableLanguages: false,
  name: 'N/A',
  email: 'N/A',
  phoneNumber: 'N/A',
  countryId: 'N/A',
  preferredLanguageCode: 'N/A',
  salutation: '',
  assignedRoles: [],
  onEdit: () => {},
};

export default TechnicalUserInformationSection;
