import { createSelector } from '@reduxjs/toolkit';
import { isValidArray } from 'libs/utils/array';

export const selectMiniCartItems = (state) => state.cart.miniCart.items;
export const selectTotalQuantity = (state) => state.cart.miniCart.totalQuantity;

export const selectCartData = (state) => state.cart.cartData;
export const selectCartItems = (state) => state.cart.cartData.items;
export const selectProductReferences = (state) => state.cart.productReferences;
export const selectIsSmallLoading = (state) => state.cart.isSmallLoading;
export const selectIsFetchingCart = (state) => state.cart.isLoading;
export const selectFetchCartState = (state) => state.cart?.fetchCartState;
export const selectIsAddingToCartFromPriceBox = (state) =>
  state.cart?.isAddingToCartFromPriceBox;
export const selectIsLoadingPriceBox = (state) => state.cart?.isLoadingPriceBox;

export const selectVoucherCode = (state) =>
  state.cart.cartData?.voucherValue?.code;

export const selectCheckoutSetting = (state) =>
  state.cart?.cartData?.checkoutSettings;

export const selectIsEmptyingCart = (state) => state.cart.isEmptyingCart;

export const selectCurrencyUnit = (state) =>
  state.cart.priceBoxData.currencyUnit;

export const selectNetPrice = (state) => state.cart.priceBoxData.netPrice;

export const selectDeliveryDates = (state) =>
  state.cart.priceBoxData.deliveryDates;

export const selectIsProductDangerous = (state) =>
  state.cart.priceBoxData.isDangerous;

export const selectHasMinQuantity = (state) =>
  state.cart.priceBoxData.hasMinQuantity;

export const selectShouldDisplayDeliveryDates = (state) =>
  state.cart.priceBoxData.displayDeliveryDate ||
  state.product.data.displayDeliveryDate;

export const selectHasMoqProduct = createSelector(
  selectCartItems,
  (cartItems) => {
    if (isValidArray(cartItems)) {
      return cartItems.some((cartItem) => !!cartItem?.hasMinQuantity);
    }
    return false;
  }
);

export const selectVoucherValue = (state) => state.cart?.cartData?.voucherValue;

export const selectShouldDisplayVoucherLevel = createSelector(
  [selectCartItems, selectVoucherValue, (_, materialNumber) => materialNumber],
  (cartItems, { categoryIds, materialNumbers }, materialNumber) => {
    if (!isValidArray(categoryIds) && !isValidArray(materialNumbers)) {
      return false;
    }
    const currentItem = cartItems.find(
      (cartItem) => cartItem?.materialNumber === materialNumber
    );
    return currentItem && currentItem?.discount && +currentItem?.discount > 0;
  }
);

export const selectIsAvoidProceedingCart = createSelector(
  selectCartItems,
  (cartItems) => {
    if (isValidArray(cartItems)) {
      return cartItems.some(
        (cartItem) =>
          !!cartItem?.replacedByMaterialNumber || !!cartItem?.hasMinQuantity
      );
    }
    return false;
  }
);

export const selectIsLoadingAfterRedeemingVoucher = (state) =>
  state.cart.isLoadingAfterRedeemingVoucher;

export const selectIsQuickEntryLoading = (state) =>
  state.cart.isQuickEntryLoading;

export const selectIsDisplayingDisableBProgramCheckbox = createSelector(
  selectCartItems,
  (cartItems) => {
    if (isValidArray(cartItems)) {
      return cartItems.some(
        (cartItem) =>
          cartItem?.requiresInstruction && !cartItem?.disableBProgramCheckbox
      );
    }
    return false;
  }
);

export const selectReOrderIds = (state) => state.cart.reorderIds;
