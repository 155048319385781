import ScopeSelection from 'components/ScopeSelection';
import { CONFIG_LEVELS } from 'libs/constants/configs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ProductScope.module.scss';

const ProductScope = ({ selectScopeHandler }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['scope-container']}>
      <div className={styles['scope-introduction']}>
        {t('productManagement.introductionText')}
      </div>
      <div className={styles['scope-selection']}>
        <ScopeSelection
          onSelectScope={selectScopeHandler}
          topLevel={CONFIG_LEVELS.COUNTRY}
        />
      </div>
    </div>
  );
};

ProductScope.propTypes = {
  selectScopeHandler: PropTypes.func,
};

ProductScope.defaultProps = {
  selectScopeHandler: () => {},
};

export default ProductScope;
