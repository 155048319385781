import './styles.scss';

import { Col, Divider, Row } from 'antd';
import CustomButton from 'components/CustomButton';
import { B2becButtonBack } from 'components/CustomButton/B2becButtonIcon';
import { SERVICE_ENTRY } from 'libs/constants';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ContactInformationSection from './components/ContactInformationSection';
import DeliveryAddressSection from './components/DeliveryAddressSection';

const AddressAndDateSelectStep = ({
  currentTab,
  handleChangeTab,
  serviceEntry,
  form,
}) => {
  const { t } = useTranslation();

  const renderBackButtonText = useCallback(() => {
    switch (serviceEntry) {
      case SERVICE_ENTRY.MAINTENANCE:
        return 'backToSelectMachine';
      default:
        return 'backToSelectService';
    }
  }, [serviceEntry]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24} md={12}>
          <ContactInformationSection form={form} />
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col span={24} md={12}>
          <DeliveryAddressSection />
        </Col>
      </Row>

      <div className="service-request__layout--shrink">
        <Divider />
      </div>

      {/* buttons wrapper */}
      <Row gutter={[24, 24]}>
        <Col
          span={24}
          xs={{ order: 2 }}
          sm={{ order: 2 }}
          md={{ span: 12, order: 1 }}
          lg={8}
        >
          <div className="service-request__layout--shrink">
            <B2becButtonBack
              type="ghost"
              block
              onClick={() => {
                handleChangeTab(+currentTab - 1);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {t(`service.addressAndDate.button.${renderBackButtonText()}`)}
            </B2becButtonBack>
          </div>
        </Col>
        <Col
          span={24}
          xs={{ order: 1 }}
          sm={{ order: 1 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 8, push: 8 }}
        >
          <div className="service-request__layout--shrink">
            <CustomButton
              block
              onClick={() => {
                handleChangeTab(+currentTab + 1);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {t('service.button.next')}
            </CustomButton>
          </div>
        </Col>
      </Row>
    </>
  );
};

AddressAndDateSelectStep.propTypes = {
  form: PropTypes.shape({}).isRequired,
  currentTab: PropTypes.number.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
  serviceEntry: PropTypes.string,
};

AddressAndDateSelectStep.defaultProps = {
  serviceEntry: SERVICE_ENTRY.MAINTENANCE,
};

export default AddressAndDateSelectStep;
