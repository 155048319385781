export const getIsLoadingCheckout = (state) => state?.orders?.isPlacingAnOrder;
export const getOrderData = (state) => state?.orders?.orderData;
export const getOrderItems = (state) => state?.orders?.orderData?.items;
export const getDeliveryData = (state) => state?.orders?.deliveryData;

export const selectSalesOrderNumber = (state) =>
  state.orders.orderData?.salesOrderNumber;
export const selectCurrencyUnit = (state) =>
  state.orders.orderData?.currencyUnit;
export const selectIsIncludingVAT = (state) =>
  state.orders.orderData?.checkoutSettings?.includingVAT;
export const selectVAT = (state) => state.orders.orderData?.vat;
export const selectCartTotalVAT = (state) =>
  state.orders.orderData?.cartTotalVAT;
export const selectCartTotalWithoutVAT = (state) =>
  state.orders.orderData?.cartTotal;
