import './styles.scss';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { ReactComponent as LoggingHistoryIcon } from '../../../../../../assets/icons/loginhistory.svg';
import {
  formatDate,
  getFormatType,
} from '../../../../../../libs/utils/formatDate';

function LoggingHistorySection(props) {
  const {
    isLoading,
    invitedOn,
    revokedOn,
    createdOn,
    lastLogin,
    lastOrderCreation,
  } = props;

  const { t } = useTranslation();
  const formatType = getFormatType();

  return (
    <section className="user-details__logging-history__wrapper">
      <div className="user-details__logging-history__title">
        <LoggingHistoryIcon className="user-details__logging-history__title__icon" />
        <span>
          {t(
            'adminWorkspace.userManagement.userDetails.loggingHistorySection.title'
          )}
        </span>
      </div>

      <div className="user-details__logging-history__subtitle">
        {t(
          'adminWorkspace.userManagement.userDetails.loggingHistorySection.invitedOn'
        )}
      </div>
      <div className="user-details__logging-history__date-time">
        <Row>
          <Col xs={24} sm={24} md={24} lg={10}>
            {isLoading ? (
              <Skeleton />
            ) : (
              formatDate(invitedOn, formatType.default) || 'N/A'
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            {!isLoading &&
              formatDate(invitedOn, formatType.timeDetail) &&
              `${t(
                'adminWorkspace.userManagement.userDetails.loggingHistorySection.time'
              )} ${formatDate(revokedOn, formatType.timeDetail)}`}
          </Col>
        </Row>
      </div>
      <div className="user-details__logging-history__subtitle">
        {t(
          'adminWorkspace.userManagement.userDetails.loggingHistorySection.invitationRevokedOn'
        )}
      </div>
      <div className="user-details__logging-history__date-time">
        <Row>
          <Col xs={24} sm={24} md={24} lg={10}>
            {isLoading ? (
              <Skeleton />
            ) : (
              formatDate(revokedOn, formatType.default) || 'N/A'
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            {!isLoading &&
              formatDate(revokedOn, formatType.timeDetail) &&
              `${t(
                'adminWorkspace.userManagement.userDetails.loggingHistorySection.time'
              )} ${formatDate(revokedOn, formatType.timeDetail)}`}
          </Col>
        </Row>
      </div>

      <div className="user-details__logging-history__subtitle">
        {t(
          'adminWorkspace.userManagement.userDetails.loggingHistorySection.createdOn'
        )}
      </div>
      <div className="user-details__logging-history__date-time">
        <Row>
          <Col xs={24} sm={24} md={24} lg={10}>
            {isLoading ? (
              <Skeleton />
            ) : (
              formatDate(createdOn, formatType.default) || 'N/A'
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            {!isLoading &&
              formatDate(createdOn, formatType.timeDetail) &&
              `${t(
                'adminWorkspace.userManagement.userDetails.loggingHistorySection.time'
              )} ${formatDate(createdOn, formatType.timeDetail)}`}
          </Col>
        </Row>
      </div>
      <div className="user-details__logging-history__subtitle">
        {t(
          'adminWorkspace.userManagement.userDetails.loggingHistorySection.lastLogin'
        )}
      </div>
      <div className="user-details__logging-history__date-time">
        <Row>
          <Col xs={24} sm={24} md={24} lg={10}>
            {isLoading ? (
              <Skeleton />
            ) : (
              formatDate(lastLogin, formatType.default) || 'N/A'
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            {!isLoading &&
              formatDate(lastLogin, formatType.timeDetail) &&
              `${t(
                'adminWorkspace.userManagement.userDetails.loggingHistorySection.time'
              )} ${formatDate(lastLogin, formatType.timeDetail)}`}
          </Col>
        </Row>
      </div>
      <div className="user-details__logging-history__subtitle">
        {t(
          'adminWorkspace.userManagement.userDetails.loggingHistorySection.lastOrderCreation'
        )}
      </div>
      <div className="user-details__logging-history__date-time">
        <Row>
          <Col xs={24} sm={24} md={24} lg={10}>
            {isLoading ? (
              <Skeleton />
            ) : (
              formatDate(lastOrderCreation, formatType.default) || 'N/A'
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            {!isLoading &&
              formatDate(lastOrderCreation, formatType.timeDetail) &&
              `${t(
                'adminWorkspace.userManagement.userDetails.loggingHistorySection.time'
              )} ${formatDate(lastOrderCreation, formatType.timeDetail)}`}
          </Col>
        </Row>
      </div>
    </section>
  );
}

LoggingHistorySection.propTypes = {
  isLoading: PropTypes.bool,
  createdOn: PropTypes.string,
  revokedOn: PropTypes.string,
  invitedOn: PropTypes.string,
  lastLogin: PropTypes.string,
  lastOrderCreation: PropTypes.string,
};

LoggingHistorySection.defaultProps = {
  isLoading: false,
  createdOn: '',
  revokedOn: '',
  invitedOn: '',
  lastLogin: '',
  lastOrderCreation: '',
};

export default LoggingHistorySection;
