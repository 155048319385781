import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import B2becTranslation from 'components/B2becTranslation';
import B2BecLink from 'components/B2BLink';
import CustomButton from 'components/CustomButton';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const tableColumns = (matchingProductClick, handleCellClick, isLoading) => [
  {
    dataIndex: 'imgUrl',
    onCell: ({ materialNumber }) => ({
      onClick: () => handleCellClick(materialNumber),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : text ? (
        <Avatar size={64} shape="square" src={text} />
      ) : (
        <Avatar size={64} shape="square" icon={<UserOutlined />} />
      ),
  },
  {
    title: <B2becTranslation value="cart.table.itemNo" />,
    width: '15%',
    dataIndex: 'materialNumber',
    onCell: ({ materialNumber }) => ({
      onClick: () => handleCellClick(materialNumber),
    }),
    render: (text) => (isLoading ? <Skeleton /> : text),
  },
  {
    title: <B2becTranslation value="cart.table.itemName" />,
    dataIndex: 'name',
    onCell: ({ materialNumber }) => ({
      onClick: () => handleCellClick(materialNumber),
    }),
    render: (text) => (isLoading ? <Skeleton /> : text),
  },
  {
    title: <B2becTranslation value="orderDetail.orderDate" />,
    dataIndex: 'orderDate',
    onCell: ({ materialNumber }) => ({
      onClick: () => handleCellClick(materialNumber),
    }),
    render: (text) =>
      isLoading ? <Skeleton /> : formatDate(text, getFormatType().default),
  },
  {
    dataIndex: 'materialNumber',
    align: 'right',
    width: '15%',
    render: (text) => {
      return isLoading ? (
        <Skeleton />
      ) : (
        <B2BecLink className="link-normalize" to={`/product/${text}/all`}>
          <CustomButton>
            <B2becTranslation value="myMachines.matchingProduct" />
          </CustomButton>
        </B2BecLink>
      );
    },
  },
];
export default tableColumns;
