import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

function BaseBadge(props) {
  const { count, className, status, isMiniCart } = props;

  const statusColor = () => {
    switch (status) {
      case 'success':
        return '#52c41a';
      case 'error':
        return '#ff4d4f';
      case 'default':
        return '#000';
      case 'processing':
        return '#1890ff';
      case 'warning':
        return '#faad14';
      default:
        return '#d9d9d9';
    }
  };

  const displayQuantity = () => {
    if (isMiniCart && count > 99) {
      return '99+';
    }
    if (count > 999) {
      return '999+';
    }
    return count;
  };

  return count && count > 0 ? (
    <span
      style={{ background: statusColor() }}
      className={
        className
          ? `${className} base-badge__container bounceIn`
          : 'base-badge__container bounceIn'
      }
    >
      {displayQuantity()}
    </span>
  ) : (
    ''
  );
}

BaseBadge.propTypes = {
  count: PropTypes.number,
  className: PropTypes.string,
  status: PropTypes.string,
  isMiniCart: PropTypes.bool,
};

BaseBadge.defaultProps = {
  count: 0,
  className: '',
  status: 'default',
  isMiniCart: false,
};

export default BaseBadge;
