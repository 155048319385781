import './styles.scss';

import { InputNumber } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MinusIconSVG } from '../../assets/icons/minus.svg';
import { ReactComponent as PlusIconSVG } from '../../assets/icons/plus.svg';
import usePermission from '../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../libs/constants/modulerights';
import { formatQuantity } from '../../libs/utils/handleNumber';
import CustomButton from '../CustomButton';
import QUANTITY_TYPE from './constants';

const B2becQuantity = ({
  materialNumber,
  initialQuantity,
  minIntDigits,
  onChange,
  type,
  wrapperClassName,
  buttonClassName,
  inputClassName,
  isPriceEqualZero,
  isParentDisabled,
}) => {
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState('');
  const [quantityValue, setQuantityValue] = useState(initialQuantity);
  const { hasPermission: hasChangeCartPermission } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.CHANGE_CART
  );

  const isDisable =
    !hasChangeCartPermission ||
    isParentDisabled ||
    isPriceEqualZero ||
    (type === QUANTITY_TYPE.SHOPPING_CART &&
      typeof materialNumber !== 'string');

  const handleChangeQuantity = (newQuantity) => {
    const isValidQuantity = RegExp('^[0-9]+$').test(newQuantity);

    if (isValidQuantity) {
      setErrMsg(null);
      setQuantityValue(newQuantity);

      if (type === QUANTITY_TYPE.SHOPPING_CART) {
        onChange(materialNumber, newQuantity);
      } else {
        onChange(newQuantity);
      }
    } else {
      setErrMsg('errors.positiveNumber');
    }
  };

  const onIncrease = () => {
    setQuantityValue(quantityValue + 1);
    handleChangeQuantity(quantityValue + 1);
  };
  const onDecrease = () => {
    setQuantityValue(quantityValue - 1);
    handleChangeQuantity(quantityValue - 1);
  };

  useEffect(() => {
    setQuantityValue(initialQuantity);
  }, [initialQuantity]);

  return (
    <div className={clsx('quantity-wrapper', wrapperClassName)}>
      <div className="quantity__top">
        <CustomButton
          aria-label="decrease-button"
          isQuantity
          onClick={onDecrease}
          className={buttonClassName}
          disabled={isDisable || quantityValue <= 1 || !hasChangeCartPermission}
        >
          <MinusIconSVG />
        </CustomButton>

        <InputNumber
          type="number"
          className={`quantity__top__input ${inputClassName}`}
          onFocus={(event) => event.target.select()}
          onBlur={() => {
            setErrMsg('');
          }}
          value={formatQuantity(quantityValue || 1, minIntDigits)}
          disabled={isDisable}
          onChange={(value) => {
            if (quantityValue !== value) {
              handleChangeQuantity(value);
            }
          }}
          formatter={(value) => formatQuantity(value, minIntDigits)}
        />
        {errMsg ? (
          <p className="custom-validate-input__wrapper--error__message quantity__top__input--error">
            {t(errMsg)}
          </p>
        ) : null}

        <CustomButton
          aria-label="increase-button"
          isQuantity
          onClick={onIncrease}
          className={buttonClassName}
          disabled={isDisable || !hasChangeCartPermission}
        >
          <PlusIconSVG />
        </CustomButton>
      </div>
    </div>
  );
};

B2becQuantity.propTypes = {
  materialNumber: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  initialQuantity: PropTypes.number,
  minIntDigits: PropTypes.number,
  onChange: PropTypes.func,
  type: PropTypes.number,
  wrapperClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  isPriceEqualZero: PropTypes.bool,
  isParentDisabled: PropTypes.bool,
};

B2becQuantity.defaultProps = {
  materialNumber: '',
  initialQuantity: 1,
  minIntDigits: 2,
  onChange: () => {},
  type: QUANTITY_TYPE.PRODUCT_DETAIL,
  wrapperClassName: '',
  buttonClassName: '',
  inputClassName: '',
  isPriceEqualZero: false,
  isParentDisabled: false,
};

export default B2becQuantity;
