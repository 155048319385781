/* eslint-disable no-unused-vars */

import './styles.scss';

import { Col, Row } from 'antd';
import { DeliveryAddress } from 'components/DisplayAddress';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

function InvoiceAddressSection({
  isLoading,
  name,
  name2,
  street,
  street4,
  houseNumber,
  zip,
  region,
  city,
  country,
  customerNumber,
}) {
  const { t } = useTranslation();

  const renderCustomerAddress = () => (
    <>
      <div>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
    </>
  );

  const renderCustomerNumber = () => (
    <>
      <div className="user-details__invoice-address__subtitle">
        {t(
          'adminWorkspace.userManagement.userDetails.invoiceAddressSection.customerNumber'
        )}
      </div>
      <div>{isLoading ? <Skeleton /> : customerNumber || 'N/A'}</div>
    </>
  );

  return (
    <section className="user-details__invoice-address__wrapper">
      <div className="user-details__invoice-address__title">
        {t(
          'adminWorkspace.userManagement.userDetails.invoiceAddressSection.title'
        )}
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24}>
          {isLoading ? (
            renderCustomerAddress()
          ) : (
            <DeliveryAddress
              data={{
                name,
                name2,
                street,
                street4,
                houseNumber,
                zip,
                region,
                city,
                country,
              }}
            />
          )}
        </Col>
        <Col xs={24} sm={24}>
          {renderCustomerNumber()}
        </Col>
      </Row>
    </section>
  );
}

InvoiceAddressSection.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  name2: PropTypes.string,
  street: PropTypes.string,
  street4: PropTypes.string,
  houseNumber: PropTypes.string,
  zip: PropTypes.string,
  region: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  customerNumber: PropTypes.string,
};

InvoiceAddressSection.defaultProps = {
  isLoading: false,
  name: 'N/A',
  name2: 'N/A',
  street: 'N/A',
  street4: '',
  houseNumber: 'N/A',
  zip: 'N/A',
  region: 'N/A',
  city: 'N/A',
  country: 'N/A',
  customerNumber: 'N/A',
};

export default InvoiceAddressSection;
