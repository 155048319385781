import './styles.scss';

import { Input } from 'antd';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import DeliveryDate from 'components/B2bDeliveryDate';
import { formatQuantity } from 'libs/utils/handleNumber';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeCartProductReference } from 'store/slices/cartSlice';

const CheckoutNameBlock = ({ record }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [productPreference, setProductReference] = useState(
    record.productReference
  );

  const timerRef = useRef();
  const handleChangeProductReference = (value) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (record.price === 0) {
      return;
    }

    timerRef.current = setTimeout(() => {
      dispatch(
        changeCartProductReference({
          materialNumber: record.materialNumber,
          productReference: value,
          quantity: record.quantity,
        })
      );
    }, 300);
  };

  return (
    <div className="checkout-name__wrapper">
      <div className="checkout-name__top">
        <span className="checkout-name__top__name">{record.name}</span>
        <span className="checkout-name__top__quantity">
          {formatQuantity(record.quantity)}
        </span>
      </div>
      <div className="checkout-name__middle">
        <DeliveryDate deliveryData={record.deliveryData} />
      </div>
      {record.isDangerous && (
        <div className="checkout-name__middle__dangerous-goods">
          <InfoIcon className="checkout-name__middle__dangerous-goods__icon" />
          <span>
            {t('checkout.step3.middleSection.table.dangerousGoodsHint')}
          </span>
        </div>
      )}
      <div className="checkout-name__bottom">
        <span
          className="checkout-name__bottom__title"
          style={{ display: 'block', textTransform: 'uppercase' }}
        >
          {t('checkout.step3.middleSection.table.productPreference')}
        </span>
        <Input
          type="text"
          name="productReference"
          placeholder={t(
            'checkout.step3.middleSection.table.productPreference'
          )}
          className="custom-input checkout-name__bottom__reference-product"
          value={productPreference}
          disabled={record.price === 0}
          onChange={(e) => {
            if (productPreference !== e.target.value) {
              setProductReference(e.target.value);
              handleChangeProductReference(e.target.value);
            }
          }}
          onFocus={(e) => e.target.select()}
        />
      </div>
    </div>
  );
};
CheckoutNameBlock.propTypes = {
  record: PropTypes.shape({
    productReference: PropTypes.string,
    materialNumber: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    isDangerous: PropTypes.bool,
    price: PropTypes.number,
  }),
};
CheckoutNameBlock.defaultProps = {
  record: {},
};
export default CheckoutNameBlock;
