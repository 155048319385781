import './styles.scss';

import BackToTopButton from 'components/BackToTopButton';
import CustomButton from 'components/CustomButton';
import Footer from 'components/Layout/components/Footer';
import Content from 'components/PublicHeader/Content';
import Header from 'components/PublicHeader/Header';
import Headline from 'components/PublicHeader/Headline';
import ScrollToTop from 'components/ScrollToTop';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SelfRegistrationConfirmationPage = () => {
  const { t } = useTranslation();

  return (
    <div className="registration-page__wrapper">
      <Header />
      <ScrollToTop />
      <Headline />

      <div className="self-registration__confirmation__wrapper">
        <h1 className="self-registration__confirmation--headline">
          {t('selfRegistration.confirmation.headline')}
        </h1>
        <p className="self-registration__confirmation--text">
          {t('selfRegistration.confirmation.text')}
        </p>
        <div className="self-registration__confirmation__instruction">
          <h2 className="self-registration__confirmation__instruction--headline">
            {t('selfRegistration.confirmation.instruction.headline')}
          </h2>
          <p className="self-registration__confirmation__instruction--text">
            {t('selfRegistration.confirmation.instruction.text')}
          </p>
        </div>
        <Content>
          <div className="self-registration__confirmation__divider" />
        </Content>

        <div className="self-registration__confirmation__actions--wrapper">
          <h1>{t('selfRegistration.confirmation.actionHeadline')}</h1>
          <div className="self-registration__confirmation__actions--buttons">
            <a href="https://www.kaercher.com">
              <CustomButton type="ghost">
                {t('buttonTexts.goToKaercher')}
              </CustomButton>
            </a>
            <a href="https://business.kaercher.com/">
              <CustomButton>
                {t('buttonTexts.goToMyKaercherBusiness')}
              </CustomButton>
            </a>
          </div>
        </div>
      </div>
      <BackToTopButton />
      <Footer />
    </div>
  );
};

export default SelfRegistrationConfirmationPage;
