import { CONFIG_NUMBERS_SEPARATOR } from 'libs/constants/configs';

export const formatQuantity = (number, minIntDigits = 2) => {
  const isFormattedNumber = RegExp('^[0-9]+$').test(number);
  if (number && isFormattedNumber) {
    const result = Number(number);
    return result.toLocaleString('en-US', {
      minimumIntegerDigits: minIntDigits,
      useGrouping: false,
    });
  }
  return number;
};

export const formatPrice = (
  price,
  currencyUnit,
  numberSeparator = CONFIG_NUMBERS_SEPARATOR[1].value,
  isCurrencyFront = true,
  currencySymbol = ''
) => {
  if (typeof price !== 'number' || !currencyUnit) return price;
  const convertedPrice = Number(price);
  let currency = currencySymbol;
  let formattedPrice = '';

  // Todo: Workaround for invalid currency
  // Improve later
  try {
    const priceParts = Intl.NumberFormat(numberSeparator, {
      style: 'currency',
      currency: currencyUnit,
    }).formatToParts(convertedPrice);

    priceParts.forEach((value) => {
      if (value.type === 'currency') {
        if (!currencySymbol) {
          currency = value.value;
        }
        return;
      }
      if (value.type !== 'literal') {
        formattedPrice += value.value;
      }
    });
  } catch {
    formattedPrice = Intl.NumberFormat(numberSeparator, {
      minimumFractionDigits: 2,
    }).format(convertedPrice);
  }

  if (!currency) {
    return formattedPrice;
  }

  if (isCurrencyFront) {
    return `${currency} ${formattedPrice}`;
  }

  return `${formattedPrice} ${currency}`;
};
