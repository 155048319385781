import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React from 'react';

const B2becTableExpandIcon = ({ expanded, onExpand, record }) => {
  if (!Array.isArray(record.children)) {
    return <div />;
  }
  if (expanded) {
    return <ChevronUpIcon onClick={(e) => onExpand(record, e)} />;
  }

  return <ChevronDownIcon onClick={(e) => onExpand(record, e)} />;
};

B2becTableExpandIcon.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  record: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default B2becTableExpandIcon;
