import { createSlice } from '@reduxjs/toolkit';
import { ACTION_STATUS } from 'libs/constants';

const initialState = {
  isLoading: false,
  actionStatus: ACTION_STATUS.DEFAULT,
  data: {
    list: [],
    materialName: '',
    materialNumber: '',
    longDescription: '',
    hasAccessories: false,
    hasDetergents: false,
    hasSuitableProducts: false,
    displayDeliveryDate: undefined,
    type: 0,
    technicalData: [],
    equipments: [],
    applications: [],
    documents: [],
  },
  matchingProducts: {
    isFetchingMatchingProducts: false,
    suitableProducts: {
      items: [],
      totalCount: 0,
      materialName: '',
    },
    accessories: {
      items: [],
      totalCount: 0,
      materialName: '',
    },
    detergents: {
      items: [],
      totalCount: 0,
      materialName: '',
    },
    hasFetchedFailed: false,
  },
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProductsBySubCategory: (state) => {
      state.isLoading = true;
    },
    getProductsBySubCategorySuccess: (state, { payload }) => {
      state.isLoading = false;
      state.actionStatus = ACTION_STATUS.SUCCESS;
      state.data = payload;
    },
    getProductsBySubCategoryFailed: (state) => {
      state.isLoading = false;
      state.actionStatus = ACTION_STATUS.FAILED;
    },
    getProductDetailsByLanguage: (state) => {
      state.isLoading = true;
      state.actionStatus = ACTION_STATUS.DEFAULT;
      state.data = {};
    },
    getProductDetailsByLanguageSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.actionStatus = ACTION_STATUS.SUCCESS;
      state.data = payload;
    },
    getProductDetailsByLanguageFailed: (state) => {
      state.isLoading = false;
      state.actionStatus = ACTION_STATUS.FAILED;
    },

    getMatchingProducts: (state) => {
      state.matchingProducts.isFetchingMatchingProducts = true;
      state.matchingProducts.hasFetchedFailed = false;
    },
    getMatchingProductsSuccess: (state, { payload }) => {
      const { productType, matchingProducts } = payload;

      state.matchingProducts.isFetchingMatchingProducts = false;
      state.matchingProducts.hasFetchedFailed = false;
      state.matchingProducts[productType] = matchingProducts;
    },
    getAllMatchingProducts: () => {},
    getMatchingProductsFailed: (state) => {
      state.matchingProducts.isFetchingMatchingProducts = false;
      state.matchingProducts.hasFetchedFailed = true;
    },
    resetProductActionStatus: (state) => {
      state.actionStatus = ACTION_STATUS.DEFAULT;
    },
    setProductNameToEmpty: (state) => {
      state.data.materialName = '';
    },
    setMaterialNumberToEmpty: (state) => {
      state.data.materialNumber = '';
    },
    clearMatchingProductsWhenUnmount: (state) => {
      state.matchingProducts = initialState.matchingProducts;
    },
  },
});

export const productActions = productSlice.actions;

export default productSlice.reducer;
