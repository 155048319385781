import './styles.scss';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Radio } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

export default function CollapsedRadio(props) {
  const {
    dataSrc,
    className,
    style,
    expandIcon,
    formName,
    initialValue,
  } = props;
  const [selectedValue, setSelectedValue] = useState(1);

  const renderExpandIcon = (icon, children = null, selectedVal) => {
    if (children) {
      return (
        <div className="checkbox-accordion__radio__expand__icon">
          {selectedVal === selectedValue ? <MinusOutlined /> : <PlusOutlined />}
        </div>
      );
    }

    return null;
  };

  const renderContent = (data) => (
    <Radio.Group
      className={`checkbox-accordion__wrapper ${className}`}
      onChange={(e) => setSelectedValue(e.target.value)}
      style={style}
    >
      {data.map((item) => (
        <Radio
          key={item.value}
          value={item.value}
          className="checkbox-accordion__radio"
        >
          <div className="checkbox-accordion__radio__icon">{item.icon}</div>
          <div className="checkbox-accordion__radio__name">{item.label}</div>
          {expandIcon &&
            renderExpandIcon(expandIcon, item.children, item.value)}
          {selectedValue === item.value && item.children ? (
            <div className="checkbox-accordion__radio__expand active">
              {item.children}
            </div>
          ) : (
            <div className="checkbox-accordion__radio__expand" />
          )}
        </Radio>
      ))}
    </Radio.Group>
  );

  const renderCheckList = () => {
    return formName !== '' ? (
      <Form.Item name={formName} initialValue={initialValue}>
        {renderContent(dataSrc)}
      </Form.Item>
    ) : (
      renderContent(dataSrc)
    );
  };

  return <>{renderCheckList(dataSrc)}</>;
}

CollapsedRadio.propTypes = {
  dataSrc: PropTypes.instanceOf(Array),
  className: PropTypes.string,
  style: PropTypes.shape({}),
  expandIcon: PropTypes.bool,
  formName: PropTypes.string,
  initialValue: PropTypes.string,
};

CollapsedRadio.defaultProps = {
  dataSrc: [],
  className: '',
  style: {},
  expandIcon: false,
  formName: '',
  initialValue: '',
};
