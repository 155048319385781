import { CONFIG_DATA_TYPES, CONFIG_NAMES } from 'libs/constants/configs';
import { isCurrentTimeInSAPDowntime } from 'libs/utils/dateTimeUtils';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fapiConfigurationService } from 'services/fapi';

import {
  getAllConfig,
  getConfig,
  getConfigFailed,
  getConfigSuccess,
  getCountries,
  getSAPDowntimeSuccess,
  getUserConfigurationsSuccess,
} from '../slices/configSlice';

function* getCountriesSaga() {
  try {
    yield put(getConfig('availableCountries'));
    const { data } = yield fapiConfigurationService.getCountryList();
    yield put(getConfigSuccess({ key: 'availableCountries', data }));
  } catch (error) {
    yield put(getConfigFailed('availableCountries'));
  }
}

function* getUserConfigurations() {
  try {
    const configParams = [
      {
        configurationKey: CONFIG_NAMES.CURRENCY_POSITION_FRONT,
        dataTypeDto: CONFIG_DATA_TYPES.BOOLEAN,
      },
      {
        configurationKey: CONFIG_NAMES.CURRENTCY_SYMBOL,
        dataTypeDto: CONFIG_DATA_TYPES.STRING,
      },
      {
        configurationKey: CONFIG_NAMES.LOGO,
        dataTypeDto: CONFIG_DATA_TYPES.STRING,
      },
      {
        configurationKey: CONFIG_NAMES.DATE_FORMAT,
        dataTypeDto: CONFIG_DATA_TYPES.STRING,
      },
      {
        configurationKey: CONFIG_NAMES.EQUIPMENT_MANAGER_LINK,
        dataTypeDto: CONFIG_DATA_TYPES.STRING,
      },
      {
        configurationKey: CONFIG_NAMES.HIDE_QUICK_ENTRY,
        dataTypeDto: CONFIG_DATA_TYPES.BOOLEAN,
      },
      {
        configurationKey: CONFIG_NAMES.SHOW_RENTAL,
        dataTypeDto: CONFIG_DATA_TYPES.BOOLEAN,
      },
      {
        configurationKey: CONFIG_NAMES.ADDRESS_SCHEME,
        dataTypeDto: CONFIG_DATA_TYPES.STRING,
      },
      {
        configurationKey: CONFIG_NAMES.REGION_LIST,
        dataTypeDto: CONFIG_DATA_TYPES.ARRAY,
      },
      {
        configurationKey: CONFIG_NAMES.SAP_Downtime_Start_Time,
        dataTypeDto: CONFIG_DATA_TYPES.DATE,
      },
      {
        configurationKey: CONFIG_NAMES.SAP_Downtime_End_Time,
        dataTypeDto: CONFIG_DATA_TYPES.DATE,
      },
      {
        configurationKey: CONFIG_NAMES.SERVICE_REPAIR_PAGE,
        dataTypeDto: CONFIG_DATA_TYPES.OBJECT,
      },
      {
        configurationKey: CONFIG_NAMES.AVAILABLE_LANGUAGES,
        dataTypeDto: CONFIG_DATA_TYPES.ARRAY,
      },
      {
        configurationKey: CONFIG_NAMES.NUMBER_SEPARATOR,
        dataTypeDto: CONFIG_DATA_TYPES.STRING,
      },
      {
        configurationKey: CONFIG_NAMES.SHOW_SPARE_PART_LINK,
        dataTypeDto: CONFIG_DATA_TYPES.BOOLEAN,
      },
      {
        configurationKey: CONFIG_NAMES.SERVICE_MENU,
        dataTypeDto: CONFIG_DATA_TYPES.BOOLEAN,
      },
      {
        configurationKey: CONFIG_NAMES.AVAILABLE_SALESORG,
        dataTypeDto: CONFIG_DATA_TYPES.ARRAY,
      },
    ];

    const response = yield fapiConfigurationService.getConfigsByNames(
      configParams
    );
    const {
      [CONFIG_NAMES.SAP_Downtime_Start_Time]: startTime,
      [CONFIG_NAMES.SAP_Downtime_End_Time]: endTime,
      ...data
    } = response;

    const parsedServiceRepairPage = JSON.parse(
      data[CONFIG_NAMES.SERVICE_REPAIR_PAGE]
    );
    const parsedAvailableLanguages = JSON.parse(
      data[CONFIG_NAMES.AVAILABLE_LANGUAGES]
    );

    data[CONFIG_NAMES.SERVICE_REPAIR_PAGE] = parsedServiceRepairPage;
    data[CONFIG_NAMES.AVAILABLE_LANGUAGES] = parsedAvailableLanguages;

    const isInSAPDowntime = isCurrentTimeInSAPDowntime(startTime, endTime);

    yield all([
      put(getSAPDowntimeSuccess(isInSAPDowntime)),
      put(getUserConfigurationsSuccess(data)),
    ]);
  } catch (error) {
    console.error('getUserConfigurations: error', error);
  }
}

function* getConfigSaga() {
  yield all([call(getUserConfigurations), call(getCountriesSaga)]);
}

export default function* configurationSaga() {
  yield takeLatest(getAllConfig.type, getConfigSaga);
  yield takeLatest(getCountries.type, getCountriesSaga);
}
