import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const RENTAL_ENDPOINT = '/v1.0/rental';

const adminFapiClient = new HttpClient(environment.adminFAPIUrl);

const getRentableProductList = ({pageNumber, pageSize, sortBy, orderBy}) => {
  const params = new URLSearchParams({
    pageNumber: pageNumber,
    pageSize: pageSize,
    sortType: sortBy,
    sortOrder: orderBy,
  });
  return adminFapiClient.get(`${RENTAL_ENDPOINT}/list?${params}`);
};

const deleteProductFromRentableList = ({materialNumber, countryId}) => {
  const params = new URLSearchParams({
    materialNumber, countryId
  });
  return adminFapiClient.delete(`${RENTAL_ENDPOINT}/delete?${params}`,  {
    body: null,
  });
}

const getSuggestionsProductsList = ({query, cancelToken}) => {
  const params = new URLSearchParams({
    query
  });
  return adminFapiClient.get(`${RENTAL_ENDPOINT}/search?${params}`, cancelToken);
}

const addRental = (data) => {
  return adminFapiClient.post(`${RENTAL_ENDPOINT}/add`, {
    body: data
  });
}

export const adminFapiRental = {
  getRentableProductList,
  deleteProductFromRentableList,
  getSuggestionsProductsList,
  addRental
};
