import './styles.scss';

import B2becTranslation from 'components/B2becTranslation';
import ConfirmModal from 'components/ConfirmModal';
import { notificationComponent } from 'components/Notification';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useAsync from 'hooks/useAsync';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminFapiVoucher } from 'services/fapi/adminFapi';
import { voucherDataSelector } from 'store/selectors/voucherSelectors';
import { voucherActions } from 'store/slices/voucherSlice';

import Title from './components/Title';
import VouchersTable from './components/VouchersTable';

function VoucherManagement({ title, breadcrumbs, pageId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedVoucher, setSelectedVoucher] = useState();
  const { voucherData } = useSelector((state) => ({
    voucherData: voucherDataSelector(state),
  }));

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'Voucher Management',
    pageType: PAGE_TYPES.VOUCHER,
  });

  const { voucherList, isLoading, hasError } = voucherData;

  const {
    execute: executeDeleteVoucherInfo,
    status: deleteVoucherInfoStatus,
    error: deleteVoucherInfoError,
  } = useAsync(adminFapiVoucher.deleteVoucherInfo, false);

  const reloadList = useCallback(() => {
    dispatch(voucherActions.getVoucherList());
  }, [dispatch]);

  const handleEditVoucher = (record) => {
    history.push(linkGenerator(`/voucher/${record.id}`));
  };

  useEffect(() => {
    if (deleteVoucherInfoStatus === 'success') {
      reloadList();
      notificationComponent({
        type: NOTIFICATION_TYPE.SUCCESS,
        message: <B2becTranslation value="notification.success.delete" />,
        description: '',
      });
    }
    if (deleteVoucherInfoError) {
      notificationComponent({
        type: NOTIFICATION_TYPE.ERROR,
        message: <B2becTranslation value="notification.error.delete" />,
        description: '',
      });
    }
  }, [deleteVoucherInfoStatus, deleteVoucherInfoError, reloadList]);

  const renderConfirmModal = useCallback(
    (recordId) => {
      if (!recordId) {
        return undefined;
      }
      return (
        <ConfirmModal
          isShowModal
          setIsShowModal={() => setSelectedVoucher(undefined)}
          modalTitle={t('voucher.deleteVoucher')}
          modalContent={t('voucher.deleteVoucherConfirm')}
          confirmActionHandler={() => executeDeleteVoucherInfo(recordId)}
        />
      );
    },
    [executeDeleteVoucherInfo, t]
  );

  const handleAddVoucher = () => {
    history.push(linkGenerator('/voucher'));
  };

  useEffect(() => {
    dispatch(voucherActions.getVoucherList());
  }, [dispatch]);

  useEffect(() => {
    if (hasError) {
      notificationComponent({
        type: NOTIFICATION_TYPE.ERROR,
        message: <B2becTranslation value="notification.error.load" />,
        description: '',
      });
    }
  }, [hasError]);

  return (
    <div className="voucher-management__container">
      <Title handleAddVoucher={handleAddVoucher} />
      <VouchersTable
        voucherList={voucherList}
        handleEditVoucher={handleEditVoucher}
        handleDeleteVoucher={setSelectedVoucher}
        isLoading={isLoading}
      />
      {renderConfirmModal(selectedVoucher?.id)}
    </div>
  );
}

VoucherManagement.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default VoucherManagement;
