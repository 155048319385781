import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const RENTAL_REQUEST = '/v1.0/rental/create';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const fapiRentalService = {
  rentalCreateRequest(body) {
    return fapiClient.post(`${RENTAL_REQUEST}`, { body });
  },
};
