import environment from 'environments/environment.dev';
import React from 'react';
import { useDispatch } from 'react-redux';
import { authenticationActions } from 'store/slices/authenticationSlice';

const {
  aws: { cognito },
} = environment;

const AdminLoginPage = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      authenticationActions.signInViaIdentityProvider(
        cognito.identityProvider.employees
      )
    );
  }, [dispatch]);

  return <></>;
};

export default AdminLoginPage;
