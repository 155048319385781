import './styles.scss';

import { Checkbox, Input } from 'antd';
import B2becCustomSelect from 'components/B2becCustomSelect';
import { CustomFormItem } from 'components/B2bUserForm';
import { REGISTRATION_CUSTOMER_TYPE } from 'libs/constants/registration';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import Skeleton from 'react-loading-skeleton';

const InvoiceAddressForm = (props) => {
  const { customerType } = props;

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const renderCompanyAdditionalInfoSection = () => {
    return (
      customerType &&
      customerType === REGISTRATION_CUSTOMER_TYPE.NEW_CUSTOMER && (
        <div className="self-registration__form__company-info-section">
          <CustomFormItem
            className="self-registration__form__company-branch"
            name="companyBranch"
          >
            <B2becCustomSelect options={[]} />
          </CustomFormItem>
          <CustomFormItem
            className="self-registration__form__number-of-employees"
            name="numberOfEmployees"
          >
            <B2becCustomSelect options={[]} />
          </CustomFormItem>
        </div>
      )
    );
  };

  return (
    <div className="self-registration__form__invoice-address-section">
      <h3>{t('myProfile.invoiceAddress')}</h3>

      <CustomFormItem
        className="self-registration__form__company-name"
        name="companyName"
        rules={[{ required: true }]}
      >
        <Input />
      </CustomFormItem>

      {/* <CustomFormItem
        className="self-registration__form__email"
        name="email"
        rules={[{ required: true }]}
      >
        <Input />
      </CustomFormItem> */}

      {/* {renderCompanyAdditionalInfoSection()} */}

      <div className="self-registration__form__address-section">
        <CustomFormItem
          className="self-registration__form__street"
          name="street"
          rules={[{ required: true }]}
        >
          <Input />
        </CustomFormItem>
        <CustomFormItem
          className="self-registration__form__house-number"
          name="houseNumber"
          rules={[{ required: true }]}
        >
          <Input />
        </CustomFormItem>
      </div>

      <div className="self-registration__form__address-section">
        <CustomFormItem
          className="self-registration__form__zip-code"
          name="zip"
          rules={[{ required: true }]}
        >
          <Input />
        </CustomFormItem>
        <CustomFormItem
          className="self-registration__form__city"
          name="city"
          rules={[{ required: true }]}
        >
          <Input />
        </CustomFormItem>
      </div>

      <div className="self-registration__form__contact-section">
        <CustomFormItem
          className="self-registration__form__contactNumber"
          name="contactNumber"
          rules={[{ required: true }]}
        >
          <Input />
        </CustomFormItem>
        <CustomFormItem
          label="contactEmail"
          className="self-registration__form__email"
          name="email"
          rules={[{ required: true }]}
        >
          <Input />
        </CustomFormItem>
      </div>

      <CustomFormItem
        className="self-registration__form__country"
        name="country"
        disabled
      >
        <Input disabled />
      </CustomFormItem>

      <CustomFormItem
        name="isDefaultAddress"
        label="myProfile.form.deliveryAddress.label"
        className="self-registration__form__default-address"
        isSharedLabel={false}
      >
        <>
          <Checkbox checked disabled>
            {t('myProfile.form.setAsDefaultAddress')}
          </Checkbox>
          <span className="profile__checkbox-text">
            {t('myProfile.form.thisIsActuallyDefaultAddress')}
          </span>
        </>
      </CustomFormItem>
    </div>
  );
};

export default InvoiceAddressForm;

InvoiceAddressForm.propTypes = {
  customerType: PropTypes.string,
};

InvoiceAddressForm.defaultProps = {
  customerType: REGISTRATION_CUSTOMER_TYPE.NEW_CUSTOMER,
};
