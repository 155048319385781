import environment from 'environments/environment.dev';

import HttpClient from '../apiHttpClient';

const RENTAL_ENDPOINT = '/v1.0/rental';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const fapiRentalService = {
  getList({ searchQuery, pageNumber, pageSize = 10, sortBy, orderBy }) {
    return fapiClient.get(`${RENTAL_ENDPOINT}/search`, {
      queryParams: {
        searchQuery,
        pageNumber,
        pageSize,
        sortBy,
        orderBy,
      },
    });
  },
  getIsRentable(materialNumber) {
    return fapiClient.get(`${RENTAL_ENDPOINT}/isrentable/${materialNumber}`);
  },
};
