import { Button, Input } from 'antd';
import clsx from 'clsx';
import B2becPagination from 'components/B2becPagination';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChevronLeftIcon,
  CloseIcon,
  SearchIcon,
} from '../../../../components/Icons';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import DeliveryAddressItem from './components/DeliveryAddressItem';
import {
  addSearchTerms,
  filterAddressByTerms,
  getPaginateData,
} from './helper';
import styles from './SearchAddress.module.scss';

const SearchAddress = (props) => {
  const { dataSource, value, onChange, onCancel, shouldFocusOnSearchInput } =
    props;
  const [forceUpdate, setForceUpdate] = useState();
  const [searchTerms, setSearchTerms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearching, setIsSearching] = useState(
    () => !!shouldFocusOnSearchInput
  );
  const { t } = useTranslation();

  const { isMobile } = useDeviceDetect();

  const searchInputRef = useRef(null);

  useEffect(() => {
    if (shouldFocusOnSearchInput && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [shouldFocusOnSearchInput]);

  const handlePressEnter = (e) => {
    setSearchTerms((terms) => {
      return addSearchTerms(terms, e.target.value);
    });
    setCurrentPage(1);
    setForceUpdate(new Date().toTimeString());
  };

  const filteredDataSource = useMemo(() => {
    return filterAddressByTerms(dataSource, searchTerms);
  }, [dataSource, searchTerms]);

  const results = useMemo(() => {
    return !isMobile
      ? getPaginateData(filteredDataSource, 12, currentPage)
      : filteredDataSource;
  }, [isMobile, currentPage, filteredDataSource]);

  const totalResult = filteredDataSource.length;

  const handleDeleteSearchTerm = (searchTerm) => {
    setSearchTerms((terms) => {
      return terms.filter((term) => term !== searchTerm);
    });
  };

  const renderSearchInputMobile = () => {
    return (
      <div className={styles.inputWrapper}>
        <Button
          className={clsx(
            'button-as-link',
            isSearching && styles.searchingInput
          )}
          onClick={() => {
            if (!isSearching) {
              onCancel();
            }
          }}
        >
          {!isSearching && <ChevronLeftIcon />}
          {isSearching ? (
            <div className="custom-form-item">
              <Input
                key={forceUpdate}
                onPressEnter={handlePressEnter}
                placeholder={t('checkout.step1.addressSearch')}
                className={styles.searchingButton}
                ref={searchInputRef}
              />
            </div>
          ) : (
            <span className={styles.searchTitle}>
              {t('checkout.step1.allAddresses')}
            </span>
          )}
        </Button>
        <Button
          className={clsx(
            'button-as-link',
            isSearching && styles.startPosition
          )}
          onClick={() => {
            setIsSearching(true);
          }}
        >
          <SearchIcon />
        </Button>
        {isSearching && (
          <Button
            className="button-as-link"
            onClick={() => {
              setIsSearching(false);
            }}
          >
            <CloseIcon />
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={styles.searchAddress}>
      {!isMobile ? (
        <div className="custom-form-item">
          <Input
            className={styles.searchAddressInput}
            key={forceUpdate}
            onPressEnter={handlePressEnter}
            placeholder={t('checkout.step1.addressSearch')}
          />
        </div>
      ) : (
        renderSearchInputMobile()
      )}
      <div className={styles.listSearchTerm}>
        {searchTerms.map((text) => (
          <div className={styles.searchTerm} key={text}>
            <span className={styles.searchTermInner}>
              {text}{' '}
              <Button
                type="text"
                icon="X"
                onClick={() => handleDeleteSearchTerm(text)}
              />
            </span>
          </div>
        ))}
      </div>
      <div className={styles.totalResult}>
        <strong>
          {t('checkout.step1.foundedAddress', {
            totalResult,
          })}
        </strong>
      </div>
      <div className={styles.listAddress}>
        {results.map((address) => {
          return (
            <DeliveryAddressItem
              key={address.partnerNumber}
              address={address}
              value={value}
              onChange={onChange}
            />
          );
        })}
      </div>
      {!isMobile && (
        <B2becPagination
          hideOnSinglePage
          pageSize={12}
          total={filteredDataSource.length}
          current={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

SearchAddress.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.shape({ partnerNumber: PropTypes.string }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(SearchAddress);
