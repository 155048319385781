import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import CartItem from '../CartItem';

function CartItemList(props) {
  const { isLoading, listData, currencyUnit } = props;

  const renderList = () =>
    isLoading ? (
      <CartItem
        isLoading={isLoading}
        img={{ alt: '' }}
        name=""
        materialNumber=""
      />
    ) : (
      listData.map((item) => {
        const {
          imgUrl,
          name,
          materialNumber,
          price,
          quantity,
          total,
          productReference,
        } = item;
        return (
          <CartItem
            key={materialNumber}
            img={{ src: imgUrl, alt: name }}
            name={name}
            materialNumber={materialNumber}
            price={price}
            quantity={quantity}
            total={total}
            currencyUnit={currencyUnit}
            productReference={productReference}
          />
        );
      })
    );

  return (
    <div className="order-overview__middle-section__cart-item-list__wrapper fw-container">
      {renderList()}
    </div>
  );
}

CartItemList.propTypes = {
  isLoading: PropTypes.bool,
  listData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currencyUnit: PropTypes.string,
};

CartItemList.defaultProps = {
  isLoading: false,
  currencyUnit: '',
};

export default CartItemList;
