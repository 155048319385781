/* eslint-disable react/button-has-type */
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './CallToActionButton.module.scss';

const CallToActionButton = React.forwardRef((props, ref) => {
  const { type, loading, leftIcon, rightIcon, children, className, ...rest } =
    props;

  const [innerLoading, setInnerLoading] = React.useState(!!loading);
  const buttonRef = ref || React.createRef();

  React.useEffect(() => {
    setInnerLoading(loading);
  }, [loading]);

  const handleClick = (e) => {
    const { onClick } = props;

    if (innerLoading) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    // eslint-disable-next-line no-unused-expressions
    onClick?.(e);
  };

  const classes = clsx(styles.wrapper, {
    [className]: className,
  });

  return (
    <button
      {...rest}
      type={type}
      className={classes}
      onClick={handleClick}
      ref={buttonRef}
    >
      {leftIcon && (
        <span className={styles.icon}>
          {innerLoading ? <LoadingOutlined /> : leftIcon}
        </span>
      )}

      <span className={styles.content}>{children}</span>

      {rightIcon && (
        <span className={styles.icon}>
          {innerLoading ? <LoadingOutlined /> : rightIcon}
        </span>
      )}
    </button>
  );
});

CallToActionButton.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  leftIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.symbol,
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.symbol,
  ]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
};

CallToActionButton.defaultProps = {
  type: 'button',
  loading: false,
  leftIcon: null,
  rightIcon: null,
  className: null,
  children: null,
  onClick: () => {},
};

CallToActionButton.displayName = 'CallToActionButton'

export default CallToActionButton;
