import { Form, Input } from 'antd';
import B2BecOrderDetailTitle from 'components/B2BecOrderDetailTitle';
import { useAsync } from 'hooks';
import { ASYNC_STATUS } from 'libs/constants';
import OrderDetailsTable from 'pages/OrderPage/OrderDetailsPage/OrderDetailsTable';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adminFapiSupport } from 'services/adminFapi';

import styles from './OrderDetails.module.scss';

const handleGetOrderDetails = async (userId, countryId, orderNumber) => {
  const response = await adminFapiSupport.getOrderByUser(
    userId,
    countryId,
    orderNumber
  );
  return response.data;
};

function OrderDetails({ userId, countryId, onLoaded }, ref) {
  const { t } = useTranslation();
  const fetchOrderDetails = useCallback(
    (orderNumber) => {
      return handleGetOrderDetails(userId, countryId, orderNumber);
    },
    [countryId, userId]
  );

  const { value, status, execute } = useAsync(fetchOrderDetails, false);
  const [orderNumber, setOrderNumber] = useState();
  const isLoading = status === ASYNC_STATUS.PENDING;
  const isIdle = status === ASYNC_STATUS.IDLE;
  const shouldShowTable = !isIdle || value;
  const { current } = ref;

  if (current) {
    current.handleGet = () => execute(orderNumber);
  }

  return (
    <div className={styles.wrapper} ref={ref}>
      <div className={styles.header}>
        <Form.Item
          className="custom-form-item"
          label={t('orderDetail.orderNumber')}
          labelCol={{ span: 24 }}
        >
          <Input
            placeholder={t('orderDetail.orderNumber')}
            onChange={(e) => setOrderNumber(e.target.value)}
          />
        </Form.Item>
      </div>
      {shouldShowTable && (
        <div>
          <B2BecOrderDetailTitle isLoading={isLoading} orderDetails={value} />
          <OrderDetailsTable
            isLoading={isLoading}
            orderItems={value}
            orderNumber={value?.salesOrderNumber}
            status={value?.status}
            orderDate={value?.orderDate}
            referenceNumber={value?.referenceNumber}
          />
        </div>
      )}
    </div>
  );
}

export default React.forwardRef(OrderDetails);
