/* eslint-disable prefer-promise-reject-errors */
import B2becTranslation from 'components/B2becTranslation';
import { PASSWORD_PATTERN } from 'libs/constants/regexPattern';
import React from 'react';

export const validateRequiredMessage = (labelId) => (
  <>
    <B2becTranslation value={labelId} />{' '}
    <B2becTranslation value="setPassword.validationMessage.required" />
  </>
);

export const validatePasswordRequirements = (_, value) => {
  try {
    if (RegExp(PASSWORD_PATTERN).test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      <B2becTranslation
        value="setPassword.validationMessage.passwordRequirements"
        isComponent
        htmlTags={[<div key="paddingLeft" style={{ paddingLeft: '15px' }} />]}
      />
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const validateConfirmPassword = (newPassword, errorMessage) => ({
  validator(_, value) {
    if (!value || newPassword === value) {
      return Promise.resolve();
    }
    return Promise.reject(
      <B2becTranslation
        value={errorMessage || 'setPassword.validationMessage.confirmPassword'}
      />
    );
  },
});

// this function is implemented temporarily, it will be removed in the extend approach
export const validateSharedSecretRequirements = (_, value) => {
  try {
    if (RegExp(PASSWORD_PATTERN).test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      <B2becTranslation
        value="form.validate.sharedSecretRequired"
        isComponent
        htmlTags={[<div key="paddingLeft" style={{ paddingLeft: '15px' }} />]}
      />
    );
  } catch (error) {
    return Promise.reject(error);
  }
};
