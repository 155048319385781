import './styles.scss';

import { Modal } from 'antd';
import clsx from 'clsx';
import { DeleteBorderlessIcon, DeleteIcon } from 'components/Icons';
import useDeviceDetect from 'hooks/useDeviceDetect';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeCartItem } from 'store/slices/cartSlice';

const { confirm } = Modal;
function RemoveCartItem(props) {
  const {
    materialNumber,
    haveText,
    isOnFlexStart,
    wrapperClassname,
    buttonClassname,
    ...rest
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceDetect();

  const wrapperClasses = clsx(
    'remove-cart-item__wrapper',
    {
      'justify-start': isOnFlexStart,
    },
    [wrapperClassname]
  );
  const buttonClasses = clsx('remove-cart-item--btn', [buttonClassname]);

  const handleDelete = (e, materialNum) => {
    e.stopPropagation();

    dispatch(removeCartItem(materialNum));
  };

  const showDeleteConfirm = (e) => {
    e.stopPropagation();

    confirm({
      title: t('cart.modal.title'),
      content: t('cart.modal.content'),
      okText: t('cart.modal.confirmBtn'),
      okType: 'danger',
      cancelText: t('cart.modal.cancelBtn'),
      onOk() {
        handleDelete(e, materialNumber);
      },
    });
  };

  return (
    <div className={wrapperClasses}>
      <button
        {...rest}
        type="button"
        className={buttonClasses}
        onClick={
          typeof materialNumber === 'string'
            ? (e) => showDeleteConfirm(e)
            : (e) => handleDelete(e)
        }
      >
        {isMobile ? (
          <DeleteIcon className="remove-cart-item__icon" />
        ) : (
          <DeleteBorderlessIcon className="remove-cart-item__icon" />
        )}
        {haveText ? t('cart.removeItem') : null}
      </button>
    </div>
  );
}

RemoveCartItem.propTypes = {
  materialNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  haveText: PropTypes.bool,
  isOnFlexStart: PropTypes.bool,
  style: PropTypes.shape({}),
  wrapperClassname: PropTypes.string,
  buttonClassname: PropTypes.string,
};

RemoveCartItem.defaultProps = {
  materialNumber: '',
  haveText: true,
  isOnFlexStart: false,
  style: {},
  wrapperClassname: null,
  buttonClassname: null,
};

export default RemoveCartItem;
