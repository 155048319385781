import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearEmployeeSuggestionList,
  getSuggestionList,
  selectEmployeeSuggestionList,
} from 'store/slices/usersManagementSlice';

const SuggestionInput = ({ onSetUserIdValue }) => {
  const timeoutId = useRef();
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const dispatch = useDispatch();

  const employeeSuggestionList = useSelector(selectEmployeeSuggestionList);

  const onSelectHandler = useCallback(
    (_, employee) => {
      onSetUserIdValue(employee?.key);
    },
    [onSetUserIdValue]
  );

  const onChangeHandler = useCallback(
    (value) => {
      clearTimeout(timeoutId.current);

      setSelectedEmployee(value);

      timeoutId.current = setTimeout(() => {
        if (value.length > 0) {
          dispatch(getSuggestionList(value));
        } else {
          dispatch(clearEmployeeSuggestionList());
        }
      }, 300);
    },
    [dispatch]
  );

  const options = useMemo(() => {
    return employeeSuggestionList.map((employee) => ({
      key: employee?.userId,
      value: employee?.login,
      label: (
        <span className="suggestion--employee-user">
          {employee?.displayName} | {employee?.login}
        </span>
      ),
    }));
  }, [employeeSuggestionList]);

  return (
    <AutoComplete
      onSelect={onSelectHandler}
      onChange={onChangeHandler}
      options={options}
      value={selectedEmployee}
      suffixIcon={<LoadingOutlined />}
    >
      <Input />
    </AutoComplete>
  );
};

export default SuggestionInput;

SuggestionInput.propTypes = {
  onSetUserIdValue: PropTypes.func.isRequired,
};
