import B2becTable from 'components/B2becTable';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsPortfolioLoading,
  selectPortfolioOverviewList,
} from 'store/selectors/portfolioSelector';
import { setSelectedProduct } from 'store/slices/portfolioSlice';

import tableColumns from './tableColumns';

const PortfolioOverviewList = ({ canUpdateProductPortfolio }) => {
  const isLoading = useSelector(selectIsPortfolioLoading);
  const dispatch = useDispatch();
  const portfolioOverviewList = useSelector(
    selectPortfolioOverviewList.selectAll
  );

  return (
    <div className="portfolio-list-wrapper">
      <B2becTable
        columns={tableColumns(isLoading)}
        dataSource={portfolioOverviewList || []}
        rowKey="materialNumber"
        boxShadow={false}
        onRow={(record) => ({
          onClick: () =>
            canUpdateProductPortfolio
              ? dispatch(setSelectedProduct(record))
              : null,
        })}
      />
    </div>
  );
};

export default PortfolioOverviewList;
