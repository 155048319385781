import { NotificationManager } from 'components/Notification';
import { STATUS_CODE } from 'libs/constants';
import { linkGenerator } from 'libs/utils/language';
import { delay, getContext, put, takeLatest } from 'redux-saga/effects';
import { WishlistService } from 'services';
import { getCartItems } from 'store/slices/cartSlice';
import {
  addAllProductsToCart,
  addAllProductsToCartFailed,
  addAllProductsToCartSuccess,
  deleteWishlist,
  deleteWishlistFailed,
  deleteWishlistSuccess,
  getWishlistDetails,
  getWishlistDetailsFailed,
  getWishlistDetailsSuccess,
  getWishlistOverview,
  getWishlistOverviewFailed,
  getWishlistOverviewSuccess,
  removeWishlistProduct,
  removeWishlistProductSuccess,
  updateProductQuantity,
  updateProductQuantitySucceed,
} from 'store/slices/wishlistSlice';

import { modifyPayloadIfHavingSessionBasedShoppingCart } from './cartSaga';

const mapWishlistOverviewItems = (items, ownerId) => {
  return items.map((item) => {
    return {
      ...item,
      isShared: item.ownerId !== ownerId,
    };
  });
};

function* getWishlistOverviewSaga(action) {
  const { pageNumber } = action.payload;
  try {
    let response = yield WishlistService.getWishlistOverview(action.payload);
    if (response.data.items.length === 0 && pageNumber !== 1) {
      response = yield WishlistService.getWishlistOverview(
        Object.assign(action.payload, {
          pageNumber: Math.max(pageNumber - 1, 1),
        })
      );
    }
    const { items, ...pagination } = response.data;
    yield put(
      getWishlistOverviewSuccess({
        items: mapWishlistOverviewItems(items, action.payload.ownerId),
        pagination,
      })
    );
  } catch {
    yield put(getWishlistOverviewFailed());
  }
}

function* getWishlistDetailsSaga({ payload }) {
  try {
    const { data, status } = yield WishlistService.getWishlistDetail(payload);
    if (status === STATUS_CODE.SUCCESS) {
      yield put(getWishlistDetailsSuccess(data));
    }
  } catch (error) {
    yield put(getWishlistDetailsFailed());
    NotificationManager.error({
      message: 'notification.error.getWishlistDetail',
      description: error,
    });
  }
}

function* updateProductQuantitySaga({ payload }) {
  try {
    yield delay(350);
    yield put(updateProductQuantitySucceed(payload));
    yield WishlistService.updateWishlistProductQuantity(payload);
  } catch (error) {
    NotificationManager.error({
      message: 'notification.error.updateWishListProduct',
      description: error,
    });
  }
}

function* removeWishlistProductSaga({ payload }) {
  try {
    const { status } = yield WishlistService.removeProductInWishlist(payload);
    if (status === STATUS_CODE.SUCCESS) {
      yield put(removeWishlistProductSuccess(payload));
    }
  } catch (error) {
    NotificationManager.error({
      message: 'notification.error.removeWishlistProduct',
      description: error,
    });
  }
}

function* addWishlistProductsToCartSaga({ payload }) {
  try {
    const modifiedPayload = yield modifyPayloadIfHavingSessionBasedShoppingCart(
      payload
    );

    const { status } = yield WishlistService.addWishlistProductsToCart(
      modifiedPayload
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put(addAllProductsToCartSuccess());
      yield put(getCartItems());
      NotificationManager.success({
        message: 'notification.success.addProduct',
      });
    }
  } catch (error) {
    yield put(addAllProductsToCartFailed(error));
    NotificationManager.error({
      message: 'notification.error.addProduct',
      description: error,
    });
  }
}

function* deleteWishlistSaga({ payload }) {
  try {
    const { history } = yield getContext('dependencies');
    const { status } = yield WishlistService.deleteWishlist(payload);
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteWishlistSuccess(payload));
      yield history.push(linkGenerator('/wishlists'));
      NotificationManager.success({
        message: 'notification.success.deleteWishlist',
      });
    }
  } catch (error) {
    yield put(deleteWishlistFailed(error));
    NotificationManager.error({
      message: 'notification.error.deleteWishlist',
      description: error,
    });
  }
}

export default function* wishlistSaga() {
  yield takeLatest(getWishlistOverview, getWishlistOverviewSaga);
  yield takeLatest(getWishlistDetails.type, getWishlistDetailsSaga);
  yield takeLatest(removeWishlistProduct.type, removeWishlistProductSaga);
  yield takeLatest(updateProductQuantity.type, updateProductQuantitySaga);
  yield takeLatest(addAllProductsToCart.type, addWishlistProductsToCartSaga);
  yield takeLatest(deleteWishlist.type, deleteWishlistSaga);
}
