import './styles.scss';

import Translator from 'components/B2becTranslation';
import { notificationComponent } from 'components/Notification';
import useAsync from 'hooks/useAsync';
import { ASYNC_STATUS, STATUS_CODE } from 'libs/constants';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminFapiUserManagement } from 'services/adminFapi';
import { selectUserCountryId } from 'store/selectors/userSelector';

import ActionButtonWithModal from '../ActionsModalComponent';

const DeleteInvitationSection = ({ isLoading, invitationId }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { countryId } = useSelector((state) => ({
    countryId: selectUserCountryId(state),
  }));

  const deleteInvitation = useCallback(
    (cancelToken) => (userId, userCountryId) =>
      adminFapiUserManagement.deleteInvitation(
        userId,
        userCountryId,
        cancelToken
      ),
    []
  );

  const { execute, status } = useAsync(
    deleteInvitation,
    false,
    undefined,
    undefined,
    true
  );

  const onDeleteInvitation = useCallback(() => {
    if (invitationId && countryId) {
      execute(invitationId, countryId).then(({ response, error }) => {
        if (response?.status === STATUS_CODE.SUCCESS) {
          notificationComponent({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: (
              <Translator value="notification.success.deleteInvitation" />
            ),
          });

          history.push(linkGenerator('/user-management'));
        }

        if (error) {
          notificationComponent({
            type: NOTIFICATION_TYPE.ERROR,
            message: <Translator value="notification.error.deleteInvitation" />,
          });
        }
      });
    }
  }, [invitationId, countryId, execute, history]);

  const translateTextByKey = useCallback(
    (key) =>
      t(
        `adminWorkspace.userManagement.userDetails.deleteInvitationSection.${key}`
      ),
    [t]
  );

  return (
    <ActionButtonWithModal
      className="user-details__delete-invitation__wrapper"
      isLoading={status === ASYNC_STATUS.PENDING}
      isDisabled={isLoading}
      title={translateTextByKey('title')}
      description={translateTextByKey('description')}
      buttonName={translateTextByKey('button')}
      modalTitle={t('userManagement.deleteInvitation.title')}
      modalContent={t('userManagement.deleteInvitation.modalContent')}
      onConfirmAction={onDeleteInvitation}
    />
  );
};

DeleteInvitationSection.propTypes = {
  isLoading: PropTypes.bool,
  invitationId: PropTypes.string.isRequired,
};

DeleteInvitationSection.defaultProps = {
  isLoading: false,
};

export default DeleteInvitationSection;
