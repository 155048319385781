import { NotificationManager } from 'components/Notification';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { fapiProductPortfolioService } from 'services/adminFapi';

import {
  addProduct,
  addProductFailed,
  addProductSuccess,
  clearSelectedProduct,
  deleteProduct,
  deleteProductFailed,
  deleteProductSuccess,
  getPortfolioOverviewList,
  getPortfolioOverviewListFailed,
  getPortfolioOverviewListSuccess,
  updateProduct,
  updateProductFailed,
  updateProductSuccess,
} from '../slices/portfolioSlice';

function* getPortfolioOverviewListSaga({ payload }) {
  try {
    const { data } = yield call(
      fapiProductPortfolioService.getProductPortfolioList,
      payload
    );

    yield put(getPortfolioOverviewListSuccess(data));
  } catch (error) {
    yield put(getPortfolioOverviewListFailed(error));
  }
}

function* addProductSaga({ payload }) {
  try {
    yield call(fapiProductPortfolioService.addProduct, payload);

    yield put(addProductSuccess(payload.materialNumber));
    NotificationManager.success({ message: 'notification.success.addProduct' });
  } catch (error) {
    yield put(addProductFailed(payload.materialNumber));
    NotificationManager.error({ message: 'notification.error.addProduct' });
  }
}

function* updateProductSaga({ payload }) {
  try {
    const { data } = yield fapiProductPortfolioService.updateProduct(payload);
    yield put(updateProductSuccess(data));
    yield put(clearSelectedProduct());
    NotificationManager.success({
      message: 'notification.success.updateProduct',
    });
  } catch (error) {
    yield put(deleteProductFailed());
    NotificationManager.error({
      message: 'notification.error.updateProduct',
    });
  }
}

function* deleteProductSaga({ payload }) {
  try {
    const { data } = yield call(
      fapiProductPortfolioService.deleteProduct,
      payload
    );
    yield put(deleteProductSuccess(data));
    yield put(clearSelectedProduct());
    NotificationManager.success({
      message: 'notification.success.setToDefault',
    });
  } catch (error) {
    yield put(updateProductFailed());
    NotificationManager.error({
      message: 'notification.error.setToDefault',
    });
  }
}

function* productPortfolioSaga() {
  yield takeLatest(getPortfolioOverviewList.type, getPortfolioOverviewListSaga);
  yield takeEvery(addProduct.type, addProductSaga);
  yield takeLatest(updateProduct.type, updateProductSaga);
  yield takeLatest(deleteProduct.type, deleteProductSaga);
}

export default productPortfolioSaga;
