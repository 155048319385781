import './styles.scss';

import { Col, Empty, Row } from 'antd';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addProductToCart } from 'store/slices/cartSlice';
import {
  sendProductDetail,
  showAddProductToWishlistModal,
} from 'store/slices/wishlistSlice';

import usePermission from '../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../libs/constants/modulerights';
import AddToCartBtn from '../AddToCartBtn';
import ProductCard from '../ProductCard';

const MatchingProductsWithPaging = ({ products }) => {
  const dispatch = useDispatch();
  const { hasPermission: hasViewListPricePermission } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_LIST_PRICE
  );

  const onAddProductToCart = useCallback(
    (materialId) => {
      dispatch(addProductToCart({ materialNumber: materialId }));
    },
    [dispatch]
  );

  const handleAddToWishlist = ({ materialName, materialNumber, img }) => {
    dispatch(sendProductDetail({ materialName, materialNumber, img }));
    dispatch(showAddProductToWishlistModal());
  };

  return (
    <Row
      gutter={[
        { xs: 0, sm: 0, md: 24, lg: 32, xl: 32, xxl: 32 },
        { xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 },
      ]}
      style={{ marginBottom: 20 }}
    >
      {isValidArray(products) ? (
        products.map((item) => {
          return (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              key={item.materialId}
              className="card-item"
            >
              <ProductCard
                {...item}
                isSuitableProduct
                listPrice={item?.listPrice || item?.price}
                currencyUnit={item?.currencyUnit || item?.currency}
                requiresInstruction={item?.requiresInstruction}
                shouldDisplayListPrice={
                  item?.displayListPrice && hasViewListPricePermission
                }
                addToCartBtn={
                  <AddToCartBtn
                    handleClick={() => onAddProductToCart(item.materialId)}
                  />
                }
                onAddToWishlist={handleAddToWishlist}
              />
            </Col>
          );
        })
      ) : (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Empty />
        </div>
      )}
    </Row>
  );
};

MatchingProductsWithPaging.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
};

MatchingProductsWithPaging.defaultProps = {
  products: [],
};

export default MatchingProductsWithPaging;
