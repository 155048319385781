import { useEffect } from 'react';

const useDisableBodyScroll = (isOpen) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.setProperty('overflow', 'hidden', 'important');
    }

    return () => {
      document.body.style.setProperty('overflow', 'auto', 'important');
    };
  });
};

export default useDisableBodyScroll;
