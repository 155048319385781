import { Grid, Space } from 'antd';
import React from 'react';

const CustomSpace = (props) => {
  const { xl } = Grid.useBreakpoint();
  return (
    <Space
      className="block-two-col__btn-wrapper"
      direction={xl ? 'horizontal' : 'vertical'}
      {...props}
    />
  );
};

export default CustomSpace;
