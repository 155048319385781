import qs from 'querystring';

import environment from '../environments/environment.dev';
import {
  FAPI_PRODUCT_ENDPOINT,
  FAPI_TREE_ENDPOINT,
} from '../libs/constants/b2bFapiApiEndpoints';
import HttpClient from './apiHttpClient';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const ProductService = {
  requestProductDetailByLang(materialNumber) {
    return fapiClient.get(`${FAPI_PRODUCT_ENDPOINT}/${materialNumber}/details`);
  },

  requestCategoryPath(categoryId) {
    return fapiClient.get(`${FAPI_PRODUCT_ENDPOINT}/categorypath`, {
      queryParams: {
        categoryId,
      },
    });
  },

  getComparisonDetails(listMaterialNumber = []) {
    const queryParams = listMaterialNumber.join('&materialNumber=');

    return fapiClient.get(
      `${FAPI_PRODUCT_ENDPOINT}/compare?materialNumber=${queryParams}`
    );
  },

  getSubmenuProductCategoryList() {
    return fapiClient.get(`${FAPI_TREE_ENDPOINT}/navigation-menu`);
  },

  getCategoryInfo(categoryIds) {
    return fapiClient.get(`${FAPI_TREE_ENDPOINT}/category-without-product`, {
      queryParams: {
        categoryIds,
      },
      paramsSerializer: {
        serialize: (params) => qs.stringify(params),
      },
    });
  },

  getProductInfo(materialNumbers) {
    return fapiClient.get(`${FAPI_PRODUCT_ENDPOINT}/material-name`, {
      queryParams: {
        materialNumbers,
      },
      paramsSerializer: {
        serialize: (params) => qs.stringify(params),
      },
    });
  },

  getClassTypesAndProductCount(categoryId, sortOrder = 0) {
    const queryParams = new URLSearchParams({
      categoryId,
      sortOrder,
      pageSize: 1,
    });
    return fapiClient.get(`${FAPI_TREE_ENDPOINT}/category?${queryParams}`);
  },

  getProductsByClassType(categoryId, pageNumber, sortOrder, pageSize) {
    const queryParams = new URLSearchParams({
      categoryId,
      sortOrder,
      pageSize,
      pageNumber,
    });
    return fapiClient.get(`${FAPI_TREE_ENDPOINT}/category?${queryParams}`);
  },

  getProductSubCategoryOverviewData(categoryId) {
    const queryParams = new URLSearchParams({ categoryId });
    return fapiClient.get(`${FAPI_TREE_ENDPOINT}/category?${queryParams}`);
  },

  getMachineServiceFAQs(materialNumber) {
    return fapiClient.get(
      `${FAPI_PRODUCT_ENDPOINT}/${materialNumber}/servicefaqs`
    );
  },

  getBasicProductInfo(materialNumber = '') {
    return fapiClient.get(`${FAPI_PRODUCT_ENDPOINT}/${materialNumber}`);
  },

  getPromotionProducts() {
    return fapiClient.get(`${FAPI_PRODUCT_ENDPOINT}/promotion`);
  },

  getMatchingProducts(
    productType,
    materialNumber,
    pageNumber,
    sortOrder = 2,
    pageSize = 12
  ) {
    const queryParams = new URLSearchParams({
      pageNumber,
      pageSize,
      sortOrder,
    });

    return fapiClient.get(
      `${FAPI_PRODUCT_ENDPOINT}/${materialNumber}/${productType}?${queryParams}`
    );
  },

  getProductSpareParts(materialNumber, sessionId) {
    return fapiClient.get(`${FAPI_PRODUCT_ENDPOINT}/${materialNumber}/disis`, {
      queryParams: {
        sessionId,
      },
    });
  },

  getDisisHomePageLink(sessionId) {
    return fapiClient.get(`${FAPI_PRODUCT_ENDPOINT}/disis`, {
      queryParams: {
        sessionId,
      },
    });
  },
};
