import React from 'react';

import B2becTranslation from '../../components/B2becTranslation';
import { orderService } from 'services/orderServiceFAPI';

const callValidateZipCodeApi = (country, zipCode) => {
  return orderService.validateZipCode(country, zipCode);
};

export const validateZipCode = (country) => ({
  async validator(_, value) {
    if (!value) {
      return Promise.resolve();
    }
    const response = await callValidateZipCodeApi(country, value);
    if (response && response?.status === 200 && response.data) {
      return Promise.resolve();
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(
      <B2becTranslation value="myProfile.form.validateMessages.invalidZip" />
    );
  },
});

export const isFormDirty = (initialValues, changedValues) => {
  if (!initialValues) {
    return true;
  }

  return (
    Object.keys(changedValues).filter((key) => {
      const initial = initialValues[key];
      const changed = changedValues[key];

      if ([key] in initialValues) {
        return initial !== changed;
      }

      return !!changed;
    }).length !== 0
  );
};
