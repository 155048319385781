import './styles.scss';

import { Col, Divider, Form, Input, Row } from 'antd';
import CustomDateRangePicker from 'components/B2BDateRangePicker';
import CustomTimeRangePicker from 'components/B2BTimeRangePicker';
import CustomButton from 'components/CustomButton';
import PermissionWrapper from 'HOCs/permissionWrapper';
import { VOUCHER_ACTION_TYPES } from 'libs/constants/actionTypes';
import { MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS } from 'libs/constants/modulerights';
import {
  convertDateToISOString,
  createMomentFromDateAndTime,
} from 'libs/utils/formatDate';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ApplicableForm } from '../ApplicableProducts';
import {
  validateDiscount,
  validateDuplicateVoucherCode,
  validateRequiredMessage,
  validateVoucherCodePattern,
} from './validators';

const VoucherPage = (props) => {
  const { t } = useTranslation();
  const {
    voucherInfo,
    voucherAction,
    voucherList,
    onSubmit,
    form: formFromProp,
  } = props;
  const [form] = Form.useForm(formFromProp);
  const history = useHistory();
  const isEditVoucher = voucherAction === VOUCHER_ACTION_TYPES.EDIT_VOUCHER;

  const formItemLayout = {
    labelAlign: 'left',
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const renderTitle = () =>
    isEditVoucher ? t('voucher.editVoucher') : t('voucher.addVoucher');

  const handleSaveVoucher = async () => {
    await form.validateFields();
    const formValues = form.getFieldsValue();
    const finalVoucherInfo = {
      name: formValues.name,
      voucherCode: formValues.voucherCode,
      discount: parseInt(formValues.discount, 10),
      startDate: convertDateToISOString(
        createMomentFromDateAndTime(formValues.fromDate, formValues.fromTime)
      ),
      endDate: convertDateToISOString(
        createMomentFromDateAndTime(formValues.toDate, formValues.toTime)
      ),
      categoryIds: formValues.categoryIds,
      materialNumbers: formValues.materialNumbers,
    };

    onSubmit(finalVoucherInfo);
  };

  const handleCancel = () => {
    history.push(linkGenerator('/voucher-management/'));
  };

  const handleChangeDiscountNumber = (event) => {
    const { value } = event.target;
    const re = /^\d+$/;
    if (value === '' || re.test(value)) {
      form.setFieldsValue({ discount: value });
    } else {
      form.setFieldsValue({ discount: value.replace(/\D/, '') });
    }
  };

  return (
    <div className="mt-3">
      <div className="full-width-page-component__wrapper">
        <div className="voucher-management__title-wrapper">
          <div className="voucher-management__title-content">
            <h1>{renderTitle()}</h1>
          </div>
        </div>
      </div>
      <div className="voucher__wrapper">
        <Form form={form} {...formItemLayout} initialValues={voucherInfo}>
          <h3>{t('voucher.information')}</h3>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="name"
                label={t('voucher.fields.voucherName')}
                className="custom-form-item"
                rules={[
                  {
                    required: true,
                    message: validateRequiredMessage(
                      'voucher.fields.voucherName'
                    ),
                  },
                ]}
              >
                <Input placeholder={`${t('voucher.placeholder.name')}`} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                name="voucherCode"
                label="Voucher Code"
                className="custom-form-item"
                rules={[
                  {
                    required: true,
                    message: validateRequiredMessage(
                      'voucher.fields.voucherCode'
                    ),
                  },
                  {
                    validator: validateVoucherCodePattern,
                  },
                  ({ getFieldValue }) => {
                    const currentValue = getFieldValue('voucherCode');
                    return validateDuplicateVoucherCode(
                      currentValue,
                      voucherList,
                      isEditVoucher
                    );
                  },
                ]}
              >
                <Input placeholder={t('voucher.placeholder.code')} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                name="discount"
                label="Discount"
                className="custom-form-item"
                rules={[
                  {
                    required: true,
                    message: validateRequiredMessage('voucher.fields.discount'),
                  },
                  ({ getFieldValue }) => {
                    const currentValue = getFieldValue('discount');
                    return validateDiscount(currentValue);
                  },
                ]}
              >
                <Input
                  placeholder={t('voucher.placeholder.discount')}
                  onChange={handleChangeDiscountNumber}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="voucher__duration__wrapper">
                <Form.Item dependencies={['fromDate', 'toDate']}>
                  {({ getFieldsValue }) => {
                    const values = getFieldsValue(['fromDate', 'toDate']);
                    return (
                      <CustomDateRangePicker
                        {...values}
                        setFromDate={async (date) => {
                          form.setFieldsValue({ fromDate: date });
                        }}
                        setToDate={async (date) => {
                          form.setFieldsValue({ toDate: date });
                        }}
                      />
                    );
                  }}
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item dependencies={['fromTime', 'toTime']}>
                {({ getFieldsValue }) => {
                  const values = getFieldsValue(['fromTime', 'toTime']);
                  return (
                    <CustomTimeRangePicker
                      {...values}
                      setFromTime={async (time) => {
                        form.setFieldsValue({ fromTime: time });
                      }}
                      setToTime={async (time) => {
                        form.setFieldsValue({ toTime: time });
                      }}
                    />
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div className="mt-6">
            <h3>{t('voucher.applicableProducts')}</h3>
            <ApplicableForm />
          </div>
          <div className="voucher__footer mt-3">
            <CustomButton
              className="btn btn--submit"
              type="ghost"
              onClick={handleCancel}
            >
              {t('voucher.cancel')}
            </CustomButton>
            <PermissionWrapper
              permission={
                MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.UPDATE_VOUCHER
              }
            >
              <CustomButton
                className="btn btn--submit"
                type="primary"
                onClick={handleSaveVoucher}
              >
                {t('voucher.saveChanges')}
              </CustomButton>
            </PermissionWrapper>
          </div>
        </Form>
      </div>
    </div>
  );
};
VoucherPage.propTypes = {
  voucherAction: PropTypes.string,
  voucherInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    voucherCode: PropTypes.string,
    discount: PropTypes.number,
    startDate: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  }),
  onSubmit: PropTypes.func.isRequired,
};

VoucherPage.defaultProps = {
  voucherAction: VOUCHER_ACTION_TYPES.ADD_VOUCHER,
  voucherInfo: {},
};

export default React.memo(VoucherPage);
