import '../styles.scss';

import B2BecLink from 'components/B2BLink';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderData } from '../../../store/selectors/checkoutSelector';

const UpperSectionComponent = () => {
  const { t } = useTranslation();
  const { orderData } = useSelector((state) => ({
    orderData: getOrderData(state),
  }));

  const { salesOrderNumber } = orderData;

  return (
    <div className="order-confirm__main__upper">
      <span className="order-confirm__main__upper--main-text">
        {t('checkout.confirmOrder.yourOrderNumberWasSuccessfullyPlaced', {
          orderNumber: salesOrderNumber,
        })}
        !
      </span>
      <div className="order-confirm__main__upper--sub-text">
        {t('checkout.confirmOrder.YourOrderIsBeingProcessed')}&nbsp;
        <B2BecLink
          to={`/orders/${salesOrderNumber}`}
          state={{ currentPage: 1 }}
        >
          {t('checkout.confirmOrder.seeOrderDetails')}
        </B2BecLink>
      </div>
    </div>
  );
};
export default UpperSectionComponent;
