import clsx from 'clsx';
import { formatPrice } from 'libs/utils/handleNumber';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectCurrencySymbol,
  selectIsCurrencyFront,
} from 'store/selectors/configSelector';
import {
  selectNumberSeparator,
  selectUserCurrency,
} from 'store/selectors/userSelector';

import styles from './FormatPrice.module.scss';

const FormatPrice = ({ price, currencyUnit, wrappedBy, className, style }) => {
  let Comp = 'span';

  const userCurrencyUnit = useSelector(selectUserCurrency);
  const numberSeparator = useSelector(selectNumberSeparator);
  const isCurrencyFront = useSelector(selectIsCurrencyFront);
  const currencySymbol = useSelector(selectCurrencySymbol);
  const formattedPrice = formatPrice(
    // For some pages such as:
    // Cart,... we will use the currency unit from SAP
    price,
    currencyUnit || userCurrencyUnit,
    numberSeparator,
    isCurrencyFront,
    currencySymbol
  );

  if (wrappedBy) {
    Comp = wrappedBy;
  }

  return (
    <Comp className={clsx(styles.formatPrice, className)} style={style}>
      {formattedPrice}
    </Comp>
  );
};

FormatPrice.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currencyUnit: PropTypes.string,
  wrappedBy: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.symbol,
  ]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

FormatPrice.defaultProps = {
  price: 0,
  currencyUnit: '',
  wrappedBy: 'span',
  className: null,
  style: null,
};

export default FormatPrice;
