import { NotificationManager } from 'components/Notification';
import { put, takeLatest } from 'redux-saga/effects';
import { serviceCaseService } from 'services';
import {
  createServiceCase,
  createServiceCaseError,
  createServiceCaseSuccess,
} from 'store/slices/serviceCaseSlice';

function* createServiceCaseSaga({ payload }) {
  try {
    const { data } = yield serviceCaseService.createServiceCase(payload);

    yield put(createServiceCaseSuccess(data));

    NotificationManager.success({
      message: 'notification.success.submitRequestService',
    });
  } catch (error) {
    yield put(createServiceCaseError());
    NotificationManager.error({
      message: 'notification.error.submitRequestService',
    });
  }
}

export default function* serviceCaseSaga() {
  yield takeLatest(createServiceCase.type.toString(), createServiceCaseSaga);
}
