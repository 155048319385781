export const USER_STATUS = {
  IN_PROGRESS: 'inprogress',
  AUTHORIZED: 'authorized',
  UNAUTHORIZED: 'unauthorized',
};

export const USER_ACTION = {
  CREATE_USER: 'CREATE_USER',
  EDIT_USER: 'EDIT_USER',
};

export const USER_STATUS_ARRAY = [
  {
    value: 'InProgress',
    label: 'inProgress',
  },
  {
    value: 'Authorized',
    label: 'authorized',
  },
  {
    value: 'Unauthorized',
    label: 'unauthorized',
  },
];

export const USER_SALUTATION_ARRAY = [
  {
    value: 'Mr',
    label: 'mr',
  },
  {
    value: 'Ms',
    label: 'ms',
  },
];

export const USER_INVITATION_STATUS = {
  INVITED: 'Invited',
  REVOKED: 'Revoked',
};

export const USER_ACCOUNT_STATUS = {
  OPEN: 'Open',
  AUTHORIZED: 'Authorized',
  IN_PROGRESS: 'InProgress',
  UN_AUTHORIZED: 'Unauthorized',
};

export const USER_STATUS_CLASS_NAME = {
  [USER_ACCOUNT_STATUS.UN_AUTHORIZED]: 'userStatus--white',
  [USER_ACCOUNT_STATUS.AUTHORIZED]: 'userStatus--green',
  [USER_ACCOUNT_STATUS.IN_PROGRESS]: 'userStatus--gray',
  [USER_INVITATION_STATUS.INVITED]: 'userStatus--light-gray',
  [USER_INVITATION_STATUS.REVOKED]: 'userStatus--red',
};

export const CONTACT_REASON_TAG = 'contact-reason';

export const CUSTOMER_USER = 'user';
export const EMPLOYEE_USER = 'employee';
export const TECHNICAL_USER = 'technical';

export const USER_TYPES = { CUSTOMER_USER, EMPLOYEE_USER, TECHNICAL_USER };
