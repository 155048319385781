const ORDER_STATUS = {
  unset: 'unset',
  order_created: 'order_created',
  delivery_normal_created: 'delivery_normal_created',
  goods_issue_booked_normal: 'goods_issue_booked_normal',
  goods_issue_booked_route: 'goods_issue_booked_route',
  in_progress_no_delivery_yet: 'in_progress_no_delivery_yet',
  delivery_route_created: 'delivery_route_created',
  goods_issue_total: 'goods_issue_total',
  invoice_normal: 'invoice_normal',
  invoice_route: 'invoice_route',
  invoice_total: 'invoice_total',
  invoice: 'invoice',
  invoiced: 'invoiced',
  in_preparation: 'in_preparation',
  open: 'open',
  in_progress: 'in_progress',
  delivered: 'delivered',
  order_created_route: 'order_created_route',
  order_created_normal: 'order_created_normal',
};

export const ORDER_STATUS_DATA = {
  [ORDER_STATUS.unset]: { title: 'orderStatus.open', className: 'open' },
  [ORDER_STATUS.order_created]: {
    title: 'orderStatus.orderReceived',
    className: 'received',
  },
  [ORDER_STATUS.delivery_normal_created]: {
    title: 'orderStatus.inProgress',
    className: 'in-progress',
  },
  [ORDER_STATUS.goods_issue_booked_normal]: {
    title: 'orderStatus.inProgress',
    className: 'in-progress',
  },
  [ORDER_STATUS.goods_issue_booked_route]: {
    title: 'orderStatus.inProgress',
    className: 'in-progress',
  },
  [ORDER_STATUS.in_progress_no_delivery_yet]: {
    title: 'orderStatus.inProgress',
    className: 'in-progress',
  },
  [ORDER_STATUS.delivery_route_created]: {
    title: 'orderStatus.inProgress',
    className: 'in-progress',
  },
  [ORDER_STATUS.goods_issue_total]: {
    title: 'orderStatus.inDelivery',
    className: 'open',
  },
  [ORDER_STATUS.invoice_normal]: {
    title: 'orderStatus.invoiced',
    className: 'open',
  },
  [ORDER_STATUS.invoice_route]: {
    title: 'orderStatus.invoiced',
    className: 'open',
  },
  [ORDER_STATUS.invoice_total]: {
    title: 'orderStatus.invoiced',
    className: 'open',
  },
  [ORDER_STATUS.invoice]: {
    title: 'orderStatus.invoiced',
    className: 'open',
  },
  [ORDER_STATUS.invoiced]: {
    title: 'orderStatus.invoiced',
    className: 'open',
  },
  [ORDER_STATUS.in_preparation]: {
    title: 'orderStatus.inProgress',
    className: 'in-progress',
  },
  [ORDER_STATUS.open]: {
    title: 'orderStatus.open',
    className: 'open',
  },
  [ORDER_STATUS.in_progress]: {
    title: 'orderStatus.inProgress',
    className: 'in-progress',
  },
  [ORDER_STATUS.delivered]: {
    title: 'orderStatus.inDelivery',
    className: 'in-progress',
  },
  [ORDER_STATUS.order_created_route]: {
    title: 'orderStatus.orderReceived',
    className: 'open',
  },
  [ORDER_STATUS.order_created_normal]: {
    title: 'orderStatus.inProgress',
    className: 'open',
  },
};

export default ORDER_STATUS;
