import B2becCustomSelect from 'components/B2becCustomSelect';
import { useAsync } from 'hooks';
import { ASYNC_STATUS } from 'libs/constants';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { adminFapiConfiguration } from 'services/adminFapi';

const getListCustomer = async (country) => {
  const response = await adminFapiConfiguration.getCustomersByCountry(country);
  return response?.data;
};

const handleFilterCustomer = (filterValue, option) => {
  return (
    option?.label?.toLowerCase()?.includes(filterValue.toLowerCase()) ||
    option?.value?.toLowerCase()?.includes(filterValue.toLowerCase())
  );
};

const B2BSelectCustomer = (props) => {
  const { t } = useTranslation();
  const { country } = props;
  const placeHolder = t('scopeBlock.allCustomers');
  const getCustomersByCountry = useCallback(
    () => getListCustomer(country),
    [country]
  );
  const { value = [], status } = useAsync(getCustomersByCountry, !!country);

  const options = useMemo(() => {
    if (!value) {
      return [{ value: '', label: placeHolder }];
    }
    const formatedOtions = value?.map((customerItem) => {
      return {
        key: customerItem?.customerId,
        label: customerItem?.companyName,
        value: customerItem?.sapCustomerNumber,
      };
    });

    if (placeHolder) {
      return [{ value: '', label: placeHolder }, ...formatedOtions];
    }

    return formatedOtions;
  }, [placeHolder, value]);

  return (
    <B2becCustomSelect
      showSearch
      options={options}
      loading={status === ASYNC_STATUS.PENDING}
      filterOption={handleFilterCustomer}
      {...props}
    />
  );
};

export default B2BSelectCustomer;
