import './styles.scss';

import { Divider } from 'antd';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { ACTION_STATUS } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { analyzeTransaction } from 'libs/utils/adobeAnalysis';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCustomerAddress,
  getCustomerFetchStatus,
} from 'store/selectors/customerSelector';
import {
  trackCompanyInfo,
  trackTransaction,
} from 'store/slices/adobeAnalyticSlice';

import B2becSpinner from '../../components/B2becSpinner';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import {
  getDeliveryData,
  getIsLoadingCheckout,
  getOrderData,
} from '../../store/selectors/checkoutSelector';
import { ordersActions } from '../../store/slices/orderSlice';
import BottomSectionComponent from './BottomSection';
import MiddleSectionComponent from './MiddleSection';
import UpperSectionComponent from './UpperSection';

const OrderConfirmationPage = (props) => {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageName: 'order confirmation',
    pageType: PAGE_TYPES.CONFIRMATION,
    pageId,
  });

  const { t } = useTranslation();
  const history = useHistory();

  const orderData = useSelector(getOrderData);
  const deliveryData = useSelector(getDeliveryData);
  const isCheckoutLoading = useSelector(getIsLoadingCheckout);
  const customerAddress = useSelector(getCustomerAddress);
  const customerFetchStatus = useSelector(getCustomerFetchStatus);

  const { salesOrderNumber } = orderData;
  const dispatch = useDispatch();

  // analyze transaction
  useEffect(() => {
    if (orderData && deliveryData && salesOrderNumber) {
      const transaction = analyzeTransaction({ orderData, deliveryData });

      dispatch(trackTransaction(transaction));
    }
    if (customerFetchStatus !== ACTION_STATUS.DEFAULT) {
      const companyName = `${customerAddress?.name || ''} ${
        customerAddress?.name2 || ''
      }`.trim();

      const companyID = customerAddress?.partnerNumber || '';

      dispatch(
        trackCompanyInfo({
          companyName,
          companyID,
        })
      );
    }
  }, [
    dispatch,
    orderData,
    deliveryData,
    salesOrderNumber,
    customerAddress,
    customerFetchStatus,
  ]);

  useEffect(() => {
    if (!salesOrderNumber) {
      history.push(linkGenerator('/my-workspace'));
    }
    return () => {
      dispatch(ordersActions.clearOrderData());
    };
  }, [salesOrderNumber, history, dispatch]);

  return (
    <div className="order-confirm-wrapper">
      <B2becSpinner isLoading={isCheckoutLoading} />
      <h1 className="order-confirm__header">
        {t('checkout.confirmOrder.thankForYourOrder')}!
      </h1>
      <div className="order-confirm__main">
        <UpperSectionComponent />
        <MiddleSectionComponent />
        <Divider className="order-confirm__divider" />
        <BottomSectionComponent />
      </div>
    </div>
  );
};

OrderConfirmationPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default OrderConfirmationPage;
