import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminFapiUserManagement } from 'services/adminFapi';
import { selectUserId } from 'store/selectors/authenticationSelectors';

import Translator from '../../../../../../components/B2becTranslation';
import { notificationComponent } from '../../../../../../components/Notification';
import useAsync from '../../../../../../hooks/useAsync';
import { ASYNC_STATUS, STATUS_CODE } from '../../../../../../libs/constants';
import { NOTIFICATION_TYPE } from '../../../../../../libs/constants/notification';
import ActionButtonWithModal from '../ActionsModalComponent';

function DeleteUserSection(props) {
  const { isLoading, userId, handleDeleteUser, redirectUrl, className } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const currentUserId = useSelector(selectUserId);

  const deleteUserAccount = useMemo(() => {
    return (cancelToken) => (uid, deleteByUserId) =>
      handleDeleteUser(uid, deleteByUserId, cancelToken);
  }, [handleDeleteUser]);

  const { execute, status } = useAsync(
    deleteUserAccount,
    false,
    undefined,
    undefined,
    true
  );

  const onDeleteUser = useCallback(() => {
    if (userId) {
      execute(userId).then(({ response, error }) => {
        if (response?.status === STATUS_CODE.SUCCESS) {
          notificationComponent({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: <Translator value="notification.success.deleteUser" />,
          });

          history.replace(linkGenerator(redirectUrl));
        }
        if (error) {
          notificationComponent({
            type: NOTIFICATION_TYPE.ERROR,
            message: <Translator value="notification.error.deleteUser" />,
          });
        }
      });
    }
  }, [userId, execute, history, redirectUrl]);

  const translateTextByKey = useCallback(
    (key) =>
      t(`adminWorkspace.userManagement.userDetails.deleteUserSection.${key}`),
    [t]
  );

  return (
    <ActionButtonWithModal
      className={className}
      isLoading={status === ASYNC_STATUS.PENDING}
      isDisabled={isLoading || userId === currentUserId}
      title={translateTextByKey('title')}
      description={translateTextByKey('description')}
      buttonName={translateTextByKey('button')}
      modalTitle={t('userManagement.deleteUser.title')}
      modalContent={t('userManagement.deleteUser.modalContent')}
      onConfirmAction={onDeleteUser}
    />
  );
}

DeleteUserSection.propTypes = {
  isLoading: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  handleDeleteUser: PropTypes.func,
  redirectUrl: PropTypes.string,
  className: PropTypes.string,
};

DeleteUserSection.defaultProps = {
  isLoading: false,
  handleDeleteUser: adminFapiUserManagement.deleteUserAccount,
  redirectUrl: '/user-management',
  className: '',
};

export default React.memo(DeleteUserSection);
