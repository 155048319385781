export const selectTechnicalDetailsLoading = (state) =>
  state.technical.details.isLoading;

export const selectTechnicalDetailsIsUpdating = (state) =>
  state.technical.details.isUpdating;

export const selectTechnicalDetailsError = (state) =>
  state.technical.details.error;

export const selectTechnicalDetailsData = (state) =>
  state.technical.details.data;
