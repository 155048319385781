import B2becButton from 'components/B2becButton';
import { DeleteBorderlessIcon, DeleteIcon } from 'components/Icons';
import useDeviceDetect from 'hooks/useDeviceDetect';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './RemoveVoucher.module.scss';

const RemoveVoucher = ({ onRemoveVoucher, isDisabled }) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  return (
    <div className={styles.wrapper}>
      <B2becButton
        className={styles.button}
        disabled={isDisabled}
        onClick={onRemoveVoucher}
        ghost
        icon={
          isMobile ? (
            <DeleteIcon className={styles.icon} />
          ) : (
            <DeleteBorderlessIcon className={styles.icon} />
          )
        }
      >
        {t('cart.removeVoucher')}
      </B2becButton>
    </div>
  );
};

RemoveVoucher.propTypes = {
  onRemoveVoucher: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

RemoveVoucher.defaultProps = {
  isDisabled: false,
};

export default RemoveVoucher;
