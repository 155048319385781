import './styles.scss';

import clsx from 'clsx';
import { ChevronLeftIcon } from 'components/Icons';
import { useCheckoutContext } from 'contexts/checkout-context';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectIsCurrentTimeInSAPDowntime } from 'store/selectors/configSelector';

import Translator from '../../../../components/B2becTranslation';
import CustomButton from '../../../../components/CustomButton';

export const PlaceOrderButton = (props) => {
  const { submitFormHandler, className, disabled } = props;

  return (
    <CustomButton
      className={`button-wrapper__button button-wrapper__button--next ${className}`}
      onClick={submitFormHandler}
      disabled={disabled}
    >
      <Translator value="checkout.step3.navigationButtons.next" />
    </CustomButton>
  );
};

const ButtonsWrapperComponent = (props) => {
  const { tab, setTabHandler, submitFormHandler } = props;
  const history = useHistory();

  const { shouldDisableCheckout } = useCheckoutContext();

  const isCurrentTimeInSAPDowntime = useSelector(
    selectIsCurrentTimeInSAPDowntime
  );

  const activePrevTabHandler = () => {
    switch (tab) {
      case 1:
        history.push(linkGenerator('/cart'));
        break;
      case 2:
      case 3:
      default:
        setTabHandler(+(tab - 1));
        break;
    }
  };

  const navigationButtonTitleKeys = useMemo(() => {
    switch (tab) {
      case 1:
        return {
          back: 'checkout.step1.navigationButtons.back',
          next: 'checkout.step1.navigationButtons.next',
        };
      case 2:
        return {
          back: 'checkout.step2.navigationButtons.back',
          next: 'checkout.step2.navigationButtons.next',
        };
      case 3:
        return {
          back: 'checkout.step3.navigationButtons.back',
          next: 'checkout.step3.navigationButtons.next',
        };
      default:
        return {
          back: 'checkout.navigationButtons.back',
          next: 'checkout.navigationButtons.next',
        };
    }
  }, [tab]);

  return (
    <div className="button-wrapper">
      <CustomButton
        className={clsx(
          'button-wrapper__button',
          'button-wrapper__button--back',
          {
            'button-wrapper__icon': tab === 1,
          }
        )}
        onClick={activePrevTabHandler}
        type="ghost"
        icon={<ChevronLeftIcon />}
      >
        <Translator value={navigationButtonTitleKeys.back} />
      </CustomButton>
      {tab >= 3 ? (
        <PlaceOrderButton
          submitFormHandler={submitFormHandler}
          disabled={shouldDisableCheckout || isCurrentTimeInSAPDowntime}
        />
      ) : (
        <CustomButton
          className="button-wrapper__button button-wrapper__button--next"
          onClick={() => setTabHandler(+(tab + 1))}
        >
          <Translator value={navigationButtonTitleKeys.next} />
        </CustomButton>
      )}
    </div>
  );
};

ButtonsWrapperComponent.propTypes = {
  tab: PropTypes.number,
  setTabHandler: PropTypes.func,
  submitFormHandler: PropTypes.func,
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldValue: PropTypes.func,
  }).isRequired,
};
ButtonsWrapperComponent.defaultProps = {
  tab: 1,
  setTabHandler: () => {},
  submitFormHandler: () => {},
};
PlaceOrderButton.propTypes = {
  submitFormHandler: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
PlaceOrderButton.defaultProps = {
  submitFormHandler: () => {},
  className: null,
  disabled: false,
};

export default ButtonsWrapperComponent;
