import './styles.scss';

import { Layout } from 'antd';
import clsx from 'clsx';
import { useCheckLocation } from 'hooks';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { STICKY_ZINDEX } from 'libs/constants/header';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Sticky from 'react-stickynode';

import SidebarMenu from '../SidebarMenu';

const { Sider } = Layout;

function Sidebar() {
  const { t } = useTranslation();
  const { isInMyWorkspace } = useCheckLocation();

  const { isXLScreen } = useDeviceDetect();

  const classes = clsx(
    'sidebar__wrapper',
    isInMyWorkspace && 'sidebar__wrapper-homepage'
  );

  const renderSidebarHeader = (words) =>
    words
      .split(' ')
      .map((word, idx) => <span key={`${word}${idx + 1}`}>{word}</span>);

  const renderSideBarMenu = () => (
    <Sider style={{ backgroundColor: 'transparent' }}>
      <div className="sidebar__header">
        {renderSidebarHeader(t('myBusinessWorkspace'))}
      </div>
      <SidebarMenu />
    </Sider>
  );

  return (
    isXLScreen && (
      <Sticky
        className={classes}
        top={isInMyWorkspace ? 94 : 136}
        innerZ={STICKY_ZINDEX.SIDE_BAR}
      >
        <div className="sidebar__icons-wrapper">{renderSideBarMenu()}</div>
      </Sticky>
    )
  );
}

export default memo(Sidebar);
