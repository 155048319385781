import { NotificationManager } from 'components/Notification';
import { adminFapiSupport } from 'services/adminFapi';

export const handleAddToCartSupport = (materialNumber, countryId, userId) => {
  const addProductHandler = (inputValue) => {
    return adminFapiSupport.addProductToCustomerCart({
      materialNumber: inputValue,
      quantity: 1,
      countryId,
      userId,
    });
  };
  return addProductHandler(materialNumber).then((response, error) => {
    if (response) {
      NotificationManager.success({
        message: 'notification.success.addProduct',
      });
    }
    if (error) {
      NotificationManager.error({
        message: 'notification.error.addProduct',
      });
    }
  });
};

export const handleDeleteCartSupport = async (userId) => {
  try {
    await adminFapiSupport.deleteCartForGivenUserId({ userId });
    NotificationManager.success({
      message: 'notification.success.submit',
    });
  } catch {
    NotificationManager.error({
      message: 'notification.error.submit',
    });
  }
};

export const handleClearCacheSupport = async (countryId) => {
  try {
    await adminFapiSupport.clearCacheForCountry(countryId);
    NotificationManager.success({
      message: 'notification.success.submit',
    });
  } catch {
    NotificationManager.error({
      message: 'notification.error.submit',
    });
  }
};
