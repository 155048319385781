import { Empty } from 'antd';
import B2becPagination from 'components/B2becPagination';
import { NotificationManager } from 'components/Notification';
import ProductCard from 'components/ProductCard';
import B2becCardSkeleton from 'components/Skeletons/B2becCardSkeleton';
import { PAGE_SIZE } from 'libs/constants';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adminFapiSupport } from 'services/adminFapi';
import { httpClient } from 'services/apiHttpClient';

import styles from './MachinesList.module.scss';

function MachinesList({ userId }) {
  const { t } = useTranslation();
  const [machinesList, setMachinesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const handlePageChange = (current) => {
    setCurrentPage(current);
  };

  let content;
  if (isLoading) {
    content = (
      <B2becCardSkeleton
        gutter={20}
        columns={{ xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 6 }}
        imageHeight={255}
        hasPrice={false}
        number={8}
      />
    );
  } else if (isValidArray(machinesList)) {
    content = (
      <div className={styles.machines}>
        {machinesList.map((machine) => {
          const key =
            machine?.materialNumber +
            machine?.orderDate +
            machine?.salesOrderNumber;

          return (
            <div key={key}>
              <ProductCard
                isRenderedInSupportArea
                date={machine?.orderDate}
                name={machine?.name}
                img={machine?.imgUrl}
                materialId={machine?.materialNumber}
                category={machine?.categoryName}
                serialNumber={machine?.serialNumber}
                salesOrderNumber={machine?.salesOrderNumber}
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    content = <Empty description={t('noData')} />;
  }

  useEffect(() => {
    let ignore = false;
    setIsLoading(true);
    const source = httpClient.cancelTokenSource();

    const getMachinesList = async () => {
      try {
        const { data } = await adminFapiSupport.getMachinesListByCustomerNumber(
          {
            userId,
            pageNumber: currentPage,
            pageSize: PAGE_SIZE[12],
            sortOrder: 6,
            cancelToken: source.token,
          }
        );

        if (!ignore) {
          setMachinesList(data?.items);
          setTotal(data?.totalCount);
        }
      } catch (error) {
        if (httpClient.isCancel(error)) {
          console.error('Cancelled due to stale request');
        } else {
          NotificationManager.error({
            message: 'notification.error.load',
          });
          console.error(error?.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getMachinesList();

    return () => {
      ignore = true;
      source.cancel();
    };
  }, [userId, currentPage]);

  return (
    <div className={styles.wrapper}>
      {content}
      <B2becPagination
        current={currentPage}
        total={total}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

MachinesList.propTypes = {
  userId: PropTypes.string.isRequired,
};

export { MachinesList };
