import './styles.scss';

import clsx from 'clsx';
import B2BecLink from 'components/B2BLink';
import PropTypes from 'prop-types';
import React from 'react';

import B2becTooltip from '../../../../components/B2becTooltip';
import SubItemList from '../../components/SubItemList';

function CategoryItem(props) {
  const { url, categoryId, imgUrl, imgDescription, title, subCategories } =
    props;

  const categoryItemTitle = (title || '').replace(
    /(.*)([-/])(\S)(.*)/,
    '$1$2 $3$4'
  );

  return (
    <div className="category-item__wrapper">
      <B2BecLink to={url}>
        {/* <div className="category-item__image-wrapper"> */}
        <div
          className={clsx('category-item__image-wrapper', {
            'no-image': !imgUrl,
          })}
        >
          {imgUrl ? (
            <img
              className="category-item__image"
              src={imgUrl}
              alt={imgDescription}
            />
          ) : null}
        </div>
        <h2 className="category-item__title pt-3 pb-3">
          <B2becTooltip title={categoryItemTitle} content={categoryItemTitle} />
        </h2>
      </B2BecLink>
      <SubItemList
        mainCategoryId={categoryId}
        type="category-overview"
        listData={subCategories}
      />
    </div>
  );
}

CategoryItem.propTypes = {
  url: PropTypes.string,
  imgUrl: PropTypes.string,
  imgDescription: PropTypes.string,
  title: PropTypes.string,
  categoryId: PropTypes.number.isRequired,
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

CategoryItem.defaultProps = {
  url: '',
  imgUrl: '',
  imgDescription: '',
  title: '',
  subCategories: [],
};

export default CategoryItem;
